import './CasualShiftList.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { CASUAL_SHIFT_PROPS } from '../../StoreProfileProps';
import useStoreProfile from '../../../hooks/StoreProfileContext';

import CasualShiftItem from './CasualShiftItem';
import StoreShiftList from './StoreShiftList';

const propTypes = {
  /* Casual shifts listed by the store. */
  shifts: PropTypes.arrayOf(PropTypes.shape(CASUAL_SHIFT_PROPS)).isRequired,

  /* Store name. */
  storeName: PropTypes.string.isRequired,
}

function CasualShiftList({ shifts, storeName }) {
  const { storeProfile } = useStoreProfile();
  const { theme, storeUrl } = storeProfile;
  const { secondaryHexColor } = theme;
  const numShifts = shifts.length;
  const text = numShifts === 1 ? 'View casual shift' : 'View casual shifts';
  const buttonText = `${text} (${numShifts})`;
  const disabled = numShifts === 0;
  const casualShifts = shifts.map((shift, index) => {
    const isEven = index % 2 === 0;
    const additionalClassNames = isEven ? ['even'] : ['odd'];
    return (
      <CasualShiftItem
        additionalClassNames={additionalClassNames}
        shift={shift}
        storeName={storeName}
        isEven={isEven}
        key={`key-${JSON.stringify(shift)}`}
      />
    )
  });
  const plausibleLink = `plausible-event-link=${storeUrl}`;
  const plausibleEventClass = `plausible-event-name=Casual+Shifts+Click ${plausibleLink}`;
  return (
    <StoreShiftList
      buttonText={buttonText}
      disabled={disabled}
      additionalClassNames={['casual', plausibleEventClass]}
      dropdownArrowColor={secondaryHexColor}
      borderColor={secondaryHexColor}
    >
      {casualShifts}
    </StoreShiftList>
  )
}

CasualShiftList.displayName = 'CasualShiftList';
CasualShiftList.propTypes = propTypes;
export default CasualShiftList;