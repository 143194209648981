import './ReviewStoreInput.scss';

import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  /* Html for review store input. */
  htmlFor: PropTypes.string.isRequired,

  /* Label. */
  label: PropTypes.string.isRequired,

  /* Children. */
  children: PropTypes.node.isRequired,
}

function ReviewStoreInput({ htmlFor, label, children }) {
  return (
    <section className={'review-store-input-container'}>
      <label className={'review-store-input-label'} htmlFor={htmlFor}>{label}</label>
      {children}
    </section>
  )
}

ReviewStoreInput.displayName = 'ReviewStoreInput';
ReviewStoreInput.propTypes = propTypes;
export default ReviewStoreInput;