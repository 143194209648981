import React from 'react';
import { useNavigate } from 'react-router-dom';
import { NAVIGATION_OPTIONS } from '../BusinessConstants.js';
import useStore from '../../hooks/StoreHook.jsx';
import './StoreCardNavigationButton.scss';

const StoreCardNavigationButton = ({ mode, storeId }) => {
  const { url, icon, text } = NAVIGATION_OPTIONS[mode];
  const { selectStoreId } = useStore();
  const navigate = useNavigate();
  const onClick = () => {
    selectStoreId(storeId);
    navigate(url, { state: { storeId }})
  };
  return (
    <button className={'store-card-navigation-button very-light'} onClick={onClick}>
    <img className={'navigation-icon'} src={icon} alt={`${text} icon`} />
    <span className={'button-text'}>{text}</span>
    </button>
  );
}

export default StoreCardNavigationButton;
