import { useEffect } from 'react';

import { postFrontendErrorLog } from '../Api';

export const useErrorLogger = (profile) => {
  useEffect(() => {
    const logEvent = (event) => {
      console.log({ event });
      const { error = {}, message, isTrusted, filename, lineno, colno, type, target } = event;
      const { clientInformation, location } = window;
      const { userAgent } = clientInformation;
      const { href } = location;
      const currentSrc = target ? target.currentSrc : null;
      postFrontendErrorLog({
        error: { ...error, type, currentSrc   },
        message,
        isTrusted,
        userAgent,
        href,
        location,
        profile,
        filename,
        lineno,
        colno
      });
    };
    return () => window.removeEventListener('error', logEvent);
  }, [profile]);
}