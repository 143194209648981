import './RatingStars.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import RatingStar from './RatingStar';

const propTypes = {
  /* Number of stars. */
  number: PropTypes.number,

  /* Select number of stars. */
  selectStars: PropTypes.func.isRequired,
}

function RatingStars({ number = null, selectStars }) {
  const [stars, setStars] = useState(null);
  const starClick = (starNumber) => () => selectStars(starNumber);
  const onStarMouseEnter = (starNumber) => () => setStars(starNumber);
  const onStarMouseLeave = () => setStars(null);
  const isSelected = (starNumber) => {
    if (stars !== null) {
      return starNumber <= stars;
    }
    return number !== null && starNumber <= number;
  };
  console.log(isSelected(1));
  return (
    <section className={'rating-stars'}>
      <RatingStar
        onClick={starClick(1)}
        onMouseEnter={onStarMouseEnter(1)}
        onMouseLeave={onStarMouseLeave}
        selected={isSelected(1)}
      />
      <RatingStar
        onClick={starClick(2)}
        onMouseEnter={onStarMouseEnter(2)}
        onMouseLeave={onStarMouseLeave}
        selected={isSelected(2)}
      />
      <RatingStar
        onClick={starClick(3)}
        onMouseEnter={onStarMouseEnter(3)}
        onMouseLeave={onStarMouseLeave}
        selected={isSelected(3)}
      />
      <RatingStar
        onClick={starClick(4)}
        onMouseEnter={onStarMouseEnter(4)}
        onMouseLeave={onStarMouseLeave}
        selected={isSelected(4)}
      />
      <RatingStar
        onClick={starClick(5)}
        onMouseEnter={onStarMouseEnter(5)}
        onMouseLeave={onStarMouseLeave}
        selected={isSelected(5)}
      />
    </section>
  )
}

RatingStars.defaultProps = { number: null };
RatingStars.displayName = 'RatingStars';
RatingStars.propTypes = propTypes;
export default RatingStars;