import { createContext, useEffect, useState } from "react";

const CartContext = createContext();

export function CartProvider({children}) {
  const [cartShifts, setCartShifts] = useState({});
  const [rates, setRates] = useState({});
  const [messages, setMessages] = useState({});
  const [initialLoad, setInitialLoad] = useState(false);

  const addToCart = (shift) => {
    // If item already in cart, remove it. Otherwise, add to cart.
    setCartShifts((prevState) => {
      if (prevState && prevState.hasOwnProperty(shift._id)) {
        const storeIds = getStoreIds();
        delete prevState[shift._id];
        removeRate(shift._id);
        if (!storeIds.includes(shift.storeId)) {
          removeStoreMsg(shift.storeId);
        }
        return {...prevState};
      } else {
        return {...prevState, [shift._id]: shift};
      }
    });
  }

  useEffect(() => {
    let prevCart = JSON.parse(localStorage.getItem('cart')) || {};
    setCartShifts(prevCart)
    setInitialLoad(true)
  }, [])
  
  useEffect(() => {
    if (initialLoad) {
      localStorage.setItem("cart", JSON.stringify(cartShifts));
    }
  }, [cartShifts, initialLoad]);

  const clearCart = () => {
    setCartShifts({});
    setRates({});
    setMessages({});
    localStorage.removeItem('cart');

  }

  const removeShifts = (shifts) => {
    setCartShifts(prevState => {
      let changedState = {...prevState};
      Object.keys(shifts).forEach(shiftId => {
        if (changedState.hasOwnProperty(shiftId)) {
          delete changedState[shiftId];
        }
      })
      return changedState;
    })
    setRates(prevState => {
      let changedState = {...prevState};
      Object.keys(shifts).forEach(shiftId => {
        if (changedState.hasOwnProperty(shiftId)) {
          delete changedState[shiftId];
        }
      })
      return changedState;
    })
    setMessages(prevState => {
      let changedState = {...prevState};
      Object.keys(shifts).forEach(shiftId => {
        if (changedState.hasOwnProperty(shifts[shiftId])) {
          delete changedState[shifts[shiftId]];
        }
      })
      return changedState;
    })
  }

  // Add new rate
  // TODO: in server side if rate is same as proposed rate, don't send msg.
  const proposeRate = (shift, rate) => {
    setRates((prevState) => ({...prevState, [shift._id]: {shift: shift, newRate: parseInt(rate)}}));
  }

  // If a shift gets removed from cart, remove the proposed rate.
  const removeRate = (shiftId) => {
    setRates(prevState => {
      delete prevState[shiftId];
      return {...prevState};
    })
  }

  const addMessage = (storeId, message) => {
    setMessages(prevState => ({...prevState, [storeId]: {defMsg: message}}));
  }

  const removeStoreMsg = (storeId) => {
    setMessages(prevState => {
      delete prevState[storeId];
      return {...prevState}
    })
  }

  const getStoreIds = () => {
    return Object.keys(cartShifts).map(shift => shift.storeIds);
  }

  const aggregateStores = (shifts) => {
    let stores = {}
    Object.keys(shifts).forEach(shift => {
      let shiftData = {...shifts[shift]};
      delete shiftData.name;
      delete shiftData.address;
      delete shiftData.avatar;
      delete shiftData.network;
      delete shiftData.organization;
      delete shiftData.contact;
      delete shiftData.preferences;
      delete shiftData.banking;
      if (stores.hasOwnProperty(shiftData.storeId)) {
        stores[shiftData.storeId].shifts.push(shiftData);
      } else {
        let storeData = {
          name: shifts[shift].name,
          address: shifts[shift].address,
          avatar: shifts[shift].avatar,
          network: shifts[shift].network,
          organization: shifts[shift].organization,
          contact: shifts[shift].contact,
          preferences: shifts[shift].preferences,
          banking: shifts[shift].banking,
          shifts: [shiftData]
        }
        stores[shiftData.storeId] = storeData
      }
    });
    return stores;
  }

  return (
    <CartContext.Provider value={{ cartShifts, addToCart, aggregateStores, rates, proposeRate, messages, addMessage, clearCart, removeShifts }}>
      {children}
    </CartContext.Provider>
  )
}

export default CartContext;