import './ReviewDropdown.scss';

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useOutsideClick } from '../../../../../hooks/CommonHooks';

import StoreProfileDropdownButton from '../../shared/StoreProfileDropdownButton';

const propTypes = {
  /* Options to select from. */
  options: PropTypes.array.isRequired,

  /* Callback to select an option. */
  select: PropTypes.func.isRequired,

  /* Selected option. Can be null. */
  selected: PropTypes.string,
}

function ReviewDropdown({ options, select, selected = null }) {
  const dropdownRef = useRef(null);
  const [open, setOpen] = useState(false);
  const selectedOption = options.find(({ key }) => key === selected);
  const buttonText = selectedOption ? selectedOption.displayText : '';
  const onClick = () => setOpen(!open);
  const clickKey = (key) => {
    select(key);
    setOpen(false);
  }
  useOutsideClick(dropdownRef, () => setOpen(false));
  const dropdownOptions = options.map(({ key, displayText }) => (
    <li className={'review-dropdown-option very-light'} key={`review-dropdown-${key}`}>
      <button className={'review-option'} onClick={() => clickKey(key)} type={'button'}>
        {displayText}
      </button>
    </li>
  ))
  return (
    <section className={'review-dropdown'} ref={dropdownRef}>
      <StoreProfileDropdownButton open={open} onClick={onClick} buttonText={buttonText} />
      { open && (
        <section className={'dropdown'}>
          <ul className={'dropdown-list'}>
            {dropdownOptions}
          </ul>
        </section>
      )}
    </section>
  )
}

ReviewDropdown.defaultProps = { selected: null };
ReviewDropdown.displayName = 'ReviewDropdown';
ReviewDropdown.propTypes = propTypes;
export default ReviewDropdown;