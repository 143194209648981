import React from 'react';
import { Route } from 'react-router-dom';

import FindPage from '../components/find/FindPage';
import StoreShiftPage from '../components/locum/StoreShiftPage';
import ShiftPage from '../components/locum/ShiftPage';
import LocumlyPage from '../wrappers/LocumlyPage';

function getFindRoutes() {
  return (
    <Route path={'/find'} element={<LocumlyPage />}>
      <Route index={true} element={<FindPage />} />
      <Route path={'store/:id'} element={<StoreShiftPage />} />
      <Route path={':id'} element={<ShiftPage />} />
      <Route path={'*'} element={<FindPage />} />
    </Route>
  );
}

export default getFindRoutes;