import './ReviewStoreSection.scss';

import PropTypes from 'prop-types';
import React from 'react';

import ReviewStoreInput from '../shared/ReviewStoreInput';
import YesNoRecommendation from './YesNoRecommendation';
import GoToButton from '../shared/GoToButton';
import ProgressBar from '../shared/ProgressBar';
import ReviewDropdown from '../shared/ReviewDropdown';
import { EMPLOYMENT_STATUS, LENGTH_OF_WORK } from '../ReviewConstants';

const hasNullValue = (arr) => arr.filter(element => element === null).length > 0;

function ReviewStoreSection({ review, setReview, next }) {
  const { employmentStatus, lengthOfWork, pros, cons, wouldRecommend } = review;
  const onChange = (fieldName) => ({ target }) =>
    setReview({ ...review, [fieldName]: target.value });
  const setWouldRecommend = (recommendation) =>
    setReview({ ...review, wouldRecommend: recommendation });
  const selectOption = (fieldName) => (key) => setReview({ ...review, [fieldName]: key });
  const disabled = hasNullValue(Object.values(review));
  return (
    <section className={'review-store-section'}>
      <section className={'inputs'}>
        <ReviewStoreInput htmlFor={'employment-status'} label={'Employment status'}>
          <ReviewDropdown
            options={Object.values(EMPLOYMENT_STATUS)}
            selected={employmentStatus}
            select={selectOption('employmentStatus')}
          />
        </ReviewStoreInput>
        <ReviewStoreInput htmlFor={'length-of-work'} label={'How long did you work here?'}>
        <ReviewDropdown
            options={Object.values(LENGTH_OF_WORK)}
            selected={lengthOfWork}
            select={selectOption('lengthOfWork')}
          />
        </ReviewStoreInput>
        <ReviewStoreInput htmlFor={'pros'} label={'Pros'}>
          <input
            className={'review-store-input'}
            id={'pros'}
            type={'text'}
            value={pros}
            onChange={onChange('pros')}
          />
        </ReviewStoreInput>
        <ReviewStoreInput htmlFor={'cons'} label={'Cons'}>
          <input
            className={'review-store-input'}
            id={'cons'}
            type={'text'}
            value={cons}
            onChange={onChange('cons')}
          />
        </ReviewStoreInput>
        <ReviewStoreInput
          htmlFor={'recommend'}
          label={'Would you recommend this store to a friend?'}
        >
          <YesNoRecommendation
            wouldRecommend={wouldRecommend}
            setWouldRecommend={setWouldRecommend}
          />
        </ReviewStoreInput>
        <section className={'go-to-buttons'}>
          <GoToButton goTo={next} disabled={disabled} reversed={false} />
        </section>
      </section>
      <ProgressBar stepNum={'one'} />
    </section>
  );
}

export default ReviewStoreSection;