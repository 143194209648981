import React, { useState, createContext, useContext } from 'react';
import Cookies from 'js-cookie';
import { useLocation, Navigate } from 'react-router-dom';
import { postLogin } from '../Api.js';
import { DEFAULT_PERSON_AVATAR, USER_ROLES, DEFAULT_STORE_AVATAR, BUSINESS_DEFAULT_ROUTE } from '../Constants';

const BUSINESS_ONLY_PATHS = [
  '/business/account/manage',
  '/store/profile/edit',
  '/store/shifts/add',
  '/store/shifts/upcoming',
  '/store/payments/complete',
  '/store/applicants/review',
  '/store/preferences/manage',
  '/business/stores',
];

const LOCUM_ONLY_PATHS = [
  '/locum/payments/manage',
  '/locum/shifts/completed',
  '/locum/shifts/upcoming',
  '/locum/profile/edit',
  '/locum/experience/edit',
  '/locum/account/manage',
]

const AuthContext = createContext();

const useAuth = () => {
  const [authed, setAuthed] = useState(false);
  const [profile, setProfile] = useState(null);
  const [chats, setChats] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const setUserProfile = (userProfile) => {
    const { avatar, stores = [] } = userProfile;
    const profileAvatar = avatar ? avatar: DEFAULT_PERSON_AVATAR;
    const storesWithDefaultAvatar = stores.map((store) => {
      const storeAvatar = store.avatar ? store.avatar : DEFAULT_STORE_AVATAR[store.type];
      return { ...store, avatar: storeAvatar };
    });
    setProfile({ ...userProfile, avatar: profileAvatar, stores: storesWithDefaultAvatar });
  }
  return {
    authed,
    setAuthed,
    profile,
    setProfile: setUserProfile,
    chats,
    setChats,
    notifications,
    setNotifications,
    login: async (user) => postLogin(user, setAuthed, setProfile),
    logout: () => {
      return new Promise((res) => {
        Cookies.remove('token');
        setAuthed(false);
        setProfile(null);
        res();
      });
    }
  };
};

export const AuthProvider = ({ children }) => {
  const auth = useAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

const AuthConsumer = () => {
  return useContext(AuthContext);
};

export default AuthConsumer;

export const RequireAuth = ({ children }) => {
  const { authed, profile } = AuthConsumer();
  const location = useLocation();
  if (authed) {
    if (profile.role === USER_ROLES.LOCUM) {
      if (!profile.completedOnboarding && location.pathname !== '/user/onboarding') {
        return <Navigate to={'/user/onboarding'} replace={'true'} state={{ path: location.pathname }} />;
      }
      if (BUSINESS_ONLY_PATHS.includes(location.pathname)) {
        return <Navigate to={'/find'} replace={'true'} state={{ path: location.pathname }} />;
      }
    }
    else {
      if (!profile.completedOnboarding && location.pathname !== '/user/onboarding') {
        return <Navigate to={'/user/onboarding'} replace={'true'} state={{ path: location.pathname }} />;
      }
      if (LOCUM_ONLY_PATHS.includes(location.pathname)) {
        return <Navigate to={BUSINESS_DEFAULT_ROUTE} replace={'true'} state={{ path: location.pathname }} />;
      }
    }
  }

  return authed 
    ? children : <Navigate to={'/login'} replace={'true'} state={{ path: location.pathname }} />;
};

export const RequireNoAuth = ({ children }) => {
  const { authed, profile } = AuthConsumer();
  const location = useLocation();
  if (authed) {
    if (!profile.completedOnboarding && location.pathname !== '/user/onboarding') {
      return <Navigate to={'/user/onboarding'} replace={'true'} state={{ path: location.pathname }} />;
    }
  }
  return !authed
    ? children
    : <Navigate
      to={profile.role === USER_ROLES.LOCUM ? '/find' : BUSINESS_DEFAULT_ROUTE}
      replace={'true'}
      state={{ path: location.pathname }}
    />;
};