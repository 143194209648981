import { ASSET_CDN_URL } from '../Constants';

const AddShiftIcon = `${ASSET_CDN_URL}/store/add-shifts.svg`;
const UpcomingShiftsIcon = `${ASSET_CDN_URL}/store/upcoming-shifts.svg`;
const ReviewApplicantsIcon = `${ASSET_CDN_URL}/store/review-applicants.svg`;
const CompletePaymentsIcon = `${ASSET_CDN_URL}/store/complete-payments.svg`;
const StorePreferencesIcon = `${ASSET_CDN_URL}/store/store-preferences.svg`;
const StoreProfileIcon = `${ASSET_CDN_URL}/store/store-profile.svg`;

export const NAVIGATION_OPTIONS = {
  SHIFT: {
    url: '/store/shifts/add',
    mode: 'SHIFT',
    icon: AddShiftIcon,
    text: 'Add shift',
    actionText: 'Add shifts'
  },
  UPCOMING: {
    url: '/store/shifts/upcoming',
    mode: 'UPCOMING',
    icon: UpcomingShiftsIcon,
    text: 'Upcoming shifts',
    actionText: 'Upcoming shifts'
  },
  APPLICANTS: {
    url: '/store/applicants/review',
    mode: 'APPLICANTS',
    icon: ReviewApplicantsIcon,
    text: 'Review applicants',
    actionText: 'Review applicants'
  },
  PAYMENTS: {
    url: '/store/payments/complete',
    mode: 'PAYMENTS',
    icon: CompletePaymentsIcon,
    text: 'Complete payments',
    actionText: 'Complete payments'
  },
  PREFERENCES: {
    url: '/store/preferences/manage',
    mode: 'PREFERENCES',
    icon: StorePreferencesIcon,
    text: 'Store preferences',
    actionText: 'Manage preferences'
  },
  STORE: {
    url: '/store/profile/edit',
    mode: 'STORE',
    icon: StoreProfileIcon,
    text: 'Store profile',
    actionText: 'Edit store details'
  },
}

export const NAVIGATION_MODES = Object.keys(NAVIGATION_OPTIONS);
