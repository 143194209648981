import './DropdownMultiselect.scss';

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { JOB_OPTIONS, JOB_OPTIONS_ORDER } from '../../StoreProfileModalConstants';
import { useOutsideClick } from '../../../../../hooks/CommonHooks';

import DropdownMultiselectOption from './DropdownMultiselectOption';
import StoreProfileDropdownButton from '../StoreProfileDropdownButton';


const propTypes = {
  /* Options to choose from. */
  options: PropTypes.oneOf([JOB_OPTIONS_ORDER]).isRequired,

  /* Select an option. */
  select: PropTypes.func.isRequired,

  /* Array of selected options. */
  selectedOptions: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(JOB_OPTIONS.OPTOMETRY))).isRequired,
}

function DropdownMultiselect({ options, select, selectedOptions }) {
  const dropdownRef = useRef(null);
  const [open, setOpen] = useState(false);
  const dropdownOptions = options.map(({ key, displayText }) => {
    const selected = selectedOptions.includes(key);
    const onChange = () => select(key);
    return (
      <DropdownMultiselectOption
        key={`key-dropdown-multiselect-${key}`}
        displayText={displayText}
        onChange={onChange}
        selected={selected}
        optionKey={key}
      />
    )
  });
  const onClick = () => setOpen(!open);
  const multipleButtonText = selectedOptions.length === 0 ? '' : 'Multiple options selected';
  const buttonText = selectedOptions.length === 1
    ? options.find(({ key }) => key === selectedOptions[0]).displayText
    : multipleButtonText;
  useOutsideClick(dropdownRef, () => setOpen(false));
  return (
    <section className={'dropdown-multiselect-container'} ref={dropdownRef}>
      <StoreProfileDropdownButton buttonText={buttonText} onClick={onClick} open={open} />
      { open && (
        <section className={'dropdown'}>
          <ul className={'dropdown-list'}>
            {dropdownOptions}
          </ul>
        </section>
      )}
    </section>
  )
}

DropdownMultiselect.displayName = 'DropdownMultiselect';
DropdownMultiselect.propTypes = propTypes;
export default DropdownMultiselect;