import React, { useState, useEffect, useRef } from 'react';
import { ASSET_CDN_URL } from '../Constants';
import { useNavigate } from 'react-router-dom';
import { USER_ROLES, ABR_URL, AHPRA_URL, BUSINESS_DEFAULT_ROUTE } from '../Constants';
import useAuth from '../hooks/AuthHook.jsx';
import { postStripeOnboardingUrl, postCompleteOnboarding, postUpdatePassword, postAbnAndAhpra, postResetPasswordUpdate } from '../Api.js';
import './OnboardingPage.scss';

const PartyPopper = `${ASSET_CDN_URL}/graphics/party-popper.png`;

export const LoadingButton = ({ loading, onClick, classNames = [], text, buttonRef = null, disabled = false, backgroundColor = null, borderColor = null }) => {
  return (
    <button
      className={`${classNames.join(' ')} ${loading || disabled ? 'loading' : ''}`}
      onClick={onClick}
      disabled={loading || disabled}
      ref={buttonRef}
      style={{ backgroundColor, borderColor }}
    >
      {!loading ? text : 'Loading...'}
    </button>
  );
}

export const ChangePasswordContainer = ({ confirm, firstTime, text, token = null, emailAddress = null, role = null }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState('');
  const onChangePassword = (e) => {
    setPassword(e.target.value);
    setErrorText('');
  };
  const onChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    setErrorText('');
  }
  const changePasswordRef = useRef(null);
  const onConfirm = () => {
    if (password.length < 6) {
      setErrorText('Password length must be greater than 6 characters');
    }
    else if (password !== confirmPassword) {
      setErrorText('Passwords do not match');
    }
    else {
      setLoading(true);
      if (emailAddress !== null && token !== null) {
        postResetPasswordUpdate(emailAddress, password, role, token)
          .then(({ error }) => {
            setLoading(false);
            if (error) {
              setErrorText(error);
            }
            else {
              confirm(false);
            }
          })
          .catch(() => {
            setErrorText('An unknown error occurred. Please try again later!');
            setLoading(false);
            confirm(true);
          })
      }
      else {
        postUpdatePassword(password, firstTime)
          .then(() => {
            setLoading(false);
            confirm();
          })
          .catch(() => {
            setLoading(false);
            setErrorText('An unknown error occurred. Please try again later!')
          });
      }
    }
  };
  const changePasswordListener = (e) => e.key === 'Enter' && changePasswordRef.current.click();
  useEffect(() => {
    window.addEventListener('keypress', changePasswordListener);
    return () => window.removeEventListener('keypress', changePasswordListener);
  }, []);
  return (
    <section className={'passwords'}>
      <span className={'error-text'}>{errorText}</span>
      <section className={'password'}>
        <label className={'password-label'}>{'New Password'}</label>
        <input type={'password'} placeholder={'Enter your new password'} className={'password-input'} value={password} onChange={onChangePassword}/>
      </section>
      <section className={'password'}>
        <label className={'password-label'}>{'Confirm Password'}</label>
        <input type={'password'} placeholder={'Confirm your new password'} className={'password-input'} value={confirmPassword} onChange={onChangeConfirmPassword} />
      </section>
      <LoadingButton onClick={onConfirm} classNames={['reset-continue-button', 'very-dark']} loading={loading} text={text} buttonRef={changePasswordRef} />
    </section>
  );
}

const ChangePasswordOnboarding = ({ confirm }) => {
  return (
    <section className={'change-password-onboarding'}>
      <p className={'subtitle'}>{`To start off, let's improve your security by choosing a new password.`}</p>
      <p className={'subtitle'}>{`Your new password should be at least 6 characters long.`}</p>
      <ChangePasswordContainer confirm={confirm} firstTime={true} text={'Continue'} />
    </section>
  );
};

const ChangeLocumNumbersOnboarding = ({ confirm, profile }) => {
  const { setProfile } = useAuth();
  const [abn, setAbn] = useState(profile.abn);
  const [ahpra, setAhpra] = useState(profile.ahpra);
  const [loading, setLoading] = useState(false);

  const onConfirm = () => {
    postAbnAndAhpra(abn, ahpra)
      .then(() => {
        setProfile({ ...profile, abn, ahpra});
        setLoading(false);
        confirm();
      })
  };
  return (
    <section className={'locum-numbers-onboarding'}>
      <p className={'subtitle'}>{`To book shifts, you'll need a valid ABN and AHPRA number.`}</p>
      <p className={'subtitle'}>{`You can skip this step for now and add them to your profile later.`}</p>
      <p className={'subtitle'}>
        {'Visit the '}
        <a href={ABR_URL} target={'_blank'} rel={'noreferrer'}>{'Australian Business Register website'}</a>
        {' to apply for a new ABN number, if necessary.'}
      </p>
      <p className={'subtitle'}>
        {'Visit the '}
        <a href={AHPRA_URL} target={'_blank'} rel={'noreferrer'}>{'AHPRA website'}</a>
        {' to register your AHPRA number, if necessary.'}
      </p>
      <section className={'abn-and-ahpra'}>
        <section className={'number'}>
          <label className={'label'}>{'ABN'}</label>
          <input className={'number-input'} value={abn} onChange={(e) => setAbn(e.target.value)} />
        </section>
        <section className={'number'}>
          <label className={'label'}>{'AHPRA'}</label>
          <input className={'number-input'} value={ahpra} onChange={(e) => setAhpra(e.target.value)} />
        </section> 
        <section className={'buttons'}>
          <button className={'skip-button very-dark'} onClick={confirm}>
            {'Skip'}
          </button>
          <LoadingButton onClick={onConfirm} classNames={['continue-button', 'very-dark']} loading={loading} text={'Continue'} />
        </section>
      </section>
    </section>
  );
};

const StripeOnboarding = () => {
  const [loading, setLoading] = useState(false);
  const [stripeUrl, setStripeUrl] = useState(null);
  const [clickedOnboarding, setClickedOnboarding] = useState(false);
  useEffect(() => {
    postStripeOnboardingUrl(window.location.href).then(response => {
      setStripeUrl(response.url);
      setLoading(false);
    });
  }, []);
  const refreshClick = () => window.location.reload();
  const urlClick = () => {
    if (stripeUrl !== null) {
      window.open(stripeUrl);
      setClickedOnboarding(true);
    }
  };
  const onboardingButton = !clickedOnboarding
    ? <LoadingButton
        onClick={urlClick} classNames={['onboarding-button', 'very-light']} loading={loading} text={'Take me to Stripe onboarding'}
      /> 
    : <button className={'completed-button very-light'} onClick={refreshClick} disabled={loading}>
        {`Click here once you are done`}
      </button>;
  return (
    <section className={'stripe-onboarding'}>
      <p className={'subtitle'}>{`We partner with Stripe to give your moolah the security it deserves.`}</p>
      <p className={'subtitle'}>{`Follow the onboarding process below to enable Stripe for Locumly.`}</p>
      <section className={'stripe-onboarding'}>
        {onboardingButton}
      </section> 
    </section>
  );
}

const CompleteOnboarding = ({ completedSubtitle }) => {
  const { profile, setProfile } = useAuth();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const clickComplete = () => {
    setLoading(true);
    postCompleteOnboarding()
      .then(() => {
        if (profile.role === USER_ROLES.LOCUM) {
          setProfile({ ...profile, completedOnboarding: true });
          navigate('/find');
        }
        else {
          setProfile({ ...profile, completedOnboarding: true });
          navigate(BUSINESS_DEFAULT_ROUTE);
        }
        setLoading(false);
      });
  }
  return (
    <section className={'completed-onboarding'}>
      <p className={'subtitle'}>{'Great work! You have completed the Locumly onboarding!'}</p>
      <p className={'subtitle'}>{completedSubtitle}</p>
      <img src={PartyPopper} className={'party-popper'} alt={'Party popper'} />
      <LoadingButton
        onClick={clickComplete} classNames={['complete-onboarding-button', 'very-dark']} loading={loading} text={'Complete onboarding'}
      />
    </section>
  );
}

const getBusinessOnboardingView = (profile) => {
  if (!profile.firstPasswordUpdate) {
    return 'PASSWORD';
  }
  else {
    return 'COMPLETE';
  }
};

const BusinessOnboarding = ({ profile }) => {
  const stage = getBusinessOnboardingView(profile);
  const [view, setView] = useState(stage);
  const nextStageFromPassword = () => setView('COMPLETE');
  const completedSubtitle = `Let's help you find some Locums!`;
  return (
    <>
      { view === 'PASSWORD' && <ChangePasswordOnboarding confirm={nextStageFromPassword} /> }
      { view === 'COMPLETE' && <CompleteOnboarding completedSubtitle={completedSubtitle} /> }
    </>
  )
};

const getLocumOnboardingView = (profile) => {
  if (!profile.firstPasswordUpdate) {
    return 'PASSWORD';
  }
  else if (profile.abn.length === 0 || profile.ahpra.length === 0) {
    return 'NUMBERS';
  }
  else {
    return 'COMPLETE';
  }
};

const LocumOnboarding = ({ profile }) => {
  const { setProfile } = useAuth();
  const stage = getLocumOnboardingView(profile);
  const [view, setView] = useState(stage);
  const nextStageFromPassword = () => {
    setProfile({ ...profile, firstPasswordUpdate: true });
    setView('NUMBERS');
  };
  const nextStageFromNumbers = () => setView('COMPLETE');
  const nextStageFromStripe  = () => setView('COMPLETE');
  const completedSubtitle= `Let's go find you some shifts!`;
  return (
    <>
      { view === 'PASSWORD' && <ChangePasswordOnboarding confirm={nextStageFromPassword} /> }
      { view === 'NUMBERS' && <ChangeLocumNumbersOnboarding confirm={nextStageFromNumbers} profile={profile} /> }
      { view === 'STRIPE' && <StripeOnboarding confirm={nextStageFromStripe} /> }
      { view === 'COMPLETE' && <CompleteOnboarding completedSubtitle={completedSubtitle} /> }
    </>
  );
};

const OnboardingPage = () => {
  const { profile } = useAuth();
  const { firstName, role } = profile;
  const onboarding = role === USER_ROLES.LOCUM
    ? <LocumOnboarding profile={profile} />
    : <BusinessOnboarding profile={profile} />;
  return (
    <article className={'onboarding-page'}>
      <h1 className={'title'}>
        {'Welcome to Locumly, '}
        <span className={'colored'}>{firstName}</span>
        {'!'}
      </h1>
      {onboarding}
    </article>
  );
};

export default OnboardingPage;
