import './StoreProfileButton.scss';

import useStoreProfile from '../../../hooks/StoreProfileContext';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  /* Children component. */
  children: PropTypes.node.isRequired,

  /* Callback on click of this button. */
  onClick: PropTypes.func.isRequired,

  /* Additional class names for the store profile button. */
  additionalClassNames: PropTypes.arrayOf(PropTypes.string),

  /* True if button is disabled. */
  disabled: PropTypes.bool.isRequired,
};

const DEFAULT_BACKGROUND_COLOR = '#FFFFFF';

function StoreProfileButton({ onClick, children, additionalClassNames = [], disabled = false }) {
  const [hovered, setHovered] = useState(false);
  const disabledClass = disabled ? 'disabled' : 'very-light';
  const { storeProfile } = useStoreProfile();
  const { theme } = storeProfile;
  const onMouseEnter = () => setHovered(true);
  const onMouseLeave = () => setHovered(false);
  const { primaryHexColor, secondaryHexColor } = theme;
  const backgroundColor = hovered ? secondaryHexColor : DEFAULT_BACKGROUND_COLOR;
  return (
    <button
      className={`store-profile-button ${disabledClass} ${additionalClassNames.join(' ')}`}
      onClick={onClick}
      type={'button'}
      disabled={disabled}
      style={{ borderColor: primaryHexColor, color: primaryHexColor, backgroundColor }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </button>
  )
}

StoreProfileButton.defaultProps = { additionalClassNames: [], disabled: false };
StoreProfileButton.displayName = 'StoreProfileButton';
StoreProfileButton.propTypes = propTypes;
export default StoreProfileButton;