import React, { useState, useRef, useEffect } from 'react';
import { ASSET_CDN_URL, USER_ROLES, DEFAULT_PERSON_AVATAR } from '../Constants';
import useAuth from '../hooks/AuthHook.jsx';
import { useNavigate, useLocation } from 'react-router-dom';
import NotificationsAndMessagesSidebar from './NotificationsAndMessagesSidebar.jsx';
import { useOutsideClick, useMediaQuery } from '../hooks/CommonHooks.jsx';
import EmojiPicker from 'emoji-picker-react';
import './MessagesPage.scss';
import { postSendMessage, postSeenMessage } from '../Api.js';

const EmojiIcon = `${ASSET_CDN_URL}/icons/emoji-icon.png`;
const SendMessageIcon = `${ASSET_CDN_URL}/icons/send-message-icon.svg`;
const DropdownArrow = `${ASSET_CDN_URL}/dropdown/shift-rec-drop-arrow.svg`;

const SECONDS_IN_A_MINUTE = 60;
const SECONDS_IN_AN_HOUR = 60 * SECONDS_IN_A_MINUTE;
const SECONDS_IN_A_DAY = 24 * SECONDS_IN_AN_HOUR;
const SECONDS_IN_A_WEEK = 7 * SECONDS_IN_A_DAY;

const Attachment = ({ file, deleteAttachment }) => {
  return (
    <span className={'attachment'}>
      {file.name}
      <span className={'delete-container'}>
        <button className={'delete'} onClick={deleteAttachment}>{'×'}</button>
      </span>
    </span>
  );
}

const ChatInput = ({ chat, setSelected, isHidden }) => {
  const { profile, chats, setChats } = useAuth();
  const [message, setMessage] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [showEmojiSelection, setShowEmojiSelection] = useState(false);
  const showEmojiSelectionClick = () => setShowEmojiSelection(!showEmojiSelection);
  const onEmojiClick = (_, emoji) => setMessage(`${message}${emoji.emoji}`);
  const sendMessage = () => {
    if (attachments.length === 0) {
      if (!message || message.length === 0 || message.replace(/\s/g, '').length === 0) {
        return;
      }
    }
    const isBusiness = profile.role === USER_ROLES.BUSINESS;
    const { locumId, storeId, businessId } = chat;
    const receiverId = isBusiness ? locumId : businessId
    const timestamp = new Date().getTime();
    const messageText = message;
    const files = [...attachments];
    const newMessage = {
      message,
      senderId: profile._id,
      receiverId,
      timestamp,
      attachment: [...attachments.map((file, index) => ({ _id: `${index}-temp`, url: window.URL.createObjectURL(file), name: file.name, size: file.size }))] 
    };
    setAttachments([]);
    setMessage('');
    setSelected({...chat, chatHistory: [ ...chat.chatHistory, { ...newMessage, loading: true } ]});
    postSendMessage(locumId, businessId, storeId, messageText, files)
      .then((response) => {
        setSelected({ ...response });
        const newChats = chats.filter(chat => {
          const sameLocum = chat.locumId === response.locumId;
          const sameStore = chat.storeId === response.storeId;
          const sameBusiness = chat.businessId === response.businessId;
          return !(sameLocum && sameStore && sameBusiness);
        });
        newChats.push(response);
        setChats(newChats);
      })
      .catch(() => {});
  }
  const emojiRef = useRef(null);
  useOutsideClick(emojiRef, () => setShowEmojiSelection(false));
  const messageRef = useRef(null);
  const messageListener = (e) => {
    return e.key === 'Enter' && !e.shiftKey && messageRef.current.click()
  };
  const onKeyDownChat = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      messageRef.current.click();
    }
  }
  const onChangeChat = (e) => {
    e.preventDefault();
    setMessage(e.target.value);
  };
  const onChangeAttachment = (e) => {
    const file = e.target.files[0];
    setAttachments([...attachments, file]);
  };

  const removeAttachment = (index) => setAttachments(attachments.filter((_, i) => i !== index));
  const attachmentsList = attachments.map((file, index) => <Attachment file={file} deleteAttachment={() => removeAttachment(index)} key={`key-${file.name}`} />);
  useEffect(() => {
    window.addEventListener('keypress', messageListener);
    return () => window.removeEventListener('keypress', messageListener);
  }, []);
  return (
    <section className={`chat-input-container ${isHidden ? 'hidden' : ''}`}>
      <section className={'chat-bar'}>
        <button className={'attachment-button'}>
          <label for={'upload-file'} className={'add-attachment'}>{'+'}</label>
          <input type={'file'} id={'upload-file'} name={'upload-file'} accept={'*'} onChange={onChangeAttachment} />
        </button>
        <section className={'inputs'}>
          <section className={'attachments-container'}>
            { attachments.length > 0 && <section className={'attachments'} style={{ bottom: `${25 + 28 * (attachments.length - 1)}px` }}>{attachmentsList}</section>}
          </section>
          <textarea
            class={message.length ? 'chat-input chat-input-content' : 'chat-input'}
            placeholder={'Type a message...'}
            value={message}
            onChange={onChangeChat}
            onKeyDown={onKeyDownChat}
          />
        </section>
        <section className={'chat-emoji-picker'} ref={emojiRef}>
          <button className={'emoji-button'} onClick={showEmojiSelectionClick}>
            <img className={'emoji-icon'} src={EmojiIcon} alt={'Emoji Icon'} />
          </button>
          { showEmojiSelection && 
            <section className={'emoji-picker-container'}>
              <EmojiPicker onEmojiClick={onEmojiClick} preload={true} native={true} disableAutoFocus={true} />
              <span className={'triangle'} />
            </section>
          }
        </section>
        <button className={'send-button'} onClick={sendMessage} ref={messageRef}>
          <img className={'send-message-icon'} src={SendMessageIcon} alt={'Send Message Icon'} />
        </button>
      </section>
    </section>
  );
};

const isSameYear = (date1, date2) => date1.getFullYear() === date2.getFullYear();
const isSameDay = (date1, date2) => {
  return isSameYear(date1, date2) && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
}
const isYesterday = (date1, date2) => {
  return date1.toDateString() === date2.toDateString();
}

const getDatestampDisplay = (timestamp) => {
  const currentDate = new Date();
  const timestampDate = new Date(timestamp);
  const currentTime = currentDate.getTime();
  if (isSameDay(currentDate, timestampDate)) {
    return 'Today';
  }
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  if (isYesterday(yesterday, timestampDate)) {
    return 'Yesterday';
  }
  const timeDiff = currentTime - timestamp;
  if (timeDiff < SECONDS_IN_A_WEEK * 1000) {
    return timestampDate.toLocaleString('en-AU', { weekday: 'short'});
  }
  else if (isSameYear(currentDate, timestampDate)) {
    return timestampDate.toLocaleString('en-AU', { month: 'short', day: 'numeric'});
  }
  else {
    return timestampDate.toLocaleString('en-AU', { year: 'numeric', month: 'short', day: 'numeric'});
  }
}

const getTimestampDisplay = (timestamp) => {
  const timestampDate = new Date(timestamp);
  return timestampDate.toLocaleString('en-AU', { hour: 'numeric', hour12: true, minute: 'numeric' });
}

const ChatMessage = ({ chatMessage, senderId }) => {
  const { receiverId, message, timestamp } = chatMessage;
  return (
    <div className={'chat-msg-container'}>
      <span className={`chat-message ${receiverId === senderId ? 'receiver' : 'sender'} `}>
        {message}
      </span>
      <ChatTimestamp 
        timestamp={timestamp} isReceiver={receiverId === senderId} key={`timestamp-${timestamp}`}
      />
    </div>
  );
}

const ChatReceiverName = ({ firstName }) => {
  return <span className={'chat-receiver-name'} >{firstName}</span>
}

const ChatTimestamp = ({ timestamp, isReceiver }) => {
  const timestampDisplay = getTimestampDisplay(timestamp);
  return <span className={`chat-timestamp ${isReceiver ? 'receiver' : 'sender'}`}>{timestampDisplay}</span>;
}

const ChatDatestamp = ({ timestamp }) => {
  const timestampDisplay = getDatestampDisplay(timestamp);
  return <span className={'chat-datestamp'}>{timestampDisplay}</span>;
}

const ChatAttachment = ({ receiverId, attachment, senderId, timestamp }) => {
  const { size, url, fileName } = attachment;
  let sizeDisplay = `${size} B`;
  if (size >= 1000 && size < 1000 * 1000) {
    sizeDisplay = `${(size / 1000).toFixed(1)} KB`;
  }
  else {
    sizeDisplay = `${(size / (1000 * 1000)).toFixed(1)} MB`;
  }
  return (
    <div className={'chat-msg-container'}>
      <span className={`chat-attachment ${receiverId === senderId ? 'receiver' : 'sender'}`}>
        <a href={url} className={`${url === null ? 'loading' : ''}`}>
          {fileName}
        </a>
        <span className={'size'}>
          {sizeDisplay}
        </span>
      </span>
      <ChatTimestamp 
        timestamp={timestamp} isReceiver={receiverId === senderId} key={`timestamp-${timestamp}`}
      />
    </div>
  );

}

const ChatHistory = ({ chatHistory, senderId, firstName }) => {
  const history = [];
  const chatHistoryRef = useRef(null);
  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scroll(0, chatHistoryRef.current.scrollHeight);
    }
  });
  if (chatHistory.length > 0) {
    const chatMessage = chatHistory[0];
    const chatMessageKey =`key-${chatMessage.locumId}-${chatMessage.businessId}-${chatMessage.storeId}-${chatMessage.timestamp}`;
    history.push(<ChatDatestamp timestamp={chatMessage.timestamp} key={`timestamp-${chatMessage.timestamp}`}/>);
    if (chatMessage.receiverId === senderId) {
      history.push(<ChatReceiverName firstName={firstName} />)
    }
    if (chatMessage.attachments && chatMessage.attachments.length > 0) {
      chatMessage.attachments.forEach(attachment => {
        history.push(
          <ChatAttachment
            receiverId={chatMessage.receiverId}
            attachment={attachment}
            timestamp={chatMessage.timestamp}
            senderId={senderId}
            key={`message-${attachment._id}`}
          />
        );
      }); 
    }
    if (chatMessage.message.length > 0 && chatMessage.message.replace(/\s/g, '').length > 0) {
      history.push(<ChatMessage chatMessage={chatMessage} senderId={senderId} key={chatMessageKey} firstName={firstName} />);
    }
  }
  for (let i = 1; i < chatHistory.length; i++) {
    const chatMessage = chatHistory[i];
    const prevChatMessage = chatHistory[i - 1];
    const chatMessageKey =`key-${chatMessage.locumId}-${chatMessage.businessId}-${chatMessage.storeId}-${chatMessage.timestamp}`;
    if (isSameDay(new Date(chatMessage.timestamp), new Date(prevChatMessage.timestamp))) {
      if (prevChatMessage.receiverId !== senderId && chatMessage.receiverId === senderId) {
        history.push(<ChatReceiverName firstName={firstName} />)
      }
      if (chatMessage.attachments && chatMessage.attachments.length > 0) {
        chatMessage.attachments.forEach(attachment => {
          history.push(
            <ChatAttachment
              receiverId={chatMessage.receiverId}
              attachment={attachment}
              timestamp={chatMessage.timestamp}
              senderId={senderId}
              key={`message-${attachment._id}`}
            />
          );
        }); 
      }
      if (chatMessage.message.length > 0 && chatMessage.message.replace(/\s/g, '').length > 0) {
        history.push(<ChatMessage chatMessage={chatMessage} senderId={senderId} key={chatMessageKey} firstName={firstName} />);
      }
    }
    else {
      history.push(<ChatDatestamp timestamp={chatMessage.timestamp} key={`timestamp-${chatMessage.timestamp}`} />);
      if (chatMessage.receiverId === senderId) {
        history.push(<ChatReceiverName firstName={firstName} />)
      }
      if (chatMessage.attachments && chatMessage.attachments.length > 0) {
        chatMessage.attachments.forEach(attachment => {
          history.push(
            <ChatAttachment
              receiverId={chatMessage.receiverId}
              attachment={attachment}
              timestamp={chatMessage.timestamp}
              senderId={senderId}
              key={`message-${attachment._id}`}
            />
          );
        }); 
      }
      if (chatMessage.message.length > 0 && chatMessage.message.replace(/\s/g, '').length > 0) {
        history.push(<ChatMessage chatMessage={chatMessage} senderId={senderId} key={chatMessageKey} firstName={firstName} />);
      }
    }
  }
  return <section className={'chat-history'} ref={chatHistoryRef}>{history}</section>;
}

const ChatRoom = ({ chat, senderId, setSelected, isHidden }) => {
  return (
    <section className={'chat-room'}>
      {!!chat && <ChatHistory chatHistory={chat.chatHistory} senderId={senderId} firstName={chat.firstName} />}
      {!!chat && <ChatInput chat={chat} senderId={senderId} setSelected={setSelected} isHidden={isHidden} />}
    </section>
  );
}

const MessagesPage = () => {
  const { profile, chats, setChats } = useAuth();
  const { notifications } = profile;
  const navigate = useNavigate();
  const { state } = useLocation();
  const isMobile = useMediaQuery('(max-width: 980px)');
  let selectedChat = null;
  let firstChat = false;
  if (state && state.locum && state.store) {
    const { locum, store } = state;
    const { avatar, firstName, lastName, _id } = locum;
    const name = `${firstName} ${lastName}`;
    let chat = chats.find(message => message.locumId === locum._id && message.storeId === store._id);
    selectedChat = chat 
      ? chat
      : { 
          chatHistory: [],
          name,
          firstName,
          lastName,
          shortName: firstName,
          locumId: _id,
          businessId: profile._id,
          storeId: store._id,
          avatar: avatar ? avatar : DEFAULT_PERSON_AVATAR,
          storeName: store.name,
          role: USER_ROLES.LOCUM,
        };
    firstChat = chat ? false : true;
  }
  else if (state && state.business && state.store) {
    const { business, store } = state;
    const { firstName, lastName, _id } = business;
    const name = `${firstName} ${lastName}`;
    let chat = chats.find(message => message.businessId === _id && message.storeId === store._id);
    selectedChat = chat 
      ? chat
      : { 
          chatHistory: [],
          name,
          firstName,
          lastName,
          shortName: firstName,
          locumId: profile._id,
          businessId: _id,
          storeId: store._id,
          avatar: store.avatar,
          storeName: store.name,
          role: USER_ROLES.BUSINESS,
        };
    firstChat = chat ? false : true;
  }
  else if (state && state.chat) {
    selectedChat = chats.find(
      chat => 
        state.chat.locumId === chat.locumId
        && state.chat.businessId === chat.businessId
        && state.chat.storeId === chat.storeId);
  }
  const hasNew = selectedChat && selectedChat.chatHistory ? selectedChat.chatHistory.filter(message => message.new).length > 0 : false;
  const messages = selectedChat && firstChat ? [selectedChat, ...chats] : chats;

  useEffect(() => {
    if (selectedChat && hasNew) {
      const newChats = chats.map(chat => {
        if (chat.locumId === selectedChat.locumId && chat.businessId === selectedChat.businessId && chat.storeId === selectedChat.storeId) {
          return { ...chat, chatHistory: chat.chatHistory.map(message => ({ ...message, new: false })) };
        }
        return chat;
      });
      setChats(newChats);
      postSeenMessage(selectedChat.locumId, selectedChat.businessId, selectedChat.storeId);
    }
  }, [selectedChat, hasNew, profile, chats, setChats]);

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, [])

  const setSelected = (chat) => navigate('/user/messages', { state: { chat }, replace: true });
  const [open, setOpen] = useState(state && state.hasOwnProperty('open') ? state.open : true);
  
  useEffect(() => {
    if (!state || (Object.keys(state).length === 1 && state.hasOwnProperty('open'))) {
      setOpen(true);
    }
  }, [state])

  const chatRoomPerson = selectedChat ? `${selectedChat.firstName} ${selectedChat.lastName}` : '';
  const chatRoomStore = selectedChat ? selectedChat.storeName : '';

  // Resizes shift summary to fit right below month component.
  useEffect(() => {
    if (!open && isMobile) {
      const nav = document.getElementById('navbar-id');
      const header = document.getElementById('messages-header');
      header.style.height = `${nav.offsetHeight}px`;
    }
  }, [open, isMobile])

  return (
    <article className={'messages-page'}>
      {(!open && isMobile) && <div className={'shift-header'}>
        <div className={'chat-room-title-container'} id={'messages-header'} onClick={() => setOpen(!open)}>
          <img src={DropdownArrow} className={'chat-title-back'} alt={'back button'} />
          <div className={'chat-room-title'}>
            <span className={'chat-person'}>{chatRoomPerson}</span>
            <span className={'chat-store'}>{chatRoomStore}</span>
          </div>
        </div>
      </div>}
      <section className={`messages-container`}>
        {(open || !isMobile) && 
          <section className={`sidebar-container ${open ? 'viewing-message' : 'hidden'}`}>
            <NotificationsAndMessagesSidebar
              isNotification={false}
              notifications={notifications ? notifications : []}
              messages={messages}
              selected={selectedChat}
              setSelected={setSelected}
              senderId={profile._id}
              setOpen={setOpen}
            />
          </section>
        }
        {!open && 
          <section className={`chat-room-container ${open ? '' : 'viewing-message'}`}>
            {!isMobile && <section className={'chat-room-title'}>
              <span className={'chat-person'}>{chatRoomPerson}</span>
              {!open && <span className={'divider'}></span>}
              <span className={'chat-store'}>{chatRoomStore}</span>
            </section>}
            <ChatRoom chat={selectedChat} senderId={profile._id} setSelected={setSelected} isHidden={open} />
          </section>
        }
      </section>
    </article>
  );
};

export default MessagesPage;