import React, { useState } from 'react';
import useAuth from '../hooks/AuthHook.jsx';
import { ShiftListViewer } from '../business/store/UpcomingShiftsPage.jsx';
import BackArrowButton from '../common/BackArrowButton.jsx';
import Shift from './Shift.jsx';
import LocumPage from './LocumPage.jsx';
import { LOCUM_NAVIGATION_BUTTONS } from './LocumNavigationSidebar.jsx';
import LocumShiftCard from './LocumShiftCard.jsx';
import { SHIFT_STATUSES } from '../Constants.js';
import './LocumUpcomingShiftsPage.scss';

const PRODA_URL = 'https://proda.humanservices.gov.au/prodalogin/pages/public/login.jsf';
export const TUTORIAL_URL = '/tutorials/additional-provider-numbers';

const UpcomingTitleAndSubtitle = () => (
  <>
    <h1 className={'upcoming-shifts-title'}>{'Upcoming Shifts'}</h1>
    <p className={'upcoming-shifts-subtitle'}>
      {`We know you've got lots going on.`}
    </p>
    <p className={'upcoming-shifts-subtitle'}>
      {`We'll let you know when the store accepts your shift request or if they went in another direction.`}
    </p>
  </>
);

const SelectedShiftTitleAndSubtitle = ({ status }) => {
  let shiftText = '';
  let subShiftText = '';
  if (SHIFT_STATUSES.UNFILLED.key === status) {
    shiftText = `This shift has been requested, we're awaiting the store's decision`;
    subShiftText = `Hang tight! We'll let you know if the store accepts your request or if they go in another direction.`
  }
  else if (SHIFT_STATUSES.FILLED.key === status) {
    shiftText = `Congratulations! The store has chosen you as the Locum for this shift. Your next step is to fill out your Medicare Provider Number for the store.`;
    subShiftText = 
      <>
        {`You can get a Medicare Provider Number by logging into your PRODA account `}
        {<a href={PRODA_URL} target={'_blank'} rel={'noreferrer'}>{'here'}</a>}
        {'. Follow our '}
        {<a href={TUTORIAL_URL} target={'_blank'} rel={'noreferrer'}>{'quick and easy custom tutorial'}</a>}
        {' on how to get your Medicare Provider Number for this shift.'}
      </>
  }
  else {
    shiftText = 'Great work! This shift has been confirmed and you are good to go.';
    subShiftText = 
      'Easy tip for a good shift: stay in close contact with the store representative on Locumly by sending a message to them prior to the shift start time.';
  }
  return (
    <>
      <h1 className={'upcoming-shifts-title'}>{'Your Selected Shift'}</h1>
      <p className={'upcoming-shifts-subtitle'}>{shiftText}</p>
      <p className={'upcoming-shifts-subtitle'}>{subShiftText}</p>
    </>
  );
}

export const LocumShiftViewer = ({ shift, select }) => {
  const [showingStoreDetails, setShowingStoreDetails] = useState(false);
  const returnToShifts = () => {
    select(null);
    setShowingStoreDetails(false);
  }

  const returnToShift = () => {
    setShowingStoreDetails(false);
  }
  return (
    <section className={'view-shift-container'}>
      <section className={'button-containers'}>
        { showingStoreDetails 
            ? <BackArrowButton onClick={returnToShift} text={'Back to shift'} />
            : <BackArrowButton onClick={returnToShifts} text={'Back to my shifts'} />
        }
      </section>
      <Shift shift={shift} select={select} isViewer={true} showingStoreDetails={showingStoreDetails} setShowingStoreDetails={setShowingStoreDetails} />
    </section>
  );
};

const LocumUpcomingShiftsPage = () => {
  const { profile } = useAuth();
  const emptyText = 'No upcoming shifts';
  const shifts = profile.shifts
    .filter(shift => shift.status === SHIFT_STATUSES.CONFIRMED.key
        || shift.status === SHIFT_STATUSES.FILLED.key
        || (shift.status === SHIFT_STATUSES.UNFILLED.key && shift.startTime > new Date().getTime()))
    .sort((e1, e2) => e1.startTime - e2.startTime);
  const [shift, setShift] = useState(null);
  return (
    <LocumPage mode={LOCUM_NAVIGATION_BUTTONS.UPCOMING.mode}>
      <article className={'upcoming-shifts-page'}>
        {shift === null && <UpcomingTitleAndSubtitle />}
        {shift !== null && <SelectedShiftTitleAndSubtitle status={shift.status} /> }
        {shift === null &&
          <section className={'shift-viewer-container'}>
            <ShiftListViewer
              shifts={shifts}
              CardComponent={LocumShiftCard}
              emptyText={emptyText}
              select={setShift}
            />
          </section>
        }
        {shift !== null && 
          <LocumShiftViewer shift={shift} select={setShift} />
        }
      </article>
    </LocumPage>
  );
}

export default LocumUpcomingShiftsPage;
