import './JoinTeamSection.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { CASUAL_SHIFT_PROPS, PERMANENT_SHIFT_PROPS } from '../../StoreProfileProps';

import StoreProfileSection from './StoreProfileSection';
import StoreCasualShiftList from '../shared/CasualShiftList';
import StorePermanentShiftList from '../shared/PermanentShiftList';

const propTypes = {
  /* Permanent shifts. */
  permanentShifts: PropTypes.arrayOf(PropTypes.shape(PERMANENT_SHIFT_PROPS)).isRequired,

  /* Casual shifts. */
  casualShifts: PropTypes.arrayOf(PropTypes.shape(CASUAL_SHIFT_PROPS)).isRequired,

  /* Store name. */
  storeName: PropTypes.string.isRequired,
}

function JoinTeamSection({ permanentShifts, casualShifts, storeName }) {
  return (
    <StoreProfileSection
      titlePosition={'center'}
      sectionTitle={'Join our team'}
      additionalClassNames={['join-team']}
    >
      <StorePermanentShiftList shifts={permanentShifts} />
      <StoreCasualShiftList shifts={casualShifts} storeName={storeName} />
    </StoreProfileSection>
  )
}

JoinTeamSection.displayName = 'JoinTeamSection';
JoinTeamSection.propTypes = propTypes;
export default JoinTeamSection;
