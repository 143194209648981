import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const IGNORED_PATHNAMES = ['/user/messages', '/user/notifications'];

const ScrollToTopHook = () => {
  const location = useLocation();
  useEffect(() => {
    if (!IGNORED_PATHNAMES.includes(location.pathname)) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (null);
}

export default ScrollToTopHook;