import './SubscribeToStoreModal.scss';

import PropTypes from 'prop-types'
import React from 'react';

import StoreProfileModalWrapper from '../shared/StoreProfileModalWrapper';
import SubscribeToStoreForm from './SubscribeToStoreForm';

const propTypes = {
  /* Sets the modal open. */
  setOpen: PropTypes.func.isRequired,

  /* Sets if the store has been subscribed. */
  setSubscribed: PropTypes.func.isRequired,

  /* Store name. */
  storeName: PropTypes.string.isRequired,

  /* Store id. */
  storeId: PropTypes.string.isRequired,
};

function SubscribeToStoreModal({ setOpen, setSubscribed, storeName, storeId }) {
  const closeModal = () => setOpen(false);
  return (
    <StoreProfileModalWrapper
      closeModal={closeModal}
      additionalClassNames={['subscribe']}
      title={'Subscribe to be notified for new jobs'}
    >
      <SubscribeToStoreForm
        closeModal={closeModal}
        setSubscribed={setSubscribed}
        storeName={storeName}
        storeId={storeId}
      />
    </StoreProfileModalWrapper> 
  )
}

SubscribeToStoreModal.displayName = 'SubscribeToStoreModal';
SubscribeToStoreModal.propTypes = propTypes;
export default SubscribeToStoreModal;