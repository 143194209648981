import React, { useEffect } from 'react';
import { ASSET_CDN_URL } from '../Constants';
import { useMediaQuery } from '../hooks/CommonHooks.jsx';
import './StaffingAssistantPage.scss';

const TimerImg = `${ASSET_CDN_URL}/marketing/timer-image.svg`;
const LossImg = `${ASSET_CDN_URL}/marketing/loss-image.svg`;
const PhoneImg = `${ASSET_CDN_URL}/marketing/phone-image.svg`;
const PriceImg = `${ASSET_CDN_URL}/marketing/price-image.svg`;
const AddShiftImg = `${ASSET_CDN_URL}/marketing/add-shifts.svg`;
const AddLocumImg = `${ASSET_CDN_URL}/marketing/add-locum-image.svg`;
const FreeImg = `${ASSET_CDN_URL}/marketing/free-image.svg`;
const NetworkImg = `${ASSET_CDN_URL}/marketing/network-image.svg`;

const SmallCard = ({ image, headerText, children }) => {
  return (
    <section className={'small-card'}>
      <img src={image} alt={''} />
      <h3 className={'header-text'}>{headerText}</h3>
      {children}
    </section>
  );
}

const MainCard = ({ image, headerText, children }) => {
  return (
    <section className={'main-card'}>
      <img src={image} alt={''} />
      <h3 className={'header-text'}>{headerText}</h3>
      {children}
    </section>
  );
}

const MobileStartContent = ({ onClick }) => {
  return (
    <section className={'start-content'}>
      <section className={'side-text'}>
        <h3 className={'side-title'}>
          {'24/7 temporary staffing assistant, in your pocket.'}
        </h3>
        <p className={'side-content'}>
          {'Save time and cost by instantly reaching your preferred contacts. Leverage our deepest on-demand talent pool, only when you truly need it.'}
        </p>
        <img src={TimerImg} alt={'Save time on temporary staffing'} />
        <button className={'free-trial-button very-dark'} onClick={onClick}>{'Start 30-day Free Trial'}</button>
      </section>
  </section>
  )
}

const StartContent = ({ onClick }) => {
  return (
    <section className={'start-content'}>
      <section className={'side-text'}>
        <h3 className={'side-title'}>
          {'24/7 temporary staffing assistant, in your pocket.'}
        </h3>
        <p className={'side-content'}>
          {'Save time and cost by instantly reaching your preferred contacts. Leverage our deepest on-demand talent pool, only when you truly need it.'}
        </p>
        <button className={'free-trial-button very-dark'} onClick={onClick}>{'Start 30-day Free Trial'}</button>
      </section>
      <img src={TimerImg} alt={'Save time on temporary staffing'} />
  </section>
  )
}

const BannerContent = ({ bannerTitle, bannerText}) => (
  <section className={'banner-content'}>
    <h2 className={'header-title'}>{bannerTitle}</h2>
    <p className={'header-text'}>{bannerText}</p>
  </section>
);

const StepText = ({ additionalClassNames, stepHeader, stepContent }) => (
  <section className={`step-text ${additionalClassNames.join(' ')}`}>
    <h4 className={'step-header'}>{stepHeader}</h4>
    <p className={'step-paragraph'}>{stepContent}</p>
  </section>
)

const StepImage = ({ image, altText }) => (
  <section className={'step-image'}>
    <img src={image} alt={altText} />
  </section>
);

const StepCounter = ({ stepNumber }) => (
  <section className={'step-number-container'}>
    <span className={'step-number'}>{stepNumber}</span>
  </section>
)

const SIGN_UP_LINK = "https://z2m4caj4dl7.typeform.com/to/rjoRNmaA";
const REQUEST_DEMO_LINK = "https://calendly.com/locumly/intro-to-locum-ly-solving-temporary-staffing";

const MobileStaffingAssistantPage = () => {
  const signUp = () => window.open(SIGN_UP_LINK);
  const requestDemo = () => window.open(REQUEST_DEMO_LINK);
  return (
    <article className={'staffing-assistant-page mobile'}>
      <MobileStartContent onClick={signUp} />
      <BannerContent
        bannerTitle={'No effort, at minimal cost to you'} 
        bannerText={`Here's why the Locumly temporary staffing assistant is something special`}
      />
      <section className={'card-content'}>
        <section className={'card-row'}>
          <MainCard image={PhoneImg} headerText={`Using Locumly's virtual temp staffing assistant`}>
            <p>{'All your preferred workers are immendiately notified of new staffing needs. Book them at no cost.'}</p>
            <p>{'Only when you need it, instantly access the deepest on-demand pool of workers for your business.'}</p>
          </MainCard>
          <SmallCard image={LossImg} headerText={'No staffing assistance'}>
            <p>{'Potential to miss out on $3,000+ revenue per day if appointment books have to be closed due to a lack of staff.'}</p>
            <p>{'Up to 2 hours playing phone tag, sending 10+ back & forth messages just to be told no one is available.'}</p>
          </SmallCard>
          <SmallCard image={PriceImg} headerText={'Purely relying on external staffing'}>
            <p>{'Not fully leveraging your preferred worker network to fill shifts where you can, leading to incurring unnecessary costs.'}</p>
          </SmallCard>
        </section>
      </section>
      <BannerContent 
        bannerTitle={'Get the best of both worlds'} 
        bannerText={`Immediately notify and book your preferred workers for temporary shifts at no cost. If no one is available, access the deepest talent pool on-demand to fill your shifts at a fraction of what recruitment agencies charge.`}
      />
      <section className={'advertising-block'}>
        <section className={'step-column'}>
          <StepCounter stepNumber={1} />
          <StepImage image={AddLocumImg} altText={''} />
          <StepText
            additionalClassNames={['one']}
            stepHeader={'Add your preferred worker'}
            stepContent={`Once your superstar team accepts your invite, they are immediately notified via SMS (96% open rate) whenever you need a shift filled. It costs nothing to book them in!`}
          />
          <StepCounter stepNumber={2} />
          <StepImage image={AddShiftImg} altText={''} />
          <StepText
            additionalClassNames={['two']}
            stepHeader={'Add shifts, and watch the requests come in'}
            stepContent={`Never worry about playing phone tag, or sending back & forth texts again. Simply add your shift and relax. If no preferred workers are available, you can choose when to add it to the public marketplace.`}
          />
          <StepCounter stepNumber={3} />
          <StepImage image={FreeImg} altText={''} />
          <StepText
            additionalClassNames={['three']}
            stepHeader={'Book your preferred workers for free'}
            stepContent={`Your preferred workers are immediately notified by SMS. Book as many shifts as you'd like for free. Choose when you'd like to add the remaining shifts onto the public marketplace.`}
          />
          <StepCounter stepNumber={4} />
          <StepImage image={NetworkImg} altText={''} />
          <StepText
            additionalClassNames={['four']}
            stepHeader={`Fill remaining shifts using our on-demand marketplace`}
            stepContent={`If shifts are not taken up by preferred workers, you can automatically access the deepest pool of on-demand workers. You only pay for what you use, at a fraction of the cost of recruitment agencies.`}
          />
        </section>
      </section>
      <section className={'sign-up-block'}>
        <section className={'tiny-card'}>
          <h3 className={'header-text strike-through'}>{'$12/month per store'}</h3>
          <h4 className={'header-subtitle'}>{'Free for 30 days'}</h4>
          <ul className={'pitch-list'}>
            <li className={'pitch-item'}>{'Add up to 4 preferred workers'}</li>
            <li className={'pitch-item'}>{'Book preferred workers for free'}</li>
            <li className={'pitch-item'}>{'Notify new shifts automatically'}</li>
            <li className={'pitch-item'}>{'Access on-demand pool'}<br />{'(book non-preferred workers for free)'}</li>
          </ul>
        </section>
        <section className={'free-trial'}>
          <h3 className={'header-text'}>{'Try it for free'}</h3>
          <p className={'end-content'}>{'No payment details required. Invite your preferred workers and experience our Virtual Assistant for 30 days.'}</p>
          <button className={'start-trial-button very-dark'} onClick={signUp}>{'Start 30-day Free Trial'}</button>
        </section>
      </section>
      <section className={'want-to-know-more-block'}>
        <h3 className={'header-text'}>{'Want to know more?'}</h3>
        <h4 className={'subtitle'}>{`Schedule a time and we'll tell you all about it!`}</h4>
        <button className={'request-demo-button very-dark'} onClick={requestDemo}>{'Request a demo'}</button>
      </section>
    </article>
  )
}

const TypeformStaffingAssistantPopover = () => (
  <>
    <div
      data-tf-popover={'rjoRNmaA'}
      data-tf-button-color={'#40806D'}
      data-tf-tooltip={'Start 30 day free trial'}
      data-tf-chat
      data-tf-medium={'snippet'}
    />
  </>
)

const WebStaffingAssistantPage = () => {
  const requestDemo = () => window.open(REQUEST_DEMO_LINK);
  const signUp = () => window.open(SIGN_UP_LINK);
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//embed.typeform.com/next/embed.js';
    script.async = true;
    document.body.appendChild(script);
  }, [])
  return (
    <article className={'staffing-assistant-page web'}>
      <TypeformStaffingAssistantPopover />      
      <StartContent onClick={signUp} />
      <BannerContent
        bannerTitle={'No effort, at minimal cost to you'} 
        bannerText={`Here's why the Locumly temporary staffing assistant is something special`}
      />
      <section className={'card-content'}>
        <section className={'card-row'}>
          <SmallCard image={LossImg} headerText={'No staffing assistance'}>
            <p>{'Potential to miss out on $3,000+ revenue per day if appointment books have to be closed due to a lack of staff.'}</p>
            <p>{'Up to 2 hours playing phone tag, sending 10+ back & forth messages just to be told no one is available.'}</p>
          </SmallCard>
          <MainCard image={PhoneImg} headerText={`Using Locumly's virtual temp staffing assistant`}>
            <p>{'All your preferred workers are immendiately notified of new staffing needs. Book them at no cost.'}</p>
            <p>{'Only when you need it, instantly access the deepest on-emand pool of workers for your business.'}</p>
          </MainCard>
          <SmallCard image={PriceImg} headerText={'Purely relying on external staffing'}>
            <p>{'Not fully leveraging your preferred worker network to fill shifts where you can, leading to incurring unnecessary costs.'}</p>
          </SmallCard>
        </section>
      </section>
      <BannerContent 
        bannerTitle={'Get the best of both worlds'} 
        bannerText={`Immediately notify and book your preferred workers for temporary shifts at no cost. If no one is available, access the deepest talent pool on-demand to fill your shifts at a fraction of what recruitment agencies charge.`}
      />
      <section className={'advertising-block'}>
        <section className={'step-column'}>
          <StepImage image={AddLocumImg} altText={''} />
          <StepText
            additionalClassNames={['two']}
            stepHeader={'Add shifts, and watch the requests come in'}
            stepContent={`Never worry about playing phone tag, or sending back & forth texts again. Simply add your shift and relax. If no preferred workers are available, you can choose when to add it to the public marketplace.`}
          />
          <StepImage image={FreeImg} altText={''} />
          <StepText
            additionalClassNames={['four']}
            stepHeader={`Fill remaining shifts using our on-demand marketplace`}
            stepContent={`If shifts are not taken up by preferred workers, you can automatically access the deepest pool of on-demand workers. You only pay for what you use, at a fraction of the cost of recruitment agencies.`}
          />
        </section>
        <span className={'vertical-line'} />
        <section className={'step-counters'}>
          <StepCounter stepNumber={1} />
          <StepCounter stepNumber={2} />
          <StepCounter stepNumber={3} />
          <StepCounter stepNumber={4} />
        </section>
        <section className={'step-column'}>
          <StepText
            additionalClassNames={['one']}
            stepHeader={'Add your preferred worker'}
            stepContent={`Once your superstar team accepts your inv ite, they are immediately notified via SMS (96% open rate) whenever you need a shift filled. It costs nothing to book them in!`}
          />
          <StepImage image={AddShiftImg} altText={''} />
          <StepText
            additionalClassNames={['three']}
            stepHeader={'Book your preferred workers for free'}
            stepContent={`Your preferred workers are immediately notified by SMS. Book as many shifts as you'd like for free. Choose when you'd like to add the remaining shifts onto the public marketplace.`}
          />
          <StepImage image={NetworkImg} altText={''} />
        </section>
      </section>
      <section className={'sign-up-block'}>
        <section className={'free-trial'}>
          <h3 className={'header-text'}>{'Try it for free'}</h3>
          <p className={'end-content'}>{'No payment details required. Invite your preferred workers and experience our Virtual Assistant for 30 days.'}</p>
          <button className={'start-trial-button very-dark'} onClick={signUp}>{'Start 30-day Free Trial'}</button>
        </section>
        <section className={'tiny-card'}>
          <h3 className={'header-text strike-through'}>{'$12/month per store'}</h3>
          <h4 className={'header-subtitle'}>{'Free for 30 days'}</h4>
          <ul className={'pitch-list'}>
            <li className={'pitch-item'}>{'Add up to 4 preferred workers'}</li>
            <li className={'pitch-item'}>{'Book preferred workers for free'}</li>
            <li className={'pitch-item'}>{'Notify new shifts automatically'}</li>
            <li className={'pitch-item'}>{'Access on-demand pool'}<br />{'(book non-preferred workers for free)'}</li>
          </ul>
        </section>
      </section>
      <section className={'want-to-know-more-block'}>
        <h3 className={'header-text'}>{'Want to know more?'}</h3>
        <h4 className={'subtitle'}>{`Schedule a time and we'll tell you all about it!`}</h4>
        <button className={'request-demo-button very-dark'} onClick={requestDemo}>{'Request a demo'}</button>
      </section>
    </article>
  );
}

const StaffingAssistantPage = () => {
  const isMobile = useMediaQuery('(max-width: 980px)');
  return isMobile ? <MobileStaffingAssistantPage /> : <WebStaffingAssistantPage />;
}

export default StaffingAssistantPage;
