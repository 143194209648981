import React from 'react';
import { Route } from 'react-router-dom';

import { RequireAuth } from '../components/hooks/AuthHook';

import BusinessManageAccountPage from '../components/business/BusinessManageAccountPage';
import StoresPage from '../components/business/stores/StoresPage';
import LocumlyPage from '../wrappers/LocumlyPage';

function getBusinessRoutes() {
  return (
    <Route path={'/business'} element={<LocumlyPage />}>
      <Route index={true} element={<RequireAuth><StoresPage /></RequireAuth>} />
      <Route path={'stores'} element={<RequireAuth><StoresPage /></RequireAuth>} />
      <Route path={'account/manage'} element={<RequireAuth><BusinessManageAccountPage /></RequireAuth>} />
      <Route path={'*'} element={<RequireAuth><StoresPage /></RequireAuth>} />
    </Route>
  );
}

export default getBusinessRoutes;