import React, { useState, useEffect, useContext } from 'react';
import { ASSET_CDN_URL, BUSINESS_DEFAULT_ROUTE, USER_ROLES } from '../../Constants';
import CartContext from '../../hooks/CartContext.jsx';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import ProfileDropdown from './ProfileDropdown.jsx';
import useAuth from '../../hooks/AuthHook.jsx';
import { useMediaQuery } from '../../hooks/CommonHooks.jsx';
import './Navbar.scss';
import { debounce } from "../../../helpers/debounce";
import MobileSearchButton from './NavSearch.jsx';
import { NAVIGATION_OPTIONS } from '../../business/BusinessConstants.js';
import { UnreadCount } from '../../dashboard/NotificationsAndMessagesSidebar.jsx';

// Icons/Logo
const Logo = `${ASSET_CDN_URL}/logo/aqua.png`;
const SearchIcon = `${ASSET_CDN_URL}/icons/magnifying-glass-icon.svg`;
const PostIcon = `${ASSET_CDN_URL}/navbar/post-work.png`;
const HowIcon = `${ASSET_CDN_URL}/navbar/how-it-works.svg`;
const LoginIcon = `${ASSET_CDN_URL}/navbar/login.svg`;
const CalendarIcon = `${ASSET_CDN_URL}/navbar/schedule.svg`;
const CartIcon = `${ASSET_CDN_URL}/navbar/cart.svg`;
const InboxIcon = `${ASSET_CDN_URL}/navbar/inbox.svg`;
const UpcomingShiftsIcon = `${ASSET_CDN_URL}/store/upcoming-shifts.svg`;
const ReviewApplicantsIcon = `${ASSET_CDN_URL}/store/review-applicants.svg`;
const StoreProfileIcon = `${ASSET_CDN_URL}/store/store-profile.svg`;

const BusinessNavButtons = ({ hasMessages, numTotalUnread, onClick = () => {} }) => {
  const { profile } = useAuth();
  const { stores } = profile;
  const navigate = useNavigate();

  const applicantsClick = () => {
    if (stores.length > 0) {
      navigate('/store/applicants/review', { state: { storeId: stores[0]._id } });
    }
    else {
      navigate(BUSINESS_DEFAULT_ROUTE);
    }
  }

  const addShiftsClick = () => {
    if (stores.length > 0) {
      navigate('/store/shifts/add', { state: { storeId: stores[0]._id } });
    }
    else {
      navigate(BUSINESS_DEFAULT_ROUTE);
    }
  }
  return (
    <ul className={`navbar-list`}>
      <li className={'nav-option-container'}>
        <NavLink className={'nav-option'} exact={'true'} to={'/find'} onClick={onClick}>{'Find shifts'}</NavLink>
      </li>
      <li className={'nav-option-container'}>
        <button className={'nav-option'} onClick={addShiftsClick}>{'Add shifts'}</button>
      </li>
      <li className={'nav-option-container'}>
        <button className={'nav-option'} onClick={applicantsClick}>{'Applicants'}</button>
      </li>
      <li className={'nav-option-container'}>
        <NavLink className={'nav-option'} exact={'true'} to={hasMessages ? '/user/messages' : '/user/notifications'} onClick={onClick}>
          {'Inbox'}
          {numTotalUnread > 0 && <UnreadCount count={numTotalUnread} />}
        </NavLink>
      </li>
      <ProfileDropdown />
    </ul>
  )
}

const LocumNavButtons = ({ hasMessages, cartCount, numTotalUnread, onClick = () => {} }) => {
  return (
    <ul className={`navbar-list`}>
      <li className={'nav-option-container'}>
        <NavLink className={'nav-option'} exact={'true'} to={'/find'} onClick={onClick}>{'Find shifts'}</NavLink>
      </li>
      <li className={'nav-option-container'}>
        <NavLink className={'nav-option'} exact={'true'} to={'/locum/cart'} onClick={onClick}>
          {'Cart'}
          {cartCount > 0 && <UnreadCount count={cartCount} />}
        </NavLink>
      </li>
      <li className={'nav-option-container'}>
        <NavLink className={'nav-option'} exact={'true'} to={hasMessages ? '/user/messages' : '/user/notifications'} onClick={onClick}>
          {'Inbox'}
          {numTotalUnread > 0 && <UnreadCount count={numTotalUnread} />}
        </NavLink>
      </li>
      <ProfileDropdown />
    </ul>
  )
};


const WebNavButtons = ({ onClick = () => {} }) => {
  return (
    <ul className={`navbar-list`}>
      <li className={'nav-option-container'}>
        <NavLink className={'nav-option'} exact={'true'} to={'/find'} onClick={onClick}>{'Find shifts'}</NavLink>
      </li>
      <li className={'nav-option-container'}>
        <NavLink className={'nav-option'} exact={'true'} to={'/post'} onClick={onClick}>{'Post shifts'}</NavLink>
      </li>
      <li className={'nav-option-container'}>
        <NavLink className={'nav-option'} exact={'true'} to={'/how-it-works'} onClick={onClick}>{'How it works'}</NavLink>
      </li>
      <li className={'nav-option-container'}>
        <NavLink className={'login-button very-dark'} exact={'true'} to={'/login'} onClick={onClick}>{'Log in'}</NavLink>
      </li>
    </ul>
  );
}

const AuthedWebNavbar = () => {
  const navigate = useNavigate();
  const navigateHome = () => navigate('/');
  const { cartShifts } = useContext(CartContext);
  const cartCount = Object.keys(cartShifts).length;
  const { profile, chats, notifications } = useAuth();
  const { role } = profile;
  const messages = chats;
  const hasMessages = messages.length > 0;
  const newNotificationCount = notifications ? notifications.filter(notification => notification.new).length : 0;
  const newMessageCount = messages ? messages.reduce((count, message) => count + message.chatHistory.reduce((val, x) => val + (x.new ? 1 : 0), 0), 0) : 0;
  const numTotalUnread = newNotificationCount + newMessageCount;
  return (
    <section className={'navbar-container'} id={'navbar-id'}>
      <nav className={'navbar auth'}>
        <img src={Logo} onClick={navigateHome} className={'locumly-logo very-dark'} alt={'Logo'} />
        { role === USER_ROLES.LOCUM
          ? <LocumNavButtons hasMessages={hasMessages} cartCount={cartCount} numTotalUnread={numTotalUnread} />
          : <BusinessNavButtons hasMessages={hasMessages} numTotalUnread={numTotalUnread} />
        }
      </nav>
    </section>
  );
}

/** Component for locumly non-authed Navbar. MAX-WIDTH > 480px */
const WebNavbar = () => {
  const navigate = useNavigate();
  const navigateHome = () => navigate('/');
  return (
    <section className={'navbar-container'} id={'navbar-id'}>
      <nav className={'navbar no-auth'}>
        <img src={Logo} onClick={navigateHome} className={'locumly-logo very-dark'} alt={'Logo'} />
        <WebNavButtons />
      </nav>
    </section>
  );
};

// Component for the navigation panel for mobile view. <480px
export const MobileFooter = ({ location }) => {

  return (
    <nav className={'mobile-footer-container'}>
      <NavLink className={'nav-option'} exact={'true'} to={'/find'}>
        <div className={'search-icon-container'}>
          <img className={location === '/find' ? 'footer-icon active-footer-icon' : 'footer-icon'} src={SearchIcon} alt={'Search Icon'}/>
        </div>
        <div className={location === '/find' ? 'footer-text active-footer-text' : 'footer-text'}>{'Find Work'}</div>
      </NavLink>
      <NavLink className={location === '/post' ? 'nav-option active-nav-option' : 'nav-option'} exact={'true'} to={'/post'}>
        <div className={'search-icon-container'}>
          <img className={location === '/post' ? 'footer-icon active-footer-icon' : 'footer-icon post-icon'} src={PostIcon} alt={'Post Icon'}/>
        </div>
        <div className={location === '/post' ? 'footer-text active-footer-text' : 'footer-text'}>{'Post Work'}</div>
      </NavLink>
      <NavLink className={'nav-option'} exact={'true'} to={'/how-it-works'}>
        <div className={'search-icon-container'}>
          <img className={location === '/how-it-works' ? 'footer-icon active-footer-icon' : 'footer-icon'} src={HowIcon} alt={'How it works Icon'}/>
        </div>
        <div className={location === '/how-it-works' ? 'footer-text active-footer-text' : 'footer-text'}>{'How it works'}</div>
      </NavLink>
      <NavLink className={'nav-option'} exact={'true'} to={'/login'}>
        <div className={'search-icon-container'}>
          <img className={location.includes('/login') ? 'footer-icon active-footer-icon' : 'footer-icon'} src={LoginIcon} alt={'Log in Icon'}/>
        </div>
        <div className={location.includes('/login') ? 'footer-text active-footer-text' : 'footer-text'}>{'Log in'}</div>
      </NavLink>
    </nav>
  )
}

// Component for the navigation panel for a mobile view when on logged in experience. <480px
export const AuthedMobileFooter = ({ location, role, profile }) => {
  const { chats, notifications } = profile;
  const messages = chats;
  const newNotificationCount = notifications ? notifications.filter(notification => notification.new).length : 0;
  const newMessageCount = messages ? messages.reduce((count, message) => count + message.chatHistory.reduce((val, x) => val + (x.new ? 1 : 0), 0), 0) : 0;
  const numTotalUnread = newNotificationCount + newMessageCount;
  const { cartShifts } = useContext(CartContext);
  const cartCount = Object.keys(cartShifts).length;
  const [selectedProfile, setSelectedProfile] = useState(false);
  const { stores } = profile && profile.stores ? profile : {stores: []};
  const hasStores = stores.length > 0;
  const storeId = hasStores ? stores[0]._id : null;
  const navigate = useNavigate();
  const navigateTo = (role, url) => {
    return () => {
      if (role === 'LOCUM') {
        navigate(url)
      } else {
        navigate(url, { state: { storeId } });
      } 
    }
  };
  return (
    <nav className={'mobile-footer-container'}>
      {role === 'LOCUM'
      ? <>
        <NavLink className={'nav-option'} exact={'true'} to={'/find'}>
          <div className={'search-icon-container'}>
            <img className={location === '/find' && !selectedProfile ? 'footer-icon active-footer-icon' : 'footer-icon'} src={SearchIcon} alt={'Find Work'}/>
          </div>
          <div className={location === '/find' && !selectedProfile ? 'footer-text active-footer-text' : 'footer-text'}>{'Find Work'}</div>
        </NavLink>
        <NavLink className={'nav-option'} exact={'true'} to={'/locum/cart'}>
          <div className={'search-icon-container'}>
            <img className={location === '/locum/cart' && !selectedProfile ? 'footer-icon active-footer-icon' : 'footer-icon'} src={CartIcon} alt={'My Cart'}/>
            {cartCount > 0 && <UnreadCount count={cartCount} />}
          </div>
          <div className={location === '/locum/cart' && !selectedProfile ? 'footer-text active-footer-text' : 'footer-text'}>{'My Cart'}</div>
        </NavLink>
        <NavLink className={'nav-option'} exact={'true'} to={'/locum/schedule'}>
          <div className={'search-icon-container'}>
            <img className={location === '/locum/schedule' && !selectedProfile ? 'footer-icon active-footer-icon' : 'footer-icon'} src={CalendarIcon} alt={'Schedule'}/>
          </div>
          <div className={location === '/locum/schedule' && !selectedProfile ? 'footer-text active-footer-text' : 'footer-text'}>{'Schedule'}</div>
        </NavLink>
      </>
      :
      <>
        <div className={'nav-option'} onClick={navigateTo(role, BUSINESS_DEFAULT_ROUTE)}>
          <div className={'search-icon-container'}>
            <img className={location === BUSINESS_DEFAULT_ROUTE && !selectedProfile ? 'footer-icon active-footer-icon' : 'footer-icon'} src={StoreProfileIcon} alt={'My Stores'}/>
          </div>
          <div className={location === BUSINESS_DEFAULT_ROUTE && !selectedProfile ? 'footer-text active-footer-text' : 'footer-text'}>{'My Stores'}</div>
        </div>
        <div className={'nav-option'} onClick={navigateTo(role, '/store/shifts/upcoming')}>
          <div className={'search-icon-container'}>
            <img className={location === '/store/shifts/upcoming' && !selectedProfile ? 'footer-icon active-footer-icon' : 'footer-icon'} src={UpcomingShiftsIcon} alt={'My Shifts'}/>
          </div>
          <div className={location === '/store/shifts/upcoming' && !selectedProfile ? 'footer-text active-footer-text' : 'footer-text'}>{'My Shifts'}</div>
        </div>
        <div className={'nav-option'} onClick={navigateTo(role, '/store/applicants/review')}>
          <div className={'search-icon-container'}>
            <img className={location === '/store/applicants/review' && !selectedProfile ? 'footer-icon active-footer-icon' : 'footer-icon'} src={ReviewApplicantsIcon} alt={'Applicants'}/>
          </div>
          <div className={location === '/store/applicants/review' && !selectedProfile ? 'footer-text active-footer-text' : 'footer-text'}>{'Applicants'}</div>
        </div>
      </>
      }
        <div className={'nav-option'} onClick={() => navigate('/user/messages', {state: {open: true}})}>
          <div className={'search-icon-container'}>
            <img className={location === '/user/messages' && !selectedProfile ? 'footer-icon active-footer-icon' : 'footer-icon'} src={InboxIcon} alt={'Inbox Icon'}/>
            {numTotalUnread > 0 && <UnreadCount count={numTotalUnread} />}
          </div>
          <div className={location === '/user/messages' && !selectedProfile ? 'footer-text active-footer-text' : 'footer-text'}>{'Inbox'}</div>
        </div>
        <ProfileDropdown mobileView={true} setSelectedProfile={setSelectedProfile} selectedProfile={selectedProfile} />
    </nav>
  )
}

const BusinessAddShift = ({profile}) => {
  const { SHIFT } = NAVIGATION_OPTIONS;
  const { stores } = profile;
  const hasStores = stores.length > 0;
  const storeId = hasStores ? stores[0]._id : null;
  const navigate = useNavigate();
  const navigateTo = (url) => {
    return () => {
      navigate(url, { state: { storeId } });
    }
  };
  return (
    <div className={'business-add-container'}>
      <button className={'business-add-button'} onClick={navigateTo(SHIFT.url)}>{'Add Shifts'}</button>
    </div>
  )
}

// Overall navbar for mobile view, contains the search and footer.
const MobileNavbar2 = ({ authed, profile }) => {
  const { role } = profile ? profile : {role: 'LOCUM'};
  const navigate = useNavigate();
  const navigateHome = () => navigate('/');
  const { pathname } = useLocation();
  const hideNavBoxShadow = ['/find', '/locum/cart', '/find/store/shifts', '/user/messages', '/user/notifications', '/locum/schedule'];
  const hideNavFooter = [];
  const hideNav = ['/locum/profile/edit', '/business/account/manage', '/locum/account/manage', '/find/shift', '/find/store/view'];

  const [prevScrollPos, setPrevScrollPos] = useState(-1);
  const [visible, setVisible] = useState(true);

  const handleScroll = debounce(() => {
    const currentScrollPos = window.scrollY;

    // If refreshed, show footer, otherwise hide footer when scrolling down and show footer when scrolling up.
    setVisible(prevScrollPos === -1 
              || (prevScrollPos >= currentScrollPos) 
              || currentScrollPos < 10);

    setPrevScrollPos(currentScrollPos);
  }, 60);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);

  }, [prevScrollPos, visible, handleScroll]);
  
  return (
    <>
      {!hideNavFooter.includes(pathname) && 
        <>
          {!hideNav.includes(pathname) && 
            <section className={'navbar-container'} id={'navbar-id'}>
            <nav className={hideNavBoxShadow.includes(pathname) ? 'mobile-navbar no-auth in-find-page' : 'mobile-navbar no-auth'}>
              <section className={'navbar-content'}>
                <img src={Logo} onClick={navigateHome} className={'locumly-logo very-dark'} alt={'Logo'} />
                {role === 'LOCUM' ? <MobileSearchButton /> : <BusinessAddShift profile={profile} />}
              </section>
            </nav>
          </section>
          }
          <section className={ visible ? 'navbar-footer' : 'navbar-footer nav-tranform'} id={'navbar-footer'}>
            {authed ? <AuthedMobileFooter location={pathname} role={role} profile={profile} /> : <MobileFooter location={pathname} />}
          </section>
        </>
      }
    </>
  );
}

// Using same component for auth/noauth mobile navbar as the only difference is footer.
const Navbar = () => {
  const isMobile = useMediaQuery('(max-width: 480px)');
  const { authed, profile } = useAuth(); 
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname !== '/find') {
      document.body.classList.remove('prevent-scroll');
    }
  }, [pathname])

  if (isMobile) {
    return <MobileNavbar2 authed={authed} profile={profile}/>;
  }
  if (!authed) {
    return <WebNavbar />;
  }
  else {
    return <AuthedWebNavbar />;
  }
}

export default Navbar;