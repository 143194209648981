export const ASSET_CDN_URL = 'https://dq55dzprwc7f6.cloudfront.net';

const optometryIcon = `${ASSET_CDN_URL}/avatar/optometry.svg`;
const pharmacyIcon = `${ASSET_CDN_URL}/avatar/pharmacy.svg`;
const defaultPersonAvatar = `${ASSET_CDN_URL}/avatar/person.svg`;

export const EMAIL_REGEX = /^[-a-zA-Z0-9_@.]+$/;
export const DEFAULT_PERSON_AVATAR = defaultPersonAvatar;
export const DEFAULT_STORE_AVATAR = { OPTOMETRY: optometryIcon, PHARMACY: pharmacyIcon };

export const USER_ROLES = {
  BUSINESS: 'BUSINESS',
  LOCUM: 'LOCUM',
};

export const SHIFT_STATUSES = {
  FILLED: {
    key: 'FILLED',
    text: 'Filled',
    className: 'filled',
    locumText: 'Action Required',
    hasSubtitle: true,
    subtitle: 'Missing Provider ID',
  },
  UNFILLED: {
    key: 'UNFILLED',
    text: 'Not Filled',
    className: 'unfilled',
    locumText: 'Requested',
    hasSubtitle: false,
    subtitle: '',
  },
  CONFIRMED: {
    key: 'CONFIRMED',
    text: 'Filled',
    className: 'confirmed',
    locumText: 'Filled',
    hasSubtitle: true,
    subtitle: 'Provider ID Received',
  },
  INCOMPLETE: {
    key: 'INCOMPLETE',
    text: 'Incomplete',
    className: 'incomplete',
    locumText: 'Incomplete',
    hasSubtitle: true,
    subtitle: 'Action Required',
  },
  INVOICE: {
    key: 'INVOICE',
    text: 'Pending Payment',
    className: 'invoice',
    locumText: 'Pending Payment',
    hasSubtitle: false,
    subtitle: '',
  },
  PAID: {
    key: 'PAID',
    text: 'Payment Complete',
    className: 'completed',
    locumText: 'Payment Complete',
    hasSubtitle: false,
    subtitle: '',
  },
  COMPLETED: {
    key: 'COMPLETED',
    text: 'Completed',
    className: 'completed',
    locumText: 'Completed',
    hasSubtitle: false,
    subtitle: '',
  },
  DISPUTED: {
    key: 'DISPUTED',
    text: 'Disputed',
    className: 'disputed',
    locumText: 'Disputed',
    hasSubtitle: true,
    subtitle: 'Pending Review',
  },
};

export const SHIFT_TYPES = {
  'ALL' : {
    name: 'All Shifts',
    key: 'ALL',
    imageUrl: null,
    isComingSoon: false,
  },
  'OPTOMETRY' : {
    name: 'Optometry',
    key: 'OPTOMETRY',
    imageUrl: optometryIcon,
    isComingSoon: false,
  },
  'PHARMACY' : {
    name: 'Pharmacy',
    key: 'PHARMACY',
    imageUrl: pharmacyIcon,
    isComingSoon: false,
  },
};

export const CALENDAR_SEARCH_METHODS = {
  'BYDAYOFWEEK' : {
    name: 'by days of the week',
    key: 'BYDAYOFWEEK',
    isComingSoon: false
  },
  'BYMONTH' : {
    name: 'by month',
    key: 'BYMONTH',
    isComingSoon: false
  }
};

export const NETWORKS = {
  OPTOMETRY: {
    SPECSAVERS: { key: 'SPECSAVERS', text: 'Specsavers' },
    OPSM: { key: 'OPSM', text: 'OPSM' },
    OSCAR_WYLEE: { key: 'OSCAR_WYLEE', text: 'Oscar Wylee' },
    EYECARE_PLUS: { key: 'EYECARE_PLUS', text: 'Eyecare Plus' },
    BAILEY_NELSON: { key: 'BAILEY_NELSON', text: 'Bailey Nelson' },
    GEORGE_AND_MATILDA: { key: 'GEORGE_AND_MATILDA', text: 'George & Matilda' },
    BUPA_OPTICAL: { key: 'BUPA_OPTICAL', text: 'Bupa Optical'},
    ONE_THOUSAND_AND_ONE_OPTICAL: { key: 'ONE_THOUSAND_AND_ONE_OPTICAL', text: '1001 Optical' },
    "1001_OPTICAL": { key: '1001_OPTICAL', text: '1001 Optical' },
    OTHER: { key: 'OTHER', text: 'Other' },
  }, 
  PHARMACY: {
    CHEMIST_WAREHOUSE: { key: 'CHEMIST_WAREHOUSE', text: 'Chemist Warehouse' },
    TERRY_WHITE: { key: 'TERRY_WHITE', text: 'Terry White' },
    PRICELINE: { key: 'PRICELINE', text: 'Priceline' },
    AMCAL: { key: 'AMCAL', text: 'Amcal' },
    DIRECT_CHEMIST: { key: 'DIRECT_CHEMIST', text: 'Direct Chemist' },
    DIRECT_CHEMIST_OUTLET: { key: 'DIRECT_CHEMIST_OUTLET', text: 'Direct Chemist Outlet'},
    NATIONAL_PHARMACY: { key: 'NATIONAL_PHARMACY', text: 'National Pharmacy'},
    QUALITY_PHARMACY: { key: 'QUALITY_PHARMACY', text: 'Quality Pharmacy' },
    OTHER: { key: 'OTHER', text: 'Other' },
  }
};

export const STRUCTURE_TYPES = {
  INDIVIDUAL_PROPRIETOR: { key: 'INDIVIDUAL_PROPRIETOR', text: 'Individual Proprietor' },
  PARTNERSHIP: { key: 'PARTNERSHIP', text: 'Partnership' },
  OTHER_PUBLIC_BODY: { key: 'OTHER_PUBLIC_BODY', text: 'Other Public Body' },
  COMPANY: { key: 'COMPANY', text: 'Company' },
  STATE_GOVERNMENT: { key: 'STATE_GOVERNMENT', text: 'State Government' },
  TERRITORY_GOVERNMENT: { key: 'TERRITORY_GOVERNMENT', text: 'Territory Government' },
  UNINCORPORATED_ASSOCIATION: { key: 'UNINCORPORATED_ASSOCIATION', text: 'Unincorporated Association' },
};

export const PREMISE_TYPES = {
  GENERAL_PRACTICE: { key: 'GENERAL_PRACTICE', text: 'Practice - General Practice' },
  OTHER_PRACTICE: { key: 'OTHER_PRACTICE', text: 'Practice - Other Private Practice' },
  PUBLIC_HOSPITAL: { key: 'PUBLIC_HOSPITAL', text: 'Hospital - Public' },
  PRIVATE_HOSPITAL: { key: 'PRIVATE_HOSPITAL', text: 'Hospital - Private' },
  RESIDENTIAL_CARE_FACILITY: { key: 'RESIDENTIAL_CARE_FACILITY', text: 'Residential Care Facility' },
  EDUCATIONAL_INSTITUTION: { key: 'EDUCATIONAL_INSTITUTION', text: 'Educational Institution' },
  OTHER: { key: 'OTHER', text: 'Other Community Healthcare Service' },
};

export const ABR_URL = 'https://www.abr.gov.au/business-super-funds-charities/applying-abn/abn-entitlement';
export const AHPRA_URL = 'https://www.ahpra.gov.au/registration.aspx';

export const MILLISECONDS_IN_AN_HOUR = 60 * 60 * 1000;

export const PDF_CLOUDFRONT = 'https://d3ahi3szjvwe70.cloudfront.net/index.html?document=';

export const DAYS_OF_WEEK = {
  displayText: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  apiText: ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY']
};

export const DAYS_OF_WEEK_KEY = [
  { key: 'MONDAY', text: 'Monday', },
  { key: 'TUESDAY', text: 'Tuesday', },
  { key: 'WEDNESDAY', text: 'Wednesday', },
  { key: 'THURSDAY', text: 'Thursday', },
  { key: 'FRIDAY', text: 'Friday', },
  { key: 'SATURDAY', text: 'Saturday', },
  { key: 'SUNDAY', text: 'Sunday', },
];

export const MONTHS = {
  displayText: ['January', 'Febuary', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  apiText: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
};

export const STATES = [
  { key: 'NSW', text: 'New South Wales', },
  { key: 'QLD', text: 'Queensland', },
  { key: 'NT', text: 'Northern Territory', },
  { key: 'WA', text: 'Western Australia', },
  { key: 'SA', text: 'South Australia', },
  { key: 'VIC', text: 'Victoria', },
  { key: 'ACT', text: 'Australian Capital Territory', },
  { key: 'TAS', text: 'Tasmania', },
]

export const GOOGLE_DIRECTIONS_URL = 'https://www.google.com/maps?saddr=Current+Location&daddr=';

export const DEFAULT_LOCATION = {
  radius: 1000,
  coordinates: {
    latitude: -37.840935,
    longitude: 144.946457,
  },
  text: 'Port Melbourne',
  searchText: 'Port Melbourne',
};

export const BUSINESS_DEFAULT_ROUTE = '/business/stores';