import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import useAuth from '../../hooks/AuthHook.jsx';
import StoreNavigationSidebar from './StoreNavigationSidebar.jsx';
import StoreDropdown from './StoreDropdown.jsx';
import { DEFAULT_STORE_AVATAR } from '../../Constants';
import { getProfile, postStoreAvatarUpload } from '../../Api.js';
import useStore from '../../hooks/StoreHook.jsx';
import './StorePage.scss';

const StorePage = ({ children, mode }) => {
  const { profile, setProfile } = useAuth();
  const { state } = useLocation();
  const storeId = state && state.storeId ? state.storeId : profile.stores[0]._id;
  const store = profile.stores.find((store) => store._id === storeId);
  const { selectStoreId } = useStore();
  useEffect(() => {
    selectStoreId(storeId);
  }, []);
  const [storeAvatar, setStoreAvatar] = useState(store.avatar ? store.avatar : DEFAULT_STORE_AVATAR[store.type]);
  const uploadAvatar = (e) => {
    const file = e.target.files[0];
    setStoreAvatar(window.URL.createObjectURL(file));
    postStoreAvatarUpload(store._id, file)
      .then(() => getProfile().then(({ profile }) => {
        setProfile(profile);
      }))
      .catch((err) => console.error(err));
  }
  return (
    <article className={'store-page'}>
      <section className={'store-dropdown-container'}>
        <img className={'store-image'} src={storeAvatar} alt={'Store Avatar'} />
        <section className={'upload-avatar-container'}>
          <label for={'avatar-image-upload'} className={'upload-avatar'}>{'Upload New Avatar'}</label>
          <input
            type={'file'}
            name={'avatar-image-upload'}
            id={'avatar-image-upload'}
            className={'avatar-image-upload'}
            accept={'image/*'}
            onChange={uploadAvatar}
          />
        </section>
        <span className={'view-for-text'}>{'Viewing for'}</span>
        <StoreDropdown currentMode={mode} />
      </section>
      <StoreNavigationSidebar currentMode={mode} />
      {children}
    </article>
  );
};

export default StorePage;