import './StoreProfileDetails.scss';

import PropTypes from 'prop-types';
import React from 'react';

import useStoreProfile from '../../hooks/StoreProfileContext';

import StoreHiringBanner from './sections/StoreHiringBanner';
import StorePhotoSection from './sections/StorePhotoSection';
import StoreReviewSection from './sections/StoreReviewSection';
import JoinTeamSection from './sections/JoinTeamSection';
import StoreOfferingSection from './sections/StoreOfferingSection';
import StoreLocationSection from './sections/StoreLocationSection';
import StoreWordsFromAdminSection from './sections/StoreWordsFromAdminSection';

const propTypes = {
  /* Sets the modal data to display. */
  setModalData: PropTypes.func.isRequired,
}

function StoreProfileDetails({ setModalData }) {
  const { storeProfile } = useStoreProfile();
  const {
    storePhotos,
    storeReviews,
    permanentShifts,
    casualShifts,
    storeOfferings,
    storeName,
    wordsFromAdmin,
    address,
    coordinates,
    seekUrl,
    glassdoorUrl,
    storeId,
  } = storeProfile;
  const numShiftsAvailable = permanentShifts.length + casualShifts.length;
  return (
    <section className={'store-profile-details'}>
      <StoreHiringBanner
        numRolesAvailable={numShiftsAvailable}
        storeId={storeId}
        storeName={storeName}
      />
      <StorePhotoSection photos={storePhotos} />
      <StoreReviewSection
        reviews={storeReviews}
        seekUrl={seekUrl ? seekUrl : ''}
        glassdoorUrl={glassdoorUrl ? glassdoorUrl : ''}
        storeName={storeName}
        storeId={storeId}
      />
      <JoinTeamSection
        casualShifts={casualShifts}
        permanentShifts={permanentShifts}
        storeName={storeName}
      />
      <StoreOfferingSection offerings={storeOfferings} setModalData={setModalData} />
      <StoreWordsFromAdminSection storeName={storeName} wordsFromAdmin={wordsFromAdmin} />
      <StoreLocationSection address={address} coordinates={coordinates} storeName={storeName} />
    </section>
  );
};

StoreProfileDetails.displayName = 'StoreProfileDetails';
StoreProfileDetails.propTypes = propTypes;
export default StoreProfileDetails;