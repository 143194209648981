import React, { useState, useEffect, useContext, useRef } from 'react';
import { DAYS_OF_WEEK, MONTHS, ASSET_CDN_URL } from '../Constants';
import CartContext from '../hooks/CartContext';
import { useNavigate } from 'react-router-dom';
import { postShiftRequest } from '../Api.js';
import { UnreadCount } from '../dashboard/NotificationsAndMessagesSidebar';
import { useOutsideClick, useMediaQuery } from '../hooks/CommonHooks';
import { orderShifts } from '../../helpers/ShiftUtils.js';
import useAuth from '../hooks/AuthHook.jsx';
import { getProfile } from '../Api.js';
import './CartPage.scss';

const TrashCan = `${ASSET_CDN_URL}/icons/trash-icon.svg`;
const SaveIcon = `${ASSET_CDN_URL}/icons/req-submit-tick-icon.svg`;

const nth = d => {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
  }
}

const AMPM = (time) => {
  let hour = time.getHours() > 12 ? time.getHours() - 12 : time.getHours();
  let min = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes();
  let suffix = time.getHours() > 12 ? 'pm' : 'am';
  return hour + ':' + min + suffix;
}

const ShiftItem = ({shift, rates, proposeRate, isEditing, selectedShifts, setSelectedShifts, isMobile}) => {
  const { startTime, endTime, rate, billToMedicare, total, negotiable } = shift;
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);
  const formattedDate = DAYS_OF_WEEK.displayText[startDate.getDay()] + ' ' + startDate.getDate() + nth(startDate.getDate()) + ' ' + MONTHS.displayText[startDate.getMonth()] + ', ' + startDate.getFullYear();
  const formattedHours = AMPM(startDate) + ' - ' + AMPM(endDate);
  const rateDisplay = billToMedicare ? 'Rate: NA' : `$${(rate / 100).toFixed(0)} p/h`;
  const totalDisplay = billToMedicare ? 'NA' : `$${(total / 100).toFixed(0)} total`;
  const rateArray = Array.from({length:41},(v,k)=>k*5);

  const handleSelect = () => {
    setSelectedShifts(prevState => {
      let changedState = {...prevState};
      if (changedState.hasOwnProperty(shift._id)) {
        delete changedState[shift._id];
      } else {
        changedState[shift._id] = shift.storeId;
      }
      return changedState;
    })
  }
  return (
    <div className={`shift-edit-container${isEditing ? ' editing' : ''}`}>
      {(isMobile && isEditing) && 
      <div className={'shift-edit-checkbox'}>
        <input checked={Object.keys(selectedShifts).includes(shift._id)} className='edit-input' type={'checkbox'} value={shift._id} onClick={() => handleSelect()} />
      </div>
      }
      <div className={'shift-item-container'}>
        {(!isMobile && isEditing) && 
        <div className={'shift-edit-checkbox'}>
          <input checked={Object.keys(selectedShifts).includes(shift._id)} className='edit-input' type={'checkbox'} value={shift._id} onClick={() => handleSelect()} />
        </div>}
        <div className={'date-row'}>
          <span>{formattedDate}</span>
        </div>
        <div className={'time-row'}>
          <span>{formattedHours}</span>
        </div>
        <div className={'right-grid'}>
          <div className={'total-row rate-display'}>
            <span>{totalDisplay}</span>
          </div>
          <div className={'rate-row rate-display'}>
            <span>{rateDisplay}</span>
          </div>
          {/* To be added once recommendation engine is ready */}
          {/* <div className={'typical-rate-row'}>
            <em>{'We usually see $80 - $85 here'}</em>
          </div> */}
        </div>
        <form className={'new-rate-row'}>
          <select className={`new-rate ${negotiable ? '' : 'fixed'}`} disabled={!negotiable} defaultValue={!!rates[shift._id] ? rates[shift._id].newRate : ""} name="rate" id="rate" onChange={(e) => proposeRate(shift, e.target.value)}>
            <option value="" disabled>{negotiable ? 'Suggest another rate...' : 'Fixed rate shift'}</option>
            {rateArray.map(rate => <option value={rate * 100}>{`$${rate}`}</option>)}
          </select>
        </form>
      </div>
    </div>
  );
}

const StoreShiftDisplay = ({store, rates, proposeRate, isEditing, selectedShifts, setSelectedShifts, isMobile}) => {
  const { name, shifts } = store;
  return (
    <section className={'store-container'}>
      <h2 className={'store-name'}>{name}</h2>
      <div className={'store-shifts'}>
        {orderShifts(shifts, 'date-ascending').map(shift => <ShiftItem 
                              shift={shift} 
                              rates={rates} 
                              proposeRate={proposeRate} 
                              isEditing={isEditing}
                              selectedShifts={selectedShifts}
                              setSelectedShifts={setSelectedShifts}
                              isMobile={isMobile}
                              key={`shift-${shift._id}`}
                            />)}
      </div>
    </section>
  );
}

const AddMessage = ({storeId, store, messages, addMessage}) => {
  const { name } = store;
  return (
    <section className={'store-questions-container'}>
      <h2 className={'store-title'}>{name}</h2>
      <div className={'answer-form'}>
        <span className={'store-question'}>{"Are there any comments you'd like to add?"}</span>
        <textarea 
          value={!!messages[storeId] ? messages[storeId].defMsg : ''}
          onChange={(e) => addMessage(storeId, e.target.value)} 
          placeholder={'You can use this section to highlight any relevant past experience (e.g. worked at this particular store before)'} 
          className={'answer-box'} name={'name'}></textarea>
      </div>
    </section>
  );
}

const CartPage = () => {
  const {setProfile} = useAuth();
  const navigate = useNavigate();
  const [viewCart, setViewCart] = useState(true);
  const [sentRequests, setSentRequests] = useState(false);
  const {cartShifts, aggregateStores, rates, proposeRate, messages, addMessage, removeShifts, clearCart} = useContext(CartContext);
  const numCartShifts = Object.keys(cartShifts).length;
  const stores = aggregateStores(cartShifts);
  const totals = Object.keys(cartShifts).map((shiftId) => cartShifts[shiftId].total);
  const overall = totals.length > 0 ? (totals.reduce((total, num) => total + num) / 100).toFixed(0) : 0;
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedShifts, setSelectedShifts] = useState({});
  const totalHours = Object.keys(cartShifts).reduce((acc, cur) => (acc + Math.abs(new Date(cartShifts[cur].startTime) - new Date(cartShifts[cur].endTime))), 0) / 36e5;
  const messageRef = useRef(null);
  const isMobile = useMediaQuery('(max-width: 720px)');
  useOutsideClick(messageRef, () => {
    if (isMobile) {
      setViewCart(true)
    }
  });

  const handleSelectAll = () => {
    setIsEditing(true);
    if (Object.keys(selectedShifts).length === numCartShifts) {
      setSelectedShifts({});
    } else {
      setSelectedShifts(Object.keys(cartShifts).reduce((acc, cur) => ({...acc, [cur]: cartShifts[cur].storeId}), {}));
    }
  }

  const handleSelect = () => {
    setIsEditing(!isEditing);
    setSelectedShifts({});
  }

  const handleRemove = (selectedShifts) => {
    if (Object.keys(selectedShifts).length > 0) {
      removeShifts(selectedShifts);
    }

    setIsEditing(false);
  }

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, [])

  const sendCartRequests = () => {
    setIsLoading(true);
    let requests = {};
    Object.keys(cartShifts).forEach(shiftId => {
      let shift = cartShifts[shiftId];
      if (requests.hasOwnProperty(shift.storeId)) {
        if (requests[shift.storeId].hasOwnProperty(shift.businessId)) {
          requests[shift.storeId][shift.businessId].shifts.push(shiftId);
        } else {
          requests[shift.storeId][shift.businessId] = {
            shifts: [shiftId],
            rateReq: {},
            storeMsg: {}
          }
        }
      } else {
        requests[shift.storeId] = {
          [shift.businessId]: {
            shifts: [shiftId],
            rateReq: {},
            storeMsg: {}
          }
        }
      }
    })
    Object.keys(rates).forEach(rateId => {
      let rate = rates[rateId];
      if (rate.shift.rate !== rate.newRate) {
        requests[rate.shift.storeId][rate.shift.businessId].rateReq[rateId] = {
          rate: rate.newRate, 
          date: rate.shift.startTime
        };
      }
    })
    Object.keys(messages).forEach(messageId => {
      let message = messages[messageId];
      Object.keys(requests[messageId]).forEach(businessId => {
        if (message.defMsg.length > 0) {
          requests[messageId][businessId].storeMsg.defMsg = message.defMsg;
        }
      })
    })
    postShiftRequest(Object.keys(cartShifts), requests)
      .then(({error}) => {
        if (error) {
          console.error(error);
        } 
        else {
          getProfile()
            .then(({ profile }) => {
              setProfile(profile);
            });
        }
      })
      .catch(err => console.error(err))
      .finally(() => {
        setSentRequests(true);
        setViewCart(true);
        setIsLoading(false);
        clearCart();
      });
  }

  return (
    <article className={'cart-container'}>
      <div className={'cart-detail-container'}>
        {sentRequests 
        ?
        <div className={'complete-screen'}>
          <div className={'complete-title'}>{'Your requests have been submitted!'}</div>
          <img className={'complete-img'} src={SaveIcon} alt={'green tick'} />
          <div className={'complete-text'}>{"View pending and confirmed shifts in your schedule."}</div>
        </div>
        :
        <>
          <div className={'cart-detail-heading'}>
            <div className={'cart-detail-title'}>
              <span className={'cart-title'}>{'Your Cart'}</span>
              {numCartShifts > 0 && <UnreadCount count={numCartShifts} />}
            </div>
            {numCartShifts > 0 && <div className={'select-container'}>
              <div className={'edit-buttons'}>
                <button className={isEditing && selectedShifts.length === numCartShifts ? 'select-shifts bold' : 'select-shifts'} onClick={() => handleSelectAll()}>{'Select all'}</button>
                <button className={isEditing ? 'select-shifts bold' : 'select-shifts'} onClick={() => handleSelect()}>{'Select shifts'}</button>
              </div>
              {isEditing && 
                <button className={'delete-shifts'} onClick={() => handleRemove(selectedShifts)}>
                  {'Remove'}
                  <img className={'trash-icon'} src={TrashCan} alt={'Trash Delete icon'}/>
                </button>
              }
            </div>}
          </div>
          {numCartShifts === 0 
          ?
          <div className={'empty-cart-container'}>
            <span className={'empty-cart-text'}>{'Your cart is empty. Find shifts '}</span>
            <button className={'empty-cart'} onClick={() => navigate('/find')}>{'here'}</button>
            <span className={'empty-cart-text'}>{'.'}</span>
          </div>
          :
          <div className={'cart-content'}>
            <div className={'cart-info-container'}>
              <div className={`store-scroll ${(!viewCart && !isMobile) ? 'message-container' : ''}`}>
                {
                  (!isMobile && !viewCart) 
                  ?
                  <div className={'message-questions'}>
                    {Object.keys(stores).map((store) =>
                      <AddMessage 
                        storeId={store}
                        store={stores[store]}
                        messages={messages}
                        addMessage={addMessage}
                        key={`storemsg-${store}`}
                      />
                    )}
                  </div>
                  :
                  Object.keys(stores).map((store) =>
                    <StoreShiftDisplay 
                      store={stores[store]}
                      rates={rates}
                      proposeRate={proposeRate}
                      isEditing={isEditing}
                      selectedShifts={selectedShifts}
                      setSelectedShifts={setSelectedShifts}
                      isMobile={isMobile}
                      key={`store-${store}`}
                    />
                  )
                }
              </div>
            </div>
            <div className={`cart-summary-container ${(!viewCart && isMobile) ? 'message-container' : ''}`} ref={messageRef}>
              {isMobile 
              ? viewCart 
                ?
                <>
                  <div className={'cart-summary'}>
                    <span className={'shift-req-num'}>{`You are requesting ${numCartShifts} ${numCartShifts === 1 ? 'shift' : 'shifts'}`}</span>
                    <span className={'shift-req-hours'}>{`Total working hours - ${totalHours} hours`}</span>
                  </div>
                  <button className={'request-shift'} onClick={() => setViewCart(false)}>{'Request'}</button>
                </>
                :
                <>
                  <div className={`cart-summary ${viewCart ? '' : 'message-summary'}`}>
                    <div className={'summary-info'}>
                      <span className={'shift-req-num'}>{'Additional Shift Information'}</span>
                      <span className={'shift-req-hours'}>{`You are requesting ${numCartShifts} ${numCartShifts === 1 ? 'shift' : 'shifts'}`}</span>
                    </div>
                    <div className={'proceed-button-container'}>
                      <button disabled={isLoading} className={'proceed-button'} onClick={() => sendCartRequests()}>
                        {isLoading ? 'Sending your Requests!' : 'Confirm'}
                      </button>
                    </div>
                  </div>
                  <div className={'message-questions'}>
                    {Object.keys(stores).map((store) =>
                      <AddMessage 
                        storeId={store}
                        store={stores[store]}
                        messages={messages}
                        addMessage={addMessage}
                        key={`storemsg-${store}`}
                      />
                    )}
                  </div>
                </>
              :
                <>
                  <div className={'cart-summary'}>
                    <span className={'shift-req-num'}>{`You are requesting ${numCartShifts} ${numCartShifts === 1 ? 'shift' : 'shifts'}`}</span>
                    <span className={'shift-req-hours'}>{`Total working hours: ${totalHours} hours`}</span>
                    <span className={'shift-req-hours'}>{`Total payment (excl. GST): $${overall}`}</span>
                  </div>
                  {viewCart 
                  ? 
                  <button className={'request-shift'} onClick={() => setViewCart(false)}>{'Request'}</button>
                  :
                  <div className={'proceed-button-container'}>
                    <button disabled={isLoading} className={'proceed-button'} onClick={() => sendCartRequests()}>
                      {isLoading ? 'Sending your Requests!' : 'Confirm'}
                    </button>
                  </div>
                  }
                </>
              }
            </div>
          </div>
          }
        </>
        }
      </div>
    </article>
  );
}

export default CartPage;