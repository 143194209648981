import './StoreReviewQuote.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { ASSET_CDN_URL } from '../../../Constants';

const FireEmoji = `${ASSET_CDN_URL}/store-profile/reviews/fire-emoji.svg`;
const GrimaceEmoji = `${ASSET_CDN_URL}/store-profile/reviews/grimacing-emoji.png`;

const propTypes = {
  /* True if the locum would recommend this store. */
  wouldRecommend: PropTypes.bool.isRequired,

  /* Pros about the store. */
  pros: PropTypes.string.isRequired,

  /* Cons about the store. */
  cons: PropTypes.string.isRequired,
}

function StoreReviewQuote({ wouldRecommend, pros, cons }) {
  const recommendText = wouldRecommend
    ? 'Recommends this store' : 'Does not recommend this store';
  const RecommendIcon = wouldRecommend ? FireEmoji : GrimaceEmoji;
  const prosText = pros.length > 0 ? pros : 'No pros for this store';
  const consText = cons.length > 0 ? cons: 'No cons for this store';
  return (
    <section className={'store-review-quote'}>
      <section className={'review-title'}>
        <img className={'recommend-icon'} src={RecommendIcon} alt={'Recommend icon'} />
        <h4 className={'title'}>{recommendText}</h4>
      </section>
      <section className={'pros-text'}>
        <h5 className={'title'}>{'Pros'}</h5>
        <p className={'text'}>{prosText}</p>
      </section>
      <section className={'cons-text'}>
        <h5 className={'title'}>{'Cons'}</h5>
        <p className={'text'}>{consText}</p>
      </section>
    </section>
  );
}

StoreReviewQuote.displayName = 'StoreReviewQuote';
StoreReviewQuote.propTypes = propTypes;
export default StoreReviewQuote;