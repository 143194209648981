const postData = async (url, data) => {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
  .then(response => response.json());
  return response;
};

const getData = async (url) => {
  const response = await fetch(url, { method: 'GET', mode: 'cors', cache: 'default' })
    .then(response => response.json())
  return response;
};


const STORE_PROFILE_SUBSCRIPTION_URL = '/store-profile/store/subscribe';
const STORE_PROFILE_REVIEW_URL = '/store-profile/store/review/post';
const STORE_PROFILE_URL = '/store-profile/store/';

export const getStoreProfile = async (storeName) => getData(`${STORE_PROFILE_URL}${storeName}`);

export const postStoreProfileSubscription = (subscription) => postData(STORE_PROFILE_SUBSCRIPTION_URL, subscription);

export const postStoreProfileReview = (review) => postData(STORE_PROFILE_REVIEW_URL, review);
