import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EnterProviderNumberButton } from './Shift.jsx';
import useAuth from '../hooks/AuthHook.jsx';
import { MILLISECONDS_IN_AN_HOUR, DEFAULT_STORE_AVATAR, ASSET_CDN_URL } from '../Constants';
import { formatDatesWithTimeZone } from '../../helpers/DateUtils.js';
import { getProfile, postShiftCompletion } from '../Api.js';
import { SHIFT_STATUSES } from '../Constants.js';
import CompleteShiftModal from './CompleteShiftModal.jsx';
import './LocumShiftCard.scss';

const dateIcon = `${ASSET_CDN_URL}/shift-card/shift-date.png`;
const timeIcon = `${ASSET_CDN_URL}/shift-card/shift-time.png`;
const locationIcon = `${ASSET_CDN_URL}/shift-card/shift-location.png`;

export const MarkShiftAsCompleteButton = ({ shift }) => {
  const { profile, setProfile } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const clickOpen = (e) => setOpen(true);
  const close = () => setOpen(false);
  const markShiftAsComplete = (invoiceGeneration, review, setLoading) => {
    const { completedStripeOnboarding } = profile;
    if (completedStripeOnboarding || !invoiceGeneration) {
      setLoading(true);
      postShiftCompletion(shift._id, invoiceGeneration, review)
        .then(() => {
          setLoading(false);
          getProfile().then(({ profile }) => {
            setProfile(profile);
            close();
          });
        });
    }
    else {
      navigate('/locum/payments/manage');
    }
  }
  return (
    <>
      <button className={'shift-button complete-button very-dark'} onClick={clickOpen}>{'Mark Shift As Complete'}</button>
      { open && <CompleteShiftModal confirm={markShiftAsComplete} closeModal={close} shift={shift} /> }
    </>
  );
};

const LocumShiftCard = ({ shift, select = null }) => {
  const { name, startTime, endTime, address, rate, total, status, unpaidBreakTime, businessId, storeId, firstName, lastName, avatar, negotiable, providerNumber, invoiceLink, billToMedicare, type } = shift;
  const { suburb, state, timeZone } = address;
  const navigate = useNavigate();
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);
  const incomplete = endTime < new Date().getTime() && (status === SHIFT_STATUSES.CONFIRMED.key || status === SHIFT_STATUSES.FILLED.key);
  const viewShift = () => navigate(`/find/id=${shift._id}`);
  const sendMessage = () => navigate('/user/messages', { state: { business: { _id: businessId, firstName, lastName }, store: { _id: storeId, avatar: avatar ? avatar : DEFAULT_STORE_AVATAR[type], name } } });
  const unpaidTimeDisplay = (unpaidBreakTime / MILLISECONDS_IN_AN_HOUR).toFixed(2);
  const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(startDate, endDate, false, timeZone);
  const rateDisplay = billToMedicare ? 'Rate: NA' : `$${(rate / 100).toFixed(2)}/hr`;
  const totalDisplay = billToMedicare ? 'NA' : `$${(total / 100).toFixed(2)}`;
  const currentStatus = incomplete ? SHIFT_STATUSES.INCOMPLETE.key : status;
  const { className, locumText, hasSubtitle, subtitle } = SHIFT_STATUSES[currentStatus];
  const filled = status === SHIFT_STATUSES.FILLED.key;
  const isInvoice = status === SHIFT_STATUSES.INVOICE.key;
  const downloadInvoice = () => {
    const link = document.createElement('a');
    link.href = invoiceLink;
    link.click();
  };
  return (
    <li className={'shift-card locum'}>
      <section className={'shift-details'}>
        <section className={`${className} status`}>
          {locumText}
          {hasSubtitle && <span className={'subtitle'}>{subtitle}</span>}
        </section>
        <h2 className={'store-name'}>{name}</h2>
        <span className={'shift-date'}>
          <img className={'shift-icon'} src={dateIcon} alt={'Date icon'} />
          {date}
        </span>
        <span className={'shift-time'}>
          <img className={'shift-icon'} src={timeIcon} alt={'Time icon'} />
          {`${startTimeDisplay} - ${endTimeDisplay}`}
        </span>
        <span className={'shift-location'}>
          <img className={'shift-icon'} src={locationIcon} alt={'Location icon'} />
          {`${suburb}, ${state}`}
        </span>
        <span className={'shift-break-time'}>{`Unpaid break: ${unpaidTimeDisplay} hrs`}</span>
        <span className={'shift-rate'}>{rateDisplay}</span>
        <span className={'shift-total'}>{`Total: ${totalDisplay}`}</span>
        { billToMedicare && <span className={'shift-rate'}>{'Locum takes home Medicare billing'}</span> }
        { !billToMedicare &&
          <span className={`shift-negotiable ${negotiable ? 'negotiable' : ''}`}>{negotiable ? 'Rate Negotiable' : 'Rate Fixed'}</span>
        }
        { !!providerNumber && <span className={'shift-provider-number'}>{`Provider Number: ${providerNumber}`}</span>}
      </section>
      <section className={'shift-buttons'}>
        { incomplete && <MarkShiftAsCompleteButton shift={shift} /> }
        { isInvoice &&
          <button className={'shift-button download-invoice-button very-light'} onClick={downloadInvoice}>
            {'View Invoice'}
          </button>
        }
        { filled && <EnterProviderNumberButton additionalClassName={'shift-button'} shift={shift} />}
        <button className={'shift-button message-button very-dark'} onClick={sendMessage}>{`Message Store`}</button>
        <button className={'shift-button view-button very-light'} onClick={viewShift}>{'View Shift'}</button>
      </section>
    </li>
  );
};

export default LocumShiftCard;