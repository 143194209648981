import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import useAuth from '../../hooks/AuthHook.jsx';
import StorePage from './StorePage.jsx';
import { postNewPreferences, postNewSchedule, getProfile } from '../../Api.js';
import './ManagePreferencesPage.scss';
import { NAVIGATION_OPTIONS } from '../BusinessConstants.js';
import { DAYS_OF_WEEK_KEY, MILLISECONDS_IN_AN_HOUR } from '../../Constants.js';
import { FLOAT_REGEX, MAXIMUM_RATE } from './AddShiftsPage.jsx';
import { DateTimeDropdown, Dropdown, TimeDropdown } from './AddShiftsPage.jsx';

export const SOFTWARES = {
  OPTOMETRY: {
    SOCRATES: { key: 'SOCRATES', text: 'Socrates' },
    LUMI: { key: 'LUMI', text: 'LUMI' },
    OPTOMATE: { key: 'OPTOMATE', text: 'Optomate' },
    MAGENTO: { key: 'MAGENTO', text: 'Magento' },
    SUNIX: { key: 'SUNIX', text: 'SUNIX' },
    OTHER: {key: 'OTHER', text: 'Other'}
  },
  PHARMACY: {
    FRED_DISPENSE: { key: 'FRED_DISPENSE', text: 'Fred Dispense' },
    Z_DISPENSE: { key: 'Z_DISPENSE', text: 'Z Dispense' },
    STRONGROOM: { key: 'STRONGROOM', text: 'Strongroom' },
    AQUARIUS: { key: 'AQUARIUS', text: 'Aquarius' },
    MINFOS: { key: 'MINFOS', text: 'MINFOS' },
    LOTS_DISPENSE: { key: 'LOTS_DISPENSE', text: 'Lots Dispense' }
  }
};

export const EQUIPMENTS = {
  OPTOMETRY: {
    FUNDUS_CAMERA: { key: 'FUNDUS_CAMERA', text: 'Fundus Camera' },
    OCT: { key: 'OCT', text: 'OCT' },
    OPTOS: { key: 'OPTOS', text: 'OPTOS' },
    VF: { key: 'VF', text: 'Visual Fields' },
    AR: { key: 'AR', text: 'Autorefractor' },
    AP: { key: 'AP', text: 'Autophoropter' }, 
  },
};

const StoreDetailCard = ({ day, initialDaySchedule, saveSchedule }) => {
  const rateRef = useRef(null);
  const [daySchedule, setDaySchedule] = useState(initialDaySchedule);
  const save = (newDaySchedule) => {
    setDaySchedule(newDaySchedule);
    saveSchedule(day.key, newDaySchedule)
  };
  const setStartTime = (startTime) => {
    const duration = endTime - startTime;
    const unpaidBreakTime = duration <= daySchedule.unpaidBreakTime * 60 * 1000 ? 0 : daySchedule.unpaidBreakTime;
    const newDaySchedule = { ...daySchedule, startTime, unpaidBreakTime };
    save(newDaySchedule);
  };
  const setEndTime = (endTime) => {
    const duration = endTime - startTime;
    const unpaidBreakTime = duration <= daySchedule.unpaidBreakTime * 60 * 1000 ? 0 : daySchedule.unpaidBreakTime;
    const newDaySchedule = { ...daySchedule, endTime, unpaidBreakTime };
    save(newDaySchedule);
  };
  const setRate = (e) => {
    if (e.target.value === "" || (FLOAT_REGEX.test(e.target.value) && parseFloat(e.target.value) <= MAXIMUM_RATE)) {
      const rate = e.target.value;
      const newDaySchedule = { ...daySchedule, rate };
      save(newDaySchedule);
    }
  };
  const setNegotiable = () => {
    const newDaySchedule = { ...daySchedule, negotiable: !negotiable };
    save(newDaySchedule);
  };
  const setBreakTime = (breakTime) => {
    const newDaySchedule= { ...daySchedule, unpaidBreakTime: breakTime };
    save(newDaySchedule);
  };

  const { startTime, endTime, rate, unpaidBreakTime, negotiable } = daySchedule;
  const duration = ((endTime - startTime) / MILLISECONDS_IN_AN_HOUR).toFixed(1);
  const total = ((rate * (duration - unpaidBreakTime / 60))).toFixed(2);

  return (
    <section className={'store-detail-card'}>
      <section className={'date-title'}>{day.text}</section>
      <section className={'time-and-rate'}>
        <section className={'time-from'}>
          <span className={'label'}>{'From'}</span>
          <DateTimeDropdown selected={startTime} setSelected={setStartTime} endTimeOffset={endTime} />
        </section>
        <section className={'time-to'}>
          <span className={'label'}>{'To'}</span>
          <DateTimeDropdown selected={endTime} setSelected={setEndTime} startTimeOffset={startTime} />
        </section>
        <section className={'rate'}>
          <span className={'label'}>{'Rate per hr'}</span>
          <span className={'rate-input-container'}>
            <span className={'currency-sign'}>{'$'}</span>
            <input
              ref={rateRef}
              onClick={() => rateRef.current.focus()}
              className={'rate-input'}
              type={'text'}
              placeholder={'0.00'}
              value={rate}
              onChange={setRate}
            />
          </span>
        </section>
      </section>
      <section className={'duration-and-total'}>
        <span className={'duration'}>{`${duration} hours`}</span>
        <span className={'total'}>{`$${total}`}</span>
      </section>
      <section className={'unpaid-break'}>
        <span className={'label'}>{'Unpaid break'}</span>
        <TimeDropdown selected={unpaidBreakTime} setSelected={setBreakTime} intervalSize={30} maxSize={Math.min(duration * 60, 120)} isDateTime={true} />
      </section>
      <section className={'negotiable'}>
        <span className={'label'}>{'Rate negotiable'}</span>
        <input type={'checkbox'} checked={negotiable} onChange={setNegotiable} />
      </section>
    </section>
  );
};

const PreferenceQuestion = ({ text, isAdditionalQuestion = false, deleteQuestion = () => {}, index = null, disabled = false, children }) => {
  return (
    <section className={'preference-question'}>
      <section className={'question'}>
        { isAdditionalQuestion && 
          <button className={'delete-button very-dark'} onClick={() => deleteQuestion(index)}>{'×'}</button>
        }
        <span className={'text'}>
          {text}
        </span>
      </section>
      {children}
    </section>
  );
};

const ManagePreferences = ({ preferenceType, preferences, children, editing, setEditing, save, cancel }) => {
  const [addedQuestions, setAddedQuestions] = useState(preferences.additionalQuestions ? preferences.additionalQuestions : []);
  const [question, setQuestion] = useState('');
  const addExtraQuestion = () => {
    if (question.length > 0) {
      const newAddedQuestions = [ ...addedQuestions, { text: question }];
      setAddedQuestions(newAddedQuestions);
      setQuestion('');
    } 
  };
  const deleteQuestion = (currIndex) => {
    const newAddedQuestion = [ ...addedQuestions ].filter((_, index) => index !== currIndex);
    setAddedQuestions(newAddedQuestion);
  }

  const cancelChanges = () => {
    setAddedQuestions(preferences.additionalQuestions ? preferences.additionalQuestions : [])
    setQuestion('');
    cancel();
  }

  const additionalQuestions = addedQuestions.map(
    (question, index) => <PreferenceQuestion text={question.text} isAdditionalQuestion={true} deleteQuestion={deleteQuestion} index={index} disabled={!editing} />
  );
  const editOrSave = () => {
    if (!editing) {
      setEditing(true);
    }
    else {
      save(addedQuestions);
    }
  }
  return (
    <section className={`manage-preferences ${preferenceType} ${editing ? '' : 'disabled'}`}>
      <h2 className={'subtitle'}>{`Tell us how a Locum can better match your store's needs`}</h2>
      {children}
      { additionalQuestions.length > 0 &&
          <section className={'additional-questions-section'}>
            <h3 className={'title'}>{'Additional questions for the Locum to answer:'}</h3>
            {additionalQuestions}
          </section>  
      }
      <section className={'add-question'}>
        <button className={'add-extra-question very-dark'} onClick={addExtraQuestion} disabled={!editing}>
          {`+ Add extra question for locum`}
        </button>
        <textarea className={'extra-question-text-area'} onChange={(e) => setQuestion(e.target.value)} disabled={!editing} />
      </section>
      <section className={'buttons'}>
        { editing && <button className={'reset-button very-light'} onClick={cancelChanges}>{'Cancel'}</button> }
        <button className={`edit-button very-dark`} onClick={editOrSave}>{editing ? 'Save' : 'Edit'}</button>
      </section>
    </section>
  )
}

const OPTOMETRY_TESTING_TIMES = {
  15: {
    key: 15,
    text: '15 minutes',
  },
  20: {
    key: 20,
    text: '20 minutes',
  },
  25: {
    key: 25,
    text: '25 minutes',
  },
  30: {
    key: 30,
    text: '30 minutes',
  },
  35: {
    key: 35,
    text: '35 minutes',
  },
  40: {
    key: 40,
    text: '40 minutes',
  },
  0 : {
    key: 0,
    text: 'No preference',
  },
};

const YesNoAnswer = ({ select, selected, disabled }) => {
  return (
    <section className={'yes-no-answer'}>
      <button
        className={`yes-no-button ${selected ? 'selected' : 'very-light'}`}
        onClick={() => select(true)}
        disabled={selected || disabled}
      >
        {'Yes'}
      </button>
      <button
        className={`yes-no-button ${!selected ? 'selected' : 'very-light'}`}
        onClick={() => select(false)}
        disabled={!selected || disabled}
      >
        {'No'}
      </button>
    </section>
  );
};

const Multiselect = ({ selected, select, options, hasOtherOption, other, setOther, disabled = false }) => {
  const selectOption = (key) => {
    const newSelection = new Set(selected);
    if (selected.has(key)) {
      newSelection.delete(key);
    }
    else {
      newSelection.add(key);
    }
    select(newSelection);
  }
  const buttons = options.map(option =>
    <button className={`multiselect-button ${selected.has(option.key) ? 'selected very-dark' : 'very-light'}`} onClick={() => selectOption(option.key)} disabled={disabled}>{option.text}</button>
  );
  return (
    <section className={'multiselect'}>
      <section className={'multiselect-buttons'}>
        {buttons}
      </section>
      {hasOtherOption &&
        <span className={'other-option'}>
          <span className={'label'}>{'Other: '}</span>
          <input type={'text'} onChange={(e) => setOther(e.target.value)} value={other} defaultValue={'Other'} disabled={disabled} />
        </span>
      }
    </section>
  )
}

const ManageOptometryPreferences = ({ preferences, setProfile, storeId }) => {
  const [editing, setEditing] = useState(false);
  const [interval, setInterval] = useState(preferences.interval ? preferences.interval : 0);
  const [pretesting, setPretesting] = useState(preferences.pretesting ? preferences.pretesting : false);
  const [previousNetworkExperience, setPreviousNetworkExperience] = useState(preferences.previousNetworkExperience ? preferences.previousNetworkExperience : false);
  const [softwares, setSoftwares] = useState(preferences.softwares ? new Set(preferences.softwares) : new Set());
  const [otherSoftware, setOtherSoftware] = useState(preferences.otherSoftware ? preferences.otherSoftware : '');
  const [equipments, setEquipments] = useState(preferences.equipments ? new Set(preferences.equipments) : new Set());
  const [otherEquipment, setOtherEquipment] = useState(preferences.otherEquipment ? preferences.otherEquipment : '');
  const [travel, setTravel] = useState(preferences.travel ? preferences.travel : false);
  const [accommodation, setAccommodation] = useState(preferences.accommodation ? preferences.accommodation : false);
  const cancelPreferences = () => {
    setInterval(preferences.interval ? preferences.interval : 0);
    setPretesting(preferences.pretesting ? preferences.pretesting : false);
    setPreviousNetworkExperience(preferences.previousNetworkExperience ? preferences.previousNetworkExperience : false);
    setSoftwares(preferences.softwares ? new Set(preferences.softwares) : new Set());
    setOtherSoftware(preferences.otherSoftware ? preferences.otherSoftware : '');
    setEquipments(preferences.equipments ? new Set(preferences.equipments) : new Set());
    setOtherEquipment(preferences.otherEquipment ? preferences.otherEquipment : '');
    setTravel(preferences.travel ? preferences.travel : false);
    setAccommodation(preferences.accommodation ? preferences.accommodation : false);
    setEditing(false);
  };
  const savePreferences = (additionalQuestions) => {
    const newPreferences = {
      interval,
      pretesting,
      previousNetworkExperience,
      softwares: [...softwares],
      otherSoftware,
      equipments: [...equipments],
      otherEquipment,
      travel,
      accommodation,
      additionalQuestions,
    };

    postNewPreferences(storeId, newPreferences)
    .then(() => {
      getProfile()
        .then(({ profile }) => {
          setProfile(profile);
          setEditing(false);
        });
    });
  }
  return (
    <ManagePreferences preferenceType={'optometry'} editing={editing} setEditing={setEditing} cancel={cancelPreferences} preferences={preferences} save={savePreferences}>
      <PreferenceQuestion text={`What is the store's appointment intervals?`}>
        <Dropdown options={Object.values(OPTOMETRY_TESTING_TIMES)} selected={interval} setSelected={setInterval} disabled={!editing} />
      </PreferenceQuestion>
      <PreferenceQuestion text={`Does the store's staff provide pre-testing?`}>
        <YesNoAnswer select={setPretesting} selected={pretesting} disabled={!editing} />
      </PreferenceQuestion>
      <PreferenceQuestion text={`Do you require the store to have past experience in your store network?`}>
        <YesNoAnswer select={setPreviousNetworkExperience} selected={previousNetworkExperience} disabled={!editing} />
      </PreferenceQuestion>
      <PreferenceQuestion text={`What software does your store use?`}>
        <Multiselect
          selected={softwares}
          select={setSoftwares}
          options={Object.values(SOFTWARES.OPTOMETRY)}
          hasOtherOption={true}
          other={otherSoftware}
          setOther={setOtherSoftware}
          disabled={!editing}
        />
      </PreferenceQuestion>
      <PreferenceQuestion text={`What equipment does your store use?`}>
        <Multiselect
          selected={equipments}
          select={setEquipments}
          options={Object.values(EQUIPMENTS.OPTOMETRY)}
          hasOtherOption={true}
          other={otherEquipment}
          setOther={setOtherEquipment}
          disabled={!editing}
        />
      </PreferenceQuestion>
      <PreferenceQuestion text={`Can travel be arranged for Locums?`}>
        <YesNoAnswer select={setTravel} selected={travel} disabled={!editing} />
      </PreferenceQuestion>
      <PreferenceQuestion text={`Can accommodation be arranged for Locums?`}>
        <YesNoAnswer select={setAccommodation} selected={accommodation} disabled={!editing} />
      </PreferenceQuestion>
    </ManagePreferences>
  );
};

export const DAILY_SCRIPT_VOLUME = {
  "LESS_THAN_100": {
    key: 'LESS_THAN_100',
    value: 0,
    text: 'Less than 100',
    displayText: 'less than 100',
  },
  "100_TO_150": {
    key: '100_TO_150',
    value: 100,
    text: '100 to 150',
    displayText: '100 to 150',
  },
  "150_TO_180": {
    key: '150_TO_180',
    value: 150,
    text: '150 to 180',
    displayText: '150 to 180',
  },
  "MORE_THAN_180": {
    key: 'MORE_THAN_180',
    value: 180,
    text: 'More than 180',
    displayText: 'more than 180',
  },
};

export const SUPPORTS = {
  OTHER_PHARMACISTS: {
    key: 'OTHER_PHARMACISTS',
    text: 'Other Pharmacists',
  },
  DISPENSING_TECHNICIAN: {
    key: 'DISPENSING_TECHNICIAN',
    text: 'Dispensing Technician',
  },
  PHARMACY_INTERN: {
    key: 'PHARMACY_INTERN',
    text: 'Pharmacy Intern',
  },
  PHARMACY_STUDENTS: {
    key: 'PHARMACY_STUDENTS',
    text: 'Pharmacy Students',
  }
}

const ManagePharmacyPreferences = ({ preferences, setProfile, storeId }) => {
  const [editing, setEditing] = useState(false);
  const [dailyScriptVolume, setDailyScriptVolume] = useState(preferences.dailyScriptVolume ? preferences.dailyScriptVolume : DAILY_SCRIPT_VOLUME['LESS_THAN_100'].key);
  const [supports, setSupports] = useState(preferences.supports ? new Set(preferences.supports) : new Set());
  const [softwares, setSoftwares] = useState(!preferences.softwares ? new Set(preferences.softwares) : new Set());
  const [otherSoftware, setOtherSoftware] = useState(preferences.otherSoftware ? preferences.otherSoftware : '');
  const [travel, setTravel] = useState(preferences.travel ? preferences.travel : false);
  const [accommodation, setAccommodation] = useState(preferences.accommodation ? preferences.accommodation : false);
  const cancelPreferences = () => {
    setDailyScriptVolume(preferences.dailyScriptVolume ? preferences.dailyScriptVolume : DAILY_SCRIPT_VOLUME['LESS_THAN_100'].key);
    setSupports(preferences.supports ? new Set(preferences.supports) : new Set());
    setSoftwares(preferences.softwares ? new Set(preferences.softwares) : new Set());
    setOtherSoftware(preferences.otherSoftware ? preferences.otherSoftware : '');
    setTravel(preferences.travel ? preferences.travel : false);
    setAccommodation(preferences.accommodation ? preferences.accommodation : false);
    setEditing(false);
  };

  const savePreferences = (additionalQuestions) => {
    const newPreferences = {
      dailyScriptVolume,
      supports: [...supports],
      softwares: [...softwares],
      otherSoftware,
      travel,
      accommodation,
      additionalQuestions,
    };

    postNewPreferences(storeId, newPreferences)
      .then(() => {
        getProfile()
          .then(({ profile }) => {
            setProfile(profile);
            setEditing(false);
          });
      });
  }
  return (
    <ManagePreferences preferenceType={'pharmacy'} editing={editing} setEditing={setEditing} cancel={cancelPreferences} preferences={preferences} save={savePreferences}>
      <PreferenceQuestion text={`What is the daily script volume? (Rx/day)`}>
        <Dropdown selected={dailyScriptVolume} setSelected={setDailyScriptVolume} options={Object.values(DAILY_SCRIPT_VOLUME)} disabled={!editing} />
      </PreferenceQuestion>
      <PreferenceQuestion text={`What support staff is available at the store?`}>
        <Multiselect
          selected={supports}
          select={setSupports}
          options={Object.values(SUPPORTS)}
          hasOtherOption={false}
          disabled={!editing}
        />
      </PreferenceQuestion>
      <PreferenceQuestion text={`What software does your store use?`}>
        <Multiselect
          selected={softwares}
          select={setSoftwares}
          options={Object.values(SOFTWARES.PHARMACY)}
          hasOtherOption={true}
          other={otherSoftware}
          setOther={setOtherSoftware}
          disabled={!editing}
        />
      </PreferenceQuestion>
      <PreferenceQuestion text={`Can travel be arranged for Locums?`}>
        <YesNoAnswer select={setTravel} selected={travel} disabled={!editing} />
      </PreferenceQuestion>
      <PreferenceQuestion text={`Can accommodation be arranged for Locums?`}>
        <YesNoAnswer select={setAccommodation} selected={accommodation} disabled={!editing} />
      </PreferenceQuestion>
    </ManagePreferences>
  );
};

const DEFAULT_SCHEDULE = {
  MONDAY: {
    startTime: 9 * MILLISECONDS_IN_AN_HOUR,
    endTime: 17 * MILLISECONDS_IN_AN_HOUR,
    rate: 80.00,
    unpaidBreakTime: 30,
    negotiable: true,
  },
  TUESDAY: {
    startTime: 9 * MILLISECONDS_IN_AN_HOUR,
    endTime: 17 * MILLISECONDS_IN_AN_HOUR,
    rate: 80.00,
    unpaidBreakTime: 30,
    negotiable: true,
  },
  WEDNESDAY: {
    startTime: 9 * MILLISECONDS_IN_AN_HOUR,
    endTime: 17 * MILLISECONDS_IN_AN_HOUR,
    rate: 80.00,
    unpaidBreakTime: 30,
    negotiable: true,
  },
  THURSDAY: {
    startTime: 9 * MILLISECONDS_IN_AN_HOUR,
    endTime: 17 * MILLISECONDS_IN_AN_HOUR,
    rate: 80.00,
    unpaidBreakTime: 30,
    negotiable: true,
  },
  FRIDAY: {
    startTime: 9 * MILLISECONDS_IN_AN_HOUR,
    endTime: 17 * MILLISECONDS_IN_AN_HOUR,
    rate: 80.00,
    unpaidBreakTime: 30,
    negotiable: true,
  },
  SATURDAY: {
    startTime: 9 * MILLISECONDS_IN_AN_HOUR,
    endTime: 17 * MILLISECONDS_IN_AN_HOUR,
    rate: 80.00,
    unpaidBreakTime: 30,
    negotiable: true,
  },
  SUNDAY: {
    startTime: 9 * MILLISECONDS_IN_AN_HOUR,
    endTime: 17 * MILLISECONDS_IN_AN_HOUR,
    rate: 80.00,
    unpaidBreakTime: 30,
    negotiable: true,
  },
};


const ManageStoreDetails = ({ store, setProfile }) => {
  const [schedule, setSchedule] = useState(store.schedule ? store.schedule : DEFAULT_SCHEDULE);
  const saveSchedule = (day, daySchedule) => {
    const newSchedule = { ...schedule };
    newSchedule[day] = daySchedule;
    setSchedule(newSchedule)
    postNewSchedule(store._id, newSchedule)
      .then(() => getProfile().then(({ profile }) => setProfile(profile)));
  }
  const storeDetailCards = DAYS_OF_WEEK_KEY.map(day => {
    return <StoreDetailCard day={day} initialDaySchedule={schedule[day.key]} saveSchedule={saveSchedule} />;
  });
  return (
    <section className={'store-details'}>
      <h2 className={'subtitle'}>{'Fill out your store details'}</h2>
      <ul className={'store-detail-list'}>
        {storeDetailCards}
      </ul>
    </section>
  );
}

const ManagePreferencesPage = () => {
  const { state } = useLocation();
  const { profile, setProfile } = useAuth();
  const store = profile.stores.find((store) => (state && store._id === state.storeId)) || profile.stores[0];
  const isPharmacy = store.type === 'PHARMACY';
  const { preferences } = store;
  return (
    <StorePage mode={NAVIGATION_OPTIONS.PREFERENCES.mode}>
      <article className={'manage-preferences-page'}>
        <h1 className={'title'}>{'Manage Preferences'}</h1>
        <section className={'preferences'}>
          <ManageStoreDetails store={store} setProfile={setProfile} />
          { !isPharmacy 
            ? <ManageOptometryPreferences preferences={preferences} setProfile={setProfile} storeId={store._id} /> 
            : <ManagePharmacyPreferences preferences={preferences} setProfile={setProfile} storeId={store._id} /> 
          } 
        </section>
      </article>
    </StorePage>
  )
}

export default ManagePreferencesPage;
