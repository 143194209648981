import './ProgressBar.scss';

import useStoreProfile from '../../../../../hooks/StoreProfileContext';

function ProgressBar({ stepNum }) {
  const { storeProfile } = useStoreProfile();
  const { theme } = storeProfile;
  const { primaryHexColor } = theme;
  return (
    <section className={'progress-bar'}>
      <span className={`bar-progress ${stepNum}`} style={{ background: primaryHexColor }} />
    </section>
  );
}

export default ProgressBar;