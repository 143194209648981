import React from 'react';
import './PartnerCard.scss';

const PartnerCard = ({ name, title, image, url }) => {
  return (
    <li className={'partner-card'}>
      <a className={'partner-card-link very-light'} href={url} target={'_blank'} rel={'noreferrer noopener'}>
        <img className={'partner-image'} src={image} alt={`${name} Logo`} />
        <span className={'partner-name'}>{name}</span>
        <span className={'partner-title'}>{title}</span>
      </a>
    </li>
  );
};

export default PartnerCard;