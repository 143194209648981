import React, { useState } from 'react';
import { formatDatesWithTimeZone } from '../../helpers/DateUtils';
import { ASSET_CDN_URL } from '../Constants';
import { computeDistance } from '../../helpers/ShiftUtils.js';
import './MapPopup.scss';

const ShiftDropdownArrow = `${ASSET_CDN_URL}/dropdown/shift-rec-drop-arrow.svg`;

export const MapStorePopup = ({ store, shifts, location }) => {
  const { name, address } = store;
  const { suburb, state, coordinates } = address;
  const distance = computeDistance(location.coordinates, coordinates, 'kilometers');
  return (
    <div className={'popup-shift-carousel'}>
      <div className={'carousel-item active-shift'}>
        <a className={'shift-tag'} href={`/find/store/view?id=${store._id}`} target="_blank" rel="noopener noreferrer">
          <div className={'shift-title'}>
            <span className={'shift-name'}>{name}</span>
            <span className={'shift-address'}>{`${suburb}, ${state}`}</span>
          </div>
          <div className={'shift-distance'}>{`${distance.toFixed(1)} KM away`}</div>
          <div className={'store-shifts'}>
            <span className={'shift-rate-display'}>{`${shifts.length} ${shifts.length === 1 ? 'shifts' : 'shift'} available`}</span>
          </div>
        </a>
      </div>
    </div>
  )
}

const MapShiftItem = ({ shift, location, index, currentIndex }) => {
  const { name, address, startTime, endTime, negotiable, billToMedicare, rate } = shift;
  const { suburb, state, coordinates, timeZone } = address;
  const isActive = index === currentIndex;
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);
  const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(startDate, endDate, false, timeZone);
  const rateDisplay = billToMedicare ? 'Rate: NA' : `$${(rate / 100).toFixed(2)}/hr`;
  const distance = computeDistance(location.coordinates, coordinates, 'kilometers');

  return(
    <div className={`carousel-item ${isActive ? 'active-shift' : ''}`}>
      <a className={'shift-tag'} href={`/find/shift?id=${shift._id}`} target="_blank" rel="noopener noreferrer">
        <div className={'shift-title'}>
          <span className={'shift-name'}>{name}</span>
          <span className={'shift-address'}>{`${suburb}, ${state}`}</span>
        </div>
        <div className={'shift-time'}>
          <span className={'shift-date'}>{date}</span>
          <span className={'shift-hours'}>{`${startTimeDisplay} - ${endTimeDisplay}`}</span>
        </div>
        <div className={'shift-distance'}>{`${distance.toFixed(1)} KM away`}</div>
        <div className={'shift-rate'}>
          <span className={'shift-rate-display'}>{rateDisplay}</span>
          <span className={'shift-nego'}>{negotiable ? 'Rate Negotiable' : 'Rate Fixed'}</span>
        </div>
      </a>
    </div>
  )
}

const MapShiftPopup = ({shifts, location}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? shifts.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  }

  const goToNext = () => {
    const isLastSlide = currentIndex === shifts.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  }
  
  return (
    <div className={'popup-shift-carousel'}>
      <div className={'direction-shift'}>{`${currentIndex + 1}/${shifts.length}`}</div>
      <div className={'left-arrow'} onClick={() => goToPrevious()}>
        <img className={'slide-arr-left'} src={ShiftDropdownArrow} alt={'left-arrow'}/>
      </div>
      <div className={'right-arrow'} onClick={() => goToNext()}>
        <img className={'slide-arr-right'} src={ShiftDropdownArrow} alt={'right-arrow'}/>
      </div>
      {shifts.map((shift, index) => <MapShiftItem shift={shift} location={location} index={index} currentIndex={currentIndex}/>)}
    </div>
  )
}

export default MapShiftPopup;