import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/AuthHook.jsx';
import { orderShifts } from '../../helpers/ShiftUtils.js';
import { MONTHS, NETWORKS, ASSET_CDN_URL } from '../Constants';
import { SOFTWARES, EQUIPMENTS, DAILY_SCRIPT_VOLUME } from '../business/store/ManagePreferencesPage.jsx';
import { getManyShifts } from '../Api.js';
import { useOutsideClick, useMediaQuery } from '../hooks/CommonHooks.jsx';
import { DEFAULT_PERSON_AVATAR } from '../Constants.js';
import { postLocumProfileEdit, postNewExperiences } from '../Api.js';
import { removeItemOnce } from '../../helpers/arrayHelpers';
import { LOCUM_NAVIGATION_BUTTONS } from './LocumNavigationSidebar.jsx';
import LocumPage from './LocumPage.jsx';
import './LocumProfilePage.scss';

// Icon Imports
const EditIcon = `${ASSET_CDN_URL}/icons/edit-pen-icon.svg`;
const SaveIcon = `${ASSET_CDN_URL}/icons/save-tick-icon.svg`;
const WhiteLogo = `${ASSET_CDN_URL}/logo/white.png`;
const DeleteIcon = `${ASSET_CDN_URL}/icons/delete-icon.svg`;
const PlusIcon = `${ASSET_CDN_URL}/icons/white-plus-icon.svg`;

export const YEARS = {
  [-1]: { key: -1, text: '', displayText: '' },
  0: { key: 0, text: '< 1 year', displayText: 'Less than 1 year' },
  1: { key: 1, text: '1 to 2 years', displayText: '1 to 2 years' },
  2: { key: 2, text: '2 to 3 years', displayText: '2 to 3 years' },
  3: { key: 3, text: '3 to 4 years', displayText: '3 to 4 years' },
  4: { key: 4, text: '4 to 5 years', displayText: '4 to 5 years' },
  5: { key: 5, text: '5+ years', displayText: 'More than 5 years' },
};

export const hasExperiencesChanged = (experiences1, experiences2) => {
  return JSON.stringify(experiences1) !== JSON.stringify(experiences2);
}

const MultiPick = ({ addLabel, experiences, onFeatureClick, FEATURE, excludeList = [], tag }) => {
  return (
    <div className={'dropdown-item'}>
      <label className={'experience-label'} for={'networks'}>{addLabel}</label>
      <section id={'networks'} className={'network-items'}>
        {Object.keys(FEATURE).map(key => {
          return !excludeList.includes(key) ? <NetworkButton onFeatureClick={onFeatureClick} featureKey={key} existingSelection={experiences[tag].includes(key)} FEATURE={FEATURE}/> : ''})}
      </section>
    </div>
  )
}

const NetworkButton = ({ onFeatureClick, featureKey, existingSelection, FEATURE }) => {
  const [selected, setSelected] = useState(existingSelection)
  const onSelection = (key) => {
    onFeatureClick(key)
    setSelected(!selected);
  }
  return (
    <button className={`network-button ${selected ? 'selected' : ''}`} onClick={() => onSelection(featureKey)}>
      {FEATURE[featureKey].text}
    </button>
  )
}

const YearsExperience = ({ addLabel, experiences, setExperiences }) => {
  const setYears = (years) => setExperiences({ ...experiences, years });
  return (
    <div className={'dropdown-item'}>
      <label className={'experience-label'} for={'years'}>{'Years of Experience'}</label>
      <select id={'years'} 
      className={'add-dropdown'} 
      defaultValue={(experiences.years === '-1' || experiences.years === undefined || experiences.years === null) ? "" : experiences.years} 
      onChange={(e) => setYears(e.target.value)}>
        <option value="" disabled>{`Add ${addLabel}`}</option>
        {Object.keys(YEARS).map(key => <option value={key}>{YEARS[key].displayText}</option>)}
      </select>
    </div>
  )
}

const IntervalExp = ({ addLabel, experiences, setExperiences }) => {
  const times = Array(6).fill().map((_, i) => 15 + i * 5);
  const setInterval = (interval) => setExperiences({ ...experiences, interval });
  return (
    <div className={'dropdown-item'}>
      <label className={'experience-label'} for={'intervals'}>{'Minimum testing interval you are comfortable with'}</label>
      <select id={'intervals'} 
      className={'add-dropdown'} 
      defaultValue={(experiences.interval === '0' || experiences.interval === undefined) ? "" : experiences.interval} 
      onChange={(e) => setInterval(e.target.value)}>
        <option value="" disabled>{`Add ${addLabel}`}</option>
        {times.map(time => <option value={time}>{`${time} minutes`}</option>)}
      </select>
    </div>
  )
}

const PharmacyScriptExp = ({ addLabel, experiences, setExperiences }) => {
  const setScriptRange = (pharmacyScriptRange) => setExperiences({ ...experiences, pharmacyScriptRange });
  return (
    <div className={'dropdown-item'}>
      <label className={'experience-label'} for={'intervals'}>{'Comfortable Daily Rx Volume'}</label>
      <select id={'intervals'} 
      className={'add-dropdown'} 
      defaultValue={(experiences.pharmacyScriptRange === '-1' || experiences.pharmacyScriptRange === undefined) ? "" : experiences.pharmacyScriptRange} 
      onChange={(e) => setScriptRange(e.target.value)}>
        <option value="" disabled>{`Add ${addLabel}`}</option>
        {Object.keys(DAILY_SCRIPT_VOLUME).map(key => 
          <option value={DAILY_SCRIPT_VOLUME[key].key}>{`${DAILY_SCRIPT_VOLUME[key].displayText}`}</option>
        )}
      </select>
    </div>
  )
}

const AddTag = ({ addLabel, setAddTag, isSkill, profile, setProfile, experiences, setExperiences }) => {
  const { type } = profile;
  const dropdownRef = useRef(null);
  const notIncludedNetworks = ['ONE_THOUSAND_AND_ONE_OPTICAL', '1001_OPTICAL', '']
  useOutsideClick(dropdownRef, () => setAddTag(false));
  const saveExperiences = () => {
    if (hasExperiencesChanged(profile.experiences, experiences)) {
      profile.experiences = experiences;
      postNewExperiences(experiences)
        .then(() => setProfile({ ...profile }))
        .catch((err) => console.error(err));
    }
    setAddTag(false);
  }

  const onNetworkClick = (network) => {
    const newNetworks = new Set(experiences.networks)
    if (newNetworks.has(network)) {
      newNetworks.delete(network);
    }
    else {
      newNetworks.add(network);
    }
    setExperiences({ ...experiences, networks: [ ...newNetworks ]});
  };

  const onSoftwareClick = (software) => {
    const newSoftwares = new Set(experiences.softwares)
    if (newSoftwares.has(software)) {
      newSoftwares.delete(software);
    }
    else {
      newSoftwares.add(software);
    }
    setExperiences({ ...experiences, softwares: [ ...newSoftwares ]});
  };
  const onEquipmentClick = (equipment) => {
    const newEquipments = new Set(experiences.equipments)
    if (newEquipments.has(equipment)) {
      newEquipments.delete(equipment);
    }
    else {
      newEquipments.add(equipment);
    }
    setExperiences({ ...experiences, equipments: [ ...newEquipments ]});
  };
  return(
    <div className={'add-tag-container'}>
      <div className={`add-tag-modal ${type === 'PHARMACY' && isSkill ? 'only-one-skill' : ''}`} ref={dropdownRef}>
        <div className={'add-tag-modal-heading'}>{`Add ${addLabel}`}</div>
        {!isSkill && <YearsExperience addLabel={addLabel} experiences={experiences} setExperiences={setExperiences}/>}
        {!isSkill && type === 'OPTOMETRY' && <IntervalExp addLabel={addLabel} experiences={experiences} setExperiences={setExperiences}/>}
        {!isSkill && type === 'PHARMACY' && <PharmacyScriptExp addLabel={addLabel} experiences={experiences} setExperiences={setExperiences}/>}
        {!isSkill && <MultiPick addLabel={'Networks you have experience working with'} experiences={experiences} onFeatureClick={onNetworkClick} FEATURE={NETWORKS[type]} excludeList={notIncludedNetworks} tag={'networks'}/>}
        {isSkill && <MultiPick addLabel={'Software you are familiar with'} experiences={experiences} onFeatureClick={onSoftwareClick} FEATURE={SOFTWARES[type]} tag={'softwares'}/>}
        {isSkill && type === 'OPTOMETRY' && <MultiPick addLabel={'Equipment you are familiar with'} experiences={experiences} onFeatureClick={onEquipmentClick} FEATURE={EQUIPMENTS[type]} tag={'equipments'}/>}
        <div className={'confirm-tag'} onClick={() => saveExperiences()}>
          <img className={'confirm-icon'} src={SaveIcon} alt={'Save'} />
        </div>
      </div>
    </div>
  )
}

const SkillExpTag = ({ tag = null, value = null, skill, isEditing, addExp = false, isSkill, setAddTag, profile, setProfile, experiences, setExperiences }) => {
  const handleClick = (addExp) => {
    if (addExp) {
      setAddTag(true)
    }
  }

  const removeExperience = () => {
    if (tag === 'years') {
      setExperiences({ ...experiences, years: "-1" })
    } else if (tag === 'interval') {
      setExperiences({ ...experiences, interval: "0" })
    } else if (tag === 'networks') {
      setExperiences({ ...experiences, networks: removeItemOnce(experiences.networks, value) });
    } else if (tag === 'softwares') {
      setExperiences({ ...experiences, softwares: removeItemOnce(experiences.softwares, value) });
    } else if (tag === 'equipments') {
      setExperiences({ ...experiences, equipments: removeItemOnce(experiences.equipments, value) });
    } else if (tag === 'pharmacyScriptRange') {
      setExperiences({ ...experiences, pharmacyScriptRange: '-1' });
    }
  }

  useEffect(() => {
    if (tag !== null && value !== null && hasExperiencesChanged(profile.experiences, experiences)) {
      profile.experiences = experiences;
      postNewExperiences(experiences)
        .then(() => setProfile({ ...profile }))
        .catch((err) => console.error(err));
    }
  }, [tag, value, profile, setProfile, experiences])

  return(
    <div className={`tag-item ${isSkill ? 'skill-tag' : ''}`} onClick={() => handleClick(addExp)}>
      {skill}
      {isEditing 
        ? addExp 
          ? 
          <div className={`add-tag ${isSkill ? 'skill-add' : ''}`}>
            <img src={PlusIcon} alt={'Add'} />
          </div>
          :
          <div className={'delete-tag'} onClick={() => removeExperience()}>
            <img src={DeleteIcon} alt={'Delete'} />
          </div>
        :
        ''
      }
    </div>
  )
}

const ProfileTag = ({ isEditing, isSkill, profile, setProfile, experiences, setExperiences }) => {
  const { type } = profile;
  const [addTag, setAddTag] = useState(false)
  const [tags, setTags] = useState({});
  const { interval, softwares, equipments, years, networks, otherNetworks, pharmacyScriptRange } = experiences;
  useEffect(() => {
    if (isSkill) {
      setTags({equipments, softwares});
    } else {
      setTags({interval, years, networks, otherNetworks, pharmacyScriptRange});
    }
  }, [profile, equipments, softwares, interval, years, networks, otherNetworks, isSkill, pharmacyScriptRange])
  return (
    <section className={'tag-container'}>
      {Object
        .keys(tags)
        .map(tagKey => {
          if (tagKey === 'years') {
            return tags[tagKey] !== "-1" && tags[tagKey] !== null ? <SkillExpTag tag={tagKey} value={tags[tagKey]} skill={`${YEARS[tags[tagKey]].displayText} experience`} isEditing={isEditing} isSkill={isSkill} profile={profile} setProfile={setProfile} experiences={experiences} setExperiences={setExperiences}/> : '';
          } else if (tagKey === 'networks') {
            const networkTags = tags[tagKey].filter(network => NETWORKS[type][network] !== undefined);
            return networkTags.map((network) => <SkillExpTag tag={tagKey} value={network} skill={`Past experience - ${NETWORKS[type][network].text}`} isEditing={isEditing} isSkill={isSkill} profile={profile} setProfile={setProfile} experiences={experiences} setExperiences={setExperiences} />)
          } else if (tagKey === 'interval' && type === 'OPTOMETRY') {
            return tags[tagKey] !== "0" ? <SkillExpTag tag={tagKey} value={tags[tagKey]} skill={`${tags[tagKey]} min testing time`} isEditing={isEditing} isSkill={isSkill} profile={profile} setProfile={setProfile} experiences={experiences} setExperiences={setExperiences} /> : '';
          } else if (tagKey === 'softwares') {
            const softwareTags = tags[tagKey].filter(software => SOFTWARES[type][software] !== undefined);
            return softwareTags.map((software) => <SkillExpTag tag={tagKey} value={software} skill={`Software - ${SOFTWARES[type][software].text}`} isEditing={isEditing} isSkill={isSkill} profile={profile} setProfile={setProfile} experiences={experiences} setExperiences={setExperiences} />)
          } else if (tagKey === 'equipments' && type === 'OPTOMETRY') {
            const equipmentTags = tags[tagKey].filter(equipment => EQUIPMENTS[type][equipment] !== undefined);
            return equipmentTags.map((equipment) => <SkillExpTag tag={tagKey} value={equipment} skill={`Equipment - ${EQUIPMENTS[type][equipment].text}`} isEditing={isEditing} isSkill={isSkill} profile={profile} setProfile={setProfile} experiences={experiences} setExperiences={setExperiences} />)
          } else if (tagKey === 'pharmacyScriptRange' && type === 'PHARMACY') {
            return (tags[tagKey] !== '-1' && tags[tagKey] !== undefined) ? <SkillExpTag tag={tagKey} value={tags[tagKey]} skill={`${DAILY_SCRIPT_VOLUME[tags[tagKey]].displayText} daily rx volume`} isEditing={isEditing} isSkill={isSkill} profile={profile} setProfile={setProfile} experiences={experiences} setExperiences={setExperiences} /> : '';
          }
          return null;
      })}
      {isEditing && <SkillExpTag skill={'Add experience'} isEditing={true} addExp={true} isSkill={isSkill} profile={profile} setProfile={setProfile} experiences={experiences} setExperiences={setExperiences} setAddTag={setAddTag}/>}
      {addTag && <AddTag addLabel={isSkill ? 'Skill' : 'Experience'} setAddTag={setAddTag} isSkill={isSkill} profile={profile} setProfile={setProfile} experiences={experiences} setExperiences={setExperiences}/>}
    </section>
  )
}

const CompletedShiftHistory = ({ completedShifts }) => {
  const navigate = useNavigate();

  let monthlyShifts = null
  let noCompleted = completedShifts === null || Object.keys(completedShifts).length === 0;
  if (noCompleted) {
    monthlyShifts = <div className={'no-completed'}>
                      <span>{"You haven't completed any shifts yet!"}</span>
                      <p>
                        {'Click '}
                        <button className={'find-button'} onClick={() => navigate('/find')}>{'here'}</button>
                        {' to search for shifts!'}
                      </p>
                    </div>
  } else {
    monthlyShifts = Object.keys(completedShifts).map((shiftYear) => 
      Object.keys(completedShifts[shiftYear]).sort((a, b) => b - a).map((shiftMonth) => 
        <div className={'month-container'}>
          <span className={'shift-dates'}>{`${MONTHS.displayText[shiftMonth]}, ${shiftYear}`}</span>
          {completedShifts[shiftYear][shiftMonth].map((shift) => (<span className={'shift-name'}>{shift.name}</span>))}
        </div>
      )
    )
  }

  return (
    <section className={'comp-shift-container'}>
      <div className={'comp-header'}>
        <span className={'comp-text'}>{'Completed Shifts'}</span>
        <small className={'comp-sub'}>{'Most recently completed shifts'}</small>
      </div>
      <div className={`monthly-shifts-container ${noCompleted ? 'no-shifts-complete' : ''}`}>
        {monthlyShifts}
      </div>
    </section>
  )
}

const LocumProfilePage = () => {
  const { profile, setProfile } = useAuth();
  const initialAccountDetails = { 
    firstName: profile.firstName,
    lastName: profile.lastName,
    emailAddress: profile.emailAddress,
    phoneNumber: profile.phoneNumber,
    about: profile.about
  };

  const [about, setAbout] = useState(profile.about);
  const [foundShifts, setFoundShifts] = useState(null)
  const { avatar } = profile;
  const locumAvatar = avatar ? avatar : DEFAULT_PERSON_AVATAR;
  const { firstName, lastName, abn, ahpra, address, completedShifts } = profile;
  const [experiences, setExperiences] = useState(profile.experiences);

  const [isEditing, setIsEditing] = useState()
  const [viewCompleted, setViewCompleted] = useState(false);
  const isMobile = useMediaQuery('(max-width: 980px)');
  const navigate = useNavigate();

  const handleEdit = (isEditing) => {
    if (isEditing && initialAccountDetails.about !== about) {
      postLocumProfileEdit(about, abn, ahpra)
        .then(() => {
          setProfile({ ...profile, about, abn, ahpra });
        })
    }
    setIsEditing(!isEditing);
  }

  useEffect(() => {
    if (!foundShifts && completedShifts.length > 0) {
      getManyShifts(completedShifts).then((response) => {
        let orderedShifts = orderShifts(response.shifts, 'date-descending');
        let monthlyShifts = {}
        orderedShifts.forEach((shift) => {
          let month = new Date(shift.startTime).getMonth();
          let year = new Date(shift.startTime).getFullYear();
          if (monthlyShifts.hasOwnProperty(year)) {
            if (monthlyShifts[year].hasOwnProperty(month)) {
              monthlyShifts[year][month].push(shift);
            } else {
              monthlyShifts[year][month] = [shift];
            }
          } else {
            monthlyShifts[year] = {[month]: [shift]};
          }
        })
        setFoundShifts(monthlyShifts);
      }).catch((err) => console.error(err));
    }
  }, [foundShifts, completedShifts]);

  // Ensure about textarea height matches content (so no overflow scroll within textarea)
  const textAreaRef = useRef(null);
  const MIN_TEXTAREA_HEIGHT = isMobile ? 140 : 80;
  useLayoutEffect(() => {
    if (!viewCompleted) {
      textAreaRef.current.style.height = 'inherit';
      textAreaRef.current.style.height = `${Math.max(
        textAreaRef.current.scrollHeight + 5,
        MIN_TEXTAREA_HEIGHT
      )}px`;
    }
  }, [isEditing, viewCompleted, MIN_TEXTAREA_HEIGHT]);

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, [])

  return (
    <LocumPage mode={LOCUM_NAVIGATION_BUTTONS.PROFILE.mode}>
      <article className={'locum-profile-page'}>
        <section className={'locum-profile-header'}>
          {isMobile ? 
          <>
            <div className={'locum-profile-header-details'}>
              <h1 className={'locum-profile-name'}>{`${firstName} ${lastName}`}</h1>
              <small className={'locum-profile-address'}>{`${address.suburb} ${address.state}`}</small>
              <div className={'locum-profile-abn-ahpra'}>
                <span className={'abn-text'}>
                  {'ABN: '}
                  {abn ? abn : <span className={'red-text'}>{'Missing ABN'}</span>}
                </span>
                <span>
                  {'AHPRA: '}
                  {ahpra ? ahpra : <span className={'red-text'}>{'Missing AHPRA'}</span>}
                </span>
              </div>
              <button className={'manage-account-link'} onClick={() => navigate('/locum/account/manage')}>
                {'Manage Account'}
              </button>
            </div>
            <div className={'locum-avatar-container'}>
              <img className={'locum-avatar'} src={locumAvatar} alt={'Locum Avatar'} />
            </div>
          </>
          :
          <span className={'profile-subtitle'}>{'Stores can only see your profile once you request their shift'}</span>
          }
        </section>
        <section className={`locum-profile-details ${isEditing ? 'edit-background' : ''}`}>
          <div className={'edit-detail-pane'}>
            {(!isEditing && isMobile) && 
            <div className={`shift-overlay-button ${viewCompleted && 'overlay-style'}`}>
              <div className={'profile-background'} onClick={() => setViewCompleted(!viewCompleted)}>
                <div className={`profile-shift-overlay ${!viewCompleted ? 'selected' : ''}`}></div>
                <div className={`profile-shift-overlay ${viewCompleted ? 'selected' : ''}`}></div>
              </div>
            </div>}
            {!viewCompleted && <button className={'edit-detail-button'} onClick={() => handleEdit(isEditing)}>
              <img className={'edit-detail-img'} src={isEditing ? SaveIcon : EditIcon} alt={'Edit profile details'}/>
            </button>}
          </div>
          <div className={'scroll-container'}>
            {!viewCompleted && <div className={'edit-detail'}>
              <div className={'profile-about-me'}>
                <div className={'profile-heading'}>
                  <div className={'profile-tag'}>{'Profile'}</div>
                  <div className={'separator-prof'}></div>
                </div>
                <textarea
                  ref={textAreaRef}
                  className={`about-me-text ${!isEditing ? 'disabled' : ''} `}
                  value={about}
                  onChange={(e) => setAbout(e.target.value)}
                  placeholder={'Tell us about yourself!'}
                  disabled={!isEditing}
                />
              </div>
              <div className={'profile-exp'}>
                <div className={'exp-heading'}>
                  <div className={'exp-tag'}>{'Experience'}</div>
                  <div className={'separator-exp'}></div>
                </div>
                <div className={'exp-text'}>
                  <ProfileTag isEditing={isEditing} isSkill={false} profile={profile} setProfile={setProfile} experiences={experiences} setExperiences={setExperiences} />
                </div>
              </div>
              <div className={'profile-skills'}>
                <div className={'skills-heading'}>
                  <div className={`skills-tag ${isEditing && 'edit-skill'}`}>{'Skills'}</div>
                </div>
                <div className={'skills-text'}>
                  <ProfileTag isEditing={isEditing} isSkill={true} profile={profile} setProfile={setProfile} experiences={experiences} setExperiences={setExperiences} />
                </div>
              </div>
            </div>}
            {(viewCompleted || !isMobile) && <CompletedShiftHistory completedShifts={foundShifts} />}
          </div>
          {isMobile && <div className={'prof-logo-container'}>
            <img src={WhiteLogo} alt={'Logo'} className={'white-logo'} />
          </div>}
        </section>
      </article>
    </LocumPage>
  );
}

export default LocumProfilePage;
