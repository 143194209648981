import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useAuth from '../../hooks/AuthHook.jsx';
import Shift from '../../locum/Shift.jsx';
import StorePage from './StorePage.jsx';
import useStore from '../../hooks/StoreHook.jsx';
import BackArrowButton from '../../common/BackArrowButton.jsx';
import LocumProfile from '../../locum/LocumProfile.jsx';
import { TimeDropdown, YesNoDropdown, FLOAT_REGEX, MAXIMUM_RATE, DateTimeDropdown } from './AddShiftsPage.jsx';
import { MILLISECONDS_IN_AN_HOUR } from '../../Constants.js';
import ConfirmationModal from './ConfirmationModal.jsx';
import { getShiftsForStore, postShiftDeletion, postShiftEdit } from '../../Api.js';
import { NAVIGATION_OPTIONS } from '../BusinessConstants.js';
import BusinessShiftCard from '../common/BusinessShiftCard.jsx';
import { formatDatesWithTimeZone } from '../../../helpers/DateUtils.js';
import './UpcomingShiftsPage.scss'
import { useOutsideClick } from '../../hooks/CommonHooks.jsx';
import { SHIFT_STATUSES } from '../../Constants.js';
import { getTimezoneOffset } from 'date-fns-tz';

const DELETION_OPTIONS = {
  EXISTING_STAFF: {
    key: 'EXISTING_STAFF',
    text: 'Shift filled by existing staff',
  },
  OUTSIDE_STAFF: {
    key: 'OUTSIDE_STAFF',
    text: 'Shift filled outside of Locumly',
  },
  NOT_VACANT: {
    key: 'NOT_VACANT',
    text: 'Shift no longer vacant',
  },
  OTHER: {
    key: 'OTHER',
    text: 'Other',
  }
};

const EDIT_OPTIONS = {
  MISTAKE: {
    key: 'MISTAKE',
    text: 'Mistake with shift details',
  },
  NEGOTIATE: {
    key: 'NEGOTIATE',
    text: 'Pre-negotiated with locum',
  },
  CHANGE_OF_DATE: {
    key: 'CHANGE_OF_DATE',
    text: 'Date needs adjustment',
  },
  INCREASE_SPEED: {
    key: 'INCREASE_SPEED',
    text: 'Want to fill the shift faster',
  },
  OTHER: {
    key: 'OTHER',
    text: 'Other',
  }
};


export const ReasonDropdown = ({ reason, options, setReason }) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);
  useOutsideClick(dropdownRef, () => setOpen(false));
  const reasons = Object.keys(options)
    .map((key) => {
      const onClick = () => {
        setReason(key);
        setOpen(false);
      };
      return (
        <li className={'reason-option'} key={`reason-${key}`}>
          <button className={'reason-option-button very-light'} onClick={onClick}>
            {options[key].text}
          </button>
        </li>
      );
    });
  return (
    <section className={'reason-dropdown'} ref={dropdownRef}>
      <button className={`reason-button very-light ${open ? 'open' : ''}`} onClick={() => setOpen(!open)}>
        {reason === null ? 'Select an option' : options[reason].text}
      </button>
      {open && 
        <section className={'reason-options-container'}>
          <ul className={'reason-options'}>
            {reasons}
          </ul>
        </section>
      }
    </section>
  );
};

const DeleteShiftConfirmation = ({ close, confirm, setReason, reason }) => {
  const [loading, setLoading] = useState(false);
  const onConfirm = () => confirm(setLoading);
  return (
    <ConfirmationModal additionalClassName={'delete-shift-confirmation'} close={close} confirm={onConfirm} loading={loading} confirmText={'Delete this shift'} disabled={reason === null}>
      <h3 className={'confirmation-title'}>{'Are you sure?'}</h3>
        <p className={'confirmation-text'}>
          {`You won't be able to recover this shift or see its applicants once it has been deleted.`}
        </p>
        <section className={'reason-dropdown-container'}>
          <span className={'reason-text'}>{'Reason: '}</span>
          <ReasonDropdown setReason={setReason} reason={reason} options={DELETION_OPTIONS} />
        </section>
    </ConfirmationModal>
  );
}

const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;

export const getTimeOffset = (time, timeZone) => {
  const offsetDate = new Date(time);
  const timeZoneOffset = getTimezoneOffset(timeZone, offsetDate);
  const offsetTimeInUTC = time % DAY_IN_MILLISECONDS;
  const offsetTime = offsetTimeInUTC + timeZoneOffset;
  if (offsetTime < 0) {
    return DAY_IN_MILLISECONDS + offsetTime;
  }
  return offsetTime % DAY_IN_MILLISECONDS;
};

const EditShiftConfirmation = ({ close, confirm, setReason, reason, shift, errorText }) => {
  const { address, startTime, endTime } = shift;
  const { timeZone } = address;
  const { date } = formatDatesWithTimeZone(startTime, endTime, false, timeZone);
  const initialStartTimeOffset = getTimeOffset(startTime, timeZone);
  const initialEndTimeOffset = getTimeOffset(endTime, timeZone);
  const shiftTimeOffset = startTime - initialStartTimeOffset;
  const [startTimeOffset, setStartTimeOffset] = useState(initialStartTimeOffset);
  const [endTimeOffset, setEndTimeOffset] = useState(initialEndTimeOffset);
  const [billToMedicare, setBillToMedicare] = useState(shift.billToMedicare ? shift.billToMedicare : false);
  const [breakTime, setBreakTime] = useState(shift.unpaidBreakTime * 60 / MILLISECONDS_IN_AN_HOUR);
  const [rate, setRate] = useState((shift.rate / 100).toFixed(2));
  const [negotiable, setNegotiable] = useState(shift.negotiable);
  const [loading, setLoading] = useState(false);
  const onChangeRate = (e) => {
    if (e.target.value === "") {
      setRate(0);
    }
    else if (FLOAT_REGEX.test(e.target.value) && parseFloat(e.target.value) <= MAXIMUM_RATE) {
      setRate(e.target.value);
    }
  };
  const selectStartTime = (timeOffset) => {
    const duration = Math.round((endTimeOffset - timeOffset) / MILLISECONDS_IN_AN_HOUR);
    if (duration * 60 < breakTime) {
      setBreakTime(duration * 60);
    }
    setStartTimeOffset(timeOffset);
  };
  const selectEndTime = (timeOffset) => {
    const duration = Math.round((timeOffset - startTimeOffset) / MILLISECONDS_IN_AN_HOUR);
    if (duration * 60 < breakTime) {
      setBreakTime(duration * 60);
    }
    setEndTimeOffset(timeOffset);
  };
  const onClose = () => {
    setStartTimeOffset(startTimeOffset);
    setEndTimeOffset(endTimeOffset)
    setBreakTime(Math.round(shift.unpaidBreakTime * 60 / MILLISECONDS_IN_AN_HOUR));
    setRate((shift.rate / 100).toFixed(2));
    setNegotiable(shift.negotiable);
    setBillToMedicare(shift.billToMedicare);
    close();
  };
  const duration = (endTimeOffset - startTimeOffset) / MILLISECONDS_IN_AN_HOUR;
  const total = ((duration - breakTime / 60) * rate).toFixed(2);
  const onConfirm = () => {
    const newStartTime = shiftTimeOffset + startTimeOffset;
    const newEndTime = shiftTimeOffset + endTimeOffset;
    confirm(setLoading, newStartTime, newEndTime, breakTime, rate, negotiable, total)
  };
  return (
    <ConfirmationModal close={onClose} confirm={onConfirm} confirmText={'Edit this shift'} disabled={reason === null} additionalClassName={'edit'} loading={loading}>
      <h3 className={'confirmation-title'}>{'Are you sure?'}</h3>
        <p className={'confirmation-text'}>
          {`If you have already received applicants for this shift, we will notify them of the changes.`}
        </p>
        <ul className={'shift-changes'}>
          <li className={'change-item'}>
            <span className={'change-label'}>{'Date: '}</span>
            <p className={'change-item'}>{date}</p>
          </li>
          <li className={'change-item'}>
            <span className={'change-label'}>{'Start Time: '}</span>
            <DateTimeDropdown selected={startTimeOffset} setSelected={selectStartTime} endTimeOffset={endTimeOffset} />
          </li>
          <li className={'change-item'}>
            <span className={'change-label'}>{'End Time: '}</span>
            <DateTimeDropdown selected={endTimeOffset} setSelected={selectEndTime} startTimeOffset={startTimeOffset} />
          </li>
          <li className={'change-item'}>
            <span className={'change-label'}>{'Unpaid Break: '}</span>
            <TimeDropdown selected={breakTime} setSelected={setBreakTime} intervalSize={15} maxSize={Math.min(duration * 60, 120)} />
          </li>
          <li className={'change-item'}>
            <span className={'change-label'}>{'Bill to Medicare: '}</span>
            <YesNoDropdown selected={billToMedicare} setSelected={setBillToMedicare} />
          </li>
          <li className={'change-item'}>
            <span className={'change-label'}>{'Rate per hour: '}</span>
            <input className={`rate ${billToMedicare ? 'disabled' : ''}`} type={'text'} placeholder={'0.00'} value={billToMedicare ? 'NA' : rate} onChange={onChangeRate} disabled={billToMedicare}/>
          </li>
          <li className={'change-item'}>
            <span className={'change-label'}>{'Negotiable: '}</span>
            <YesNoDropdown selected={negotiable} setSelected={setNegotiable} disabled={billToMedicare} />
          </li>
          <li className={'change-item'}>
            <span className={'change-label'}>{'Total: '}</span>
            <span className={'bold'}>{`$${total}`}</span>
          </li>
        </ul>
        <section className={'reason-dropdown-container'}>
          <span className={'reason-text'}>{'Reason: '}</span>
          <ReasonDropdown setReason={setReason} reason={reason} options={EDIT_OPTIONS} />
        </section>
        <section className={'error-text'}>{errorText}</section>
    </ConfirmationModal>
  );
}

export const EditShiftButton = ({ shift, editText, select = () => {}, classNames, store }) => {
  const { setShifts } = useStore();
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState(null);
  const close = () => {
    setOpen(false);
    setReason(null);
    setErrorText(null);
  }
  const [errorText, setErrorText] = useState(null);
  const confirm = (setLoading, startTime, endTime, unpaidBreakTime, rate, negotiable, total) => {
    setLoading(true);
    const changeSet = {
      startTime,
      endTime,
      unpaidBreakTime: Math.round((unpaidBreakTime / 60) * MILLISECONDS_IN_AN_HOUR),
      rate: Math.round(rate * 100),
      negotiable,
      total: Math.round(total * 100),
    };
    postShiftEdit(shift._id, changeSet, reason)
      .then(() => {
        getShiftsForStore(shift.storeId)
          .then(({ shifts }) => {
            setShifts(shifts);
            const selectedShift = shifts.find(({ _id }) => _id === shift._id);
            select(selectedShift);
            setLoading(false);
            close();
          });
      })
      .catch(() => setErrorText('An error occurred! Please report this to the administrators.'));
  }
  return (
    <>
      <button className={`${classNames.join(' ')} very-dark`} onClick={() => setOpen(true)}>
        {editText}
      </button>
      { open && <EditShiftConfirmation close={close} confirm={confirm} setReason={setReason} reason={reason} shift={shift} errorText={errorText} /> }
    </>
  );
}

const DeleteShiftButton = ({ shiftId, returnToShifts }) => {
  const { setShifts, storeId } = useStore();
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState(null);
  const close = () => {
    setOpen(false);
    setReason(null);
  }
  const confirm = (setLoading) => {
    setLoading(true);
    postShiftDeletion(shiftId, reason)
      .then(() => {
        getShiftsForStore(storeId)
        .then(({ shifts }) => {
          setShifts(shifts);
          setLoading(false);
          close();
          returnToShifts();
        })
        .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  }
  return (
    <>
      <button className={'delete-shift shift-button very-dark'} onClick={() => setOpen(true)}>
        {'Delete shift'}
      </button>
      { open && <DeleteShiftConfirmation close={close} confirm={confirm} setReason={setReason} reason={reason} /> }
    </>
  );
}

export const ShiftListViewer = ({ shifts, CardComponent, review, select, selectedId, emptyText, selectLocum }) => {
  if (shifts === null) {
    return (
      <section className={'shift-list-viewer'}>
        <ul className={'shift-list no-shifts'}>
          <span className={'no-shifts-text'}>{'Loading shifts...'}</span>
        </ul>
      </section>
    );
  }
  const shiftList = shifts
    .map(shift => {
      return <CardComponent
        shift={shift}
        authed={true}
        review={review}
        select={select}
        selectLocum={selectLocum}
        selectedId={selectedId}
        key={`shift-${shift._id}`}
      />;
    });
  return (
    <section className={'shift-list-viewer'}>
      <ul className={`shift-list ${shiftList.length === 0 ? 'no-shifts' : ''}`}>
        {shiftList}
        {shiftList.length === 0 && <span className={'no-shifts-text'}>{emptyText}</span> }
      </ul>
    </section>
  );
};

export const ShiftViewer = ({ shift, select = () => {}, back = null, store }) => {
  const returnToShifts = () => select(null);
  const modifiable = shift !== null && (shift.status === SHIFT_STATUSES.UNFILLED.key || shift.status === SHIFT_STATUSES.FILLED.key || shift.status === SHIFT_STATUSES.CONFIRMED.key);
  return (
    <section className={'view-shift-container'}>
      <section className={'button-containers'}>
        <BackArrowButton onClick={back === null ? returnToShifts : back} text={'Back to my shifts'} />
      { modifiable &&
        <section className={'shift-buttons'}>
          <EditShiftButton shift={shift} editText={'Edit shift'} select={select} classNames={['edit-shift', 'shift-button']} store={store} />
          <DeleteShiftButton returnToShifts={returnToShifts} shiftId={shift._id} />
        </section>
      }
      </section>
      <Shift shift={shift} showRequestButton={false} isViewer={true} />
    </section>
  );
};

export const LocumViewer = ({ locum, confirm = null, backText, shift = null, back }) => {
  return (
    <section className={'view-locum-container'}>
      <BackArrowButton onClick={back} text={backText} />
      <LocumProfile locum={locum} confirm={confirm} shift={shift} />
    </section>
  )
}

const UpcomingTitleAndSubtitle = () => (
  <>
    <h1 className={'upcoming-shifts-title'}>{'Upcoming Shifts'}</h1>
    <p className={'upcoming-shifts-subtitle'}>
      {`Summary of upcoming shifts for your practice.`}
    </p>
    <p className={'upcoming-shifts-subtitle'}>
      {`Unfilled shifts are visible to Locums on the marketplace.`}
    </p>
  </>
);

const SelectedShiftTitleAndSubtitle = ({ unfilled }) => (
  <>
    <h1 className={'upcoming-shifts-title'}>{'Your Selected Shift'}</h1>
    <p className={'upcoming-shifts-subtitle'}>
      {`This is how your shift appears to Locums.`}
    </p>
    <p className={'upcoming-shifts-subtitle'}>

      {unfilled 
        ? `You can modify your shift details until it is filled. Applicants will be informed of any changes.`
        : `This shift has been filled. You cannot modify the shift details.`
      }
    </p>
  </>
);

const SelectedLocumTitleAndSubtitle = () => {
  return (
    <>
      <h1 className={'upcoming-shifts-title'}>{'Your Selected Locum'}</h1>
      <p className={'upcoming-shifts-subtitle'}>{'This is your selected locum for this shift.'}</p>
      <p className={'upcoming-shifts-subtitle'}>{'Pro tip: You can message your Locum before the shift start with all the details they need to know to have a good shfit.'}</p>
    </>
  );
}

const UpcomingShiftsPage = () => {
  const { profile } = useAuth();
  const { shifts } = useStore();
  const allShifts = shifts !== null
    ? shifts
        .filter(shift => shift.endTime > new Date().getTime())
        .sort((e1, e2) => e1.startTime - e2.startTime)
    : null;
  let { state } = useLocation();
  const { storeId } = state;
  const store = profile.stores.find((store) => store._id === storeId);
  const emptyText = 'No upcoming shifts';
  const [shift, setShift] = useState(state.shift ? state.shift : null);
  const [locum, setLocum] = useState(null);
  const selectLocum = (locum, shift) => {
    setLocum(locum);
    setShift(shift);
  }
  const back = () => {
    setLocum(null);
    setShift(null);
  }
  const unfilled = shift !== null && shift.status === SHIFT_STATUSES.UNFILLED.key;
  return (
    <StorePage mode={NAVIGATION_OPTIONS.UPCOMING.mode}>
      <article className={'upcoming-shifts-page'}>
        {shift === null && locum === null && <UpcomingTitleAndSubtitle />}
        {shift !== null && locum === null && <SelectedShiftTitleAndSubtitle unfilled={unfilled} /> }
        {shift !== null && locum !== null && <SelectedLocumTitleAndSubtitle unfilled={unfilled} /> }
        {shift === null && locum === null &&
          <section className={'shift-viewer-container'}>
            <ShiftListViewer
              shifts={allShifts}
              CardComponent={BusinessShiftCard}
              emptyText={emptyText}
              select={setShift}
              selectLocum={selectLocum}
            />
          </section>
        }
        {shift !== null && locum === null && <ShiftViewer shift={shift} select={setShift} store={store} />}
        {locum !== null && shift !== null && <LocumViewer locum={locum} selectLocum={selectLocum} shift={shift} backText={'Back to my shifts'} back={back} />}
      </article>
    </StorePage>
  );
}

export default UpcomingShiftsPage;
