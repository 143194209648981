import { MONTHS } from '../components/Constants.js';
import { formatInTimeZone } from 'date-fns-tz';

const MONTHS_SHORT = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const DAYS_OF_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const getOrdinalSuffix = (day) => {
  if (day % 100 < 20 && day % 100 > 10) {
    return 'th';
  }
  if (day % 10 === 1) {
    return 'st'
  }
  else if (day % 10 === 2) {
    return 'nd';
  }
  else if (day % 10 === 3) {
    return 'rd';
  }
  return 'th';
}

export const formatDate = (fromDate, toDate) => {
  let formattedFrom = fromDate.getDate() + ' ' + MONTHS.displayText[fromDate.getMonth()].substring(0, 3);
  let formattedTo = toDate.getDate() + ' ' + MONTHS.displayText[toDate.getMonth()].substring(0, 3)
  return (formattedFrom + ' - ' + formattedTo);
}
  

export const formatDatesWithTimeZone = (startDate, endDate, isLong, timeZone) => {
  const dateOptions = isLong ? 'eeee, d MMMM yyyy' : 'eee, d MMM yyyy';
  const timeOptions = 'h:mm a'
  const date = formatInTimeZone(startDate, timeZone, dateOptions);
  const startTimeDisplay = formatInTimeZone(startDate, timeZone, timeOptions);
  const endTimeDisplay = formatInTimeZone(endDate, timeZone, timeOptions);
  return { date, startTimeDisplay, endTimeDisplay };
};

export const formatDatesShort = (startDate, endDate) => {
  const dayIndex = startDate.getDate();
  const monthIndex = startDate.getMonth();
  const dayOfWeekIndex = startDate.getDay();
  const timeOptions = { timeStyle: 'short' };
  const startTimeDisplay = startDate.toLocaleTimeString('en-AU', timeOptions);
  const endTimeDisplay = endDate.toLocaleTimeString('en-AU', timeOptions);
  const date = `${DAYS_OF_WEEK[dayOfWeekIndex]} ${dayIndex}${getOrdinalSuffix(dayIndex)} ${MONTHS_SHORT[monthIndex]}`;
  return { date, startTimeDisplay, endTimeDisplay };
};