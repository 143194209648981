import React, { useState } from 'react';

import { REVIEW_STATES } from './ReviewConstants';
import { postStoreProfileReview } from '../../../StoreProfileApi';

import RateStoreSection from './rate-store/RateStoreSection';
import ReviewStoreSection from './review-store/ReviewStoreSection';
import ValidateIdentitySection from './validate-identity/ValidateIdentitySection';

const { REVIEW, RATING, VALIDATION } = REVIEW_STATES;

const DEFAULT_REVIEW = {
  employmentStatus: null, lengthOfWork: null, pros: '', cons: '', wouldRecommend: null,
};
const DEFAULT_RATING = {
  compensation: null, culture: null, diversity: null, staff: null, workLifeBalance: null,
}
const DEFAULT_VALIDATION = { email: '', fullName: '' };

function ReviewStoreView({ closeModal, storeId, storeName }) {
  const [viewState, setViewState] = useState(REVIEW);
  const [review, setReview] = useState(DEFAULT_REVIEW);
  const [rating, setRating] = useState(DEFAULT_RATING);
  const [validation, setValidation] = useState(DEFAULT_VALIDATION);

  let reviewDisplay = null;
  const goToReview = () => setViewState(REVIEW);
  const goToRating = () => setViewState(RATING);
  const goToValidation = () => setViewState(VALIDATION);
  const submit = (setLoading) => {
    const storeReview = { review, rating, validation, storeId, storeName };
    setLoading(true);
    postStoreProfileReview(storeReview)
      .then(() => {
        setLoading(false);
        closeModal();
      });
  }
  if (viewState === REVIEW) {
    reviewDisplay = (
      <ReviewStoreSection
        next={goToRating}
        review={review}
        setReview={setReview}
      />
    );
  }
  else if (viewState === RATING) {
    reviewDisplay = (
      <RateStoreSection
        back={goToReview}
        next={goToValidation}
        rating={rating}
        setRating={setRating}
      />
    );
  }
  else if (viewState === VALIDATION) {
    reviewDisplay = (
      <ValidateIdentitySection
        back={goToRating}
        submit={submit}
        validation={validation}
        setValidation={setValidation}
      />
    );
  }
  return <section className={'review-display'}>{reviewDisplay}</section>
}

export default ReviewStoreView;