import React from 'react';
import { ASSET_CDN_URL } from '../../Constants';
import { useNavigate } from 'react-router-dom';
import BackArrowButton from '../../common/BackArrowButton.jsx';
import './WhatIsALocum.scss';

const WhatIsALocumImg = `${ASSET_CDN_URL}/blogs/what-is-a-locum/what-is-a-locum.png`;

const BUSINESS_STRUCTURE_LINK = 'https://www.ato.gov.au/Business/Starting-your-own-business/Before-you-get-started/Choosing-your-business-structure/';
const TURNOVER_LINK = 'https://www.ato.gov.au/business/gst/in-detail/gst-issues-registers/financial-services---questions-and-answers/?page=75#:~:text=The%20GST%20turnover%20for%20an,turnover%20is%20%2475%2C000%20or%20above.';
const REGISTER_GST_LINK = 'https://www.ato.gov.au/Business/GST/Registering-for-GST/';
const SUPER_ANNUATION_SOLE_LINK = 'https://www.ato.gov.au/Business/Super-for-employers/Work-out-if-you-have-to-pay-super/Super-for-sole-traders-and-partnerships/';
const SUPER_ANNUATION_EMPLOYER_LINK = 'https://www.ato.gov.au/Business/Super-for-employers/Work-out-if-you-have-to-pay-super/';
const PAYG_LINK = 'https://www.ato.gov.au/Business/PAYG-withholding/';
const INSURANCE_LINK = 'https://business.gov.au/risk-management/insurance/business-insurance';

const WhatIsALocum = () => {
  const navigate = useNavigate();
  const onClick = () => navigate('/tutorials');
  return (
    <article className={'blog-page what-is-a-locum'}>
      <BackArrowButton onClick={onClick} text={'Back to tutorials'} />
      <section className={'article'}>
        <img className={'image-banner'} src={WhatIsALocumImg} alt={`What is a Locum?`} />
        <h1 className={'title'}>{`Locums: What's different?`}</h1>
        <h2 className={'date'}>{`Updated on April 17th, 2022`}</h2>
        <section className={'content'}>
          <p className={'paragraph'}>
            {`Being a locum means that you work flexibly and gain exposure to a range of different settings. Where and when you work is your choice.`}
          </p>
          <p className={'paragraph'}>
            {`Locuming can be awesome. You gain flexibility in choosing your hours and location. The rates are usually higher than full time work as you are often providing temporary essential services that would otherwise be unfufilled at the healthcare provider in need of locums.`}
          </p>
          <p className={'paragraph'}>
            {`If you are looking for more flexibility in your life, need extra money, want to travel, or are looking to explore what you want from your career, locuming might be a great opportunity for you.`}
          </p>
          <p className={'paragraph'}>
            {`As a locum, you are an independent contractor, which means you'll have a few financial and legal requirements that do not apply to the typical full-time employee.`}
          </p>
          <p className={'paragraph'}>
            {`Here are a couple decisions to make when you first set yourself up as a locum:`}
          </p>
          <ul className={'tutorial-list'}>
            <li className={'tutorial'}>
              <span className={'tutorial-title'}>{'1. Business Structure: Set up as a sole trader, or as a company?'}</span>
              <p className={'paragraph'}>
                {`A sole trader is an individual running a business. It is the simplest and cheapest business structure.  A sole trader owns and manages all business assets, and is also responsible for all liabilities of the business.`}
              </p>
              <p className={'paragraph'}>
                {`A company is a separate legal entity from you, and has at least one shareholder (owner) and one director (who runs the business). Income and losses belong to the company, and companies can employ staff.  If you are an independent contractor, you can set up as a 'one person company' with a sole director and member. `}
              </p>
              <p className={'paragraph'}>
                {`Companies are regulated and must follow the standards set out by Australian Securities and Investments Commission (ASIC).`}
              </p>
              <p className={'paragraph'}>
                {'See this guide on choosing your business structure '}
                <a className={'link'} href={BUSINESS_STRUCTURE_LINK}>{'here'}</a>
                {'.'}
              </p>
            </li>
            <li className={'tutorial'}>
              <span className={'tutorial-title'}>{'2. GST: To register or not register for GST'}</span>
              <p className={'paragraph'}>
                {`Optometric locum services are taxable for Goods and Services Tax (GST). For sole traders or companies, if you anticipate your `}
                <a className={'link'} href={TURNOVER_LINK}>{'annual turnover'}</a>
                {` to be more than $75,000  a year, then you must register for GST.`}
              </p>
              <p className={'paragraph'}>
                {`If you expect your `}
                <a className={'link'} href={TURNOVER_LINK}>{'annual turnover'}</a>
                {` to be less than $75,000, you may still register for GST. You may wish to do this if you anticipate substantial business-related experiences, as you will be able to claim input tax credits on these expenses. `}
              </p>
              <p className={'paragraph'}>
                {'See this guide on registering for GST '}
                <a className={'link'} href={REGISTER_GST_LINK}>{'here'}</a>
                {'.'}
              </p>
              <p className={'paragraph italics'}>
                {'If an optometrist has earnings from a part-time employee position, and also has earnings as a contractor, the earnings from the part-time employment do not count towards the $75,000 GST turnover threshold.'}
              </p>
            </li>
            <li className={'tutorial'}>
              <span className={'tutorial-title'}>{'3. Superannuation'}</span>
              <p className={'paragraph'}>
                {`If you are providing locum services as a sole trader, you are responsible for your own superannuation arrangements. If you as a sole trader employ others, you will need to make superannuation payments for any eligible employees.`}
              </p>
              <p className={'paragraph'}>
                {'Check out this link for determining if you have to pay your own superannuation as a '}
                <b>{'sole trader'}</b>
                {' or '}
                <b>{'partnership '}</b>
                <a className={'link'} href={SUPER_ANNUATION_SOLE_LINK}>{'here'}</a>
                {'.'}
              </p>
              <p className={'paragraph'}>
                {`If you are providing locum services through a company trading structure, your company must pay superannuation to you, or any other eligible employees.`}
              </p>
              <p className={'paragraph'}>
                {'Check out this link for determining your superannuation arrangements as an employer '}
                <a className={'link'} href={SUPER_ANNUATION_EMPLOYER_LINK}>{'here'}</a>
                {'.'}
              </p>
              <p className={'paragraph'}>
                {`The current superannuation guarantee is 10% of ordinary time earnings.`}
              </p>
            </li>
            <li className={'tutorial'}>
              <span className={'tutorial-title'}>{'4. PAYG'}</span>
              <p className={'paragraph'}>
                {`PAYG is a form of payroll tax where employers withhold a certain amount from their employees' wages to assist them to meet their end of year tax liabilities. If a practice does not receive an ABN from a locum, the practice is required to withhold PAYG tax equal to the top rate for an individual.`}
              </p>
              <p className={'paragraph'}>
                {`Unless a voluntary agreement is in place with a practice to withhold and remit the tax to the ATO, if you are operating as a sole trader, you will be required to lodge and pay your own PAYG tax.`}
              </p>
              <p className={'paragraph'}>
                {`If you are operating through a company structure, you will need to be registered for PAYG tax and withhold tax for your employee.`}
              </p>
              <p className={'paragraph'}>
                {'Check out this link for understanding PAYG withholding rules '}
                <a className={'link'} href={PAYG_LINK}>{'here'}</a>
                {'.'}
              </p>
            </li>
            <li className={'tutorial'}>
              <span className={'tutorial-title'}>{'5. Insurance'}</span>
              <p className={'paragraph'}>
                {`Your business may require certain types of insurance. Some of the most relevant types for optometrists include:`}
              </p>
              <p className={'paragraph'}>
                {`It is a requirement specified by AHPRA that all practising Optometrists in Australia have `}
                <b>{'Professional Indemnity Insurance'}</b>
                {`, which covers monetary loss resulting from negligence related to the locum's work as an optometrist.`}
              </p>
              <p className={'paragraph'}>
                {`If you are operating as a company for your locum services, it is a requirement for your company to have `}
                <b>{'Workers Compensation Insurance'}</b>
                {`, which covers employers for the cost of benefits if employees are injured or become ill because of their work.`}
              </p>
              <p className={'paragraph'}>
                {`If you are a sole trader, you cannot insure yourself for workers compensation because you are not anyone's employee. `}
                <b>{'Income protection insurance'}</b>
                {` may be worth considering to ensure the financial security of being paid a portion of your income if you are unable to work due to injury or illness.`}
              </p>
              <p className={'paragraph'}>
                {'For a full list of insurance types, visit this '}
                <a className={'link'} href={INSURANCE_LINK}>{'link'}</a>
                {'.'}
              </p>
            </li>
          </ul>
          <p className={'paragraph end italics'}>
            {'Disclaimer: This article is provided as a guide only; it is important to seek your own independent legal, accounting and insurance advice. '}
          </p>
        </section>
      </section>
    </article>
  );
}

export default WhatIsALocum;