export const removeItemOnce = (arr, value) => {
  let index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

export const removeItemIfExistsElseAdd = (arr, value) => {
  let index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  } else {
    arr.push(value);
  }
  return arr;
}
