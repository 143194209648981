import React, { useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { StoreDetails } from '../business/store/EditStorePage.jsx';
import BackArrowButton from '../common/BackArrowButton.jsx';
import { ReasonDropdown } from '../business/store/UpcomingShiftsPage.jsx';
import ConfirmationModal from '../business/store/ConfirmationModal.jsx';
import useAuth from '../hooks/AuthHook.jsx';
import { formatDatesWithTimeZone } from '../../helpers/DateUtils.js';
import { TUTORIAL_URL } from './LocumUpcomingShiftsPage.jsx';
import './Shift.scss';
import { SOFTWARES, EQUIPMENTS, DAILY_SCRIPT_VOLUME, SUPPORTS } from '../business/store/ManagePreferencesPage.jsx';
import { NETWORKS, SHIFT_STATUSES, USER_ROLES, DEFAULT_STORE_AVATAR, ASSET_CDN_URL } from '../Constants';
import { getProfile, postShiftConfirmation, postShiftRequest, postShiftWithdrawal } from '../Api.js';

const BlurredMap =  `${ASSET_CDN_URL}/shift-card/blurred-map.png`;

const WITHDRAWAL_OPTIONS = {
  MISTAKE: {
    key: 'TIME_CONFLICT',
    text: 'Shift time conflict',
  },
  NEGOTIATE: {
    key: 'OUTSIDE_SHIFT',
    text: 'Confirmed outside of Locumly',
  },
  CHANGE_OF_DATE: {
    key: 'NO_LONGER_AVAILABLE',
    text: 'No longer available',
  },
  OTHER: {
    key: 'OTHER',
    text: 'Other',
  }
};

const RequestShiftConfirmation = ({ close, shift }) => {
  const { setProfile } = useAuth();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const shiftId = shift._id;
  const confirm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    postShiftRequest(shiftId, message)
      .then(() => 
        getProfile().then(({ profile }) => {
          setProfile(profile);
          setLoading(false);
          close();
        })
      )
  }
  return (
    <ConfirmationModal loading={loading} close={close} additionalClassName={'request-shift'} confirm={confirm} confirmText={'Request shift'}>
      <h3 className={'confirmation-title'}>{'Almost there...'}</h3>
      <p className={'confirmation-text'}>
        {`Your shift request will be sent to the store.`}
      </p>
      <p className={'confirmation-text'}>
        {'If you are no longer available for the shift you will need to update your request status in Locumly.'}
      </p>
      <textarea
        className={'message'}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder={'Send a short message introducing yourself to the store... (Optional)'}
      />  
    </ConfirmationModal>
  );
}

const RequestShiftButton = ({ disabled, shift, missingNumbers }) => {
  const [open, setOpen] = useState(false);
  const clickOpen = (e) => setOpen(true);
  const close = () => setOpen(false);
  return (
    <>
      { !missingNumbers && 
        <button 
          className={`request-button very-light ${disabled ? 'disabled' : ''}`} 
          onClick={clickOpen}
          disabled={disabled}
        >
          {'Request shift'}
        </button>
      }
      { missingNumbers && 
        <span className={'missing-numbers'}>
          {'To start requesting shifts, go to '}
          <NavLink className={'edit-link'} exact={'true'} to={'/locum/profile/view'}>
            {'your profile'}
          </NavLink>
          {' and add in your ABN and AHPRA numbers.'}
        </span>
      }
      { open && <RequestShiftConfirmation close={close} shift={shift} /> }
    </>
  );
};

const WithdrawFromShiftConfirmation = ({ shift, close, back }) => {
  const { setProfile } = useAuth();
  const [reason, setReason] = useState(null);
  const [loading, setLoading] = useState(false);
  const shiftId = shift._id;
  const confirm = () => {
    setLoading(true);
    postShiftWithdrawal(shiftId, reason)
      .then(() => {
        getProfile().then(({ profile }) => {
          setLoading(false);
          setProfile(profile);
          close();
          back();
        });
      });
  };
  return (
    <ConfirmationModal loading={loading} close={close} additionalClassName={'withdraw-shift'} confirm={confirm} confirmText={'Withdraw from shift'} disabled={reason === null}>
      <h3 className={'confirmation-title'}>{'Are you sure?'}</h3>
      <p className={'confirmation-text'}>
        {`We know circumstances change. We do ask that you be mindful of frequent cancellations as it could impact businesses.`}
      </p>
      <section className={'reason-dropdown-container'}>
        <span className={'reason-text'}>{'Reason: '}</span>
      <ReasonDropdown setReason={setReason} reason={reason} options={WITHDRAWAL_OPTIONS} />
      </section>

    </ConfirmationModal>
  );
}

export const WithdrawFromShiftButton = ({ shift, back }) => {
  const [open, setOpen] = useState(false);
  const clickOpen = (e) => setOpen(true);
  const close = () => setOpen(false);
  return (
    <>
      <button className={`withdraw-button very-dark`} onClick={clickOpen}>{shift.status === 'UNFILLED' ? 'Withdraw request' : 'Withdraw from shift'}</button>
      { open && <WithdrawFromShiftConfirmation close={close} shift={shift} back={back} /> }
    </>
  );
};


const EnterProviderNumberConfirmation = ({ close, shift }) => {
  const { setProfile } = useAuth();
  const [number, setNumber] = useState('');
  const confirm = () => {
    postShiftConfirmation(shift._id, shift.storeId, number)
      .then(() => {
        getProfile().then(({ profile }) => {
          setProfile(profile);
          close();
        });
      });
  };
  return (
    <ConfirmationModal close={close} additionalClassName={'enter-provider-number'} confirm={confirm} confirmText={'Submit Provider ID'} disabled={number.length === 0}>
      <h3 className={'confirmation-title'}>{'Medicare Provider ID'}</h3>
      <p className={'confirmation-text'}>
        {`To confirm this shift, you will need to provide a Medicare Provider ID for the store.`}
      </p>
      <p className={'confirmation-text'}>
        {'You will need a unique Provider ID for each new store that you Locum for.'}
      </p>
      <p className={'confirmation-text'}>
        {'Follow our '}
        {<Link className={'link'} to={TUTORIAL_URL} target={'_blank'} rel={'noreferrer noopener'}>{'quick and easy custom tutorial'}</Link>}
        {' on how you can get your Medicare Provider ID for a store.'}
      </p>
      <p className={'confirmation-text'}>
        {'Click on the view store details button or message the store for all the information required to request a provider number for a new store.'}
      </p>
      <input
        type={'text'}
        className={'number'}
        value={number}
        onChange={(e) => setNumber(e.target.value)}
        placeholder={'Your Medicare Provider ID'}
      />  
    </ConfirmationModal>
  );
}

export const EnterProviderNumberButton = ({ shift, additionalClassName = '' }) => {
  const [open, setOpen] = useState(false);
  const clickOpen = (e) => setOpen(true);
  const close = () => setOpen(false);
  return (
    <>
      <button className={`enter-number-button very-light ${additionalClassName}`} onClick={clickOpen}>
        {'Enter Provider Number'}
      </button>
      { open && <EnterProviderNumberConfirmation close={close} shift={shift} /> }
    </>
  );
};

const getPretestingText = (pretesting) => {
  return !pretesting ? 'Pretesting will not be provided by the store' : 'Pretesting will be provided by the store';
};

const getNetworkTextPreferred = (inNetwork, network) => {
  return !inNetwork ? 'The store has no requirements for past experience' : `Past experience at ${network} is preferred`;
}

const getIntervalTextPreferred = (interval) => {
  return interval === 0 ? 'No specific testing time familiarity required' : `The store operates using ${interval} minute testing times`;
}

const getSoftwaresText = (softwares, type) => {
  return `${softwares.length ? softwares.map(software => SOFTWARES[type][software].text).join(', ') : 'None'}`;
}

const getEquipmentsText = (equipments, type) => {
  return `${equipments.length > 0 ? equipments.map(equipment => EQUIPMENTS[type][equipment].text).join(', ') : 'None'}`;
}

const getDailyScriptingPreferred = (dailyScriptVolume) => {
  return `The store processes ${DAILY_SCRIPT_VOLUME[dailyScriptVolume].displayText} scripts/Rx per day`;
}

export const Information = (
  { preferences, network, storeType }
) => {
  const { inNetwork, pretesting, interval, intervalText, softwares, equipments, previousNetworkExperience, travel, accommodation, dailyScriptVolume, supports = [] } = preferences;
  const informationList = (
    <ul className={'information-list'}>
      { storeType === 'OPTOMETRY' && <li className={'information bold'}>{getPretestingText(pretesting)}</li> }
      { intervalText && 
        <li className={'information'}>
          <span className={'bold'}>{'Other working preferences for the store: '}</span>
          {intervalText}
        </li>
      }
      {network.key !== NETWORKS[storeType].OTHER.key &&
        <li className={'information'}>
          <span className={'bold'}>
            {getNetworkTextPreferred(inNetwork || previousNetworkExperience, network.text)}
          </span>
        </li>
      }
      { storeType === 'OPTOMETRY' &&
        <li className={'information'}>
          <span className={'bold'}>{getIntervalTextPreferred(interval)}</span>
        </li>
      }
      {
        storeType === 'PHARMACY' && dailyScriptVolume && 
          <li className={'information'}>
            <span className={'bold'}>{getDailyScriptingPreferred(dailyScriptVolume)}</span>
          </li>
      }
      {
        storeType === 'PHARMACY' && supports && supports.length > 0 &&
          <li className={'information'}>
            <span className={'bold'}>{'Support staff available at the store include: '}</span>
            {supports.map(support => SUPPORTS[support].text).join(', ')}
          </li>
      }
      { softwares.length > 0 &&
        <li className={'information'}>
          <span className={'bold'}>{'Software used by the store: '}</span>
          {getSoftwaresText([...softwares], storeType)}
        </li>
      }
      { storeType === 'OPTOMETRY' && equipments && equipments.length > 0 &&
        <li className={'information'}><span className={'bold'}>{'Equipment used by the store: '}</span>{getEquipmentsText([...equipments], storeType)}</li>
      }
      { travel &&
        <li className={'information'}> 
          <span className={'bold'}>{'Travel: '}</span>
          {'Travel expenses can be covered by the store.'}
        </li>
      }
      { accommodation &&
        <li className={'information'}> 
          <span className={'bold'}>{'Accommodation: '}</span>
          {'Accommodation expenses can be covered by the store.'}
        </li>
      }
    </ul>
  );
  return (
    <section className={'information-container'}>
      <span className={'title'}>
        {'Useful information for the shift'}
      </span>
      {informationList}
    </section>
  );
};

const FULLY_VACCINATED_URL = 'https://www.health.gov.au/sites/default/files/documents/2021/12/atagi-advice-on-the-definition-of-fully-vaccinated.pdf';

export const Requirements = () => {
  const requirementList = (
    <ul className={'requirement-list'}>
      <li className={'requirement'}>
        <span className={'bold'}>{'There are currently no requirements for this job!'}</span>
      </li>
    </ul>
  )
  // const requirementList = (
  //   <ul className={'requirement-list'}>
  //     <li className={'requirement'}>
  //       <a className={'bold'} href={FULLY_VACCINATED_URL}>{'Be fully vaccinated.'}</a>
  //       {' The store will check vaccination status upon arrival.'}
  //     </li>
  //   </ul>
  // )
  return (
    <section className={'requirements'}>
      <span className={'title'}>{`What's required for this shift?`}</span>
      {requirementList}
    </section>
  );
};

export const GOOGLE_DIRECTIONS_URL = 'https://www.google.com/maps?saddr=Current+Location&daddr=';

const Shift = ({ shift, select, isViewer = false, showingStoreDetails = false, setShowingStoreDetails = () => {} }) => {
  const { profile, authed } = useAuth();
  const navigate = useNavigate();
  if (showingStoreDetails) {
    return (
      <section className={'shift-store-view'}>
        <StoreDetails store={shift.store} showEditButton={false} />
      </section>
    )
  }
  const isLocum = profile !== null && profile.role === USER_ROLES.LOCUM;
  const showRequestButton = isLocum;
  const { name, businessId, storeId, startTime, endTime, address, total, preferences, network, avatar, negotiable, rate, status, firstName, lastName, providerNumber, locumId, billToMedicare, type } = shift;

  const { mall, shopNumber, street, suburb, state, postcode, timeZone } = address;
  const mallDisplay = mall ? `${mall}, ` : '';
  const shopNumberDisplay = shopNumber ? `${shopNumber}, ` : '';
  const streetDisplay = `${mallDisplay}${shopNumberDisplay}${street}`;
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);
  const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(startDate, endDate, true, timeZone);
  const canRequestShift = profile !== null && (profile.abn && profile.abn.length > 0) && (profile.ahpra && profile.ahpra.length > 0);
  const viewOnMap = () => {
    const uriEncodedAddress = encodeURI(`${name} ${street} ${suburb} ${state} ${postcode}`);
    window.open(`${GOOGLE_DIRECTIONS_URL}${uriEncodedAddress}`);
  };
  const totalDisplay = (total / 100).toFixed(2);
  const profileShifts = profile && profile.shifts ? profile.shifts : [];
  const hasRequested = showRequestButton && profileShifts.filter(profileShift => shift._id === profileShift._id).length > 0;
  const canRemoveRequest = hasRequested && (status === SHIFT_STATUSES.UNFILLED.key || status === SHIFT_STATUSES.FILLED.key || status === SHIFT_STATUSES.CONFIRMED.key);

  const unfilled = status === SHIFT_STATUSES.UNFILLED.key;
  const confirmed = status === SHIFT_STATUSES.CONFIRMED.key;
  const requestShiftButton = !hasRequested
    ? <RequestShiftButton shift={shift} disabled={!showRequestButton || !canRequestShift} missingNumbers={showRequestButton && !canRequestShift} />
    : <button className={'request-button very-light requested'}>{!confirmed ? 'Shift requested' : 'Shift confirmed'}</button>
  const filled = status === SHIFT_STATUSES.FILLED.key;

  const messageStore = () => navigate('/user/messages', { state: { open: false, business: { _id: businessId, firstName, lastName }, store: { _id: storeId, avatar, name } } });
  const back = () => {
    if (select && isViewer) {
      select(null);
    }
  }
  const backToMap = () => {
    if (select) {
      select(null);
    }
  }
  const totalDisplayContainer = (
    <>
      {`You'll receive: `}
      <span className={'total-display'}>
        <span className={'total'}>{`$${totalDisplay}`}</span>
        {' + GST'}
      </span>
    </>
  )
  const storeAvatar = avatar ? avatar : DEFAULT_STORE_AVATAR[type];
  return (
    <section className={'shift'}>
      <section className={'store'}>
        <section className={'back-and-avatar'}>
          { !isViewer && <BackArrowButton onClick={backToMap} text={'Back to map'} /> }
          <section className={'avatar-container'}>
            <img className={'avatar'} src={storeAvatar} alt={'Business Avatar'} />
          </section>
        </section>
        <section className={'business-details'}>
          <span className={'poster-name'}>
            <span className={'label'}>{`Posted by: `}</span>
            <span className={'text'}>{`${firstName} ${lastName}`}</span>
          </span>
          <span className={'network-name'}>
            <span className={'label'}>{`Network: `}</span>
            <span className={'text'}>{`${NETWORKS[type][network].text}`}</span>
          </span>
          <button 
            className={`send-message-button very-dark ${hasRequested ? '' : 'disabled'}`}
            onClick={messageStore}
            disabled={!hasRequested}
          >
            {'Message Store'}
          </button>
          { (!unfilled && isLocum && locumId === profile._id) && isViewer && 
            <button className={'shift-button view-store very-dark'} onClick={() => setShowingStoreDetails(true)}>
              {'View Store Details'}
            </button>
          }
        </section>
        <section className={'blurred-map-container'}>
          <button className={'go-to-map-button'} onClick={viewOnMap}>
            <img className={'blurred-map'} src={BlurredMap} alt={'Blurred Map'} />
          </button>
          <span className={'caption-container'}>
            <span className={'caption'} onClick={viewOnMap}>{'Get Directions'}</span>
          </span>
        </section>
      </section>
      <section className={'shift-details'}>
        <section className={'store-and-total'}>
          <section className={'store-details'}>
            <span className={'store-name'}>{name}</span>
            <span className={'suburb-and-state'}>{`${streetDisplay}`}</span>
            <span className={'suburb-and-state'}>{`${suburb}, ${state}, ${postcode}`}</span>
            <span className={'date'}>{date}</span>
            <span className={'time'}>{`${startTimeDisplay} to ${endTimeDisplay}`}</span>
            <span className={'rate'}>
              {billToMedicare ? 'Rate: NA' : `$${(rate / 100).toFixed(2)} per hour`}
            </span>
            {!billToMedicare && <span className={'negotiable'}>{negotiable ? 'Negotiable' : 'Fixed Rate'}</span>}
          </section>
          <section className={'total-and-buttons'}>
            <span className={'total-container'}>
              {billToMedicare ? 'Locum takes home Medicare billing' : totalDisplayContainer}
            </span>
            {providerNumber && 
              <span className={'provider-number'}>
                <span className={'title'}>{'Medicare Provider Number'}</span>
                <span className={'number'}>{providerNumber}</span>
              </span>
            }
            {authed ? requestShiftButton : <span className={'request-message'}>
              <a className={'link'} href={'/login/locum'}>{'Login'}</a>
              {' to apply'}
            </span>}
            {showRequestButton && filled && <EnterProviderNumberButton shift={shift} />}
            {canRemoveRequest && <WithdrawFromShiftButton shift={shift} back={back} /> }
        </section>
        </section>
        <section className={'requirements-and-information'}>
          <Requirements preferences={preferences} network={NETWORKS[type][network]} type={type} />
          <Information preferences={preferences} network={NETWORKS[type][network]} storeType={type} />
        </section>
      </section>
      <section className={'blurred-map-container'}>
        <button className={'go-to-map-button'} onClick={viewOnMap}>
          <img className={'blurred-map'} src={BlurredMap} alt={'Blurred Map'} />
        </button>
        <span className={'caption-container'}>
          <span className={'caption'} onClick={viewOnMap}>{'Get Directions'}</span>
        </span>
      </section>
    </section>
  );
};

export default Shift;
