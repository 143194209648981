import './StoreLocationMap.scss';

import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { COORDINATES_PROPS } from '../../StoreProfileProps';
import { useStoreLocationMap } from '../../../hooks/LocationMapHook';


const propTypes = {
  /* Coordinates of the store. */
  coordinates: PropTypes.shape(COORDINATES_PROPS).isRequired,
}

function StoreLocationMap({ coordinates }) {
  const mapContainerRef = useRef(null);
  useStoreLocationMap(coordinates, mapContainerRef);
  return (
    <section className={'store-location-map-container'}>
      <section className={'store-location-map'} ref={mapContainerRef} />
    </section>
  )
}

StoreLocationMap.displayName = 'StoreLocationMap';
StoreLocationMap.propTypes = propTypes;
export default StoreLocationMap;