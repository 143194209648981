import { useState, useRef } from 'react';
import { ASSET_CDN_URL, BUSINESS_DEFAULT_ROUTE, USER_ROLES } from '../../Constants';
import useAuth from '../../hooks/AuthHook.jsx';
import { useOutsideClick } from '../../hooks/CommonHooks.jsx';
import { useNavigate } from 'react-router-dom';
import './ProfileDropdown.scss';

const ProfileIcon = `${ASSET_CDN_URL}/navbar/profile-icon.svg`;

const Hamburger = ({ open }) => {
  return (
    <section className={`hamburger ${open ? 'open' : ''}`}>
      <span className={'line'} />
      <span className={'line'} />
      <span className={'line'} />
    </section>
  );
}

const LocumProfileDropdownList = ({ closeDropdown }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const scheduleClick = () => {
    navigate('/locum/shifts');
    closeDropdown();
  };
  const profileClick = () => {
    navigate('/locum/profile/edit');
    closeDropdown();
  };
  const paymentsClick = () => {
    navigate('/locum/payments/manage');
    closeDropdown();
  };
  const accountClick = () => {
    navigate('/locum/account/manage');
    closeDropdown();
  };
  const logoutClick = () => {
    closeDropdown();
    navigate('/', { replace: true });
    logout();
  };
  return (
    <section className={'dropdown-list-container'}>
      <ul className={'profile-dropdown-list'}>
        <li className={'link not-mobile'}>
          <button className={'navigational-button very-light'} onClick={scheduleClick}>{'Schedule'}</button>
        </li>
        <li className={'link'}>
          <button className={'navigational-button very-light'} onClick={profileClick}>{'Profile'}</button>
        </li>
        <li className={'link'}>
          <button className={'navigational-button very-light'} onClick={paymentsClick}>{'Payments'}</button>
        </li>
        <li className={'divider'}/>
        <li className={'link'}>
          <button className={'navigational-button very-light'} onClick={accountClick}>{'Account'}</button>
        </li>
        <li className={'link'}>
          <button className={'navigational-button very-light'} onClick={logoutClick}>{'Logout'}</button>
        </li>
      </ul>
    </section>
  );
}

const BusinessProfileDropdownList = ({ closeDropdown }) => {
  const { profile, logout } = useAuth();
  const { stores } = profile;
  const navigate = useNavigate();
  const myStoresClick = () => {
    navigate(BUSINESS_DEFAULT_ROUTE);
    closeDropdown();
  };
  const myShiftsClick = () => {
    if (stores.length > 0) {
      navigate('/store/shifts/upcoming', { state: { storeId: stores[0]._id } });
    }
    else {
      myStoresClick();
    }
    closeDropdown();
  };
  const completePaymentsClick = () => {
    if (stores.length > 0) {
      navigate('/store/payments/complete', { state: { storeId: stores[0]._id } });
    }
    else {
      myStoresClick();
    }
    closeDropdown();
  };
  const storePreferencesClick = () => {
    if (stores.length > 0) {
      navigate('/store/preferences/manage', { state: { storeId: stores[0]._id } });
    }
    else {
      myStoresClick();
    }
    closeDropdown();
  };
  const storeProfileClick = () => {
    if (stores.length > 0) {
      navigate('/store/profile/edit', { state: { storeId: stores[0]._id } });
    }
    else {
      myStoresClick();
    }
    closeDropdown();
  };
  const accountClick = () => {
    if (stores.length > 0) {
      navigate('/business/account/manage', { state: { storeId: stores[0]._id } });
    }
    else {
      myStoresClick();
    }
    closeDropdown();
  };
  const logoutClick = () => {
    closeDropdown();
    navigate('/', { replace: true });
    logout();
  };
  return (
    <section className={'dropdown-list-container'}>
      <ul className={'profile-dropdown-list'}>
        <li className={'link'}>
          <button className={'navigational-button very-light'} onClick={myStoresClick}>{'My stores'}</button>
        </li>
        <li className={'link'}>
          <button className={'navigational-button very-light'} onClick={myShiftsClick}>{'My shifts'}</button>
        </li>
        <li className={'link'}>
          <button className={'navigational-button very-light'} onClick={completePaymentsClick}>{'Complete payments'}</button>
        </li>
        <li className={'link'}>
          <button className={'navigational-button very-light'} onClick={storePreferencesClick}>{'Store preferences'}</button>
        </li>
        <li className={'link'}>
          <button className={'navigational-button very-light'} onClick={storeProfileClick}>{'Store profile'}</button>
        </li>
        <li className={'divider'}/>
        <li className={'link'}>
          <button className={'navigational-button very-light'} onClick={accountClick}>{'Account'}</button>
        </li>
        <li className={'link'}>
          <button className={'navigational-button very-light'} onClick={logoutClick}>{'Logout'}</button>
        </li>
      </ul>
    </section>
  );
}

const ProfileDropdownList = ({ closeDropdown }) => {
  const { profile } = useAuth();
  const { role } = profile;
  return role === USER_ROLES.LOCUM
    ? <LocumProfileDropdownList closeDropdown={closeDropdown} />
    : <BusinessProfileDropdownList closeDropdown={closeDropdown} />
}

const ProfileDropdown = () => {
  const dropdownRef = useRef(null);
  const [open, setOpen] = useState(false);
  const onClick = () => setOpen(!open);
  useOutsideClick(dropdownRef, () => setOpen(false));
  return (
    <section className={'profile-dropdown-container'} ref={dropdownRef}>
      <button className={'profile-dropdown very-dark'} onClick={onClick}>
        <Hamburger open={open} />
        <section className={'profile-icon-container'}>
          <img className={'profile-icon'} src={ProfileIcon} alt={''} />
        </section>
      </button>
      { open && <ProfileDropdownList closeDropdown={() => setOpen(false)} />}
    </section>
  )
}

export default ProfileDropdown;