import React, { useState } from 'react';

import useStoreProfile from '../../hooks/StoreProfileContext';

import StoreProfileModal from './StoreProfileModal';
import StoreProfileDetails from './StoreProfileDetails';
import StoreProfileTitle from './StoreProfileTitle';

function StoreProfile() {
  const [modalData, setModalData] = useState(null);
  const { storeProfile } = useStoreProfile();
  const { storeName, locationName, storeLogo } = storeProfile;
  const showModal = modalData !== null;
  return (
    <article className={'store-profile-page'}>
      { showModal && <StoreProfileModal setModalData={setModalData} modalData={modalData} /> }
      <StoreProfileTitle storeName={storeName} locationName={locationName} avatar={storeLogo} />
      <StoreProfileDetails setModalData={setModalData} />
    </article>
  )
}

StoreProfile.displayName = 'StoreProfile';
export default StoreProfile;