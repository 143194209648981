import React from 'react';
import { ASSET_CDN_URL } from '../Constants';
import './BackArrowButton.scss';

const BackArrow = `${ASSET_CDN_URL}/icons/back-arrow-icon.svg`;

const BackArrowButton = ({ onClick, text }) => (
  <button className={'back-arrow-container'} onClick={onClick}>
    <img className={'back-arrow-icon'} src={BackArrow} alt={'back arrow'} />
    <span className={'back-text'}>
      {text}
    </span>
  </button>
);

export default BackArrowButton;