import './StoreWordsFromAdminSection.scss';

import PropTypes from 'prop-types';
import React from 'react';

import StoreProfileSection from './StoreProfileSection';

const propTypes = {
  /* Store name. */
  storeName: PropTypes.string.isRequired,

  /* Words from store's admin. */
  wordsFromAdmin: PropTypes.string.isRequired,
}

function StoreWordsFromAdminSection({ storeName, wordsFromAdmin }) {
  const sectionTitle = `Words from ${storeName}'s admin`;
  return (
    <StoreProfileSection
      titlePosition={'center'}
      sectionTitle={sectionTitle}
      additionalClassNames={['words-from-admin']}
    >
      <p className={'text'}>{wordsFromAdmin}</p>
    </StoreProfileSection>
  )
};

StoreWordsFromAdminSection.displayName = 'StoreWordsFromAdminSection';
StoreWordsFromAdminSection.propTypes = propTypes;
export default StoreWordsFromAdminSection;