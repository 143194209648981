import React, { useState, useEffect, useRef } from 'react';
import { ASSET_CDN_URL } from '../../Constants';
import { useNavigate, useLocation } from 'react-router-dom';
import { NETWORKS, DAYS_OF_WEEK, MONTHS } from '../../Constants.js';
import useAuth from '../../hooks/AuthHook.jsx';
import { DEFAULT_RADIUS, useOutsideClick } from '../../hooks/CommonHooks.jsx';
import MobileSearchWhere, { WebSearchWhere } from './MobileSearchWhere.jsx';
import MobileSearchWhen, { WebSearchWhen } from './MobileSearchWhen.jsx';
import MobileSearchOther, { WebSearchOther } from './MobileSearchOther.jsx';
import { formatDate } from '../../../helpers/DateUtils.js';

import './NavSearch.scss';

// Icons/Logo
const SearchIcon = `${ASSET_CDN_URL}/icons/magnifying-glass-icon.svg`;
const ExitIcon = `${ASSET_CDN_URL}/icons/exit-icon.svg`;
const LeftArrow = `${ASSET_CDN_URL}/icons/left-arrow-icon.svg`;

// Multi and Single Select Filter Options
const FilterItem = ({Option, itemType, OptionObject, setSpecifiedState}) => {
  let isComingSoon = OptionObject[Option].isComingSoon;
  return (
    <div className={'item-container'}>
      <div className={'item-padding'}>
        <button disabled={isComingSoon} className={isComingSoon ? 'inactive-button coming-soon' : itemType === Option ? 'active-button' : 'inactive-button'} onClick={() => setSpecifiedState(Option)}>
          <span className={isComingSoon ? 'item-span coming-soon-item' : 'item-span'}>{OptionObject[Option].name}</span>
          {isComingSoon && <span className={'coming-soon-text'}><em>{' soon'}</em></span>}
        </button>
      </div>
    </div>
  )
}

export const FilterCarousel = ({Question, OptionList, OptionObject, itemType, setItemType}) => {
  const setSpecifiedState = (Option) => {
    if (Question === 'Which type of work?') {
      setItemType({
        ...itemType,
        shiftType: Option,
        networks: Object.keys(NETWORKS[Option]).reduce((acc, cur) => ({ ...acc, [cur]: true }), {})
      })
    } else if (Question === 'How would you like to search?') {
      setItemType({
        ...itemType,
        other: {
          searchMethod: Option,
          selectedItems: Option === 'BYMONTH' ? [...Array(12).keys()].reduce((acc, cur) => ({ ...acc, [cur]: false }), {}) : [...Array(7).keys()].reduce((acc, cur) => ({ ...acc, [cur]: false }), {})
        }
      })
    } else if (Question === 'Only show stores with shifts?') {
      setItemType({
        ...itemType,
        allStores: Option === 'Stores with shifts only' ? false : true
      })
    }
  }

  // Make it reusable, just add a new condition.
  let item;
  if (Question === 'Which type of work?') {
    item = itemType.shiftType;
  } else if (Question === 'How would you like to search?') {
    item = itemType.other.searchMethod;
  } else if (Question === 'Only show stores with shifts?') {
    item = itemType.allStores ? 'All Stores' : 'Stores with shifts only';
  }

  return (
    <div className={'filter-carousel'}>
      <div className={'filter-title'}>{Question}</div>
      <div className={'filter-container'}>
        <div className={'filter-scroll'}>
          {OptionList.map((option, i) => <FilterItem Option={option} itemType={item} OptionObject={OptionObject} setSpecifiedState={setSpecifiedState} key={`${option}-${i}`}/>)}
        </div>
      </div>
    </div>
  )
}

const MultiFilterItem = ({Option, selectedOptions, setSelectedOptions, OptionObject, itemKey}) => {
  const handleMultiSelect = () => {
    setSelectedOptions({
      ...selectedOptions, 
      [itemKey]: {
        ...selectedOptions[itemKey], 
        [Option]: !selectedOptions[itemKey][Option]
      }
    })
  }
  return (
    <div className={'item-container'}>
      <div className={'item-padding'}>
        <button className={selectedOptions[itemKey][Option] ? 'active-button' : 'inactive-button'} onClick={() => handleMultiSelect()}>
          <span className={'item-span'}>{OptionObject[selectedOptions.shiftType][Option].text}</span>
        </button>
      </div>
    </div>
  )
}

export const MultiFilterCarousel = ({Question, OptionList, OptionObject, itemType, setItemType, itemKey}) => {
  return (
    <div className={'filter-carousel'}>
      <div className={'filter-title'}>{Question}</div>
      <div className={'filter-container'}>
        <div className={'filter-scroll'}>
          { Object.keys(OptionList)
              .filter(option => (option !== '' && option !== 'ONE_THOUSAND_AND_ONE_OPTICAL'))
              .map((option, i) => 
                <MultiFilterItem Option={option} selectedOptions={itemType} setSelectedOptions={setItemType} OptionObject={OptionObject} itemKey={itemKey} key={`${option}-${i}`} />)
          }
        </div>
      </div>
    </div>
  )
}

// Header of search component to pick locum or perm work.
const MobileSearchHeader = ({isLocum, setIsLocum, setSearchOpened, searchActive, setSearchActive}) => {
  const handleCloseButton = () => {
    if (searchActive) {
      setSearchActive(false);
    } else if (!searchActive) {
      setSearchOpened(false);
    }
  }
  return (
    <div className={'mobile-search-header'}>
      <div className={'mobile-search-exit-container'}>
        <button className={'mobile-search-exit-button'} onClick={() => handleCloseButton()} >
          <img className={'exit-icon'} src={searchActive ? LeftArrow : ExitIcon} alt={'Exit Icon'} />
        </button>
      </div>
      <div>
        <button className={isLocum ? 'work-type selected' : 'work-type'} onClick={() => setIsLocum(true)} >{'Locum'}</button>
        <button disabled className={'work-type coming-soon'} onClick={() => setIsLocum(false)} >
          <span className={'coming-soon-item'}>{'Perm work'}</span>
          <span className={'coming-soon-text'}><em>{' soon'}</em></span>
        </button>
      </div>
    </div>
  )
}

// Overall Search Component
const MobileSearchComponent = ({ searchOpened, setSearchOpened }) => {
  const { profile, authed } = useAuth();
  const type = authed ? profile.type : 'OPTOMETRY';
  const [searchActive, setSearchActive] = useState(false);
  const [isLocum, setIsLocum] = useState(true);
  const [activePanel, setActivePanel] = useState({
    isWhere: true,
    isWhen: false,
    isOther: false,
  });

  const addDays = (date, days) => {
    let newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  }

  const handleActivePanel = (panel) => {
    setActivePanel({
      isWhere: panel === 'isWhere' ? true : false,
      isWhen: panel === 'isWhen' ? true : false,
      isOther: panel === 'isOther' ? true : false
    })
  }

  const [whereFilter, setWhereFilter] = useState({
    searchText: '',
    byState: false,
    nearMe: true,
    coordinates: {
      longitude: null,
      latitude: null
    },
    text: '',
    radius: DEFAULT_RADIUS,
    chosenState: 0
  })
  const [whenFilter, setWhenFilter] = useState({
    useDates: true,
    dates: {
      fromDate: new Date(), 
      toDate: addDays(new Date(), 365)
    },
    other: {
      searchMethod: 'BYDAYOFWEEK',
      selectedItems: [...Array(7).keys()].reduce((acc, cur) => ({ ...acc, [cur]: false }), {})
    }
  });

  const [otherFilter, setOtherFilter] = useState({
    shiftType: type,
    networks: Object.keys(NETWORKS[type]).reduce((acc, cur) => ({ ...acc, [cur]: true }), {}),
    allStores: false
  })

  const resetState = () => {
    setWhereFilter({
      searchText: '',
      byState: false,
      nearMe: true,
      coordinates: {
        longitude: 144.946457,
        latitude: -37.840935
      },
      text: '',
      radius: DEFAULT_RADIUS,
      chosenState: 0
    });
    setWhenFilter({
      useDates: true,
      dates: {
        fromDate: new Date(), 
        toDate: addDays(new Date(), 365)
      },
      other: {
        searchMethod: 'BYDAYOFWEEK',
        selectedItems: [...Array(7).keys()].reduce((acc, cur) => ({ ...acc, [cur]: false }), {})
      }
    });
    setOtherFilter({
      shiftType: type,
      networks: Object.keys(NETWORKS[type]).reduce((acc, cur) => ({ ...acc, [cur]: true }), {}),
      allStores: false
    });
  }

  const navigate = useNavigate();
  const searchClick = () => {
    setSearchOpened(false);
    navigate('/find', { state: { isLocum, whereFilter, whenFilter, otherFilter } })
  };

  return (
    <section className={searchOpened ? 'mobile-search-component open-panel' : 'mobile-search-component'} >
      <MobileSearchHeader isLocum={isLocum} setIsLocum={setIsLocum} setSearchOpened={setSearchOpened} searchActive={searchActive} setSearchActive={setSearchActive}/>
      <div className={'mobile-search-body'}>
        <div className={'filter-margins'}>
          <MobileSearchWhere handleActivePanel={handleActivePanel} activePanel={activePanel} whereFilter={whereFilter} setWhereFilter={setWhereFilter} searchActive={searchActive} setSearchActive={setSearchActive}/>
          <MobileSearchWhen handleActivePanel={handleActivePanel} activePanel={activePanel} whenFilter={whenFilter} setWhenFilter={setWhenFilter}/>
          <MobileSearchOther handleActivePanel={handleActivePanel} activePanel={activePanel} otherFilter={otherFilter} setOtherFilter={setOtherFilter} />
        </div>
      </div>
      <footer className={'mobile-search-footer'}>
        <button className={'clear-all'} onClick={() => resetState()}>
          <div className={'footer-text'}>{'Clear all'}</div>
        </button>
        <button className={'footer-search-button'} onClick={() => searchClick()} >
          <span className={'button-container'}></span>
          <span className={'text-container'}>
            <div className={'aligner'}>
              <img className={'search-icon'} src={SearchIcon} alt={'Search Icon in Search Bar'} />
              <div className={'search-text'}>{'Search'}</div>
            </div>
          </span>
        </button>
      </footer>
    </section>
  );
};

export const WebSearchButton = () => {
  const { pathname } = useLocation();
  const isHome = pathname === '/';
  const [searchOpened, setSearchOpened] = useState(false);
  const { profile, authed } = useAuth();

  const type = authed && profile.role === 'LOCUM' ? profile.type : 'OPTOMETRY';
  const isLocum = !authed || profile.role === 'LOCUM';

  const [searchActive, setSearchActive] = useState(false);
  const [activePanel, setActivePanel] = useState({
    isWhere: true,
    isWhen: false,
    isOther: false,
  });

  const handleOutsideClick = () => {
    setSearchOpened(false);
    setSearchActive(false);
  }

  const SearchRef = useRef(null)
  useOutsideClick(SearchRef, () => handleOutsideClick());

  const addDays = (date, days) => {
    let newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  }

  const handleActivePanel = (panel) => {
    if (panel !== 'isWhere') {
      setSearchActive(false);
    }
    setActivePanel({
      isWhere: panel === 'isWhere' ? true : false,
      isWhen: panel === 'isWhen' ? true : false,
      isOther: panel === 'isOther' ? true : false
    })
  }

  const [whereFilter, setWhereFilter] = useState({
    searchText: '',
    byState: false,
    nearMe: true,
    coordinates: {
      longitude: null,
      latitude: null
    },
    text: '',
    radius: DEFAULT_RADIUS,
    chosenState: 0
  })
  const [whenFilter, setWhenFilter] = useState({
    useDates: true,
    dates: {
      fromDate: new Date(), 
      toDate: addDays(new Date(), 365)
    },
    other: {
      searchMethod: 'BYDAYOFWEEK',
      selectedItems: [...Array(7).keys()].reduce((acc, cur) => ({ ...acc, [cur]: false }), {})
    }
  });

  const [otherFilter, setOtherFilter] = useState({
    shiftType: type,
    networks: Object.keys(NETWORKS[type]).reduce((acc, cur) => ({ ...acc, [cur]: true }), {}),
    allStores: false
  })

  const navigate = useNavigate();
  const searchClick = (e) => {
    e.stopPropagation();
    setSearchOpened(false);
    navigate('/find', { state: { isLocum, whereFilter, whenFilter, otherFilter } })
  };

  let sMethodSelected = Object.keys(whenFilter.other.selectedItems).reduce((acc, cur) => whenFilter.other.selectedItems[cur] === true ? [...acc, cur] : [...acc], []);
  const formatSubtext = () => {
    let formattedText = ''
    if (whenFilter.useDates) {
      formattedText = formatDate(whenFilter.dates.fromDate, whenFilter.dates.toDate);
    } else {
      if (whenFilter.other.searchMethod === "BYDAYOFWEEK") {
        formattedText = 'Shifts on ' + sMethodSelected.map(day => DAYS_OF_WEEK.displayText[day].substring(0, 3)).join(', ');
      } else if (whenFilter.other.searchMethod === "BYMONTH") {
        formattedText = 'Shifts in ' + sMethodSelected.map(month => MONTHS.displayText[month].substring(0, 3)).join(', ');
      }
    }
    return formattedText.length > 28 ? formattedText.substring(0, 28) + '...' : formattedText;
  }

  return (
    <section className={"search-bar"} onClick={() => setSearchOpened(true)} ref={SearchRef}>
      <section className={`filters ${searchOpened ? 'darken-search' : ''}`}>
        <div className={`highlight-container ${searchOpened && activePanel.isWhere ? 'highlight-filter' : ''}`}>
          <div className={'location-filter'} onClick={() => handleActivePanel('isWhere')}>
            <span>{'Where'}</span>
            <span className={'subtitle-span'}>{whereFilter.text ? whereFilter.text : 'Search area'}</span>
          </div>
        </div>
        <div className={`highlight-container ${searchOpened && activePanel.isWhen ? 'highlight-filter' : ''}`}>
          <div className={'when-filter'} onClick={() => handleActivePanel('isWhen')}>
            <span>{'When'}</span>
            <span className={'subtitle-span'}>{formatSubtext()}</span>
          </div>
        </div>
        <div className={`highlight-container ${searchOpened && activePanel.isOther ? 'highlight-filter' : ''}`}>
          <div className={'other-container'} onClick={() => handleActivePanel('isOther')}>
            <div className={'other-filter'}>
              <span>{'Other'}</span>
              <span className={'subtitle-span'}>{'Filters'}</span>
            </div>
            <div className={`search-button ${isHome ? 'home-page' : ''}`} onClick={(e) => searchClick(e)}>
              <img className={'search-icon'} src={SearchIcon} alt={'Search-icon'}/>
              {isHome && <span className={'search-text'}>{"Let's go!"}</span>}
            </div>
          </div>
        </div>
      </section>
      {searchOpened && <div className={'filter-dropdown'}>
        {activePanel.isWhere && <WebSearchWhere handleActivePanel={handleActivePanel} activePanel={activePanel} whereFilter={whereFilter} setWhereFilter={setWhereFilter} searchActive={searchActive} setSearchActive={setSearchActive}/>}
        {activePanel.isWhen && <WebSearchWhen handleActivePanel={handleActivePanel} activePanel={activePanel} whenFilter={whenFilter} setWhenFilter={setWhenFilter}/>}
        {activePanel.isOther && <WebSearchOther handleActivePanel={handleActivePanel} activePanel={activePanel} otherFilter={otherFilter} setOtherFilter={setOtherFilter} />}
      </div>}
    </section>
  )
}

// Search button on NavBar which opens search component.
const MobileSearchButton = () => {
  const [searchOpened, setSearchOpened] = useState(false);

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }, [])

  // When on map view, scroll to top of page and prevent scrolling.
  useEffect(() => {
    searchOpened ? document.body.classList.add('prevent-scroll') : document.body.classList.remove('prevent-scroll');
    window.scrollTo({top: 0, left: 0});
  }, [searchOpened]);

  return (
    <>
      <button className={'mobile-navbar-search'} onClick={() => setSearchOpened(true)} >
        <div className={'search-icon-container'}>
          <img className={'search-icon'} src={SearchIcon} alt={'Search Icon'} />
        </div>
        <div className={'search-input'}>
          <span className={'search-placeholder'}>{'Search Shifts'}</span>
        </div>
      </button>
      <MobileSearchComponent searchOpened={searchOpened} setSearchOpened={setSearchOpened} />
    </>
  );
};

export default MobileSearchButton;
