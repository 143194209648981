import './SubscribeToStoreCheckbox.scss';

import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  /* Id that the label is for. */
  htmlFor: PropTypes.string.isRequired,

  /* Label of the checkbox. */
  label: PropTypes.string.isRequired,

  /* True if checked. */
  checked: PropTypes.bool.isRequired,

  /* On change callback. */
  onChange: PropTypes.func.isRequired,
}

function SubscribeToStoreCheckbox({ htmlFor, checked, onChange, label }) {
  return (
    <section className={'subscribe-to-store-checkbox'}>
      <input
        type={'checkbox'}
        className={'subscribe-checkbox'}
        id={htmlFor}
        checked={checked}
        onChange={onChange}
      />
      <label className={'subscribe-checkbox-label'} htmlFor={htmlFor}>{label}</label>
    </section>
  )
};

SubscribeToStoreCheckbox.displayName = 'SubscribeToStoreCheckbox';
SubscribeToStoreCheckbox.propTypes = propTypes;
export default SubscribeToStoreCheckbox;