import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import LoadingPage from '../../LoadingPage';

const propTypes = {
  /* Url to navigate to. */
  url: PropTypes.string.isRequired,
};

function QRCodeRedirect({ url }) {
  useEffect(() => {
    const timeout = setTimeout(() => window.location.replace(url), 1000);
    return () => clearTimeout(timeout);
  }, [url]);
  return <LoadingPage />;
}

QRCodeRedirect.displayName = 'QRCodeRedirect';
QRCodeRedirect.propTypes = propTypes;
export default QRCodeRedirect;