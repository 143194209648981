import React from 'react';
import './LoadingPage.scss';

const LoadingPage = () => {
  return (
    <article className={'loading-page'}>
      <span className={'loading-text'}>
      <svg viewBox="0 0 90 90" xmlns="http://www.w3.org/2000/svg">
		    <circle id="c" fill="none" strokeWidth="8" strokeLinecap="round" stroke="#55A08D" cx="45" cy="45" r="43" />
	    </svg>
      </span>
    </article>
  );
}

export default LoadingPage;