import React, { useState, useRef, useEffect } from 'react';
import useAuth from '../hooks/AuthHook.jsx';
import { useNavigate } from 'react-router-dom';
import { postStoreSubscription, getProfile } from '../Api.js';
import { removeItemIfExistsElseAdd } from '../../helpers/arrayHelpers.js';
import { useMediaQuery, useOutsideClick } from '../hooks/CommonHooks.jsx';
import './FindStoreCard.scss';
import { ASSET_CDN_URL } from '../Constants.js';

const TickIcon = `${ASSET_CDN_URL}/icons/review-tick-icon.svg`;

export const ShowStoreNotif = ({setShowStoreNotif, isShift}) => {
  const NotifRef = useRef(null);
  useOutsideClick(NotifRef, () => setShowStoreNotif(false));
  return(
    <div className={'store-notif'}>
      <span className={'notif-text'} ref={NotifRef}>
        {isShift
        ? 'You need to be logged in as a Locum to request shifts'
        : 'You need to be logged in as a Locum to subscribe to stores'}
      </span>
    </div>
  )
}

const FindStoreCard = ({store, storeId, distance, currentState, setViewStoreShifts, setSelectedStore, shiftList, location, isListView = true, fromMap = false}) => {
  const navigate = useNavigate();
  const { profile, setProfile, authed } = useAuth(); 
  const [notifyStores, setNotifyStores] = useState([]);
  const [showStoreNotif, setShowStoreNotif] = useState(false);
  const isMobile = useMediaQuery('(max-width: 480px)');
  const { geolocationPreferences } = profile ? profile : {geolocationPreferences: null};
  const { stores } = geolocationPreferences ? geolocationPreferences : {stores: []};

  useEffect(() => {
    if (authed) {
      setNotifyStores(stores.map(store => store._id))
    }
  }, [authed, stores])

  const ViewStoreShifts = (e) => {
    e.stopPropagation();
    setViewStoreShifts(true);
    setSelectedStore(store);
    window.scrollTo({top: 0, left: 0});
  }

  const ViewStorePage = (e) => {
    e.stopPropagation();
    if (isMobile) {
      navigate(`/find/store/view?id=${storeId}`, {state: {...currentState, distance, isListView, location, shiftList, store}});
    } else {
      const newWindow = window.open(`/find/store/view?id=${storeId}`, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    }
  }

  const handleNotifyMe = (e, storeId) => {
    e.stopPropagation();
    if (authed && profile.role === 'LOCUM') {
      const notifyingStores = removeItemIfExistsElseAdd(notifyStores, storeId);
      postStoreSubscription(notifyingStores)
        .then(({error}) => {
          if (error) {
            console.error(error);
          }
          else {
            setNotifyStores(notifyingStores)
            getProfile()
              .then(({ profile }) => {
                setProfile(profile);
              });
          }
        })
    } else if (authed && profile.role === 'BUSINESS') {
      setShowStoreNotif(true);
    }
    else {
      navigate('/login');
    }
  }

  return (
    <li className={isListView ? 'storecard-container' : 'storecard-container map-shift'} onClick={(e) => ViewStorePage(e)}>
      <section className={isMobile ? 'store-container' : 'web-store-container'}>
        {isMobile
        ?
        <>
          <div className={'title-container'}>
            <div className={'store-title'}>
              <span>{store.name}</span>
            </div>
            { distance !== null && distance > 0 && 
            <div className={'right-section store-distance'}>
              <span className={''}>{`${distance.toFixed(1)} KM away`}</span>
            </div>
            }
          </div>
          <div className={'store-details'}>
            <div className={'store-address'}>
              <span>{`${store.address.suburb}, ${store.address.state}`}</span>
            </div>
            {/* <div className={'store-reviews'}>
              <div className={'review'}>
                <img className={'tick-icon'} src={TickIcon} alt={'tickmark'} />
                <span>{'Confirmed shifts rarely get cancelled'}</span>
              </div>
              <div className={'review'}>
                <img className={'tick-icon'} src={TickIcon} alt={'tickmark'} />
                <span>{'Responds quickly to shift requests'}</span>
              </div>
            </div> */}
            <div className={'store-button-container store-subscribe'}>
              <button className={'store-button notify-shifts'} onClick={(e) => handleNotifyMe(e, storeId)}>{notifyStores.includes(storeId) ? 'Subscribed' : 'Notify me for new shifts'}</button>
              <button className={'store-button notify-shifts-small'} onClick={(e) => handleNotifyMe(e, storeId)}>{notifyStores.includes(storeId) ? 'Subscribed' : 'Notify me'}</button>
            </div>
            <div className={'right-section store-button-container'}>
              {/* <button className={'store-button notify-shifts'} onClick={(e) => handleNotifyMe(e, storeId)}>{notifyStores.includes(storeId) ? 'Subscribed' : 'Notify me for new shifts'}</button>
              <button className={'store-button notify-shifts-small'} onClick={(e) => handleNotifyMe(e, storeId)}>{notifyStores.includes(storeId) ? 'Subscribed' : 'Notify me'}</button> */}
              <button className={'store-button view-shifts'} onClick={(e) => ViewStoreShifts(e)}>{`View ${store.shifts.length} shifts`}</button>
            </div>
          </div>
        </>
        :
        <>
          <div className={'title-container'}>
            <div className={'store-title'}>
              <span>{store.name}</span>
            </div>
            <div className={'store-address'}>
              <span>{`${store.address.suburb}, ${store.address.state}`}</span>
            </div>
          </div>
          <div className={'store-reviews'}>
            <div className={'review'}>
              <img className={'tick-icon'} src={TickIcon} alt={'tickmark'} />
              <span>{'Confirmed shifts rarely get cancelled'}</span>
            </div>
            <div className={'review'}>
              <img className={'tick-icon'} src={TickIcon} alt={'tickmark'} />
              <span>{'Responds quickly to shift requests'}</span>
            </div>
          </div>
          { distance !== null && distance > 0 && 
          <div className={'store-distance'}>
            <span className={'distance-text'}>{`${distance.toFixed(0)} KM away`}</span>
          </div>
          }
          <div className={'store-button-container'}>
            <button className={'store-button notify-shifts'} onClick={(e) => handleNotifyMe(e, storeId)}>{notifyStores.includes(storeId) ? 'Subscribed' : 'Notify me for new shifts'}</button>
            <button className={'store-button view-shifts'} onClick={(e) => ViewStoreShifts(e)}>{`View ${store.shifts.length} shifts`}</button>
          </div>
        </>
        }
      </section>
      {showStoreNotif && <ShowStoreNotif setShowStoreNotif={setShowStoreNotif} isShift={false}/>}
    </li>
  )
}

export default FindStoreCard;