import React, { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import CartContext from '../hooks/CartContext';
import useAuth from '../hooks/AuthHook.jsx';
import { useMediaQuery } from '../hooks/CommonHooks.jsx';
import { formatDatesWithTimeZone } from '../../helpers/DateUtils.js';
import './ShiftCard.scss';
import { ShowStoreNotif } from './FindStoreCard';

const ShiftCard = ({ shift, distance = null, isListView = true, currentState, location, allShifts = null, fromStore = null, requestedShiftList }) => {
  const navigate = useNavigate();
  const { authed, profile } = useAuth(); 
  const { name, startTime, endTime, address, rate, negotiable, billToMedicare } = shift;
  const { suburb, state, timeZone } = address;
  const { pathname } = useLocation();
  const datePosted = Math.floor(Math.abs(new Date(shift.datePosted) - new Date()) / (36e5 * 24));
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);
  const { addToCart, cartShifts } = useContext(CartContext);
  const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(startDate, endDate, false, timeZone);
  const rateDisplay = billToMedicare ? 'Rate: NA' : `$${(rate / 100).toFixed(2)}/hr`;
  const [showStoreNotif, setShowStoreNotif] = useState(false);
  const isMobile = useMediaQuery('(max-width: 480px)');
  
  const HandleCart = (e, shift, reqNow) => {
    e.stopPropagation();
    if (authed && profile.role === 'LOCUM') {
      if (cartShifts.hasOwnProperty(shift._id) && reqNow) {
        navigate('/locum/cart');
      } else {
        addToCart(shift);
        if (reqNow) {
          navigate('/locum/cart');
        }
      }
    } else if (authed && profile.role === 'BUSINESS') {
      setShowStoreNotif(true);
    } else {
      navigate('/login');
    }
  }

  const ViewShift = (e) => {
    e.stopPropagation();
    if (isMobile) {
      navigate(`/find/shift?id=${shift._id}`, {state: {...currentState, isListView, distance, location, allShifts, fromStore}});
    } else {
      const newWindow = window.open(`/find/shift?id=${shift._id}`, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    }
  }

  return (
    <li className={isListView ? 'shift-card-container' : 'shift-card-container map-shift'} onClick={(e) => ViewShift(e)}>
      <div className={`shift-post-date ${datePosted < 2 ? 'new-shift' : 'old-shift'} ${fromStore ? 'store-shift' : ''}`}>
        {datePosted < 2 ? 'NEW' : `Added ${datePosted}d ago`}
      </div>
      <section className={(isMobile || (!isMobile && pathname === '/find/store/view')) ? 'shift-container' : 'web-shift-container'}>
        {(isMobile || (!isMobile && pathname === '/find/store/view'))
        ?
        <>
          <div className={'title-container'}>
            <div className={'shift-title'}>
              <span>{name}</span>
            </div>
            { distance !== null && distance > 0 && 
            <div className={'right-section shift-distance'}>
              <span className={''}>{`${distance.toFixed(1)} KM away`}</span>
            </div>
            }
          </div>
          <div className={'address-container'}>
            <div className={'shift-address'}>
              <span>{`${suburb}, ${state}`}</span>
            </div>
            <div className={'right-section shift-rate'}>
              <span className={''}>
                {rateDisplay}
              </span>
            </div>
            <div className={'right-section shift-nego'}>
              <span className={''}>
                {negotiable ? 'Rate Negotiable' : 'Rate Fixed'}
              </span>
            </div>
          </div>
          <div className={'shift-details'}>
            <div className={'shift-date'}>
              <span>{date}</span>
            </div>
            <div className={'shift-time'}>
              <span>{`${startTimeDisplay} - ${endTimeDisplay}`}</span>
            </div>
            <div className={'right-section shift-buttons'}>
              {requestedShiftList.includes(shift._id)
              ?
              <button disabled className={'shift-button'}>
                {'Requested'}
              </button>
              :
              <>
                <button onClick={(e) => HandleCart(e, shift, true)} className={'shift-button'}>
                  {'Request Shift'}
                </button>
                <button onClick={(e) => HandleCart(e, shift, false)} className={'shift-button add-to-cart'}>
                  {cartShifts.hasOwnProperty(shift._id) ? 'Added' : 'Add to cart'}
                </button>
              </>
              }
            </div>
          </div>
        </>
        : 
        <>
          <div className={'title-container'}>
            <div className={'shift-title'}>
              <span>{name}</span>
            </div>
            <div className={'shift-address'}>
              <span>{`${suburb}, ${state}`}</span>
            </div>
          </div>
          <div className={'shift-date-container'}>
            <div className={'shift-date'}>
              <span>{date}</span>
            </div>
            <div className={'shift-time'}>
              <span>{`${startTimeDisplay} - ${endTimeDisplay}`}</span>
            </div>
          </div>
          { distance !== null && distance > 0 && 
            <div className={'shift-distance'}>
              <span className={''}>{`${distance.toFixed(0)} KM away`}</span>
            </div>
          }
          <div className={'shift-rate-container'}>
            <div className={'shift-rate'}>
              <span className={''}>
                {rateDisplay}
              </span>
            </div>
            <div className={'shift-nego'}>
              <span className={''}>
                {negotiable ? 'Rate Negotiable' : 'Rate Fixed'}
              </span>
            </div>
          </div>
          <div className={`shift-buttons ${requestedShiftList.includes(shift._id) ? 'requested' : ''}`}>
            {requestedShiftList.includes(shift._id)
            ?
            <button disabled className={'shift-button'}>
              {'Requested'}
            </button>
            :
            <>
              <button onClick={(e) => HandleCart(e, shift, true)} className={'shift-button'}>
                {'Request Shift'}
              </button>
              <button onClick={(e) => HandleCart(e, shift, false)} className={'shift-button add-to-cart'}>
                {cartShifts.hasOwnProperty(shift._id) ? 'Added' : 'Add to cart'}
              </button>
            </>
            }
          </div>
        </>
        }
      </section>
      {showStoreNotif && <ShowStoreNotif setShowStoreNotif={setShowStoreNotif} isShift={true}/>}
    </li>
  );
};

export default ShiftCard;