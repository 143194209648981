import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ShiftListViewer, ShiftViewer } from './UpcomingShiftsPage.jsx';
import useAuth from '../../hooks/AuthHook.jsx';
import StorePage from './StorePage.jsx';
import BusinessInvoiceCard from '../common/BusinessInvoiceCard.jsx';
import './CompletePaymentsPage.scss'
import { SHIFT_STATUSES } from '../../Constants.js';
import { NAVIGATION_OPTIONS } from '../BusinessConstants.js';
import useStore from '../../hooks/StoreHook.jsx';

const CompletePaymentsPage = () => {
  const { profile } = useAuth();
  const { state } = useLocation();
  const { storeId } = state;
  const store = profile.stores.find((store) => store._id === storeId);
  const { shifts } = useStore();
  const allShifts = shifts !== null
    ? shifts.filter(shift => 
      (
        (shift.status === SHIFT_STATUSES.CONFIRMED && shift.endTime <= new Date().getTime()) || 
        shift.status === SHIFT_STATUSES.INVOICE.key || 
        shift.status === SHIFT_STATUSES.DISPUTED.key ||
        shift.status === SHIFT_STATUSES.COMPLETED.key
      ))
    : null;;
  const emptyText = 'No payments to complete';
  const [shift, setShift] = useState(null);
  return (
    <StorePage mode={NAVIGATION_OPTIONS.PAYMENTS.mode}>
      <article className={'complete-payments-page'}>
        <h1 className={'complete-payments-title'}>{'Pay For Completed Shifts'}</h1>
        <p className={'complete-payments-subtitle'}>
          {`Below are completed shifts that require payment. Easy instructions can be found on the invoice.`}
        </p>
        <p className={'complete-payments-subtitle'}>
          {`If you encounter an issue with a shift, get in touch with us.`}
        </p>
        {shift === null &&
          <section className={'shift-viewer-container'}>
            <ShiftListViewer shifts={allShifts} CardComponent={BusinessInvoiceCard} emptyText={emptyText} select={setShift} />
          </section>
        }
        {shift !== null && <ShiftViewer shift={shift} select={setShift} store={store} />}
      </article>
    </StorePage>
  );
}

export default CompletePaymentsPage;
