import React, { useState, useEffect } from 'react';
import { ASSET_CDN_URL } from '../Constants';
import LocumPage from '../locum/LocumPage.jsx';
import useAuth from '../hooks/AuthHook.jsx';
import { getStripeDashboardUrl, postStripeOnboardingUrl } from '../Api.js';
import './LocumManagePaymentsPage.scss';
import { LOCUM_NAVIGATION_BUTTONS } from './LocumNavigationSidebar.jsx';

const SkateboarderImg = `${ASSET_CDN_URL}/graphics/skateboarder.png`;

const LocumManagePaymentsPage = () => {
  const { profile } = useAuth();
  const { completedStripeOnboarding } = profile;
  const [stripeUrl, setStripeUrl] = useState(null);
  const [clickedVerification, setClickedVerification] = useState(false);
  const subtitleTextOne = 'We partner with Stripe to give your moolah the security it deserves.';
  const subtitleTextTwo = 'You can track your payments history through your own personal Stripe dashboard.';
  const incompleteSubtitleTextOne = 'IMPORTANT: Complete your Stripe verification to automatically generate invoices and process payments from stores on your behalf.';
  const incompleteSubtitleTextTwo = 'You will be able to mark shifts as complete once we receive your Stripe verification.';

  const buttonText = completedStripeOnboarding ? 'View my Stripe dashboard' : 'Complete stripe verification';
  const refreshText = 'Click here once you are done';
  const subtitleOne = completedStripeOnboarding ? subtitleTextOne : incompleteSubtitleTextOne;
  const subtitleTwo = completedStripeOnboarding ? subtitleTextTwo : incompleteSubtitleTextTwo;

  const urlClick = () => {
    if (stripeUrl !== null) {
      window.open(stripeUrl);
      if (!completedStripeOnboarding) {
        setClickedVerification(true);
      }
    }
  };

  const refreshClick = () => window.location.reload();
  const stripeButton = !completedStripeOnboarding && clickedVerification
    ? <button className={'stripe-button completed very-light'} onClick={refreshClick}>{refreshText}</button>
    : <button className={'stripe-button very-dark'} onClick={urlClick}>{buttonText}</button>;
  
  useEffect(() => {
    if (completedStripeOnboarding) {
      getStripeDashboardUrl().then(response => {
        setStripeUrl(response.url);
      });
    }
    else {
      postStripeOnboardingUrl(window.location.href).then(response => {
        setStripeUrl(response.url);
      });
    }
  }, [completedStripeOnboarding]);


  return (
    <LocumPage mode={LOCUM_NAVIGATION_BUTTONS.PAYMENTS.mode}>
      <article className={'locum-manage-payments-page'}>
        <h1 className={'locum-manage-payments-title'}>{'Manage Payments'}</h1>
        <p className={'locum-manage-payments-subtitle'}>
          {subtitleOne}
        </p>
        <p className={'locum-manage-payments-subtitle'}>
          {subtitleTwo}
        </p>
        <section className={'manage-payments'}>
          <img className={'skateboarder'} src={SkateboarderImg} alt={'Skateboarder'} />
          <section className={'manage-payments-button'}>
            {stripeButton}
          </section>
        </section>
      </article>
    </LocumPage>
  );
};

export default LocumManagePaymentsPage;
