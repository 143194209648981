import React, { useState, useEffect, useRef } from 'react';
import useAuth from '../hooks/AuthHook.jsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { orderShifts } from '../../helpers/ShiftUtils.js';
import { formatDatesWithTimeZone } from '../../helpers/DateUtils.js';
import { debounce } from '../../helpers/debounce.js';
import { useNavigate } from 'react-router-dom';
import { WithdrawFromShiftButton, EnterProviderNumberButton } from './Shift.jsx';
import { SHIFT_STATUSES, ASSET_CDN_URL } from '../Constants';
import { useOutsideClick } from '../hooks/CommonHooks.jsx';
import { MarkShiftAsCompleteButton } from './LocumShiftCard.jsx';
import { ReportIssueButton } from '../business/common/BusinessInvoiceCard';
import './Schedule.scss';
import { ALL_STATUSES, CONFIRMED_STATUSES, PAYMENT_PENDING_STATUSES } from './LocumShiftsPage.jsx';

const DateArrow = `${ASSET_CDN_URL}/dropdown/shift-rec-drop-arrow.svg`;
const ReqIcon = `${ASSET_CDN_URL}/icons/requested-shift-icon.svg`;
const ConIcon = `${ASSET_CDN_URL}/icons/confirmed-shift-icon.svg`;
const EditIcon = `${ASSET_CDN_URL}/icons/edit-pen-icon.svg`;
const ScheduleUp = `${ASSET_CDN_URL}/icons/schedule-up-icon.svg`;
const ScheduleDown = `${ASSET_CDN_URL}/icons/schedule-down-icon.svg`;

const { UNFILLED, FILLED, CONFIRMED, PAID } = SHIFT_STATUSES;

export const ManageShift = ({ shift, setManageShifts, onSchedule, setRemovedShiftTime = () => '' }) => {
  const navigate = useNavigate();
  const { name, businessId, storeId, avatar, firstName, lastName, status, endTime, billToMedicare, invoiceLink } = shift;
  const confirmed = endTime > new Date().getTime() && (status === SHIFT_STATUSES.CONFIRMED.key || status === SHIFT_STATUSES.FILLED.key || status === SHIFT_STATUSES.UNFILLED.key);
  const incomplete = endTime < new Date().getTime() && (status === SHIFT_STATUSES.CONFIRMED.key || status === SHIFT_STATUSES.FILLED.key);
  const complete = status === SHIFT_STATUSES.COMPLETED.key || status === SHIFT_STATUSES.INVOICE.key || status === SHIFT_STATUSES.DISPUTED.key;

  const CloseModal = () => {
    setManageShifts(false);
  }
  const ManageRef = useRef(null);
  useOutsideClick(ManageRef, (e) => CloseModal(e));
  const messageStore = () => navigate('/user/messages', { 
    state: { 
      open: false,
      business: { 
        _id: businessId, 
        firstName, 
        lastName 
      }, 
      store: { 
        _id: storeId, 
        avatar, 
        name 
      }
    }
  });
  const back = () => {
    setManageShifts(false);
    if (onSchedule) {
      setRemovedShiftTime(shift.startTime);
    }
  }
  const downloadInvoice = () => {
    const link = document.createElement('a');
    link.href = invoiceLink;
    link.click();
  };

  const ModalClick = e => {
    e.stopPropagation();
  }

  return (
    <div className={'manage-shift-modal'} ref={ManageRef} onClick={(e) => ModalClick(e)}>
      <div className={'manage-shift-container'}>
        <button className={'message-store'} onClick={() => messageStore()}>{'Message Store'}</button>
        {confirmed && <WithdrawFromShiftButton shift={shift} back={back}/>}
        {confirmed && <EnterProviderNumberButton shift={shift} />}
        {incomplete && <MarkShiftAsCompleteButton shift={shift}/>}
        { (!billToMedicare && complete) &&
          <button className={'download-invoice-button very-light'} onClick={downloadInvoice}>
            {'View Invoice'}
          </button>
        }
        {(incomplete || complete) && <ReportIssueButton shift={shift}/>}
      </div>
    </div>
  )
}

const CustomHeader = ({ monthDate, customHeaderCount, decreaseMonth, increaseMonth }) => {
  return (
    <div className={'header-container-top'}>
      <button
        aria-label="Previous Month"
        className={
          "react-datepicker__navigation react-datepicker__navigation--previous"
        }
        style={customHeaderCount === 1 ? { visibility: "hidden" } : null}
        onClick={decreaseMonth}
      >
        <span
          className={
            "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
          }
        >
          <img className={'date-arrow'} src={DateArrow} alt={'arrow-select'} />
        </span>
      </button>
      <span className="react-datepicker__current-month">
        {monthDate.toLocaleString("en-US", {
          month: "long",
          year: "numeric",
        })}
      </span>
      <button
        aria-label="Next Month"
        className={
          "react-datepicker__navigation react-datepicker__navigation--next"
        }
        style={customHeaderCount === 1 ? { visibility: "hidden" } : null}
        onClick={increaseMonth}
      >
        <span
          className={
            "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
          }
        >
          <img className={'date-arrow'} src={DateArrow} alt={'arrow-select'} />
        </span>
      </button>
    </div>
  )
}

const DayShiftDisplay = ({ shift, setRemovedShiftTime }) => {
  const { startTime, endTime, status, name, address } = shift;
  const { suburb, state, timeZone } = address;
  const { startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(new Date(startTime), new Date(endTime), false, timeZone);
  const [manageShift, setManageShifts] = useState(false);
  const iconObj = {
    'Requested': ReqIcon,
    'Confirmed': ConIcon,
    'Not Paid': ReqIcon,
    'Paid': ConIcon
  }

  const StatusDisplay = (status) => {
    if (new Date(endTime) > new Date() && status === UNFILLED.key) {
      return 'Requested';
    } 
    else if (new Date(endTime) > new Date() && (status === CONFIRMED.key || status === FILLED.key)) {
      return 'Confirmed';
    } 
    else if (new Date(endTime) < new Date() && PAYMENT_PENDING_STATUSES.includes(status)) {
      return 'Not Paid';
    }
    else if (status === PAID.key) {
      return 'Paid';
    }
  }

  return (
    <div className={'shift-details'}>
      <div className={'day-time'}>
        <div>{startTimeDisplay}</div>
        <div>{endTimeDisplay}</div>
      </div>
      <div className={'status-container'}>
        <img src={iconObj[StatusDisplay(status)]} alt={StatusDisplay(status)} className={'status-icon'} />
        <span className={"status-text"}>{StatusDisplay(status)}</span>
      </div>
      <div className={'store-details'}>
        <div className={'store-name'}>
          <a className={'store-link'} href={`${window.location.origin}/find/shift?id=${shift._id}`}>{name}</a>
        </div>
        <div className={'store-address'}>{`${suburb}, ${state}`}</div>
      </div>
      <div>
        <img className={'edit-shift'} src={EditIcon} alt={'edit-shift'} onClick={() => setManageShifts(true)}/>
      </div>
      {manageShift && 
        <ManageShift 
          shift={shift}
          setManageShifts={setManageShifts}
          onSchedule={true}
          setRemovedShiftTime={setRemovedShiftTime}
        />
      }
    </div>
  )
}

const DayShifts = ({ shifts, setRemovedShiftTime }) => {
  const day = new Date(shifts[0].startTime);
  const shiftList = shifts
    .map(shift => 
      <DayShiftDisplay 
        setRemovedShiftTime={setRemovedShiftTime} 
        shift={shift} 
      />)
  return (
    <div className={'day-container'} data-index-number={shifts[0].startTime} data-parent={day.getFullYear()} data-columns={day.getMonth()} data-id={`${day.getFullYear()}${day.getMonth() <= 9 ? '0' + day.getMonth() : day.getMonth()}`}>
      <div className={'shift-day'}>
        <span className={'shift-day-div'}>{day.toLocaleString('en-AU', { weekday: 'long', month: 'short', day: 'numeric'}).toUpperCase()}</span>
      </div>
      <div className={'day-info'}>
        {shiftList}
      </div>
    </div>
  )
}

const MonthViewInfo = ({ shifts, visible, viewingDate, setViewingDate, showView, setShowView, removedShiftTime, setRemovedShiftTime }) => {
  const navigate = useNavigate();
  const [dailyShifts, setDailyShifts] = useState({});
  const [observer, setObserver] = useState(null);
  const observedShifts = document.querySelectorAll('.day-container');
  useEffect(() => {
    const orderedShifts = orderShifts(shifts.filter(shift => !(new Date(shift.endTime) < new Date() && shift.status === UNFILLED.key)), 'date-ascending');
    let dayShifts = {}
    orderedShifts.forEach(shift => {
      const day = new Date(shift.startTime);
      // if (day.getMonth() !== viewingDate) return;
      const dayKey = `${day.getFullYear()}${day.getMonth() <= 9 ? '0' + day.getMonth() : day.getMonth()}${day.getDate() <= 9 ? '0' + day.getDate() : day.getDate()}`;
      if (dayShifts.hasOwnProperty(dayKey)) {
        dayShifts[dayKey].push(shift);
      } else {
        dayShifts[dayKey] = [shift];
      }
    })
    setDailyShifts(dayShifts);
  }, [shifts, viewingDate])

  const scrollRef = useRef(null);

  useEffect(() => {
    // data-parent = year, data-columns = month, data-id = '[FullYear][Month]`
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const ratio = entry.intersectionRatio;
        const boundingRect = entry.boundingClientRect;
        const intersectionRect = entry.intersectionRect;
        const month = entry.target.dataset.columns;
        const startTime = entry.target.dataset.indexNumber;

        if (ratio < 1 && ratio > 0.1 && month !== (new Date(removedShiftTime).getMonth()) && boundingRect.top < intersectionRect.top) {
          setViewingDate(month);
          setRemovedShiftTime(parseInt(startTime));
        }
      })
    },
    {
      threshold: [0, 1]
    });
    setObserver(observer);
  }, [observedShifts, viewingDate, showView, removedShiftTime, setRemovedShiftTime, setViewingDate]);

  observedShifts.forEach(shift => {
    observer.observe(shift);
  })

  useEffect(() => {
    if (observedShifts) {

      for (let i = 0; i < observedShifts.length; i++) {
        const shift = observedShifts[i];
        const viewingDay = new Date(viewingDate)
        const formatDate = `${viewingDay.getFullYear()}${viewingDay.getMonth() <= 9 ? '0' + viewingDay.getMonth() : viewingDay.getMonth()}`;
        const shiftDate = new Date(parseInt(shift.getAttribute("data-index-number")));
        const formatShiftDate = `${shiftDate.getFullYear()}${shiftDate.getMonth() <= 9 ? '0' + shiftDate.getMonth() : shiftDate.getMonth()}`;
        if (formatDate === formatShiftDate) {
          shift.scrollIntoView();
          break;
        }
      }
    }
    // scrollRef.current.scrollTop = 
  }, [observedShifts, viewingDate])

  return (
    <div id={'month-view'} className={`month-view${!visible ? ' view-transform' : ''}${showView ? '' : ' hide-view'}`}>
      <div className={'header-bar'} onClick={() => setShowView(!showView)}>
        {/* <div className={'bar'}></div> */}
        <img className={'bar-img'} src={showView ? ScheduleDown : ScheduleUp} alt={'bar'}/>
      </div>
      {showView && <div ref={scrollRef} className={`shift-container ${Object.keys(dailyShifts).length === 0 ? 'no-shifts' : ''}`}>
        {Object.keys(dailyShifts).length > 0 
        ? Object.keys(dailyShifts).map(shiftKey => <DayShifts setRemovedShiftTime={setRemovedShiftTime} shifts={dailyShifts[shiftKey]} />)
        : 
        <div className={'no-upcoming'}>
          <div>
            {'No upcoming shifts this month!'}
          </div>
          <div>
            {'Click '}
            <button className={'find-button'} onClick={() => navigate('/find')}>{'here'}</button>
            {' to find shifts!'}
          </div>
        </div>
        }
      </div>}
    </div>
  )
}

const Schedule = () => {
  const { profile } = useAuth();
  const { firstName, shifts } = profile;
  let highlightWithRanges = null;
  const [viewingDate, setViewingDate] = useState(new Date().getMonth());
  const [showView, setShowView] = useState(true);
  const [removedShiftTime, setRemovedShiftTime] = useState(null);
  const allShifts = shifts.filter(shift => ALL_STATUSES.includes(shift.status));
  const requestShifts = shifts.filter(shift => shift.startTime > new Date().getTime() && shift.status === SHIFT_STATUSES.UNFILLED.key)
  const confirmedShifts = shifts.filter(shift => shift.startTime > new Date().getTime() && CONFIRMED_STATUSES.includes(shift.status));
  const pendingPayShifts = shifts.filter(shift => new Date(shift.endTime) < new Date() && PAYMENT_PENDING_STATUSES.includes(shift.status));
  const paidShifts = shifts
    .filter(shift => 
      shift.status === SHIFT_STATUSES.PAID.key
    )

  highlightWithRanges = [
    {
      "react-datepicker__day--highlighted-confirmed": confirmedShifts.map(shift => new Date(shift.startTime))
    },
    {
      "react-datepicker__day--highlighted-requested": requestShifts.map(shift => new Date(shift.startTime)),
    },
    {
      "react-datepicker__day--highlighted-pending-pay": pendingPayShifts.map(shift => new Date(shift.startTime)),
    },
    {
      "react-datepicker__day--highlighted-paid": paidShifts.map(shift => new Date(shift.startTime)),
    }
  ]

  const [prevScrollPos, setPrevScrollPos] = useState(-1);
  const [visible, setVisible] = useState(true);

  const handleScroll = debounce(() => {
    const currentScrollPos = window.scrollY;

    // If refreshed, show footer, otherwise hide footer when scrolling down and show footer when scrolling up.
    setVisible(prevScrollPos === -1 || 
              (
                prevScrollPos > currentScrollPos
              ) ||
                currentScrollPos < 10);

    setPrevScrollPos(currentScrollPos);
  }, 60);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  // Resizes shift summary to fit right below month component.
  useEffect(() => {
    if (showView) {
      const nav = document.getElementById('navbar-id');
      const banner = document.getElementById('sche-banner');
      const monthPicker = document.getElementById('date-container-schedule');
      const monthView = document.getElementById('month-view');
      // If height is <= 700, make height 40% to accomodate for small screens. Will cover the month but they can
      // close the summary anyway.
      if (window.innerHeight <= 700) {
        monthView.style.height = '40%';
      } else {
        monthView.style.height = `${document.documentElement.clientHeight - (nav.offsetHeight + banner.offsetHeight + (monthPicker.offsetHeight/(showView ? 1 : 2)) + 60)}px`;
      }
    }
  }, [allShifts, viewingDate, showView, removedShiftTime])

  return(
    <article className={'schedule-page'}>
      <div className={'schedule-banner'} id={'sche-banner'}>
        <div className={'title-name'}>{`Hi, ${firstName}!`}</div>
        <div className={'title-confirmed'}>{`You have ${confirmedShifts.length === 0 ? 'no' : confirmedShifts.length} upcoming confirmed shifts.`}</div>
        <div className={'title-requested'}>{`You have ${requestShifts.length === 0 ? 'no' : requestShifts.length} pending requests.`}</div>
        <div className={'title-pending-payment'}>{`You have ${pendingPayShifts.length === 0 ? 'no' : pendingPayShifts.length} shifts pending payment.`}</div>
      </div>
      <div className={'date-container'} id={'date-container-schedule'}>
        {highlightWithRanges !== null && 
          <DatePicker 
            selected={removedShiftTime ? new Date(removedShiftTime) : null}
            dateFormat={'dd/MM/yyyy'}
            inline
            onMonthChange={(month) => {
              setViewingDate(month)
            }}
            highlightDates={highlightWithRanges}
            useWeekdaysShort={false}
            monthsShown={showView ? 1 : 2}
            readOnly
            key={`${allShifts.length}-datepicker`}
            renderCustomHeader={({
              monthDate,
              customHeaderCount,
              decreaseMonth,
              increaseMonth,
            }) => <CustomHeader 
                    monthDate={monthDate}
                    customHeaderCount={customHeaderCount}
                    decreaseMonth={decreaseMonth}
                    increaseMonth={increaseMonth}
                  />}
          />
        }
      </div>
      <MonthViewInfo setRemovedShiftTime={setRemovedShiftTime} removedShiftTime={removedShiftTime} shifts={allShifts} visible={visible} viewingDate={viewingDate} setViewingDate={setViewingDate} showView={showView} setShowView={setShowView}/>
    </article>
  )
}

export default Schedule;