import './StoreOfferingSection.scss';

import PropTypes from 'prop-types';
import React from 'react';

import useStoreProfile from '../../../hooks/StoreProfileContext';
import { STORE_OFFERING_PROPS } from '../../StoreProfileProps';

import StoreProfileButton from '../shared/StoreProfileButton';
import StoreProfileSection from './StoreProfileSection';

const propTypes = {
  /* List of things that the store offers. */
  offerings: PropTypes.arrayOf(PropTypes.shape(STORE_OFFERING_PROPS)).isRequired,

  /* Sets the modal data to display. */
  setModalData: PropTypes.func.isRequired,
}

function StoreOfferingSection({ offerings, setModalData }) {
  const { storeProfile } = useStoreProfile();
  const { storeUrl } = storeProfile;
  const plausibleLink = `plausible-event-link=${storeUrl}`;
  const plausibleEventClick = 'plausible-event-name=Store+Offering+Click';
  const offeringsList = offerings.map((offering) => {
    const plausibleText = offering.text.replace(/ /g, '_');
    const clickOffering = () => setModalData(offering);
    const plausibleOffering = `plausible-event-offering=${plausibleText}`;
    const plausibleEventClass = `${plausibleEventClick} ${plausibleLink} ${plausibleOffering}`;
    return (
      <li className={'offering-item'} key={`key-offering-${offering.text}`}>
        <StoreProfileButton onClick={clickOffering} additionalClassNames={[plausibleEventClass]}>
          {offering.text}
        </StoreProfileButton>
      </li>
    )
  });
  return (
    <StoreProfileSection
      titlePosition={'center'}
      sectionTitle={'What this store offers...'}
      additionalClassNames={['offering']}
    >
      <ul className={'offerings-list'}>
        {offeringsList}
      </ul>
    </StoreProfileSection>
  )
}

StoreOfferingSection.displayName = 'StoreOfferingSection';
StoreOfferingSection.propTypes = propTypes;
export default StoreOfferingSection;