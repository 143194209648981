import React, { useState, useRef, useEffect } from 'react';
import { ASSET_CDN_URL } from '../../Constants';
import { useLocation } from 'react-router-dom';
import useAuth from '../../hooks/AuthHook.jsx';
import { EditAccountButton, TextEditInput, EditInput, PhoneEditInput } from '../../locum/LocumManageAccountPage.jsx';
import StorePage from './StorePage.jsx';
import { USER_ROLES } from '../../Constants.js';
import { useOutsideClick } from '../../hooks/CommonHooks.jsx';
import './EditStorePage.scss';
import { NAVIGATION_OPTIONS } from '../BusinessConstants.js';
import { getProfile, postStoreContactDetails, postStoreOrganizationDetails, postStoreBankingDetails, postStoreAddressDetails, postStoreLocumOnboardingUpload, postStoreLocumOnboardingDelete } from '../../Api.js';

const DropdownArrow = `${ASSET_CDN_URL}/dropdown/black-dropdown-arrow.svg`;

const STRUCTURE_TYPES = {
  INDIVIDUAL_PROPRIETOR: { key: 'INDIVIDUAL_PROPRIETOR', text: 'Individual Proprietor' },
  PARTNERSHIP: { key: 'PARTNERSHIP', text: 'Partnership' },
  OTHER_PUBLIC_BODY: { key: 'OTHER_PUBLIC_BODY', text: 'Other Public Body' },
  COMPANY: { key: 'COMPANY', text: 'Company' },
  STATE_GOVERNMENT: { key: 'STATE_GOVERNMENT', text: 'State Government' },
  TERRITORY_GOVERNMENT: { key: 'TERRITORY_GOVERNMENT', text: 'Territory Government' },
  UNINCORPORATED_ASSOCIATION: { key: 'UNINCORPORATED_ASSOCIATION', text: 'Unincorporated Association' },
};

export const PREMISE_TYPES = {
  GENERAL_PRACTICE: { key: 'GENERAL_PRACTICE', text: 'Practice - General Practice' },
  OTHER_PRACTICE: { key: 'OTHER_PRACTICE', text: 'Practice - Other Private Practice' },
  PUBLIC_HOSPITAL: { key: 'PUBLIC_HOSPITAL', text: 'Hospital - Public' },
  PRIVATE_HOSPITAL: { key: 'PRIVATE_HOSPITAL', text: 'Hospital - Private' },
  RESIDENTIAL_CARE_FACILITY: { key: 'RESIDENTIAL_CARE_FACILITY', text: 'Residential Care Facility' },
  EDUCATIONAL_INSTITUTION: { key: 'EDUCATIONAL_INSTITUTION', text: 'Educational Institution' },
  OTHER: { key: 'OTHER', text: 'Other Community Healthcare Service' },
};

export const StoreDetailsDropdown = ({ selected, setSelected, options, disabled, placeholder, type }) => {
  const emptyText = disabled ? placeholder : 'Select an option...';
  const keys = Object.keys(options);
  const [open, setOpen] = useState(false);
  const toggleDropdown = () => setOpen(!open);
  const dropdownRef = useRef(null);
  useOutsideClick(dropdownRef, () => setOpen(false));
  const dropdownList = keys.map((key) => {
    const onSelection = () => {
      setSelected(key);
      toggleDropdown();
    }
    return (
      <li className={'dropdown-item'} key={`store-details-${key}`}>
        <button className={`dropdown-option`} onClick={onSelection}>
          {options[key].text}
        </button>
      </li>
    );
  });
  return (
    <section className={`dropdown-button ${open ? 'open' : ''} ${disabled ? 'disabled' : ''} ${type}`} ref={dropdownRef}>
      <button className={'data-view-button'} onClick={toggleDropdown} disabled={disabled}>
        {options[selected] ? options[selected].text : emptyText}
        { !disabled && 
          <img className={`dropdown-arrow ${open ? 'open' : ''}`} src={DropdownArrow} alt={'Dropdown arrow'} />
        }
      </button>
      {open &&
        <section className={'dropdown-list-container'}>
          <ul className={'dropdown-list'}>
            {dropdownList}
          </ul>
        </section>
      }
    </section>
  );
};

const DropdownEditInput = ({ label, children }) => {
  return (
    <EditInput label={label} type={'dropdown'}>
      {children}
    </EditInput>
  )
}

const StoreDetailSection = ({ title, type, save, edit, cancel, children, open, showEditButton }) => {
  const { profile } = useAuth();
  const showEdit = profile.role === USER_ROLES.BUSINESS && showEditButton;
  return (
    <section className={`details ${type}`}>
      <section className={'detail-title'}>
        <h2 className={'title'}>{title}</h2> 
        { showEdit && <EditAccountButton save={save} cancel={cancel} edit={edit} open={open} />}
      </section>
      {children}
    </section>
  );
}

const AddressDetails = ({ store, showEditButton }) => {
  const { profile, setProfile } = useAuth();
  const placeholder = profile.role === USER_ROLES.BUSINESS ? '' : 'Contact store for details';
  const initialAddress = store.address 
  ?
    {
      mall: store.address.mall ? store.address.mall : '',
      shopNumber: store.address.shopNumber ? store.address.shopNumber : '',
      street: store.address.street ? store.address.street : '',
      locality: store.address.suburb ? store.address.suburb : '',
      city: store.address.city ? store.address.city : '',
      state: store.address.state ? store.address.state : '',
      postcode: store.address.postcode ? store.address.postcode : '',
      country: store.address.country ? store.address.country : '',
    }
  : {
    mall: '',
    shopNumber: '',
    street: '',
    locality: '',
    city: '',
    postcode: '',
    state: '',
    country: '',
  }
  const [errorText, setErrorText] = useState(false);
  const [address, setAddress] = useState(initialAddress);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setAddress(initialAddress);
  }, [initialAddress]);
  const saveAddress = (setLoading) => {
    setLoading(true);
    postStoreAddressDetails(store._id, { ...address, suburb: address.locality })
      .then(({ error }) => {
        if (error) {
          setErrorText(error);
          setLoading(false);
        }
        else {
          getProfile().then(({ profile }) => {
            setLoading(false);
            setProfile(profile);
            setOpen(false);
          });
        }
      })
      .catch(({ error }) => {
        setErrorText(error);
        setLoading(false);
      }); 
  };
  const editAddress = () => setOpen(true);
  const cancelAddress = () => {
    setOpen(false);
    setAddress(initialAddress);
  }
  const changeMall = (e) => {
    setErrorText('');
    setAddress({ ...address, mall: e.target.value });
  };
  const changeShopNumber = (e) => {
    setErrorText('');
    setAddress({ ...address, shopNumber: e.target.value });
  };
  const changeStreet = (e) => {
    setErrorText('');
    setAddress({ ...address, street: e.target.value });
  };
  const changeLocality = (e) => {
    setErrorText('');
    setAddress({ ...address, locality: e.target.value });
  };
  const changeState = (e) => {
    setErrorText('');
    setAddress({ ...address, state: e.target.value });
  }
  const changePostcode = (e) => {
    setErrorText('');
    setAddress({ ...address, postcode: e.target.value });
  };
  const changeCountry = (e) => {
    setErrorText('');
    setAddress({ ...address, country: e.target.value });
  };
  const { mall, shopNumber, street, locality, postcode, state, country } = address;
  return (
    <StoreDetailSection
      title={'Address'}
      type={'address'}
      save={saveAddress}
      cancel={cancelAddress}
      open={open}
      edit={editAddress}
      showEditButton={showEditButton}
    >
      <span className={'error-text'}>{errorText}</span>
      <section className={'edit-options'}>
        <TextEditInput label={'Mall'} value={mall} onChange={changeMall} disabled={!open} hideOnEmpty={profile.role === USER_ROLES.LOCUM} />
        <TextEditInput label={'Shop Number'} value={shopNumber} onChange={changeShopNumber} disabled={!open} hideOnEmpty={profile.role === USER_ROLES.LOCUM} />
        <TextEditInput label={'Street'} value={street} onChange={changeStreet} disabled={!open} placeholder={placeholder} />
        <TextEditInput label={'Locality'} value={locality} onChange={changeLocality} disabled={!open} placeholder={placeholder} />
        <TextEditInput label={'State'} value={state} onChange={changeState} disabled={!open} placeholder={placeholder} />
        <TextEditInput label={'Postcode'} value={postcode} onChange={changePostcode} disabled={!open} placeholder={placeholder} />
        <TextEditInput label={'Country'} value={country} onChange={changeCountry} disabled={!open} placeholder={placeholder} />
      </section>
    </StoreDetailSection>
  );
};

const ContactDetails = ({ store, showEditButton }) => {
  const { profile, setProfile } = useAuth();
  const placeholder = profile.role === USER_ROLES.BUSINESS ? '' : 'Contact store for details';
  const initialContact = {
    telephoneNumber: store.contact && store.contact.telephoneNumber ? store.contact.telephoneNumber : '',
    mobileNumber: store.contact && store.contact.mobileNumber ? store.contact.mobileNumber : '',
    emailAddress: store.contact && store.contact.emailAddress ? store.contact.emailAddress : '',
    website: store.contact && store.contact.website ? store.contact.website : '',
  };
  const [name, setName] = useState(store.name);
  const [errorText, setErrorText] = useState(false);
  const [contact, setContact] = useState(initialContact);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setContact(initialContact);
    if (store) {
      setName(store.name);
    }
  }, [initialContact, store.name]);
  const saveContact = (setLoading) => {
    setLoading(true);
    const newMobileNumber = mobileNumber !== undefined && mobileNumber !== null ? `${contact.mobileNumber}` : ''
    postStoreContactDetails(store._id, { ...contact, mobileNumber: newMobileNumber }, name)
      .then(({ error }) => {
        if (error) {
          setErrorText(error);
          setLoading(false);
        }
        else {
          getProfile().then(({ profile }) => {
            setLoading(false);
            setContact({ ...contact, mobileNumber: newMobileNumber });
            setProfile(profile);
            setOpen(false);
          });
        }
      })
      .catch(({ error }) => {
        setErrorText(error);
        setLoading(false);
      }); 
  };
  const editContact = () => setOpen(true);
  const cancelContact = () => {
    setOpen(false);
    setContact(initialContact);
    setName(store.name);
  };
  const changeStoreName = (e) => {
    setErrorText('');
    setName(e.target.value);
  };
  const changeTelephoneNumber = (e) => {
    setErrorText('');
    setContact({ ...contact, telephoneNumber: e.target.value });
  };
  const changeMobileNumber = (phoneNumber) => {
    setErrorText('');
    setContact({ ...contact, mobileNumber: phoneNumber });
  };
  const changeEmailAddress = (e) => {
    setErrorText('');
    setContact({ ...contact, emailAddress: e.target.value });
  };
  const changeWebsite = (e) => {
    setErrorText('');
    setContact({ ...contact, website: e.target.value });
  };

  const onboardingDocument = store.locumOnboardingDocument ? store.locumOnboardingDocument : null;
  const hasOnboarding = onboardingDocument !== null;
  const uploadOnboarding = (e) => {
    const file = e.target.files[0];
    postStoreLocumOnboardingUpload(store._id, file)
      .then(() => getProfile().then(({ profile }) => {
        setProfile(profile);
        e.target.value = "";
      }))
      .catch((err) => console.error(err));
  };

  const deleteOnboarding = () => {
    postStoreLocumOnboardingDelete(store._id)
      .then(() => getProfile().then(({ profile }) => {
        setProfile(profile);
      }))
      .catch((err) => console.error(err));
  }

  const onboardingFileName = onboardingDocument ? onboardingDocument.fileName : 'No file chosen';
  const onboardingDocumentButton = showEditButton
  ? (
    <section className={'upload-onboarding-container'}>
      <label for={'onboarding-upload'} className={'upload-onboarding'}>{'Upload Locum Onboarding Document'}</label>
      <input type={'file'} name={'onboarding-upload'} id={'onboarding-upload'} className={'onboarding-upload'} onChange={uploadOnboarding} />
      <span className={'delete-container'}>
        <span className={'file-name'}>{onboardingFileName}</span>
        { onboardingDocument && <button className={'delete'} onClick={deleteOnboarding}>{'×'}</button> }
      </span>
    </section>
  )
  : (
    <section className={'upload-onboarding-container'}>
      { hasOnboarding
          ? (
            <a className={`download-onboarding`} href={onboardingDocument.url} download={onboardingDocument.fileName} target={'_blank'} rel={'noreferrer noopener'}>
              {'Download Locum Onboarding Document'}
            </a>
          )
          : (
            <span className={'downloading-onboarding no-document'}>{'No Locum Onboarding Document Uploaded'}</span>
          )
      }
    </section>
  );

  const { telephoneNumber, mobileNumber, emailAddress, website } = contact;
  return (
    <StoreDetailSection
      title={'Contact'}
      type={'contact'}
      save={saveContact}
      cancel={cancelContact}
      edit={editContact}
      open={open}
      showEditButton={showEditButton}
    >
      <span className={'error-text'}>{errorText}</span>
      <section className={'edit-options'}>
        <TextEditInput label={'Store name'} value={name} onChange={changeStoreName} disabled={!open} placeholder={placeholder} />
        <TextEditInput label={'Telephone'} value={telephoneNumber} onChange={changeTelephoneNumber} disabled={!open} placeholder={placeholder} />
        <PhoneEditInput label={'Mobile'} value={mobileNumber} onChange={changeMobileNumber} disabled={!open} />
        <TextEditInput label={'Nominated email for locums to contact'} value={emailAddress} onChange={changeEmailAddress} disabled={!open} placeholder={placeholder} />
        <TextEditInput label={'Website'} value={website} onChange={changeWebsite} disabled={!open} placeholder={placeholder} />
        {onboardingDocumentButton}
      </section>
    </StoreDetailSection>
  )
};

const OrganizationDetails = ({ store, showEditButton }) => {
  const { profile, setProfile } = useAuth();
  const placeholder = profile.role === USER_ROLES.BUSINESS ? ' ' : 'Contact store for details';
  const initialOrganization = store.organization 
    ? store.organization
    : {
      registeredBusinessName: null,
      abn: null,
      acn: null,
      tradingAs: null,
      structure: null,
      premises: null,
    }
  const [organization, setOrganization] = useState(initialOrganization);
  const [errorText, setErrorText] = useState(false);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOrganization(initialOrganization);
  }, [initialOrganization]);
  const saveOrganization = (setLoading) => {
    setLoading(true);
    postStoreOrganizationDetails(store._id, organization)
      .then(({ error }) => {
        if (error) {
          setErrorText(error);
          setLoading(false);
        }
        else {
          getProfile().then(({ profile }) => {
            setLoading(false);
            setProfile(profile);
            setOpen(false);
          });
        }
      })
      .catch(({ error }) => {
        setErrorText(error);
        setLoading(false);
      }); 
  };
  const editOrganization = () => setOpen(true);
  const cancelOrganization = () => {
    setOpen(false);
    setOrganization(initialOrganization);
  }
  const changeRegisteredBusinessName = (e) => {
    setErrorText('');
    setOrganization({ ...organization, registeredBusinessName: e.target.value });
  };
  const changeAbn = (e) => {
    setErrorText('');
    setOrganization({ ...organization, abn: e.target.value });
  };
  const changeAcn = (e) => {
    setErrorText('');
    setOrganization({ ...organization, acn: e.target.value });
  };
  const changeTradingAs = (e) => {
    setErrorText('');
    setOrganization({ ...organization, tradingAs: e.target.value });
  };
  const setStructure = (structure) => {
    setErrorText('');
    setOrganization({ ...organization, structure });
  };
  const setPremises = (premises) => {
    setErrorText('');
    setOrganization({ ...organization, premises });
  };
  const { registeredBusinessName, abn, acn, tradingAs, structure, premises } = organization;
  return (
    <StoreDetailSection
      title={'Organization'}
      type={'organization'}
      save={saveOrganization}
      edit={editOrganization}
      cancel={cancelOrganization}
      open={open}
      showEditButton={showEditButton}
    >
    <span className={'error-text'}>{errorText}</span>
    <section className={'edit-options'}>
      <TextEditInput label={'Registered Business Name'} value={registeredBusinessName} onChange={changeRegisteredBusinessName} disabled={!open} placeholder={placeholder} />
      <TextEditInput label={'ABN'} value={abn} onChange={changeAbn} disabled={!open} placeholder={placeholder} />
      <TextEditInput label={'ACN'} value={acn} onChange={changeAcn} disabled={!open} placeholder={placeholder} />
      <TextEditInput label={'Trading As'} value={tradingAs} onChange={changeTradingAs} disabled={!open} placeholder={registeredBusinessName} />
      <DropdownEditInput label={'Business Type'}>
        <StoreDetailsDropdown type={'structure'} selected={structure} setSelected={setStructure} options={STRUCTURE_TYPES} disabled={!open} placeholder={placeholder} />
      </DropdownEditInput>
      <DropdownEditInput label={'Premises Type'}>
        <StoreDetailsDropdown type={'premises'} selected={premises} setSelected={setPremises} options={PREMISE_TYPES} disabled={!open} placeholder={placeholder} />
      </DropdownEditInput>
    </section>
    </StoreDetailSection>
  );
};

const BankingDetails = ({ store, showEditButton }) => {
  const { profile, setProfile } = useAuth();
  const placeholder = profile.role === USER_ROLES.BUSINESS ? '' : 'Contact store for details';
  const initialBanking = store.banking
    ? store.banking
    : {
      bsb: '',
      accountNumber: '',
      accountName: '',
      invoiceEmail: '',
    };
  const [banking, setBanking] = useState(initialBanking);
  useEffect(() => {
    setBanking(initialBanking);
  }, [initialBanking]);
  const [open, setOpen] = useState(false);
  const [errorText, setErrorText] = useState(false);
  const saveBanking = (setLoading) => {
    setLoading(true);
    postStoreBankingDetails(store._id, banking)
      .then(({ error }) => {
        if (error) {
          setErrorText(error);
          setLoading(false);
        }
        else {
          getProfile().then(({ profile }) => {
            setLoading(false);
            setProfile(profile);
            setOpen(false);
          });
        }
      })
      .catch(({ error }) => {
        setErrorText(error);
        setLoading(false);
      }); 
  };
  const editBanking = () => setOpen(true);
  const cancelBanking = () => {
    setOpen(false);
    setBanking(initialBanking)
  };
  const changeBsb = (e) => {
    setErrorText('');
    setBanking({ ...banking, bsb: e.target.value });
  };
  const changeAccountNumber = (e) => {
    setErrorText('');
    setBanking({ ...banking, accountNumber: e.target.value });
  };
  const changeAccountName = (e) => {
    setErrorText('');
    setBanking({ ...banking, accountName: e.target.value });
  };
  const changeInvoiceEmail = (e) => {
    setErrorText('');
    setBanking({ ...banking, invoiceEmail: e.target.value });
  }
  const { bsb, accountNumber, accountName, invoiceEmail = '' } = banking;
  return (
    <StoreDetailSection
      title={'Banking'}
      type={'banking'}
      save={saveBanking}
      edit={editBanking}
      cancel={cancelBanking}
      open={open}
      showEditButton={showEditButton}
    >
      <span className={'error-text'}>{errorText}</span>
      <section className={'edit-options'}>
        <TextEditInput label={'Branch Number (BSB)'} value={bsb} onChange={changeBsb} disabled={!open} placeholder={placeholder} />
        <TextEditInput label={'Account Number'} value={accountNumber} onChange={changeAccountNumber} disabled={!open} placeholder={placeholder} />
        <TextEditInput label={'Account Name'} value={accountName} onChange={changeAccountName} disabled={!open} placeholder={placeholder} />
        <TextEditInput label={'Nominated Email To Receive Invoices'} value={invoiceEmail} onChange={changeInvoiceEmail} disabled={!open} placeholder={placeholder} />
      </section>
    </StoreDetailSection>
  );
}


export const StoreDetails = ({ store = null, showEditButton = true }) => {
  const { profile } = useAuth();
  const { state } = useLocation();
  const [storeObject, setStoreObject] = useState(store);
  const storeId = state ? state.storeId : null;
  useEffect(() => {
    if (store === null && storeId) {
      setStoreObject(profile.stores.find((store) => store._id === storeId));
    }
  }, [store, storeId, profile.stores]);
  if (storeObject === null) {
    return null;
  }
  return (
    <section className={`store-details ${showEditButton ? '' : 'locum-view'}`}>
      <section className={'address-and-contact'}>
        <ContactDetails store={storeObject} showEditButton={showEditButton} />
        <AddressDetails store={storeObject} showEditButton={showEditButton} />
      </section>
      <section className={'organization-and-banking'}>
        <OrganizationDetails store={storeObject} showEditButton={showEditButton} />
        <BankingDetails store={storeObject} showEditButton={showEditButton} />
      </section>
    </section>
  );
}

const EditStorePage = () => {
  const { state } = useLocation();
  return (
    <StorePage mode={NAVIGATION_OPTIONS.STORE.mode}>
      <article className={'edit-store-page'}>
        <h1 className={'edit-store-title'}>{'Edit Store Details'}</h1>
        <p className={'edit-store-subtitle'}>
          {`Modify store name, address, phone number and other contact details below.`}
        </p>
        <p className={'edit-store-subtitle'}>
          {`This information is only shared with Locums confirmed for a shift to help setup their new provider numbers.`}
        </p>
        <StoreDetails />
      </article>
    </StorePage>
  );
};

export default EditStorePage;