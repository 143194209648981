import React from 'react';
import { Route } from 'react-router-dom';

import StoreProfilePage from '../components/store-profile/StoreProfilePage';

function getStoreProfileAppRoutes() {
  return (
    <Route path={'/'}>
      <Route path={':storeName'} element={<StoreProfilePage />} />
    </Route>
  );
}

export default getStoreProfileAppRoutes;