import React, { useState, useRef } from 'react';
import { ASSET_CDN_URL } from '../../Constants';
import useAuth from '../../hooks/AuthHook.jsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { NAVIGATION_OPTIONS } from '../BusinessConstants.js';
import { useOutsideClick } from '../../hooks/CommonHooks.jsx';
import useStore from '../../hooks/StoreHook.jsx';
import './StoreDropdown.scss';

const DisabledDropdownArrow = `${ASSET_CDN_URL}/dropdown/disabled-dropdown-arrow.svg`;
const DropdownArrow = `${ASSET_CDN_URL}/dropdown/dropdown-arrow.svg`;

const getStoreOptions = (stores) => {
  const storeOptions = stores
    .reduce((options, store) => ({...options, [store._id]: { key: store._id, text: store.name }, }), {});
  return storeOptions;
}

const StoreDropdown = ({ currentMode }) => {
  const { state } = useLocation();
  const { selectStoreId } = useStore();
  const navigate = useNavigate();
  const select = (storeId) => {
    selectStoreId(storeId);
    navigate(NAVIGATION_OPTIONS[currentMode].url, { state: { ...state, storeId }})
  };
  const { profile } = useAuth();
  const { stores } = profile;
  const selected = (state && state.storeId) || stores[0]._id;
  const storeOptions = getStoreOptions(stores);
  const [open, setOpen] = useState(false);
  const orderedStoreOptions = Object
    .keys(storeOptions)
    .sort((key1, key2) => storeOptions[key1].text.localeCompare(storeOptions[key2].text));
  const storeList = orderedStoreOptions.map(key => {
    const onClick = () => {
      setOpen(false);
      select(storeOptions[key].key);
    }
    return (
      <li className={'store-item'} key={`store-option-${key}`}>
        <button className={'store-selection-button'} onClick={onClick}>{storeOptions[key].text}</button>
      </li>
    );
  });
  const disabled = stores.length === 1;
  const dropdownRef = useRef(null);
  useOutsideClick(dropdownRef, () => setOpen(false));
  const toggleDropdown = () => setOpen(!open);
  return (
    <section className={'store-dropdown'} ref={dropdownRef}>
      <button className={`store-selector ${disabled ? 'disabled' : ''}`} onClick={toggleDropdown} disabled={disabled}>
        <span className={'store-text'}>{storeOptions[selected].text}</span>
        <img className={`dropdown-arrow ${open ? 'open' : ''}`} src={disabled ? DisabledDropdownArrow : DropdownArrow} alt={'Dropdown arrow'} />
      </button>
      {open &&
        <section className={'store-selection-container'}>
          <ul className={'store-list'}>{storeList}</ul>
        </section>
      }
    </section>
  );
}

export default StoreDropdown;