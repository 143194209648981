import { useEffect, useState } from 'react';
import { ASSET_CDN_URL } from '../Constants';
import { SIGN_UP_LINK, CALENDAR_LINK } from '../home/HomeConstants';
import useMediaQuery from '../hooks/CommonHooks';
import { PARTNER_LIST } from '../home/HomeConstants';
import PartnerCard from '../home/PartnerCard';
import './PostPage.scss';

const ConfusedConversation = `${ASSET_CDN_URL}/marketing/confused-conversation.svg`;
const TimerImg = `${ASSET_CDN_URL}/marketing/timer.svg`;
const NetworkImg = `${ASSET_CDN_URL}/marketing/network.svg`;
const DownDollarImg = `${ASSET_CDN_URL}/marketing/down-dollar.svg`;
const AddShiftsImg = `${ASSET_CDN_URL}/marketing/add-shifts.svg`;
const NotificationsImg = `${ASSET_CDN_URL}/marketing/notifications.svg`;
const AutomationImg = `${ASSET_CDN_URL}/marketing/automations.svg`;
const LeftArrowImg = `${ASSET_CDN_URL}/marketing/left-arrow.svg`;
const RightArrowImg = `${ASSET_CDN_URL}/marketing/right-arrow.svg`;
const DefaultTestimonialImg = `${ASSET_CDN_URL}/marketing/default-testimonial-image.svg`;

const HeaderContent = () => {
  return (
    <section className={'title-banner'}>
      <section className={'staffing-banner'}>
        <h2 className={'title'}>{'24/7 temporary staffing assistant, in your pocket.'}</h2>
        <h3 className={'subtitle'}>{'The easiest way to connect to Locums for your temporary shifts. Fill shifts in minutes, not days.'}</h3>
        <button className={'green-button very dark'} onClick={() => window.open(SIGN_UP_LINK)}>{'Find staff now'}</button>
      </section>
      <img className={'title-image'} src={ConfusedConversation} alt={''} />
    </section>
  )
};

const MobileHeaderContent = () => {
  return (
    <section className={'title-banner'}>
      <section className={'staffing-banner'}>
        <h2 className={'title'}>{'24/7 temporary staffing assistant, in your pocket.'}</h2>
      </section>
      <img className={'title-image'} src={ConfusedConversation} alt={''} />
      <h3 className={'subtitle'}>{'The easiest way to connect to Locums for your temporary shifts. Fill shifts in minutes, not days.'}</h3>
      <button className={'green-button very dark'} onClick={() => window.open(SIGN_UP_LINK)}>{'Find staff now'}</button>
    </section>
  )
};

const BannerContent = ({ text, subtitle, children = null }) => (
  <section className={'banner-content'}>
    <h2 className={'title'}>{text}</h2>
    <h3 className={'subtitle'}>{subtitle}</h3>
    {children}
  </section>
);

const LongCard = ({ title, image, children }) => (
  <section className={'long-card'}>
    <section className={'image-container'}>
      <img className={'card-image'} src={image} alt={''} />
    </section>
    <section className={'content'}>
      <h4 className={'title'}>{title}</h4>
      {children}
    </section>
  </section>
);

const AdvantageCard = ({ mainTitle, subtitle, title, advantages, buttonText, link }) => (
  <section className={'long-card advantage'}>
    <h4 className={'main-title'}>{mainTitle}</h4>
    <h5 className={'subtitle'}>{subtitle}</h5>
    <h6 className={'title'}>{title}</h6>
    <ul className={'advantage-list'}>
      {advantages.map(advantage => <li className={'advantage'} key={advantage}>{advantage}</li>)}
    </ul>
    <button className={'green-button very-dark'} onClick={() => window.open(link)}>{buttonText}</button>
  </section>
);

export const StepCounter = ({ stepNumber }) => (
  <section className={'step-number-container'}>
    <span className={'step-number'}>{stepNumber}</span>
  </section>
);

export const StepText = ({ additionalClassNames, stepHeader, children }) => (
  <section className={`step-text ${additionalClassNames.join(' ')}`}>
    <h4 className={'step-header'}>{stepHeader}</h4>
    {children}
  </section>
);

export const StepImage = ({ image, altText }) => (
  <section className={'step-image'}>
    <img src={image} alt={altText} />
  </section>
);

export const HOW_IT_WORKS_POSTING = [
  {
    className: 'one',
    stepHeaderText: 'Add your shifts, anytime',
    stepContentText: ['Set up your store and add shifts in 30 seconds within your self serve portal.', 'Never chase up on staff availabilities again as they automatically trigger notifications hundreds of Locums.'],
    stepImage: AddShiftsImg,
  },
  {
    className: 'two',
    stepHeaderText: 'Be notified for new requests',
    stepContentText: [`The portal gives you full visibility and control over who you'd like to staff for your shifts, so you can be confident they're the right fit.`, `On average, each shift is seen by ~300 staff.`],
    stepImage: NotificationsImg,
  },
  {
    className: 'three',
    stepHeaderText: 'Automate everything after confirmation',
    stepContentText: [`Automatically send confirmed staff instructions for onboarding (e.g. applying for a provider number).`, `Keep track of all your upcoming temp shifts and outstanding payments, in 1 spot.`],
    stepImage: AutomationImg,
  }
];
export const MobileHowItWorks = ({ hideTitle = false, content }) => {
  const contentSection = content.map(({ className, stepHeaderText, stepContentText, stepImage }, index) => {
    return (
      <section className={'step'} key={`how-it-works-section-${className}-${stepHeaderText}`}>
        <StepCounter stepNumber={index + 1} key={`step-counter-${index + 1}`} />
        <StepImage image={stepImage} altText={''} />
        <StepText additionalClassNames={[className]} stepHeader={stepHeaderText}>
          {stepContentText.map(text => <p className={'step-paragraph'} key={`step-paragraph-${text}`}>{text}</p>)}
        </StepText>
      </section>
    );
  });
  return (
    <section className={'how-it-works-block'}>
      { !hideTitle && <h3 className={'title'}>{'How it works'}</h3> }
      <section className={'how-it-works'}>
        {contentSection}
      </section>
    </section>
  );
}

export const HowItWorks = ({ hideTitle = false, content }) => {
  const stepCounters = <section className={'step-counters'}>{content.map((_, index) => <StepCounter stepNumber={index + 1} key={`step-counter-${index + 1}`} />)}</section>;
  return (
    <section className={'how-it-works-block'}>
      { !hideTitle && <h3 className={'title'}>{'How it works'}</h3>}
      <section className={'how-it-works'}>
        <section className={'step-column'}>
          <StepImage image={content[0].stepImage} altText={''} />
          <StepText additionalClassNames={[content[1].className]} stepHeader={content[1].stepHeaderText}>
            {content[1].stepContentText.map(text => <p className={'step-paragraph'} key={`step-paragraph-${text}`}>{text}</p>)}
          </StepText>
          <StepImage image={content[2].stepImage} altText={''} />
        </section>
        <span className={'vertical-line'} />
        {stepCounters}
        <section className={'step-column'}>
          <StepText additionalClassNames={[content[0].className]} stepHeader={content[0].stepHeaderText}>
            {content[0].stepContentText.map(text => <p className={'step-paragraph'} key={`step-paragraph-${text}`}>{text}</p>)}
          </StepText>
          <StepImage image={content[1].stepImage} altText={''} />
          <StepText additionalClassNames={['three']} stepHeader={'Automate everything after confirmation'}>
            {content[2].stepContentText.map(text => <p className={'step-paragraph'} key={`step-paragraph-${text}`}>{text}</p>)}
          </StepText>
        </section>
      </section>
    </section>
  );
}

const randomWalk = () => {
  const x = Math.floor(Math.random() * 2);
  if (x === 1) {
    return -1;
  }
  else {
    return 1;
  }
}

const Testimonial = ({ image, quote, name, title, isDefault = false }) => (
  <section className={'testimonial'}>
    <section className={`image-container ${isDefault ? 'default' : ''}`}>
      <img className={'image'} src={image} alt={name} />
    </section>
    <p className={'quote'}>{quote}</p>
    <h5 className={'name'}>{name}</h5>
    <h6 className={'title'}>{title}</h6>
  </section>
)

const Testimonials = () => {
  const testimonials = [
    { 
      image: DefaultTestimonialImg,
      isDefault: true,
      quote: `“We had such an amazing day today with Tara she is absolutely amazingly talented and we enjoyed working with her. Thanks again for taking an interest in helping me out and essentially saving my business.”`,
      name: 'Fiona Bament',
      title: 'Store Owner, Eyewear Specs Forbes'
    },
  ];
  const [index, setIndex] = useState(0);
  useEffect(() => {
    const id = setInterval(() => setIndex((index + randomWalk()) % testimonials.length), 10000);
    return () => clearInterval(id);
  });
  const moveLeft = () => setIndex((index - 1) % testimonials.length);
  const moveRight = () => setIndex((index + 1) % testimonials.length);
  return (
    <section className={'testimonial-carousel'}>
      <button className={'left-button carousel-nav'} onClick={moveLeft}>
        <img className={'left-arrow'} src={LeftArrowImg} alt={'Left arrow'} />
      </button>
      <Testimonial {...testimonials[index]} />
      <button className={'right-button carousel-nav'} onClick={moveRight}>
        <img className={'right-arrow'} src={RightArrowImg} alt={'Right arrow'} />
      </button>
    </section>
  );
};

export const BottomBanner = ({ title, subtitle, buttonText, link }) => {
  const onClick = () => window.open(link);
  return (
    <section className={'bottom-banner'}>
      <h3 className={'title'}>{title}</h3>
      <h4 className={'subtitle'}>{subtitle}</h4>
      <button className={'green-button very-dark'} onClick={onClick}>{buttonText}</button>
    </section>
  )
}

const WebPostPage = () => {
  const partnerList = PARTNER_LIST.map((p) => <PartnerCard {...p} key={`partner-${p.name}`} />);
  return (
    <article className={'post-page web'}>
      <HeaderContent />
      <BannerContent text={'Why use Locumly?'} subtitle={`Here's why the Locumly temporary staffing assistant is something special`} />
      <section className={'card-array'}>
        <LongCard title={'Automate your staffing'} image={TimerImg}>
          <p>{`Add as many shifts as you'd like in less than 30 seconds. We take over from there, with fully automated sourcing of staff. You get final say over who you'd like to work for your store.`}</p>
          <p>{`No more playing phone tag with staff availabilities.`}</p>
        </LongCard>
        <LongCard title={'Deep talent pool, on demand'} image={NetworkImg}>
          <p>{`Fill your shifts in minutes, not days. Our talent communities are built from social media not emails that land in the spam folder.`}</p>
          <p>{`No more closing appointment books and losing out on $3K+ revenue per day.`}</p>
        </LongCard>
        <LongCard title={'Fraction of Agency costs'} image={DownDollarImg}>
          <p>{`No such thing as paying to post a job. We charge a fraction of average recruitment agency fees, only once you have found the temp staff your business needs.`}</p>
        </LongCard>
      </section>
      <BannerContent text={'300+ stores have joined'} subtitle={`Pharmacy and Optometry stores nationwide rely on us for their temporary staffing.`}>
        <section className={'partners-list-container'}>
          <ul className={'partners-list'}>
            {partnerList}
          </ul>
        </section>
      </BannerContent>
      <HowItWorks hideTitle={false} content={HOW_IT_WORKS_POSTING} />
      <BannerContent text={'Hear directly from store managers'} subtitle={'What they have said after using Locumly'} />
      <Testimonials />
      <BannerContent text={'Flexible pricing for your needs'} subtitle={'Whether you need staffing for a single store, or hundreds.'} />
      <section className={'card-array advantage-array'}>
        <AdvantageCard
          mainTitle={'Complete Access'}
          subtitle={'Perfect for hiring temp staff for 1 - 10 stores'}
          title={<><span className={'large'}>{'10%'}</span><span className={'small'}>{'Service Fee'}</span></>}
          advantages={['Add unlimited shifts for free', 'Access our talent pool on demand', 'Book talent that suits your business', 'Cancellation protection']}
          buttonText={'Sign up my store'}
          link={SIGN_UP_LINK}
        />
        <AdvantageCard
          mainTitle={'Enterprises'}
          subtitle={'For store networks hiring temp staff across many stores'}
          title={'Contact us'}
          advantages={['Receive dedicated 24/7 support', 'Set up custom permissions for store managers to add shifts', 'Easy store preference management', 'Discounted pricing']}
          buttonText={'Get in touch'}
          link={CALENDAR_LINK}
        />
      </section>
      <BottomBanner
        title={'Want to know more?'}
        subtitle={`Schedule a time and we'll walk you through how we solve temp staffing.`}
        buttonText={'Request a demo'}
        link={CALENDAR_LINK}
      />
    </article>
  )
};
<BottomBanner
title={'Want to know more?'}
subtitle={`Schedule a time and we'll walk you through how we solve temp staffing.`}
buttonText={'Request a demo'}
link={CALENDAR_LINK}
/>
const MobilePostPage = () => {
  const partnerList = PARTNER_LIST.map((p) => <PartnerCard {...p} key={`partner-${p.name}`} />);
  return (
    <article className={'post-page mobile'}>
      <MobileHeaderContent />
      <BannerContent text={'Why use Locumly?'} subtitle={`Here's why the Locumly temporary staffing assistant is something special`} />
      <section className={'card-array'}>
        <LongCard title={'Automate your staffing'} image={TimerImg}>
          <p>{`Add as many shifts as you'd like in less than 30 seconds. We take over from there, with fully automated sourcing of staff. You get final say over who you'd like to work for your store.`}</p>
          <p>{`No more playing phone tag with staff availabilities.`}</p>
        </LongCard>
        <LongCard title={'Deep talent pool, on demand'} image={NetworkImg}>
          <p>{`Fill your shifts in minutes, not days. Our talent communities are built from social media not emails that land in the spam folder.`}</p>
          <p>{`No more closing appointment books and losing out on $3K+ revenue per day.`}</p>
        </LongCard>
        <LongCard title={'Fraction of Agency costs'} image={DownDollarImg}>
          <p>{`No such thing as paying to post a job. We charge a fraction of average recruitment agency fees, only once you have found the temp staff your business needs.`}</p>
        </LongCard>
      </section>
      <BannerContent text={'300+ stores have joined'} subtitle={`Pharmacy and Optometry stores nationwide rely on us for their temporary staffing.`}>
        <section className={'partners-list-container'}>
          <ul className={'partners-list'}>
            {partnerList}
          </ul>
        </section>
      </BannerContent>
      <MobileHowItWorks hideTitle={false} content={HOW_IT_WORKS_POSTING} />
      <BannerContent text={'Hear directly from store managers'} subtitle={'What they have said after using Locumly'} />
      <Testimonials />
      <BannerContent text={'Flexible pricing for your needs'} subtitle={'Whether you need staffing for a single store, or hundreds.'} />
      <section className={'card-array advantage-array'}>
        <AdvantageCard
          mainTitle={'Complete Access'}
          subtitle={'Perfect for hiring temp staff for 1 - 10 stores'}
          title={<><span className={'large'}>{'10%'}</span><span className={'small'}>{'Service Fee'}</span></>}
          advantages={['Add unlimited shifts for free', 'Access our talent pool on demand', 'Book talent that suits your business', 'Cancellation protection']}
          buttonText={'Sign up my store'}
          link={SIGN_UP_LINK}
        />
        <AdvantageCard
          mainTitle={'Enterprises'}
          subtitle={'For store networks hiring temp staff across many stores'}
          title={'Contact us'}
          advantages={['Receive dedicated 24/7 support', 'Set up custom permissions for store managers to add shifts', 'Easy store preference management', 'Discounted pricing']}
          buttonText={'Get in touch'}
          link={CALENDAR_LINK}
        />
      </section>
      <BottomBanner
        title={'Want to know more?'}
        subtitle={`Schedule a time and we'll walk you through how we solve temp staffing.`}
        buttonText={'Request a demo'}
        link={CALENDAR_LINK}
      />
    </article>
  )
}

const PostPage = () => {
  const isMobile = useMediaQuery('(max-width: 1020px)');
  return isMobile ? <MobilePostPage /> : <WebPostPage />;
}

export default PostPage;
