import Cookies from 'js-cookie';
import { USER_ROLES } from './Constants.js';

const postData = async (url, data) => {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
  .then(response => response.json());
  return response;
};

const postMessage = async (url, data) => {
  const { locumId, businessId, storeId, message, files } = data;
  const formData = new FormData();
  formData.append('locumId', locumId);
  formData.append('storeId', storeId);
  formData.append('businessId', businessId);
  formData.append('message', message);
  files.forEach(file => formData.append('files', file));
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    body: formData,
  }).then(response => response.json());
  return response;
}

const postAvatar = async (url, avatar) => {
  const formData = new FormData();
  formData.append('avatar', avatar);
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    body: formData,
  }).then(response => response.json());
  return response;
}

const postStoreAvatar = async (url, data) => {
  const { avatar, storeId } = data;
  const formData = new FormData();
  formData.append('avatar', avatar);
  formData.append('storeId', storeId);
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    body: formData,
  }).then(response => response.json());
  return response;
}

const postStoreLocumOnboardingDocument = async (url, data) => {
  const { storeId, file } = data;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('storeId', storeId);
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    body: formData,
  }).then(response => response.json());
  return response;
}


const getData = async (url) => {
  const response = await fetch(url, { method: 'GET', mode: 'cors', cache: 'default' })
    .then(response => response.json())
  return response;
};

// Mapbox API URL Links
export const ACCESS_TOKEN = `pk.eyJ1IjoibG9jdW1seSIsImEiOiJja3l1dXpydXMxcjZnMnBwZWx5OXJ6dnoxIn0.gE-chcAAEETzmDZgOOgTqA`;
const MAPBOX_API_URL = 'https://api.mapbox.com';
const FORWARD_GEOCODING_URL = `${MAPBOX_API_URL}/geocoding/v5/mapbox.places`;
const GEOCODING_PARAMS = `?access_token=${ACCESS_TOKEN}&autocomplete=true&country=AU`;
const REVERSE_GEOCODING_API_URL = `${MAPBOX_API_URL}/geocoding/v5/mapbox.places`;

export const getLocationSuggestions = async (searchText) => {
  const forwardGeocodingUrl = `${FORWARD_GEOCODING_URL}/${encodeURIComponent(searchText)}.json${GEOCODING_PARAMS}`;
  return getData(forwardGeocodingUrl);
};

export const getLocationFromCoordinates = async (longitude, latitude) => {
  const backwardGeocodingUrl = `${REVERSE_GEOCODING_API_URL}/${longitude},${latitude}.json?access_token=${ACCESS_TOKEN}`;
  return getData(backwardGeocodingUrl);
}

// TODO: Clean up all these links and split Api.js into BusinessApi.js, UserApi.js, LocumApi.js, StoreApi.js and split by GET vs POST

// Business API urls
const BUSINESS_LOGIN_URL = '/api/business/login';
const SELECT_LOCUM_FOR_SHIFT_URL = '/api/business/shift/locum/select';
const SHIFT_DELETION_URL = '/api/business/shift/delete';
const ADD_SHIFT_URL = '/api/business/store/shifts/add';
const SHIFT_EDIT_URL = '/api/business/shift/edit';
const BUSINESS_ACC_UPDATE_ALL = '/api/business/settings/update';

// Locum API urls
const ONBOARDING_NUMBERS_URL = '/api/locum/onboarding/numbers';
const LOCUM_PROFILE_EDIT_URL = '/api/locum/profile/edit';
const STRIPE_ONBOARDING_URL = '/api/locum/stripe/onboarding';
const STRIPE_DASHBOARD_URL = '/api/locum/stripe/dashboard';
const LOCUM_AVATAR_URL = '/api/locum/avatar/upload';
const UPDATE_EXPERIENCE_URL = '/api/locum/experiences/update';
const LOCUM_LOGIN_URL = '/api/locum/login';
const LOCUM_ACC_UPDATE_ALL = '/api/locum/settings/update';
const SHIFT_SETTINGS_URL = '/api/locum/settings/geolocation/update';
const SHIFT_COMPLETION_URL = '/api/locum/shift/complete';
const CONFIRM_SHIFT_URL = '/api/locum/shift/confirm';
const SHIFT_LOCUM_EDIT_URL = '/api/locum/shift/edit';
const REQUEST_SHIFT_URL = '/api/locum/shift/request';
const SHIFT_WITHDRAWAL_URL = '/api/locum/shift/withdraw';
const LOCUM_STORE_SUBSCRIPTION = '/api/locum/store/subscribe';

// Store API urls
const STORE_AVATAR_URL = '/api/store/avatar/upload';
const STORE_CONTACT_URL = '/api/store/contact/edit';
const STORE_ADDRESS_URL = '/api/store/address/edit';
const STORE_ORGANIZATION_URL = '/api/store/organization/edit';
const STORE_BANKING_URL = '/api/store/banking/edit';
const STORE_LOCUM_ONBOARDING_DOCUMENT_URL = '/api/store/documents/locum/onboarding/upload';
const STORE_LOCUM_ONBOARDING_DOCUMENT_DELETE_URL = '/api/store/documents/locum/onboarding/delete'
const STORE_SCHEDULE_URL = '/api/store/schedule/update';
const MANAGE_PREFERENCES_URL = '/api/store/preferences/update';
const STORE_SHIFTS_URL = '/api/store/shifts/';

// User API urls
const PROFILE_URL = '/api/user/profile';
const ALL_SHIFTS_URL = '/api/user/shifts/all';
const ALL_STORES_URL = '/api/user/stores/all';
const SINGLE_STORE = '/api/user/stores/';
const SINGLE_SHIFT = '/api/user/shift/';
const MANY_SHIFTS = '/api/user/shifts/many';
const UPDATE_PASSWORD_URL = '/api/user/password/update';
const CONFIRM_PASSWORD_URL = '/api/user/password/confirm';
const COMPLETE_ONBOARDING_URL = '/api/user/onboarding/complete';
const MESSAGES_URL = '/api/user/messages';
const EMAIL_MESSAGE_URL = '/api/user/email/message';
const SEND_MESSAGE_URL = '/api/user/message/send';
const SEND_SEEN_MESSAGE_URL = '/api/user/message/seen';
const SEEN_NOTIFICATIONS_URL = '/api/user/notifications/seen';
const RESET_PASSWORD_URL = '/api/user/password/reset';
const RESET_PASSWORD_UPDATE_URL = '/api/user/password/reset/update';
const VALIDATE_RESET_TOKEN_URL = '/api/user/reset/token/validate';
const EDIT_ACCOUNT_DETAILS_URL = '/api/user/account/edit';
const SMS_SETTINGS_URL = '/api/user/sms/settings/update';
const EMAIL_SETTINGS_URL = '/api/user/email/settings/update';
const SHIFT_ISSUE_URL = '/api/user/shift/report';
const ALL_CHATS_URL = '/api/user/chats';
const ALL_NOTIFICATIONS_URL = '/api/user/notifications';

const ERROR_LOGGER_URL = '/api/error/log';

export const postFrontendErrorLog = (data) => {
  return postData(ERROR_LOGGER_URL, data);
}

export const getAllShifts = async () => {
  return getData(ALL_SHIFTS_URL);
};

export const getAllChats = async () => {
  return getData(ALL_CHATS_URL);
};

export const getAllNotifications = async () => {
  return getData(ALL_NOTIFICATIONS_URL);
};

export const getAllStores = async () => {
  return getData(ALL_STORES_URL);
}

export const getShiftsForStore = async (storeId) => {
  return getData(STORE_SHIFTS_URL + storeId);
}

export const getOneStore = async (storeId) => {
  return getData(SINGLE_STORE + storeId);
}

export const getOneShift = async (shiftId) => {
  return getData(SINGLE_SHIFT + shiftId);
}

export const getManyShifts = async (shiftIds) => {
  return postData(MANY_SHIFTS, { shiftIds });
}

export const getProfile = async () => {
  return getData(PROFILE_URL);
};

export const getStripeDashboardUrl = async () => {
  return getData(STRIPE_DASHBOARD_URL);
}

export const getMessages = async (receiver) => {
  return getData(MESSAGES_URL, receiver);
};

export const postStripeOnboardingUrl = async (returnUrl) => {
  return postData(STRIPE_ONBOARDING_URL, { returnUrl });
};

export const postNewPreferences = async (storeId, preferences) => {
  return postData(MANAGE_PREFERENCES_URL, { storeId, preferences });
};

export const postLocumSelection = async (shiftId, locumId) => {
  return postData(SELECT_LOCUM_FOR_SHIFT_URL, { shiftId, locumId });
};

// Returns the profile of the logged in user - note the shape differs between the locum and business roles
export const postLogin = async (user, setAuthed, setProfile) => {
  const loginUrl = user.role === USER_ROLES.LOCUM ? LOCUM_LOGIN_URL : BUSINESS_LOGIN_URL;
  return postData(loginUrl, user)
    .then((response) => {
      const { profile, token } = response;
      setProfile(profile);
      Cookies.set('token', token);
      setAuthed(true);
    });
};

// Returns the array of newly created shifts
export const postShifts = async (shifts, storeId) => {
  return postData(ADD_SHIFT_URL, { shifts, storeId });
};

// Returns the newly request shift
export const postShiftRequest = async (shiftIds, requests) => {
  return postData(REQUEST_SHIFT_URL, { shiftIds, requests });
};

export const postShiftConfirmation = async (shiftId, storeId, providerNumber) => {
  return postData(CONFIRM_SHIFT_URL, { shiftId, storeId, providerNumber });
};

export const postShiftCompletion = async (shiftId, invoiceGeneration, review) => {
  return postData(SHIFT_COMPLETION_URL, { shiftId, invoiceGeneration, review });
};

export const postShiftDeletion = async (shiftId, reason) => {
  return postData(SHIFT_DELETION_URL, { shiftId, reason });
};

export const postShiftChangeFromLocum = async (shiftId, changeSet, registeredForGst) => {
  return postData(SHIFT_LOCUM_EDIT_URL, { shiftId, changeSet, gstFree: !registeredForGst });
}

export const postShiftEdit = async (shiftId, changeSet, reason) => {
  return postData(SHIFT_EDIT_URL, { shiftId, changeSet, reason });
};

export const postUpdatePassword = async (password, firstTime) => {
  return postData(UPDATE_PASSWORD_URL, { password, firstTime });
};

export const postLocumProfileEdit = async (about, abn, ahpra) => {
  return postData(LOCUM_PROFILE_EDIT_URL, { about, abn, ahpra });
};

export const postAbnAndAhpra = async (abn, ahpra) => {
  return postData(ONBOARDING_NUMBERS_URL, { abn, ahpra });
};

export const postLocumAccountAll = async (accountSettings, smsSettings, emailSettings, shiftSettings) => {
  return postData(LOCUM_ACC_UPDATE_ALL, { accountSettings, smsSettings, emailSettings, shiftSettings });
}

export const postBusinessAccountAll = async (accountSettings, smsSettings, emailSettings) => {
  return postData(BUSINESS_ACC_UPDATE_ALL, { accountSettings, smsSettings, emailSettings });
}

export const postCompleteOnboarding = async () => {
  return postData(COMPLETE_ONBOARDING_URL, {});
};

export const postNewExperiences = async (experiences) => {
  return postData(UPDATE_EXPERIENCE_URL, { experiences });
}

export const postEmailMessage = async (name, email, message) => {
  return postData(EMAIL_MESSAGE_URL, { name, email, message });
}

export const postSendMessage = async (locumId, businessId, storeId, message, files) => {
  return postMessage(SEND_MESSAGE_URL, { locumId, businessId, storeId, message, files });
}

export const postSeenMessage = async (locumId, businessId, storeId) => {
  return postData(SEND_SEEN_MESSAGE_URL, { locumId, businessId, storeId });
}

export const postShiftWithdrawal = async (shiftId, reason) => {
  return postData(SHIFT_WITHDRAWAL_URL, { shiftId, reason });
}

export const postSeenNotifications = async () => {
  return postData(SEEN_NOTIFICATIONS_URL);
}

export const postShiftIssue = async (shiftId, message) => {
  return postData(SHIFT_ISSUE_URL, { shiftId, message });
}

export const postResetPassword = async (emailAddress, role) => {
  return postData(RESET_PASSWORD_URL, { emailAddress, role, originUrl: window.location.origin });
}

export const postConfirmPassword = async (emailAddress, password, role) => {
  return postData(CONFIRM_PASSWORD_URL, { emailAddress, password, role });
}

export const postResetPasswordUpdate = async (emailAddress, password, role, token) => {
  return postData(RESET_PASSWORD_UPDATE_URL, { emailAddress, password, role, token });
}

export const postValidateResetToken = async (emailAddress, role, token) => {
  return postData(VALIDATE_RESET_TOKEN_URL, { emailAddress, role, token });
}

export const postLocumAvatarUpload = async (avatar) => {
  return postAvatar(LOCUM_AVATAR_URL, avatar);
}

export const postAccountDetails = async (accountDetails) => {
  return postData(EDIT_ACCOUNT_DETAILS_URL, { accountDetails });
}

export const postSmsSettings = async (smsSettings) => {
  return postData(SMS_SETTINGS_URL, { smsSettings });
}

export const postEmailSettings = async (emailSettings) => {
  return postData(EMAIL_SETTINGS_URL, { emailSettings });
}

export const postShiftSettings = async (shiftSettings) => {
  return postData(SHIFT_SETTINGS_URL, { shiftSettings });
}

export const postStoreSubscription = async (stores) => {
  return postData(LOCUM_STORE_SUBSCRIPTION, { stores });
}

export const postStoreAvatarUpload = async (storeId, avatar) => {
  return postStoreAvatar(STORE_AVATAR_URL, { storeId, avatar });
}

export const postStoreContactDetails = async (storeId, contact, storeName) => {
  return postData(STORE_CONTACT_URL, { storeId, contact, storeName });
}

export const postStoreAddressDetails = async (storeId, address) => {
  return postData(STORE_ADDRESS_URL, { storeId, address });
}

export const postStoreOrganizationDetails = async (storeId, organization) => {
  return postData(STORE_ORGANIZATION_URL, { storeId, organization });
}

export const postStoreBankingDetails = async (storeId, banking) => {
  return postData(STORE_BANKING_URL, { storeId, banking });
}

export const postStoreLocumOnboardingUpload = async (storeId, file) => {
  return postStoreLocumOnboardingDocument(STORE_LOCUM_ONBOARDING_DOCUMENT_URL, { storeId, file });
}

export const postStoreLocumOnboardingDelete = async (storeId) => {
  return postData(STORE_LOCUM_ONBOARDING_DOCUMENT_DELETE_URL, { storeId });
}

export const postNewSchedule = async (storeId, schedule) => {
  return postData(STORE_SCHEDULE_URL, { storeId, schedule });
}
