import React from 'react';
import { Route } from 'react-router-dom';

import { RequireNoAuth } from '../components/hooks/AuthHook';

import {
  LoginPage,
  LocumLoginPage,
  BusinessLoginPage,
  ResetLocumPasswordPage,
  ResetBusinessPasswordPage,
  ChangePasswordPage
} from '../components/login/LoginPage';
import LocumlyPage from '../wrappers/LocumlyPage';

function getLoginRoutes() {
  return (
    <Route path={'/login'} element={<LocumlyPage />}>
      <Route index={true} element={<RequireNoAuth><LoginPage /></RequireNoAuth>} />
      <Route path={'business'} element={<RequireNoAuth><BusinessLoginPage /></RequireNoAuth>} />
      <Route path={'locum'} element={<RequireNoAuth><LocumLoginPage /></RequireNoAuth>}/>
      <Route path={'business/reset'} element={<RequireNoAuth><ResetBusinessPasswordPage /></RequireNoAuth>} />
      <Route path={'locum/reset'} element={<RequireNoAuth><ResetLocumPasswordPage /></RequireNoAuth>}/>
      <Route path={'password/reset'} element={<RequireNoAuth><ChangePasswordPage /></RequireNoAuth>} />
      <Route path={'*'} element={<RequireNoAuth><LoginPage /></RequireNoAuth>} />
    </Route>
  );
}

export default getLoginRoutes;