import React from 'react';
import { PARTNER_LIST, SIGN_UP_LINK } from './HomeConstants.js';
import PartnerCard from './PartnerCard.jsx';
import './PartnersSection.scss';

/** Component for Locumly Partners section. */
const PartnersSection = () => {
  const partnerList = PARTNER_LIST.map((p) => <PartnerCard {...p} key={`partner-${p.name}`} />);
  return (
    <section className={'partners-section'}>
      <h3 className={'title'}>{'Store Networks using Locumly'}</h3>
      <h4 className={'subtitle'}>{'Access the largest range of corporate and independent networks.'}</h4>
      <h4 className={'subtitle'}>{'Stay with your preferred work culture, or test the waters before going into a permanent role.'}</h4>
      <section className={'partners-list-container'}>
        <ul className={'partners-list'}>
          {partnerList}
        </ul>
      </section>
      <section className={'book-next-shift-section'}>
        <h3 className={'title'}>{'Book your next shift'}</h3>
        <h4 className={'subtitle'}>{'It takes 2 minutes to start flexible work.'}</h4>
        <button className={'green-button very-dark'} onClick={() => window.open(SIGN_UP_LINK)}>{'Sign up'}</button>
      </section>
    </section>
  );
};

export default PartnersSection;