import React, { useState } from 'react';
import { ASSET_CDN_URL } from '../../Constants';
import { useNavigate } from 'react-router-dom';
import useStore from '../../hooks/StoreHook.jsx';
import ConfirmationModal from '../store/ConfirmationModal.jsx';
import { MILLISECONDS_IN_AN_HOUR } from '../../Constants.js';
import { formatDatesWithTimeZone } from '../../../helpers/DateUtils.js';
import { SHIFT_STATUSES } from '../../Constants.js';
import { postShiftIssue, getShiftsForStore } from  '../../Api.js';
import './BusinessInvoiceCard.scss';

const DateIcon = `${ASSET_CDN_URL}/shift-card/shift-date.png`;
const TimeIcon = `${ASSET_CDN_URL}/shift-card/shift-time.png`;
const LocationIcon = `${ASSET_CDN_URL}/shift-card/shift-location.png`;

const ReportIssueConfirmation = ({ close, shift }) => {
  const { setShifts } = useStore();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const confirm = () => {
    setLoading(true);
    postShiftIssue(shift._id, message)
      .then(() => getShiftsForStore(shift.storeId)
        .then(({ shifts }) => {
          setLoading(false);
          setShifts(shifts);
          close();
        }));
  };
  return (
    <ConfirmationModal loading={loading} close={close} additionalClassName={'report-shift'} confirm={confirm} confirmText={'Report issue'}>
      <h3 className={'confirmation-title'}>{'Something wrong with your shift?'}</h3>
      <p className={'confirmation-text'}>
        {`We are here to help.`}
      </p>
      <p className={'confirmation-text'}>
        {`Send us a quick message below describing your issue and our support team will respond within 48 hours.`}
      </p>
      <textarea
        className={'message'}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder={'A short message describing the issue you encountered with this shift...'}
      />  
    </ConfirmationModal>
  );
}

export const ReportIssueButton = ({ shift }) => {
  const [open, setOpen] = useState(false);
  const clickOpen = () => setOpen(true);
  const close = () => setOpen(false);
  return (
    <>
      <button className={'report-issue-button very-dark'} onClick={clickOpen}>{'Report An Issue'}</button>
      { open && <ReportIssueConfirmation close={close} shift={shift} /> }
    </>
  );
}

export const BusinessInvoiceCard = ({ shift, select = null }) => {
  const navigate = useNavigate();
  const { name, startTime, endTime, address, rate, locum, status, invoiceLink, providerNumber, storeId, billToMedicare } = shift;
  const { suburb, state, timeZone } = address;
  const { firstName, lastName } = locum;
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);
  const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(startDate, endDate, false, timeZone);
  const total = (rate / 100).toFixed(2) * (endTime - startTime) / MILLISECONDS_IN_AN_HOUR;
  const { className, text, hasSubtitle, subtitle } = SHIFT_STATUSES[status];
  const isInvoice = status === SHIFT_STATUSES.INVOICE.key;
  const downloadInvoice = () => {
    const link = document.createElement('a');
    link.href = invoiceLink;
    link.click();
  };
  const rateDisplay = billToMedicare ? 'Locum takes home Medicare billing' : `$${(rate / 100).toFixed(2)}/hr`;
  const sendMessage = () => navigate('/user/messages', { state: { open: false, locum, store: { name, _id: storeId } }});
  const viewShift = () => navigate(`/find/shift?id=${shift._id}`);
  return (
    <li className={'invoice-card business'}>
      <section className={`status ${className}`}>
        {text}
        {hasSubtitle && <span className={'subtitle'}>{subtitle}</span>}
      </section>
      <h2 className={'store-name'}>{name}</h2>
      <span className={'shift-date'}>
        <img className={'shift-icon'} src={DateIcon} alt={'Date icon'} />
        {date}
      </span>
      <span className={'shift-time'}>
        <img className={'shift-icon'} src={TimeIcon} alt={'Time icon'} />
        {`${startTimeDisplay} - ${endTimeDisplay}`}
      </span>
      <span className={'shift-location'}>
        <img className={'shift-icon'} src={LocationIcon} alt={'Location icon'} />
        {`${suburb}, ${state}`}
      </span>
      <span className={'shift-rate'}>{rateDisplay}</span>
      { !billToMedicare && <span className={'shift-total'}>{`Total: $${total.toFixed(2)}`}</span>}
      { !!providerNumber && <span className={'shift-provider-number'}>{`Provider Number: ${providerNumber}`}</span>}
      <p className={'shift-worker'}>{`Shift completed by ${firstName} ${lastName}`}</p>
      <section className={'invoice-buttons'}>
        { !billToMedicare && 
          <button className={'download-invoice-button very-light'} onClick={downloadInvoice}>
            {'View Invoice'}
          </button>
        }
        { isInvoice && <ReportIssueButton shift={shift} /> }
        <button className={'message-locum-button very-dark'} onClick={sendMessage}>
          {`Message Locum`}
        </button>
        <button className={'view-shift-button very-dark'} onClick={viewShift}>{'View Shift'}</button>
      </section>
    </li>
  );
};

export default BusinessInvoiceCard;