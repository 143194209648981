import { ASSET_CDN_URL } from '../Constants';
import './HowItWorksSection.scss';

const WatchImg = `${ASSET_CDN_URL}/home/watch.svg`;
const PhoneImg = `${ASSET_CDN_URL}/home/phone.svg`;
const CalendarImg = `${ASSET_CDN_URL}/home/calendar.svg`;

const HowItWorksRow = ({ title, image, children }) => {
  return (
    <section className={'how-it-works-row'}>
      <img className={'image'} src={image} alt={''} />
      <section className={'content'}>
        <h4 className={'title'}>{title}</h4>
        {children}
      </section>
    </section>
  );
}

const HowItWorksSection = () => {
  return (
    <section className={'how-it-works-section'}>
      <h2 className={'title'}>{'How it works'}</h2>
      <h3 className={'subtitle'}>{'For healthcare professionals'}</h3>
      <section className={'how-it-works'}>
        <HowItWorksRow title={'Your single source to find flexible work'} image={PhoneImg}>
          <p className={'text'}>
            {'Access the latest and greatest source of Healthcare jobs available to you. Get notified for relevant jobs based on where, and when you want to work.'}
          </p>
        </HowItWorksRow>
        <HowItWorksRow title={'Make informed choices about your work'} image={CalendarImg}>
          <p className={'text'}>
            {'Choose where and when you want to work. Be confident you are charging the right pay rates with our recommendation engine.'}
          </p>
        </HowItWorksRow>
        <HowItWorksRow title={'Get paid, without the paperwork'} image={WatchImg}>
          <p className={'text'}>
            {'Generate invoices with a single click. Know exactly when you’ll be paid via your personal dashboard.'}
          </p>
        </HowItWorksRow>
      </section>
    </section>
  )
}

export default HowItWorksSection;