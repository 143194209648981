import React from 'react';

import QRCodeRedirect from './QRCodeRedirect';

const OPTOMETRY_INSTAGRAM_URL = 'https://www.instagram.com/locumly/';

function OptometryQRCodeRedirect() {
  return <QRCodeRedirect url={OPTOMETRY_INSTAGRAM_URL} />;
}

OptometryQRCodeRedirect.displayName = 'OptometryQRCodeRedirect';
export default OptometryQRCodeRedirect;