import { Route, Routes } from 'react-router-dom';
import React from 'react';

import HomePage from '../components/home/HomePage';
import LocumlyPage from '../wrappers/LocumlyPage';
import getBusinessRoutes from './BusinessRoutes';
import getFindRoutes from './FindRoutes';
import getHomeRoutes from './HomeRoutes';
import getLocumRoutes from './LocumRoutes';
import getLoginRoutes from './LoginRoutes';
import getQRCodeRoutes from './QRCodeRoutes';
import getStoreRoutes from './StoreRoutes';
import getStoreProfileAppRoutes from './StoreProfileAppRoutes';
import getUserRoutes from './UserRoutes';

function AppRoutes() {
  return (
    <Routes>
      {getBusinessRoutes()}
      {getFindRoutes()}
      {getHomeRoutes()}
      {getLoginRoutes()}
      {getLocumRoutes()}
      {getQRCodeRoutes()}
      {getStoreRoutes()}
      {getStoreProfileAppRoutes()}
      {getUserRoutes()}
      <Route path={'/'} element={<LocumlyPage />}>
        <Route path={'*'} element={<HomePage />} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;