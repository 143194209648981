import React, { useState, useEffect } from 'react';
import { ASSET_CDN_URL } from '../../Constants';
import { getLocationSuggestions } from '../../Api.js'
import { useCurrentLocation } from '../../hooks/CommonHooks.jsx';

import './MobileSearchWhere.scss';

const SearchIcon = `${ASSET_CDN_URL}/icons/magnifying-glass-icon.svg`;
const ExitIcon = `${ASSET_CDN_URL}/icons/exit-icon.svg`;
const LocationDot = `${ASSET_CDN_URL}/icons/location-dot-icon.svg`;
const ACTIcon = `${ASSET_CDN_URL}/navbar/ACT.svg`;
const NSWIcon = `${ASSET_CDN_URL}/navbar/NSW.svg`;
const QLDIcon = `${ASSET_CDN_URL}/navbar/QLD.svg`;
const SAIcon = `${ASSET_CDN_URL}/navbar/SA.svg`;
const VICIcon = `${ASSET_CDN_URL}/navbar/VIC.svg`;
const WAIcon = `${ASSET_CDN_URL}/navbar/WA.svg`;
const AusIcon = `${ASSET_CDN_URL}/navbar/australia.svg`;

const STATES = [
  {state: 'Near me', 
  coordinates: {
    longitude: null,
    latitude: null
  }}, 
  {state: 'Victoria',
  coordinates: {
    longitude: 144.28111111,
    latitude: -36.85416667
  }},
  {state: 'New South Wales',
  coordinates: {
    longitude: 147.01666667,
    latitude: -32.16333333
  }}, 
  {state: 'Queensland',
  coordinates: {
    longitude: 144.43166667,
    latitude: -22.48694444
  }}, 
  {state: 'Western Australia',
  coordinates: {
    longitude: 122.29833333,
    latitude: -25.32805556
  }}, 
  {state: 'South Australia',
  coordinates: {
    longitude: 135.76333333,
    latitude: -30.05833333
  }}, 
  {state: 'Australian Capital Territory',
  coordinates: {
    longitude: 149.00138889,
    latitude: -35.49000000
  }}]
const STATE_IMG = [
                    AusIcon,
                    NSWIcon,
                    QLDIcon,
                    WAIcon,
                    SAIcon,
                    VICIcon,
                    ACTIcon
                  ];

// ********************** WHERE ********************** //
// Each Item for the Mobile Search Carousel. E.g. NSW, QLD, VIC
const CarouselItem = ({itemLabel, itemImage, handleStatePicked, chosenState, stateId, handleActivePanel}) => {
  const handleStateSelection = () => {
    handleStatePicked(stateId);
    handleActivePanel('isWhen');
  }
  return (
    <div className={'state-picker-item'}>
      <div className={'state-picker-button-container'}>
        <button className={chosenState === stateId ? 'selected-button state-picker-button' : 'state-picker-button'} onClick={() => handleStateSelection()}>
          <img className={'state-picker-image'} src={itemImage} alt={'Flexible Location'} />
        </button>
        <div className={'carousel-item-label'}>
          {itemLabel}
        </div>
      </div>
    </div>
  )
}

const AutofillSearch = ({suggestion, whereFilter, setWhereFilter, setSearchActive, handleActivePanel}) => {
  const { geometry, place_name, text } = suggestion;
  const [long, lat] = geometry.coordinates;
  const handleSuggestionSelect = (selectedLocation) => {
    setWhereFilter({...whereFilter, coordinates: { longitude: long, latitude: lat }, searchText: selectedLocation, byState: false, text, chosenState: null});
    setSearchActive(false);
    handleActivePanel('isWhen');
  }
  return (
    <div className={'suggestion-box'} onClick={() => handleSuggestionSelect(place_name)}>
      <div className={'suggestion-icon-container'}>
        <img className={'suggestion-icon'} src={LocationDot} alt={'location marker'} />
      </div>
      <div className={'suggestion-place'}>
        <div className={'suggestion-text'}>{place_name}</div>
      </div>
    </div>
  )
}

// Handles the overall Where filter of the search component.
export const WebSearchWhere = ({ handleActivePanel, activePanel, searchActive, setSearchActive, whereFilter, setWhereFilter }) => {
  const handleStatePicked = (stateId) => {
    setWhereFilter({
      ...whereFilter,
      searchText: stateId === 0 ? '' : STATES[stateId].state,
      text: STATES[stateId].state,
      byState: true,
      nearMe: stateId === 0 ? true : false,
      coordinates: STATES[stateId].coordinates,
      chosenState: stateId
    })
  }

  const [locationSuggestions, setLocationSuggestions] = useState([]);
  useEffect(() => {
    if (whereFilter.searchText.length > 3) {
      getLocationSuggestions(whereFilter.searchText)
        .then((response) => {
          setLocationSuggestions(response.features);
        });
    } else if (whereFilter.searchText.length === 0) {
      setLocationSuggestions([]);
    }
  }, [whereFilter.searchText]);

  const handleSearchActive = () => {
    setSearchActive(true);
  }
  
  useCurrentLocation(setWhereFilter, whereFilter.nearMe, null, false);
  
  return (
    <div className={'where-padding'}>
      <div className={searchActive ? 'where-search-active panel-size' : 'panel-size'}>
        <section className={searchActive ? 'where-detail active' : 'where-detail'}>
          <div className={searchActive ? 'where-input-padding active-search-padding' : 'where-input-padding'}>
              <div className={'where-align'} onClick={() => handleSearchActive()}>
                <form className={'where-form'}>
                  <label className={'where-label'}>
                    <div className={'where-icon-container'}>
                      <img className={'where-icon'} src={SearchIcon} alt={'Search Where Icon'} />
                    </div>
                    <input className={'where-input'} placeholder={'Search by suburb'} value={whereFilter.searchText} onChange={(e) => setWhereFilter({...whereFilter, coordinates: {...whereFilter.coordinates}, searchText: e.target.value})}/>
                  </label>
                  {searchActive && whereFilter.searchText.length > 0 && <div className={'clear-container'}>
                    <div className={'clear-button'} onClick={() => setWhereFilter({...whereFilter, coordinates: {...whereFilter.coordinates}, searchText: ''})}>
                      <span className={'clear-holder'}>
                        <img className={'clear-image'} src={ExitIcon} alt={'exit icon'} />
                      </span>
                    </div>
                  </div>}
                </form>
              </div>
          </div>
          {!searchActive && <div className={'where-state-picker'}>
            <div className={'state-search-text'}>{'Search by state'}</div>
            <div className={'state-picker-carousel'}>
              {STATES.map((state, i) => <CarouselItem itemLabel={state.state} itemImage={STATE_IMG[i]} key={`${state.state}-${i}`} handleStatePicked={handleStatePicked} chosenState={whereFilter.chosenState} stateId={i} handleActivePanel={handleActivePanel}/>)}
            </div>
          </div>}
          {searchActive && <div className={'autofill-container'}>
            <div className={'autofill-padding'}>
              {locationSuggestions.map(suggestion => <AutofillSearch suggestion={suggestion} whereFilter={whereFilter} setWhereFilter={setWhereFilter} setSearchActive={setSearchActive} handleActivePanel={handleActivePanel} key={`location-${suggestion.place_name}`} />)}
            </div>
          </div>}
        </section>
      </div>
    </div>
  );
};

// Handles the overall Where filter of the search component.
const MobileSearchWhere = ({ handleActivePanel, activePanel, searchActive, setSearchActive, whereFilter, setWhereFilter }) => {
  const handleStatePicked = (stateId) => {
    setWhereFilter({
      ...whereFilter,
      searchText: stateId === 0 ? '' : STATES[stateId].state,
      text: STATES[stateId].state,
      byState: true,
      nearMe: stateId === 0 ? true : false,
      coordinates: STATES[stateId].coordinates,
      chosenState: stateId
    })
  }

  const [locationSuggestions, setLocationSuggestions] = useState([]);
  useEffect(() => {
    if (whereFilter.searchText.length > 3) {
      getLocationSuggestions(whereFilter.searchText)
        .then((response) => {
          setLocationSuggestions(response.features);
        });
    } else if (whereFilter.searchText.length === 0) {
      setLocationSuggestions([]);
    }
  }, [whereFilter.searchText]);

  const handleSearchActive = () => {
    setSearchActive(true);
  }
  
  useCurrentLocation(setWhereFilter, whereFilter.nearMe, null, false);
  
  return (
    <div className={'where-padding'}>
      <div className={searchActive ? 'where-search-active panel-size' : 'panel-size'}>
        <button className={activePanel.isWhere ? 'panel-closed' : 'panel-button'} onClick={() => handleActivePanel('isWhere')}>
          <span className={'panel-title'}>{'Where'}</span>
          <span className={'panel-search-term'}>{whereFilter.text}</span>
        </button>
        <section className={activePanel.isWhere ? 'panel-open' : 'panel-closed'}>
          {!searchActive && <div className={'panel-open-title'}>{"Where do you want to work?"}</div>}
          <div className={searchActive ? 'where-input-padding active-search-padding' : 'where-input-padding'}>
              <div className={'where-align'} onClick={() => handleSearchActive()}>
                <form className={'where-form'}>
                  <label className={'where-label'}>
                    <div className={'where-icon-container'}>
                      <img className={'where-icon'} src={SearchIcon} alt={'Search Where Icon'} />
                    </div>
                    <input className={'where-input'} placeholder={'Search by suburb'} value={whereFilter.searchText} onChange={(e) => setWhereFilter({...whereFilter, coordinates: {...whereFilter.coordinates}, searchText: e.target.value})}/>
                  </label>
                  {searchActive && whereFilter.searchText.length > 0 && <div className={'clear-container'}>
                    <div className={'clear-button'} onClick={() => setWhereFilter({...whereFilter, coordinates: {...whereFilter.coordinates}, searchText: ''})}>
                      <span className={'clear-holder'}>
                        <img className={'clear-image'} src={ExitIcon} alt={'exit icon'} />
                      </span>
                    </div>
                  </div>}
                </form>
              </div>
          </div>
          {!searchActive && <div className={'where-state-picker'}>
            <div className={'state-picker-carousel'}>
              {STATES.map((state, i) => <CarouselItem itemLabel={state.state} itemImage={STATE_IMG[i]} key={`${state.state}-${i}`} handleStatePicked={handleStatePicked} chosenState={whereFilter.chosenState} stateId={i} handleActivePanel={handleActivePanel}/>)}
            </div>
          </div>}
          {searchActive && <div className={'autofill-container'}>
            <div className={'autofill-padding'}>
              {locationSuggestions.map(suggestion => <AutofillSearch suggestion={suggestion} whereFilter={whereFilter} setWhereFilter={setWhereFilter} setSearchActive={setSearchActive} handleActivePanel={handleActivePanel} key={`location-${suggestion.place_name}`} />)}
            </div>
          </div>}
        </section>
      </div>
    </div>
  );
};

export default MobileSearchWhere;