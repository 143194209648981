import React, { useEffect } from 'react'
import LocumNavigationSidebar from './LocumNavigationSidebar.jsx';
import './LocumPage.scss';

const LocumPage = ({ children, mode }) => {

  // Workaround for vh bug on mobile
  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, [])

  return (
    <article className={'locum-page'}>
      <LocumNavigationSidebar currentMode={mode} />
      {children}
    </article>
  );
};

export default LocumPage;