import React from 'react';
import { Route } from 'react-router-dom';

import HomePage from '../components/home/HomePage';
import PostPage from '../components/find/PostPage';
import HowItWorksPage from '../components/information/HowItWorksPage';
import TermsAndConditionsPage from '../components/information/TermsAndConditionsPage';
import PrivacyPolicyPage from '../components/information/PrivacyPolicyPage';
import CommunityGuidelinesPage from '../components/information/CommunityGuidelinesPage';
import FirstTimeLocum from '../components/information/blogs/FirstTimeLocum';
import WhatIsALocum from '../components/information/blogs/WhatIsALocum';
import AdditionalProviderNumbersGuide from '../components/information/blogs/AdditionalProviderNumbersGuide';
import FAQsPage from '../components/information/FAQsPage';
import AboutPage from '../components/information/AboutPage';
import HelpPage from '../components/information/HelpPage';
import ContactPage from '../components/information/ContactPage';
import StaffingAssistantPage from '../components/information/StaffingAssistantPage';
import LocumlyPage from '../wrappers/LocumlyPage';

function getHomeRoutes() {
  return (
    <Route path={'/'} element={<LocumlyPage />}>
      <Route index={true} element={<HomePage />} />
      <Route path={'index.html'} element={<HomePage />} />
      <Route path={'staffing-assistant'} element={<StaffingAssistantPage />} />
      <Route path={'post'} element={<PostPage />} />
      <Route path={'how-it-works'} element={<HowItWorksPage />} />
      <Route path={'terms-and-conditions'} element={<TermsAndConditionsPage />} />
      <Route path={'privacy-policy'} element={<PrivacyPolicyPage />} />
      <Route path={'community-guidelines'} element={<CommunityGuidelinesPage />} />
      <Route path={'faqs'} element={<FAQsPage />} />
      <Route path={'about'} element={<AboutPage />} />
      <Route path={'tutorials'} element={<HelpPage />} />
      <Route path={'contact'} element={<ContactPage />} />
      <Route path={'tutorials/additional-provider-numbers'} element={<AdditionalProviderNumbersGuide />} />
      <Route path={'tutorials/what-is-a-locum'} element={<WhatIsALocum />} />
      <Route path={'tutorials/setting-up-as-a-locum'} element={<FirstTimeLocum />} />
    </Route>
  );
}

export default getHomeRoutes;