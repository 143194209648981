import React from 'react';
import { ASSET_CDN_URL } from '../Constants';
import { NavLink, useLocation} from 'react-router-dom';
import './FooterSection.scss';

const WhiteLogo = `${ASSET_CDN_URL}/logo/white.png`;
const WhiteInstagramIcon = `${ASSET_CDN_URL}/social-sharing/instagram-icon.png`;
const WhiteFacebookIcon = `${ASSET_CDN_URL}/social-sharing/facebook-icon.png`;
const WhiteTwitterIcon = `${ASSET_CDN_URL}/social-sharing/twitter-icon.png`;
const WhiteLinkedInIcon = `${ASSET_CDN_URL}/social-sharing/linkedin-icon.png`;
const WhiteYouTubeIcon = `${ASSET_CDN_URL}/social-sharing/youtube-icon.png`;

/** Component for Locumly footer section. */
const FooterSection = () => {
  const { pathname } = useLocation();
  const hideFooterPaths = ['/find/shift', '/locum/schedule', '/user/messages', '/user/notifications', '/locum/profile/edit', '/locum/account/manage', '/locum/cart', '/find', '/business/account/manage', '/locum/shifts', '/locum/payments/manage'];

  return (
    <section className={hideFooterPaths.includes(pathname) ? 'footer-background hide-footer' : 'footer-background'}>
      <img className={'footer-logo'} src={WhiteLogo} alt={'White Locumly Logo'} />
      <section className={'footer-head'}>
        <section className={'footer-navigation'}>
          <section className={'footer-group'}>
            <span className={'footer-navigation-list-title'}>{'Resources'}</span>
            <ul className={'footer-navigation-list'}>
              <li className={'footer-nav'}>
                <NavLink className={'footer-nav-link'} exact={'true'} to={'/faqs'}>
                  {'FAQs'}
                </NavLink>
              </li>
              <li className={'footer-nav'}>
                <NavLink className={'footer-nav-link'} exact={'true'} to={'/community-guidelines'}>
                  {'Community Guidelines'}
                </NavLink>
              </li>
              <li className={'footer-nav'}>
                <NavLink className={'footer-nav-link'} exact={'true'} to={'/how-it-works'}>
                  {'Key Features'}
                </NavLink>
              </li>
              <li className={'footer-nav'}>
                <NavLink className={'footer-nav-link'} exact={'true'} to={'/tutorials'}>
                  {'Tutorials'}
                </NavLink>
              </li>
              <li className={'footer-nav'}>
                <NavLink className={'footer-nav-link'} exact={'true'} to={'/staffing-assistant'}>
                  {'(New!) Staffing Assistant'}
                </NavLink>
              </li>
            </ul>
          </section>
          <section className={'footer-group'}>
            <span className={'footer-navigation-list-title'}>{'Quick Access'}</span>
            <ul className={'footer-navigation-list'}>
              <li className={'footer-nav'}>
                <NavLink className={'footer-nav-link'} exact={'true'} to={'/about'}>
                  {'About Us'}
                </NavLink>
              </li>
              <li className={'footer-nav'}>
                <NavLink className={'footer-nav-link'} exact={'true'} to={'/login'}>
                  {'Login Access'}
                </NavLink>
              </li>
              <li className={'footer-nav'}>
                <NavLink className={'footer-nav-link'} exact={'true'} to={'/contact'}>
                  {'Contact Us'}
                </NavLink>
              </li>
              <li className={'footer-nav'}>
                <NavLink className={'footer-nav-link'} exact={'true'} to={'/privacy-policy'}>
                  {'Privacy Policy'}
                </NavLink>
              </li>
              <li className={'footer-nav'}>
                <NavLink className={'footer-nav-link'} exact={'true'} to={'/terms-and-conditions'}>
                  {'Terms And Conditions'}
                </NavLink>
              </li>
            </ul>
          </section>
        </section>
      </section>
      <hr className={'divider'} />
      <section className={'social-navigation'}>
        <a
            className={'social-link very-light'}
            href={'https://www.instagram.com/locumly/'}
            target={'_blank'}
            rel={'noreferrer noopener'}
        >
          <img src={WhiteInstagramIcon} className={'social-icon'} alt={'Instagram Icon'}/>
        </a>
        <a
            className={'social-link very-light'}
            href={'https://www.linkedin.com/company/locumly/'}
            target={'_blank'}
            rel={'noreferrer noopener'}
        >
          <img src={WhiteLinkedInIcon} className={'social-icon'} alt={'LinkedIn Icon'} />
        </a>
        <a
            className={'social-link very-light'}
            href={'https://www.facebook.com/Locumly'}
            target={'_blank'}
            rel={'noreferrer noopener'}
        >
          <img src={WhiteFacebookIcon} className={'social-icon'} alt={'Facebook Icon'} />
        </a>
        <a
            className={'social-link very-light'}
            href={'https://twitter.com/locumly'}
            target={'_blank'}
            rel={'noreferrer noopener'}
        >
          <img src={WhiteTwitterIcon} className={'social-icon'} alt={'Twitter Icon'} />
        </a>
        <a
            className={'social-link very-light'}
            href={'https://www.youtube.com/channel/UC5MEDNZUYqHkDsmF2MR-nfg'}
            target={'_blank'}
            rel={'noreferrer noopener'}
        >
          <img src={WhiteYouTubeIcon} className={'social-icon'} alt={'YouTube Icon'} />
        </a>
      </section>
      <section className={'trademark'}>{'©2022 Locum.ly'}</section>
    </section>
  );
};

export default FooterSection;
