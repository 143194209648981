import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ASSET_CDN_URL, DEFAULT_PERSON_AVATAR } from '../Constants';
import useAuth from '../hooks/AuthHook.jsx';
import { postAbnAndAhpra } from '../Api.js';
import './LocumNavigationSidebar.scss';

const EditIcon = `${ASSET_CDN_URL}/icons/edit-pen-icon.svg`;

export const LOCUM_NAVIGATION_BUTTONS = {
  ALL: { buttonText: 'Schedule', path: '/locum/shifts', mode: 'ALL'},
  PROFILE: { buttonText: 'Profile', path: '/locum/profile/edit', mode: 'PROFILE' },
  PAYMENTS: { buttonText: 'Payments', path: '/locum/payments/manage', mode: 'PAYMENTS' },
  ACCOUNT: { buttonText: 'Account', path: '/locum/account/manage', mode: 'ACCOUNT' },
  LOGOUT: { buttonText: 'Logout', path: '/', mode: 'LOGOUT'}
}

export const LocumNavigationSidebar = ({ currentMode }) => {
  const { profile, logout, setProfile } = useAuth();

  const { firstName, lastName, avatar, address } = profile;
  const { suburb, state } = address;
  const navigate = useNavigate();

  const navigationList = Object.keys(LOCUM_NAVIGATION_BUTTONS).map(mode => {
    const nav = LOCUM_NAVIGATION_BUTTONS[mode];
    const { buttonText, path } = nav;
    const buttonClick = () => navigate(path);
    const active = mode === currentMode;
    const activeClass = active ? 'active' : '';
    const logoutClick = () => {
      navigate('/', { replace: true });
      logout();
    };

    if (mode === 'PAYMENTS') {
      return (
        <>
          <li className={'locum-navigation-item'} key={mode}>
            <button className={`locum-navigation very-light ${activeClass}`} onClick={buttonClick} disabled={active}>
              {buttonText}
            </button>
          </li>
          <div className={'divider'}></div>
        </>
      )
    } else if (mode === 'LOGOUT') {
      return (
        <li className={'locum-navigation-item'} key={mode}>
          <button className={`locum-navigation very-light ${activeClass}`} onClick={logoutClick} disabled={active}>
            {buttonText}
          </button>
        </li>
      )
    } else {
      return (
        <li className={'locum-navigation-item'} key={mode}>
          <button className={`locum-navigation very-light ${activeClass}`} onClick={buttonClick} disabled={active}>
            {buttonText}
          </button>
        </li>
      )
    }
  });

  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true);
  const initialAccountDetails = { 
    abn: profile.abn,
    ahpra: profile.ahpra
  };
  const [accountDetails, setAccountDetails] = useState(initialAccountDetails);
  const { abn, ahpra } = accountDetails;
  const changeABN = (e) => {
    setAccountDetails({ ...accountDetails, abn: e.target.value });
  }
  const changeAHPRA = (e) => {
    setAccountDetails({ ...accountDetails, ahpra: e.target.value });
  }
  const saveClick = () => {
    setLoading(true);
    postAbnAndAhpra(abn, ahpra)
      .then(() => {
        setProfile({ ...profile, abn, ahpra});
        setDisabled(true);
        setLoading(false);
      })
      .catch(e => {
        console.error(e);
        setLoading(false);
      })
  }
  const profileAvatar = avatar ? avatar : DEFAULT_PERSON_AVATAR;
  return (
    <aside className={'locum-sidebar'}>
      <section className={'locum-image-and-name'}>
        <section className={'locum-image-container'}>
          <img className={'locum-image'} src={profileAvatar} alt={'Profile Avatar'} />
        </section>
        <h2 className={'locum-sidebar-name'}>
          {`${firstName} ${lastName}`}
        </h2>
        <div className={'locum-sidebar-address'}>{`${suburb}, ${state}`}</div>
        <div className={'edit-abn-container'}>
          <div className={'locum-sidebar-abn-ahpra'}>
            <div className={'abn-container'}>
              <span className={'abn-text'}>{`ABN: `}</span>
              <input
                type={'text'}
                className={`edit-text ${disabled ? 'disabled' : ''}`}
                value={abn}
                placeholder={'Missing ABN'}
                onChange={(e) => changeABN(e)}
                disabled={disabled}
              />
            </div>
            <div className={"ahpra-container"}>
              <span className={'abn-text'}>{`AHPRA: `}</span>
              <input
                type={'text'}
                className={`edit-text ${disabled ? 'disabled' : ''}`}
                value={ahpra}
                placeholder={'Missing AHPRA'}
                onChange={(e) => changeAHPRA(e)}
                disabled={disabled}
              />
            </div>
          </div>
          <div className={'edit-abn'}>
            {disabled
            ?
            <div className={'edit-button'} onClick={() => setDisabled(false)}>
              <img src={EditIcon} alt={'edit'} className={'edit-icon'}/>
            </div>
            :
            <>
              <div className={`save-button ${loading ? 'loading' : ''}`} onClick={() => saveClick()}>
                {'save'}
              </div>
              <div className={'cancel-button'} onClick={() => setDisabled(true)}>
                {'cancel'}
              </div>
            </>
            }
          </div>
        </div>
      </section>
      <ul className={'locum-navigation-list'}>
        {navigationList}
      </ul>
    </aside>
  );
};

export default LocumNavigationSidebar;
