import './ReviewStoreModal.scss';

import PropTypes from 'prop-types';
import React from 'react';

import ReviewStoreView from './ReviewStoreView';
import StoreProfileModalWrapper from '../shared/StoreProfileModalWrapper';

const propTypes = {
  /* Sets the modal open. */
  setOpen: PropTypes.func.isRequired,
  
  /* Store id. */
  storeId: PropTypes.string.isRequired,

  /* Store name. */
  storeName: PropTypes.string.isRequired,
}

function ReviewStoreModal({ setOpen, storeId, storeName }) {
  const closeModal = () => setOpen(false);
  return (
    <StoreProfileModalWrapper
      closeModal={closeModal}
      additionalClassNames={['review']}
      title={'Anonymously review this store'}
    >
      <ReviewStoreView
        closeModal={closeModal}
        storeId={storeId}
        storeName={storeName}
      />
    </StoreProfileModalWrapper> 
  )
}

ReviewStoreModal.displayName = 'ReviewStoreModal';
ReviewStoreModal.propTypes = propTypes;
export default ReviewStoreModal;