import './ValidateIdentitySection.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import useStoreProfile from '../../../../../hooks/StoreProfileContext';

import { LoadingButton } from '../../../../../common/OnboardingPage';
import ReviewStoreInput from '../shared/ReviewStoreInput';
import GoToButton from '../shared/GoToButton';
import ProgressBar from '../shared/ProgressBar';

function ValidateIdentitySection({ back, validation, setValidation, submit }) {
  const [loading, setLoading] = useState(false);
  const onChange = (fieldName) => ({ target }) =>
    setValidation({ ...validation, [fieldName]: target.value });
  const { email, fullName } = validation;
  const disabled = email.length === 0 || fullName.length === 0;
  const { storeProfile } = useStoreProfile();
  const { theme, storeUrl } = storeProfile;
  const { primaryHexColor } = theme;
  const plausibleLink = `plausible-event-link=${storeUrl}`;
  const plausibleEventClass = `plausible-event-name=Submit+Review+Button+Click ${plausibleLink}`;
  return (
    <section className={'validate-identity-section'}>
      <section className={'inputs'}>
        <h4 className={'title'}>{'Enter details to submit your review'}</h4>
        <h5 className={'subtitle'}>
          {'(for validation purposes, and won\'t be shared with the store)'}
        </h5>
        <ReviewStoreInput htmlFor={'email'} label={'Email'}>
          <input
            className={'review-store-input'}
            type={'text'}
            value={email}
            onChange={onChange('email')}
          />
        </ReviewStoreInput>
        <ReviewStoreInput htmlFor={'full-name'} label={'Full name'}>
          <input
            className={'review-store-input'}
            type={'text'}
            value={fullName}
            onChange={onChange('fullName')}
          />
        </ReviewStoreInput>
        <section className={'submit-button-container'}>
          <LoadingButton
            classNames={['submit-button', 'very-dark', plausibleEventClass]}
            disabled={disabled}
            text={'Submit'}
            onClick={() => submit(setLoading)}
            loading={loading}
            backgroundColor={primaryHexColor}
            borderColor={primaryHexColor}
          />
        </section>
        <section className={'go-to-buttons'}>
          <GoToButton goTo={back} disabled={false} reversed={true} />
        </section>
      </section>
      <ProgressBar stepNum={'three'} />
    </section>
  );
}

export default ValidateIdentitySection;