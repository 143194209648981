import React, { useState, useRef, useEffect } from 'react';
import { ASSET_CDN_URL, BUSINESS_DEFAULT_ROUTE, DEFAULT_PERSON_AVATAR } from '../Constants';
import { useNavigate } from 'react-router-dom';
import { useOutsideClick } from '../hooks/CommonHooks';
import { LoadingButton } from '../common/OnboardingPage';
import useAuth from '../hooks/AuthHook';
import { EMAIL_REGEX } from '../Constants';
import ReactPhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/style.css';
import './BusinessManageAccountPage.scss';

import { getProfile, postUpdatePassword, postConfirmPassword, postAccountDetails, postEmailSettings, postSmsSettings } from '../Api';

const Logo = `${ASSET_CDN_URL}/logo/aqua.png`;
const ShowPasswordIcon = `${ASSET_CDN_URL}/icons/show-password-icon.svg`;
const HidePasswordIcon = `${ASSET_CDN_URL}/icons/hide-password-icon.svg`;
const SaveIcon = `${ASSET_CDN_URL}/icons/save-tick-icon.svg`;
const DeleteIcon = `${ASSET_CDN_URL}/icons/delete-icon.svg`;

const ChangePasswordConfirmationModal = ({ close, confirm }) => {
  const { profile } = useAuth();
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [passwordChanged, setPasswordChanged] = useState(false);

  const [curInputType, setCurInputType] = useState('password');
  const [inputType, setInputType] = useState('password');
  const [confirmInputType, setConfirmInputType] = useState('password');
  const handleShowPassword = (passwordType, inputType) => {
    const newType = inputType === 'password' ? 'text' : 'password';
    if (passwordType === 'current') setCurInputType(newType);
    if (passwordType === 'new') setInputType(newType);
    if (passwordType === 'confirm') setConfirmInputType(newType);
  }

  const onCurrentPassword = (e) => {
    setCurrentPassword(e.target.value);
    setErrorText('');
  };
  const onChangePassword = (e) => {
    setPassword(e.target.value);
    setErrorText('');
  };
  const onChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    setErrorText('');
  }
  const changePasswordRef = useRef(null);
  const modalRef = useRef(null);
  useOutsideClick(modalRef, () => close());
  const onConfirm = () => {
    setLoading(true);
    postConfirmPassword(profile.emailAddress, currentPassword, profile.role)
      .then(({ success }) => {
        setLoading(false);
        if (success) {
          if (password.length < 6) {
            setErrorText('Password length must be greater than 6 characters');
          }
          else if (password !== confirmPassword) {
            setErrorText('Passwords do not match');
          }
          else {
            setLoading(true);
            postUpdatePassword(password, false)
              .then(() => {
                setLoading(false);
                setPasswordChanged(true);
              })
              .catch(() => {
                setLoading(false);
                setErrorText('An unknown error occurred. Please try again later!');
              });
          }
        } else {
          setErrorText('You have entered the wrong current password.');
        }
      }
    ).catch(() => {
      setLoading(false);
      setErrorText('An unknown error occurred. Please try again later!');
    })
  }
  useEffect(() => {
    if (changePasswordRef.current) {
      const changePasswordListener = (e) => e.key === 'Enter' && changePasswordRef.current.click();
      window.addEventListener('keypress', changePasswordListener);
      return () => window.removeEventListener('keypress', changePasswordListener);
    }
  }, [changePasswordRef]);
  return (
    <section className={`confirmation-modal-container change-password`}>
      <section className={'confirmation-modal'} ref={modalRef}>
        <section className={'confirmation-modal-content'}>
          <h3 className={'confirmation-title'}>{'Change password'}</h3>
          {!passwordChanged ? 
          <>
            <span className={'error-text'}>{errorText}</span>
            <section className={'password'}>
              <input placeholder={'Enter current password'} type={curInputType} className={'password-input'} value={currentPassword} onChange={onCurrentPassword} />
              <img className={'show-pass-icon'} src={curInputType === 'password' ? ShowPasswordIcon : HidePasswordIcon} alt={'show-password'} onClick={() => handleShowPassword('current', curInputType)} />
            </section>
            <section className={'password'}>
              <input placeholder={'Enter new password'} type={inputType} className={'password-input'} value={password} onChange={onChangePassword}/>
              <img className={'show-pass-icon'} src={inputType === 'password' ? ShowPasswordIcon : HidePasswordIcon} alt={'show-password'} onClick={() => handleShowPassword('new', inputType)} />
            </section>
            <section className={'password'}>
              <input placeholder={'Re-enter new password'} type={confirmInputType} className={'password-input'} value={confirmPassword} onChange={onChangeConfirmPassword} />
              <img className={'show-pass-icon'} src={confirmInputType === 'password' ? ShowPasswordIcon : HidePasswordIcon} alt={'show-password'} onClick={() => handleShowPassword('confirm', confirmInputType)} />
            </section>
            <section className={'buttons'}>
              <LoadingButton loading={loading} classNames={['confirm', 'very-dark']} onClick={onConfirm} text={'Change password'} buttonRef={changePasswordRef} />
            </section>
          </>
          :
          <>
            <div className={'pass-changed'}>
              <span className={'pass-changed-text'}>{'Your password has been changed!'}</span>
              <button className={'pass-changed-button'} onClick={() => confirm()} ref={changePasswordRef}>
                <img className={'pass-changed-icon'} src={SaveIcon} alt={'confirm'} />
              </button>
            </div>
          </>
          }
        </section>
      </section>
    </section>
  );
}

const ChangePasswordButton = () => {
  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);
  const confirm = () => setOpen(false);
  return (
    <>
      <button className={'change-password-button very-light'} onClick={() => setOpen(true)}>
        {'Change password...'}
      </button>
      { open && <ChangePasswordConfirmationModal close={close} confirm={confirm} />}
    </>
  )
}

export const EditAccountButton = ({ save, cancel, isAccount = false }) => {
  const [loading, setLoading] = useState(false);
  const cancelEdit = () => cancel();
  const saveEdit = () => save(setLoading);
  return (
    <section className={`edit-account-button ${isAccount && 'profile-save'}`}>
      <button className={`cancel-button very-dark ${loading ? 'disabled' : ''}`} onClick={cancelEdit} disabled={loading}>
        {'Cancel'}
      </button>
      <button className={`save-button very-dark ${loading ? 'disabled' : ''}`} onClick={saveEdit} disabled={loading}>
        {loading ? 'Loading...' : 'Done'}
      </button>
    </section>
  );
};

export const EditInput = ({ children, label, type }) => {
  return (
    <section className={`edit-option ${type}`}>
      <section className={'edit-label'}>{label}</section>
      {children}
    </section>
  )
}

export const PhoneEditInput = ({ label, value, onChange }) => {
  const input = <ReactPhoneInput 
                  inputExtraProps={{
                    required: true,
                    autoFocus: true
                  }}
                  regions={['oceania']}
                  country={'au'}
                  value={value}
                  onChange={onChange} 
                  inputClass={'phone-number'}
                />
  return (
    <EditInput label={label} type={'text'}>
      {input}
    </EditInput>
  )
}

export const TextEditInput = ({ label, value, onChange, placeholder }) => {
  const usePlaceholder = !value;
  return (
    <EditInput label={label} type={'text'}>
      <input
        type={'text'}
        className={`edit-text ${usePlaceholder ? 'placeholder' : ''}`}
        value={usePlaceholder ? placeholder : value}
        onChange={onChange}
      />
    </EditInput>
  )
}

const ButtonEditInput = ({ label, children }) => {
  return (
    <EditInput label={label} type={'button'}>
      {children}
    </EditInput>
  )
}

export const CheckboxInput = ({ label, subtitle, value, apiName, onChange, isGroup = false }) => {
  return (
    <section className={`edit-option checkbox`}>
      <input type={'checkbox'} className={'edit-checkbox'} value={apiName} checked={value} onChange={onChange} />
      <section className={'edit-label'}>
        <h3 className={`title-label ${isGroup ? 'group-weight' : ''}`}>{label}</h3>
        {!isGroup && <h4 className={'subtitle-label'}>{subtitle}</h4>}
      </section>
    </section>
  );
}

export const ProfileDetails = ({ children, title, save = null, cancel, additionalClassNames = [], isAccount = false, setOpen, isLocum = false }) => {
  return (
    <section className={`profile-details ${additionalClassNames.join(' ')}`}>
      <section className={`title-container ${isAccount ? 'is-account' : ''} ${isLocum && !isAccount ? 'locum-title' : ''}`}>
        {!isAccount && 
          <button className={'exit-button'}>
            <img className={'exit-icon'} src={DeleteIcon} alt={'delete'} onClick={() => setOpen(false)} />
          </button>}
        {!isAccount && <section className={'title'}>{title}</section>}
        {isAccount && <EditAccountButton save={save} cancel={cancel} isAccount={isAccount}/>}
      </section>
      {children}
    </section>
  );
}

export const AccountDetails = () => {
  const navigate = useNavigate();
  const { profile, setProfile } = useAuth();
  const initialAccountDetails = { 
    firstName: profile.firstName,
    lastName: profile.lastName,
    emailAddress: profile.emailAddress,
    phoneNumber: profile.phoneNumber
  };
  const { avatar } = profile;
  const locumAvatar = avatar ? avatar : DEFAULT_PERSON_AVATAR;
  const [accountDetails, setAccountDetails] = useState(initialAccountDetails);
  const [errorText, setErrorText] = useState(false);
  const { firstName, lastName, emailAddress, phoneNumber } = accountDetails;
  const saveAccountDetails = async (setLoading) => {
    if (!EMAIL_REGEX.test(emailAddress)) {
      setErrorText('Must be a valid email address format.');
      return;
    }

    setLoading(true);
    postAccountDetails(accountDetails)
      .then(({error}) => {
        if (error) {
          setErrorText(error);
          setLoading(false);
        } else {
          getProfile()
            .then(({ profile }) => {
              setProfile(profile);
              setLoading(false);
              setAccountDetails({ ...accountDetails, phoneNumber: phoneNumber });
              navigate(BUSINESS_DEFAULT_ROUTE);
            });
        }
      })
      .catch(({ error }) => {
        setErrorText(error)
        setLoading(false);
      });
  };
  const cancelAccountDetails = () => {
    setAccountDetails(initialAccountDetails);
    navigate(BUSINESS_DEFAULT_ROUTE);
  }
  const changeFirstName = (e) => {
    setErrorText('');
    setAccountDetails({ ...accountDetails, firstName: e.target.value });
  }
  const changeLastName = (e) => {
    setErrorText('');
    setAccountDetails({ ...accountDetails, lastName: e.target.value });
  }
  const changeEmailAddress = (e) => {
    setErrorText('');
    setAccountDetails({ ...accountDetails, emailAddress: e.target.value });
  }
  const changePhoneNumber = (phoneNumber) => {
    setErrorText('');
    setAccountDetails({ ...accountDetails, phoneNumber });
  }

  return (
    <ProfileDetails additionalClassNames={['account']} title={'Account details'} save={saveAccountDetails} cancel={cancelAccountDetails} isAccount={true}>
      <section className={'locum-avatar-comp'}>
        <section className={'locum-avatar-container'}>
          <img className={'locum-avatar'} src={locumAvatar} alt={'Locum Avatar'} />
        </section>
      </section>
      <div className={'account-detail-container'}>
        <span className={'error-text'}>{errorText}</span>
        <TextEditInput label={'First Name'} value={firstName} onChange={changeFirstName} />
        <TextEditInput label={'Last Name'} value={lastName} onChange={changeLastName} />
        <TextEditInput label={'Email'} value={emailAddress} onChange={changeEmailAddress} />
        <PhoneEditInput label={'Phone'} value={phoneNumber} onChange={changePhoneNumber} />
        <ButtonEditInput label={'Password'}>
          <ChangePasswordButton />
        </ButtonEditInput>
      </div>
    </ProfileDetails>
  )
};

export const SmsSettings = ({ smsOpen, setSMSOpen, smsSettings, setSmsSettings, initialSmsSettings }) => {
  const [errorText, setErrorText] = useState(null);
  const cancelSmsSettings = () => {
    setSMSOpen(false);
    setSmsSettings(initialSmsSettings);
  }
  const editSmsSettings = () => setSMSOpen(true);
  
  const onChangeShiftRequest = (e) => {
    setErrorText(null);
    setSmsSettings({ ...smsSettings, shiftRequest: e.target.checked });
  }
  const onChangeShiftConfirmation = (e) => {
    setErrorText(null);
    setSmsSettings({ ...smsSettings, shiftConfirmation: e.target.checked });
  }
  const onChangeShiftReminder= (e) => {
    setErrorText(null);
    setSmsSettings({ ...smsSettings, shiftReminder: e.target.checked });
  }
  const onChangeShiftCompletion = (e) => {
    setErrorText(null);
    setSmsSettings({ ...smsSettings, shiftCompletion: e.target.checked });
  }
  const onChangeShiftWithdrawal = (e) => {
    setErrorText(null);
    setSmsSettings({ ...smsSettings, shiftWithdrawal: e.target.checked });
  }
  const { shiftRequest = true, shiftConfirmation = true, shiftReminder = true, shiftCompletion = true, shiftWithdrawal = true } = smsSettings;
  return (
    <div className={`checkbox-modal ${smsOpen ? 'open' : ''}`}>
      <ProfileDetails title={'Mobile SMS Preferences'} edit={editSmsSettings} cancel={cancelSmsSettings} setOpen={setSMSOpen} isLocum={true}>
        <span className={'error-text'}>{errorText}</span>
        <div className={'checkbox-scroll'}>
          <CheckboxInput
            label={'Shift Confirmation'}
            value={shiftConfirmation}
            subtitle={'Receive an SMS for every shift confirmation from store'}
            onChange={onChangeShiftConfirmation}
          />
          <CheckboxInput
            label={'Shift Request'}
            value={shiftRequest}
            subtitle={'Receive an daily SMS whenever a shift of yours has been requested'}
            onChange={onChangeShiftRequest}
          />
          <CheckboxInput
            label={'Shift Reminder'}
            value={shiftReminder}
            subtitle={'Receive an SMS for 24 hours before a shift starts'}
            onChange={onChangeShiftReminder}
          />
          <CheckboxInput
            label={'Shift Completion'}
            value={shiftCompletion}
            subtitle={'Receive an SMS after shift is complete reminding to mark it as complete on Locumly'}
            onChange={onChangeShiftCompletion}
          />
          <CheckboxInput
            label={'Shift Withdrawal'}
            value={shiftWithdrawal}
            subtitle={'Receive an SMS after a Locum withdraws from a shift that was filled'}
            onChange={onChangeShiftWithdrawal}
          />
        </div>
      </ProfileDetails>
    </div>
  );
}

export const EmailSettings = ({ emailOpen, setEmailOpen, emailSettings, setEmailSettings, initialEmailSettings }) => {
  const [errorText, setErrorText] = useState(null);
  const cancelEmailSettings = () => {
    setEmailOpen(false);
    setEmailSettings(initialEmailSettings);
  }
  const editEmailSettings = () => setEmailOpen(true);
  const onChangeShiftRequest = (e) => setEmailSettings({ ...emailSettings, shiftRequest: e.target.checked });
  const onChangeShiftConfirmation = (e) => setEmailSettings({ ...emailSettings, shiftConfirmation: e.target.checked });
  const onChangeShiftReminder = (e) => setEmailSettings({ ...emailSettings, shiftReminder: e.target.checked });
  const onChangeShiftCompletion = (e) => setEmailSettings({ ...emailSettings, shiftCompletion: e.target.checked });
  const onChangeShiftWithdrawal = (e) => setEmailSettings({ ...emailSettings, shiftWithdrawal: e.target.checked });
  const onChangeNewMessages = (e) => setEmailSettings({ ...emailSettings, newMessages: e.target.checked });
  const onChangePaymentReminder = (e) => setEmailSettings({ ...emailSettings, paymentReminder: e.target.checked });

  useEffect(() => setErrorText(null), [emailSettings]);
  const { shiftRequest = true, shiftConfirmation = true, shiftReminder = true, shiftCompletion = true, shiftWithdrawal = true, newMessages = true, paymentReminder = true } = emailSettings;
  return (
    <div className={`checkbox-modal ${emailOpen ? 'open' : ''}`}>
      <ProfileDetails title={'Email Notification Preferences'} edit={editEmailSettings} cancel={cancelEmailSettings} setOpen={setEmailOpen} isLocum={true}>
        <span className={'error-text'}>{errorText}</span>
        <div className={'checkbox-scroll'}>
          <CheckboxInput
            label={'Shift Request'}
            value={shiftRequest}
            subtitle={'Receive an email for every shift request sent to stores'}
            onChange={onChangeShiftRequest}
          />
          <CheckboxInput
            label={'Shift Confirmation'}
            value={shiftConfirmation}
            subtitle={'Receive an email for every shift confirmation from store'}
            onChange={onChangeShiftConfirmation}
          />
          <CheckboxInput
            label={'Shift Reminder'}
            value={shiftReminder}
            subtitle={'Receive an email for 24 hours before a shift starts'}
            onChange={onChangeShiftReminder}
          />
          <CheckboxInput
            label={'Shift Completion'}
            value={shiftCompletion}
            subtitle={'Receive an email after your shift is complete reminding you to mark it as complete on Locumly'}
            onChange={onChangeShiftCompletion}
          />
          <CheckboxInput
            label={'Shift Withdrawal'}
            value={shiftWithdrawal}
            subtitle={'Receive an email after a Locum withdraws from a shift that was filled'}
            onChange={onChangeShiftWithdrawal}
          />
          <CheckboxInput
            label={'New Messages'}
            value={newMessages}
            subtitle={'Receive an email whenever a new message is sent to you from a store and is unread at least 2 hours'}
            onChange={onChangeNewMessages}
          />
          <CheckboxInput
            label={'Payment Reminder'}
            value={paymentReminder}
            subtitle={'Receive an email reminding you to pay an outstanding invoice if 7 days have passed since it was issued'}
            onChange={onChangePaymentReminder}
          />
        </div>
      </ProfileDetails>
    </div>
  );
}

const BusinessManageAccountPage = () => {
  const navigate = useNavigate();
  const { profile, setProfile } = useAuth();
  const [smsOpen, setSMSOpen] = useState(false);
  const initialSmsSettings = profile.smsSettings
    ? profile.smsSettings
    : {
      shiftRequest: true,
      shiftConfirmation: true,
      shiftReminder: true,
      shiftCompletion:true,
      shiftWithdrawal:true
    };
  const [smsSettings, setSmsSettings] = useState(initialSmsSettings);
  const handleSMS = (e) => {
    e.stopPropagation();
    setSMSOpen(true);
  }

  const [emailOpen, setEmailOpen] = useState(false);
  const initialEmailSettings = profile.emailSettings
    ? profile.emailSettings
    : {
      shiftRequest: true,
      shiftConfirmation: true,
      shiftReminder: true,
      shiftCompletion: true,
      shiftWithdrawal: true,
      newMessages: true,
      paymentReminder: true,
    };
  const [emailSettings, setEmailSettings] = useState(initialEmailSettings);
  const handleEmail = (e) => {
    e.stopPropagation();
    setEmailOpen(true);
  }

  const submitEmailSettings = (newEmailSettings) => {
    setEmailSettings(newEmailSettings);
    postEmailSettings(newEmailSettings)
      .then(() => {
        getProfile().then(({ profile }) => setProfile(profile));
      });
  };

  const submitSmsSettings = (newSmsSettings) => {
    setSmsSettings(newSmsSettings);
    postSmsSettings(newSmsSettings)
      .then(() => {
        getProfile().then(({ profile }) => setProfile(profile));
      });
  };

  // When on map view, scroll to top of page and prevent scrolling.
  useEffect(() => {
    emailOpen || smsOpen ? document.body.classList.add('prevent-scroll') : document.body.classList.remove('prevent-scroll');
    window.scrollTo({top: 0, left: 0});
  }, [emailOpen, smsOpen]);

  return (
    <article className={'business-manage-account-page business-acc-page'}>
      <div className={'manage-header'}>
        <div className={'logo-container'}>
          <img src={Logo} alt={'Logo'} onClick={() => navigate('/')} className={'locumly-logo'}/>
        </div>
      </div>
      <section className={'profile'}>
        <AccountDetails />
        <div className={'setting-button'} onClick={(e) => handleSMS(e)}>
          <button className={'open-checkbox-settings'}>{'Mobile SMS Preferences'}</button>
        </div>
        <div className={'setting-button'} onClick={(e) => handleEmail(e)}>
          <button className={'open-checkbox-settings'}>{'Email Notification Preferences'}</button>
        </div>
        <SmsSettings smsOpen={smsOpen} setSMSOpen={setSMSOpen} smsSettings={smsSettings} setSmsSettings={submitSmsSettings} initialSmsSettings={initialSmsSettings} />
        <EmailSettings emailOpen={emailOpen} setEmailOpen={setEmailOpen} emailSettings={emailSettings} setEmailSettings={submitEmailSettings} initialEmailSettings={initialEmailSettings} />
      </section>
      </article>
  );
}

export default BusinessManageAccountPage;
