import './AddReviewButton.scss';

import React, { useState } from 'react';

import useStoreProfile from '../../../../hooks/StoreProfileContext';

import ReviewStoreModal from './ReviewStoreModal';

function AddReviewButton({ storeId, storeName }) {
  const [open, setOpen] = useState(false);
  const { storeProfile } = useStoreProfile();
  const { storeUrl } = storeProfile;
  const openModal = () => setOpen(true);
  const plausibleLink = `plausible-event-link=${storeUrl}`;
  const plausibleEventClass = `plausible-event-name=Add+Review+Button+Click ${plausibleLink}`;
  return (
    <>
      <button
        className={`add-review-button very-dark ${plausibleEventClass}`}
        type={'button'}
        onClick={openModal}
      >
        {'Add Review'}
      </button>
      { open && <ReviewStoreModal setOpen={setOpen} storeId={storeId} storeName={storeName} /> }
    </>
  )
}

export default AddReviewButton;