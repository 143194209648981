import './SubscribeToStoreForm.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { JOB_OPTIONS_ORDER } from '../StoreProfileModalConstants';
import useStoreProfile from '../../../../hooks/StoreProfileContext';

import { LoadingButton } from '../../../../common/OnboardingPage';
import SubscribeToStoreCheckbox from './SubscribeToStoreCheckbox';
import SubscribeToStoreInput from './SubscribeToStoreInput';
import DropdownMultiselect from '../shared/dropdown-multiselect/DropdownMultiselect';
import { postStoreProfileSubscription } from '../../../StoreProfileApi';

const propTypes = {
  /* Closes the modal. */
  closeModal: PropTypes.func.isRequired,

  /* Sets if the store has been subscribed. */
  setSubscribed: PropTypes.func.isRequired,

  /* Store name. */
  storeName: PropTypes.string.isRequired,

  /* Store id. */
  storeId: PropTypes.string.isRequired,
}

function SubscribeToStoreForm({ closeModal, storeName, storeId, setSubscribed }) {
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({ fullName: '', email: '', sms: '', type: [] });
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [agreeToNotification, setAgreeToNotification] = useState(false);
  const onChange = (fieldName) => ({ target }) =>
    setDetails({ ...details, [fieldName]: target.value});
  const selectType = (key) => {
    const typeSet = new Set(type);
    if (typeSet.has(key)) {
      typeSet.delete(key);
    }
    else {
      typeSet.add(key);
    }
    setDetails({ ...details, type: [...typeSet] });
  }
  const subscribe = () => {
    const subscription = { ...details, storeId, storeName };
    setLoading(true);
    postStoreProfileSubscription(subscription)
      .then(() => {
        setLoading(false);
        setSubscribed(true);
        closeModal();
      });
  };
  const { fullName, email, sms, type } = details;
  const hasFilledInDetails = fullName || email || sms;
  const disabled = !agreeToTerms || !agreeToNotification || !hasFilledInDetails;
  const { storeProfile } = useStoreProfile();
  const { theme, storeUrl } = storeProfile;
  const { primaryHexColor } = theme;
  const plausibleLink = `plausible-event-link=${storeUrl}`;
  const plausibleEventClass = `plausible-event-name=Submit+Store+Subscription+Click ${plausibleLink}`;
  const disabledClass = disabled ? 'disabled' : 'very-dark';
  return (
    <section className={'subscribe-to-store'}>
      <SubscribeToStoreInput label={'Full name'} htmlFor={'full-name'}>
        <input
          id={'full-name'}
          type={'text'}
          value={fullName}
          onChange={onChange('fullName')}
          className={'text-input'}
        />
      </SubscribeToStoreInput>
      <SubscribeToStoreInput label={'Email'} htmlFor={'email'}>
        <input
          id={'email'}
          type={'text'}
          value={email}
          onChange={onChange('email')}
          className={'text-input'}
        />
      </SubscribeToStoreInput>
      <SubscribeToStoreInput label={'SMS'} htmlFor={'sms'}>
        <input
          id={'sms'}
          type={'text'}
          value={sms}
          onChange={onChange('sms')}
          className={'text-input'}
        />
      </SubscribeToStoreInput>
      <SubscribeToStoreInput label={'Type'} htmlFor={'type-dropdown'}>
        <DropdownMultiselect
          options={JOB_OPTIONS_ORDER}
          select={selectType}
          selectedOptions={type}
        />
      </SubscribeToStoreInput>
      <section className={'checkboxes'}>
        <SubscribeToStoreCheckbox
          checked={agreeToTerms}
          onChange={() => setAgreeToTerms(!agreeToTerms)}
          htmlFor={'terms'}
          label={'I agree to Locumly\'s Terms and Conditions and Privacy Notice.'}
        />
        <SubscribeToStoreCheckbox
          checked={agreeToNotification}
          onChange={() => setAgreeToNotification(!agreeToNotification)}
          htmlFor={'notification'}
          label={`I agree to my contact details being shared with ${storeName} who may contact me.`}
        />
      </section>
      <LoadingButton
        classNames={['subscribe-button', disabledClass, plausibleEventClass]}
        onClick={subscribe}
        type={'button'}
        disabled={disabled}
        loading={loading}
        text={'Subscribe'}
        backgroundColor={primaryHexColor}
        borderColor={primaryHexColor}
      />
    </section>
  )
}

SubscribeToStoreForm.displayName = 'SubscribeToStoreForm';
SubscribeToStoreForm.propTypes = propTypes;
export default SubscribeToStoreForm;