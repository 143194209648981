import './RateStoreSection.scss';

import PropTypes from 'prop-types';
import React from 'react';

import GoToButton from '../shared/GoToButton';
import ProgressBar from '../shared/ProgressBar';
import RatingInput from './RatingInput';
import RatingStars from './RatingStars';

function RateStoreSection({ back, next, rating, setRating }) {
  const { compensation, culture, diversity, staff, workLifeBalance } = rating;
  const setStars = (fieldName) => (numStars) =>
    setRating({ ...rating, [fieldName]: numStars });
  return (
    <section className={'rate-store-section'}>
      <section className={'inputs'}>
        <h4 className={'title'}>{'Ratings (optional)'}</h4>
        <RatingInput htmlFor={'compensation'} label={'Compensation & Benefits:'}>
          <RatingStars number={compensation} selectStars={setStars('compensation')} />
        </RatingInput>
        <RatingInput htmlFor={'culture'} label={'Culture & Values:'}>
          <RatingStars number={culture} selectStars={setStars('culture')} />
        </RatingInput>
        <RatingInput htmlFor={'diversity'} label={'Diversity & Inclusion:'}>
          <RatingStars number={diversity} selectStars={setStars('diversity')} />
        </RatingInput>
        <RatingInput htmlFor={'staff'} label={'Staff & Management:'}>
          <RatingStars number={staff} selectStars={setStars('staff')} />
        </RatingInput>
        <RatingInput htmlFor={'work-life-balance'} label={'Work/Life Balance:'}>
          <RatingStars number={workLifeBalance} selectStars={setStars('workLifeBalance')} />
        </RatingInput>
        <section className={'go-to-buttons'}>
          <GoToButton goTo={back} disabled={false} reversed={true} />
          <GoToButton goTo={next} disabled={false} reversed={false} />
        </section>
      </section>
      <ProgressBar stepNum={'two'} />
    </section>
  );
}

export default RateStoreSection;