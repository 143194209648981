import React from 'react';
import ShiftCard from './ShiftCard';
import FindStoreCard from './FindStoreCard';
import { computeDistance } from '../../helpers/ShiftUtils.js';
import './MapShifts.scss';

const MapShiftItem = ({ shift, state, location, requestedShiftList }) => {
  const distance = computeDistance(location.coordinates, shift.address.coordinates, 'kilometers')
  return (
    <div className={'map-shift-position'} id={shift._id} data-id={shift.storeId} >
      <ShiftCard 
        shift={shift} 
        distance={distance} 
        isListView={false}
        currentState={state}
        location={location}
        requestedShiftList={requestedShiftList}
        />
    </div>
  )
}

const MapStoreItem = ({store, setHighlightedStore, map, state, mapCenter, location, setViewStoreShifts, setSelectedStore, navigate, shiftList}) => {
  const distance = computeDistance(mapCenter !== null ? mapCenter : location.coordinates, store.address.coordinates, 'kilometers')
  const highlightIcon = (storeId) => {
    document.getElementById(storeId).scrollIntoView();
    setHighlightedStore(storeId);
    map.current.setLayoutProperty('stores', 'icon-image', ['match', ['get', 'storeId'], storeId, 'active-map-icon', 'map-icon'])
  }
  
  return (
    <div className={'map-shift-position'} id={store._id} data-id={store._id} onClick={() => highlightIcon(store._id)}>
      <FindStoreCard 
        store={store} 
        storeId={store._id} 
        currentState={state} 
        distance={distance} 
        shiftList={shiftList}
        setViewStoreShifts={setViewStoreShifts}
        setSelectedStore={setSelectedStore}
        isListView={false}
      />
    </div>
  )
}

const MapShifts = ({orderedMapShifts, orderedMapStores, setHighlightedStore, setHighlightedShift, map, jobView, state, mapCenter, location, setViewStoreShifts, setSelectedStore, viewStoreShifts, requestedShiftList, navigate, includeEmptyStores}) => {
  const cards = document.querySelectorAll('.map-shift-position');

  const filteredMapStores = orderedMapStores.filter((store => includeEmptyStores || store.shifts.length > 0));
  console.log(filteredMapStores);
  
  const mapStoreObj = filteredMapStores.reduce((sum, count) => ({...sum, [count._id]: count.shifts.length}), {})
  const mapStoreCoords = filteredMapStores.reduce((acc, store) => ({ ...acc, [store._id]: { coordinates: store.address.coordinates, isTopRated: store.isTopRated} }), {})
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        if (jobView) {
          setHighlightedShift(entry.target.dataset.id);
          if (mapStoreCoords[entry.target.dataset.id].isTopRated && map.current.getLayer('topRatedShifts')) {
            map.current.setLayoutProperty('topRatedShifts', 'icon-image', ['match', ['get', 'storeId'], entry.target.dataset.id, 'active-map-icon', 'top-rated-map-icon']);
            map.current.setLayoutProperty('shifts', 'icon-image', 'map-icon');
          } else if (map.current.getLayer('shifts')) {
            map.current.setLayoutProperty('shifts', 'icon-image', ['match', ['get', 'storeId'], entry.target.dataset.id, 'active-map-icon', 'map-icon']);
            map.current.setLayoutProperty('topRatedShifts', 'icon-image', 'top-rated-map-icon');
          }
          map.current.flyTo({
            center: [mapStoreCoords[entry.target.dataset.id].coordinates.longitude, mapStoreCoords[entry.target.dataset.id].coordinates.latitude],
            essential: true
          })
        } else {
          setHighlightedStore(entry.target.dataset.id);
          if (mapStoreObj[entry.target.dataset.id] === 0 && map.current.getLayer('emptyStores')) {
            map.current.setLayoutProperty('emptyStores', 'icon-image', ['match', ['get', 'storeId'], entry.target.dataset.id, 'active-map-icon', 'inactive-map-icon']);
            map.current.setLayoutProperty('stores', 'icon-image', 'map-icon');
            map.current.setLayoutProperty('topRatedStores', 'icon-image', 'top-rated-map-icon');
          } else if (mapStoreCoords[entry.target.dataset.id].isTopRated && map.current.getLayer('topRatedStores')) {
            map.current.setLayoutProperty('topRatedStores', 'icon-image', ['match', ['get', 'storeId'], entry.target.dataset.id, 'active-map-icon', 'top-rated-map-icon']);
            map.current.setLayoutProperty('emptyStores', 'icon-image', 'inactive-map-icon');
            map.current.setLayoutProperty('stores', 'icon-image', 'map-icon');
          } else if (map.current.getLayer('stores')) {
            map.current.setLayoutProperty('stores', 'icon-image', ['match', ['get', 'storeId'], entry.target.dataset.id, 'active-map-icon', 'map-icon']);
            map.current.setLayoutProperty('emptyStores', 'icon-image', 'inactive-map-icon');
            map.current.setLayoutProperty('topRatedStores', 'icon-image', 'top-rated-map-icon');
          }
          map.current.flyTo({
            center: [mapStoreCoords[entry.target.dataset.id].coordinates.longitude, mapStoreCoords[entry.target.dataset.id].coordinates.latitude],
            essential: true
          })
        }
      }
    })
  },
  {
    threshold: 1
  });

  cards.forEach(card => {
    observer.observe(card);
  })

  return (
    <section className={'map-shift-container'}>
      <div className={'map-shift-carousel'}>
        { jobView && orderedMapShifts.map(shift => 
          <MapShiftItem 
            shift={shift} 
            state={state}
            location={location}
            requestedShiftList={requestedShiftList}
            key={`mapshift-${shift._id}`}
          />
        )}
        {!jobView && filteredMapStores.map(store => 
          <MapStoreItem 
            store={store} 
            storeId={store._id}
            setHighlightedStore={setHighlightedStore} 
            shiftList={orderedMapShifts.filter(shift => shift.storeId === store._id)}
            map={map}
            state={state}
            mapCenter={mapCenter}
            location={location}
            setViewStoreShifts={setViewStoreShifts}
            setSelectedStore={setSelectedStore}
            navigate={navigate}
            key={`mapstore-${store._id}`}
          />
        )}
      </div>
    </section>
  )
}

export default MapShifts;