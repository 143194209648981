import './PermanentShiftList.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { PERMANENT_SHIFT_PROPS } from '../../StoreProfileProps';
import useStoreProfile from '../../../hooks/StoreProfileContext';

import PermanentShiftItem from './PermanentShiftItem';
import StoreShiftList from './StoreShiftList';


const propTypes = {
  /* Permanent shifts listed by the store. */
  shifts: PropTypes.arrayOf(PropTypes.shape(PERMANENT_SHIFT_PROPS)).isRequired,
}

function PermanentShiftList({ shifts }) {
  const { storeProfile } = useStoreProfile();
  const { theme, storeUrl } = storeProfile;
  const { primaryHexColor } = theme;
  const numShifts = shifts.length;
  const text = numShifts === 1 ? 'View permanent role' : 'View permanent roles';
  const buttonText = `${text} (${numShifts})`;
  const disabled = numShifts === 0;
  const permanentShifts = shifts.map((shift, index) => {
    const isEven = index % 2 === 0;
    const additionalClassNames = isEven ? ['even'] : ['odd'];
    return (
      <PermanentShiftItem
        shift={shift}
        isEven={isEven}
        key={`key-${JSON.stringify(shift)}`}
        additionalClassNames={additionalClassNames}
      />
    );
  });
  const plausibleLink = `plausible-event-link=${storeUrl}`;
  const plausibleEventClass = `plausible-event-name=Permanent+Roles+Click ${plausibleLink}`;
  return (
    <StoreShiftList
      buttonText={buttonText}
      disabled={disabled}
      additionalClassNames={['permanent', plausibleEventClass]}
      dropdownArrowColor={'#1A1A1A'}
      backgroundColor={primaryHexColor}
    >
      {permanentShifts}
    </StoreShiftList>
  )
}

PermanentShiftList.displayName = 'PermanentShiftList';
PermanentShiftList.propTypes = propTypes;
export default PermanentShiftList;