import './DropdownMultiselectOption.scss';

import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  /* Key used for selection. */
  optionKey: PropTypes.string.isRequired,

  /* Callback to select/deselect a key. */
  onChange: PropTypes.func.isRequired,

  /* True if option is selected. */
  selected: PropTypes.bool.isRequired,

  /* Display text. */
  displayText: PropTypes.string.isRequired,
}

function DropdownMultiselectOption({ optionKey, onChange, selected, displayText }) {
  return (
    <li className={'dropdown-multiselect-option'}>
      <input
        type={'checkbox'}
        onChange={onChange}
        checked={selected}
        className={'dropdown-checkbox'}
        id={`dropdown-checkbox-${optionKey}`}
      />
      <label htmlFor={`dropdown-checkbox-${optionKey}`} className={'dropdown-checkbox-label'}>
        {displayText}
      </label>
    </li>
  )
};

DropdownMultiselectOption.displayName = 'DropdownMultiselectOption';
DropdownMultiselectOption.propTypes = propTypes;
export default DropdownMultiselectOption;