import './StoreProfileModalWrapper.scss';

import React, { useRef } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  /* Callback to close modal. */
  closeModal: PropTypes.func.isRequired,

  /* Child component. */
  children: PropTypes.node.isRequired,

  /* Additional class names. */
  additionalClassNames: PropTypes.arrayOf(PropTypes.string).isRequired,
}

function StoreProfileModalWrapper({ closeModal, children, additionalClassNames, title }) {
  const modalRef = useRef(null);
  return (
    <section className={`store-profile-modal-wrapper ${additionalClassNames.join(' ')}`}>
      <section className={'store-profile-modal'} ref={modalRef}>
        <section className={'close-modal-button-container'}>
          <button className={'close-modal-button very-dark'} onClick={closeModal} type={'button'}>
            {'×'}
          </button>
        </section>
        <section className={'modal-content'}>
          <h3 className={'modal-title'}>{title}</h3>
          {children}
        </section>
      </section>
    </section>
  );
}

StoreProfileModalWrapper.displayName = 'StoreProfileModalWrapper';
StoreProfileModalWrapper.propTypes = propTypes;
export default StoreProfileModalWrapper;