import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { WebSearchButton } from '../common/navbar/NavSearch.jsx';
import { type, type as loop } from '../../helpers/TypingUtils.js';
import { WAITLIST_LINK } from '../login/LoginOptionsCard.jsx';
import './SearchSection.scss';
import './SearchBar.scss';
import { DualButtonSection } from '../information/HowItWorksPage';
import useMediaQuery from '../hooks/CommonHooks.jsx';
import { ASSET_CDN_URL } from '../Constants';

const LightGreenDot = `${ASSET_CDN_URL}/home/light-green-dot.svg`;
const GreenDot = `${ASSET_CDN_URL}/home/green-dot.svg`;
const WorkImageOne = `${ASSET_CDN_URL}/home/work-image-one.svg`;
const WorkImageTwo = `${ASSET_CDN_URL}/home/work-image-two.svg`;

const CAROUSEL_STEPS = [1000, 'Optometry.', 1000, 'Pharmacy.'];

export const TypingCarousel = ({ steps, showCursor = true }) => {
  const typingRef = useRef(null);
  useEffect(() => {
    if (typingRef.current) {
      type(typingRef.current, ...steps, loop);
    }
  }, [steps]);
  return (
    <section className={'typing'}>
      <p className={'typing-section'} ref={typingRef} />
      {showCursor && <span className={'blinking-cursor'}>|</span>}
    </section>
  );
};

const WorkCarousel = () => {
  const workImages = [WorkImageOne, WorkImageTwo];
  const [index, setIndex] = useState(0);
  const [startX, setStartX] = useState(0);
  const click = () => setIndex((index + 1) % workImages.length);

  useEffect(() => {
    const intervalId = setInterval(click, 8000);
    return () => clearInterval(intervalId);
  });

  const dragStart = (e) => {
    setStartX(e.clientX);
  };

  const dragEnd = (e) => {
    const diff = e.clientX - startX;
    if (diff >= 0) {
      setIndex((index + 1) % workImages.length);
    }
    else {
      if (index === 0) {
        setIndex(workImages.length - 1);
      }
      else {
        setIndex((index - 1) % workImages.length);
      }
    }
  }

  return (
    <section className={'work-carousel'}>
      <img
        className={'carousel-image'}
        onDragStart={dragStart}
        onDragEnd={dragEnd}
        onTouchStart={dragStart}
        onTouchEnd={dragEnd}
        src={workImages[index]}
        alt={''}
      />
      <section className={'triple-dots'}>
        <img className={'dot'} src={index % 3 === 0 ? GreenDot : LightGreenDot} alt={''} />
        <img className={'dot'} src={index % 3 === 1 ? GreenDot : LightGreenDot} alt={''} />
        <img className={'dot'} src={index % 3 === 2 ? GreenDot : LightGreenDot} alt={''} />
      </section>
    </section>
  );
}

const FlexibleWorkSection = () => {
  return (
    <section className={'flexible-work'}>
      <h3 className={'title'}>{'See how others work flexibly'}</h3>
      <WorkCarousel />
    </section>
  )
}

const WebSearchSection = () => {
  return (
    <section className={'search'}>
      <section className={'search-background'}>
        <section className={'header'}>
          <h1 className={'search-title typing-title'}>
            <span className={'title'}>
              {'Truly '}
              <span className={'italics'}>{'flexible'}</span>
              {' work for'}
              <TypingCarousel steps={[1000, 'Optometry.', 1000, 'Pharmacy.']} />
            </span>
            <a className={'sign-up-button very-dark'} href={WAITLIST_LINK} rel={'noopener noreferrer'} target={'_blank'}>
              {'Sign Up'}
            </a>
          </h1>
          <section className={'search-bar-container'}>
          < WebSearchButton />
          </section>
        </section>
        <FlexibleWorkSection />
      </section>
    </section>
  );
};

const MobileSearchSection = ({ setType }) => {
  const clickOptom = () => {
    setType('OPTOMETRY');
    const top = document.getElementById('shift-section').offsetTop;
    window.scrollTo(0, top - 100);
    setOptometrist(true);
  };
  const clickPharmacy = () => {
    setType('PHARMACY');
    const top = document.getElementById('shift-section').offsetTop;
    window.scrollTo(0, top - 100);
    setOptometrist(false);
  };
  const firstText = `I'm an Optometrist`;
  const secondText = `I'm a Pharmacist`;
  const [optometrist, setOptometrist] = useState(true);
  return (
    <section className={'search mobile'}>
    <section className={'search-background'}>
      <section className={'header'}>
        <h1 className={'search-title typing-title'}>
          <span className={'title'}>
            {'Truly '}
            <span className={'italics'}>{'flexible'}</span>
            {' work for'}
            <TypingCarousel steps={CAROUSEL_STEPS} />
          </span>
          <a className={'sign-up-button very-light'} href={WAITLIST_LINK} rel={'noopener noreferrer'} target={'_blank'}>
            {'Sign Up'}
          </a>
          <NavLink className={'sign-up-button login-button very-dark'} exact={'true'} to={'/login'}>
            {'Log In'}
          </NavLink>
        </h1>
      </section>
        <DualButtonSection
          posting={optometrist}
          firstClick={clickOptom}
          secondClick={clickPharmacy}
          firstText={firstText}
          secondText={secondText}
        />
    </section>
    <FlexibleWorkSection />
  </section>
  )
}

const SearchSection = ({ setType }) => {
  const isMobile = useMediaQuery('(max-width: 980px)');
  return isMobile ? <MobileSearchSection setType={setType} /> : <WebSearchSection />;
}

export default SearchSection;