import React from 'react';
import { SHIFT_TYPES, NETWORKS } from '../../Constants.js';
import { FilterCarousel, MultiFilterCarousel } from './NavSearch.jsx';
import './MobileSearchOther.scss';

// Overall Filter component for other filters.
const MobileSearchOther = ({ handleActivePanel, activePanel, otherFilter, setOtherFilter }) => {
  const shiftTypes = Object.keys(SHIFT_TYPES).reduce((acc, cur) => {
    if (cur !== 'ALL') {
      return {...acc, [cur]: SHIFT_TYPES[cur]};
    }
    return {...acc};
  }, {})

  const storeFilterOptions = {
    'Stores with shifts only': {
      name: 'Stores with shifts only'
    },
    'All Stores': {
      name: 'All Stores'
    }
  };
  return (
    <div className={'other-padding'}>
      <div className={'panel-size'}>
        <button className={activePanel.isOther ? 'panel-closed' : 'panel-button'} onClick={() => handleActivePanel('isOther')}>
          <span className={'panel-title'}>{'Other Filters'}</span>
          <span className={'panel-search-term'}>{otherFilter.shiftType === null ? 'Add Filters' : shiftTypes[otherFilter.shiftType].name}</span>
        </button>
        <section className={activePanel.isOther ? 'panel-open' : 'panel-closed'}>
          <div className={'panel-open-title'}>{'Other search filters'}</div>
          <div className={'other-container'}>
            <div>
              <FilterCarousel Question={'Which type of work?'} OptionList={Object.keys(shiftTypes)} OptionObject={shiftTypes} itemType={otherFilter} setItemType={setOtherFilter} />
              <MultiFilterCarousel Question={'Which network?'} OptionList={NETWORKS[otherFilter.shiftType]} OptionObject={NETWORKS} itemType={otherFilter} setItemType={setOtherFilter} itemKey={'networks'}/>
              <FilterCarousel Question={'Only show stores with shifts?'} OptionList={Object.keys(storeFilterOptions)} OptionObject={storeFilterOptions} itemType={otherFilter} setItemType={setOtherFilter} />
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export const WebSearchOther = ({ handleActivePanel, activePanel, otherFilter, setOtherFilter }) => {
  const shiftTypes = Object.keys(SHIFT_TYPES).reduce((acc, cur) => {
    if (cur !== 'ALL') {
      return {...acc, [cur]: SHIFT_TYPES[cur]};
    }
    return {...acc};
  }, {})

  const storeFilterOptions = {
    'Stores with shifts only': {
      name: 'Stores with shifts only'
    },
    'All Stores': {
      name: 'All Stores'
    }
  };
  return (
    <div className={'other-padding'}>
      <div className={'panel-size'}>
        <section className={activePanel.isOther ? 'panel-open' : 'panel-closed'}>
          <div className={'other-container'}>
            <div>
              <FilterCarousel Question={'Which type of work?'} OptionList={Object.keys(shiftTypes)} OptionObject={shiftTypes} itemType={otherFilter} setItemType={setOtherFilter} />
              <MultiFilterCarousel Question={'Which network?'} OptionList={NETWORKS[otherFilter.shiftType]} OptionObject={NETWORKS} itemType={otherFilter} setItemType={setOtherFilter} itemKey={'networks'}/>
              <FilterCarousel Question={'Only show stores with shifts?'} OptionList={Object.keys(storeFilterOptions)} OptionObject={storeFilterOptions} itemType={otherFilter} setItemType={setOtherFilter} />
              {/* <MobileOtherItem Title={'Minimum number of consecutive days?'}/> */}
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default MobileSearchOther;