import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/AuthHook.jsx';
import { ChangePasswordContainer } from '../common/OnboardingPage.jsx';
import LoadingPage from '../../LoadingPage.jsx';
import { USER_ROLES } from '../Constants.js';
import { postValidateResetToken } from '../Api.js';

const ChangePassword = ({ setView, emailAddress, token, role }) => {
  const confirm = (hasError) => {
    if (hasError) {
      setView('EXPIRED');
    }
    else {
      setView('CONFIRM');
    }
  }
  return (
    <section className={'reset-password-container'}>
      <div className={'form-container'}>
        <h1 className={'login-text'}>{`Reset your password`}</h1>
        <ChangePasswordContainer
          confirm={confirm}
          firstTime={false}
          text={'Continue'}
          emailAddress={emailAddress}
          token={token}
	  role={role}
        />
      </div>
    </section>
  );
}

const ExpiredLink = ({ role }) => {
  const url = `${window.location.origin}/login/${role === USER_ROLES.BUSINESS ? 'business' : 'locum'}/reset`;
  return (
    <section className={'reset-password-container'}>
      <h1 className={'login-text'}>{`Password link expired`}</h1>
      <p className={'reset-password-subtitle'}>
        {`Unforunately, this link has expired.`}
      </p>
      <section className={'confirm-buttons'}>
        <a className={'back-to-login'} href={url}>{'Try resetting your password again.'}</a>
      </section>
    </section>
  );
}

const SuccessConfirmation = ({ role }) => {
  const url = `${window.location.origin}/login/${role === USER_ROLES.BUSINESS ? 'business' : 'locum'}`;
  return (
    <section className={'reset-password-container success'}>
      <h1 className={'login-text success'}>{`Your password has been successfully changed!`}</h1>
      <section className={'confirm-buttons'}>
        <a className={'back-to-login'} href={url}>{'Back to login'}</a>
      </section>
  </section>
  )
}


const ChangePasswordCard = () => {
  const { authed } = useAuth();
  const urlParams = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  let defaultView;
  if (authed) {
    defaultView = 'REDIRECT';
  }
  else if (!urlParams.role) {
    defaultView = 'EXPIRED';
  }
  else {
    defaultView = 'LOADING';
  }
  const [view, setView] = useState(defaultView);
  useEffect(() => {
    postValidateResetToken(urlParams.emailAddress, urlParams.role, urlParams.token)
      .then(({ error }) => {
        if (error) {
          setView('EXPIRED');
        }
        else {
          setView('RESET');
        }
      })
      .catch(_ => setView('EXPIRED'));
  }, [urlParams.emailAddress, urlParams.token, urlParams.role])
  if (view === 'LOADING') {
    return (
      <section className={'reset-password-container'}>
        <LoadingPage />
      </section>
    );
  }
  else if (view === 'REDIRECT') {
    return <Navigate to={'/'} replace={'true'} />;
  }
  else if (view === 'RESET') {
    return <ChangePassword setView={setView} token={urlParams.token} emailAddress={urlParams.emailAddress} role={urlParams.role}  />
  }
  else if (view === 'EXPIRED') {
    return <ExpiredLink role={urlParams.role} />;
  }
  else {
    return <SuccessConfirmation role={urlParams.role} />;
  }
};

export default ChangePasswordCard;
