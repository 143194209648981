import React from 'react';
import { useNavigate } from 'react-router-dom';
import { NAVIGATION_OPTIONS } from '../BusinessConstants.js';
import './StoreSidebarNavigationButton.scss';

const StoreSidebarNavigationButton = ({ mode, state, currentMode }) => {
  const navigate = useNavigate();
  const { url, actionText } = NAVIGATION_OPTIONS[mode];
  const onClick = () => {
    navigate(url, { state: { ...state, shift: null, locum: null } });
  };
  const active = mode === currentMode;
  return (
    <li className={`store-sidebar-navigation-item`}>
      <button className={`store-sidebar-navigation very-light ${active ? 'active' : ''}`} onClick={onClick} disabled={active}>
        {actionText}
      </button>
    </li>
  );
};

export default StoreSidebarNavigationButton;