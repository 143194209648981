import React, { useState } from 'react';

import useStoreProfile from '../../../../hooks/StoreProfileContext';

import StoreProfileButton from '../../shared/StoreProfileButton';
import ShareStoreModal from './ShareStoreModal';

function ShareStoreButton() {
  const [open, setOpen] = useState(false);
  const shareStore = () => setOpen(true);
  const { storeProfile } = useStoreProfile();
  const { theme, storeUrl } = storeProfile;
  const { primaryHexColor } = theme;
  const plausibleLink = `plausible-event-link=${storeUrl}`;
  const plausibleEventClass = `plausible-event-name=Share+Store+Button+Click ${plausibleLink}`;
  return (
    <>
      <StoreProfileButton onClick={shareStore} additionalClassNames={[plausibleEventClass]}>
        <span className={'text'}>{'Share'}</span>
        <svg
          className={'share-icon'}
          width={'14'}
          height={'15'}
          viewBox={'0 0 14 15'} 
          fill={'none'}
          xmlns={'http://www.w3.org/2000/svg'}
        >
          <path
            d={'M12.3684 7.00441V13.3249H1V1.94807H7.31579M8.89474 1H13M13 1V5.10828M13 1L4.78947 9.53259'}
            stroke={primaryHexColor}
            stroke-width={'2'}
          />
        </svg>
      </StoreProfileButton>
      { open && <ShareStoreModal setOpen={setOpen} /> }
    </>
  );
}

ShareStoreButton.displayName = 'ShareStoreButton';
export default ShareStoreButton;