import * as turf from '@turf/turf';
import { SHIFT_STATUSES } from '../components/Constants';

export const computeDistance = (coordinates1, coordinates2, units) => {
  const long1 = coordinates1.longitude;
  const lat1 = coordinates1.latitude;
  const long2 = coordinates2.longitude;
  const lat2 = coordinates2.latitude;
  const line = turf.lineString([[long1, lat1], [long2, lat2]])
  return turf.length(line, { units });
};

export const orderShifts = (shifts, orderByType) => {
  switch (orderByType) {
    case 'distance-ascending':
      return shifts.sort((e1, e2) => e1.distance - e2.distance);
    case 'distance-descending':
      return shifts.sort((e1, e2) => e2.distance - e1.distance);
    case 'date-descending':
      return shifts.sort((e1, e2) => e2.startTime - e1.startTime);
    case 'date-ascending':
      return shifts.sort((e1, e2) => e1.startTime - e2.startTime);
    case 'rate-descending':
      return shifts.sort((e1, e2) => e2.rate - e1.rate);
    case 'rate-ascending':
    default:
      return shifts.sort((e1, e2) => e1.rate - e2.rate);
  }
};

const computeLocation = (location, address) => {
  if (location.byState) {
    return location.text === 'All' ? true : location.searchText === address.state;
  }
  return computeDistance(location.coordinates, address.coordinates, 'kilometers') <= location.radius;
}

export const filterShifts = (shifts, shiftType, fromDate, toDate, location, networkFilter, newlyAdded, showRegional, showTopRated) => {
  return shifts
    .filter(({ datePosted }) => !newlyAdded || ((Math.floor(Math.abs(new Date(datePosted) - new Date()) / (36e5 * 24)) < 2) === newlyAdded))
    .filter(({ isTopRated }) => !showTopRated || isTopRated === showTopRated)
    // .filter(({ preferences }) => !showRegional || (preferences && (preferences.hasOwnProperty('travel') ? preferences.travel : false && preferences.hasOwnProperty('accommodation') ? preferences.accommodation : false) === showRegional))
    .filter(({ isRegional }) => !showRegional || (isRegional === 'REGIONAL' && showRegional))
    .filter(({ status }) => status === SHIFT_STATUSES.UNFILLED.key)
    .filter(({ type }) => shiftType === type)
    .filter(({ network }) => networkFilter[network] || (network === '' && networkFilter['OTHER']))
    .filter(({ startTime }) => startTime <= toDate.getTime() && startTime >= fromDate.getTime())
    .filter(({ address }) => showRegional || computeLocation(location, address));
};

export const filterShiftsByMonth = (shifts, shiftType, searchMethod, selectedDates, location, networkFilter, newlyAdded, showRegional, showTopRated) => {
  shifts = shifts
  .filter(({ datePosted }) => !newlyAdded || ((Math.floor(Math.abs(new Date(datePosted) - new Date()) / (36e5 * 24)) < 2) === newlyAdded))
  .filter(({ isTopRated }) => !showTopRated || isTopRated === showTopRated)
  // .filter(({ preferences }) => !showRegional || (preferences && (preferences.hasOwnProperty('travel') ? preferences.travel : false && preferences.hasOwnProperty('accommodation') ? preferences.accommodation : false) === showRegional))
  .filter(({ isRegional }) => !showRegional || (isRegional === 'REGIONAL' && showRegional))
  .filter(({ status }) => status === SHIFT_STATUSES.UNFILLED.key)
  .filter(({ type }) => shiftType === type)
  .filter(({ network }) => networkFilter[network] || (network === '' && networkFilter['OTHER']))
  .filter(({ address }) => showRegional || computeLocation(location, address));
  const noSelection = Object.values(selectedDates).every(el => el === false);
  // If they selected Other, but didn't select a specific day/month, then by default assume they selected all.
  if (searchMethod === 'BYMONTH') {
    return shifts.filter(({ startTime }) => noSelection ? true : selectedDates[new Date(startTime).getMonth()])
  } else if (searchMethod === 'BYDAYOFWEEK') {
    return shifts.filter(({ startTime }) => noSelection ? true : selectedDates[new Date(startTime).getDay()])
  }
}

export const getDefaultDates = (state) => {
  if (state && state.whenFilter) {
    if (state.whenFilter.dates && state.whenFilter.useDates) {
      return state.whenFilter.dates;
    } else if (!state.whenFilter.useDates) {
      return state.whenFilter.other;
    }
  }
  const fromDate = new Date();
  const toDate = new Date();
  toDate.setDate(toDate.getDate() + 120);
  return { fromDate, toDate }
};