import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ASSET_CDN_URL } from '../../Constants';
import BackArrowButton from '../../common/BackArrowButton.jsx';
import './AdditionalProviderNumbersGuide.scss';

const GuideOne = `${ASSET_CDN_URL}/blogs/additional-provider-numbers/guide-one.png`;
const GuideTwoA = `${ASSET_CDN_URL}/blogs/additional-provider-numbers/guide-two-a.png`;
const GuideTwoB = `${ASSET_CDN_URL}/blogs/additional-provider-numbers/guide-two-b.png`;
const GuideTwoC = `${ASSET_CDN_URL}/blogs/additional-provider-numbers/guide-two-c.png`;
const GuideThreeA = `${ASSET_CDN_URL}/blogs/additional-provider-numbers/guide-three-a.png`;
const GuideThreeB = `${ASSET_CDN_URL}/blogs/additional-provider-numbers/guide-three-b.png`;
const GuideThreeC = `${ASSET_CDN_URL}/blogs/additional-provider-numbers/guide-three-c.png`;
const GuideThreeD = `${ASSET_CDN_URL}/blogs/additional-provider-numbers/guide-three-d.png`;
const AdditionalProviderNumbers = `${ASSET_CDN_URL}/blogs/additional-provider-numbers/additional-provider-numbers.png`;

const AdditionalProviderNumbersGuide = () => {
  const navigate = useNavigate();
  const onClick = () => navigate('/tutorials');
  return (
    <article className={'blog-page additional-provider-numbers'}>
      <BackArrowButton onClick={onClick} text={'Back to tutorials'} />
      <section className={'article'}>
        <img className={'image-banner'} src={AdditionalProviderNumbers} alt={`What's your number?`} />
        <h1 className={'title'}>{'How to apply for additional provider numbers via PRODA/HPOS'}</h1>
        <h2 className={'date'}>{`Updated on March 26, 2022`}</h2>
        <p className={'paragraph'}>
          {`You may need to apply for a new provider number if you are going to be working in a new practice location. The fastest (and easiest!) way to do this is via `}
          <span className={'bold'}>{`Health Professional Online Services (HPOS)`}</span>
          {` which you can access via logging into your `}
          <a href={'https://proda.humanservices.gov.au/prodalogin/pages/public/login.jsf'} className={'link'} target={'_blank'} rel={'noopener noreferrer'}>
            {`PRODA account`}
          </a>
          {`.`}
        </p>
        <p className={'paragraph'}>
          {`If you do not yet have a PRODA account, you can apply for one `}
          <a href={'https://proda.humanservices.gov.au/pia/pages/public/registration/account/createAccount.jsf'} className={'link'} target={'_blank'} rel={'noopener noreferrer'}>
            {`here`}
          </a>
          {`, then link HPOS from the “My Linked Services” page.`}
        </p>
        <p className={'paragraph'}>
          {`Once this is set up, it’s a quick and easy process to apply for additional provider numbers at any future locations that you work at.`}
        </p>
        <ul className={'tutorial-list'}>
          <li className={'tutorial'}>
            <span className={'guide'}>
              {`1. Log in to `}
              <a href={'https://proda.humanservices.gov.au/prodalogin/pages/public/login.jsf'} className={'link'} target={'_blank'} rel={'noopener noreferrer'}>{'PRODA'}</a>
              {' and go to HPOS'}
            </span>
            <img className={'tutorial-image'} src={GuideOne} alt={'Guide one'} />
          </li>
          <li className={'tutorial'}>
            <span className={'guide'}>
              {'2. Select My details > My provider numbers > Create a new provider location'}
            </span>
            <span className={'subguide'}>{`a. Click Select My details`}</span>
            <img className={'tutorial-image'} src={GuideTwoA} alt={'Guide two A'} />
            <span className={'subguide'}>{`b. Click My provider numbers`}</span>
            <img className={'tutorial-image'} src={GuideTwoB} alt={'Guide two B'} />
            <span className={'subguide'}>{`c. Click Create a new provider location`}</span>
            <img className={'tutorial-image'} src={GuideTwoC} alt={'Guide two C'} />
          </li>
          <li className={'tutorial'}>
            <span className={'guide'}>
              {'3. Input your organisation’s details (can be found on store profile page) through the following screens:'}
            </span>
            <span className={'subguide'}>{`a. Submit the provider's address details`}</span>
            <img className={'tutorial-image'} src={GuideThreeA} alt={'Guide three A'} />
            <span className={'subguide'}>{`b. Submit the provider's contact details`}</span>
            <img className={'tutorial-image'} src={GuideThreeB} alt={'Guide three B'} />
            <span className={'subguide'}>{`c. Submit the provider's business details`}</span>
            <img className={'tutorial-image'} src={GuideThreeC} alt={'Guide three C'} />
            <span className={'subguide'}>{`c. Submit the provider's banking details`}</span>
            <img className={'tutorial-image'} src={GuideThreeD} alt={'Guide three D'} />
            <p className={'paragraph'}>
              {`All the details above can be found by visiting the store profile page from the shift or downloading the store's locum onboarding document if it exists.`}
            </p>
            <p className={'paragraph'}>
              {`If you are missing any details or unsure, feel free to message your store and they can help you out!`}
            </p>
          </li>
        </ul>
        <p className={'paragraph end'}>
          {'Once submitted, you will be issued your new provider number immediately.'}
        </p>
      </section>
    </article>
  );
}

export default AdditionalProviderNumbersGuide;