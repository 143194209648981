import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/AuthHook';
import { USER_ROLES, EMAIL_REGEX, BUSINESS_DEFAULT_ROUTE } from '../Constants';
import { WAITLIST_LINK } from './LoginOptionsCard';
import './LoginCard.scss';

const LoginCard = ({ isBusiness }) => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const loginRef = useRef(null);
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [errorText, setErrorText] = useState('');
  const [loading, setLoading] = useState(false);
  const onChangeEmail = (e) => {
    if (e.target.value === '' || EMAIL_REGEX.test(e.target.value)) {
      setErrorText('');
      setEmailAddress(e.target.value);
    }
  };
  const onChangePassword = (e) => {
    setErrorText('');
    setPassword(e.target.value)
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const emailAddressParam = params.get('emailAddress');
    const passwordParam = params.get('password');
    if (emailAddressParam && passwordParam) {
      setEmailAddress(emailAddressParam);
      setPassword(passwordParam);
      login({ emailAddress: emailAddressParam, password: passwordParam, role: isBusiness ? USER_ROLES.BUSINESS : USER_ROLES.LOCUM })
        .then(() => {
          setLoading(false);
          navigate('/', { replace: true });
        })
        .catch(_ => {
          setLoading(false);
          setErrorText('Invalid email address or password');
        });
      }
  }, [isBusiness, login, navigate]);

  const loginCallback = () => {
    setLoading(true);
    login({ emailAddress, password, role: isBusiness ? USER_ROLES.BUSINESS : USER_ROLES.LOCUM })
      .then(() => {
        setLoading(false);
        navigate(BUSINESS_DEFAULT_ROUTE, { replace: true });
      })
      .catch(_ => {
        setLoading(false);
        setErrorText('Invalid email address or password');
      });
  }

  const onResetClick = () => {
    const url = isBusiness ? '/login/business/reset' : '/login/locum/reset';
    navigate(url);
  };

  const loginListener = (e) => e.key === 'Enter' && loginRef.current.click();
  useEffect(() => {
    window.addEventListener('keypress', loginListener);
    return () => window.removeEventListener('keypress', loginListener);
  }, []);
  return (
    <section className={'login-container'}>
      <div className={'form-container'}>
        <h1 className={'login-text'}>{isBusiness ? 'Sign in to post shifts' : 'Sign in to request jobs'}</h1>
        <div className={'input-container'}>
          <span className='error-text login-error-text'>{errorText}</span>
          <input
            className={'email-address-container'}
            type={'text'}
            placeholder={'Email'}
            value={emailAddress}
            onChange={onChangeEmail}
          />
          <input
            className={'password-container'}
            type={'password'}
            placeholder={'Password'}
            value={password}
            onChange={onChangePassword}
          />
          <section className={'confirm-buttons'}>
            <button className={`continue-button very-dark ${loading ? 'loading' : ''}`} onClick={loginCallback} ref={loginRef} disabled={loading}>
              { !loading ? 'Sign In': 'Loading...' }
            </button>
            <button className={'forgot-password'} onClick={onResetClick}>{'Having trouble logging in?'}</button>
          </section>
          <section className={'no-account'}>
            <a href={WAITLIST_LINK} className={'waitlist-button'} rel={'noopener noreferrer'} target={'_blank'}>{'Sign up for access'}</a>
          </section>
        </div>
      </div>
    </section>
  );
};

export default LoginCard;