import React, { useState } from 'react';
import SearchSection from './SearchSection.jsx';
import ShiftSection from './ShiftSection.jsx';
import HowItWorksSection from './HowItWorksSection.jsx';
import PartnersSection from './PartnersSection.jsx';

/** Component for Locumly Home. */
const HomePage = () => {
  const [type, setType] = useState(null);
  return (
    <article className={'home-page'}>
      <SearchSection setType={setType} />
      <ShiftSection shiftType={type} />
      <HowItWorksSection />
      <PartnersSection />
    </article>
  );
};

export default HomePage;