import './StoreProfilePage';

import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { getStoreProfile } from './StoreProfileApi';
import useStoreProfile from '../hooks/StoreProfileContext';

import LoadingPage from '../../LoadingPage';
import StoreProfile from './profile/StoreProfile';
import {
  DEFAULT_PRIMARY_HEX_COLOR,
  DEFAULT_SECONDARY_HEX_COLOR,
  DEFAULT_TERTIARY_HEX_COLOR
} from './StoreProfileConstants';

const STORE_PROFILE_STATES = {
  LOADING: 'LOADING',
  FOUND: 'FOUND',
  NOT_FOUND: 'NOT_FOUND',
};

const { LOADING, FOUND, NOT_FOUND } = STORE_PROFILE_STATES;

function StoreProfilePage() {
  const { storeName, fbclid } = useParams();
  const [viewState, setViewState] = useState(LOADING);
  const { storeProfile, setStoreProfile } = useStoreProfile();
  useEffect(() => {
    getStoreProfile(storeName)
      .then((response) => {
        if (response.success) {
          const theme = response.profile.theme;
          const primaryHexColor = theme.primaryHexColor
            ? theme.primaryHexColor : DEFAULT_PRIMARY_HEX_COLOR;
          const secondaryHexColor = theme.secondaryHexColor
            ? theme.secondaryHexColor : DEFAULT_SECONDARY_HEX_COLOR;
          const tertiaryHexColor = theme.tertiaryHexColor
            ? theme.tertiaryHexColor : DEFAULT_TERTIARY_HEX_COLOR;
          const newProfile = {
            ...response.profile,
            theme: { primaryHexColor, secondaryHexColor, tertiaryHexColor },
          }
          setStoreProfile(newProfile);
          setViewState(FOUND);
          console.log({ fbclid });
        }
        else {
          throw new Error('Store not found!');
        }
      })
      .catch(() => setViewState(NOT_FOUND));
  }, [storeName, setStoreProfile, fbclid]);
  if (viewState === LOADING || (storeProfile === null && viewState !== NOT_FOUND)) {
    return <LoadingPage />
  }
  else if (viewState === NOT_FOUND) {
    return <Navigate to={'/'} replace={'true'} />;
  }
  else {
    return <StoreProfile />;
  }
}

export default StoreProfilePage;
