import React from 'react';
import { Route } from 'react-router-dom';

import { RequireAuth } from '../components/hooks/AuthHook';

import AddShiftsPage from '../components/business/store/AddShiftsPage';
import UpcomingShiftsPage from '../components/business/store/UpcomingShiftsPage';
import ReviewApplicantsPage from '../components/business/store/ReviewApplicantsPage';
import ManagePreferencesPage from '../components/business/store/ManagePreferencesPage';
import CompletePaymentsPage from '../components/business/store/CompletePaymentsPage';
import EditStorePage from '../components/business/store/EditStorePage';
import LocumlyPage from '../wrappers/LocumlyPage';

function getStoreRoutes() {
  return (
    <Route path={'/store'} element={<LocumlyPage />}>
      <Route index={true} element={<RequireAuth><UpcomingShiftsPage /></RequireAuth>} />
      <Route path={'profile/edit'} element={<RequireAuth><EditStorePage /></RequireAuth>} />
      <Route path={'shifts/add'} element={<RequireAuth><AddShiftsPage /></RequireAuth>} />
      <Route path={'shifts/upcoming'} element={<RequireAuth><UpcomingShiftsPage /></RequireAuth>} />
      <Route path={'payments/complete'} element={<RequireAuth><CompletePaymentsPage /></RequireAuth>} />
      <Route path={'applicants/review'} element={<RequireAuth><ReviewApplicantsPage /></RequireAuth>} />
      <Route path={'preferences/manage'} element={<RequireAuth><ManagePreferencesPage /></RequireAuth>} />
      <Route path={'*'} element={<RequireAuth><UpcomingShiftsPage /></RequireAuth>} />
    </Route>
  );
}

export default getStoreRoutes;