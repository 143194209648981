import React, { useState } from 'react';
import StoreCard from './StoreCard.jsx';
import useAuth from '../../hooks/AuthHook.jsx';
import './StoresPage.scss';

const ADD_NEW_STORE_LINK = 'https://z2m4caj4dl7.typeform.com/to/xUlgc6U6';

const FilterSearchInput = ({ searchText, setSearchText }) => {
  return (
    <section className={'filter-search-container'}>
      <input
        type={'text'}
        className={'filter-search-input'}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        placeholder={'Enter any part of the store name you are looking for...'} 
      />
    </section>
  );
}

/** Component for Locumly Stores page. */
const StoresPage = () => {
  const { profile } = useAuth();
  const { firstName, stores } = profile;
  const [searchText, setSearchText] = useState('');
  const storeList = stores
    .filter(store => store.name.toLowerCase().match(searchText.toLowerCase()))
    .sort((store1, store2) => store1.name.localeCompare(store2.name))
    .map(store => <StoreCard store={store} key={`store-card-${store.name}`} />);
  return (
    <article className={'stores-page'}>
      <h1 className={'stores-introduction'}>{`Hi ${firstName}, how can we help?`}</h1>
      <p className={'stores-subtitle'}>{'You can access all your stores from here.'}</p>
      <FilterSearchInput searchText={searchText} setSearchText={setSearchText} />
      <ul className={'store-list'}>{storeList}</ul>
      <p className={'add-stores-subtitle'}>
        {`Have another store to add? Fill out a new store application by clicking on the button below.`}
      </p>
      <a href={ADD_NEW_STORE_LINK} className={'add-another-store-button very-dark'} target={'_blank'} rel={'noopener noreferrer'}>
        {'Add another store'}
      </a>
    </article>
  );
};

export default StoresPage;