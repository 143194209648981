import './StoreHiringBanner.scss';

import PropTypes from 'prop-types';
import React from 'react';

import useStoreProfile from '../../../hooks/StoreProfileContext';

import SubscribeToStoreButton from '../modals/subscribe/SubscribeToStoreButton';
import ShareStoreButton from '../modals/share/ShareStoreButton';

const propTypes = {
  /* Number of open roles available at the store. */
  numRolesAvailable: PropTypes.number.isRequired,

  /* Store Id. */
  storeId: PropTypes.string.isRequired,

  /* Store name. */
  storeName: PropTypes.string.isRequired,
}

function StoreHiringBanner({ numRolesAvailable, storeName, storeId }) {
  const { storeProfile } = useStoreProfile();
  const { theme, storeUrl } = storeProfile;
  const { primaryHexColor } = theme;
  const goToHiring = () => {
    const component = document.querySelector('.store-profile-section.join-team');
    if (component) {
      component.scrollIntoView();
      window.scrollTo(0, component.offsetTop); 
    }
  };
  const disabled = numRolesAvailable === 0;
  const rolesText = numRolesAvailable === 1 ? 'role available' : 'roles available';
  const buttonText = numRolesAvailable > 0 ? 'We are hiring!' : 'No open positions.'
  const disabledClass = disabled ? 'disabled' : 'very-dark';
  const plausibleLink = `plausible-event-link=${storeUrl}`;
  const plausibleEventClass = `plausible-event-name=Hiring+Button+Click ${plausibleLink}`;
  return (
    <section className={'store-hiring-banner-container'}>
      <section className={'store-hiring-banner'}>
        <button
          className={`store-hiring-button ${disabledClass} ${plausibleEventClass}`}
          onClick={goToHiring}
          type={'button'}
          disabled={disabled}
          style={{ backgroundColor: primaryHexColor, borderColor: primaryHexColor }}
        >
          <span className={'button-text'}>{buttonText}</span>
          <section className={'roles-available'}>
            <span className={'number'}>{numRolesAvailable}</span>
            <span className={'roles-text'}>{rolesText}</span>
          </section>
        </button>
        <section className={'buttons'}>
          <SubscribeToStoreButton storeName={storeName} storeId={storeId} />
          <ShareStoreButton />
        </section>
      </section>
    </section>
  )
}

StoreHiringBanner.displayName = 'StoreHiringBanner';
StoreHiringBanner.propTypes = propTypes;
export default StoreHiringBanner;