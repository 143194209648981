import React from 'react';
import { ASSET_CDN_URL } from '../Constants';
import './AboutPage.scss';

const FlexibleWorkImg = `${ASSET_CDN_URL}/marketing/flexible-working.svg`;
const TemporaryStaffingImg = `${ASSET_CDN_URL}/marketing/temporary-staffing.svg`;

const ABOUT_CARDS = {
  FLEXIBLE_WORK: {
    image: FlexibleWorkImg,
    altText: 'Flexible Work',
    title: 'Flexible Working',
    type: 'small',
    descriptor: `Locuming should be awesome. You get to work anywhere, anytime. But it’s not. Up to 30% of your time goes toward finding shifts and sorting out admin. We think that’s unfair and that’s why we’ve created Locumly.`,
  },
  SOLVE_TEMPORARY_STAFFING: {
    image: TemporaryStaffingImg,
    altText: 'Temporary staffing',
    title: 'Solve Temporary Staffing',
    type: 'small',
    descriptor: `Temporary staffing is pivotal. Unfilled shifts reduce your ability to serve patients, and can cost you thousands per day. And yet, existing processes are manual and costly, with no way to gauge the quality of care your temporary staff can provide.`
  },
}

const FeatureCard = ({ image, altText, title, descriptor, type, additionalClassNames = [] }) => (
  <section className={`square-card ${type} ${additionalClassNames.join(' ')}`}>
    <section className={'image-container'}>
      <img className={'image'} src={image} alt={altText} />
    </section>
    <section className={'text-content'}>
      <h3 className={'card-title'}>{title}</h3>
      <p className={'descriptor'}>{descriptor}</p>
    </section>
  </section>
);

const SmallFeatureCard = (props) => <FeatureCard {...props} type={'small'} />;

const AboutPage = () => {
  return (
    <article className={'about-page'}>
      <section className={'title-banner'}>
        <section className={'title-container'}>
          <h1 className={'title'}>{`Here's why we're excited`}</h1> 
        </section>
      </section>
      <section className={'about-container'}>
        <section className={'about-content'}>
          <section className={'about-title-container'}>
            <h2 className={'about-title'}>
              {'We have a '}
              <span className={'title-emphasized-text'}>{'dual mission'}</span>
            </h2>
            <h3 className={'about-subtitle'}>
              {'We recognise the need to achieve both flexible work and solve temporary staffing in Allied Health'}
            </h3>
          </section>
          <section className={'squares medium'}>
            <SmallFeatureCard {...ABOUT_CARDS.FLEXIBLE_WORK} additionalClassNames={['one']} />
            <SmallFeatureCard {...ABOUT_CARDS.SOLVE_TEMPORARY_STAFFING} additionalClassNames={['two', 'temporary-staffing']} />
          </section>
        </section>
      </section>
    </article>
  );
}

export default AboutPage;