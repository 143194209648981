import './StoreProfileSection.scss';

import PropTypes from 'prop-types';
import React from 'react';

const TITLE_POSITIONS = ['center', 'left'];

const propTypes = {
  /* Title position. Can be left aligned or center. */
  titlePosition: PropTypes.oneOf(TITLE_POSITIONS).isRequired,

  /* Section title. */
  sectionTitle: PropTypes.string.isRequired,

  /* Child components of this section. */
  children: PropTypes.node.isRequired,

  /* Additional class names. */
  additionalClassNames: PropTypes.arrayOf(PropTypes.string),
}

function StoreProfileSection({ titlePosition, sectionTitle, children, additionalClassNames = [] }) {
  return (
    <section className={`store-profile-section ${additionalClassNames.join(' ')}`}>
      <h4 className={`section-title ${titlePosition}`}>{sectionTitle}</h4>
      {children}
    </section>
  );
}

StoreProfileSection.defaultProps = { additionalClassNames: [] };
StoreProfileSection.displayName = 'StoreProfileSection';
StoreProfileSection.propTypes = propTypes;
export default StoreProfileSection;