import './StoreReviewer.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { ASSET_CDN_URL } from '../../../Constants';

const VerifiedIcon = `${ASSET_CDN_URL}/icons/verified-icon.svg`;

const propTypes = {
  /* Avatar for the store reviewer. */
  avatar: PropTypes.string.isRequired,

  /* Title of the reviewer. */
  title: PropTypes.string.isRequired,

  /* True if the user is verified. */
  verified: PropTypes.bool.isRequired,

  /* String duration length of reviewer's time spent working at the store. */
  duration: PropTypes.string.isRequired,
}

function StoreReviewer({ avatar, title, verified, duration }) {
  return (
    <section className={'store-reviewer'}>
      <img className={'reviewer-icon'} alt={title} src={avatar} />
      <section className={'reviewer-details'}>
        <section className={'reviewer-title-container'}>
          <span className={'reviewer-title'}>{title}</span>
          { verified &&
            <img className={'verified-icon'} src={VerifiedIcon} alt={'Verified Icon'} />
          }
        </section>
        <span className={'duration'}>{duration}</span>
      </section>
  </section>
  )
}

StoreReviewer.displayName = 'StoreReviewer';
StoreReviewer.propTypes = propTypes;
export default StoreReviewer;