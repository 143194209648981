import React from 'react';
import { Route } from 'react-router-dom';

import { RequireAuth } from '../components/hooks/AuthHook';

import MessagesPage from '../components/dashboard/MessagesPage';
import NotificationsPage from '../components/dashboard/NotificationsPage';
import OnboardingPage from '../components/common/OnboardingPage';
import LocumlyPage from '../wrappers/LocumlyPage';

function getUserRoutes() {
  return (
    <Route path={'/user'} element={<LocumlyPage />}>
      <Route index={true} element={<RequireAuth><MessagesPage /></RequireAuth>} />
      <Route path={'onboarding'} element={<RequireAuth><OnboardingPage /></RequireAuth>} />
      <Route path={'notifications'} element={<RequireAuth><NotificationsPage /></RequireAuth>} />
      <Route path={'messages'} element={<RequireAuth><MessagesPage /></RequireAuth>} />
      <Route path={'*'} element={<RequireAuth><MessagesPage /></RequireAuth>} />
    </Route>
  );
}

export default getUserRoutes;