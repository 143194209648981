import React from 'react';
import { Route } from 'react-router-dom';

import LocumlyQRCodeRedirect from '../redirects/qr-code/LocumlyQRCodeRedirect';
import OptometryQRCodeRedirect from '../redirects/qr-code/OptometryQRCodeRedirect';
import PharmacyQRCodeRedirect from '../redirects/qr-code/PharmacyQRCodeRedirect';

function getQRCodeRoutes() {
  return (
    <Route path={'/qr-code'}>
      <Route path={'locumly'} element={<LocumlyQRCodeRedirect />} />
      <Route path={'optometry'} element={<OptometryQRCodeRedirect />} />
      <Route path={'pharmacy'} element={<PharmacyQRCodeRedirect />} />
    </Route>
  );
}

export default getQRCodeRoutes;