import React from 'react';
import { DateRange } from 'react-date-range';
import { DAYS_OF_WEEK, MONTHS, CALENDAR_SEARCH_METHODS } from '../../Constants.js';
import { formatDate } from '../../../helpers/DateUtils.js';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { FilterCarousel } from './NavSearch';
import './MobileSearchWhen.scss';

// ********************** WHEN ********************** //
// Similar to CarouselItem component above. However handles the multi select month carousel
// under the other type of the when filter.
const MonthItem = ({Month, Year, chosenMonths, setChosenMonths, IMAGE}) => {
  return (
    <div className={'month-item'}>
      <div className={'month-padding'}>
        <button className={chosenMonths[Month] ? 'month-button-selected' : 'month-button'} onClick={() => setChosenMonths({...chosenMonths, [Month]: !chosenMonths[Month]})}>
          <div className={'item-position'}>
            <div className={'item-padding'}>
              <img src={chosenMonths[Month] ? IMAGE[0] : IMAGE[1]} alt={'Calendar Img'} className={'item-img'} />
              <div>
                <div className={'item-month'}>{MONTHS.displayText[Month]}</div>
                <div className={'item-year'}>{Year}</div>
              </div>
            </div>
          </div>
          <span></span>
        </button>
      </div>
    </div>
  )
}

const DoWItem = ({DayOfWeek, chosenDays, setChosenDays, IMAGE}) => {
  return (
    <div className={'month-item'}>
      <div className={'month-padding'}>
        <button className={chosenDays[DayOfWeek] ? 'month-button-selected' : 'month-button'} onClick={() => setChosenDays({...chosenDays, [DayOfWeek]: !chosenDays[DayOfWeek]})}>
          <div className={'item-position'}>
            <div className={'item-padding'}>
              <img src={chosenDays[DayOfWeek] ? IMAGE[0] : IMAGE[1]} alt={'Calendar Img'} className={'item-img'} />
              <div>
                <div className={'item-month'}>{DAYS_OF_WEEK.displayText[DayOfWeek]}</div>
              </div>
            </div>
          </div>
          <span></span>
        </button>
      </div>
    </div>
  )
}

// Overall When filter component.
const MobileSearchWhen = ({ handleActivePanel, activePanel, whenFilter, setWhenFilter }) => {
  const IMAGE = ['https://a0.muscache.com/pictures/33e22c88-92bf-47be-847a-98a7e374d78b.jpg', 'https://a0.muscache.com/pictures/cf82c9bc-520a-4486-9be4-1f0927972381.jpg']
  const d = new Date();
  const currMonth = d.getMonth();
  const currYear = d.getFullYear();

  // Calendar config
  const selectionRange = {
    startDate: whenFilter.dates.fromDate,
    endDate: whenFilter.dates.toDate,
    key: 'selection',
  }

  const handleDateSelect = (ranges) => {
    setWhenFilter({
      ...whenFilter, 
      dates: {
        fromDate: ranges.selection.startDate, 
        toDate: ranges.selection.endDate
      }
    })
  }

  const handleOtherSelect = (selectedItems) => {
    setWhenFilter({
      ...whenFilter,
      other: {
        ...whenFilter.other,
        selectedItems: selectedItems
      }
    })
  }

  const clearWhen = () => {
    setWhenFilter({
      ...whenFilter,
      dates: {
        fromDate: new Date(),
        toDate: new Date()
      },
      other: {
        searchMethod: "BYDAYOFWEEK",
        selectedItems: [...Array(7).keys()].reduce((acc, cur) => ({ ...acc, [cur]: false }), {})
      }
    })
  }

  let sMethodSelected = Object.keys(whenFilter.other.selectedItems).reduce((acc, cur) => whenFilter.other.selectedItems[cur] === true ? [...acc, cur] : [...acc], []);
  const formatSubtext = () => {
    let formattedText = ''
    if (whenFilter.useDates) {
      formattedText = formatDate(whenFilter.dates.fromDate, whenFilter.dates.toDate);
    } else {
      if (whenFilter.other.searchMethod === "BYDAYOFWEEK") {
        formattedText = 'Shifts on ' + sMethodSelected.map(day => DAYS_OF_WEEK.displayText[day].substring(0, 3)).join(', ');
      } else if (whenFilter.other.searchMethod === "BYMONTH") {
        formattedText = 'Shifts in ' + sMethodSelected.map(month => MONTHS.displayText[month].substring(0, 3)).join(', ');
      }
    }
    return formattedText.length > 28 ? formattedText.substring(0, 28) + '...' : formattedText;
  }
  return (
    <div className={'when-padding'}>
      <div className={'panel-size'}>
        <button className={activePanel.isWhen ? 'panel-closed' : 'panel-button'} onClick={() => handleActivePanel('isWhen')}>
          <span className={'panel-title'}>{"When"}</span>
          <span className={'panel-search-term'}>{formatSubtext()}</span>
        </button>
        <div className={activePanel.isWhen ? 'when-panel-open' : 'panel-closed'}>
          <section className={'when-panel-padding'}>
            <div className={'panel-open-title'}>{'When do you want to work?'}</div>
            <div className={'when-date-container'}>
              <div className={'when-date'}>
                <div className={'when-tabs'}>
                  <div className={'date-type-padding'}>
                    <div className={'date-type-display'}>
                      <div className={'date-type-border'}>
                        <div className={'date-type-grid'}>
                          <button className={whenFilter.useDates ? 'date-type-button-selected' : 'date-type-button'} onClick={() => setWhenFilter({...whenFilter, useDates: true})}>{'Choose dates'}</button>
                          <button className={whenFilter.useDates ? 'date-type-button' : 'date-type-button-selected'} onClick={() => setWhenFilter({...whenFilter, useDates: false})}>{'Other'}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={'selection-container'}>
                    <div className={whenFilter.useDates ? 'calendar-container': 'not-selected'}>
                      <DateRange 
                        ranges={[selectionRange]}
                        minDate={new Date()}
                        rangeColors={['#55A08D']}
                        onChange={handleDateSelect}
                      />
                    </div>
                    <div className={whenFilter.useDates ? 'not-selected' : ''}>
                      <FilterCarousel Question={'How would you like to search?'} OptionList={Object.keys(CALENDAR_SEARCH_METHODS)} OptionObject={CALENDAR_SEARCH_METHODS} itemType={whenFilter} setItemType={setWhenFilter} />
                      <div className={'month-container'}>
                        <div className={'month-carousel'}>
                          {whenFilter.other.searchMethod === "BYMONTH" ? [...Array(12).keys()].map((month, i) => 
                            <MonthItem
                              Month={currMonth + i > 11 ? [...Array(12).keys()][(currMonth + i) - 12] : [...Array(12).keys()][currMonth + i]}
                              Year={currMonth + 1 + i > 11 ? currYear + 1 : currYear}
                              key={`${MONTHS.displayText[month]}-${i}`}
                              chosenMonths={whenFilter.other.selectedItems}
                              setChosenMonths={handleOtherSelect}
                              IMAGE={IMAGE}
                            />
                          ) :
                          [...Array(7).keys()].map((DoW, i) => 
                            <DoWItem 
                              DayOfWeek={DoW}
                              chosenDays={whenFilter.other.selectedItems}
                              setChosenDays={handleOtherSelect}
                              IMAGE={IMAGE}
                              key={`${DAYS_OF_WEEK.displayText[DoW]}-${i}`}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <footer className={'when-footer'}>
                <button className={'footer-clear'} onClick={() => clearWhen()}>
                  <div className={'button-text'}>{'Clear'}</div>
                </button>
                <button className={'footer-next'} onClick={() => handleActivePanel('isOther')}>
                  <div className={'button-text'}>{'Next'}</div>
                </button>
              </footer>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export const WebSearchWhen = ({ handleActivePanel, activePanel, whenFilter, setWhenFilter }) => {
  const IMAGE = ['https://a0.muscache.com/pictures/33e22c88-92bf-47be-847a-98a7e374d78b.jpg', 'https://a0.muscache.com/pictures/cf82c9bc-520a-4486-9be4-1f0927972381.jpg']
  const d = new Date();
  const currMonth = d.getMonth();
  const currYear = d.getFullYear();

  // Calendar config
  const selectionRange = {
    startDate: whenFilter.dates.fromDate,
    endDate: whenFilter.dates.toDate,
    key: 'selection',
  }

  const handleDateSelect = (ranges) => {
    setWhenFilter({
      ...whenFilter, 
      dates: {
        fromDate: ranges.selection.startDate, 
        toDate: ranges.selection.endDate
      }
    })
  }

  const handleOtherSelect = (selectedItems) => {
    setWhenFilter({
      ...whenFilter,
      other: {
        ...whenFilter.other,
        selectedItems: selectedItems
      }
    })
  }

  const clearWhen = () => {
    setWhenFilter({
      ...whenFilter,
      dates: {
        fromDate: new Date(),
        toDate: new Date()
      },
      other: {
        searchMethod: "BYDAYOFWEEK",
        selectedItems: [...Array(7).keys()].reduce((acc, cur) => ({ ...acc, [cur]: false }), {})
      }
    })
  }
  return (
    <div className={'when-padding'}>
      <div className={'panel-size'}>
        <div className={activePanel.isWhen ? 'when-panel-open' : 'panel-closed'}>
          <section className={'when-panel-padding'}>
            <div className={'when-date-container'}>
              <div className={'when-date'}>
                <div className={'when-tabs'}>
                  <div className={'date-type-padding'}>
                    <div className={'date-type-display'}>
                      <div className={'date-type-border'}>
                        <div className={'date-type-grid'}>
                          <button className={whenFilter.useDates ? 'date-type-button-selected' : 'date-type-button'} onClick={() => setWhenFilter({...whenFilter, useDates: true})}>{'Choose dates'}</button>
                          <button className={whenFilter.useDates ? 'date-type-button' : 'date-type-button-selected'} onClick={() => setWhenFilter({...whenFilter, useDates: false})}>{'Other'}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={'selection-container'}>
                    <div className={whenFilter.useDates ? 'calendar-container': 'not-selected'}>
                      <DateRange 
                        ranges={[selectionRange]}
                        minDate={new Date()}
                        rangeColors={['#55A08D']}
                        onChange={handleDateSelect}
                      />
                    </div>
                    <div className={whenFilter.useDates ? 'not-selected' : ''}>
                      <FilterCarousel Question={'How would you like to search?'} OptionList={Object.keys(CALENDAR_SEARCH_METHODS)} OptionObject={CALENDAR_SEARCH_METHODS} itemType={whenFilter} setItemType={setWhenFilter} />
                      <div className={'month-container'}>
                        <div className={'month-carousel'}>
                          {whenFilter.other.searchMethod === "BYMONTH" ? [...Array(12).keys()].map((month, i) => 
                            <MonthItem
                              Month={currMonth + i > 11 ? [...Array(12).keys()][(currMonth + i) - 12] : [...Array(12).keys()][currMonth + i]}
                              Year={currMonth + 1 + i > 11 ? currYear + 1 : currYear}
                              key={`${MONTHS.displayText[month]}-${i}`}
                              chosenMonths={whenFilter.other.selectedItems}
                              setChosenMonths={handleOtherSelect}
                              IMAGE={IMAGE}
                            />
                          ) :
                          [...Array(7).keys()].map((DoW, i) => 
                            <DoWItem 
                              DayOfWeek={DoW}
                              chosenDays={whenFilter.other.selectedItems}
                              setChosenDays={handleOtherSelect}
                              IMAGE={IMAGE}
                              key={`${DAYS_OF_WEEK.displayText[DoW]}-${i}`}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <footer className={'when-footer'}>
                <button className={'footer-clear'} onClick={() => clearWhen()}>
                  <div className={'button-text'}>{'Clear'}</div>
                </button>
                <button className={'footer-next'} onClick={() => handleActivePanel('isOther')}>
                  <div className={'button-text'}>{'Next'}</div>
                </button>
              </footer>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default MobileSearchWhen;