import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/AuthHook';
import { NAVIGATION_MODES } from '../BusinessConstants';
import { BUSINESS_DEFAULT_ROUTE, SHIFT_TYPES } from '../../Constants';
import StoreSidebarNavigationButton from './StoreSidebarNavigationButton';
import BackArrowButton from '../../common/BackArrowButton';
import StoreDropdown from './StoreDropdown';
import { getProfile, postStoreAvatarUpload } from '../../Api';
import './StoreNavigationSidebar.scss';

export const StoreNavigationSidebar = ({ currentMode }) => {
  const { profile, setProfile } = useAuth();
  const navigate = useNavigate();
  const backToStores = () => navigate(BUSINESS_DEFAULT_ROUTE);
  const { state } = useLocation();
  const navigationList = NAVIGATION_MODES
    .map(mode => <StoreSidebarNavigationButton mode={mode} state={state} currentMode={currentMode} key={`sidebar-${mode}`} />);
  const store = profile.stores.find((store) => state && store._id === state.storeId) || profile.stores[0];
  const { avatar } = store;

  const uploadAvatar = (e) => {
    const file = e.target.files[0];
    postStoreAvatarUpload(store._id, file)
      .then(() => getProfile().then(({ profile }) => {
        setProfile(profile);
      }))
      .catch((err) => console.error(err));
  }
  const storeAvatar = avatar ? avatar : SHIFT_TYPES[store.type].imageUrl;
  return (
    <section className={'store-sidebar-container'}>
      <aside className={'store-sidebar'}>
        <BackArrowButton onClick={backToStores} text={'Back'} />
        <section className={'store-image-and-name'}>
          <img className={'store-image'} src={storeAvatar} alt={'Store Avatar'} />
          <section className={'upload-avatar-container'}>
            <label for={'avatar-image-upload'} className={'upload-avatar'}>{'Upload New Avatar'}</label>
            <input
              type={'file'}
              name={'avatar-image-upload'}
              id={'avatar-image-upload'}
              className={'avatar-image-upload'}
              accept={'image/*'}
              onChange={uploadAvatar}
            />
          </section>
          <span className={'view-for-text'}>{'Viewing for'}</span>
          <StoreDropdown currentMode={currentMode} />
        </section>
        <ul className={'store-navigation-list'}>
          {navigationList}
        </ul>
      </aside>
    </section>
  );
};

export default StoreNavigationSidebar;
