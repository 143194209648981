import './StoreLocationSection.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { ADDRESS_PROPS, COORDINATES_PROPS } from '../../StoreProfileProps';
import { GOOGLE_DIRECTIONS_URL } from '../../../Constants';
import useStoreProfile from '../../../hooks/StoreProfileContext';

import StoreProfileSection from './StoreProfileSection';
import StoreLocationMap from './StoreLocationMap';

const propTypes = {
  /* Coordinates of the store. */
  coordinates: PropTypes.shape(COORDINATES_PROPS).isRequired,

  /* Address of the store. */
  address: PropTypes.shape(ADDRESS_PROPS).isRequired,

  /* Store name. */
  storeName: PropTypes.string.isRequired,
}

function StoreLocationSection({ storeName, address, coordinates }) {
  const { storeProfile } = useStoreProfile();
  const { theme, storeUrl } = storeProfile;
  const { primaryHexColor } = theme;
  const { street, suburb, city, state, postcode, country } = address;
  const uriEncodedAddress = encodeURI(`${storeName} ${street} ${suburb} ${city} ${state} ${postcode} ${country}`)
  const getDirections = () => window.open(`${GOOGLE_DIRECTIONS_URL}${uriEncodedAddress}`);
  const plausibleLink = `plausible-event-link=${storeUrl}`;
  const plausibleEventClass = `plausible-event-name=Get+Directions+Click ${plausibleLink}`;
  return (
    <StoreProfileSection
      titlePosition={'center'}
      sectionTitle={'Our Location'}
      additionalClassNames={['location']}
    >
      <StoreLocationMap coordinates={coordinates} />
      <button
        className={`location-button very-dark ${plausibleEventClass}`}
        type={'button'}
        onClick={getDirections}
        style={{ backgroundColor: primaryHexColor, borderColor: primaryHexColor }}
      >
        {'Get Directions'}
      </button>
    </StoreProfileSection>
  )
}

StoreLocationSection.displayName = 'StoreLocationSection';
StoreLocationSection.propTypes = propTypes;
export default StoreLocationSection;