import { formatDatesWithTimeZone } from '../../helpers/DateUtils.js';
import { useNavigate } from 'react-router-dom';
import { ASSET_CDN_URL } from '../Constants';
import { STORE_TYPE } from '../home/HomeConstants';
import './HomeShiftCard.scss';

const DateIcon = `${ASSET_CDN_URL}/home/date.svg`;
const TimeIcon = `${ASSET_CDN_URL}/home/time.svg`;
const LocationIcon = `${ASSET_CDN_URL}/home/location.svg`;
const RightCaret = `${ASSET_CDN_URL}/home/right-caret.svg`;

/**
 * Shift card displayed on home screen.
 * @author mliu Friday December 2nd 2022
 */
const HomeShiftCard = ({ shift }) => {
  const navigate = useNavigate();
  const { avatar = null, name, type, startTime, endTime, address, rate, total, _id } = shift;
  const { city, state, timeZone } = address;
  const { defaultAvatar, storeTypeText } = STORE_TYPE[type];
  const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(new Date(startTime), new Date(endTime), false, timeZone);
  const time = `${startTimeDisplay} - ${endTimeDisplay}`;
  const avatarImage = avatar ? avatar : defaultAvatar;
  const location = `${city}, ${state}`;
  const rateText = `$${(rate / 100).toFixed(2)}/hr`;
  const totalText = `$${(total / 100).toFixed(2)}`;
  const viewShift = () => navigate(`/find/shift?id=${_id}`);
  return (
    <section className={'home-shift-card'}>
      <section className={'information'}>
        <section className={'store-details'}>
          <img className={'store-avatar'} src={avatarImage} alt={`${storeTypeText} avatar`} />
          <h3 className={'name'}>{name}</h3>
          <h4 className={'type'}>{storeTypeText}</h4>
        </section>
        <section className={'shift-details'}>
          <section className={'detail'}>
            <img className={'icon'} src={DateIcon} alt={'date'} />
            <span className={'label'}>{date}</span>
          </section>
          <section className={'detail'}>
            <img className={'icon'} src={TimeIcon} alt={'time'} />
            <span className={'label'}>{time}</span>
          </section>
          <section className={'detail'}>
            <img className={'icon'} src={LocationIcon} alt={'location'} />
            <span className={'label'}>{location}</span>
          </section>
        </section>
      </section>
      <section className={'footer'}>
        <section className={'payment'}>
          <section className={'rate'}>{rateText}</section>
          <section className={'total'}>{totalText}</section>
        </section>
        <button className={'view-shift very-dark'} onClick={viewShift}>
          <img className={'right-caret'} src={RightCaret} alt={'view shift'} />
        </button>
      </section>
    </section>
  );
}

export default HomeShiftCard;