import './StoreProfileModal.scss';

import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { STORE_OFFERING_PROPS } from '../StoreProfileProps';
import { useOutsideClick } from '../../hooks/CommonHooks';
import useStoreProfile from '../../hooks/StoreProfileContext';

const propTypes = {
  /* Store modal data - made up of text as title and a description of the action taken. */
  modalData: PropTypes.shape(STORE_OFFERING_PROPS).isRequired,

  /* Callback to set the modal content. */
  setModalData: PropTypes.func.isRequired,
}

function StoreProfileModal({ modalData, setModalData }) {
  const modalRef = useRef(null);
  const { storeProfile } = useStoreProfile();
  const { theme } = storeProfile;
  const { primaryHexColor } = theme;
  const { text, description } = modalData;
  const closeModal = () => setModalData(null);
  useOutsideClick(modalRef, closeModal);
  return (
    <section className={'store-profile-modal-container'}>
      <section className={'store-profile-modal'} ref={modalRef}>
        <section className={'close-modal-button-container'}>
          <button
            className={'close-modal-button very-dark'}
            onClick={closeModal}
            type={'button'}
          >
            {'×'}
          </button>
        </section>
        <section className={'modal-content'}>
          <h1 className={'text'} style={{ color: primaryHexColor }}>{text}</h1>
          <p className={'description'}>{description}</p>
        </section>
      </section>
    </section>
  )
}

StoreProfileModal.displayName = 'StoreProfileModal';
StoreProfileModal.propTypes = propTypes;
export default StoreProfileModal;