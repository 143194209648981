import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import CartContext from '../hooks/CartContext';
import { getOneShift, getAllShifts } from '../Api.js';
import useAuth from '../hooks/AuthHook.jsx';
import { useSingleShiftMap, useMediaQuery } from '../hooks/CommonHooks.jsx';
import BackArrowButton from '../common/BackArrowButton';
import { MILLISECONDS_IN_AN_HOUR, NETWORKS, ASSET_CDN_URL, DEFAULT_LOCATION } from '../Constants';
import { debounce } from '../../helpers/debounce.js';
import { computeDistance, orderShifts } from '../../helpers/ShiftUtils.js';
import { formatDatesWithTimeZone } from '../../helpers/DateUtils';
import { SOFTWARES, EQUIPMENTS, SUPPORTS, DAILY_SCRIPT_VOLUME } from '../business/store/ManagePreferencesPage.jsx';
import { ShowStoreNotif } from './FindStoreCard';
import './ShiftPage.scss';

const Logo = `${ASSET_CDN_URL}/logo/aqua.png`;
const NoResult = `${ASSET_CDN_URL}/icons/no-result-icon.svg`;
const DropdownArrow = `${ASSET_CDN_URL}/dropdown/faq-dropdown-arrow.svg`;
const ShiftDropdownArrow = `${ASSET_CDN_URL}/dropdown/shift-rec-drop-arrow.svg`;

const FULLY_VACCINATED_URL = 'https://www.health.gov.au/sites/default/files/documents/2021/12/atagi-advice-on-the-definition-of-fully-vaccinated.pdf';

export const FAQ = [
  {
    question: "What do I need to work a Locum Shift?",
    answer: "You'll need to provide a valid ABN (Australian Business Number) and AHPRA number in order to request a shift."
  },
  {
    question: "What happens after my request?",
    answer: "The store is automatically notified of your request. They will be able to see your profile with your past experience. From there, they may message you with additional questions, or confirm you for the shift.\n\nHang tight as some stores can take a few days to respond to your request! We keep track of your requests, and will nudge stores to respond quickly (typically within 48 - 72 hours).\n\nWhilst we take our best efforts to keep the shift list updated, some may be unavailable as the store has filled them internally. Once you make the request, the store can confirm if the shifts are still available."
  },
  {
    question: "Can I request multiple shifts?",
    answer: "We encourage you to request multiple shifts! It is a nice, easy way to plan out your Locum schedule in advance.\n\nYou're also able to request multiple shifts for the same day. We understand that not all shifts get confirmed (often there are multiple Locums for the same shifts!). We ask that once once you confirm a shift, you withdraw from any others on the same day to avoid double booking."
  },
  {
    question: "What happens after I'm confirmed for a shift?",
    answer: "You will be sent a confirmation email with any store-specific onboarding instructions prior.\n\nAfter you complete a shift, you will be asked to mark the shift as complete. From here you can have Locumly generate an invoice on your behalf (which requiers Stripe authentication), or send it separately yourself.\n\nYou'll also be asked to review the store. This will be super helpful in the future when deciding which stores have the best rep!"
  },
  {
    question: "What happens if I need to cancel a shift I'm confirmed for?",
    answer: "We understand there are extenuating circumstances, however we do ask that you be conscious to not frequently withdraw from shifts you have been confirmed for.\n\nWe actively track shift completion rates, and someone with a particularly low shift completion rate may not receive as many shift invites."
  }
]

const getPretestingText = (pretesting) => {
  return !pretesting ? 'Pretesting will not be provided by the store' : 'Pretesting will be provided by the store';
};

// const getNetworkText = (inNetwork, network) => {
//   return !inNetwork ? 'None' : `${network}`;
// }

const getNetworkTextPreferred = (inNetwork, network) => {
  return !inNetwork ? 'The store has no requirements for past experience' : `Past experience at ${network} is preferred`;
}

// const getIntervalText = (interval) => {
//   return interval === 0 ? 'No Preference' : `${interval} minutes`;
// }

const getIntervalTextPreferred = (interval) => {
  return interval === 0 ? 'No specific testing time familiarity required' : `The store operates using ${interval} minute testing times`;
}

const getSoftwaresText = (softwares, type) => {
  if (type === 'OPTOMETRY') {
    return `${softwares.length ? softwares.map(software => SOFTWARES[type][software].text).join(', ') : 'None'}`;
  } else {
    return `${softwares.length ? `The store uses ${softwares.map(software => SOFTWARES[type][software].text).join(', ')} for its dispensing software` : 'None'}`;
  }
}

const getEquipmentsText = (equipments, type) => {
  return `${equipments.length > 0 ? equipments.map(equipment => EQUIPMENTS[type][equipment].text).join(', ') : 'None'}`;
}
const getDailyScriptingPreferred = (dailyScriptVolume) => {
  return `The store processes ${DAILY_SCRIPT_VOLUME[dailyScriptVolume].displayText} scripts/Rx per day`;
}

export const Accordian = ({question, index, preferences, organization, unpaidBreakTime = null, network, type}) => {
  const { inNetwork, pretesting, interval, intervalText, softwares, equipments, text, previousNetworkExperience, travel, accommodation, dailyScriptVolume, supports = [] } = preferences;
  // const premises = organization ? organization.premises : null;
  const unpaidMinutes = unpaidBreakTime === null ? null : Math.round((unpaidBreakTime / MILLISECONDS_IN_AN_HOUR).toFixed(2) * 60);

  const BARCOLOUR = ['green-sidebar', 'yellow-sidebar'];
  const [selected, setSelected] = useState(null);
  const defaultText = <>{'Learn more...'}</>

  const answerText = index === 0 ? (
    <>
      <span className={'bold'}>{'There are currently no requirements for this job!'}</span>
      {/* <a className={'bold'} href={FULLY_VACCINATED_URL}>{'Be fully vaccinated.'}</a>
      <ul className={'info-list'}>
        <li className={'requirement'}>
          {' The store will check vaccination status upon arrival.'}
        </li>
      </ul> */}
      {/* {(compulsory && compulsory.pretesting) &&
        <div className={'requirement bold no-style'}>{getPretestingText(pretesting)}</div>
      }
      {(compulsory && compulsory.inNetwork) && network.key !== NETWORKS[type].OTHER.key &&
      <>
        <span className={'bold'}>{'Past experience: '}</span>
        <ul className={'info-list'}>
          <li className={'requirement'}>
            {getNetworkText(inNetwork, network.text)}
          </li>
        </ul>
      </>
      }
      {(compulsory && compulsory.interval) &&
      <>
        <span className={'bold'}>{'Familiar with the following testing time: '}</span>
        <ul className={'info-list'}>
          <li className={'requirement'}>
            {getIntervalText(interval)}
          </li>
        </ul>
      </>
      }
      {(compulsory && compulsory.softwares) && softwares.length > 0 &&
      <>
        <span className={'bold'}>{'Locum should be familiar with the following software: '}</span>
        <ul className={'info-list'}>
          <li className={'requirement'}>
            {getSoftwaresText([...softwares], SOFTWARES[type])}
          </li>
        </ul>
      </>
      }
      {(compulsory && compulsory.equipments) && equipments.length > 0 &&
      <>
        <span className={'bold'}>{'Locum should be familiar with the following equipment: '}</span>
        <ul className={'info-list'}>
          <li className={'requirement'}>
            {getEquipmentsText([...equipments], EQUIPMENTS[type])}
          </li>
        </ul>
      </>
      } */}
    </>
  ) : (
    <>
      {unpaidMinutes &&
      <>
        <span className={'bold'}>{'Unpaid time (e.g. for Lunch break): '}</span>
        <ul className={'info-list'}>
          <li className={'information'}>
            {`${unpaidMinutes} minutes`}
          </li>
        </ul>
      </>
      }
      {
        type === 'OPTOMETRY' &&
        <div className={'information bold no-style'}>{getPretestingText(pretesting)}</div>
      }
      { intervalText && 
      <>
        <span className={'bold'}>{'Other working preferences for the store: '}</span>
        <ul className={'info-list'}>
          <li className={'information'}>
            {intervalText}
          </li>
        </ul>
      </>
      }
      {network.key !== NETWORKS[type].OTHER.key &&
      <>
        <span className={'bold'}>{'Past experience: '}</span>
        <ul className={'info-list'}>
          <li className={'information'}>
            {getNetworkTextPreferred(inNetwork || previousNetworkExperience, network.text)}
          </li>
        </ul>
      </>
      }
      {type === 'OPTOMETRY' &&
      <>
        <span className={'bold'}>{'Familiar with the following testing time: '}</span>
        <ul className={'info-list'}>
          <li className={'information'}>
            {getIntervalTextPreferred(interval)}
          </li>
        </ul>
      </>
      }
      {
        type === 'PHARMACY' && dailyScriptVolume && 
        <div className={'information bold no-style'}>{getDailyScriptingPreferred(dailyScriptVolume)}</div>
      }
      {
        type === 'PHARMACY' && supports && supports.length > 0 &&
        <>
          <span className={'bold'}>{'Support staff available at the store include: '}</span>
          <ul className={'info-list'}>
            <li className={'information'}>
              {supports.map(support => SUPPORTS[support].text).join(', ')}
            </li>
          </ul>
        </>
      }
      {softwares.length > 0 && type === 'OPTOMETRY' && 
      <>
        {<span className={'bold'}>{'Software used by the store: '}</span>}
        <ul className={'info-list'}>
          <li className={'information'}>
            {getSoftwaresText([...softwares], type)}
          </li>
        </ul>
      </>
      }
      {softwares.length > 0 && type === 'PHARMACY' && 
        <div className={'information bold no-style'}>{getSoftwaresText([...softwares], type)}</div>
      }
      {type === 'OPTOMETRY' && equipments && equipments.length > 0 &&
      <>
        <span className={'bold'}>{'Equipment used by the store: '}</span>
        <ul className={'info-list'}>
          <li className={'information'}>
            {getEquipmentsText([...equipments], type)}
          </li>
        </ul>
      </>
      }
      {/* {premises &&
      <>
        <span className={'bold'}>{'Premises type: '}</span>
        <ul className={'info-list'}>
          <li className={'information'}> 
            {`${PREMISE_TYPES[premises].text}`}
          </li>
        </ul>
      </>
      } */}
      { travel && 
        <div className={'information bold no-style'}>{'Travel expenses can be covered by the store.'}</div>
      }
      { accommodation && 
        <div className={'information bold no-style'}>{'Accommodation expenses can be covered by the store.'}</div>
      }
      <span className={'bold'}>{'Other preferences to note: '}</span>
      <ul className={'info-list'}>
        <li className={'information'}>
          {`${(text && text.length > 0) ? text : 'NA' }`}
        </li>
      </ul>
    </>
  );

  const toggle = i => {
    if (selected === i) {
      setSelected(null);
    } else {
      setSelected(i);
    }
  }

  useEffect(() => {
    const text = document.getElementById(question + index);
    const wrapper = document.getElementById('answer' + question + index);
    wrapper.style.height = text.clientHeight + 'px';
    wrapper.style.maxHeight = text.clientHeight + 'px';
  }, [selected, setSelected, index, question])

  return (
    <div className={'item'} onClick={() => toggle(index)}>
      <div className={`${BARCOLOUR[index]} expandable-bar`}></div>
      <div className={'question-container'}>
        <div className={'question-title'}>
          <span>{question}</span>
        </div>
        <div id={'answer' + question + index} className={selected === null ? 'answer-content hide' : 'answer-content'}>
          <div className={'info-answers'} id={question + index}>
            {index === selected ? answerText : defaultText}
          </div>
        </div>
      </div>
    </div>
  )
}

export const FAQDropDown = ({ item, index }) => {
  const [selected, setSelected] = useState(null);
  
  const toggle = i => {
    const text = document.getElementById(item.question + index);
    const wrapper = document.getElementById('answer' + item.question + index);

    if (selected === i) {
      text.textContent = '';
      setSelected(null);
    } else {
      text.textContent = item.answer;
      setSelected(i);
    }
    
    wrapper.style.height = text.clientHeight + 'px';
    wrapper.style.maxHeight = text.clientHeight + 'px';
  }
  return (
    <div className={'faq-item'} onClick={() => toggle(index)}>
      <div className={'faq-header'}>
        {item.question}
        <img src={DropdownArrow} alt={''} className={selected === index ? 'faq-header-icon open' : 'faq-header-icon'} />
      </div>
      <div id={'answer' + item.question + index} className={selected === null ? 'faq-content' : 'faq-content show-dropdown'}>
        <span id={item.question + index} className={'faq-answer'}>{''}</span>
      </div>
    </div>
  )
}

const ShiftRecItem = ({ shift, state, HandleCart, navigate, toggle, selected, last = false }) => {
  const { startTime, endTime, address } = shift;
  const { timeZone } = address;
  const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(new Date(startTime), new Date(endTime), true, timeZone);
  const { cartShifts } = useContext(CartContext);
  const viewRecShift = (e) => {
    e.stopPropagation()
    toggle(selected);
    navigate(`/find/shift?id=${shift._id}`, {state})
  }
  return (
    <div className={'rec-shift-card'}>
      <div className={'rec-title-container'}>
        <div className={'rec-name'}>
          <span>{shift.name}</span>
        </div>
        <div className={'rec-rate'}>
          <span>{shift.billToMedicare ? 'Rate: NA' : `$${(shift.rate / 100).toFixed(0)} p/h`}</span>
        </div> 
      </div>
      <div className={'rec-detail-container'}>
        <div className={'rec-date-container'}>
          <div className={'rec-date'}>
            <span>{date}</span>
          </div>
          <div className={'rec-time'}>
            <span>{`${startTimeDisplay} - ${endTimeDisplay}`}</span>
          </div>
        </div>
        <div className={'rec-button-container'}>
          <div className={'rec-button'}>
            <button className={'rec-view'} onClick={(e) => viewRecShift(e)}>
              {'View Shift'}
              <img src={ShiftDropdownArrow} alt={''} className={'rec-button-arr'} />
            </button>
          </div>
          <div className={'rec-button'}>
            <button className={last ? 'rec-add last-shift-rec' : 'rec-add'} onClick={(e) => HandleCart(e, shift, false)}>
              {cartShifts.hasOwnProperty(shift._id) ? 'Added' : 'Quick Add'}
              {cartShifts.hasOwnProperty(shift._id) ? '' : <img src={ShiftDropdownArrow} alt={''} className={'rec-button-arr'} />}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

// Recommendation logic:
// 1. If no location at all, get 4 shifts closest to current shift that are not current shift.
// allShifts will be taken if brought from findPage, but if they have the direct link, need to query.
const ShiftRec = ({ requestedShiftList, shift, state, searchParams, HandleCart, navigate }) => {
  const [selected, setSelected] = useState(false);
  const [shifts, setShifts] = useState(state && state.allShifts !== undefined ? state.allShifts : null)
  const [recShifts, setRecShifts] = useState([]);

  useEffect(() => {
    if (shifts && shifts.length > 1) {
      const storeTypeShifts = shifts.filter(typeShift => shift.type === typeShift.type);
      const distanceShifts = storeTypeShifts
      .map(s => ({...s, distance: computeDistance(shift.address.coordinates, s.address.coordinates, 'kilometers')}));
      const distOrderedShifts = orderShifts(distanceShifts, 'distance-ascending').filter(s => s._id !== shift._id && !requestedShiftList.includes(s._id)).slice(0, 4);
      const dateOrderedShifts = orderShifts(distOrderedShifts, 'date-ascending');
      setRecShifts(dateOrderedShifts);
    }
  }, [searchParams, shifts, shift, requestedShiftList])

  useEffect(() => {
    if (shifts === null) {
      getAllShifts(true).then((response) => {
        setShifts(response.shifts);
      }).catch(() => setShifts([]));
    }
  }, [shifts])
  
  const toggle = useCallback((select) => {
    const text = document.getElementById(`${shift._id}-rec-list`);
    const wrapper = document.getElementById(`${shift._id}-rec-wrapper`);

    if (selected !== null) {
      setSelected(!select);
    }

    wrapper.style.height = select ? 0 + 'px' : text.clientHeight + 'px';
    wrapper.style.maxHeight = select ? 0 + 'px' : text.clientHeight + 'px';
  }, [selected, shift._id])

  useEffect(() => {
    if (selected) {
      toggle(null)
    }
  }, [recShifts, selected, toggle])

  return (
    <div className={'shift-rec-container'}>
      <div className={selected ? 'rec-header remove-radius' : 'rec-header'} onClick={() => toggle(selected)}>
        {'View Similar Shifts...'}
        <img src={ShiftDropdownArrow} alt={''} className={selected ? 'rec-header-icon open' : 'rec-header-icon'} />
      </div>
      <div id={`${shift._id}-rec-wrapper`} className={'rec-content'}>
        <div id={`${shift._id}-rec-list`} className={'rec-answer'}>
          {recShifts.map((recShift, i, {length}) => {
            if (length - 1 === i) {
              return <ShiftRecItem shift={recShift} state={state} HandleCart={HandleCart} navigate={navigate} toggle={toggle} selected={selected} last={true}/>
            }
            return <ShiftRecItem shift={recShift} state={state} HandleCart={HandleCart} navigate={navigate} toggle={toggle} selected={selected}/>
          })}
        </div>
      </div>
    </div>
  )
}

const StoreLocationMap = ({shift}) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  useSingleShiftMap(mapContainer, map, shift.address.coordinates);

  return (
    <div className='map-position'>
      <section ref={mapContainer} className={'shift-map-container'} />
    </div>
  )
}

const ShiftInfoCard = ({ ViewStorePage, shift, isTablet, requestedShiftList, HandleCart, cartShifts, messageStore }) => {
  const {name, address, firstName, lastName, shiftDate, shiftStartTime, shiftEndTime, startTime, endTime, unpaidBreakTime, billToMedicare, rate, total, status} = shift;
  const datePosted = Math.floor(Math.abs(new Date(shift.datePosted) - new Date()) / (36e5 * 24));
  return (
    <div className={'info-card-back'}>
      {isTablet && <div className={'info-card-top'}></div>}
      <div className={'info-card-container'}>
        <div className={`shift-post-date ${datePosted < 2 ? 'new-shift' : 'old-shift'}`}>
          {datePosted < 2 ? 'New' : `Added ${datePosted}d ago`}
        </div>
        <div className={'info-title-container'}>
          <span className={'info-title'} onClick={(e) => ViewStorePage(e)}>{name}</span>
        </div>
        <div className={'info-address-container'}>
          <span className={'info-address'}>{`${address.suburb}, ${address.state}`}</span>
        </div>
        <div className={'info-poster-container'}>
          <span className={'info-prefix'}>{'Posted by'}</span>
          <span className={'info-poster'}>{`${firstName} ${lastName}`}</span>
        </div>
        <div className={'info-datetime-container'}>
          <span className={'info-datetime'}>{`${shiftDate}. `}</span>
          <span className={'info-datetime'}>{`${shiftStartTime} - ${shiftEndTime}`}</span>
        </div>
        <div className={'info-blocks-container'}>
          <div className={'info-block'}>
            <span className={'block-title'}>{'HOURS'}</span>
            <span>{((endTime - startTime) / MILLISECONDS_IN_AN_HOUR).toFixed(2)}</span>
          </div>
          <div className={'info-block'}>
            <span className={'block-title'}>{'UNPAID HOURS'}</span>
            <span>{(unpaidBreakTime / MILLISECONDS_IN_AN_HOUR).toFixed(1)}</span>
          </div>
          <div className={'info-block'}>
            <span className={'block-title'}>{'HOURLY RATE'}</span>
            <span>{billToMedicare ? 'Rate: NA' : `$${(rate / 100).toFixed(0)}`}</span>
          </div>
          <div className={'info-block'}>
            <span className={'block-title'}>{'TOTAL'}</span>
            <span>{billToMedicare ? 'NA' : `$${(total / 100).toFixed(0)}`}</span>
          </div>
        </div>
        {!isTablet && 
        <div className={'info-req-container'}>
          {shift.deletionDate
          ?
          <>
            <button disabled className={'shift-button'}>{'This shift has been removed'}</button>
          </>
          :
            status !== 'UNFILLED'
            ?
            <>
              <button disabled className={'shift-button'}>{'This shift has been filled'}</button>
            </>
            :
            requestedShiftList.includes(shift._id)
              ?
                <>
                  <button disabled className={'shift-button'}>{'Requested'}</button>
                  <button onClick={() => messageStore()} className={'shift-button message'}>{'Message'}</button>
                </>
              :
              <>
                <button onClick={(e) => HandleCart(e, shift, true)} className={'shift-button'}>{'Request Shift'}</button>
                <button onClick={(e) => HandleCart(e, shift, false)} className={'add-to-cart-button'}>
                  {cartShifts.hasOwnProperty(shift._id) ? 'Added' : 'Add to cart'}
                </button>
              </>
          }
        </div>
        }
      </div>
      {isTablet && <div className={'info-card-bot'}></div>}
    </div>
  )
}

const ShiftPage = () => {
  const navigate = useNavigate();
  const [shift, setShift] = useState(null);
  const [storeId, setStoreId] = useState(null);
  const { state } = useLocation();
  const notOnMap = state === null || state.isListView === undefined ? true : state.isListView;
  const [searchParams] = useSearchParams();
  const [isLoading, setLoading] = useState(true);
  const { authed, profile } = useAuth(); 
  const [requestedShiftList, setRequestedShifts] = useState(profile && profile.shifts ? profile.shifts : [])
  const { addToCart, cartShifts } = useContext(CartContext);
  const [showStoreNotif, setShowStoreNotif] = useState(false);
  const location = state && state.location ? state.location : null;
  const [distance, setDistance] = useState(state && state.distance ? state.distance : null);
  const infoQuestions = ["What's required for this job?", "Other things to know"]
  const isTablet = useMediaQuery('(max-width: 820px)');
  const isMobile = useMediaQuery('(max-width: 480px)');

  useEffect(() => {
    if (shift && Object.keys(shift).length > 0) {
      setStoreId(shift.storeId);
    }
  }, [shift])

  useEffect(() => {
    if (profile && profile.shifts) {
      setRequestedShifts(profile.shifts.map(shift => shift._id));
    }
  }, [profile]);

  const handleReturn = () => {
    if (state && state.fromStore) {
      navigate(`/find/store/view?id=${state.fromStore}`, {state});
    } else {
      navigate('/find', {state: {...state, isListView: notOnMap} });
    }
  }

  const BackToHome = () => {
    navigate('/');
  }
  
  useEffect(() => {
    const shiftId = searchParams.get('id');
    if (shiftId && (shift === null || shift._id !== shiftId)) {
      setLoading(true);
      getOneShift(shiftId)
        .then((response) => {
          if (Object.keys(response.shift).length > 0) {
            setShift(response.shift);
            setDistance(computeDistance(location !== null ? location.coordinates : DEFAULT_LOCATION.coordinates, response.shift.address.coordinates, 'kilometers'))
          } else {
            setShift({})
          }
        })
        .catch(() => setShift({}))
        .finally(() => setLoading(false)); 
    }
  }, [searchParams, state, location, shift]);

  const [prevScrollPos, setPrevScrollPos] = useState(-1);
  const [visible, setVisible] = useState(true);

  const handleScroll = debounce(() => {
    const currentScrollPos = window.pageYOffset;

    // If refreshed, show footer, otherwise hide footer when scrolling down and show footer when scrolling up.
    setVisible(prevScrollPos === -1 || 
              (
                prevScrollPos > currentScrollPos
              ) ||
                currentScrollPos < 10);

    setPrevScrollPos(currentScrollPos);
  }, 20);

  const ViewStorePage = (e) => {
    e.stopPropagation();
    if (isMobile) {
      navigate(`/find/store/view?id=${storeId}`, {state: {...state.currentState, notOnMap, distance, location}});
    } else {
      const newWindow = window.open(`/find/store/view?id=${storeId}`, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    }
  }

  useEffect(() => {
    if (isMobile) {
      window.addEventListener('scroll', handleScroll);

      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [prevScrollPos, visible, handleScroll, isMobile]);

  const HandleCart = (e, shift, reqNow) => {
    e.stopPropagation();
    if (authed && profile.role === 'LOCUM') {
      if (cartShifts.hasOwnProperty(shift._id) && reqNow) {
        navigate('/locum/cart');
      } else {
        addToCart(shift);
        if (reqNow) {
          navigate('/locum/cart');
        }
      }
    } else if (authed && profile.role === 'BUSINESS') {
      setShowStoreNotif(true);
    } else {
      navigate('/login');
    }
  }

  const messageStore = () => navigate('/user/messages', { 
    state: { 
      open: false,
      business: { 
        _id: shift.businessId, 
        firstName: shift.firstName, 
        lastName: shift.lastName
      }, 
      store: { 
        _id: shift.storeId, 
        avatar: shift.avatar, 
        name: shift.name
      }
    }
  });

  return (
    <article className={'shift-page-container'}>
      {isTablet && <div className={'shift-header'}>
        <BackArrowButton onClick={handleReturn} disabled={isLoading}/>
        <div className={'logo-container'}>
          <img src={Logo} alt={'Logo'} onClick={BackToHome} className={'locumly-logo'}/>
        </div>
      </div>}
      {isLoading ? 
      <div className='loading'>
        <span>{'Loading...'}</span>
      </div>
      : Object.keys(shift).length > 0 ?
      <div className={'shift-page'}>
        <ShiftInfoCard ViewStorePage={ViewStorePage} shift={shift} isTablet={isTablet} requestedShiftList={requestedShiftList} HandleCart={HandleCart} cartShifts={cartShifts} messageStore={messageStore}/>
        <div className={'extra-info-container'} id={'extra-info'}>
          {isTablet 
          ?
          <>
          <div className={'info-content-container'}>
            <div className={'accordian'}>
              {infoQuestions.map((question, i) => 
                <Accordian 
                  question={question}
                  index={i}
                  network={NETWORKS[shift.type][shift.network]}
                  preferences={shift.preferences}
                  organization={shift.organization}
                  unpaidBreakTime={shift.unpaidBreakTime}
                  type={shift.type}
                  key={`${question} - i`}
                />
              )}
            </div>
          </div>
          <div className={'shift-rec-position'}>
            <ShiftRec requestedShiftList={requestedShiftList} shift={shift} state={state} searchParams={searchParams} HandleCart={HandleCart} navigate={navigate}/>
          </div>
          <StoreLocationMap shift={shift} />
          <div className={'faq-container'}>
            <div className={'faq-info'}>
              <div className={'faq-title'}>
                <span className={'faq-faq'}>{'FAQ'}</span>
                <span className={'faq-sub'}>{'Some things you might want to know before requesting!'}</span>
              </div>
              <div className={'faq-accordion'}>
                {FAQ.map((item, i) =>
                  <FAQDropDown
                    item={item}
                    index={i}
                    key={`${item.question} - ${i}`}
                  />
                )}
              </div>
            </div>
          </div>
          </> 
          :
          <>
            <div className={'shift-info-map'}>
              <div className={'info-content-container'}>
                <div className={'accordian'}>
                  {infoQuestions.map((question, i) => 
                    <Accordian 
                      question={question}
                      index={i}
                      network={NETWORKS[shift.type][shift.network]}
                      preferences={shift.preferences}
                      organization={shift.organization}
                      unpaidBreakTime={shift.unpaidBreakTime}
                      type={shift.type}
                      key={`${question} - i`}
                    />
                  )}
                </div>
              </div>
              <StoreLocationMap shift={shift} />
            </div>
            <div className={'shift-rec-faq'}>
              <div className={'shift-rec-position'}>
                <ShiftRec requestedShiftList={requestedShiftList} shift={shift} state={state} searchParams={searchParams} HandleCart={HandleCart} navigate={navigate}/>
              </div>
              <div className={'faq-container'}>
                <div className={'faq-info'}>
                  <div className={'faq-title'}>
                    <span className={'faq-faq'}>{'FAQ'}</span>
                    <span className={'faq-sub'}>{'Some things you might want to know before requesting!'}</span>
                  </div>
                  <div className={'faq-accordion'}>
                    {FAQ.map((item, i) =>
                      <FAQDropDown
                        item={item}
                        index={i}
                        key={`${item.question} - ${i}`}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
          }
        </div>
        {isTablet && 
        <div className={visible ? 'request-shift-container' : 'request-shift-container req-transform'}>
          {
          shift.deletionDate
          ?
          <>
            <button disabled className={'shift-button'}>{'This shift has been removed'}</button>
          </>
          :
            shift.status !== 'UNFILLED'
            ?
            <>
              <button disabled className={'shift-button'}>{'This shift has been filled'}</button>
            </>
            :
            requestedShiftList.includes(shift._id)
              ?
                <>
                  <button disabled className={'shift-button'}>{'Requested'}</button>
                  <button onClick={() => messageStore()} className={'shift-button message'}>{'Message'}</button>
                </>
              :
              <>
                <button onClick={(e) => HandleCart(e, shift, true)} className={'shift-button'}>{'Request Shift'}</button>
                <button onClick={(e) => HandleCart(e, shift, false)} className={'add-to-cart-button'}>
                  {cartShifts.hasOwnProperty(shift._id) ? 'Added' : 'Add to cart'}
                </button>
              </>
          }
        </div>
        }
      </div>
      :
      <div className={'something-wrong'}>
        <img className={'no-result-svg'} src={NoResult} alt={'no result found'} />
        <div className={'something-wrong-text'}>{"Sorry, we couldn't find this shift. Please try again later."}</div>
      </div>
      }
      {showStoreNotif && <ShowStoreNotif setShowStoreNotif={setShowStoreNotif} isShift={true} />}
    </article>
  )
}

export default ShiftPage;