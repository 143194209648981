import React, { useState, useRef } from 'react';
import { ASSET_CDN_URL } from '../../Constants';
import { useLocation } from 'react-router-dom';
import useAuth from '../../hooks/AuthHook.jsx';
import StorePage from './StorePage.jsx';
import { useOutsideClick } from '../../hooks/CommonHooks.jsx';
import { MILLISECONDS_IN_AN_HOUR, DAYS_OF_WEEK_KEY } from '../../Constants.js';
import DatePicker from 'react-datepicker';
import { getProfile, getShiftsForStore, postShifts } from '../../Api.js';
import 'react-datepicker/dist/react-datepicker.css';
import './AddShiftsPage.scss';
import { getTimezoneOffset } from 'date-fns-tz';
import { formatDatesWithTimeZone, formatDatesShort } from '../../../helpers/DateUtils.js';
import { NAVIGATION_OPTIONS } from '../BusinessConstants.js';
import { SHIFT_STATUSES } from '../../Constants.js';
import { SOFTWARES } from './ManagePreferencesPage.jsx';
import { LoadingButton } from '../../common/OnboardingPage.jsx';
import useStore from '../../hooks/StoreHook.jsx';

const HangTight = `${ASSET_CDN_URL}/graphics/hang-tight.png`;
const DropdownArrow = `${ASSET_CDN_URL}/dropdown/thin-black-dropdown-arrow.svg`;

const FIND_SHIFT_URL = 'https://www.locum.ly/find/shift?id=';

export const getTimeZoneDifference = (timeZoneOffset, offsetDate) => {
  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const currentTimeZoneOffsetInMillisecondsUTC = getTimezoneOffset(currentTimeZone, offsetDate);
  return timeZoneOffset - currentTimeZoneOffsetInMillisecondsUTC;
};

export const getOffsetTime = (time, timeZone) => {
  const offsetDate = new Date(time);
  const storeTimeZoneOffset = getTimezoneOffset(timeZone, offsetDate);
  return time - getTimeZoneDifference(storeTimeZoneOffset, offsetDate);
};

const ShareShiftPage = ({ shifts, store, addMoreShifts }) => {
  const { name, preferences, address, type } = store;
  const { interval, softwares } = preferences;
  const { postcode, timeZone } = address;
  const availableShifts = shifts 
    ? 
      shifts.map(shift => {
        const { startTime, endTime, _id, rate, negotiable, billToMedicare } = shift;
        const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(new Date(startTime), new Date(endTime), true, timeZone);
        const negotiableText = negotiable ? '(rate negotiable)' : '(rate fixed)';
        const negotiableTextDisplay = billToMedicare ? '' : negotiableText;
        const shiftUrl = `${FIND_SHIFT_URL}${_id}`;
        const rateDisplay = billToMedicare ? 'Bill directly to Medicare' : `$${(rate / 100).toFixed(2)}/hr`;
        return (
          <>
            <li className={'available-shift'} key={`shift-${_id}`}>
              {`• ${date}, ${startTimeDisplay} to ${endTimeDisplay}, ${rateDisplay} ${negotiableTextDisplay}`}
            </li>
            <li className={'booking-link'} key={`booking-${_id}`}>
              <span className={'booking-text'}>{`Book via: ${shiftUrl}`}</span>
            </li>
          </>
        );
      })
    : `No shifts added`;
  
  const copy = () => {
    const headerLine = `Hi all, we have shifts available at ${name}. If you're interested, please make a request via the links to each shift below:\n\n`;
    const availableShiftsText = shifts && shifts.length > 0
      ? 
        shifts.map(shift => {
          const { startTime, endTime, _id, rate, negotiable, billToMedicare } = shift;
          const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(new Date(startTime), new Date(endTime), true, timeZone);
          const negotiableText = negotiable ? '(rate negotiable)' : '(rate fixed)';
          const negotiableTextDisplay = billToMedicare ? '' : negotiableText;
          const shiftUrl = `${FIND_SHIFT_URL}${_id}`;
          const rateDisplay = billToMedicare ? 'Bill directly to Medicare' : `$${(rate / 100).toFixed(2)}/hr`;
          return `• ${date}, ${startTimeDisplay} to ${endTimeDisplay}, ${rateDisplay} ${negotiableTextDisplay}\nBook via: ${shiftUrl}`;
        })
        .join('\n')
      : '';
    const intervalText = (interval && interval > 0) ? `• ${interval} minute testing times` : '';
    const availableShiftsHeader = shifts && shifts.length > 0 ? 'Available shifts:\n' : '';
    const softwaresUsedText = softwares.length > 0 ? `• Softwares used: ${softwares.map(software => SOFTWARES[type][software].text).join(', ')}\n` : '';
    const storeDetailsText = `• ${name}, ${postcode}\n${intervalText}\n${softwaresUsedText}`;
    const storeDetailsHeader = 'Store details:\n';
    const copyText = `${headerLine}${availableShiftsHeader}${availableShiftsText}\n\n${storeDetailsHeader}${storeDetailsText}`;
    if (window.navigator) {
      navigator.clipboard.writeText(copyText).then(() => alert('Post copied')).catch(() => console.warn('Error copying text'));
    }
  };
  return (
    <section className={'share-shift-page'}>
      <h2 className={'share-title'}>{'Copy and share this post to any of your channels'}</h2>
      <section className={'share-shift-content'}>
        <p className={'paragraph'}>{`Hi all, we have shifts available at ${name}. If you're interested, please make a request via the links to each shift below:`}</p>
        { shifts && shifts.length > 0 && 
          <h3 className={'available-shifts-title'}>
            {'Available shifts:'}
          </h3>
        }
       { shifts && shifts.length > 0 && 
          <ul className={'available-shift-list'}>
            {availableShifts}
          </ul>
        }
        <h3 className={'store-details-title'}>
          {'Our store details:'}
        </h3>
        <ul className={'store-details-list'}>
          <li className={'store-detail'}>{`• ${name}, ${postcode}`}</li>
          {interval > 0 && <li className={'store-detail'}>{`• ${interval} minute testing times`}</li>}
          {softwares.length > 0 && <li className={'store-detail'}>{`• Softwares used: ${softwares.map(software => SOFTWARES[type][software].text).join(', ')}`}</li>}
        </ul>
        <section className={'buttons'}>
          <button className={'copy-button very-dark'} onClick={copy}>{'Copy this post'}</button>
          <button className={'return-button very-dark'} onClick={addMoreShifts}>{'Add more shifts'}</button>
        </section>
      </section>
    </section>
  );
}

const ConfirmationPage = ({ addMoreShifts, shareShifts }) => (
  <section className={'confirm-page'}>
    <img className={'hang-tight-image'} src={HangTight} alt={'Circus performer'} />
    <h3 className={'hang-tight-text'}>{'Hang Tight'}</h3>
    <p className={'notify-text'}>{'We will notify you when you receive applicants'}</p>
    <button className={'share-shifts-button very-dark'} onClick={shareShifts}>{'Share my shifts'}</button>
    <button className={'add-another-shift very-dark'} onClick={addMoreShifts}>{'Add more shifts'}</button>
  </section>
);

export const YesNoDropdown = ({ selected, setSelected, disabled }) => {
  const [open, setOpen] = useState(false);
  const toggleDropdown = () => setOpen(!open);
  const dropdownRef = useRef(null);
  useOutsideClick(dropdownRef, () => setOpen(false));
  const yesClick = () =>  {
    setSelected(true);
    toggleDropdown();
  };
  const noClick = () => {
    setSelected(false);
    toggleDropdown();
  };
  const disabledText = 'NA';
  const selectedText = selected ? 'Yes' : 'No';
  return (
    <section className={'dropdown-button yes-no'} ref={dropdownRef}>
      <button className={`data-view-button ${open ? 'open' : ''} ${disabled ? 'disabled' : ''}`} disabled={disabled} onClick={toggleDropdown}>
        {disabled ? disabledText : selectedText}
      </button>
      {open &&
        <section className={'dropdown-list-container'}>
          <ul className={'dropdown-list yes-no'}>
            <li className={'dropdown-item'}>
              <button className={'dropdown-option very-light'} onClick={yesClick}>
                {'Yes'}
              </button>
            </li>
            <li className={'dropdown-item'}>
              <button className={'dropdown-option very-light'} onClick={noClick}>
                {'No'}
              </button>
            </li>
          </ul>
        </section>
      }
    </section>
  );
}

const generate24HourSelection = (startTimeOffset, endTimeOffset) => {
  const selections = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let half = 0; half < 2; half++) {
      const minutes = half === 0 ? '00' : `${half * 30}`;
      let hours;
      if (hour === 12 || hour === 0) {
        hours = 12;
      }
      else {
        hours = hour % 12 < 10 ? `0${hour % 12}` : `${hour % 12}`;
      }
      const timeOfDay = hour >= 12 ? 'PM' : 'AM';
      const text = `${hours}:${minutes} ${timeOfDay}`;
      const millisecondsInDay = hour * MILLISECONDS_IN_AN_HOUR + half * 30 * 60 * 1000;
      if (startTimeOffset !== null && startTimeOffset > millisecondsInDay) {
        continue;
      }
      if (endTimeOffset !== null && endTimeOffset < millisecondsInDay) {
        continue;
      }
      selections.push({ key: millisecondsInDay, text }); 
    }
  }
  return selections;
};


export const DateTimeDropdown = ({ selected, setSelected, endTimeOffset = null , startTimeOffset = null }) => {
  const daySelections = generate24HourSelection(startTimeOffset, endTimeOffset);
  const [open, setOpen] = useState(false);
  const toggleDropdown = () => setOpen(!open);
  const dropdownRef = useRef(null);
  useOutsideClick(dropdownRef, () => setOpen(false));
  const dropdownList = daySelections.map(({ key, text }) => {
    const onSelection = () => {
      setSelected(key);
      toggleDropdown();
    }
    return (
      <li className={'dropdown-item'} key={`dropdown-time-${key}`}>
        <button className={'dropdown-option very-light'} onClick={onSelection}>
          {text}
        </button>
      </li>
    );
  });
  return (
    <section className={'dropdown-button date-time'} ref={dropdownRef}>
      <button className={`dropdown-view-button ${open ? 'open' : ''}`} onClick={toggleDropdown}>
        {daySelections.find(selection => selection.key === selected).text}
        <img src={DropdownArrow} alt={'Dropdown arrow'} />
      </button>
      { open && 
          <section className={'dropdown-list-container'}>
            <ul className={'dropdown-list'}>
              {dropdownList}
            </ul>
          </section>
      }
    </section>
  )
}

export const TimeDropdown = ({ selected, setSelected, intervalSize, maxSize, isDateTime = false }) => {
  const numIncrements = Math.round(maxSize / intervalSize) + 1;
  const times = Array(numIncrements).fill().map((_, i) => i * intervalSize);
  const [open, setOpen] = useState(false);
  const toggleDropdown = () => setOpen(!open);
  const dropdownRef = useRef(null);
  useOutsideClick(dropdownRef, () => setOpen(false));
  const dropdownList = times.map((time) => {
    const onSelection = () => {
      setSelected(time);
      toggleDropdown();
    };
    return (
      <li className={'dropdown-item'} key={`time-dropdown-${time}`}>
        <button className={`dropdown-option very-light`} onClick={onSelection}>
          {`${time} mins`}
        </button>
      </li>
    );
  });
  return (
    <section className={`dropdown-button ${isDateTime ? 'date-time' : 'time'}`} ref={dropdownRef}>
      <button className={`${isDateTime ? 'dropdown-view-button' : 'data-view-button' } ${open ? 'open' : ''}`} onClick={toggleDropdown}>
        {`${selected} mins`}
      </button>
      {open &&
        <section className={'dropdown-list-container'}>
          <ul className={'dropdown-list'}>
            {dropdownList}
          </ul>
        </section>
      }
    </section>
  );
};

export const FLOAT_REGEX = /^[+-]?\d*(\.\d{0,2})?$/;
export const MAXIMUM_RATE = 1000;


const DEFAULT_SHIFT_TEMPLATE = {
  START_TIME: 9,
  END_TIME: 17,
  UNPAID_BREAK_IN_MINUTES: 30,
  RATE: 80.00,
}

const ViewShiftCard = ({ shift, select, selected }) => {
  const { rate, startTime, endTime, unpaidBreakTime, negotiable } = shift;
  const { date, startTimeDisplay, endTimeDisplay } = formatDatesShort(new Date(startTime), new Date(endTime));
  const duration = ((endTime - startTime) / MILLISECONDS_IN_AN_HOUR).toFixed(1);
  const rateDisplay = parseFloat(rate).toFixed(2);
  const total = ((rate * (duration - unpaidBreakTime / 60))).toFixed(2);
  const onChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    select(shift);
  }
  return (
    <button className={`view-shift-card ${selected ? 'selected' : ''}`} onClick={() => select(shift)}>
      <h4 className={'date-title'}>{date}</h4>
      <section className={'time-and-rate'}>
        <span className={'time'}>{`${startTimeDisplay} - ${endTimeDisplay}`}</span>
        <span className={'rate'}>{`$${rateDisplay}/hr`}</span>
      </section>
      <section className={'duration'}>
        {`${duration} hours`}
      </section>
      <section className={'unpaid-break'}>
        <span className={'label'}>{'Unpaid break'}</span>
        <span className={'amount'}>{`${unpaidBreakTime} minutes`}</span>
      </section>
      <section className={'negotiable-and-total'}>
        <section className={'rate-negotiable'}>
          <span className={'label'}>{'Rate negotiable'}</span>
          <input type={'checkbox'} checked={negotiable} onChange={onChange} />
        </section>
        <section className={'total'}>{`$${total}`}</section>
      </section>
    </button>
  );
}

const EditShiftCard = ({ selectedDates, shiftModel, updateSelectedShifts }) => {
  const rateRef = useRef(null);
  const { unpaidBreakTime, startTimeOffset, endTimeOffset, rate, negotiable } = shiftModel;
  const selectBreakTime = (breakTime) => {
    const newShiftModel = { ...shiftModel, unpaidBreakTime: breakTime };
    updateSelectedShifts(newShiftModel);
  };
  const setStartTime = (timeOffset) => {
    const duration = shiftModel.endTimeOffset - shiftModel.startTimeOffset;
    const unpaidBreakTime = duration <= shiftModel.unpaidBreakTime * 60 * 1000 ? 0 : shiftModel.unpaidBreakTime;
    const newShiftModel = { ...shiftModel, startTimeOffset: timeOffset, unpaidBreakTime };
    updateSelectedShifts(newShiftModel);
  };
  const setEndTime = (timeOffset) => {
    const duration = shiftModel.endTimeOffset - shiftModel.startTimeOffset;
    const unpaidBreakTime = duration <= shiftModel.unpaidBreakTime * 60 * 1000 ? 0 : shiftModel.unpaidBreakTime;
    const newShiftModel = { ...shiftModel, endTimeOffset: timeOffset, unpaidBreakTime };
    updateSelectedShifts(newShiftModel);
  };
  const setRate = (e) => {
    if (e.target.value === "" || (FLOAT_REGEX.test(e.target.value) && parseFloat(e.target.value) <= MAXIMUM_RATE)) {
      const rate = e.target.value;
      const newShiftModel = { ...shiftModel, rate };
      updateSelectedShifts(newShiftModel);
    }
  }
  const setNegotiable = () => {
    const newShiftModel = { ...shiftModel, negotiable: !negotiable };
    updateSelectedShifts(newShiftModel);
  }
  const duration = ((endTimeOffset - startTimeOffset) / MILLISECONDS_IN_AN_HOUR).toFixed(1);
  const total = ((rate * (duration - unpaidBreakTime / 60))).toFixed(2);
  const selectedDatesText = selectedDates
    .sort((e1, e2) => e1.getTime() - e2.getTime())
    .map(shiftDate => {
      const { date } = formatDatesShort(shiftDate, shiftDate);
      return <span className={'selected-date-header'}>{date}</span>;
    });
  return (
    <section className={'edit-shift-card'}>
      <section className={'selected-dates-title'}>{selectedDatesText}</section>
      <section className={'time-and-rate'}>
        <section className={'time-from'}>
          <span className={'label'}>{'From'}</span>
          <DateTimeDropdown selected={startTimeOffset} setSelected={setStartTime} endTimeOffset={endTimeOffset} />
        </section>
        <section className={'time-to'}>
          <span className={'label'}>{'To'}</span>
          <DateTimeDropdown selected={endTimeOffset} setSelected={setEndTime} startTimeOffset={startTimeOffset} />
        </section>
        <section className={'rate'}>
          <span className={'label'}>{'Rate per hr'}</span>
          <span className={'rate-input-container'}>
            <span className={'currency-sign'}>{'$'}</span>
            <input
              ref={rateRef}
              onClick={() => rateRef.current.focus()}
              className={'rate-input'}
              type={'text'}
              placeholder={'0.00'}
              value={rate}
              onChange={setRate}
            />
          </span>
        </section>
      </section>
      <section className={'duration-and-total'}>
        <span className={'duration'}>{`${duration} hours`}</span>
        <span className={'total'}>{`$${total}`}</span>
      </section>
      <section className={'unpaid-break'}>
        <span className={'label'}>{'Unpaid break'}</span>
        <TimeDropdown selected={unpaidBreakTime} setSelected={selectBreakTime} intervalSize={30} maxSize={Math.min(duration * 60, 120)} isDateTime={true} />
      </section>
      <section className={'negotiable'}>
        <span className={'label'}>{'Rate negotiable'}</span>
        <input type={'checkbox'} checked={negotiable} onChange={setNegotiable} />
      </section>
    </section>
  );
}

const EditShiftsSection = ({ selectedDates, shiftModel, updateSelectedShifts }) => {
  const editShiftCardProps = { selectedDates, shiftModel, updateSelectedShifts };
  return (
    <section className={'edit-shifts-container'}>
      <h3 className={'title'}>{'Edit selected shift(s) details'}</h3>
      <section className={'edit-shifts-card-container'}>
        {selectedDates.length > 0 && shiftModel !== null && <EditShiftCard {...editShiftCardProps} />}
      </section>
    </section>
  );
}

const ViewShiftsSection = ({ shifts, selectShift, selectedDates }) => {
  const shiftCards = shifts.map(shift => {
    const selected = selectedDates.filter(date => date === shift.date).length > 0;
    return <ViewShiftCard shift={shift} select={selectShift} selected={selected} />;
  });
  return (
    <section className={'view-shifts-container'}>
      <h3 className={'title'}>{'Click to select/deselect shift details to edit'}</h3>
      <section className={'shift-cards'}>{shiftCards}</section>
    </section>
  )
}

const ViewAndEditShiftsSection = ({ shifts, selectShift, selectedDates, shiftModel, updateSelectedShifts }) => {
  return (
    <section className={'view-and-edit-container'}>
      { shifts.length > 0 && <ViewShiftsSection shifts={shifts} selectShift={selectShift} selectedDates={selectedDates} /> }
      { shifts.length > 0 && selectedDates.length > 0 && shiftModel !== null &&
        <EditShiftsSection selectedDates={selectedDates} shiftModel={shiftModel} updateSelectedShifts={updateSelectedShifts} />
      }
    </section>
  )
}

export const AddDatesSection = ({ onSelectDate, dates, readOnly }) => {
  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 1);

  // TODO: Add DatePicker timezone here to adjust all values to current time in store location; get offset from local to store location
  return (
    <section className={`add-dates-container ${readOnly ? 'read-only' : ''}`}>
      <h3 className={'title'}>{readOnly ? 'Please answer a few extra questions' : 'Select your shift dates'}</h3>
      <DatePicker
        minDate={minDate}
        selected={null}
        onChange={onSelectDate}
        dateFormat={'dd/MM/yyyy'}
        highlightDates={dates}
        formatWeekDay={nameOfDay => nameOfDay.substr(0, 3)}
        readOnly={readOnly}
        inline
      />
    </section>
  )
}

// TODO: set default schedule here from store.schedule
// TODO: Get the current day of the week for a store's particular timezone - return the timezone from backend
const createNewShift = (date, weekSchedule) => {
  const { UNPAID_BREAK_IN_MINUTES, RATE, START_TIME, END_TIME } = DEFAULT_SHIFT_TEMPLATE;
  const dayOfWeek = DAYS_OF_WEEK_KEY[(date.getDay() + 6) % 7].key;
  const schedule = weekSchedule && weekSchedule[dayOfWeek] ? weekSchedule[dayOfWeek] : null;
  const startTime = !schedule ? date.getTime() + START_TIME * MILLISECONDS_IN_AN_HOUR : date.getTime() + schedule.startTime;
  const endTime = !schedule ? date.getTime() + END_TIME * MILLISECONDS_IN_AN_HOUR : date.getTime() + schedule.endTime;
  const unpaidBreakTime = !schedule ? UNPAID_BREAK_IN_MINUTES : schedule.unpaidBreakTime;
  const rate = !schedule ? RATE.toFixed(2) : schedule.rate;
  const negotiable = !schedule ? true : schedule.negotiable;
  return { date, startTime, endTime, rate, unpaidBreakTime, negotiable };
}

export const Dropdown = ({ selected, setSelected, options, additionalClassNames = [], disabled = false }) => {
  const [open, setOpen] = useState(false);
  const toggleDropdown = () => setOpen(!open);
  const dropdownRef = useRef(null);
  useOutsideClick(dropdownRef, () => setOpen(false));
  const dropdownList = options.map(({ key, text }) => {
    const onSelection = () => {
      setSelected(key);
      toggleDropdown();
    }
    return (
      <li className={'dropdown-item'} key={`dropdown-time-${key}`}>
        <button className={'dropdown-option very-light'} onClick={onSelection} disabled={disabled}>
          {text}
        </button>
      </li>
    );
  });
  return (
    <section className={`dropdown-button ${additionalClassNames.join(' ')}`} ref={dropdownRef}>
      <button className={`dropdown-view-button ${open ? 'open' : ''}`} onClick={toggleDropdown} disabled={disabled}>
        {options.find(selection => `${selection.key}` === `${selected}`).text}
        <img src={DropdownArrow} alt={'Dropdown arrow'} />
      </button>
      { open && 
          <section className={'dropdown-list-container'}>
            <ul className={'dropdown-list'}>
              {dropdownList}
            </ul>
          </section>
      }
    </section>
  )
}

const AdditionalDetailCard = ({ questionText, options, selected, setSelected }) => {
  return (
    <section className={'additional-details-card'}>
      <span className={'question-text'}>{questionText}</span>
      <Dropdown selected={selected} setSelected={setSelected} options={options} additionalClassNames={['additional-questions']} />
    </section>
  )
}

const SAME_LOCUM_OPTIONS = [{ key: 'yes', text: 'Yes' }, { key: 'no', text: 'No' }];
const BILL_TO_MEDICARE_OPTIONS = [{ key: 'yes', text: 'Yes' }, { key: 'no', text: 'No' }];
const ACCOMMODATION_AND_TRAVEL_OPTIONS = [
  { key: 'accommodation_and_travel', text: 'Both accommodation and travel' },
  { key: 'accommodation_only', text: 'Accommodation only' },
  { key: 'travel_only', text: 'Travel only'},
  { key: 'none', text: 'None' }
];

const AdditionalShiftDetailsSection = ({ sameLocumKey, accommodationAndTravelKey, billToMedicareKey, setBillToMedicare, setSameLocum, setAccommodationAndTravel }) => {
  const sameLocumQuestion = 'Do you prefer that the same Locum works for multiple days?';
  const billToMedicareQuestion = 'Does the Locum directly collect their fee from Medicare?';
  const accommodationQuestion = 'Do you offer travel and/or accommodation?';
  return (
    <section className={'additional-details-section'}>
      <h3 className={'title'}>{'Other Preferences'}</h3>
      <AdditionalDetailCard
        questionText={sameLocumQuestion}
        options={SAME_LOCUM_OPTIONS}
        selected={sameLocumKey}
        setSelected={setSameLocum}
      />
      <AdditionalDetailCard
        questionText={accommodationQuestion}
        options={ACCOMMODATION_AND_TRAVEL_OPTIONS}
        selected={accommodationAndTravelKey}
        setSelected={setAccommodationAndTravel}
      />
      <AdditionalDetailCard
        questionText={billToMedicareQuestion}
        options={BILL_TO_MEDICARE_OPTIONS}
        selected={billToMedicareKey}
        setSelected={setBillToMedicare}
      />
    </section>
  )
}

const AddShiftsPage = () => {
  const { state } = useLocation();
  const { profile } = useAuth();
  const store = profile.stores.find((store) => state && store._id === state.storeId) || profile.stores[0];
  const [shifts, setShifts] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [shiftModel, setShiftModel] = useState(null);
  const [billToMedicare, setBillToMedicare] = useState(false);
  const [sameLocum, setSameLocum] = useState(false);
  const [accommodation, setAccommodation] = useState(false);
  const [travel, setTravel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editState, setEditState] = useState('SELECTING');
  const onSelectDate = (selectedDate) => {
    if (editState !== 'SELECTING') {
      return;
    }
    let newShifts = [...shifts].filter(({ date }) => selectedDate.getTime() !== date.getTime());
    let newDates = [...selectedDates].filter(date => date.getTime() !== selectedDate.getTime());
    if (newShifts.length !== shifts.length) {
      newShifts = newShifts.sort((e1, e2) => e1.date.getTime() - e2.date.getTime());
      setShifts(newShifts);
      setSelectedDates(newDates);
    }
    else {
      // TODO Add in schedule here as well as well as timezone
      const newShift = createNewShift(selectedDate, store.schedule);
      newShifts.push(newShift);
      newShifts = newShifts.sort((e1, e2) => e1.date.getTime() - e2.date.getTime());
      setShifts(newShifts);
      setSelectedDates(newDates);
    }
    if (newShifts.length === 0) {
      setShiftModel(null);
    }
  };
  const selectShift = (shift) => {
    const isSelected = selectedDates.filter(date => shift.date === date).length > 0;
    const newSelectedDates = isSelected ? selectedDates.filter(date => shift.date.getTime() !== date.getTime()) : [...selectedDates, shift.date];
    setSelectedDates(newSelectedDates);
    if (shiftModel === null) {
      const startTimeOffset = shift.startTime - shift.date.getTime();
      const endTimeOffset = shift.endTime - shift.date.getTime();
      const newShiftModel = { startTimeOffset, endTimeOffset, rate: shift.rate, unpaidBreakTime: shift.unpaidBreakTime, negotiable: shift.negotiable };
      setShiftModel(newShiftModel);
    }
    else {
      const { endTimeOffset, startTimeOffset, unpaidBreakTime, rate, negotiable } = shiftModel;
      let newShifts = [...shifts].filter(({ date }) => shift.date.getTime() !== date.getTime());
      const startTime = shift.date.getTime() + startTimeOffset;
      const endTime = shift.date.getTime() + endTimeOffset;
      newShifts.push({ ...shift, startTime, endTime, rate, unpaidBreakTime, negotiable })
      newShifts = newShifts.sort((e1, e2) => e1.date.getTime() - e2.date.getTime());
      setShifts(newShifts);
    }
  }

  const updateSelectedShifts = (newShiftModel) => {
    const { endTimeOffset, startTimeOffset, unpaidBreakTime, rate, negotiable } = newShiftModel;
    const notUpdatedShifts = [...shifts]
      .filter(shift => selectedDates.filter(date => date.getTime() === shift.date.getTime()).length === 0);
    const updatedShifts = [...shifts]
      .filter(shift => selectedDates.filter(date => date.getTime() === shift.date.getTime()).length > 0)
      .map(shift => ({ ...shift, startTime: shift.date.getTime() + startTimeOffset, endTime: shift.date.getTime() + endTimeOffset, rate, unpaidBreakTime, negotiable }));
    const newShifts = [ ...notUpdatedShifts, ...updatedShifts ].sort((e1, e2) => e1.date.getTime() - e2.date.getTime());
    setShifts(newShifts);
    setShiftModel(newShiftModel);
  }
  const storeContext = useStore();

  const confirm = () => {
    const shiftList = [];
    setLoading(true);
    for (let i = 0; i < shifts.length; i++) {
      const shift = shifts[i];
      const duration = (shift.endTime - shift.startTime) / MILLISECONDS_IN_AN_HOUR;
      const total = Math.round((duration - shift.unpaidBreakTime / 60) * shift.rate) * 100;
      const storeOffsetStartTime = getOffsetTime(shift.startTime, store.address.timeZone);
      const storeOffsetEndTime = getOffsetTime(shift.endTime, store.address.timeZone);
      const shiftData = {
        storeId: store._id,
        startTime: storeOffsetStartTime,
        endTime: storeOffsetEndTime,
        unpaidBreakTime: Math.round((shift.unpaidBreakTime / 60) * MILLISECONDS_IN_AN_HOUR),
        billToMedicare,
        negotiable: shift.negotiable,
        total,
        type: store.type,
        rate: shift.rate * 100,
        status: SHIFT_STATUSES.UNFILLED.key,
        applicants: [],
        travel,
        accommodation,
        sameLocum,
      };
      shiftList.push(shiftData);
    }
    if (shiftList.length === 0) {
      return;
    }
    postShifts(shiftList, store._id).then(({ shifts }) => {
      setShifts(shifts);
      getShiftsForStore(store._id).then(({ shifts }) => {
        storeContext.setShifts(shifts);
        setEditState('POSTED');
        setLoading(false);
      });
    });
  };

  const onNext = () => {
    if (editState === 'SELECTING') {
      setEditState('SELECTED');
    }
    else if (editState === 'SELECTED') {
      confirm();
    }
  }
  const onCancel = () => {
    if (editState === 'SELECTING' || editState === 'SHARING' || editState === 'POSTED') {
      setSelectedDates([]);
      setShiftModel(null);
      setShifts([]);
      setEditState('SELECTING');
    }
    else if (editState === 'SELECTED') {
      setEditState('SELECTING');
    }
  }

  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 1);
  let accommodationAndTravelKey = 'none';
  if (travel && accommodation) {
    accommodationAndTravelKey = 'accommodation_and_travel';
  }
  else if (travel) {
    accommodationAndTravelKey = 'travel_only';
  }
  else if (accommodation) {
    accommodationAndTravelKey = 'accommodation_only';
  }
  const viewAndEditProps = { shifts, selectShift, selectedDates, shiftModel, updateSelectedShifts };
  const additionalShiftDetailsProps = {
    sameLocumKey: sameLocum ? 'yes' : 'no',
    billToMedicareKey: billToMedicare ? 'yes' : 'no',
    accommodationAndTravelKey,
    setBillToMedicare: (key) => setBillToMedicare(key === 'yes'),
    setSameLocum: (key) => setSameLocum(key === 'yes'),
    setAccommodationAndTravel: (key) => {
      if (key === 'accommodation_and_travel') {
        setTravel(true);
        setAccommodation(true);
      }
      else if (key === 'travel_only') {
        setTravel(true);
        setAccommodation(false);
      }
      else if (key === 'accommodation_only') {
        setTravel(false);
        setAccommodation(true);
      }
      else {
        setTravel(false);
        setAccommodation(false);
      }
    }
  }
  return (
    <StorePage profile={profile} mode={NAVIGATION_OPTIONS.SHIFT.mode}>
      <article className={'add-shifts-page'}>
        <h1 className={'add-shifts-title'}>{'Add Shifts'}</h1>
        {
          !(editState === 'POSTED' || editState === 'SHARING') && (
            <>
              <section className={'post-shifts-container'}>
                <AddDatesSection onSelectDate={onSelectDate} dates={shifts.map(({ date }) => date)} readOnly={editState !== 'SELECTING'} />
                { editState === 'SELECTING' && <ViewAndEditShiftsSection {...viewAndEditProps} /> }
                { editState === 'SELECTED' && <AdditionalShiftDetailsSection {...additionalShiftDetailsProps} />}
              </section>
              <section className={'buttons'}>
                <LoadingButton
                  disabled={shifts.length === 0}
                  loading={loading}
                  onClick={onNext}
                  text={editState === 'SELECTING' ? 'Next' : 'Post Shifts'}
                  classNames={['continue-button', 'very-dark']}
                />
                <button className={'cancel-button very-light'} onClick={onCancel}>
                  {editState === 'SELECTING' ? 'Reset' : 'Back'}
                </button>
              </section>
            </>
          )
        }
        { editState === 'POSTED' && <ConfirmationPage addMoreShifts={onCancel} shareShifts={() => setEditState('SHARING')} /> }
        { editState === 'SHARING' && <ShareShiftPage store={store} shifts={shifts} addMoreShifts={onCancel} />}
      </article>
    </StorePage>
  );
};

export default AddShiftsPage;