export const JOB_OPTIONS = {
  OPTOMETRY: {
    OPTOMETRIST: {
      key: 'OPTOMETRIST',
      displayText: 'Optometrist',
    },
    OPTICAL_DISPENSER: {
      key: 'OPTICAL_DISPENSER',
      displayText: 'Optical Dispenser',
    },
    OPTICAL_OR_RETAIL_ASSISTANT: {
      key: 'OPTICAL_OR_RETAIL_ASSISTANT',
      displayText: 'Optical/Retail Assistant',
    },
    RETAIL_MANAGER: {
      key: 'RETAIL_MANAGER',
      displayText: 'Retail Manager',
    },
    OTHER: {
      key: 'OTHER',
      displayText: 'Other',
    },
  }
};

const {
  OPTOMETRIST, OPTICAL_DISPENSER, OPTICAL_OR_RETAIL_ASSISTANT, RETAIL_MANAGER, OTHER
} = JOB_OPTIONS.OPTOMETRY;

export const JOB_OPTIONS_ORDER = [
  OPTOMETRIST, OPTICAL_DISPENSER, OPTICAL_OR_RETAIL_ASSISTANT, RETAIL_MANAGER, OTHER
];