import './StoreProfileDropdownButton.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { ASSET_CDN_URL } from '../../../../Constants';

const DropdownArrow = `${ASSET_CDN_URL}/dropdown/thick-black-dropdown-arrow.svg`;

const propTypes = {
  /* On click callback. */
  onClick: PropTypes.func.isRequired,

  /* True if the dropdown is open. */
  open: PropTypes.bool.isRequired,

  /* Button text to display. */
  buttonText: PropTypes.string.isRequired,
}

function StoreProfileDropdownButton({ onClick, buttonText, open }) {
  return (
    <button className={'store-profile-dropdown-button'} type={'button'} onClick={onClick}>
      <span className={'button-text'}>{buttonText}</span>
      <section className={'dropdown-arrow-container'}>
        <img
          className={`dropdown-arrow ${open ? 'open' : ''}`}
          src={DropdownArrow}
          alt={'Dropdown arrow'}
        />
      </section>
    </button>
  );
}

StoreProfileDropdownButton.displayName = 'StoreProfileDropdownButton';
StoreProfileDropdownButton.propTypes = propTypes;
export default StoreProfileDropdownButton;