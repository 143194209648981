import PropTypes from 'prop-types';
import { EMPLOYMENT_STATUS, LENGTH_OF_WORK } from './profile/modals/review/ReviewConstants';

export const COORDINATES_PROPS = {
  /* Longitude. */
  longitude: PropTypes.number.isRequired,

  /* Latitude. */
  latitude: PropTypes.number.isRequired,
};

export const ADDRESS_PROPS = {
  /* Street of the store. */
  street: PropTypes.string.isRequired,

  /* Suburb of the store. */
  suburb: PropTypes.string.isRequired,
  
  /* City of the store. */
  city: PropTypes.string.isRequired,
  
  /* State of the store. */
  state: PropTypes.string.isRequired,
  
  /* Postcode of the store. */
  postcode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  
  /* Country of the store. */
  country: PropTypes.string.isRequired,
}

export const STORE_REVIEW_PROPS = {
  /* Store id. */
  storeId: PropTypes.string.isRequired,

  /* Reviewer title. */
  employmentStatus: PropTypes.oneOf(Object.keys(EMPLOYMENT_STATUS)).isRequired,

  /* Duration worked by reviewer. */
  lengthOfWork: PropTypes.oneOf(Object.keys(LENGTH_OF_WORK)).isRequired,

  /* True if reviewer is verified. */
  verified: PropTypes.bool.isRequired,

  /* Pros of the store. */
  pros: PropTypes.string.isRequired,

  /* Would recommend the store to a friend. */
  wouldRecommend: PropTypes.bool.isRequired,

  /* Cons of the store. */
  cons: PropTypes.string.isRequired,

  /* Avatar url for the reviwer. */
  avatar: PropTypes.string,
};

export const PERMANENT_SHIFT_PROPS = {
  /* Job title. */
  jobTitle: PropTypes.string.isRequired,

  /* Role type. */
  roleType: PropTypes.string.isRequired,

  /* Salary range. */
  salaryRange: PropTypes.string,

  /* Location name. */
  locationName: PropTypes.string.isRequired,

  /* Summary text. */
  summaryText: PropTypes.string.isRequired,

  /* Seek url. */
  seekUrl: PropTypes.string.isRequired,
}

export const CASUAL_SHIFT_PROPS = {
  /* Shift id. */
  _id: PropTypes.string.isRequired,

  /* Start time of the shift. */
  startTime: PropTypes.number.isRequired,

  /* End time of the shift. */
  endTime: PropTypes.number.isRequired,

  /* Rate of the shift in cents per hour. */
  rate: PropTypes.number.isRequired,

  /* True if billing to medicare. */
  billToMedicare: PropTypes.bool.isRequired,

  /* Total value of the shift. */
  total: PropTypes.number.isRequired,
};

export const STORE_OFFERING_PROPS = {
  /* Text to display. */
  text: PropTypes.string.isRequired,

  /* Description of the offering. */
  description: PropTypes.string.isRequired,
}

export const STORE_PROFILE_PROPS = {
  /* Photos of the store. */
  storePhotos: PropTypes.arrayOf(PropTypes.string).isRequired,

  /* Store reviews. */
  storeReviews: PropTypes.arrayOf(PropTypes.shape(STORE_REVIEW_PROPS)).isRequired,

  /* Permanent jobs. */
  permanentShifts: PropTypes.arrayOf(PropTypes.shape(PERMANENT_SHIFT_PROPS)).isRequired,

  /* Casual shifts. */
  casualShifts: PropTypes.arrayOf(PropTypes.shape(CASUAL_SHIFT_PROPS)).isRequired,

  /* Store offerings. */
  storeOfferings: PropTypes.arrayOf(PropTypes.shape(STORE_OFFERING_PROPS)).isRequired,

  /* Store name. */
  storeName: PropTypes.string.isRequired,

  /* Words from the admin. */
  wordsFromAdmin: PropTypes.string.isRequired,

  /* Coordinates of the store. */
  coordinates: PropTypes.shape(COORDINATES_PROPS).isRequired,

  /* Address of the store. */
  address: PropTypes.shape(ADDRESS_PROPS).isRequired,

  /* Seek url for the store. */
  seekUrl: PropTypes.string,

  /* Glassdoor url for the store. */
  glassdoorUrl: PropTypes.string,
}
