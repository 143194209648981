import './CopyLinkButton.scss';

import React, { useState } from 'react';

import useStoreProfile from '../../../../hooks/StoreProfileContext';

function CopyLinkButton() {
  const { storeProfile } = useStoreProfile();
  const { theme, storeUrl } = storeProfile;
  const { primaryHexColor } = theme;
  const [isCopied, setIsCopied] = useState(false);
  const linkText = isCopied ? 'Link copied!' : 'Copy';
  const copy = () => {
    navigator.clipboard.writeText(window.location.href);
    setIsCopied(true);
  };
  const plausibleLink = `plausible-event-link=${storeUrl}`;
  const plausibleEventClass = `plausible-event-name=Copy+Link+Button+Click ${plausibleLink}`;
  const disabledClass = isCopied ? 'disabled' : 'very-dark';
  return (
    <section className={'share-store-button-container'}>
      <section className={'logo-and-link'}>
        <svg
          className={'locumly-logo-icon'}
          width={'28'}
          height={'20'}
          viewBox={'0 0 28 20'}
          fill={'none'}
          xmlns={'http://www.w3.org/2000/svg'}
        >
          <path
            d={'M26.9874 8.92222H16.6806C17.052 8.33975 17.3106 7.8002 17.4168 7.33422C17.43 7.26678 17.4433 7.20547 17.4566 7.13803H21.184C21.9069 7.13803 22.5901 6.65365 22.7227 6.05892C22.736 5.99148 22.7426 5.96083 22.7625 5.88725C22.8952 5.29252 22.4375 4.80815 21.7146 4.80815H17.3969C17.3637 4.67326 17.2974 4.48932 17.2244 4.31765C17.2111 4.28086 17.1979 4.25021 17.1846 4.21342C17.0984 3.99882 17.0188 3.82102 17.0188 3.82102C16.926 3.63708 16.8198 3.45927 16.7071 3.29373C16.6142 3.15884 16.5147 3.02396 16.402 2.90133C16.4551 2.95651 16.5214 3.04848 16.5811 3.12206C16.4749 2.98104 16.3224 2.78484 16.2163 2.66221H21.0447C21.7875 2.66221 22.4839 2.17784 22.6166 1.58311C22.6299 1.51567 22.6365 1.47888 22.6564 1.41144C22.7957 0.816706 22.3181 0.332336 21.5753 0.332336C18.1662 0.332336 10.5124 0.258762 10.1078 0.252631C5.68397 0.142269 1.7244 3.52059 0.91524 7.2116C0.0331255 11.2337 2.85192 16.0774 5.35898 19.4557C5.4651 19.6029 5.53805 19.6948 5.56458 19.7316C5.57122 19.7439 5.57785 19.75 5.57785 19.75C5.57785 19.75 5.58448 19.7439 5.59775 19.7316H13.8618C14.6311 19.7316 15.3607 19.2472 15.4933 18.6525C15.5066 18.5851 15.5133 18.5544 15.5331 18.4808C15.6724 17.8861 15.1617 17.4017 14.3924 17.4017H8.37674C8.96039 16.899 9.60374 16.3472 10.267 15.7524H17.8346C18.5774 15.7524 19.2805 15.2681 19.4131 14.6672C19.4264 14.5998 19.433 14.5691 19.4529 14.4955C19.5922 13.9008 19.1147 13.4164 18.3652 13.4164H12.7807C13.5169 12.6991 14.2266 11.9694 14.8633 11.2644H26.4568C27.0205 11.2644 27.5445 10.78 27.6771 10.1853C27.6904 10.1178 27.697 10.0872 27.7169 10.0136C27.8562 9.41885 27.5445 8.93448 26.9874 8.93448M12.396 8.73215H9.94862C9.80271 9.36979 9.6568 10.0074 9.51752 10.639H5.86968C6.01559 10.0013 6.1615 9.36366 6.30079 8.73215H4.19167C4.4636 7.53655 4.59625 6.93569 4.87481 5.7401H6.98393C7.12984 5.10245 7.27575 4.4648 7.41504 3.83328H11.0629C10.917 4.47093 10.7711 5.10858 10.6318 5.7401H13.0791C12.8072 6.93569 12.6679 7.53655 12.396 8.73215Z'}
            fill={primaryHexColor}
          />
        </svg>
        <span className={'link'}>{window.location.href}</span>
      </section>
      <button
        className={`copy-link-button ${disabledClass} ${plausibleEventClass}`}
        onClick={copy}
        type={'button'}
        disabled={isCopied}
        style={{ color: primaryHexColor }}
      >
        {linkText}
      </button>
    </section>
  )
}

export default CopyLinkButton;