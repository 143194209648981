import React from 'react';
import { useNavigate } from 'react-router-dom';
import { GOOGLE_DIRECTIONS_URL, DEFAULT_PERSON_AVATAR, USER_ROLES } from '../Constants';
import { NOTIFICATION_TYPES } from './NotificationsPage';
import { formatDatesWithTimeZone } from '../../helpers/DateUtils';
import './NotificationsAndMessagesSidebar.scss';

const NOTIFICATION_TYPES_STRING = {
  BUSINESS_NEW_SHIFT_REQUEST: 'New Shift Request',
  BUSINESS_SHIFT_WITHDRAWAL: 'Shift Withdrawn',
  LOCUM_SHIFT_DELETION: 'Shift Deleted',
  LOCUM_SHIFT_SELECTION_SUCCESS: 'Shift Confirmed!',
  LOCUM_SHIFT_SELECTION_UNSUCCESSFUL: 'Shift Request Unsuccesful',
  LOCUM_PRE_SHIFT_DETAILS: 'Update Pre Shift Details!',
  LOCUM_SHIFT_REMINDER: 'Upcoming Shift Reminder',
  LOCUM_SHIFT_CHANGE: 'Shift Details Changed',
  BUSINESS_SHIFT_REMINDER: 'Upcoming Shift',
  LOCUM_SHIFT_PAYMENT: 'Shift Payment',
  BUSINESS_SHIFT_PAYMENT: 'Shift Payment',
  BUSINESS_SHIFT_NOT_CONFIRMED: 'No Requests for this Shift yet',
  WELCOME: 'Welcome!',
};

export const UnreadCount = ({ count }) => {
  return (
    <div className={`notif-number ${count > 99 ? 'many-requests' : ''}`}>
      <span>{count}</span>
    </div>
  )
};

const getNotificationMessage = (notification, navigate, close) => {
  const { type } = notification;
  switch (type) {
    case NOTIFICATION_TYPES.BUSINESS_NEW_SHIFT_REQUEST: {
      const { shift } = notification;
      const { storeId, name, startTime, endTime, address } = shift;
      const { timeZone } = address;
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);
      const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(startDate, endDate, true, timeZone);
      const navToReview = () => {
        navigate('/store/applicants/review', { state: { storeId }});
        close();
      };
      return (
        <section className={'notification-message'}>
          {`Hooray! You've got a new shift request for `}
          <span className={'name'}>{name}</span>
          {' for '}
          <span className={'shift-dates'}>
            <b>{date}</b>
            {' from '}
            <b>{startTimeDisplay}</b> 
            {' to '}
            <b>{endTimeDisplay}</b>
            {'. You can review your requests '}
            <button className={'link'} onClick={navToReview}>{'here'}</button>
            {'.'}
          </span>
        </section>
      );
    }
    case NOTIFICATION_TYPES.BUSINESS_SHIFT_WITHDRAWAL: {
      const { shift, firstName, lastName } = notification;
      const { storeId, name, startTime, endTime, applicants, address } = shift;
      const { timeZone } = address;
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);
      const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(startDate, endDate, true, timeZone);
      const navToReview = () => {
        navigate('/store/applicants/review', { state: { storeId }});
        close();
      };
      const message = applicants.length > 0 
        ? 
          <>
            {'There are other applicants for this shift you can review '}
            <button className={'link'} onClick={navToReview}>{'here'}</button>
            {'.'}
          </>
        :
          <>
            {`Hang tight! Your shift currently being shown to all the locums in your area.`}
          </>;
      return (
        <section className={'notification-message'}>
          {`Unfortunately, `}
          <span className={'name'}>{`${firstName} ${lastName}`}</span>
          {' had to withdraw from their shift for '}
          <span className={'name'}>{name}</span>
          {' on '}
          <span className={'shift-dates'}>
            <b>{date}</b>
            {' from '}
            <b>{startTimeDisplay}</b> 
            {' to '}
            <b>{endTimeDisplay}</b>
          </span>
          {'. '}
          {message}
        </section>
      );
    }
    case NOTIFICATION_TYPES.LOCUM_SHIFT_DELETION: {
      const { shift } = notification;
      const { name, startTime, endTime, address } = shift;
      const { timeZone } = address;
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);
      const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(startDate, endDate, true, timeZone);
      const navToFind = () => {
        navigate('/find');
        close();
      }
      return (
        <section className={'notification-message'}>
          {`The shift for `}
          <span className={'name'}>{name}</span>
          {' for '}
          <span className={'shift-dates'}>
            <b>{date}</b>
            {' from '}
            <b>{startTimeDisplay}</b> 
            {' to '}
            <b>{endTimeDisplay}</b>
            {' was deleted.'}
          </span>
          <br />
          {`Don't worry, plenty of other shifts are available.`}
          {' Find more shifts '}
          <button className={'link'} onClick={navToFind}>{'here'}</button>
          {'.'}
        </section>      
      );
    }
    case NOTIFICATION_TYPES.LOCUM_SHIFT_SELECTION_SUCCESS: {
      const { shift, business, store } = notification;
      const { name, startTime, endTime, address } = shift;
      const { timeZone } = address;
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);
      const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(startDate, endDate, true, timeZone);
      const navToMessages = () => {
        navigate('/user/messages', { state: { open: false, business, store }});
        close();
      };
      return (
        <section className={'notification-message'}>
          {`Hooray! You're confirmed for `}
          <span className={'name'}>{name}</span>
          {' for '}
          <span className={'shift-dates'}>
            <b>{date}</b>
            {' from '}
            <b>{startTimeDisplay}</b> 
            {' to '}
            <b>{endTimeDisplay}</b>
            {'. '}
            <button className={'link'} onClick={navToMessages}>{'Send a message'}</button>
            {' to the store if you have any questions.'}
          </span>
        </section>      
      );
    }
    case NOTIFICATION_TYPES.LOCUM_SHIFT_SELECTION_UNSUCCESSFUL: {
      const { shift } = notification;
      const { name, startTime, endTime, address } = shift;
      const { timeZone } = address;
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);
      const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(startDate, endDate, true, timeZone);
      const navToFind = () => {
        navigate('/find');
        close();
      }
      return (
        <section className={'notification-message'}>
          {`Sorry the shift at `}
          <span className={'name'}>{name}</span>
          {' for '}
          <span className={'shift-dates'}>
            <b>{date}</b>
            {' from '}
            <b>{startTimeDisplay}</b> 
            {' to '}
            <b>{endTimeDisplay}</b>
            {` got filled by someone else!`}
            <br />
            {`Don't worry, plenty of other shifts are available.`}
            {' Find more shifts '}
            <button className={'link'} onClick={navToFind}>{'here'}</button>
            {'.'}
          </span>
        </section>      
      );
    }
    case NOTIFICATION_TYPES.LOCUM_PRE_SHIFT_DETAILS: {
      const { shift, business, store } = notification;
      const { name, startTime, endTime, address } = shift;
      const { timeZone } = address;
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);
      const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(startDate, endDate, true, timeZone);
      const navToMessages = () => {
        navigate('/user/messages', { state: { open: false, business, store }});
        close();
      };
      return (
        <section className={'notification-message'}>
          {`Important: Submit your provider number to `}
          <span className={'name'}>{name}</span>
          {' ahead of your shift on '}
          <span className={'shift-dates'}>
            <b>{date}</b>
            {' from '}
            <b>{startTimeDisplay}</b> 
            {' to '}
            <b>{endTimeDisplay}</b>
            {`. You can `}
            <button className={'link'} onClick={navToMessages}>{'send a message'}</button> 
            {` to the store if you have any questions.`}
          </span>
        </section>      
      );
    }
    case NOTIFICATION_TYPES.LOCUM_SHIFT_CHANGE: {
      const { shift, business, store } = notification;
      const { name, startTime, endTime, address } = shift;
      const { timeZone } = address;
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);
      const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(startDate, endDate, true, timeZone);
      const navToMessages = () => {
        navigate('/user/messages', { state: { open: false, business, store }});
        close();
      };
      return (
        <section className={'notification-message'}>
          {`Important: Shift details have been modified by `}
          <span className={'name'}>{name}</span>
          {' for the shift on '}
          <span className={'shift-dates'}>
            <b>{date}</b>
            {' from '}
            <b>{startTimeDisplay}</b> 
            {' to '}
            <b>{endTimeDisplay}</b>
            {`. You can `}
            <button className={'link'} onClick={navToMessages}>{'send a message'}</button> 
            {` to the store if you have any questions.`}
          </span>
        </section>      
      );
    }
    case NOTIFICATION_TYPES.LOCUM_SHIFT_REMINDER: {
      const { shift } = notification;
      const { name, address } = shift;
      const { street, suburb, city, state, postcode } = address;
      const getDirections = () => {
        const uriEncodedAddress = encodeURI(`${name} ${street} ${suburb} ${city} ${state} ${postcode}`);
        window.open(`${GOOGLE_DIRECTIONS_URL}${uriEncodedAddress}`);
        close();
      }
      return (
        <section className={'notification-message'}>
          {`Get excited! You have a shift at `}
          <span className={'name'}>{name}</span>
          {` tomorrow. Here's the address: `}
          <button className={'link'} onClick={getDirections}>
            {`${street}, ${suburb}, ${city}, ${postcode}`}
          </button>
        </section>      
      );
    }
    case NOTIFICATION_TYPES.BUSINESS_SHIFT_REMINDER: {
      const { shift } = notification;
      const { locum, name } = shift;
      if (!locum) {
        return null;
      }
      const { firstName, lastName } = locum;
      return (
        <section className={'notification-message'}>
          {`Get excited! `}
          {`${firstName} ${lastName} will be completing the shift at `}
          <span className={'name'}>{name}</span>
          {` tomorrow.`}
        </section>      
      );
    }
    case NOTIFICATION_TYPES.BUSINESS_SHIFT_PAYMENT: {
      const { shift } = notification;
      const { name, startTime, endTime, invoiceLink, address } = shift;
      const { timeZone } = address;
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);
      const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(startDate, endDate, true, timeZone);
      const downloadInvoice = () => {
        const link = document.createElement('a');
        link.href = invoiceLink;
        link.click();
      }
      return (
        <section className={'notification-message'}>
          {`Has the shift at `}
          <span className={'name'}>{name}</span>
          {' for '}
          <span className={'shift-dates'}>
            <b>{date}</b>
            {' from '}
            <b>{startTimeDisplay}</b> 
            {' to '}
            <b>{endTimeDisplay}</b>
          </span>
          {` been completed? We've created an automatic invoice for you.`}
          {' Pay it here or forward it to corporate: '}
          <button className={'link'} onClick={downloadInvoice}>{'Invoice Link'}</button>
          <br />
          {`Had an issue with the shift? Send us an email at help@locum.ly and we'll be in touch.`}
        </section>      
      );
    }
    case NOTIFICATION_TYPES.LOCUM_SHIFT_PAYMENT: {
      const { shift } = notification;
      const { name, startTime, endTime, address } = shift;
      const { timeZone } = address;
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);
      const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(startDate, endDate, true, timeZone);
      const navToCompleted = () => {
        navigate('/locum/shifts');
        close();
      };
      const navToFind = () => {
        navigate('/find');
        close();
      };
      return (
        <section className={'notification-message'}>
          {`Completed your shift at `}
          <span className={'name'}>{name}</span>
          {' for '}
          <span className={'shift-dates'}>
            <b>{date}</b>
            {' from '}
            <b>{startTimeDisplay}</b> 
            {' to '}
            <b>{endTimeDisplay}</b>
          </span>
          {'? Send a '}
          <button className={'link'} onClick={navToCompleted}>{'request for payment'}</button>
          {' by marking the shift as complete and put your feet up as we generate an invoice for the store for you.'}
          <br />
          <button className={'link'} onClick={navToFind}> {'Book another shift here.'}</button>
        </section>      
      );
    }
    case NOTIFICATION_TYPES.BUSINESS_SHIFT_NOT_CONFIRMED: {
      const { shift } = notification;
      const { name, startTime, endTime, storeId, address } = shift;
      const { timeZone } = address;
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);
      const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(startDate, endDate, true, timeZone);
      const navigateToUpcoming = () => navigate('/store/shifts/upcoming', { state: { storeId }, replace: true });
      return (
        <section className={'notification-message'}>
          {`No luck yet filling your shift at `}
          <span className={'name'}>{name}</span>
          {' on '}
          <span className={'shift-dates'}>
            <b>{date}</b>
            {' from '}
            <b>{startTimeDisplay}</b> 
            {' to '}
            <b>{endTimeDisplay}</b>
          </span>
          {'? Try increasing the rate to stand out!'}
          {' You can review and edit your upcoming shift details '}
          <button className={'link'} onClick={navigateToUpcoming}>{'here'}</button>
          {'.'}
        </section>      
      );
    }
    case NOTIFICATION_TYPES.WELCOME: {
      const { firstName, role } = notification;
      const message = role === USER_ROLES.BUSINESS
        ? '! We are excited to assist you in finding the help you need.'
        : '! We are excited to assist you in finding Locum shifts.'
      return (
        <section className={'notification-message'}>
          {'Welcome onboard, '}
          <span className={'name'}>{firstName}</span>
          {message}
        </section>
      );
    }
    default:
      return null;
  }
}

const Message = ({ message, select, selected, showStore = false, setOpen }) => {
  const { name, chatHistory, avatar, role } = message;
  const lastChat = chatHistory.length > 0 ? chatHistory[chatHistory.length - 1] : null;
  const isBusiness = role === 'BUSINESS';
  const handleOpenMsg = (message) => {
    select(message);
    setOpen(false);
  }
  const numNewMessages = chatHistory.reduce((val, x) => val + (x.new ? 1 : 0), 0);
  if (lastChat === null) {
    return (
      <li className={`message-item ${selected ? 'selected' : ''}`} onClick={() => handleOpenMsg(message)}>
        <div className={'message-body'}>
          <img className={'avatar'} src={avatar} alt={'Message Avatar'} />
          <span className={`name-and-message`}>
            <span className={'name'}>
              {`${name}${isBusiness || showStore ? ` - ${message.storeName}` : ''}`}
            </span>
          </span>
        </div>
        {numNewMessages > 0 && 
          <div className={'notif-num-container'}>
            <UnreadCount count={numNewMessages} />
          </div>
        }
      </li>
    );
  }
  let lastMessage = lastChat.message;
  if ((lastMessage.length === 0 || lastMessage.replace(/\s/g, '').length === 0) && lastChat.attachments && lastChat.attachments.length > 0) {
    lastMessage = lastChat.attachments[lastChat.attachments.length - 1].fileName;
  }
  const messageAvatar = avatar ? avatar : DEFAULT_PERSON_AVATAR;
  return (
    <li className={`message-item ${selected ? 'selected' : ''}`} onClick={() => handleOpenMsg(message)}>
      <div className={'message-body'}>
        <img className={'avatar'} src={messageAvatar} alt={'Message Avatar'} />
        <span className={`name-and-message ${numNewMessages > 0 ? 'new' : ''}`}>
          <span className={'name'}>
            {`${name}${isBusiness || showStore ? ` - ${message.storeName}` : ''}`}
          </span>
          <span className={'message-and-timestamp'}>
            <span className={'message'}>{lastMessage}</span>
          </span>
        </span>
      </div>
      {numNewMessages > 0 && 
        <div className={'notif-num-container'}>
          <UnreadCount count={numNewMessages} />
        </div>
      }
    </li>
  )
};

const Notification = ({ notification, selected = false, close = () => {}, select = () => {}, setOpen }) => {
  const { _id } = notification;
  const navigate = useNavigate();
  const notificationMessage = getNotificationMessage(notification, navigate, close);
  const onClick = (e) => {
    if (e.target.className === "link" && e.target.onclick) {
      e.target.onclick();
      return;
    }
    select(notification, _id);
    setOpen(false);
  }
  return (
    <li className={`notification ${selected ? 'selected' : ''}`} onClick={onClick}>
      <section className={'date-and-notification-message'}>
        <div className={'notif-type'}>{NOTIFICATION_TYPES_STRING[notification.type]}</div>
        {notificationMessage}
      </section>
    </li>
  );
}

const NotificationsAndMessagesNavigation = ({ isNotification, numNewNotifications, numNewMessages }) => {
  const navigate = useNavigate();
  const navToMessages = () => navigate('/user/messages');
  const navToNotifications = () => navigate('/user/notifications');
  return (
    <section className={'notifications-and-messages-navigation'}>
    <button className={`nav-button msg-border ${isNotification ? '' : 'selected'}`} onClick={navToMessages}>
      {'Messages'}
      {numNewMessages !== 0 && 
        <div className={'msg-notif-unread'}>
          <UnreadCount count={numNewMessages}/>
        </div>
      }
    </button>
    <button className={`nav-button ${isNotification ? 'selected' : ''}`} onClick={navToNotifications}>
      {'Notifications'}
      {numNewNotifications !== 0 && 
        <div className={'msg-notif-unread'}>
          <UnreadCount count={numNewNotifications}/>
        </div>
      }
    </button>
  </section>
  );
};

const NotificationList = ({ notifications, selected, setSelected = null, setOpen }) => {
  const notificationList = notifications
    .sort((e1, e2) => e2.date - e1.date)
    .map(notification => {
      const { _id } = notification;
      return (
        <Notification
          notification={notification}
          selected={selected === _id}
          select={setSelected}
          key={`notification-${_id}`}
          setOpen={setOpen}
        />
      );
    });
  return <section className={'notification-list'}>{notificationList}</section>;
};

const MessageList = ({ messages, selected, setSelected = null, senderId, setOpen }) => {
  const messageList = messages
    .sort((e1, e2) => {
      if (e1.chatHistory.length === 0) {
        return 1;
      }
      else if (e2.chatHistory.length === 0) {
        return 1;
      }
      return e2.chatHistory[e2.chatHistory.length - 1].timestamp - e1.chatHistory[e1.chatHistory.length - 1].timestamp;
    })
    .map(message => {
      const { locumId, businessId, storeId, timestamp } = message;
      const isSelected = selected === null ? false : locumId === selected.locumId && businessId === selected.businessId && storeId === selected.storeId;
      return (
        <Message
          message={message}
          selected={isSelected}
          select={setSelected}
          senderId={senderId}
          showStore={true}
          key={`message-${locumId}-${businessId}-${storeId}-${timestamp}`}
          setOpen={setOpen}
        />
      );
    });
  return <section className={'message-list'}>
    {messageList.length > 0
    ? messageList
    : 
    <div className={'empty-inbox'}>
      <div className={'empty-inbox-text'}>
        {'Your inbox is empty! Click '}
        <a href={'/find'} className={'find-link'}>{'here'}</a>
        {' to find and request shifts!'}
      </div>
    </div>
    }
  </section>;
};

const NotificationsAndMessagesSidebar = ({ isNotification, notifications, messages, selected, setSelected, senderId, setOpen }) => {
  const newNotificationCount = notifications.filter(notification => notification.new).length;
  const newMessageCount = messages.reduce((count, message) => count + message.chatHistory.reduce((val, x) => val + (x.new ? 1 : 0), 0), 0);
  const numNewNotifications = newNotificationCount > 99 ? '99+' : newNotificationCount;
  const numNewMessages = newMessageCount > 99 ? '99+' : newMessageCount;
  const sidebarList = isNotification
    ? <NotificationList notifications={notifications} selected={selected} setSelected={setSelected} setOpen={setOpen}/>
    : <MessageList messages={messages} selected={selected} setSelected={setSelected} senderId={senderId} setOpen={setOpen}/>;
  return (
    <aside className={`notifications-and-messages-sidebar`}>
      <NotificationsAndMessagesNavigation
        isNotification={isNotification}
        numNewNotifications={numNewNotifications}
        numNewMessages={numNewMessages}
      />
      {sidebarList}
    </aside>
  );
};

export default NotificationsAndMessagesSidebar;