import './StoreShiftList.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  /* Children component. Should be a list of shifts. */
  children: PropTypes.node.isRequired,

  /* Button text. */
  buttonText: PropTypes.string.isRequired,

  /* Dropdown arrow color. */
  dropdownArrowColor: PropTypes.string.isRequired,

  /* Additional class names. Array of class names - can contain permanent or casual. */
  additionalClassNames: PropTypes.arrayOf(PropTypes.string).isRequired,

  /* True if disabled. */
  disabled: PropTypes.bool.isRequired,

  /* Background color. */
  backgroundColor: PropTypes.string,

  /* Border color. */
  borderColor: PropTypes.string
}

function StoreShiftList({ children, buttonText, dropdownArrowColor, additionalClassNames, disabled, backgroundColor, borderColor }) {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);
  const numChildren = children.length;
  const maxHeight = open ? `${140 * numChildren}px` : '0px';
  const disabledClass = disabled ? 'disabled' : '';
  const openClass = open ? 'open' : '';
  return (
    <section className={`store-shift-list ${additionalClassNames.join(' ')}`}>
      <button
        className={`store-shift-list-toggle very-dark ${disabledClass} ${openClass}`}
        type={'button'}
        onClick={toggleOpen}
        disabled={disabled}
        style={{ background: backgroundColor, borderColor }}
      >
        <span className={'text'}>{buttonText}</span>
        <svg className={`dropdown-arrow ${open ? 'open' : ''}`}
          width="18"
          height="9"
          viewBox="0 0 18 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.91306 0.999997L8.98308 8L16.0531 0.999997"
            stroke={dropdownArrowColor}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <ul className={'store-shifts-container'} style={{ maxHeight }}>
        {children}
      </ul>
    </section>
  )
}

StoreShiftList.defaultProps = { borderColor: null, backgroundColor: null };
StoreShiftList.displayName = 'StoreShiftList';
StoreShiftList.propTypes = propTypes;
export default StoreShiftList;