import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LocumPage from './LocumPage.jsx';
import { useOutsideClick } from '../hooks/CommonHooks.jsx';
import { LoadingButton } from '../common/OnboardingPage.jsx';
import useAuth from '../hooks/AuthHook.jsx';
import { EMAIL_REGEX, DAYS_OF_WEEK, MONTHS, NETWORKS, ASSET_CDN_URL, STATES } from '../Constants';
import { LOCUM_NAVIGATION_BUTTONS } from './LocumNavigationSidebar.jsx';
import ReactPhoneInput from 'react-phone-input-2';
import { DEFAULT_PERSON_AVATAR } from '../Constants.js';

import 'react-phone-input-2/lib/style.css';
import './LocumManageAccountPage.scss';  

import { getProfile, postLocumAvatarUpload, postUpdatePassword, postConfirmPassword, postAccountDetails, postSmsSettings, postEmailSettings, postShiftSettings } from '../Api.js';

const Logo = `${ASSET_CDN_URL}/logo/aqua.png`;
const ShowPasswordIcon = `${ASSET_CDN_URL}/icons/show-password-icon.svg`;
const HidePasswordIcon = `${ASSET_CDN_URL}/icons/hide-password-icon.svg`;
const SaveIcon = `${ASSET_CDN_URL}/icons/save-tick-icon.svg`;
const DeleteIcon = `${ASSET_CDN_URL}/icons/delete-icon.svg`;

const ChangePasswordConfirmationModal = ({ close, confirm }) => {
  const { profile } = useAuth();
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [passwordChanged, setPasswordChanged] = useState(false);

  const [curInputType, setCurInputType] = useState('password');
  const [inputType, setInputType] = useState('password');
  const [confirmInputType, setConfirmInputType] = useState('password');
  const handleShowPasswordIconword = (passwordType, inputType) => {
    const newType = inputType === 'password' ? 'text' : 'password';
    if (passwordType === 'current') setCurInputType(newType);
    if (passwordType === 'new') setInputType(newType);
    if (passwordType === 'confirm') setConfirmInputType(newType);
  }

  const onCurrentPassword = (e) => {
    setCurrentPassword(e.target.value);
    setErrorText('');
  };
  const onChangePassword = (e) => {
    setPassword(e.target.value);
    setErrorText('');
  };
  const onChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    setErrorText('');
  }
  const changePasswordRef = useRef(null);
  const modalRef = useRef(null);
  useOutsideClick(modalRef, () => close());
  const onConfirm = () => {
    setLoading(true);
    postConfirmPassword(profile.emailAddress, currentPassword, profile.role)
      .then(({ success }) => {
        setLoading(false);
        if (success) {
          if (password.length < 6) {
            setErrorText('Password length must be greater than 6 characters');
          }
          else if (password !== confirmPassword) {
            setErrorText('Passwords do not match');
          }
          else {
            setLoading(true);
            postUpdatePassword(password, false)
              .then(() => {
                setLoading(false);
                setPasswordChanged(true);
              })
              .catch(() => {
                setLoading(false);
                setErrorText('An unknown error occurred. Please try again later!');
              });
          }
        } 
        else {
          setErrorText('You have entered the wrong current password.');
        }
      }
    ).catch(() => {
      setLoading(false);
      setErrorText('An unknown error occurred. Please try again later!');
    })
  }
  useEffect(() => {
    if (changePasswordRef.current) {
      const changePasswordListener = (e) => e.key === 'Enter' && changePasswordRef.current.click();
      window.addEventListener('keypress', changePasswordListener);
      return () => window.removeEventListener('keypress', changePasswordListener);
    }
  }, [changePasswordRef]);
  return (
    <section className={`confirmation-modal-container change-password`}>
      <section className={'confirmation-modal'} ref={modalRef}>
        <section className={'confirmation-modal-content'}>
          <h3 className={'confirmation-title'}>{'Change password'}</h3>
          {!passwordChanged ? 
          <>
            <span className={'error-text'}>{errorText}</span>
            <section className={'password'}>
              <input placeholder={'Enter current password'} type={curInputType} className={'password-input'} value={currentPassword} onChange={onCurrentPassword} />
              <img className={'show-pass-icon'} src={curInputType === 'password' ? ShowPasswordIcon : HidePasswordIcon} alt={'show-password'} onClick={() => handleShowPasswordIconword('current', curInputType)} />
            </section>
            <section className={'password'}>
              <input placeholder={'Enter new password'} type={inputType} className={'password-input'} value={password} onChange={onChangePassword}/>
              <img className={'show-pass-icon'} src={inputType === 'password' ? ShowPasswordIcon : HidePasswordIcon} alt={'show-password'} onClick={() => handleShowPasswordIconword('new', inputType)} />
            </section>
            <section className={'password'}>
              <input placeholder={'Re-enter new password'} type={confirmInputType} className={'password-input'} value={confirmPassword} onChange={onChangeConfirmPassword} />
              <img className={'show-pass-icon'} src={confirmInputType === 'password' ? ShowPasswordIcon : HidePasswordIcon} alt={'show-password'} onClick={() => handleShowPasswordIconword('confirm', confirmInputType)} />
            </section>
            <section className={'buttons'}>
              <LoadingButton loading={loading} classNames={['confirm', 'very-dark']} onClick={onConfirm} text={'Change password'} buttonRef={changePasswordRef} />
            </section>
          </>
          :
          <>
            <div className={'pass-changed'}>
              <span className={'pass-changed-text'}>{'Your password has been changed!'}</span>
              <button className={'pass-changed-button'} onClick={() => confirm()} ref={changePasswordRef}>
                <img className={'pass-changed-icon'} src={SaveIcon} alt={'confirm'} />
              </button>
            </div>
          </>
          }
        </section>
      </section>
    </section>
  );
}

const ChangePasswordButton = () => {
  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);
  const confirm = () => setOpen(false);
  return (
    <>
      <button className={'change-password-button very-light'} onClick={() => setOpen(true)}>
        {'Change password...'}
      </button>
      { open && <ChangePasswordConfirmationModal close={close} confirm={confirm} />}
    </>
  )
}

export const EditAccountButton = ({ save, cancel, isAccount = false }) => {
  const [loading, setLoading] = useState(false);
  const cancelEdit = () => cancel();
  const saveEdit = () => save(setLoading);
  return (
    <section className={`edit-account-button ${isAccount && 'profile-save'}`}>
      <button className={`cancel-button very-dark ${loading ? 'disabled' : ''}`} onClick={cancelEdit} disabled={loading}>
        {'Cancel'}
      </button>
      <button className={`save-button very-dark ${loading ? 'disabled' : ''}`} onClick={saveEdit} disabled={loading}>
        {loading ? 'Loading...' : 'Done'}
      </button>
    </section>
  );
};

export const EditInput = ({ children, label, type }) => {
  return (
    <section className={`edit-option ${type}`}>
      <section className={'edit-label'}>{label}</section>
      {children}
    </section>
  )
}

export const PhoneEditInput = ({ label, value, onChange }) => {
  const input = <ReactPhoneInput 
                  inputExtraProps={{
                    required: true,
                    autoFocus: true
                  }}
                  regions={['oceania']}
                  country={'au'}
                  value={value}
                  onChange={onChange} 
                  inputClass={'phone-number'}
                />
  return (
    <EditInput label={label} type={'text'}>
      {input}
    </EditInput>
  )
}

export const TextEditInput = ({ label, value, onChange, placeholder }) => {
  const usePlaceholder = !value;
  return (
    <EditInput label={label} type={'text'}>
      <input
        type={'text'}
        className={`edit-text ${usePlaceholder ? 'placeholder' : ''}`}
        value={usePlaceholder ? placeholder : value}
        onChange={onChange}
      />
    </EditInput>
  )
}

const ButtonEditInput = ({ label, children }) => {
  return (
    <EditInput label={label} type={'button'}>
      {children}
    </EditInput>
  )
}

export const CheckboxInput = ({ label, subtitle, value, apiName, onChange, isGroup = false }) => {
  return (
    <section className={`edit-option checkbox`}>
      <input type={'checkbox'} className={'edit-checkbox'} value={apiName} checked={value} onChange={onChange} />
      <section className={'edit-label'}>
        <h3 className={`title-label ${isGroup ? 'group-weight' : ''}`}>{label}</h3>
        {!isGroup && <h4 className={'subtitle-label'}>{subtitle}</h4>}
      </section>
    </section>
  );
}

export const ProfileDetails = ({ children, title, save = null, cancel, additionalClassNames = [], isAccount = false, setOpen, isLocum = false }) => {
  return (
    <section className={`profile-details ${additionalClassNames.join(' ')}`}>
      <section className={`title-container ${isAccount ? 'is-account' : ''} ${isLocum && !isAccount ? 'locum-title' : ''}`}>
        {!isAccount && 
          <button className={'exit-button'}>
            <img className={'exit-icon'} src={DeleteIcon} alt={'delete'} onClick={() => setOpen(false)} />
          </button>}
        {!isAccount && <section className={'title'}>{title}</section>}
        {isAccount && <EditAccountButton save={save} cancel={cancel} isAccount={isAccount}/>}
      </section>
      {children}
    </section>
  );
}

export const AccountDetails = () => {
  const navigate = useNavigate();
  const { profile, setProfile } = useAuth();
  const initialAccountDetails = { 
    firstName: profile.firstName,
    lastName: profile.lastName,
    emailAddress: profile.emailAddress,
    phoneNumber: profile.phoneNumber,
    about: profile.about,
    abn: profile.abn,
    ahpra: profile.ahpra
  };
  const { avatar } = profile;
  const [locumAvatar, setLocumAvatar] = useState(avatar ? avatar : DEFAULT_PERSON_AVATAR);
  // const [open, setOpen] = useState(false);
  const [accountDetails, setAccountDetails] = useState(initialAccountDetails);
  const [errorText, setErrorText] = useState(false);
  const { firstName, lastName, emailAddress, phoneNumber, abn, ahpra } = accountDetails;
  // const editAccountDetails = () => setOpen(true);
  const saveAccountDetails = async (setLoading) => {
    if (!EMAIL_REGEX.test(emailAddress)) {
      setErrorText('Must be a valid email address format.');
      return;
    }

    setLoading(true);
    postAccountDetails(accountDetails)
      .then(({error}) => {
        if (error) {
          setErrorText(error);
          setLoading(false);
        } else {
          getProfile()
            .then(({ profile }) => {
              setProfile(profile);
              setLoading(false);
              setAccountDetails({ ...accountDetails, phoneNumber: phoneNumber });
              navigate('/locum/profile/edit');
            });
        }
      })
      .catch(({ error }) => {
        setErrorText(error)
        setLoading(false);
      });
  };
  const cancelAccountDetails = () => {
    setAccountDetails(initialAccountDetails);
    navigate('/locum/profile/edit');
  }
  const changeFirstName = (e) => {
    setErrorText('');
    setAccountDetails({ ...accountDetails, firstName: e.target.value });
  }
  const changeLastName = (e) => {
    setErrorText('');
    setAccountDetails({ ...accountDetails, lastName: e.target.value });
  }
  // const changePostcode = (e) => setAccountDetails({ ...accountDetails, postcode: e.target.value });
  const changeEmailAddress = (e) => {
    setErrorText('');
    setAccountDetails({ ...accountDetails, emailAddress: e.target.value });
  }
  const changePhoneNumber = (phoneNumber) => {
    setErrorText('');
    setAccountDetails({ ...accountDetails, phoneNumber });
  }
  const changeABN = (e) => {
    setErrorText('');
    setAccountDetails({ ...accountDetails, abn: e.target.value });
  }
  const changeAHPRA = (e) => {
    setErrorText('');
    setAccountDetails({ ...accountDetails, ahpra: e.target.value });
  }
  const uploadAvatar = (e) => {
    const file = e.target.files[0];
    setLocumAvatar(window.URL.createObjectURL(file));
    postLocumAvatarUpload(file)
      .then(() => getProfile().then(({ profile }) => {
        setProfile(profile)
      }))
      .catch((err) => console.error(err));
  }

  const uploadButton = (
    <>
      <label for={'avatar-image-upload'} className={'upload-avatar'}>{'Change profile photo'}</label>
      <input
        type={'file'}
        name={'avatar-image-upload'}
        id={'avatar-image-upload'}
        className={'avatar-image-upload'}
        accept={'image/*'}
        onChange={uploadAvatar}
      />
    </>
  );

  return (
    <ProfileDetails additionalClassNames={['account']} title={'Account details'} save={saveAccountDetails} cancel={cancelAccountDetails} isAccount={true}>
      <section className={'locum-avatar-comp'}>
        <section className={'locum-avatar-container'}>
          <img className={'locum-avatar'} src={locumAvatar} alt={'Locum Avatar'} />
        </section>
        {uploadButton}
      </section>
      <div className={'account-detail-container'}>
        <span className={'error-text'}>{errorText}</span>
        <TextEditInput label={'First Name'} value={firstName} onChange={changeFirstName} />
        <TextEditInput label={'Last Name'} value={lastName} onChange={changeLastName} />
        <TextEditInput label={'Email'} value={emailAddress} onChange={changeEmailAddress} />
        <PhoneEditInput label={'Phone'} value={phoneNumber} onChange={changePhoneNumber} />
        <ButtonEditInput label={'Password'}>
          <ChangePasswordButton />
        </ButtonEditInput>
        <TextEditInput label={'ABN'} value={abn} onChange={changeABN} />
        <TextEditInput label={'AHPRA'} value={ahpra} onChange={changeAHPRA} />
      </div>
    </ProfileDetails>
  )
};

export const SmsSettings = ({ smsOpen, setSMSOpen, smsSettings, setSmsSettings, initialSmsSettings }) => {
  const [open, setOpen] = useState(false);
  const [errorText, setErrorText] = useState(null);
  
  const cancelSmsSettings = () => {
    setOpen(false);
    setSmsSettings(initialSmsSettings);
  }
  const editSmsSettings = () => {
    setOpen(true);
  }
  const onChangeShiftConfirmation = (e) => {
    setErrorText(null);
    setSmsSettings({ ...smsSettings, shiftConfirmation: e.target.checked });
  }
  const onChangeShiftChange = (e) => {
    setErrorText(null);
    setSmsSettings({ ...smsSettings, shiftChange: e.target.checked });
  }
  const onChangeShiftRejection= (e) => {
    setErrorText(null);
    setSmsSettings({ ...smsSettings, shiftRejection: e.target.checked });
  }
  const onChangeMissingProviderNumber = (e) => {
    setErrorText(null);
    setSmsSettings({ ...smsSettings, missingProviderNumber: e.target.checked });
  }
  const onChangeShiftReminder = (e) => {
    setErrorText(null);
    setSmsSettings({ ...smsSettings, shiftReminder: e.target.checked });
  }
  const onChangeShiftCompletion = (e) => {
    setErrorText(null);
    setSmsSettings({ ...smsSettings, shiftCompletion: e.target.checked });
  }
  const { shiftConfirmation, shiftChange, shiftRejection, missingProviderNumber, shiftReminder, shiftCompletion } = smsSettings;
  return (
    <div className={`checkbox-modal ${smsOpen ? 'open' : ''}`}>
      <ProfileDetails title={'Mobile SMS Preferences'} edit={editSmsSettings} cancel={cancelSmsSettings} open={open} setOpen={setSMSOpen} isLocum={true}>
        <span className={'error-text'}>{errorText}</span>
        <div className={'checkbox-scroll'}>
          <CheckboxInput
            label={'Shift Confirmation'}
            value={shiftConfirmation}
            subtitle={'Receive an SMS for every shift confirmation from store'}
            onChange={onChangeShiftConfirmation}
          />
          <CheckboxInput
            label={'Shift Change'}
            value={shiftChange}
            subtitle={'Receive an SMS for every time a business changes a shift you have confirmed or requested'}
            onChange={onChangeShiftChange}
          />
          <CheckboxInput
            label={'Shift Rejection'}
            value={shiftRejection}
            subtitle={'Receive an SMS for every time a business confirms another Locum for a shift'}
            onChange={onChangeShiftRejection}
          />
          <CheckboxInput
            label={'Missing Provider Number'}
            value={missingProviderNumber}
            subtitle={'Receive an SMS for shifts missing provider numbers 48 hours before'}
            onChange={onChangeMissingProviderNumber}
          />
          <CheckboxInput
            label={'Shift Reminder'}
            value={shiftReminder}
            subtitle={'Receive an SMS for 24 hours before a shift starts'}
            onChange={onChangeShiftReminder}
          />
          <CheckboxInput
            label={'Shift Completion'}
            value={shiftCompletion}
            subtitle={'Receive an SMS after shift is complete reminding to mark it as complete on Locumly'}
            onChange={onChangeShiftCompletion}
          />
        </div>
      </ProfileDetails>
    </div>
  );
}

export const EmailSettings = ({ emailOpen, setEmailOpen, emailSettings, setEmailSettings, initialEmailSettings }) => {
  const [errorText, setErrorText] = useState(null);
  const cancelEmailSettings = () => {
    setEmailOpen(false);
    setEmailSettings(initialEmailSettings);
  }
  const editEmailSettings = () => {
    setEmailOpen(true);
  }
  const onChangeShiftRequest = (e) => setEmailSettings({ ...emailSettings, shiftRequest: e.target.checked });
  const onChangeNewMessages = (e) => setEmailSettings({ ...emailSettings, newMessages: e.target.checked });
  const onChangeShiftConfirmation = (e) => setEmailSettings({ ...emailSettings, shiftConfirmation: e.target.checked });
  const onChangeShiftChange = (e) => setEmailSettings({ ...emailSettings, shiftChange: e.target.checked });
  const onChangeShiftDeletion = (e) => setEmailSettings({ ...emailSettings, shiftDeletion: e.target.checked });
  const onChangeShiftRejection= (e) => setEmailSettings({ ...emailSettings, shiftRejection: e.target.checked });
  const onChangeMissingProviderNumber = (e) => setEmailSettings({ ...emailSettings, missingProviderNumber: e.target.checked });
  const onChangeShiftReminder = (e) => setEmailSettings({ ...emailSettings, shiftReminder: e.target.checked });
  const onChangeShiftCompletion = (e) => setEmailSettings({ ...emailSettings, shiftCompletion: e.target.checked });
  const onChangePaymentReceived = (e) => setEmailSettings({ ...emailSettings, paymentReceived: e.target.checked });
  useEffect(() => setErrorText(null), [emailSettings]);
  const { shiftRequest = true, newMessages = true, shiftConfirmation = true, shiftChange = true, shiftDeletion = true, shiftRejection = true, missingProviderNumber = true, shiftReminder = true, shiftCompletion = true, paymentReceived = true } = emailSettings;
  return (
    <div className={`checkbox-modal ${emailOpen ? 'open' : ''}`}>
      <ProfileDetails title={'Email Notification Preferences'} edit={editEmailSettings} cancel={cancelEmailSettings} setOpen={setEmailOpen} isLocum={true}>
        <span className={'error-text'}>{errorText}</span>
        <div className={'checkbox-scroll'}>
          <CheckboxInput
            label={'Shift Request'}
            value={shiftRequest}
            subtitle={'Receive an email for every shift request sent to stores'}
            onChange={onChangeShiftRequest}
          />
          <CheckboxInput
            label={'New Messages'}
            value={newMessages}
            subtitle={'Receive an email whenever a new message is sent to you from a store and is unread at least 2 hours'}
            onChange={onChangeNewMessages}
          />
          <CheckboxInput
            label={'Shift Confirmation'}
            value={shiftConfirmation}
            subtitle={'Receive an email for every shift confirmation from store'}
            onChange={onChangeShiftConfirmation}
          />
          <CheckboxInput
            label={'Shift Change'}
            value={shiftChange}
            subtitle={'Receive an email for every time a business changes a shift you have been confirmed for or requested'}
            onChange={onChangeShiftChange}
          />
          <CheckboxInput
            label={'Shift Deletion'}
            value={shiftDeletion}
            subtitle={'Receive an email for every time a business deletes a shift you have been confirmed for or requested'}
            onChange={onChangeShiftDeletion}
          />
          <CheckboxInput
            label={'Shift Rejection'}
            value={shiftRejection}
            subtitle={'Receive an email for every time a business confirms another Locum for a shift'}
            onChange={onChangeShiftRejection}
          />
          <CheckboxInput
            label={'Missing Provider Number'}
            value={missingProviderNumber}
            subtitle={'Receive an email for shifts missing provider numbers 48 hours before'}
            onChange={onChangeMissingProviderNumber}
          />
          <CheckboxInput
            label={'Shift Reminder'}
            value={shiftReminder}
            subtitle={'Receive an email for 24 hours before a shift starts'}
            onChange={onChangeShiftReminder}
          />
          <CheckboxInput
            label={'Shift Completion'}
            value={shiftCompletion}
            subtitle={'Receive an email after your shift is complete reminding you to mark it as complete on Locumly'}
            onChange={onChangeShiftCompletion}
          />
          <CheckboxInput
            label={'Payment Received'}
            value={paymentReceived}
            subtitle={'Receive an email after payment has been received for a completed shift'}
            onChange={onChangePaymentReceived}
          />
        </div>
      </ProfileDetails>
    </div>
  );
}

const updateArr = (arr, value, checked) => {
  let index = arr.indexOf(value);
  if (index > -1 && !checked) {
    arr.splice(index, 1);
  } else if (index === -1 && checked) {
    arr.push(value);
  }
  return arr;
}

export const ShiftSettings = ({ shiftOpen, setShiftOpen, shiftSettings, setShiftSettings, initialShiftSettings }) => {
  const { profile } = useAuth();
  const { type } = profile;
  const navigate = useNavigate();
  const [errorText, setErrorText] = useState(null);
  const { monthsOfYear, daysOfWeek, networks, maxDistance } = shiftSettings;
  const notIncludedNetworks = ['ONE_THOUSAND_AND_ONE_OPTICAL', 'OTHER_INDEPENDENT_OPTOMS', ''];
  const cancelShiftSettings = () => {
    setShiftOpen(false);
    setShiftSettings(initialShiftSettings);
  }
  const editShiftSettings = () => {
    setShiftOpen(true);
  }
  const onChangeRegionalAlert = (e) => setShiftSettings({ ...shiftSettings, regionalAlert: e.target.checked, regionalStates: e.target.checked ? STATES.map(state => state.key) : []});
  const onChangeRegionalState = (e) => {
    const newRegionalStates = updateArr(shiftSettings.regionalStates, e.target.value, e.target.checked);
    setShiftSettings({ ...shiftSettings, regionalAlert: newRegionalStates.length === 0 ? false : true, regionalStates: newRegionalStates })
  };
  const onChangeMonth = (e) => setShiftSettings({ ...shiftSettings, monthsOfYear: updateArr(shiftSettings.monthsOfYear, e.target.value, e.target.checked) });
  const onChangeDay = (e) => setShiftSettings({ ...shiftSettings, daysOfWeek: updateArr(shiftSettings.daysOfWeek, e.target.value, e.target.checked) });
  const onChangeNetwork = (e) => setShiftSettings({ ...shiftSettings, networks: updateArr(shiftSettings.networks, e.target.value, e.target.checked) });
  const onChangeMaxDist = (e) => setShiftSettings({ ...shiftSettings, maxDistance: e.target.value });
  useEffect(() => setErrorText(null), [shiftSettings]);
  const { regionalAlert, regionalStates } = shiftSettings;
  return (
    <div className={`checkbox-modal ${shiftOpen ? 'open' : ''}`}>
      <ProfileDetails title={'Shift Recommendation Preferences'} edit={editShiftSettings} cancel={cancelShiftSettings} setOpen={setShiftOpen} isLocum={true}>
        <span className={'error-text'}>{errorText}</span>
        <div className={'checkbox-scroll'}>
          <div className={'checkbox-container'}>
            <section className={'edit-label'}>
              <h3 className={'title-label'}>{'Alert me for Regional shifts with travel/accomodation'}</h3>
            </section>
            <div className={'checkbox-group'}>
              <CheckboxInput
                label={'Yes'}
                value={regionalAlert}
                subtitle={''}
                onChange={onChangeRegionalAlert}
              />
            </div>
          </div>
          <div className={'checkbox-container'}>
            <section className={'edit-label'}>
              <h3 className={'title-label'}>{'States for Regional shifts'}</h3>
              <h4 className={'subtitle-label'}>{'Receive notifications for the selected states'}</h4>
            </section>
            <div className={'checkbox-group'}>
              {STATES.map(state => 
                <CheckboxInput
                  label={state.key}
                  apiName={state.key}
                  value={regionalStates.indexOf(state.key) !== -1}
                  subtitle={''}
                  onChange={onChangeRegionalState}
                  isGroup={true}
                />
              )}
            </div>
          </div>
          <div className={'checkbox-container'}>
            <section className={'edit-label'}>
              <h3 className={'title-label'}>{'Months of the year'}</h3>
              <h4 className={'subtitle-label'}>{'Receive notifications for the selected months'}</h4>
            </section>
            <div className={'checkbox-group'}>
              {MONTHS.apiText.map((month, i) => 
                <CheckboxInput
                  label={MONTHS.displayText[i]}
                  apiName={month}
                  value={monthsOfYear.indexOf(month) !== -1}
                  subtitle={''}
                  onChange={onChangeMonth}
                  isGroup={true}
                />
              )}
            </div>
          </div>
          <div className={'checkbox-container'}>
            <section className={'edit-label'}>
              <h3 className={'title-label'}>{'Days of the week'}</h3>
              <h4 className={'subtitle-label'}>{'Receive notifications for the selected days'}</h4>
            </section>
            <div className={'checkbox-group'}>
              {DAYS_OF_WEEK.apiText.map((day, i) => 
                <CheckboxInput
                  label={DAYS_OF_WEEK.displayText[i]}
                  apiName={day}
                  value={daysOfWeek.indexOf(day) !== -1}
                  subtitle={''}
                  onChange={onChangeDay}
                  isGroup={true}
                />
              )}
            </div>
          </div>
          <div className={'checkbox-container'}>
            <section className={'edit-label'}>
              <h3 className={'title-label'}>{'Maximum distance'}</h3>
              <h4 className={'subtitle-label'}>{'Enter the maximum distance to stores to be notified for'}</h4>
            </section>
            <div className={'position-container'}>
              <div className={'max-dist-input'}  data-placeholder={'km'}>
                <input className={'max-dist'} type={'text'} onChange={onChangeMaxDist} value={maxDistance}/>
              </div>
            </div>
          </div>
          <div className={'checkbox-container'}>
            <section className={'edit-label'}>
              <h3 className={'title-label'}>{'Store Network'}</h3>
              <h4 className={'subtitle-label'}>{'Receive notifications for the selected stores'}</h4>
            </section>
            <div className={'checkbox-group'}>
              { Object
                  .keys(NETWORKS[type])
                  .filter(network => NETWORKS[type][network] && !notIncludedNetworks.includes(NETWORKS[type][network].key) && network !== '')
                  .map(network => 
                    <CheckboxInput
                      label={NETWORKS[type][network].text}
                      apiName={NETWORKS[type][network].key}
                      value={networks.indexOf(NETWORKS[type][network].key) !== -1}
                      subtitle={''}
                      onChange={onChangeNetwork}
                      isGroup={true}
                    />
                  )
              }
            </div>
          </div>
          <div className={'checkbox-container'}>
            <section className={'edit-label'}>
              <h3 className={'title-label'}>{'Subscribed Stores'}</h3>
              <h4 className={'subtitle-label'}>{'List of stores your notifications are turned on for'}</h4>
            </section>
            <div className={'subscribed-stores-container'}>
              <div className={'subscribed-stores'}>
                {shiftSettings.stores.map(store => <div className={'sub-store'} onClick={() => navigate(`/find/store/view?id=${store._id}`)}>{store.name}</div>)}
              </div>
            </div>
          </div>
        </div>
      </ProfileDetails>
    </div>
  );
}

const LocumManageAccountPage = () => {
  const navigate = useNavigate();
  const { profile, setProfile } = useAuth();
  const { type } = profile;
  // SMS Settings
  const [smsOpen, setSMSOpen] = useState(false);
  const initialSmsSettings = profile.smsSettings
    ? profile.smsSettings
    : {
      shiftConfirmation: true,
      shiftChange: true,
      shiftRejection: true,
      missingProviderNumber: true,
      shiftReminder: true,
      shiftCompletion: true,
    };
  const [smsSettings, setSmsSettings] = useState(initialSmsSettings);
  const handleSMS = (e) => {
    e.stopPropagation();
    setSMSOpen(true);
  }

  // Email Settings
  const [emailOpen, setEmailOpen] = useState(false);
  const initialEmailSettings = profile.emailSettings
    ? profile.emailSettings
    : {
      shiftRequest: true,
      newMessages: true,
      shiftRejection: true,
      missingProviderNumber: true,
      shiftReminder: true,
      shiftConfirmation: true,
      shiftChange: true,
      shiftDeletion: true,
      shiftCompletion: true,
      paymentReceived: true,
    };
  const [emailSettings, setEmailSettings] = useState(initialEmailSettings);
  const handleEmail = (e) => {
    e.stopPropagation();
    setEmailOpen(true);
  }

  // Shift Settings
  const notIncludedNetworks = ['ONE_THOUSAND_AND_ONE_OPTICAL', 'OTHER_INDEPENDENT_OPTOMS', ''];
  const [shiftOpen, setShiftOpen] = useState(false);
  const initialShiftSettings = profile.geolocationPreferences
    ? profile.geolocationPreferences
    : {
      regionalAlert: false,
      regionalStates: [],
      daysOfWeek: ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'],
      networks: Object.keys(NETWORKS[type]).filter((network) => !notIncludedNetworks.includes(network)),
      maxDistance: 20,
      stores: [],
      shouldSendSms: true,
      monthsOfYear: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
    };
  const [shiftSettings, setShiftSettings] = useState(initialShiftSettings);
  const handleShift = (e) => {
    e.stopPropagation();
    setShiftOpen(true);
  }

  const submitEmailSettings = (newEmailSettings) => {
    setEmailSettings(newEmailSettings);
    postEmailSettings(newEmailSettings)
      .then(() => {
        getProfile().then(({ profile }) => setProfile(profile));
      });
  };

  const submitSmsSettings = (newSmsSettings) => {
    setSmsSettings(newSmsSettings);
    postSmsSettings(newSmsSettings)
      .then(() => {
        getProfile().then(({ profile }) => setProfile(profile));
      });
  };

  const submitShiftSettings = (newShiftSettings) => {
    setShiftSettings(newShiftSettings);
    postShiftSettings(newShiftSettings)
      .then(() => {
        getProfile().then(({ profile }) => setProfile(profile));
      });
  };

  // When on map view, scroll to top of page and prevent scrolling.
  useEffect(() => {
    emailOpen || smsOpen || shiftOpen ? document.body.classList.add('prevent-scroll') : document.body.classList.remove('prevent-scroll');
    window.scrollTo({top: 0, left: 0});
  }, [emailOpen, smsOpen, shiftOpen]);

  return (
    <LocumPage mode={LOCUM_NAVIGATION_BUTTONS.ACCOUNT.mode}>
      <article className={'manage-account-page locum-acc-page'}>
        <div className={'manage-header'}>
          <div className={'logo-container'}>
            <img src={Logo} alt={'Logo'} onClick={() => navigate('/')} className={'locumly-logo'}/>
          </div>
        </div>
        <section className={'profile'}>
          <AccountDetails />
          <div className={'setting-button'} onClick={(e) => handleSMS(e)}>
            <button className={'open-checkbox-settings'}>{'Mobile SMS Preferences'}</button>
          </div>
          <div className={'setting-button'} onClick={(e) => handleEmail(e)}>
            <button className={'open-checkbox-settings'}>{'Email Notification Preferences'}</button>
          </div>
          <div className={'setting-button'} onClick={(e) => handleShift(e)}>
            <button className={'open-checkbox-settings'}>{'Shift Recommendation Preferences'}</button>
          </div>
          <SmsSettings smsOpen={smsOpen} setSMSOpen={setSMSOpen} smsSettings={smsSettings} setSmsSettings={submitSmsSettings} initialSmsSettings={initialSmsSettings} />
          <EmailSettings emailOpen={emailOpen} setEmailOpen={setEmailOpen} emailSettings={emailSettings} setEmailSettings={submitEmailSettings} initialEmailSettings={initialEmailSettings} />
          <ShiftSettings shiftOpen={shiftOpen} setShiftOpen={setShiftOpen} shiftSettings={shiftSettings} setShiftSettings={submitShiftSettings} initialShiftSettings={initialShiftSettings} />
        </section>
      </article>
    </LocumPage>
  );
}

export default LocumManageAccountPage;
