import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { StoreProfileProvider } from './components/hooks/StoreProfileContext';
import { useInitialProfileFetch, useProfileIntervalFetcher } from './components/hooks/DataHooks';
import { useErrorLogger } from './components/hooks/LoggerHooks';
import { CartProvider } from './components/hooks/CartContext';

import useAuth from './components/hooks/AuthHook';

import AppRoutes from './routes/AppRoutes';
import LoadingPage from './LoadingPage';

import './App.scss';
import { StoreProvider } from './components/hooks/StoreHook';

const App = () => {
  const { profile = null } = useAuth();
  const [firstLoad, setFirstLoad] = useState(false);
  useInitialProfileFetch(firstLoad, setFirstLoad);
  useProfileIntervalFetcher();
  useErrorLogger(profile);
  if (!firstLoad) {
    return <LoadingPage />;
  };
  return (
    <Router>
      <CartProvider>
        <StoreProvider>
          <StoreProfileProvider>
            <AppRoutes />
          </StoreProfileProvider>
        </StoreProvider>
      </CartProvider>
    </Router>
  );
};

export default App;
