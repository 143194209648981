import './CasualShiftItem.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { CASUAL_SHIFT_PROPS } from '../../StoreProfileProps';
import { formatDatesWithTimeZone } from '../../../../helpers/DateUtils';
import useStoreProfile from '../../../hooks/StoreProfileContext';


const propTypes = {
  /* Casual shift listed by the store. */
  shift: PropTypes.shape(CASUAL_SHIFT_PROPS).isRequired,

  /* Additional class names for the shift item. Currently, used to differentiate even/odd. */
  additionalClassNames: PropTypes.arrayOf(PropTypes.string).isRequired,

  /* Store name. */
  storeName: PropTypes.string.isRequired,
}

function CasualShiftItem({ shift, storeName, additionalClassNames, isEven }) {
  const { storeProfile } = useStoreProfile();
  const { theme, storeUrl } = storeProfile;
  const { secondaryHexColor, tertiaryHexColor } = theme;
  const { startTime, endTime, rate, _id, billToMedicare, total, address } = shift;
  const { timeZone } = address;
  const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(
    new Date(startTime), new Date(endTime), true, timeZone);
  const outboundShiftLink = `https://www.locum.ly/find/shift?id=${_id}`
  const viewShift = () => window.open(outboundShiftLink);
  const quickAdd = viewShift;
  const rateInDollars = `$${(rate / 100).toFixed(2)} p/h`;
  const totalInDollars = `$${(total / 100).toFixed(2)}`;
  const rateDisplay = billToMedicare ? null : rateInDollars;
  const totalDisplay = `Total: ${billToMedicare ? 'Bill to medicare' : totalInDollars}`;
  const timeDisplay = `${startTimeDisplay} - ${endTimeDisplay}`;
  const backgroundColor = isEven ? tertiaryHexColor : secondaryHexColor;
  const plausibleLink = `plausible-event-link=${storeUrl}`;
  const plausibleViewEventClick = 'plausible-event-name=Casual+Shift+Item+View+Click';
  const plausibleAddEventClick = 'plausible-event-name=Casual+Shift+Item+Quick+Add+Click';
  const plausibleShiftLink = `plausible-event-outbound-link=${outboundShiftLink}`;
  const plausibleEventClass = `${plausibleLink} ${plausibleShiftLink}`;
  return (
    <li
      className={`store-shift-item casual ${additionalClassNames.join(' ')}`}
      style={{ backgroundColor }}
    >
      <section className={'shift-details'}>
        <span className={'store-name'}>{storeName}</span>
        <span className={'date'}>{date}</span>
        <span className={'time'}>{timeDisplay}</span>
      </section>
      <section className={'shift-actions'}>
        <span className={'rate'}>{rateDisplay}</span>
        <span className={'total'}>{totalDisplay}</span>
        <button
          className={
            `view-shift-button very-dark ${plausibleViewEventClick} ${plausibleEventClass}`
          }
          type={'button'}
          onClick={viewShift}
        >
          {'View Shift >'}
        </button>
        <button
          className={
            `quick-add-button very-light ${plausibleAddEventClick} ${plausibleEventClass}`
          }
          type={'button'}
          onClick={quickAdd}
        >
          {'Quick Add >'}
        </button>
      </section>
    </li>
  )
}

CasualShiftItem.displayName = 'CasualShiftItem';
CasualShiftItem.propTypes = propTypes;
export default CasualShiftItem;