import { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ASSET_CDN_URL } from '../Constants';
import MapShiftPopup, { MapStorePopup } from '../locum/MapPopup.jsx';
import { orderShifts, getDefaultDates, filterShifts, filterShiftsByMonth } from '../../helpers/ShiftUtils.js';
import * as turf from '@turf/turf';
import ReactDOM from "react-dom"
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { ACCESS_TOKEN, getLocationFromCoordinates } from '../Api.js';

// Temporary fix for CORS issue on icons not loading.
import MapIcon from '../../assets/mapIcons/default-icon.png';
import TopRatedMapIcon from '../../assets/mapIcons/yellow-icon.png';
import ActiveMapIcon from '../../assets/mapIcons/purple-icon.png';
import InactiveMapIcon from '../../assets/mapIcons/grey-icon.png';
import StoreIcon from '../../assets/mapIcons/toggle-map-store.png';

// const MapIcon = `${ASSET_CDN_URL}/map/default-icon.png`;
// const TopRatedMapIcon = `${ASSET_CDN_URL}/map/yellow-icon.png`;
// const ActiveMapIcon = `${ASSET_CDN_URL}/map/purple-icon.png`;
// const InactiveMapIcon = `${ASSET_CDN_URL}/map/grey-icon.png`;
// const StoreIcon = `${ASSET_CDN_URL}/map/toggle-map-store.png`;

export const MAX_RADIUS = 500.0;

const getZoomFromRadius = (radius) => {
  if (radius >= 140) {
    return 7.2;
  }
  else if (radius >= 120) {
    return 7.5;
  }
  else if (radius >= 90) {
    return 7.8;
  }
  else if (radius >= 80) {
    return 8.2;
  }
  else if (radius >= 60) {
    return 8.5;
  }
  else if (radius >= 50) {
    return 8.8;
  }
  else if (radius >= 30) {
    return 9.5;
  }
  else if (radius >= 20) {
    return 10.0;
  }
  else if (radius >= 15) {
    return 10.6;
  }
  else if (radius >= 12.5) {
    return 10.8;
  }
  else if (radius >= 10) {
    return 11;
  }
  else if (radius >= 8) {
    return 11.5;
  }
  else if (radius >= 6) {
    return 11.7;
  }
  else if (radius >= 5) {
    return 12.0;
  }
  else if (radius >= 3) {
    return 12.5;
  }
  else if (radius >= 1) {
    return 13.0;
  }
  return 13.5;
}

mapboxgl.accessToken = ACCESS_TOKEN;

export const DEFAULT_RADIUS = 1000.0;

export const useOutsideClick = (ref, callback) => {
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    }
    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('touchstart', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('touchstart', handleOutsideClick);
    }
  }, [ref, callback]);
};

export const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);
  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [matches, query]);
  return matches;
}

export default useMediaQuery;

const locationPages = ['/', '/find', '/find/shift'];
export const useCurrentLocation = (setLocation, shouldGetCurrentLocation, searchParams = null, shouldNavigate = true) => {
  const navigate = useNavigate();
  // Including a list of pathnames to ask as this is used in navbar which can be on any page as opposed
  // to previously when it was only on findpage. This way it won't ask you for location then change your
  // page when ur just on any random page.
  const currPage = useLocation().pathname;
  useEffect(() => {
    if ('geolocation' in navigator && shouldGetCurrentLocation && locationPages.includes(currPage)) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { coords } = position;
        const { latitude, longitude } = coords; 
        getLocationFromCoordinates(longitude, latitude)
          .then((response) => {
            const locality = response.features[0].context[1].text;
            const coordinates = { longitude, latitude };
            const whereFilter = { radius: DEFAULT_RADIUS, coordinates, text: locality, searchText: locality };
            if (shouldNavigate) navigate(searchParams ? currPage + '?id=' + searchParams : currPage, { state: { location: whereFilter, whereFilter } });
            setLocation(whereFilter);
        });
      });
    }
  }, [setLocation, shouldGetCurrentLocation, navigate, currPage, searchParams, shouldNavigate]);
};

export const useSingleShiftMap = (mapContainer, map, coordinates) => {
  const { longitude, latitude } = coordinates;
  const ZOOM = 15;
  let marker = useRef(null);

  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [longitude, latitude],
      zoom: ZOOM,
      attributionControl: false
    })
    return () => map.current.remove();
  }, [longitude, latitude, map, mapContainer])

  useEffect(() => {
    if (marker.current) return;
    
    const el = document.createElement('span');
    el.className = 'shift-map-marker';
    marker.current = new mapboxgl.Marker(el)
      .setLngLat([longitude, latitude])
      .addTo(map.current);
  }, [longitude, latitude, map])
}

const mapIcons = [
  {img: MapIcon, id: 'map-icon'}, 
  {img: TopRatedMapIcon, id: 'top-rated-map-icon'},
  {img: ActiveMapIcon, id: 'active-map-icon'}, 
  {img: InactiveMapIcon, id: 'inactive-map-icon'}
];

const defaultNetworkFilter = {
  '1001_OPTICAL': true,
  'BAILEY_NELSON': true,
  'BUPA_OPTICAL': true,
  'GEORGE_AND_MATILDA': true,
  'ONE_THOUSAND_AND_ONE_OPTICAL': true,
  'OPSM': true,
  'OSCAR_WYLEE': true,
  'EYECARE_PLUS': true,
  'OTHER': true,
  'SPECSAVERS': true
};

/** Draws a map with highlighted radius for stores within a given coordinates. */
export const useMap = (mapContainer, map, location, isListView, authed, shifts, highlightedStore, setHighlightedStore, highlightedShift, setHighlightedShift, setMapStores, jobView, setMapCenter, allStores, isMobile, state, newlyAdded, showRegional, showTopRated, setIncludeEmptyStores) => {
  const { coordinates, radius } = location;
  const { longitude, latitude } = coordinates;
  let selectedShift = useRef(highlightedShift);
  let selectedStore = useRef(highlightedStore);
  const popUpRef = useRef(new mapboxgl.Popup({ offset: 15 }))

  // FILTER VARIABLES
  const dates = getDefaultDates(state);
  const useDates = state && state.whenFilter ? state.whenFilter.useDates : true;
  const shiftType = state && state.otherFilter && state.otherFilter.shiftType ? state.otherFilter.shiftType : 'OPTOMETRY';
  const networkFilter = state && state.otherFilter && state.otherFilter.networks ? state.otherFilter.networks : defaultNetworkFilter;
  

  // Initialise Map
  useEffect(() => {
    if (isListView) return;
    const zoom = getZoomFromRadius(radius);
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [longitude, latitude],
      zoom: zoom,
      attributionControl: false,
    });

    map.current.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: false,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
        showUserHeading: true,
        fitBoundsOptions: {
          maxZoom: 10
        },
        maxZoom: 15,
        minZoom: 5
      }),
      'bottom-right'
    );

    map.current.addControl(new mapboxgl.AttributionControl(), 'top-left');

    return () => map.current.remove();
  }, [longitude, latitude, radius, map, mapContainer, isListView, authed, jobView, shifts, state, allStores, newlyAdded, showRegional, showTopRated]);

  // Set Location Marker
  useEffect(() => {
    if (isListView) return;
    
    new mapboxgl.Marker()
      .setLngLat([longitude, latitude])
      .addTo(map.current);

  }, [longitude, latitude, map, isListView, jobView])

  // Initialise shift data
  useEffect(() => {
    if (isListView || !jobView) return;

    // let bounds = map.current.getBounds();
    // let boundsGeo = turf.polygon([
    //   [
    //     [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
    //     [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
    //     [bounds.getSouthEast().lng, bounds.getSouthEast().lat],
    //     [bounds.getSouthWest().lng, bounds.getSouthWest().lat],
    //     [bounds.getNorthWest().lng, bounds.getNorthWest().lat]
    //   ]
    // ]);

    map.current.on('load', async () => {
        Promise.all(
          mapIcons.map(icon => new Promise((resolve, reject) => {
            map.current.loadImage(icon.img, (error, image) => {
              if (error) throw error;
              map.current.addImage(icon.id, image)
              resolve();
            })
          }))
        ).then(() => {
          let storeData = [];
          let topRatedStoreData = [];
          let storeCoordinates = {};
          let filteredShifts = shifts;
          const filterApplied = (newlyAdded || showRegional || showTopRated)
          if ((state || filterApplied) && shifts && shifts.length > 0) {
            filteredShifts = useDates ? filterShifts(shifts, shiftType, dates.fromDate, dates.toDate, location, networkFilter, newlyAdded, showRegional, showTopRated) : filterShiftsByMonth(shifts, shiftType, dates.searchMethod, dates.selectedItems, location, networkFilter, newlyAdded, showRegional, showTopRated);
          }
          if (filteredShifts !== null) {
            filteredShifts.forEach(shift => {
              const shiftPoint = [shift.address.coordinates.longitude, shift.address.coordinates.latitude];
              // if (turf.booleanPointInPolygon(shiftPoint, boundsGeo)) {
                if (storeCoordinates.hasOwnProperty(shift.storeId)) {
                  storeCoordinates[shift.storeId] = {
                    coordinates: shift.address.coordinates,
                    shifts: [...storeCoordinates[shift.storeId].shifts, shift._id],
                    isTopRated: shift.isTopRated,
                  }
                } else {
                  storeCoordinates[shift.storeId] = {
                    coordinates: shift.address.coordinates,
                    shifts: [shift._id],
                    isTopRated: shift.isTopRated,
                  }
                }
              // }
            });
            setMapStores(Object.keys(storeCoordinates));
          }
    
          // Turn shift data into GeoJSON format
          if (Object.keys(storeCoordinates).length !== 0) {
            Object.keys(storeCoordinates).forEach(storeId => { 
              
              const shiftPoint = [storeCoordinates[storeId].coordinates.longitude, storeCoordinates[storeId].coordinates.latitude];
              if (storeCoordinates[storeId].isTopRated) {
                const topRatedStore = {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: shiftPoint
                  },
                  properties: {
                    storeId,
                    shifts: storeCoordinates[storeId].shifts.join(','),
                    isTopRated: storeCoordinates[storeId].isTopRated,
                  }
                }
                topRatedStoreData.push(topRatedStore);
              } else {
                const store = {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: shiftPoint
                  },
                  properties: {
                    storeId,
                    shifts: storeCoordinates[storeId].shifts.join(','),
                    isTopRated: storeCoordinates[storeId].isTopRated,
                  }
                }
                storeData.push(store);
              }
            });
          }

          // Store Data
          let storeGeoData = {
            type: 'FeatureCollection',
            features: storeData
          };

          if (!map.current.getSource('shifts')) {
            map.current.addSource('shifts', {
              type: 'geojson',
              data: storeGeoData
            })
          } else {
            map.current.getSource('shifts').setData({
              type: 'FeatureCollection', 
              features: storeGeoData
            });
          }

          if (!map.current.getLayer('shifts')) {
            map.current.addLayer({
              id: 'shifts',
              source: 'shifts',
              type: 'symbol',
              layout: {
                'icon-image': 'map-icon',
                'icon-padding': 0,
                'icon-allow-overlap': true,
                'icon-size': 0.3
              }
            })
          }

          // Top Rated Store Data
          let topRatedStoreGeoData = {
            type: 'FeatureCollection',
            features: topRatedStoreData
          };

          if (!map.current.getSource('topRatedShifts')) {
            map.current.addSource('topRatedShifts', {
              type: 'geojson',
              data: topRatedStoreGeoData
            })
          } else {
            map.current.getSource('topRatedShifts').setData({
              type: 'FeatureCollection', 
              features: topRatedStoreGeoData
            });
          }

          if (!map.current.getLayer('topRatedShifts')) {
            map.current.addLayer({
              id: 'topRatedShifts',
              source: 'topRatedShifts',
              type: 'symbol',
              layout: {
                'icon-image': 'top-rated-map-icon',
                'icon-padding': 0,
                'icon-allow-overlap': true,
                'icon-size': 0.3
              }
            })
          }

          if (isMobile) {
            // Top Rated Shifts
            map.current.on('click', 'topRatedShifts', e => {
              const storeInfo = e.features[0].properties;
              const shifts = storeInfo.shifts.split(',');
              setHighlightedShift(storeInfo.storeId);
              selectedShift.current = storeInfo.storeId;
              document.getElementById(shifts[0]).scrollIntoView();
              map.current.setLayoutProperty('topRatedShifts', 'icon-image', ['match', ['get', 'storeId'], storeInfo.storeId, 'active-map-icon', 'top-rated-map-icon']);
            })
  
            const topRatedStoreIds = storeData.map(store => store.properties.storeId);
            if ((selectedShift.current === null || !topRatedStoreIds.includes(selectedShift.current)) && storeData.length !== 0) {
              setHighlightedShift(topRatedStoreIds[0]);
              selectedShift.current = topRatedStoreIds[0];
              document.getElementById(storeData[0].properties.shifts.split(',')[0]).scrollIntoView();
              map.current.setLayoutProperty('topRatedShifts', 'icon-image', ['match', ['get', 'storeId'], topRatedStoreIds[0], 'active-map-icon', 'top-rated-map-icon']);
            }
  
            if (selectedShift.current !== null && topRatedStoreIds.includes(selectedShift)) {
              document.getElementById(storeCoordinates[selectedShift.current].shifts[0]).scrollIntoView();
              map.current.setLayoutProperty('topRatedShifts', 'icon-image', ['match', ['get', 'storeId'], selectedShift.current, 'active-map-icon', 'top-rated-map-icon']);
            }

            // Shifts
            map.current.on('click', 'shifts', e => {
              const storeInfo = e.features[0].properties;
              const shifts = storeInfo.shifts.split(',');
              setHighlightedShift(storeInfo.storeId);
              selectedShift.current = storeInfo.storeId;
              document.getElementById(shifts[0]).scrollIntoView();
              map.current.setLayoutProperty('shifts', 'icon-image', ['match', ['get', 'storeId'], storeInfo.storeId, 'active-map-icon', 'map-icon']);
            })
  
            const storeIds = storeData.map(store => store.properties.storeId);
            if ((selectedShift.current === null || !storeIds.includes(selectedShift.current)) && storeData.length !== 0) {
              setHighlightedShift(storeIds[0]);
              selectedShift.current = storeIds[0];
              document.getElementById(storeData[0].properties.shifts.split(',')[0]).scrollIntoView();
              map.current.setLayoutProperty('shifts', 'icon-image', ['match', ['get', 'storeId'], storeIds[0], 'active-map-icon', 'map-icon']);
            }
  
            if (selectedShift.current !== null && storeIds.includes(selectedShift)) {
              document.getElementById(storeCoordinates[selectedShift.current].shifts[0]).scrollIntoView();
              map.current.setLayoutProperty('shifts', 'icon-image', ['match', ['get', 'storeId'], selectedShift.current, 'active-map-icon', 'map-icon']);
            }
          }
        })
    })
  }, [longitude, latitude, isListView, jobView, shifts, highlightedShift, isMobile, state, dates, location, map, 
    networkFilter, setHighlightedShift, setMapStores, shiftType, useDates, newlyAdded, showRegional, showTopRated])

  useEffect(() => {
    if (isListView || !jobView || isMobile) return;
    
    map.current.on('click', 'shifts', e => {
      if (e.features.length > 0) {
        const clickedShifts = e.features[0].properties.shifts.split(',');
        const popupShifts = orderShifts(shifts.filter(shift => clickedShifts.includes(shift._id)), 'date-ascending');
        const popupNode = document.createElement("div")
        popupNode.className = 'popup-shift-container';
        ReactDOM.render(
          <MapShiftPopup
            shifts={popupShifts}
            location={location}
          />,
          popupNode
        )
        popUpRef.current
          .setLngLat(e.lngLat)
          .setDOMContent(popupNode)
          .addTo(map.current)
      }
    })

    map.current.on('click', 'topRatedShifts', e => {
      if (e.features.length > 0) {
        const clickedShifts = e.features[0].properties.shifts.split(',');
        const popupShifts = orderShifts(shifts.filter(shift => clickedShifts.includes(shift._id)), 'date-ascending');
        const popupNode = document.createElement("div")
        popupNode.className = 'popup-shift-container';
        ReactDOM.render(
          <MapShiftPopup
            shifts={popupShifts}
            location={location}
          />,
          popupNode
        )
        popUpRef.current
          .setLngLat(e.lngLat)
          .setDOMContent(popupNode)
          .addTo(map.current)
      }
    })
  }, [longitude, latitude, isListView, jobView, shifts, highlightedShift, isMobile, state, location, map, newlyAdded, showRegional, showTopRated]);

  // useEffect(() => {
  //   if (shifts === null || isListView || !jobView) return;

  //   map.current.on('moveend', async () => {
  //     let center = map.current.getCenter();
  //     setMapCenter({longitude: center.lng, latitude: center.lat});
  //     let bounds = map.current.getBounds();
  //     let boundsGeo = turf.polygon([
  //       [
  //         [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
  //         [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
  //         [bounds.getSouthEast().lng, bounds.getSouthEast().lat],
  //         [bounds.getSouthWest().lng, bounds.getSouthWest().lat],
  //         [bounds.getNorthWest().lng, bounds.getNorthWest().lat]
  //       ]
  //     ]);

  //     let storeData = [];
  //     let storeCoordinates = {};
  //     let filteredShifts = shifts;
  //     if (state && shifts && shifts.length > 0) {
  //       filteredShifts = useDates ? filterShifts(shifts, shiftType, dates.fromDate, dates.toDate, location, networkFilter, newlyAdded, showRegional, showTopRated) : filterShiftsByMonth(shifts, shiftType, dates.searchMethod, dates.selectedItems, location, networkFilter, newlyAdded, showRegional, showTopRated);
  //     }
  //     if (filteredShifts !== null) {
  //       filteredShifts.forEach(shift => {
  //         const shiftPoint = [shift.address.coordinates.longitude, shift.address.coordinates.latitude];
  //         if (turf.booleanPointInPolygon(shiftPoint, boundsGeo)) {
  //           if (storeCoordinates.hasOwnProperty(shift.storeId)) {
  //             storeCoordinates[shift.storeId] = {
  //               coordinates: shift.address.coordinates,
  //               shifts: [...storeCoordinates[shift.storeId].shifts, shift._id]
  //             }
  //           } else {
  //             storeCoordinates[shift.storeId] = {
  //               coordinates: shift.address.coordinates,
  //               shifts: [shift._id]
  //             }
  //           }
  //         }
  //       });
  //       setMapStores(Object.keys(storeCoordinates));
  //     }

  //     // Turn shift data into GeoJSON format
  //     if (Object.keys(storeCoordinates).length !== 0) {
  //       Object.keys(storeCoordinates).forEach(storeId => { 
          
  //         const shiftPoint = [storeCoordinates[storeId].coordinates.longitude, storeCoordinates[storeId].coordinates.latitude];
  //         const store = {
  //           type: 'Feature',
  //           geometry: {
  //             type: 'Point',
  //             coordinates: shiftPoint
  //           },
  //           properties: {
  //             storeId,
  //             shifts: storeCoordinates[storeId].shifts.join(',')
  //           }
  //         }
  //         storeData.push(store);
  //       });
  //     }

  //     const shiftSource = map.current.getSource('shifts');
  //     if (shiftSource) {
  //       shiftSource.setData({
  //         type: 'FeatureCollection', 
  //         features: storeData
  //       });
  //     }

  //     if (isMobile) {
  //       const storeIds = storeData.map(store => store.properties.storeId);
  //       if (storeIds.length !== 0 && !storeIds.includes(selectedShift)) {
  //         const newActiveStore = storeData[0].properties;
  //         const shifts = newActiveStore.shifts.split(',');
  //         setHighlightedShift(newActiveStore.storeId);
  //         selectedShift.current = newActiveStore.storeId;
  //         document.getElementById(shifts[0]).scrollIntoView();
  //         map.current.setLayoutProperty('shifts', 'icon-image', ['match', ['get', 'storeId'], selectedShift.current, 'active-map-icon', 'map-icon'])
  //       }
  //     }
  //   })

  // }, [map, longitude, latitude, shifts, isListView, jobView, state, dates, isMobile, location, networkFilter, 
  //   setHighlightedShift, setMapCenter, setMapStores, shiftType, useDates, newlyAdded, showRegional, showTopRated])

  // Initialise store data
  useEffect(() => {
    if (isListView || jobView) return;

    // let bounds = map.current.getBounds();
    // let boundsGeo = turf.polygon([
    //   [
    //     [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
    //     [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
    //     [bounds.getSouthEast().lng, bounds.getSouthEast().lat],
    //     [bounds.getSouthWest().lng, bounds.getSouthWest().lat],
    //     [bounds.getNorthWest().lng, bounds.getNorthWest().lat]
    //   ]
    // ]);

    map.current.on('load', async () => {
        Promise.all(
          mapIcons.map(icon => new Promise((resolve, reject) => {
            map.current.loadImage(icon.img, (error, image) => {
              if (error) throw error;
              if (!map.current.hasImage(icon.id)) {
                map.current.addImage(icon.id, image)
              }
              resolve();
            })
          }))
        ).then(() => {
          let storeData = [];
          let emptyStoreData = [];
          let topRatedStoreData = [];
          let storeCoordinates = {};
          let filteredShifts = shifts;
          const filterApplied = (newlyAdded || showRegional || showTopRated);
          if ((state || filterApplied) && shifts && shifts.length > 0) {
            console.log(filteredShifts);
            filteredShifts = useDates ? filterShifts(shifts, shiftType, dates.fromDate, dates.toDate, location, networkFilter, newlyAdded, showRegional, showTopRated) : filterShiftsByMonth(shifts, shiftType, dates.searchMethod, dates.selectedItems, location, networkFilter, newlyAdded, showRegional, showTopRated);
            console.log(filteredShifts);
          }
          let shiftIds = [];
          if (filteredShifts && filteredShifts.length > 0) {
            shiftIds = filteredShifts.map(shift => shift._id);
          }
          if (allStores !== null) {
            const filteredStores = allStores
              .filter(store => store.type === shiftType)
              .filter(({ datePosted }) => !newlyAdded || ((Math.floor(Math.abs(new Date(datePosted) - new Date()) / (36e5 * 24)) < 2) === newlyAdded))
              .filter(({ isTopRated }) => !showTopRated || isTopRated === showTopRated)
              .filter(({ isRegional }) => !showRegional || isRegional === 'REGIONAL')
            filteredStores.forEach(store => {
              const shiftPoint = [store.address.coordinates.longitude, store.address.coordinates.latitude];
              // if (turf.booleanPointInPolygon(shiftPoint, boundsGeo)) {
                storeCoordinates[store._id] = {
                  coordinates: store.address.coordinates,
                  shifts: store.shifts.filter(shift => shiftIds.includes(shift)),
                  isTopRated: store.isTopRated,
                }
              // }
            });
            setMapStores(Object.keys(storeCoordinates));
          }
          // Turn shift data into GeoJSON format
          if (Object.keys(storeCoordinates).length !== 0) {
            Object.keys(storeCoordinates).forEach(storeId => { 
              
              const shiftPoint = [storeCoordinates[storeId].coordinates.longitude, storeCoordinates[storeId].coordinates.latitude];

               if (storeCoordinates[storeId].shifts.length === 0) {
                const emptyStore = {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: shiftPoint
                  },
                  properties: {
                    storeId,
                    shifts: storeCoordinates[storeId].shifts.join(',')
                  }
                }
                emptyStoreData.push(emptyStore);
              } else if (storeCoordinates[storeId].isTopRated) {
                const topRatedStore = {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: shiftPoint
                  },
                  properties: {
                    storeId,
                    shifts: storeCoordinates[storeId].shifts.join(','),
                    isTopRated: storeCoordinates[storeId].isTopRated,
                  }
                }
                topRatedStoreData.push(topRatedStore);
              } else {
                const store = {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: shiftPoint
                  },
                  properties: {
                    storeId,
                    shifts: storeCoordinates[storeId].shifts.join(',')
                  }
                }
                storeData.push(store);
              }
            });
          }

          // Setup data for stores with shifts
          let storeGeoData = {
            type: 'FeatureCollection',
            features: storeData
          };

          if (!map.current.getSource('stores')) {
            map.current.addSource('stores', {
              type: 'geojson',
              data: storeGeoData
            })
          } else {
            map.current.getSource('stores').setData({
              type: 'FeatureCollection', 
              features: storeData
            });
          }

          if (!map.current.getLayer('stores')) {
            map.current.addLayer({
              id: 'stores',
              source: 'stores',
              type: 'symbol',
              layout: {
                'icon-image': 'map-icon',
                'icon-padding': 0,
                'icon-allow-overlap': true,
                'icon-size': 0.3
              }
            })
          }

          // Setup data for top rated stores with shifts.
          let topRatedStoreGeoData = {
            type: 'FeatureCollection',
            features: topRatedStoreData
          };

          if (!map.current.getSource('topRatedStores')) {
            map.current.addSource('topRatedStores', {
              type: 'geojson',
              data: topRatedStoreGeoData
            })
          } else {
            map.current.getSource('topRatedStores').setData({
              type: 'FeatureCollection', 
              features: topRatedStoreGeoData
            });
          }

          if (!map.current.getLayer('topRatedStores')) {
            map.current.addLayer({
              id: 'topRatedStores',
              source: 'topRatedStores',
              type: 'symbol',
              layout: {
                'icon-image': 'top-rated-map-icon',
                'icon-padding': 0,
                'icon-allow-overlap': true,
                'icon-size': 0.3
              }
            })
          }

          // Setup data for stores with no shifts
          let emptyStoreGeoData = {
            type: 'FeatureCollection',
            features: emptyStoreData
          };

          if (!map.current.getSource('emptyStores')) {
            map.current.addSource('emptyStores', {
              type: 'geojson',
              data: emptyStoreGeoData
            })
          } else {
            map.current.getSource('emptyStores').setData({
              type: 'FeatureCollection', 
              features: emptyStoreGeoData
            });
          }

          if (!map.current.getLayer('emptyStores')) {
            map.current.addLayer({
              id: 'emptyStores',
              source: 'emptyStores',
              type: 'symbol',
              layout: {
                'visibility': 'visible',
                'icon-image': 'inactive-map-icon',
                'icon-padding': 0,
                'icon-allow-overlap': true,
                'icon-size': 0.3
              }
            })
          }

          if (isMobile) {
            // TopRatedStores
            map.current.on('click', 'topRatedStores', e => {
              const storeInfo = e.features[0].properties;
              setHighlightedStore(storeInfo.storeId);
              selectedStore.current = storeInfo.storeId;
              const storeElem = document.getElementById(selectedStore.current);
              if (storeElem) {
                storeElem.scrollIntoView();
              }
              map.current.setLayoutProperty('topRatedStores', 'icon-image', ['match', ['get', 'storeId'], selectedStore.current, 'active-map-icon', 'top-rated-map-icon']);
            })
  
            const topRatedStoreIds = storeData.map(store => store.properties.storeId);
            if ((selectedStore.current === null || !topRatedStoreIds.includes(selectedStore.current)) && storeData.length !== 0) {
              setHighlightedStore(topRatedStoreIds[0]);
              selectedStore.current = topRatedStoreIds[0]
              const storeElem = document.getElementById(selectedStore.current);
              if (storeElem) {
                storeElem.scrollIntoView();
              }
              map.current.setLayoutProperty('topRatedStores', 'icon-image', ['match', ['get', 'storeId'], selectedStore.current, 'active-map-icon', 'top-rated-map-icon']);
            }
  
            if (selectedStore.current !== null && topRatedStoreIds.includes(selectedStore.current)) {
              document.getElementById(selectedStore.current).scrollIntoView();
              map.current.setLayoutProperty('topRatedStores', 'icon-image', ['match', ['get', 'storeId'], selectedStore.current, 'active-map-icon', 'top-rated-map-icon']);
            }

            // Stores
            map.current.on('click', 'stores', e => {
              const storeInfo = e.features[0].properties;
              setHighlightedStore(storeInfo.storeId);
              selectedStore.current = storeInfo.storeId;
              const storeElem = document.getElementById(selectedStore.current);
              if (storeElem) {
                storeElem.scrollIntoView();
              }
              map.current.setLayoutProperty('stores', 'icon-image', ['match', ['get', 'storeId'], selectedStore.current, 'active-map-icon', 'map-icon']);
            })
  
            const storeIds = storeData.map(store => store.properties.storeId);
            if ((selectedStore.current === null || !storeIds.includes(selectedStore.current)) && storeData.length !== 0) {
              setHighlightedStore(storeIds[0]);
              selectedStore.current = storeIds[0]
              const storeElem = document.getElementById(selectedStore.current);
              if (storeElem) {
                storeElem.scrollIntoView();
              }
              map.current.setLayoutProperty('stores', 'icon-image', ['match', ['get', 'storeId'], selectedStore.current, 'active-map-icon', 'map-icon']);
            }
  
            if (selectedStore.current !== null && storeIds.includes(selectedStore.current)) {
              document.getElementById(selectedStore.current).scrollIntoView();
              map.current.setLayoutProperty('stores', 'icon-image', ['match', ['get', 'storeId'], selectedStore.current, 'active-map-icon', 'map-icon']);
            }
  
            // Empty Stores
            map.current.on('click', 'emptyStores', e => {
              const storeInfo = e.features[0].properties;
              setHighlightedStore(storeInfo.storeId);
              selectedStore.current = storeInfo.storeId;
              const storeElem = document.getElementById(selectedStore.current);
              if (storeElem) {
                storeElem.scrollIntoView();
              }
              map.current.setLayoutProperty('emptyStores', 'icon-image', ['match', ['get', 'storeId'], selectedStore.current, 'active-map-icon', 'inactive-map-icon']);
            })
  
            const emptyStoreIds = emptyStoreData.map(store => store.properties.storeId);
            if ((selectedStore.current === null || !emptyStoreIds.includes(selectedStore.current)) && storeData.length !== 0) {
              setHighlightedStore(emptyStoreIds[0]);
              selectedStore.current = emptyStoreIds[0]
              const storeElem = document.getElementById(selectedStore.current);
              if (storeElem) {
                storeElem.scrollIntoView();
              }
              map.current.setLayoutProperty('emptyStores', 'icon-image', ['match', ['get', 'storeId'], selectedStore.current, 'active-map-icon', 'inactive-map-icon']);
            }
  
            if (selectedStore.current !== null && emptyStoreIds.includes(selectedStore.current)) {
              const storeElem = document.getElementById(selectedStore.current);
              if (storeElem) {
                storeElem.scrollIntoView();
              }
              map.current.setLayoutProperty('emptyStores', 'icon-image', ['match', ['get', 'storeId'], selectedStore.current, 'active-map-icon', 'inactive-map-icon']);
            }
          }
        })
    })
  }, [longitude, latitude, isListView, jobView, allStores, highlightedStore, shifts, state, dates, isMobile, location, map, 
    networkFilter, setHighlightedStore, setMapStores, shiftType, useDates, newlyAdded, showRegional, showTopRated])

  useEffect(() => {
    if (isListView || jobView || isMobile) return;
    
    map.current.on('click', 'stores', e => {
      if (e.features.length > 0) {
        const clickedShifts = e.features[0].properties.shifts.split(',');
        const clickedStore = e.features[0].properties.storeId;
        const filteredStores = allStores.filter(store => store.type === shiftType);
        const popupStore = filteredStores.filter(store => store._id === clickedStore);
        // const popupShifts = orderShifts(shifts.filter(shift => clickedShifts.includes(shift._id)), 'date-ascending');
        const popupNode = document.createElement("div")
        popupNode.className = 'popup-shift-container';
        ReactDOM.render(
          <MapStorePopup
            store={popupStore[0]}
            shifts={clickedShifts}
            location={location}
          />,
          popupNode
        )
        popUpRef.current
          .setLngLat(e.lngLat)
          .setDOMContent(popupNode)
          .addTo(map.current)
      }
    })

    map.current.on('click', 'topRatedStores', e => {
      if (e.features.length > 0) {
        const clickedShifts = e.features[0].properties.shifts.split(',');
        const clickedStore = e.features[0].properties.storeId;
        const filteredStores = allStores.filter(store => store.type === shiftType);
        const popupStore = filteredStores.filter(store => store._id === clickedStore);
        // const popupShifts = orderShifts(shifts.filter(shift => clickedShifts.includes(shift._id)), 'date-ascending');
        const popupNode = document.createElement("div")
        popupNode.className = 'popup-shift-container';
        ReactDOM.render(
          <MapStorePopup
            store={popupStore[0]}
            shifts={clickedShifts}
            location={location}
          />,
          popupNode
        )
        popUpRef.current
          .setLngLat(e.lngLat)
          .setDOMContent(popupNode)
          .addTo(map.current)
      }
    })

    map.current.on('click', 'emptyStores', e => {
      if (e.features.length > 0) {
        const clickedShifts = e.features[0].properties.shifts;
        const clickedStore = e.features[0].properties.storeId;
        const filteredStores = allStores.filter(store => store.type === shiftType);
        const popupStore = filteredStores.filter(store => store._id === clickedStore);
        // const popupShifts = orderShifts(shifts.filter(shift => clickedShifts.includes(shift._id)), 'date-ascending');
        const popupNode = document.createElement("div")
        popupNode.className = 'popup-shift-container';
        ReactDOM.render(
          <MapStorePopup
            store={popupStore[0]}
            shifts={clickedShifts}
            location={location}
          />,
          popupNode
        )
        popUpRef.current
          .setLngLat(e.lngLat)
          .setDOMContent(popupNode)
          .addTo(map.current)
      }
    })
  }, [longitude, latitude, isListView, jobView, shifts, highlightedShift, isMobile, state, allStores, location, map, shiftType, newlyAdded, showRegional, showTopRated]);

  useEffect(() => {
    if (jobView || isListView) return;
    // After the last frame rendered before the map enters an "idle" state.
    map.current.on('idle', () => {
      // If these two layers were not added to the map, abort
      if (!map.current.getLayer('emptyStores')) {
        return;
      }
      // Enumerate ids of the layers.
      const toggleableLayerIds = ['emptyStores'];
      
      // Set up the corresponding toggle button for each layer.
      for (const id of toggleableLayerIds) {
        // Skip layers that already have a button set up.
        if (document.getElementById(id)) {
          continue;
        }
      
        // Create a link.
        const link = document.createElement('div');
        link.id = id;
        link.href = '#';
        // link.textContent = 'Toggle Stores';
        link.className = 'active';

        const linkImg = document.createElement('img');
        linkImg.src = StoreIcon;
        linkImg.className = 'store-icon';
        linkImg.alt = 'toggle store icon'
        
        link.appendChild(linkImg);
        // Show or hide layer when the toggle is clicked.
        link.onclick = function (e) {
          const clickedLayer = id;
          e.preventDefault();
          e.stopPropagation();
          const visibility = map.current.getLayoutProperty(
          clickedLayer,
          'visibility'
          );
          
          // Toggle layer visibility by changing the layout object's visibility property.
          if (visibility === 'visible') {
            map.current.setLayoutProperty(clickedLayer, 'visibility', 'none');
            this.className = '';
            setIncludeEmptyStores(false);
          } else {
            this.className = 'active';
            map.current.setLayoutProperty(
            clickedLayer,
            'visibility',
            'visible'
            );
            setIncludeEmptyStores(true);
          }
        };
        
        const layers = document.getElementById('menu');
        layers.appendChild(link);
      }
    });
  }, [longitude, latitude, isListView, jobView, allStores, highlightedStore, shifts, state, map, newlyAdded, showRegional, showTopRated, setIncludeEmptyStores])

  // useEffect(() => {
  //   if (allStores === null || isListView || jobView) return;

  //   map.current.on('moveend', async () => {
  //     let center = map.current.getCenter();
  //     setMapCenter({longitude: center.lng, latitude: center.lat});
  //     let bounds = map.current.getBounds();
  //     let boundsGeo = turf.polygon([
  //       [
  //         [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
  //         [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
  //         [bounds.getSouthEast().lng, bounds.getSouthEast().lat],
  //         [bounds.getSouthWest().lng, bounds.getSouthWest().lat],
  //         [bounds.getNorthWest().lng, bounds.getNorthWest().lat]
  //       ]
  //     ]);

  //     let storeData = [];
  //     let emptyStoreData = [];
  //     let topRatedStoreData = [];
  //     let storeCoordinates = {};
  //     let filteredShifts = shifts;
  //     const filterApplied = (newlyAdded && showRegional && showTopRated);
  //     if ((state || filterApplied) && shifts && shifts.length > 0) {
  //       filteredShifts = useDates ? filterShifts(shifts, shiftType, dates.fromDate, dates.toDate, location, networkFilter, newlyAdded, showRegional, showTopRated) : filterShiftsByMonth(shifts, shiftType, dates.searchMethod, dates.selectedItems, location, networkFilter, newlyAdded, showRegional, showTopRated);
  //     }
  //     let shiftIds = [];
  //     if (filteredShifts && filteredShifts.length > 0) {
  //       shiftIds = filteredShifts.map(shift => shift._id);
  //     }
  //     if (allStores !== null) {
  //       const filteredStores = allStores
  //         .filter(store => store.type === shiftType)
  //         .filter(({ datePosted }) => !newlyAdded || ((Math.floor(Math.abs(new Date(datePosted) - new Date()) / (36e5 * 24)) < 2) === newlyAdded))
  //         .filter(({ isTopRated }) => !showTopRated || isTopRated === showTopRated)
  //         .filter(({ isRegional }) => !showRegional || isRegional === 'REGIONAL')
  //       filteredStores.forEach(store => {
  //         const shiftPoint = [store.address.coordinates.longitude, store.address.coordinates.latitude];
  //         if (turf.booleanPointInPolygon(shiftPoint, boundsGeo)) {
  //           storeCoordinates[store._id] = {
  //             coordinates: store.address.coordinates,
  //             shifts: store.shifts.filter(shift => shiftIds.includes(shift)),
  //             isTopRated: store.isTopRated,
  //           }
  //         }
  //       });
  //       setMapStores(Object.keys(storeCoordinates));
  //     }

  //     // Turn shift data into GeoJSON format
  //     if (Object.keys(storeCoordinates).length !== 0) {
  //       Object.keys(storeCoordinates).forEach(storeId => { 
  //         console.log(storeCoordinates[storeId]);
  //         const shiftPoint = [storeCoordinates[storeId].coordinates.longitude, storeCoordinates[storeId].coordinates.latitude];
  //         if (storeCoordinates[storeId].shifts.length === 0) {
  //           const emptyStore = {
  //             type: 'Feature',
  //             geometry: {
  //               type: 'Point',
  //               coordinates: shiftPoint
  //             },
  //             properties: {
  //               storeId,
  //               shifts: storeCoordinates[storeId].shifts.join(',')
  //             }
  //           }
  //           emptyStoreData.push(emptyStore);
  //         } else if (storeCoordinates[storeId].isTopRated) {
            
  //           const topRatedStore = {
  //             type: 'Feature',
  //             geometry: {
  //               type: 'Point',
  //               coordinates: shiftPoint
  //             },
  //             properties: {
  //               storeId,
  //               shifts: storeCoordinates[storeId].shifts.join(','),
  //               isTopRated: storeCoordinates[storeId].isTopRated,
  //             }
  //           }
  //           topRatedStoreData.push(topRatedStore);
  //         }  else {
  //           const store = {
  //             type: 'Feature',
  //             geometry: {
  //               type: 'Point',
  //               coordinates: shiftPoint
  //             },
  //             properties: {
  //               storeId,
  //               shifts: storeCoordinates[storeId].shifts.join(',')
  //             }
  //           }
  //           storeData.push(store);
  //         }
  //       });
  //     }

  //     map.current.getSource('stores').setData({
  //       type: 'FeatureCollection', 
  //       features: storeData
  //     });

  //     map.current.getSource('topRatedStores').setData({
  //       type: 'FeatureCollection', 
  //       features: topRatedStoreData
  //     });

  //     map.current.getSource('emptyStores').setData({
  //       type: 'FeatureCollection', 
  //       features: emptyStoreData
  //     });

  //     if (isMobile) {
  //       const storeIds = storeData.map(store => store.properties.storeId);
  //       const emptyStoreIds = emptyStoreData.map(store => store.properties.storeId);
  //       // There are stores with shifts, and no stores with no shifts, and selectedStore.current is not in the stores with shifts
  //       if (storeIds.length !== 0 && emptyStoreIds.length === 0 && !storeIds.includes(selectedStore.current)) {
  //         const newActiveStore = storeData[0].properties;
  //         setHighlightedStore(newActiveStore.storeId);
  //         selectedStore.current = newActiveStore.storeId;
  //         document.getElementById(selectedStore.current).scrollIntoView();
  //         map.current.setLayoutProperty('stores', 'icon-image', ['match', ['get', 'storeId'], selectedStore.current, 'active-map-icon', 'map-icon'])
  //       } else if (storeIds.length === 0 && emptyStoreIds.length !== 0 && !emptyStoreIds.includes(selectedStore.current)) {
  //         const newActiveStore = emptyStoreData[0].properties;
  //         setHighlightedStore(newActiveStore.storeId);
  //         selectedStore.current = newActiveStore.storeId;
  //         document.getElementById(selectedStore.current).scrollIntoView();
  //         map.current.setLayoutProperty('emptyStores', 'icon-image', ['match', ['get', 'storeId'], selectedStore.current, 'active-map-icon', 'inactive-map-icon'])
  //       } else if (storeIds.length !== 0 && emptyStoreIds.length !== 0 && !storeIds.includes(selectedStore.current) && !emptyStoreIds.includes(selectedStore.current)) {
  //         const newActiveStore = storeData[0].properties;
  //         setHighlightedStore(newActiveStore.storeId);
  //         selectedStore.current = newActiveStore.storeId;
  //         document.getElementById(selectedStore.current).scrollIntoView();
  //         map.current.setLayoutProperty('stores', 'icon-image', ['match', ['get', 'storeId'], selectedStore.current, 'active-map-icon', 'map-icon']);
  //         map.current.setLayoutProperty('emptyStores', 'icon-image', 'inactive-map-icon');
  //       }
  //     }
  //   })

  // }, [map, longitude, latitude, allStores, isListView, jobView, shifts, state, dates, isMobile, location, networkFilter, 
  //   setHighlightedStore, setMapCenter, setMapStores, shiftType, useDates, newlyAdded, showRegional, showTopRated])
}
