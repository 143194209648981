import { ASSET_CDN_URL, DEFAULT_STORE_AVATAR } from '../Constants';

const XpectikoLogo = `${ASSET_CDN_URL}/partners/xpectiko-logo.svg`;
const OWLogo = `${ASSET_CDN_URL}/partners/oscar-wylee-logo.svg`;
const SSLogo = `${ASSET_CDN_URL}/partners/specsavers-logo.svg`;
const GMLogo = `${ASSET_CDN_URL}/partners/george-and-matilda-logo.svg`;
const IContactLogo = `${ASSET_CDN_URL}/partners/icontact-logo.svg`;
const BerwickLogo = `${ASSET_CDN_URL}/partners/berwick-logo.svg`;
const NepeanLogo = `${ASSET_CDN_URL}/partners/nepean-optical-logo.svg`;
const DresdenLogo = `${ASSET_CDN_URL}/partners/dresden-logo.svg`;
const Eye88Logo = `${ASSET_CDN_URL}/partners/eye88-logo.svg`;
const KevinPaisleyLogo = `${ASSET_CDN_URL}/partners/kevin-paisley-logo.svg`;
const OPSMLogo = `${ASSET_CDN_URL}/partners/opsm-logo.svg`;
const YarraJunctionLogo = `${ASSET_CDN_URL}/partners/yarra-junction-logo.svg`;
const TaylorEyewearLogo = `${ASSET_CDN_URL}/partners/taylor-eyewear-logo.svg`;

/** Used in PartnersSection Component. List of Locumly Partners. */
export const PARTNER_LIST = [
  { image: GMLogo, name: 'George & Matilda', title: 'Optometry', url: 'https://georgeandmatilda.com.au/' },
  { image: OWLogo, name: 'Oscar Wylee', title: 'Optometry', url: 'https://www.oscarwylee.com.au/' },
  { image: IContactLogo, name: 'iContact Optometrists', title: 'Optometry', url: 'http://www.icontact.net.au/' },
  { image: DresdenLogo, name: 'Dresden Vision', title: 'Optometry', url: 'https://dresden.vision/' },
  { image: Eye88Logo, name: 'Eye88 Optical', title: 'Optometry', url: 'https://www.yelp.com/biz/optical-88-vision-centre-chatswood' },
  { image: TaylorEyewearLogo, name: 'Taylor Eyewear', title: 'Optometry', url: 'https://tayloreyewear.com.au/' },
  { image: SSLogo, name: 'Specsavers', title: 'Optometry', url: 'https://www.specsavers.com.au/' },
  { image: XpectikoLogo, name: 'Xpectiko', title: 'Optometry', url: 'https://www.xpectiko.com.au/' },
  { image: BerwickLogo, name: 'Berwick Optical Centre', title: 'Optometry', url: 'https://www.berwickopticalcentre.com.au/' },
  { image: NepeanLogo, name: 'Nepean Optical', title: 'Optometry', url: 'https://www.nepeanoptical.com.au/' },
  { image: OPSMLogo, name: 'OPSM', title: 'Optometry', url:  'https://www.opsm.com.au/' },
  { image: YarraJunctionLogo, name: 'Yarra Junction Optical', title: 'Optometry', url: 'https://www.yarrajunctionoptical.com.au/' },
  { image: KevinPaisleyLogo, name: 'Kevin Paisley', title: 'Optometry', url: 'https://www.kevinpaisley.com.au/' },
];

export const SIGN_UP_LINK = 'https://z2m4caj4dl7.typeform.com/to/gII3jfrO';

export const CALENDAR_LINK = 'https://calendly.com/locumly/locumly-demo-solving-temporary-staffing?month=2022-10';

export const STORE_TYPE = {
  OPTOMETRY: { key: 'OPTOMETRY', storeTypeText: 'Optometry', defaultAvatar: DEFAULT_STORE_AVATAR.OPTOMETRY },
  PHARMACY: { key: 'PHARMACY', storeTypeText: 'Pharmacy', defaultAvatar: DEFAULT_STORE_AVATAR.PHARMACY },
};