import React from 'react';
import { NavLink } from 'react-router-dom';
import './LoginOptionsCard.scss';

export const WAITLIST_LINK = 'https://z2m4caj4dl7.typeform.com/to/gII3jfrO';

const LoginOptionsCard = () => {
  return (
    <section className={'login-options-container'}>
      <h1 className={'login-title bold'}>{'Welcome to Locumly'}</h1>
      <section className={'login-options'}>
        <NavLink className={'login-now-button locum very-light'} to={'/login/locum'} exact={'true'} >
          {'Sign In For Locums'}
        </NavLink>
        <NavLink className={'login-now-button business very-dark'} to={'/login/business'} exact={'true'} >
          {'Sign In For Businesses'}
        </NavLink>
      </section>
      <section className={'waitlist'}>
        <span className={'waitlist-label'}>{'Keen to join?'}</span>
        <a href={WAITLIST_LINK} className={'waitlist-button very-light'} rel={'noopener noreferrer'} target={'_blank'}>
          {'Sign Up'}
        </a>
      </section>
    </section>
  );
};

export default LoginOptionsCard;
