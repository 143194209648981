import './GoToButton.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { ASSET_CDN_URL } from '../../../../../Constants';

const GoToIcon = `${ASSET_CDN_URL}/store-profile/reviews/go-to-button.svg`;

const propTypes = {
  /* Callback go to a specific view state. */
  goTo: PropTypes.func.isRequired,

  /* Reversed. */
  reversed: PropTypes.bool.isRequired,
}

function GoToButton({ goTo, disabled, reversed }) {
  const reversedClass = reversed ? 'reversed' : 'next';
  return (
    <button
      className={`go-to-button ${disabled ? 'disabled' : 'very-dark'} ${reversedClass}`}
      onClick={goTo}
      disabled={disabled}
    >
      <img
        alt={'Go to icon'}
        className={'next-icon'}
        src={GoToIcon}
      />
    </button>
  )
}

GoToButton.displayName = 'GoToButton';
GoToButton.propTypes = propTypes;
export default GoToButton;