import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ASSET_CDN_URL } from '../Constants';
import './HelpPage.scss';

const AdditionalProviderNumbers = `${ASSET_CDN_URL}/blogs/additional-provider-numbers/additional-provider-numbers.png`;
const WhatIsALocum = `${ASSET_CDN_URL}/blogs/what-is-a-locum/what-is-a-locum.png`;
const FirstTimeLocum = `${ASSET_CDN_URL}/blogs/first-time-locum/first-time-locum.png`;

const ARTICLES = [
  { 
    date: 'April 18th, 2022',
    title: `Setting up as a locum optometrist`,
    subtitle: `Everything you need to know to get up and running as a locum optometrist`,
    bannerImage: FirstTimeLocum,
    altText: `First time? We've got you.`,
    url: '/tutorials/setting-up-as-a-locum',
  },
  {
    date: 'April 17th, 2022',
    title: `Locums: What's different?`,
    subtitle: `A quick rundown of what things you'll need to consider when you start locuming`,
    bannerImage: WhatIsALocum,
    altText: 'WTF is a Locum?',
    url:  '/tutorials/what-is-a-locum',
  },
  {
    date: 'March 26, 2022',
    title: 'How to apply for additional provider numbers via PRODA/HPOS',
    subtitle: 'Your guide on how to quickly get a new provider number for working at new locations',
    bannerImage: AdditionalProviderNumbers,
    altText: 'Kissing for numbers',
    url: '/tutorials/additional-provider-numbers',
  },
];

const HelpItem = ({ date, title, subtitle, bannerImage, altText, url }) => {
  const navigate = useNavigate();
  const onClick = () => navigate(url);
  return (
    <li className={'help-item'}>
      <button className={'help-link'} onClick={onClick}>
        <img className={'help-image'} src={bannerImage} alt={altText} />
        <section className={'text'}>
          <span className={'date'}>{date}</span>
          <span className={'help-title'}>{title}</span>
          <span className={'subtitle'}>{subtitle}</span>
        </section>
      </button>
    </li>
  );
}

const HelpPage = () => {
  const news = ARTICLES.map(article => <HelpItem {...article} key={`key-${article.title}`} />);
  return (
    <article className={'help-page'}>
      <h1 className={'title'}>{`Tutorials`}</h1>
      <ul className={'help-list'}>
        {news}
      </ul>
    </article>
  );
}

export default HelpPage;