import './SubscribeToStoreInput.scss';

import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  /* Label for input. */
  label: PropTypes.string.isRequired,

  /* Html for tag. */
  htmlFor: PropTypes.string.isRequired,

  /* Children. */
  children: PropTypes.node.isRequired,
}

function SubscribeToStoreInput({ children, label, htmlFor }) {
  return (
    <section className={'subscribe-to-store-input'}>
      <label className={'label-text'} htmlFor={htmlFor}>{label}</label>
      {children}
    </section>
  )
}

SubscribeToStoreInput.displayName = 'SubscribeToStoreInput';
SubscribeToStoreInput.propTypes = propTypes;
export default SubscribeToStoreInput;