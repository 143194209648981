import React from 'react';
import { LoadingButton } from '../../common/OnboardingPage';
import './ConfirmationModal.scss';


const ConfirmationModal = ({ children, close, confirm, confirmText, disabled = false, additionalClassName = '', loading = false, confirmRef = null }) => {
  return (
    <section className={`confirmation-modal-container ${additionalClassName}`}>
      <section className={'confirmation-modal'}>
        <section className={'confirmation-modal-content'}>
          {children}
          <section className={'buttons'}>
            <button className={'cancel very-dark'} onClick={close}>{'Cancel'}</button>
            <LoadingButton loading={loading} classNames={['confirm', 'very-dark', disabled ? 'disabled' : '']} onClick={confirm} text={confirmText} ref={confirmRef} />
          </section>
        </section>
      </section>
    </section>
  );
}

export default ConfirmationModal