import './YesNoRecommendation.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { ASSET_CDN_URL } from '../../../../../Constants';

const ColoredYesIcon = `${ASSET_CDN_URL}/store-profile/reviews/colored-yes-icon.svg`;
const UncoloredYesIcon = `${ASSET_CDN_URL}/store-profile/reviews/uncolored-yes-icon.svg`;

const propTypes = {
  /* True if would recommend store to a friend. */
  wouldRecommend: PropTypes.bool,

  /* Callback to set would recommend. */
  setWouldRecommend: PropTypes.func.isRequired,
}

function YesNoRecommendation({ wouldRecommend = null, setWouldRecommend }) {
  const clickYes = () => setWouldRecommend(true);
  const clickNo = () => setWouldRecommend(false);
  const YesIcon = wouldRecommend === true ? ColoredYesIcon : UncoloredYesIcon;
  const NoIcon = wouldRecommend === false ? ColoredYesIcon : UncoloredYesIcon;
  return (
    <section className={'yes-no-recommendation-buttons'}>
      <button className={'recommend-button very-light'} onClick={clickYes}>
        <img className={'recommend-icon'} src={YesIcon} alt={'Yes icon'} />
      </button>
      <button className={'recommend-button very-light'} onClick={clickNo}>
        <img className={'recommend-icon reverse'} src={NoIcon} alt={'No icon'} />
      </button>
    </section>
  )
}

YesNoRecommendation.defaultProps = { wouldRecommend: null };
YesNoRecommendation.displayName = 'YesNoRecommendation';
YesNoRecommendation.propTypes = propTypes;
export default YesNoRecommendation;