import React, { useState, useRef } from 'react';
import useAuth from '../hooks/AuthHook.jsx';
import { useOutsideClick } from '../hooks/CommonHooks.jsx';
import LocumPage from './LocumPage.jsx';
import { LOCUM_NAVIGATION_BUTTONS } from './LocumNavigationSidebar.jsx';
import { SOFTWARES, EQUIPMENTS } from '../business/store/ManagePreferencesPage.jsx';
import { NETWORKS } from '../Constants.js';
import { postNewExperiences } from '../Api.js';
import './LocumExperiencePage.scss';

export const YEARS = {
  [-1]: { key: -1, text: '', displayText: ''},
  0: { key: 0, text: '< 1 year', displayText: 'Less than 1 year' },
  1: { key: 1, text: '1 to 2 years', displayText: '1 to 2 years' },
  2: { key: 2, text: '2 to 3 years', displayText: '2 to 3 years' },
  3: { key: 3, text: '3 to 4 years', displayText: '3 to 4 years' },
  4: { key: 4, text: '4 to 5 years', displayText: '4 to 5 years' },
  5: { key: 4, text: '5+ years', displayText: 'More than 5 years' },
};

export const TimeDropdown = ({ selected, setSelected, hasNone = true }) => {
  const times = Array(6).fill().map((_, i) => 15 + i * 5);
  const [open, setOpen] = useState(false);
  const toggleDropdown = () => setOpen(!open);
  const dropdownRef = useRef(null);
  useOutsideClick(dropdownRef, () => setOpen(false));
  const dropdownList = times.map((time) => {
    const onSelection = () => {
      setSelected(time);
      toggleDropdown();
    };
    return (
      <li className={'dropdown-item'} key={`time-dropdown-${time}`}>
        <button className={`dropdown-option`} onClick={onSelection}>
          {`${time} minutes`}
        </button>
      </li>
    );
  });
  const onSelectNoPreference = () => {
    setSelected(0)
    toggleDropdown();
  };
  return (
    <section className={'dropdown-button'} ref={dropdownRef}>
      <button className={`data-view-button ${open ? 'open' : ''}`} onClick={toggleDropdown}>
        {selected === 0 ? 'No preference' : `${selected} minutes`}
        <i className={open ? 'up-arrow' : 'down-arrow'} />
      </button>
      {open &&
        <section className={'dropdown-list-container'}>
          <ul className={'dropdown-list'}>
            {dropdownList}
            { hasNone &&
              <li className={'dropdown-item'}>
                <button className={`dropdown-option`} onClick={onSelectNoPreference}>
                  {`No Preference`}
                </button>
              </li>
            }
          </ul>
        </section>
      }
    </section>
  );
};

export const YearsDropdown = ({ selected, setSelected }) => {
  const [open, setOpen] = useState(false);
  const toggleDropdown = () => setOpen(!open);
  const dropdownRef = useRef(null);
  useOutsideClick(dropdownRef, () => setOpen(false));
  const dropdownList = Object.keys(YEARS).map((time) => {
    const onSelection = () => {
      setSelected(time);
      toggleDropdown();
    };
    return (
      <li className={'dropdown-item'} key={`time-dropdown-${time}`}>
        <button className={`dropdown-option`} onClick={onSelection}>{YEARS[time].text}</button>
      </li>
    );
  });
  return (
    <section className={'dropdown-button'} ref={dropdownRef}>
      <button className={`data-view-button ${open ? 'open' : ''}`} onClick={toggleDropdown}>
        {selected === null ? 'No selection' : YEARS[selected].text}
        <i className={open ? 'up-arrow' : 'down-arrow'} />
      </button>
      {open &&
        <section className={'dropdown-list-container'}>
          <ul className={'dropdown-list'}>
            {dropdownList}
          </ul>
        </section>
      }
    </section>
  );
};

const hasExperiencesChanged = (experiences1, experiences2) => {
  return JSON.stringify(experiences1) !== JSON.stringify(experiences2);
}

const Experiences = () => {
  const { profile, setProfile } = useAuth();
  const { type } = profile;
  const [experiences, setExperiences] = useState(profile.experiences);
  const { interval, softwares, equipments, years, networks, otherNetworks } = experiences;
  const onChangeOtherNetworks = (e) => setExperiences({ ...experiences, otherNetworks: e.target.value });
  const setYears = (years) => setExperiences({ ...experiences, years });
  const setInterval = (interval) => setExperiences({ ...experiences, interval });
  const onNetworkClick = (network) => {
    return () => {
      const newNetworks = new Set(networks)
      if (newNetworks.has(network)) {
        newNetworks.delete(network);
      }
      else {
        newNetworks.add(network);
      }
      setExperiences({ ...experiences, networks: [ ...newNetworks ]});
    };
  };
  const onSoftwareClick = (software) => {
    return () => {
      const newSoftwares = new Set(softwares)
      if (newSoftwares.has(software)) {
        newSoftwares.delete(software);
      }
      else {
        newSoftwares.add(software);
      }
      setExperiences({ ...experiences, softwares: [ ...newSoftwares ]});
    };
  };
  const onEquipmentClick = (equipment) => {
    return () => {
      const newEquipments = new Set(equipments)
      if (newEquipments.has(equipment)) {
        newEquipments.delete(equipment);
      }
      else {
        newEquipments.add(equipment);
      }
      setExperiences({ ...experiences, equipments: [ ...newEquipments ]});
    };
  };

  const hasChanged = hasExperiencesChanged(profile.experiences, experiences);
  const resetToDefault = () => setExperiences(profile.experiences);
  const saveExperiences = () => {
    profile.experiences = experiences;
    postNewExperiences(experiences)
      .then(() => setProfile({ ...profile }))
      .catch((err) => console.error(err));
  }
  return (
    <section className={'experiences'}>
      <section className={'experiences-container'}>
        <section className={'experience-labels'}>
          <span className={'experience-title'}>{'Your Experience'}</span>
          <ul className={'experience-label-list'}>
            <li className={'experience-label one'}>{`How many years of experience do you have as an Optometrist?`}</li>
            <li className={'experience-label two'}>{'Which optical networks do you have experience working with?'}</li>
            <li className={'experience-label three'}>{`What other optical networks or brands have you worked with?`}</li>
            <li className={'experience-label four'}>{`What is the minimum testing interval you are comfortable with?`}</li>
            <li className={'experience-label five'}>{`Which of these softwares are you familiar with using?`}</li>
            <li className={'experience-label six'}>{`Which of these optical equipments are you familiar with using?`}</li>
          </ul>
        </section>
        <section className={'options-container'}>
          <span className={'option-title'}>{'Options'}</span>
          <ul className={'option-list'}>
            <li className={'option-item one'}>
              <section className={'option-selection time'}>
                <YearsDropdown selected={years} setSelected={setYears} />
              </section>
            </li>
            <li className={'option-item two'}>
              <section className={'option-selection networks'}>
                <section className={'first-row'}>
                  <button className={`selection-button ${networks.includes(NETWORKS[type].OPSM.key)  ? 'active' : ''}`}
                    onClick={onNetworkClick(NETWORKS[type].OPSM.key)}
                  >
                    {NETWORKS[type].OPSM.text}
                  </button>
                  <button
                    className={`selection-button ${networks.includes(NETWORKS[type].SPECSAVERS.key) ? 'active' : ''}`}
                    onClick={onNetworkClick(NETWORKS[type].SPECSAVERS.key)}
                  >
                    {NETWORKS[type].SPECSAVERS.text}
                  </button>
                  <button
                    className={`selection-button end ${networks.includes(NETWORKS[type].BUPA_OPTICAL.key) ? 'active' : ''}`}
                    onClick={onNetworkClick(NETWORKS[type].BUPA_OPTICAL.key)}
                  >
                    {NETWORKS[type].BUPA_OPTICAL.text}
                  </button>
                </section>
                <section className={'second-row'}>
                  <button className={`selection-button ${networks.includes(NETWORKS[type].OSCAR_WYLEE.key)  ? 'active' : ''}`}
                    onClick={onNetworkClick(NETWORKS[type].OSCAR_WYLEE.key)}
                  >
                    {NETWORKS[type].OSCAR_WYLEE.text}
                  </button>
                  <button
                    className={`selection-button ${networks.includes(NETWORKS[type].GEORGE_AND_MATILDA.key) ? 'active' : ''}`}
                    onClick={onNetworkClick(NETWORKS[type].GEORGE_AND_MATILDA.key)}
                  >
                    {NETWORKS[type].GEORGE_AND_MATILDA.text}
                  </button>
                  <button
                    className={`selection-button end ${networks.includes(NETWORKS[type].BAILEY_NELSON.key) ? 'active' : ''}`}
                    onClick={onNetworkClick(NETWORKS[type].BAILEY_NELSON.key)}
                  >
                    {NETWORKS[type].BAILEY_NELSON.text}
                  </button>
                  <button
                    className={`selection-button end ${networks.includes(NETWORKS[type].EYECARE_PLUS.key) ? 'active' : ''}`}
                    onClick={onNetworkClick(NETWORKS[type].EYECARE_PLUS.key)}
                  >
                    {NETWORKS[type].EYECARE_PLUS.text}
                  </button>
                  <button
                    className={`selection-button end ${networks.includes(NETWORKS[type].OTHER.key) ? 'active' : ''}`}
                    onClick={onNetworkClick(NETWORKS[type].OTHER.key)}
                  >
                    {NETWORKS[type].OTHER.text}
                  </button>
                </section>
              </section>
            </li>
            <li className={'option-item three'}>
              <section className={'option-selection'}>
                <textarea 
                  className={'other-networks'}
                  placeholder={'Enter any additional optical networks or brands'}
                  value={otherNetworks}
                  onChange={onChangeOtherNetworks}
                />
              </section>
            </li>
            <li className={'option-item four'}>
              <section className={'option-selection time'}>
                <TimeDropdown selected={interval} setSelected={setInterval} hasNone={false} />
              </section>
            </li>
            <li className={'option-item five'}>
              <section className={'option-selection software'}>
                <section className={'first-row'}>
                  <button className={`selection-button ${softwares.includes(SOFTWARES.SOCRATES.key)  ? 'active' : ''}`}
                    onClick={onSoftwareClick(SOFTWARES.SOCRATES.key)}
                  >
                    {SOFTWARES.SOCRATES.text}
                  </button>
                  <button
                    className={`selection-button ${softwares.includes(SOFTWARES.LUMI.key) ? 'active' : ''}`}
                    onClick={onSoftwareClick(SOFTWARES.LUMI.key)}
                  >
                    {SOFTWARES.LUMI.text}
                  </button>
                  <button
                    className={`selection-button end ${softwares.includes(SOFTWARES.OPTOMATE.key) ? 'active' : ''}`}
                    onClick={onSoftwareClick(SOFTWARES.OPTOMATE.key)}
                  >
                    {SOFTWARES.OPTOMATE.text}
                  </button>
                </section>
                <section className={'second-row'}>
                  <button className={`selection-button ${softwares.includes(SOFTWARES.MAGENTO.key)  ? 'active' : ''}`}
                    onClick={onSoftwareClick(SOFTWARES.MAGENTO.key)}
                  >
                    {SOFTWARES.MAGENTO.text}
                  </button>
                  <button
                    className={`selection-button ${softwares.includes(SOFTWARES.SUNIX.key) ? 'active' : ''}`}
                    onClick={onSoftwareClick(SOFTWARES.SUNIX.key)}
                  >
                    {SOFTWARES.SUNIX.text}
                  </button>
                  <button
                    className={`selection-button end ${softwares.includes(SOFTWARES.OTHER.key) ? 'active' : ''}`}
                    onClick={onSoftwareClick(SOFTWARES.OTHER.key)}
                  >
                    {SOFTWARES.OTHER.text}
                  </button>
                </section>
              </section>
            </li>
            <li className={'option-item six'}>
              <section className={'option-selection equipment'}>
                <section className={'first-row'}>
                  <button
                    className={`selection-button ${equipments.includes(EQUIPMENTS.FUNDUS_CAMERA.key)  ? 'active' : ''}`}
                    onClick={onEquipmentClick(EQUIPMENTS.FUNDUS_CAMERA.key)}
                  >
                    {EQUIPMENTS.FUNDUS_CAMERA.text}
                  </button>
                  <button
                    className={`selection-button ${equipments.includes(EQUIPMENTS.OCT.key)  ? 'active' : ''}`}
                    onClick={onEquipmentClick(EQUIPMENTS.OCT.key)}
                  >
                    {EQUIPMENTS.OCT.text}
                  </button>
                  <button
                    className={`selection-button end ${equipments.includes(EQUIPMENTS.OPTOS.key)  ? 'active' : ''}`}
                    onClick={onEquipmentClick(EQUIPMENTS.OPTOS.key)}
                  >
                    {EQUIPMENTS.OPTOS.text}
                  </button>
                </section>
                <section className={'first-row'}>
                  <button
                    className={`selection-button ${equipments.includes(EQUIPMENTS.VF.key)  ? 'active' : ''}`}
                    onClick={onEquipmentClick(EQUIPMENTS.VF.key)}
                  >
                    {EQUIPMENTS.VF.text}
                  </button>
                  <button
                    className={`selection-button ${equipments.includes(EQUIPMENTS.AR.key)  ? 'active' : ''}`}
                    onClick={onEquipmentClick(EQUIPMENTS.AR.key)}
                  >
                    {EQUIPMENTS.AR.text}
                  </button>
                  <button
                    className={`selection-button end ${equipments.includes(EQUIPMENTS.AP.key)  ? 'active' : ''}`}
                    onClick={onEquipmentClick(EQUIPMENTS.AP.key)}
                  >
                    {EQUIPMENTS.AP.text}
                  </button>
                </section>
              </section>
            </li>
          </ul>
        </section>
      </section>
      <section className={'confirm-buttons'}>
        <button className={`cancel-button ${hasChanged ? '' : 'disabled'}`} onClick={resetToDefault}>{'Reset'}</button>
        <button className={`confirm-experiences-button ${hasChanged ? '' : 'disabled'}`} onClick={saveExperiences} disabled={!hasChanged}>
          {'Save Experience'}
        </button>
      </section>
    </section>
  );
};

const LocumExperiencePage = () => {
  return (
    <LocumPage mode={LOCUM_NAVIGATION_BUTTONS.EXPERIENCE.mode}>
      <article className={'upcoming-shifts-page'}>
        <h1 className={'upcoming-shifts-title'}>{'Your Experience'}</h1>
        <p className={'upcoming-shifts-subtitle'}>
          {`These details help populate your Locum profile.`}
        </p>
        <p className={'upcoming-shifts-subtitle'}>
          {`We will show you shifts that are most suitable for your experience level.`}
        </p>
        <Experiences />
      </article>
    </LocumPage>
  );
}

export default LocumExperiencePage;
