import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/AuthHook.jsx';
import './LocumProfile.scss';
import { YEARS } from './LocumExperiencePage.jsx';
import { ConfirmLocumButton } from '../business/store/ReviewApplicantsPage.jsx';
import { SOFTWARES, EQUIPMENTS, DAILY_SCRIPT_VOLUME } from '../business/store/ManagePreferencesPage.jsx';
import { getProfile, postLocumAvatarUpload } from '../Api';
import { DEFAULT_PERSON_AVATAR, NETWORKS } from '../Constants.js';

const LocumProfile = ({ isLocum = false, locum, confirm = null, shift = null }) => {
  const navigate = useNavigate();
  const { setProfile } = useAuth();
  const { firstName, lastName, avatar, address, abn, ahpra, about, experiences, completedShifts, type } = locum;
  const [locumAvatar, setLocumAvatar] = useState(avatar ? avatar : DEFAULT_PERSON_AVATAR);
  const { networks, otherNetworks, interval, softwares, equipments, years, pharmacyScriptRange } = experiences;
  const numShiftsCompleted = completedShifts.length;
  const networksDisplay = networks.length > 0 || (otherNetworks && otherNetworks.length > 0)
    ? networks.map(network => NETWORKS[type][network].text).join(', ') + (otherNetworks.length > 0 ? `, ${otherNetworks}` : '')
    : 'None';
  const intervalsDisplay = interval
    ? Array(9 - Math.round(parseInt(interval) / 5)).fill(0).map((_, i) => (parseInt(interval) + i * 5)).map(number => `${number} minutes`).join(', ')
    : 'None';

  const dailyScriptsVolumeDisplay = pharmacyScriptRange ? `${DAILY_SCRIPT_VOLUME[pharmacyScriptRange].text} per day` : 'None';
  const softwaresDisplay = softwares.length > 0 ? softwares.map(software => SOFTWARES[type][software].text).join(', ') : 'None';
  const equipmentsDisplay = equipments && equipments.length > 0 ? equipments.map(equipment => EQUIPMENTS[type][equipment].text).join(', ') : 'None';
  const confirmLocumButton = shift !== null && confirm !== null
    ? <ConfirmLocumButton confirm={confirm} locum={locum} shift={shift} disabled={isLocum} />
    : <button className={'applicant-button confirm-locum-button disabled'} disabled={true}>{'Confirm shift request'}</button>
  const confirmButton = confirm !== null || isLocum ? confirmLocumButton : null;
  const sendMessage = () => navigate('/user/messages', { state: { open: false, locum, store: { name: shift.name, _id: shift.storeId } }});

  const profileButtons = (
    <section className={'profile-buttons'}>
      {confirmButton}
      { shift !== null && 
        <button className={`applicant-button message-locum-button very-dark ${isLocum ? 'disabled' : ''}`} disabled={isLocum} onClick={sendMessage}>
          {`Message ${firstName}`}
        </button>
      }
    </section>
  );


  const uploadAvatar = (e) => {
    const file = e.target.files[0];
    setLocumAvatar(window.URL.createObjectURL(file));
    postLocumAvatarUpload(file)
      .then(() => getProfile().then(({ profile }) => {
        setProfile(profile)
      }))
      .catch((err) => console.error(err));
  }

  const uploadButton = (
    <>
      <label for={'avatar-image-upload'} className={'upload-avatar'}>{'Upload New Avatar'}</label>
      <input
        type={'file'}
        name={'avatar-image-upload'}
        id={'avatar-image-upload'}
        className={'avatar-image-upload'}
        accept={'image/*'}
        onChange={uploadAvatar}
      />
    </>
  );

  return (
    <section className={'locum-profile'}>
      <aside className={'locum-info-sidebar'}>
        <section className={'locum-avatar-and-name'}>
          <section className={'locum-avatar-container'}>
            <img className={'locum-avatar'} src={locumAvatar} alt={'Locum Avatar'} />
          </section>
          {isLocum && uploadButton}
          <span className={'name'}>{`${firstName} ${lastName}`}</span>
        </section>
        <section className={'locum-summary'}>
          {profileButtons}
          <span className={'shifts-completed'}>
            {'Shifts Completed: '}
            <span className={'colored-emphasis'}>{numShiftsCompleted}</span>
          </span>
          { address && address.suburb && address.state && <span className={'suburb-and-state'}>{`${address.suburb}, ${address.state}`}</span>}
          <span className={'abn'}>
            {'ABN: '}
            {abn ? abn : <span className={'red-text'}>{'Missing ABN'}</span>}
          </span>
          <span className={'ahpra'}>
            {'AHPRA: '}
            {ahpra ? ahpra : <span className={'red-text'}>{'Missing AHPRA'}</span>}
          </span>
        </section>
      </aside>
      <section className={'locum-details'}>
        <section className={'about-and-confirm'}>
          <section className={'about'}>
            <h2 className={'title'}>{'About'}</h2>
            <p className={'about-text'}>{about}</p>
          </section>
          {profileButtons}
        </section>
        <section className={'experiences-to-date'}>
          <h2 className={'title'}>{'Experience to date'}</h2>
          <ul className={'experience-list'}>
            { years !== null &&
              <li className={'experience-item'}>
                <span className={'experience-label'}>{'Years of experience: '}</span>
                  {YEARS[years].displayText}
              </li>
            }
            <li className={'experience-item'}>
              <span className={'experience-label'}>{'Past experience: '}</span>
              {networksDisplay}
            </li>
            { type === 'OPTOMETRY' &&
                <li className={'experience-item'}>
                  <span className={'experience-label'}>{'Familiar testing times: '}</span>
                  {intervalsDisplay}
                </li>
            }
            {
              type === 'PHARMACY' &&
              <>
                <li className={'experience-item'}>
                  <span className={'experience-label'}>{'Familiar Rx range:'}</span>
                  {dailyScriptsVolumeDisplay}
                </li>
              </>
            }
          </ul>
        </section>
        <section className={'experiences-to-date'}>
          <h2 className={'title'}>{'Familiar with'}</h2>
          <ul className={'experience-list'}>
            {
              type === 'OPTOMETRY' &&
              <li className={'experience-item'}>
                <span className={'experience-label'}>{'Equipment used: '}</span>
                {equipmentsDisplay}
              </li>
            }
            <li className={'experience-item'}>
              <span className={'experience-label'}>{type === 'OPTOMETRY' ? 'Software used: ' : 'Dispensing software used: '}</span>
              {softwaresDisplay}
            </li>
          </ul>
        </section>
        <section className={'experiences-to-date'}>
          <h2 className={'title'}>{'Previous Locumly shifts'}</h2>
          <span className={'coming-soon'}>{'Coming soon'}</span>
        </section>
      </section>
    </section>
  );
}

export default LocumProfile;