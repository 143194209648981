import React from 'react';
import { ASSET_CDN_URL, MILLISECONDS_IN_AN_HOUR } from '../../Constants';
import { useNavigate } from 'react-router-dom';
import { formatDatesWithTimeZone } from '../../../helpers/DateUtils.js';
import { SHIFT_STATUSES } from '../../Constants.js';
import './BusinessShiftCard.scss';

const DateIcon = `${ASSET_CDN_URL}/shift-card/shift-date.png`;
const TimeIcon = `${ASSET_CDN_URL}/shift-card/shift-time.png`;
const LocationIcon = `${ASSET_CDN_URL}/shift-card/shift-location.png`;

const BusinessShiftCard = ({ shift, select = null, selectLocum, review = null }) => {
  const { name, startTime, endTime, address, rate, total, unpaidBreakTime, status, locum, applicants, storeId, negotiable, calendarUrl, _id, providerNumber, billToMedicare } = shift;
  const { suburb, state, timeZone } = address;
  const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(new Date(startTime), new Date(endTime), false, timeZone);
  const unpaidTimeDisplay = (unpaidBreakTime / MILLISECONDS_IN_AN_HOUR).toFixed(2);
  const rateDisplay = billToMedicare ? 'Rate: NA' : `$${(rate / 100).toFixed(2)}/hr`;
  const totalDisplay = billToMedicare ? 'NA' : `$${(total / 100).toFixed(2)}`;
  const showMessageButton = status === SHIFT_STATUSES.CONFIRMED.key || status === SHIFT_STATUSES.FILLED.key;
  const showApplicantsButton = status === SHIFT_STATUSES.UNFILLED.key && applicants.length > 0;
  const viewMore = select !== null ? () => select(shift) : () => navigate(`/find/shift?id=${shift._id}`);
  const viewLocum = () => selectLocum(locum, shift);
  const navigate = useNavigate();
  const sendMessage = () => navigate('/user/messages', { state: { open: false, locum, store: { name, _id: storeId } }});
  const viewApplicants = () => review !== null
    ? review(shift)
    : navigate('/store/applicants/review', { state: { storeId: shift.storeId, shift, locum: null} });
  const { className, text, hasSubtitle, subtitle } = SHIFT_STATUSES[status];
  const downloadCalendar = () => {
    if (calendarUrl) {
      const downloadElem = document.createElement('a');
      downloadElem.setAttribute('href', calendarUrl);
      downloadElem.setAttribute('download', `shift-${_id}.ics`);
      downloadElem.style.display = 'none';
      document.body.appendChild(downloadElem);
      downloadElem.click();
      document.body.removeChild(downloadElem);
    }
  };
  return (
    <li className={'shift-card business'}>
      <section className={'shift-details'}>
        <section className={`${className} status`}>
          {text}
          {hasSubtitle && <span className={'subtitle'}>{subtitle}</span>}
        </section>
        <h2 className={'store-name'}>{name}</h2>
        <span className={'shift-date'}>
          <img className={'shift-icon'} src={DateIcon} alt={'Date icon'} />
          {date}
          
          { calendarUrl 
            ? <button className={'add-to-calendar very-light'} onClick={downloadCalendar}>
                {'Add to calendar'}
              </button>
            : null
          }
        </span>
        <span className={'shift-time'}>
          <img className={'shift-icon'} src={TimeIcon} alt={'Time icon'} />
          {`${startTimeDisplay} - ${endTimeDisplay}`}
        </span>
        <span className={'shift-location'}>
          <img className={'shift-icon'} src={LocationIcon} alt={'Location icon'} />
          {`${suburb}, ${state}`}
        </span>
        <span className={'shift-break-time'}>{`Unpaid break: ${unpaidTimeDisplay} hrs`}</span>
        <span className={'shift-rate'}>{rateDisplay}</span>
        <span className={'shift-total'}>{`Total: ${totalDisplay}`}</span>
        { billToMedicare && <span className={'shift-rate'}>{'Locum takes home Medicare billing'}</span>}
        { !billToMedicare && 
          <span className={`shift-negotiable ${negotiable ? 'negotiable' : ''}`}>{negotiable ? 'Rate Negotiable' : 'Rate Fixed'}</span>
        }
        { !!providerNumber && <span className={'shift-provider-number'}>{`Provider Number: ${providerNumber}`}</span>}
      </section>
      <section className={'shift-buttons'}>
        {showMessageButton && <span className={'shift-locum'}>{`Your Locum is ${locum.firstName} ${locum.lastName}`}</span>}
        {showApplicantsButton && 
          <span className={'shift-applicants'}>
          {`${applicants.length} Applicant${applicants.length === 1 ? '' : 's'}`}
          </span>
        }
        { showMessageButton &&       
          <button className={'shift-button view-locum-button very-light'} onClick={viewLocum}>
            {`View Locum Profile`}
          </button>
        }
        { showMessageButton &&       
          <button className={'shift-button message-locum-button very-dark'} onClick={sendMessage}>
            {`Message Locum`}
          </button>
        }
        { showApplicantsButton &&       
          <button className={'shift-button view-applicants-button very-light'} onClick={viewApplicants}>
            {`View Applicants`}
          </button>
        }
        <button className={'shift-button view-shift-button very-light'} onClick={viewMore}>{'View Shift'}</button>
      </section>
    </li>
  );
};

export default BusinessShiftCard;
