import React, { useState } from 'react';
import { ASSET_CDN_URL } from '../../Constants';
import StorePage from './StorePage.jsx';
import BackArrowButton from '../../common/BackArrowButton.jsx';
import useAuth from '../../hooks/AuthHook.jsx';
import ConfirmationModal from './ConfirmationModal.jsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatDatesWithTimeZone } from '../../../helpers/DateUtils.js';
import { ShiftListViewer, LocumViewer, ShiftViewer } from '../store/UpcomingShiftsPage.jsx';
import BusinessShiftCard from '../common/BusinessShiftCard.jsx';
import { SHIFT_STATUSES, DEFAULT_PERSON_AVATAR } from '../../Constants.js';
import useStore from '../../hooks/StoreHook.jsx';
import './ReviewApplicantsPage.scss';
import { postLocumSelection, getShiftsForStore } from '../../Api.js';
import { NAVIGATION_OPTIONS } from '../BusinessConstants.js';

const PartyPopper = `${ASSET_CDN_URL}/graphics/party-popper.png`;

const ConfirmedApplicantView = ({ setView }) => (
  <section className={'confirm-page'}>
    <img className={'party-popper-image'} src={PartyPopper} alt={'Party popper'} />
    <h3 className={'party-popper-text'}>{'Exciting!'}</h3>
    <p className={'notify-text'}>{'We have sent off the good news!'}</p>
    <button className={'review-another-shift very-dark'} onClick={() => setView('VIEW')}>{'Review applicants for another shift'}</button>
  </section>
);

export const ConfirmLocumButton = ({ confirm, locum, shift }) => {
  const { startTime, endTime, name, address } = shift;
  const { timeZone } = address;
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);
  const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(startDate, endDate, true, timeZone);
  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);
  const openModal = () => setOpen(true);
  const confirmLocum = () => {
    close();
    confirm(locum._id);
  }
  const { firstName } = locum;
  return (
    <>
      <button className={'applicant-button confirm-locum-button very-light'} onClick={openModal}>{'Confirm Locum'}</button>
      {open &&
        <ConfirmationModal close={close} confirm={confirmLocum} confirmText={'Confirm Locum'} additionalClassName={'confirm-locum'}>
          <h3 className={'confirmation-title'}>{'Almost there...'}</h3>
          <p className={'confirmation-text'}>
            <b>{`${firstName}`}</b>{` will be confirmed as your Locum for your shift on `}<b>{`${date}`}</b>
            {' from '}<b>{`${startTimeDisplay}`}</b>{` to `}<b>{`${endTimeDisplay}`}</b>{' for '}<b>{`${name}`}</b>{'.'}
          </p>
          <p className={'confirmation-text'}>
            {'Once you have confirmed '}<b>{`${firstName}`}</b>
            {' for this shift, we will notify them of the good news and let the rest of the applicants down easy.'}
          </p>
        </ConfirmationModal>
      }
    </>
  )
};

export const ApplicantCard = ({ locum, view, confirm, shift }) => {
  const navigate = useNavigate();
  const { firstName, lastName, avatar, completedShifts } = locum;
  const viewLocum = () => view(locum);
  const messageLocum = () => navigate('/user/messages', { state: { open: false, locum, store: { name: shift.name, _id: shift.storeId } }});
  const locumAvatar = avatar ? avatar : DEFAULT_PERSON_AVATAR;
  return (
    <li className={'applicant-card'}>
      <section className={'applicant-details'}>
        <img className={'applicant-avatar'} src={locumAvatar} alt={'Applicant avatar'} />
        <span className={'applicant-name'}>{`${firstName} ${lastName}`}</span>
        <span className={'shifts-completed'}>{`${completedShifts.length} Shifts Completed`}</span>
      </section>
      <section className={'buttons-container'}>
        <button className={'applicant-button view-locum-button very-light'} onClick={viewLocum}>
          {`View Locum Profile`}
        </button>
        <ConfirmLocumButton confirm={confirm} locum={locum} shift={shift} />
        <button className={'applicant-button message-locum-button very-light'} onClick={messageLocum}>
          {`Message Locum`}
        </button>
      </section>
    </li>
  );
};

const ApplicantsViewer = ({ applicants, view, confirm, message, selectShift, shift }) => {
  const applicantList = applicants
    .map(applicant => 
      <ApplicantCard
        locum={applicant}
        key={`applicant-${applicant._id}`}
        view={view}
        confirm={confirm}
        message={message}
        shift={shift}
      />
    );
  return (
    <section className={'applicants-viewer-container'}>
      <BackArrowButton onClick={() => selectShift(null)} text={'Back to shifts'} />
      <section className={'applicants-viewer'}>
        <ul className={'applicant-list'}>
          {applicantList}
        </ul>
      </section>
    </section>
  );
}

const ReviewApplicantsPage = () => {
  const { profile } = useAuth();
  const { state } = useLocation();
  const { storeId } = state;
  const store = profile.stores.find((store) => store._id === storeId);
  const [view, setView] = useState('VIEW');
  const [shiftView, setShiftView] = useState(null);
  const [shift, setShift] = useState(state && state.shift ? state.shift : null);
  const [locum, setLocum] = useState(state && state.locum ? state.locum : null);

  const { shifts, setShifts } = useStore();
  const allShifts = shifts !== null
    ? shifts
        .filter(shift => shift.endTime > new Date().getTime())
        .filter(shift => shift.status === SHIFT_STATUSES.UNFILLED.key && shift.applicants.length > 0)
        .sort((e1, e2) => e1.startTime - e2.startTime)
    : null;

  const selectShift = (shift) => setShift(shift);

  const confirm = (locumId) => {
    if (shift !== null) {
      postLocumSelection(shift._id, locumId)
        .then(({ locum, calendarUrl }) => {
          const oldShift = shifts.find(oldShift => oldShift._id === shift._id);
          oldShift.status = SHIFT_STATUSES.FILLED.key;
          oldShift.locum = locum;
          oldShift.calendarUrl = calendarUrl;
          getShiftsForStore(storeId)
            .then(({ shifts }) => {
              setShifts(shifts);
              setShift(null);
              setLocum(null);
              setShift(null);
              setView('CONFIRMED');
            })
            .catch((err) => console.error(err));
        });
    }
  }

  const back = () => setLocum(null);
  let subtitle = '';
  let secondSubtitle = '';
  if (allShifts !== null) {
    if (allShifts.length > 0) {
      subtitle = `Congratulations! You have applicants for your shifts.`;
      secondSubtitle = `Once you have confirmed a locum, we will inform the applicants of the outcome.`;
    }
    else {
      subtitle = `You don't have any applicants for any shifts as of this moment.`;
      secondSubtitle = `Once you have a shift with applicants, you can review and confirm an applicant for a shift below.`;
    }
  }
  const emptyText = 'No applicants to review';
  return (
    <StorePage mode={NAVIGATION_OPTIONS.APPLICANTS.mode}>
      <article className={'review-applicants-page'}>
        <h1 className={'review-applicants-title'}>{'Review Applicants'}</h1>
        <p className={'review-applicants-subtitle'}>
          {subtitle}
        </p>
        <p className={'review-applicants-subtitle'}>
          {secondSubtitle}
        </p>
        {view === 'VIEW' && shiftView === null && shift === null && locum === null &&
          <section className={'shift-viewer-container'}>
            <ShiftListViewer
              shifts={allShifts}
              CardComponent={BusinessShiftCard}
              emptyText={emptyText}
              select={setShiftView}
              review={selectShift}
              selectLocum={setLocum}
            />
          </section>
        }
        {shiftView !== null && <ShiftViewer shift={shiftView} select={setShiftView} store={store} />}
        {view === 'VIEW' && shift !== null && locum === null && <ApplicantsViewer applicants={shift.applicants} shift={shift} selectShift={setShift} confirm={confirm} view={setLocum} />}
        {view === 'VIEW' && shift !== null && locum !== null && <LocumViewer locum={locum} confirm={confirm} shift={shift} backText={'Back to applicants'} back={back}/>}
        {view === 'CONFIRMED' && <ConfirmedApplicantView setView={setView} />}
      </article>
    </StorePage>
  );
};

export default ReviewApplicantsPage;