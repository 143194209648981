import './RatingInput.scss';

import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  /* Html for review store input. */
  htmlFor: PropTypes.string.isRequired,

  /* Label. */
  label: PropTypes.string.isRequired,

  /* Children. */
  children: PropTypes.node.isRequired,
}

function RatingInput({ htmlFor, label, children }) {
  return (
    <section className={'rating-input-container'}>
      <label className={'rating-input-label'} htmlFor={htmlFor}>{label}</label>
      {children}
    </section>
  )
}

RatingInput.displayName = 'RatingInput';
RatingInput.propTypes = propTypes;
export default RatingInput;