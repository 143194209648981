import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { postResetPassword } from '../Api.js';
import { USER_ROLES } from '../Constants.js';
import './ResetPasswordCard.scss';
import './ForgotPasswordCard.scss';

const EMAIL_ADDRESS_REGEX = /^[a-zA-Z0-9_@.-]+$/;

const ResetPassword = ({ isBusiness, setView }) => {
  const navigate = useNavigate();
  const [errorText, setErrorText] = useState('');
  const [loading, setLoading] = useState(false);
  const onChangeEmail = (e) => {
    if (e.target.value === '' || EMAIL_ADDRESS_REGEX.test(e.target.value)) {
      setErrorText('');
      setEmailAddress(e.target.value);
    }
  };
  const resetCallback = () => {
    setLoading(true);
    postResetPassword(emailAddress, isBusiness ? USER_ROLES.BUSINESS : USER_ROLES.LOCUM)
      .then((response) => {
        setLoading(false);
        if (response.error && response.error.length > 0) {
          setErrorText(response.error);
        }
        else {
          setLoading(false);
          setView('SUCCESS');
        }
      })
      .catch(({ error }) => {
        setLoading(false);
        setErrorText(error);
      });
  }
  const resetRef = useRef(null);
  const [emailAddress, setEmailAddress] = useState('');
  const resetListener = (e) => e.key === 'Enter' && resetRef.current.click();
  useEffect(() => {
    window.addEventListener('keypress', resetListener);
    return () => window.removeEventListener('keypress', resetListener);
  }, []);
  const returnToLogin = () => {
    const url = isBusiness ? '/login/business' : '/login/locum';
    navigate(url);
  }
  return (
    <section className={'forgot-password-container'}>
      <div className={'form-container'}>
        <h1 className={'login-text'}>{`Forgot password`}</h1>
        <section className={'response-text'}>
          <span className={'error-text'}>{errorText}</span>
        </section>
        <div className={'input-container'}>
          <input
            className={'email-address-container'}
            type={'text'}
            placeholder={'Enter the email associated with your account'}
            value={emailAddress}
            onChange={onChangeEmail}
          />
          <section className={'confirm-buttons'}>
            <button className={`continue-button very-dark ${loading ? 'loading' : ''}`} onClick={resetCallback} ref={resetRef} disabled={loading}>
              { !loading ? 'Reset password': 'Loading...' }
            </button>
            <button className={'back-to-login'} onClick={returnToLogin}>{'Return to log in'}</button>
          </section>
        </div>
      </div>
    </section>
  );
};

const ConfirmReset = ({ isBusiness, setView }) => {
  const navigate = useNavigate();
  const returnToLogin = () => {
    const url = isBusiness ? '/login/business' : '/login/locum';
    navigate(url);
  };
  return (
    <section className={'forgot-password-container success'}>
      <h1 className={'login-text success'}>{`Thanks! Check your email for instructions to reset your password.`}</h1>
      <p className={'reset-password-subtitle'}>
        {`If you haven't received an email in 5 minutes, check your spam folder or `}
        <button className={'link'} onClick={() => setView('RESET')}>{'resend the email.'}</button>
      </p>
      <section className={'confirm-buttons'}>
        <button className={'success-back-to-login'} onClick={returnToLogin}>{'Return to log in'}</button>
      </section>
    </section>
  );
}

const ResetPasswordCard = ({ isBusiness }) => {
  const [view, setView] = useState('RESET');
  return view === 'RESET' ? <ResetPassword isBusiness={isBusiness} setView={setView} /> : <ConfirmReset isBusiness={isBusiness} setView={setView} />;
};

export default ResetPasswordCard;