import './StoreReview.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { DEFAULT_PERSON_AVATAR } from '../../../Constants';
import { STORE_REVIEW_PROPS } from '../../StoreProfileProps';
import { EMPLOYMENT_STATUS, LENGTH_OF_WORK } from '../modals/review/ReviewConstants';

import StoreReviewer from './StoreReviewer';
import StoreReviewQuote from './StoreReviewQuote';

const propTypes = {
  /* Review of the store. */
  review: PropTypes.shape(STORE_REVIEW_PROPS).isRequired,
}

function StoreReview({ review }) {
  const {
    pros,
    cons,
    wouldRecommend,
    avatar,
    employmentStatus,
    verified,
    lengthOfWork,
  } = review;
  const reviewAvatar = avatar ? avatar : DEFAULT_PERSON_AVATAR;
  return (
    <section className={'store-review'}>
      <StoreReviewQuote
        pros={pros}
        cons={cons}
        wouldRecommend={wouldRecommend}
      />
      <StoreReviewer
        avatar={reviewAvatar}
        title={EMPLOYMENT_STATUS[employmentStatus].title}
        verified={verified}
        duration={LENGTH_OF_WORK[lengthOfWork].displayText}
      />
    </section>
  )
}

StoreReview.displayName = 'StoreReview';
StoreReview.propTypes = propTypes;
export default StoreReview;