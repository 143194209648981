import React from 'react';
import './TermsAndConditionsPage.scss';
import { PDF_CLOUDFRONT } from '../Constants';

const TermsAndConditionsPage = () => {
  return (
    <article className={'information-page'}>
      <section className={'terms-and-conditions-header'}>
        <h1 className={'information-title'}>{'Terms & Conditions'}</h1>
        <span className={'update-date'}>{'Last Updated 19th September 2023'}</span>
        <p className={'subtitle'}>
          {'By accepting these terms and conditions you are entering a legally binding agreement between yourself and Locumly (Locumly Pty Ltd):'}
        </p>
      </section>
      <iframe className={'terms'} frameBorder={'0'} src={`${PDF_CLOUDFRONT}terms-and-conditions`} title={'Terms And Conditions'} />
    </article>
  )
};

export default TermsAndConditionsPage;
