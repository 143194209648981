import React, { useState } from 'react';
import { ASSET_CDN_URL } from '../Constants';
import { CALENDAR_LINK } from '../home/HomeConstants';
import { HowItWorks, BottomBanner, MobileHowItWorks, HOW_IT_WORKS_POSTING } from '../find/PostPage.jsx';
import { useMediaQuery } from '../hooks/CommonHooks.jsx';
import './HowItWorksPage.scss';

const PaidImg = `${ASSET_CDN_URL}/marketing/cost.svg`;
const NetworkImg = `${ASSET_CDN_URL}/marketing/network-image.svg`;
const NotificationsImg = `${ASSET_CDN_URL}/marketing/notifications.svg`;

const HOW_IT_WORKS_FINDING = [
  {
    className: 'one',
    stepHeaderText: 'Your single source to find flexible work',
    stepContentText: ['Access the latest and greatest source of Healthcare jobs available to you. Get notified for relevant jobs based on where, and when you want to work.'],
    stepImage: NetworkImg,
  },
  {
    className: 'two',
    stepHeaderText: 'Make informed choices about your work',
    stepContentText: ['Choose where and when you want to work. Be confident you are charging the right pay rates with our recommendation engine.'],
    stepImage: NotificationsImg,
  },
  {
    className: 'three',
    stepHeaderText: 'Get paid, without the paperwork',
    stepContentText: ['Generate invoices with a single click. Know exactly when you’ll be paid via your personal dashboard.'],
    stepImage: PaidImg,
  }
];

export const DualButtonSection = ({ posting, firstClick, secondClick, firstText, secondText }) => {
  return (
    <section className={'dual-buttons-section'}>
      <section className={'buttons-container'}>
        <button className={`button-selector ${posting ? 'selected very-dark' : 'very-light'}`} onClick={firstClick}>
          {firstText}
        </button>
        <button className={`button-selector last ${posting ? 'very-light' : 'selected very-dark'}`} onClick={secondClick}>
          {secondText}
        </button>
      </section>
    </section>
  );
};

const HowItWorksPage = () => {
  const [posting, setPosting] = useState(true);
  const isMobile = useMediaQuery('(max-width: 1020px)');
  const firstClick = () => setPosting(true)
  const secondClick = () => setPosting(false);
  const content = posting ? HOW_IT_WORKS_POSTING : HOW_IT_WORKS_FINDING;
  const howItWorksSection = isMobile ? <MobileHowItWorks hideTitle={true} content={content} /> : <HowItWorks hideTitle={true} content={content} />;
  const firstText = `I'm posting work`;
  const secondText = `I'm finding work`;
  return (
    <article className={`how-it-works-page ${isMobile ? 'mobile' : ''}`}>
      <section className={'how-it-works-header'}>
        <h1 className={'title'}>{'How it works'}</h1>
        <DualButtonSection
          posting={posting}
          firstClick={firstClick}
          secondClick={secondClick}
          firstText={firstText}
          secondText={secondText}
        />
        <section className={'how-it-works-container'}>
          {howItWorksSection}
        </section>
      </section>
      <BottomBanner
        title={'Want to know more?'}
        subtitle={`Schedule a time and we'll walk you through how we solve temp staffing.`}
        buttonText={'Request a demo'}
        link={CALENDAR_LINK}
      />
    </article>
  )
};

export default HowItWorksPage;