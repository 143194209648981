import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import { useEffect, useRef } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { ACCESS_TOKEN } from '../Api';

mapboxgl.accessToken = ACCESS_TOKEN;

export const useStoreLocationMap = (coordinates, mapContainer) => {
  const { longitude, latitude } = coordinates;
  let marker = useRef(null);
  const map = useRef(null);

  // initialize the map
  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [longitude, latitude],
      zoom: 11,
      attributionControl: false
    })
    map.current.addControl(new mapboxgl.NavigationControl());
    return () => map.current.remove();
  }, [longitude, latitude, map, mapContainer]);

  // apply a marker on the location of the store
  useEffect(() => {
    if (marker.current) return;
    const el = document.createElement('span');
    el.className = 'store-map-marker';
    marker.current = new mapboxgl.Marker(el)
      .setLngLat([longitude, latitude])
      .addTo(map.current);
    return () => map.current.remove();
  }, [longitude, latitude, map])
}