import React from 'react';
import { NAVIGATION_MODES } from '../BusinessConstants.js';
import StoreCardNavigationButton from './StoreCardNavigationButton.jsx';
import './StoreCard.scss';

const StoreCard = ({ store }) => {
  const { name, address, avatar, _id } = store;
  const { mall, shopNumber, street, suburb, postcode } = address;
  const mallDisplay = mall ? `${mall}, ` : '';
  const shopNumberDisplay = shopNumber ? `${shopNumber}, ` : '';
  const addressDisplay = `${mallDisplay}${shopNumberDisplay}${street}, ${suburb}, ${postcode}`;
  const storeButtons = NAVIGATION_MODES.map(mode => <StoreCardNavigationButton mode={mode} storeId={_id} key={`store-${_id}-${mode}`} />);
  return (
    <li className={'store-card-container'}>
      <section className={'store-card'}>
        <section className={'store-details'}>
          <section className={'name-and-address'}>
            <h2 className={'store-name'}>{name}</h2>
            <h3 className={'store-address'}>{addressDisplay}</h3>
          </section>
          <img className={'store-avatar'} src={avatar} alt={'Store Avatar'} />
        </section>
        <section className={'store-buttons'}>
          {storeButtons}
        </section>
      </section>
    </li>
  );
};

export default StoreCard;