import React, { useState } from 'react';
import PropTypes from 'prop-types';

import useStoreProfile from '../../../../hooks/StoreProfileContext';

import StoreProfileButton from '../../shared/StoreProfileButton';
import SubscribeToStoreModal from './SubscribeToStoreModal';

const propTypes = {
  /* Store name. */
  storeName: PropTypes.string.isRequired,

  /* Store id. */
  storeId: PropTypes.string.isRequired,
}

function SubscribeToStoreButton({ storeName, storeId }) {
  const [subscribed, setSubscribed] = useState(false);
  const [open, setOpen] = useState(false);
  const { storeProfile } = useStoreProfile();
  const { storeUrl } = storeProfile;
  const subscribeToStore = () => setOpen(true);
  const subscribedClass = subscribed ? ['subscribed'] : [];
  const buttonText = subscribed ? 'Subscribed' : 'Subscribe';
  const plausibleLink = `plausible-event-link=${storeUrl}`;
  const plausibleEventClass = `plausible-event-name=Subscribe+To+Store+Button+Click ${plausibleLink}`;
  return (
    <>
      <StoreProfileButton
        onClick={subscribeToStore}
        additionalClassNames={[...subscribedClass, plausibleEventClass]}
        disabled={subscribed}
      >
        {buttonText}
      </StoreProfileButton>
      { open && (
        <SubscribeToStoreModal
          setOpen={setOpen}
          setSubscribed={setSubscribed}
          storeName={storeName}
          storeId={storeId}
        />
      )}
    </>
  )
}

SubscribeToStoreButton.displayName = 'SubscribeToStoreButton';
SubscribeToStoreButton.propTypes = propTypes;
export default SubscribeToStoreButton;