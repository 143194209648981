import React, { useState, createContext, useContext, useEffect, useRef } from 'react';
import { getShiftsForStore } from '../Api';
import useAuth from '../hooks/AuthHook';

const StoreContext = createContext();

const useStore = () => {
  const prevStoreId = useRef(null);
  const [storeId, setStoreId] = useState(null);
  const [shifts, setShifts] = useState(null);
  const selectStoreId = (storeId) => {
    if (prevStoreId.current === storeId) return;
    setShifts(null);
    setStoreId(storeId);
    prevStoreId.current = storeId;
  }
  useEffect(() => {
    if (storeId) {
      getShiftsForStore(storeId)
        .then((res) => {
          const shifts = res.shifts;
          setShifts(shifts);
        });
    }
  }, [storeId]);
  return {
    shifts,
    setShifts,
    storeId,
    selectStoreId,
  }
};

export const StoreProvider = ({ children }) => {
  const store = useStore();
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

const StoreConsumer = () => {
  return useContext(StoreContext);
};

export default StoreConsumer;