export const REVIEW_STATES = {
  REVIEW: 'REVIEW',
  RATING: 'RATING',
  VALIDATION: 'VALIDATION',
};

export const EMPLOYMENT_STATUS = {
  FULL_TIME: {
    key: 'FULL_TIME',
    displayText: 'Full time',
    title: 'Full time employee',
  },
  PART_TIME: {
    key: 'PART_TIME',
    displayText: 'Part time',
    title: 'Part time employee',
  },
  LOCUM: {
    key: 'LOCUM',
    displayText: 'Casual/Locum',
    title: 'Locum/casual employee',
  }
};

export const LENGTH_OF_WORK = {
  LESS_THAN_A_YEAR: {
    key: 'LESS_THAN_A_YEAR',
    displayText: '< 1 year',
  },
  ONE_TO_TWO_YEARS: {
    key: 'ONE_TO_TWO_YEARS',
    displayText: '1 to 2 years',
  },
  TWO_TO_THREE_YEARS: {
    key: 'TWO_TO_THREE_YEARS',
    displayText: '2 to 3 year',
  },
  MORE_THAN_THREE_YEARS: {
    key: 'MORE_THAN_THREE_YEARS',
    displayText: 'More than 3 years',
  },
};