import './StoreProfileTitle.scss';

import useStoreProfile from '../../hooks/StoreProfileContext';

import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  /* Store name. */
  storeName: PropTypes.string.isRequired,

  /* Location of the store (city followed by state). */
  locationName: PropTypes.string.isRequired,

  /* Avatar of the store. */
  avatar: PropTypes.string.isRequired,
};


function StoreProfileTitle({ storeName, locationName, avatar }) {
  const { storeProfile } = useStoreProfile();
  const { theme } = storeProfile;
  const { primaryHexColor, secondaryHexColor } = theme;
  console.log({ theme });
  return (
    <header className={'store-profile-title'} style={{ backgroundColor: secondaryHexColor }}>
      <section className={'store-avatar-container'} style={{ borderColor: primaryHexColor }}>
        <img className={'store-avatar'} alt={storeName} src={avatar} />
      </section>
      <h2 className={'store-name'} style={{ color: primaryHexColor }}>
        {storeName}
      </h2>
      <h3 className={'location-name'} style={{ color: primaryHexColor }}>
        {locationName}
      </h3>
    </header>
  )
}

StoreProfileTitle.displayName = 'StoreProfileTitle';
StoreProfileTitle.propTypes = propTypes;
export default StoreProfileTitle;