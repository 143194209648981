import React from 'react';

import QRCodeRedirect from './QRCodeRedirect';

const PHARMACY_INSTAGRAM_URL = 'https://www.instagram.com/locumly.pharmacy/';

function PharmacyQRCodeRedirect() {
  return <QRCodeRedirect url={PHARMACY_INSTAGRAM_URL} />;
}

export default PharmacyQRCodeRedirect;