import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ASSET_CDN_URL } from '../../Constants';
import BackArrowButton from '../../common/BackArrowButton.jsx';
import './FirstTimeLocum.scss';

const FirstTimeLocumImg = `${ASSET_CDN_URL}/blogs/first-time-locum/first-time-locum.png`;

const AHPRA_REGISTRATION_URL = 'https://www.ahpra.gov.au/Registration/Registration-Process.aspx';
const TAX_FILE_NUMBER_URL = 'https://www.ato.gov.au/individuals/tax-file-number/';
const ABN_REGISTRATION_URL = 'https://business.gov.au/registrations/register-for-an-australian-business-number-abn';
const OPTOMETRY_BOARD_URL = 'https://www.optometryboard.gov.au/registration-standards/professional-indemnity-insurance.aspx';
const PRODA_URL = 'https://www.servicesaustralia.gov.au/how-to-register-for-individual-proda-account?context=33786';
const ADDITIONAL_PRODA_URL = 'https://www.servicesaustralia.gov.au/how-to-apply-for-additional-provider-numbers?context=34076#a2';

const FirstTimeLocum = () => {
  const navigate = useNavigate();
  const onClick = () => navigate('/tutorials');
  return (
    <article className={'blog-page first-time-locum'}>
      <BackArrowButton onClick={onClick} text={'Back to tutorials'} />
      <section className={'article'}>
        <img className={'image-banner'} src={FirstTimeLocumImg} alt={`First time? We've got you.`} />
        <h1 className={'title'}>{`Setting up as a locum optometrist`}</h1>
        <h2 className={'date'}>{`Updated on April 18th, 2022`}</h2>
        <section className={'content'}>
          <p className={'paragraph'}>
            {`If this is your first time venturing into locum work, you’ll need four things to get started. This guide will enable you to book and complete shifts on the Locumly platform.`}
          </p>
          <ul className={'tutorial-list'}>
            <li className={'tutorial'}>
              <span className={'tutorial-title'}>{'1. AHPRA registration'}</span>
              <p className={'paragraph'}>
                {`Australian Health Practitioner Regulation Agency (AHPRA) helps protect the public by regulating Australia's registered health practitioners. In order to legally practice as a health professional in Australia, you must have an AHPRA registration.`}
              </p>
              <a className={'link paragraph italic'} href={AHPRA_REGISTRATION_URL}>
                {'Click here to apply for AHPRA registration.'}
              </a>
            </li>
            <li className={'tutorial'}>
              <span className={'tutorial-title'}>{'2. Australian Business Number (ABN)'}</span>
              <p className={'paragraph'}>
                {`An ABN is a unique number that identifies your business to the public, the Australian Taxation Office (ATO), and other government agencies. Applying for an ABN is free.`}
              </p>
              <p className={'paragraph'}>
                {`Your `}
                <a className={'link'} href={TAX_FILE_NUMBER_URL}>{'Tax File Number (TFN)'}</a>
                {` is your personal reference number in tax and superannuation systems.`}
              </p>
              <p className={'paragraph'}>
                {`If you are a sole trader, you would use your personal TFN to apply for an ABN.`}
              </p>
              <p className={'paragraph'}>
                {`If you are a company, you would register for a business TFN and then use the business TFN to apply for a business ABN or ACN.`}
              </p>
              <a className={'link paragraph italic'} href={ABN_REGISTRATION_URL}>
                {'Click here to register for an ABN.'}
              </a>
            </li>
            <li className={'tutorial'}>
              <span className={'tutorial-title'}>{'3. Professional Indemnity Insurance (PII)'}</span>
              <p className={'paragraph'}>
                {`Under National Law, all practising Optometrists in Australia are required to have PII (personal or third party) which protects you in any claim of negligence related to your professional work. An insurer providing PII to health professionals must generally be registered with Australian Prodential Regulation Authority (APRA).`}
              </p>
              <p className={'paragraph italics'}>
                {'It is important to check that your level of cover meets the PII requirements set out by AHPRA at the '}
                <a className={'link'} href={OPTOMETRY_BOARD_URL}>{'official Optometry Board website'}</a>
                {'.'}
              </p>
            </li>
            <li className={'tutorial'}>
              <span className={'tutorial-title'}>{'4. Medicare Provider Number and PRODA (Provider Digital Access)'}</span>
              <p className={'paragraph'}>
                {`A `}
                <b>{'Medicare provider number'}</b>
                {` is a unique number for eligible health professionals in order to claim, bill, refer or request medicare services.`}
              </p>
              <p className={'paragraph'}>
                {`If you are providing locum services at a location for less than two weeks, you can use any current provider number. The locum will be paid by Medicare and these Medicare payments are forwarded to the practice where the services were provided. This transfer is authorised by filling out a pay group link (provided by the practice).`}
              </p>
              <p className={'paragraph'}>
                {`If you have regular days at a specific location, then you should apply for an additional provider number at that location. The easiest and quickest way to do this is through your online `}
                <b>{`PRODA`}</b>
                {` account.`}
              </p>
              <p className={'paragraph italics'}>
                {'To register for a PRODA account, visit the '}
                <a className={'link'} href={PRODA_URL}>{'Service Australia website'}</a>
                {'.'}
              </p>
              <p className={'paragraph italics'}>
                {'Alternatively, you can also apply for additional provider numbers via filling and sending a form via '}
                <a className={'link'} href={ADDITIONAL_PRODA_URL}>{'this guide'}</a>
                {'.'}
              </p>
            </li>
          </ul>
        </section>
      </section>
    </article>
  );
}

export default FirstTimeLocum;