import React from 'react';
import { Outlet } from 'react-router-dom';

import ScrollToTopHook from '../components/hooks/ScrollToTopHook';

import Navbar from '../components/common/navbar/Navbar';
import FooterSection from '../components/common/FooterSection';

function LocumlyPage() {
  return (
    <>
      <Navbar />
      <ScrollToTopHook />
      <Outlet />
      <FooterSection />
    </>
  );
}

LocumlyPage.displayName = 'LocumlyPage';
export default LocumlyPage;
