import { createContext, useContext, useState } from 'react';

const StoreProfileContext = createContext();

export function StoreProfileProvider({ children }) {
  const [storeProfile, setStoreProfile] = useState(null);
  const storeProfileContext = { storeProfile, setStoreProfile }; 
  return (
    <StoreProfileContext.Provider value={storeProfileContext}>
      {children}
    </StoreProfileContext.Provider>
  );
}

const StoreProfileConsumer = () => useContext(StoreProfileContext);

export default StoreProfileConsumer;