import './StoreReviewSection.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { STORE_REVIEW_PROPS } from '../../StoreProfileProps';
import useStoreProfile from '../../../hooks/StoreProfileContext';

import StoreProfileButton from '../shared/StoreProfileButton';
import StoreProfileSection from './StoreProfileSection';
import StoreReview from '../shared/StoreReview';
import AddReviewButton from '../modals/review/AddReviewButton';

const propTypes = {
  /* List of store reviews. */
  reviews: PropTypes.arrayOf(PropTypes.shape(STORE_REVIEW_PROPS)).isRequired,

  /* Store lassdoor url. */
  glassdoorUrl: PropTypes.string.isRequired,

  /* Store seek url. */
  seekUrl: PropTypes.string.isRequired,

  /* Store id. */
  storeId: PropTypes.string.isRequired,

  /* Store name. */
  storeName: PropTypes.string.isRequired,
}

function StoreReviewSection({ reviews, glassdoorUrl, seekUrl, storeId, storeName }) {
  const { storeProfile } = useStoreProfile();
  const { storeUrl } = storeProfile;
  const hasSeekReviews = seekUrl.length > 0;
  const hasGlassdoorReviews = glassdoorUrl.length > 0;
  const reviewItems = reviews.map((review) => {
    return (
      <li className={'store-review-item'} key={`key-${review.reviewerTitle}-comments`}>
        <StoreReview review={review} />
      </li>
    );
  });
  const clickGlassdoorUrl = () => window.open(glassdoorUrl);
  const clickSeekUrl = () => window.open(seekUrl);
  const plausibleLink = `plausible-event-link=${storeUrl}`;
  const glassdoorEvent = 'plausible-event-name=Glassdoor+Button+Click'
  const glassdoorLinkClass = `plausible-event-outbound-link=${glassdoorUrl}`;
  const plausibleGlassdoorEventClass = `${glassdoorEvent} ${plausibleLink} ${glassdoorLinkClass}`;
  const seekEvent = 'plausible-event-name=Seek+Button+Click'
  const seekLinkClass = `plausible-event-outbound-link=${seekUrl}`;
  const plausibleSeekEventClass = `${seekEvent} ${seekLinkClass} ${plausibleLink}`;
  return (
    <StoreProfileSection
      titlePosition={'center'}
      sectionTitle={'Our Reviews'}
      additionalClassNames={['review']}
    >
      { reviews.length > 0 && <ul className={'store-reviews'}>{reviewItems}</ul> }
      <AddReviewButton storeId={storeId} storeName={storeName} />
      <section className={'store-review-buttons'}>
        { hasGlassdoorReviews &&
          <StoreProfileButton
            onClick={clickGlassdoorUrl}
            additionalClassNames={[plausibleGlassdoorEventClass]}
          >
            {'See more reviews on Glassdoor'}
          </StoreProfileButton>
        }
        { hasSeekReviews && 
          <StoreProfileButton
            onClick={clickSeekUrl}
            additionalClassNames={[plausibleSeekEventClass]}
          >
            {'See more reviews on Seek'}
          </StoreProfileButton>
        }
      </section>
    </StoreProfileSection>
  )
}

StoreReviewSection.displayName = 'StoreReviewSection';
StoreReviewSection.propTypes = propTypes;
export default StoreReviewSection;