import './RatingStar.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { ASSET_CDN_URL } from '../../../../../Constants';

const ColoredStarIcon = `${ASSET_CDN_URL}/store-profile/reviews/colored-star.svg`;
const UncoloredStarIcon = `${ASSET_CDN_URL}/store-profile/reviews/uncolored-star.svg`;

const propTypes = {
  /* On click for the star. */
  onClick: PropTypes.func.isRequired,

  /* On mouse enter for the star. */
  onMouseEnter: PropTypes.func.isRequired,

  /* On mouse exit for the star. */
  onMouseLeave: PropTypes.func.isRequired,

  /* True if the star is selected.*/
  selected: PropTypes.bool.isRequired,
}

function RatingStar({ onClick, onMouseEnter, onMouseLeave, selected }) {
  const StarIcon = selected ? ColoredStarIcon : UncoloredStarIcon;
  return (
    <button
      className={'rating-star-button'}
      onClick={onClick}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
    >
      <img className={'star'} alt={'Star icon'} src={StarIcon} />
    </button>
  )
}

RatingStar.displayName = 'RatingStar';
RatingStar.propTypes = propTypes;
export default RatingStar;