import React from 'react';

import QRCodeRedirect from './QRCodeRedirect';

const LOCUMLY_INSTAGRAM_URL = 'https://www.instagram.com/locumly/';

function LocumlyQRCodeRedirect() {
  return <QRCodeRedirect url={LOCUMLY_INSTAGRAM_URL} />;
}

LocumlyQRCodeRedirect.displayName = 'LocumlyQRCodeRedirect';
export default LocumlyQRCodeRedirect;