import React from 'react';
import { PDF_CLOUDFRONT } from '../Constants';

const FAQsPage = () => {
  return (
    <article className={'information-page'}>
      <section className={'faqs-header'}>
        <h1 className={'information-title'}>{'Locumly FAQs'}</h1>
        <span className={'update-date'}>{'Last Updated 8th March 2022'}</span>
      </section>
      <iframe className={'terms'} frameBorder={'0'} src={`${PDF_CLOUDFRONT}faqs`} title={'FAQs'} />
    </article>
  )
};

export default FAQsPage;