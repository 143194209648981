import React from 'react';
import { PDF_CLOUDFRONT } from '../Constants';

const CommunityGuidelinesPage = () => {
  return (
    <article className={'information-page'}>
      <section className={'privacy-policy-header'}>
        <h1 className={'information-title'}>{'Community Guidelines'}</h1>
        <span className={'update-date'}>{'Last Updated 8th March 2022'}</span>
      </section>
      <iframe className={'terms'} frameBorder={'0'} src={`${PDF_CLOUDFRONT}community-guidelines`} title={'Community Guideliens'} />
    </article>
  )
};

export default CommunityGuidelinesPage;