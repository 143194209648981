import './ShareStoreModal.scss';

import PropTypes from 'prop-types'
import React from 'react';

import StoreProfileModalWrapper from '../shared/StoreProfileModalWrapper';
import CopyLinkButton from './CopyLinkButton';

const propTypes = {
  /* Sets the modal open. */
  setOpen: PropTypes.func.isRequired,
};

function ShareStoreModal({ setOpen }) {
  const closeModal = () => setOpen(false);
  return (
    <StoreProfileModalWrapper
      closeModal={closeModal}
      additionalClassNames={['share']}
      title={'Share this store anywhere'}
    >
      <CopyLinkButton />
    </StoreProfileModalWrapper> 
  )
}

ShareStoreModal.displayName = 'ShareStoreModal';
ShareStoreModal.propTypes = propTypes;
export default ShareStoreModal;