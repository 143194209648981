import React, { useState, useEffect } from 'react';
import { getOneStore, getManyShifts, postStoreSubscription, getProfile } from '../Api';
import { computeDistance, orderShifts } from '../../helpers/ShiftUtils.js';
import BackArrowButton from '../common/BackArrowButton';
import useAuth from '../hooks/AuthHook.jsx';
import { DEFAULT_PERSON_AVATAR, SHIFT_TYPES, NETWORKS, ASSET_CDN_URL, DEFAULT_LOCATION } from '../Constants.js';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useCurrentLocation, useMediaQuery } from '../hooks/CommonHooks';
import { removeItemIfExistsElseAdd } from '../../helpers/arrayHelpers';
import { Accordian, FAQDropDown, FAQ } from './ShiftPage';
import ShiftCard from './ShiftCard';
import { ShowStoreNotif } from './FindStoreCard';
import './StoreShiftPage.scss';

const SubscribeBell = `${ASSET_CDN_URL}/icons/subscribe-store-icon.svg`;
const NoResult = `${ASSET_CDN_URL}/icons/no-result-icon.svg`;
const TickIcon = `${ASSET_CDN_URL}/icons/review-tick-icon.svg`;

const StoreShiftPage = () => {
  const { authed, profile, setProfile } = useAuth(); 
  const { geolocationPreferences } = profile ? profile : {geolocationPreferences: null};
  const { stores } = geolocationPreferences ? geolocationPreferences : {stores: []};

  const navigate = useNavigate();
  const [ searchParams ] = useSearchParams();
  const { state } = useLocation();

  const notOnMap = state === null || state.isListView === undefined ? true : state.isListView;
  const [storeAvatar, setStoreAvatar] = useState(state && state.store && state.store.avatar ? state.store.avatar : DEFAULT_PERSON_AVATAR);
  const [store, setStore] = useState(state && state.store ? state.store : null);
  const [shiftList, setShiftList] = useState(state && state.shiftList ? state.shiftList : [])

  const [isLoading, setLoading] = useState(true);
  const infoQuestions = ["What's required for this job?", "Other things to know"]
  const [viewNum, setViewNum] = useState(5);
  const isMobile = useMediaQuery('(max-width: 820px)');
  const [requestedShiftList, setRequestedShifts] = useState(profile && profile.shifts ? profile.shifts : [])
  const [notifyStores, setNotifyStores] = useState([]);
  const [showStoreNotif, setShowStoreNotif] = useState(false);
  
  useEffect(() => {
    if (profile && profile.shifts) {
      setRequestedShifts(profile.shifts.map(shift => shift._id));
    }
  }, [profile]);

  useEffect(() => {
    if (authed) {
      setNotifyStores(stores.map(store => store._id));
    }
  }, [authed, stores])

  const [location, setLocation] = useState(state && state.location ? state.location : null);
  useCurrentLocation(setLocation, location === null, searchParams.get('id'));

  const [distance, setDistance] = useState(state && state.distance ? state.distance : null);

  const handleNotifyMe = (storeId) => {
    if (authed && profile.role === 'LOCUM') {
      const notifyingStores = removeItemIfExistsElseAdd(notifyStores, storeId);
      postStoreSubscription(notifyingStores)
        .then(({error}) => {
          if (error) {
          } else {
            setNotifyStores(notifyingStores);
            getProfile()
              .then(({ profile }) => {
                setProfile(profile);
              });
          }
        })
    } else if (authed && profile.role === 'BUSINESS') {
      setShowStoreNotif(true);
    } else {
      navigate('/login');
    }
  }

  useEffect(() => {
    const storeId = searchParams.get('id');
    if (storeId && store === null) {
      setLoading(true);
      getOneStore(storeId)
        .then((response) => {
          if (Object.keys(response.store).length > 0) {
            setStore(response.store);
            setDistance(computeDistance(location !== null ? location.coordinates : DEFAULT_LOCATION.coordinates, response.store.address.coordinates, 'kilometers'))
            setStoreAvatar(response.store.avatar ? response.store.avatar : SHIFT_TYPES[response.store.type].imageUrl);
            getManyShifts(response.store.shifts)
              .then((response) => {
                setShiftList(orderShifts(response.shifts.filter(shift => shift.startTime > new Date()), 'date-ascending'));
              })
          } else {
            setStore({});
          }
        })
        .catch((e) => {
          console.error(e)
          setStore({})
        })
        .finally(() => setLoading(false)); 
    } else {
      setLoading(false);
    }
  }, [searchParams, location, store]);

  const handleReturn = () => {
    navigate('/find', {state: {...state, isListView: notOnMap, jobView: false} });
  }
  
  return (
    <article className={'locum-store-page'}>
      {isLoading ? 
      <div className={'loading'}>
        <span>{'Loading...'}</span>
      </div>
      :
      Object.keys(store).length === 0
        ?
        <div className={'something-wrong'}>
          <img className={'no-result-svg'} src={NoResult} alt={'no result found'} />
          <div className={'something-wrong-text'}>{"Sorry, we couldn't find this store. Please try again later."}</div>
        </div>
        :
        <div className={'store-page-container'}>
          {isMobile
          ?
          <>
            <div className={'store-back-arrow'}>
              <BackArrowButton onClick={handleReturn} disabled={isLoading}/>
            </div>
            <div className={'store-header'}>
              <div className={'store-avatar-container'}>
                <img className={'store-avatar'} src={storeAvatar} alt={'store avatar'} />
              </div>
              <div className={'store-detail-container'}>
                <h1 className={'store-detail-name'}>{store.name}</h1>
                <div className={'store-detail-address'}>{`${store.address.suburb}, ${store.address.state}`}</div>
              </div>
            </div>
            <div className={'tag-container'}>
              <div className={'tag-item'}>{`${shiftList.length} shifts available`}</div>
              <div className={'tag-item'}>{`${distance.toFixed(1)}km`}</div>
              <div className={notifyStores.includes(store._id) ? 'tag-item notify-button notified' : 'tag-item notify-button'} onClick={() => handleNotifyMe(store._id)}>
                <img className={'subscribe-bell'} src={SubscribeBell} alt={'subscribe-bell'} />
                <span className={'subscribe-text'}>{notifyStores.includes(store._id) ? 'Notifications On' : 'Get notifications'}</span>
              </div>
            </div>
            <div className={'store-reviews'}>
              <div className={'review'}>
                <img className={'tick-icon'} src={TickIcon} alt={'tickmark'} />
                <span className={'review-text'}>{'Confirmed shifts rarely get cancelled'}</span>
              </div>
              <div className={'review'}>
                <img className={'tick-icon'} src={TickIcon} alt={'tickmark'} />
                <span className={'review-text'}>{'Responds quickly to shift requests'}</span>
              </div>
            </div>
            <div className={'info-content-container'}>
              <div className={'accordian'}>
                {infoQuestions.map((question, i) => 
                  <Accordian 
                    question={question}
                    index={i}
                    preferences={store.preferences}
                    network={NETWORKS[store.type][store.network]}
                    organization={store.organization}
                    type={store.type}
                    key={`${question} - i`}
                  />
                )}
              </div>
            </div>
            {shiftList.length !== 0 &&
              <div className={'shifts-container'}>
                <div className={'shift-view-title'}>{'View Shifts'}</div>
                <div className={'shifts-padding'}>
                  {shiftList.map((shift) => 
                    <ShiftCard 
                      distance={distance}
                      shift={shift}
                      currentState={state}
                      location={location}
                      key={`store-shift-${shift._id}`}
                      fromStore={store._id}
                      requestedShiftList={requestedShiftList}
                    />
                  ).slice(0, viewNum)}
                  {viewNum < shiftList.length && 
                    <div className={'view-more'} onClick={() => setViewNum(prevState => prevState + 5)}>
                      {'View more shifts...'}
                    </div>
                  }
                </div>
              </div>
            }
          </>
          :
          <>
            <div className={'store-summary'}>
              <div className={'store-header'}>
                <div className={'store-avatar-container'}>
                  <img className={'store-avatar'} src={storeAvatar} alt={'store avatar'} />
                </div>
                <div className={'store-detail-container'}>
                  <h1 className={'store-detail-name'}>{store.name}</h1>
                  <div className={'store-detail-address'}>{`${store.address.suburb}, ${store.address.state}`}</div>
                  <div className={'store-reviews'}>
                    <div className={'review'}>
                      <img className={'tick-icon'} src={TickIcon} alt={'tickmark'} />
                      <span className={'review-text'}>{'Confirmed shifts rarely get cancelled'}</span>
                    </div>
                    <div className={'review'}>
                      <img className={'tick-icon'} src={TickIcon} alt={'tickmark'} />
                      <span className={'review-text'}>{'Responds quickly to shift requests'}</span>
                    </div>
                  </div>
                  <div className={'tag-container'}>
                    <div className={'tag-item'}>{`${shiftList.length} shifts available`}</div>
                    <div className={'tag-item'}>{`${distance.toFixed(1)}km`}</div>
                    <div className={notifyStores.includes(store._id) ? 'tag-item notify-button notified' : 'tag-item notify-button'} onClick={() => handleNotifyMe(store._id)}>
                      <img className={'subscribe-bell'} src={SubscribeBell} alt={'subscribe-bell'} />
                      <span className={'subscribe-text'}>{notifyStores.includes(store._id) ? 'Notifications On' : 'Get notifications'}</span>
                    </div>
                  </div>
                </div>
              </div>
              {shiftList.length !== 0 &&
                <div className={'shifts-container'}>
                  <div className={'shift-view-title'}>{'View Shifts'}</div>
                  <div className={'shifts-padding'}>
                    {shiftList.map((shift) => 
                      <ShiftCard 
                        distance={distance}
                        shift={shift}
                        currentState={state}
                        location={location}
                        key={`store-shift-${shift._id}`}
                        fromStore={store._id}
                        requestedShiftList={requestedShiftList}
                      />
                    ).slice(0, viewNum)}
                  </div>
                  {viewNum < shiftList.length && 
                    <div className={'view-more'} onClick={() => setViewNum(prevState => prevState + 5)}>
                      {'View more shifts...'}
                    </div>
                  }
                </div>
              }
            </div>
            <div className={'store-info'}>
              <div className={'info-content-container'}>
                <div className={'accordian'}>
                  {infoQuestions.map((question, i) => 
                    <Accordian 
                      question={question}
                      index={i}
                      preferences={store.preferences}
                      network={NETWORKS[store.type][store.network]}
                      organization={store.organization}
                      type={store.type}
                      key={`${question} - i`}
                    />
                  )}
                </div>
              </div>
              <div className={'faq-container'}>
                <div className={'faq-info'}>
                  <div className={'faq-title'}>
                    <span className={'faq-faq'}>{'FAQ'}</span>
                    <span className={'faq-sub'}>{'Some things you might want to know before requesting!'}</span>
                  </div>
                  <div className={'faq-accordion'}>
                    {FAQ.map((item, i) =>
                      <FAQDropDown
                        item={item}
                        index={i}
                        key={`${item.question} - ${i}`}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
          }
        </div>
      }
      {showStoreNotif && <ShowStoreNotif setShowStoreNotif={setShowStoreNotif} isShift={false}/>}
    </article>
  )
}

export default StoreShiftPage;