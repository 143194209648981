import React, { useState } from 'react';
import useAuth from '../hooks/AuthHook.jsx';
import { MILLISECONDS_IN_AN_HOUR } from '../Constants.js';
import { TimeDropdown, FLOAT_REGEX, MAXIMUM_RATE, YesNoDropdown, DateTimeDropdown } from '../business/store/AddShiftsPage.jsx';
import { LoadingButton } from '../common/OnboardingPage.jsx';
import './CompleteShiftModal.scss';
import { getProfile, postShiftChangeFromLocum } from '../Api.js';
import useMediaQuery from '../hooks/CommonHooks.jsx';
import { getTimeOffset } from '../business/store/UpcomingShiftsPage.jsx';
import { formatDatesWithTimeZone } from '../../helpers/DateUtils.js';

const PAGE_STATES = {
  GENERATE_INVOICE: 0,
  CONFIRM_DETAILS: 1,
  STORE_REVIEW: 2,
  STORE_ENJOYMENT: 3,
  STORE_FEEDBACK: 4,
};

const ENJOYMENT_OPTIONS = {
  VIBES_FROM_STAFF: {
    key: 'VIBES_FROM_STAFF',
    label: 'Vibes from the staff',
  },
  VIBES_FROM_PATIENTS: {
    key: 'VIBES_FROM_PATIENTS',
    label: 'Vibes from the patients',
  },
  APPOINTMENT_BOOK_SET_UP: {
    key: 'APPOINTMENT_BOOK_SET_UP',
    label: 'Appointment book set up',
  },
  LOCATION: {
    key: 'LOCATION',
    label: 'Location and store facilities',
  }
};

const REVIEW_OPTIONS = {
  VERY_KEEN: {
    key: 'VERY_KEEN',
    label: 'Very keen',
  },
  SOMEWHAT_KEEN: {
    key: 'SOMEWHAT_KEEN',
    label: 'Somewhat keen',
  },
  NO_OTHER_OPTION: {
    key: 'NO_OTHER_OPTION',
    label: 'If there are no other options',
  },
  NEVER_AGAIN: {
    key: 'NEVER_AGAIN',
    label: 'I would not work here again',
  }
};

function StoreFeedbackSelection({ isPositive }) {
  const [feedback, setFeedback] = useState('');
  const updateFeedback = (e) => setFeedback(e.target.value);
  const headerText = isPositive ? `Anything else you'd like to add?` : `We're sorry to hear! What could be better?`;
  return (
    <section className={'feedback-selection'}>
      <h2 className={'modal-header'}>{headerText}</h2>
      <h3 className={'modal-subtitle above'}>{'Your invoice will be sent once you submit!'}</h3>
      <section className={'feedback-container'}>
        <textarea className={'feedback-text'} placeholder={'Type your answer here...'} value={feedback} onChange={updateFeedback}/>
      </section>
    </section> 
  );
}

const RoundedCheckbox = ({ checked, name }) => {
  return (
    <section className={'rounded-checkbox'}>
      <input type={'checkbox'} name={name} />
      <label htmlFor={name}>
        { checked && <span className={'filled'} />}
      </label>
    </section>
  )
}

function SelectionBox({ name, checked, label, onClick }) {
  return (
    <button className={`selection-option ${checked ? 'selected' : ''}`} onClick={onClick}>
      <RoundedCheckbox checked={checked} name={name} />
      <p className={'selection-label'}>{label}</p>
    </button>
  );
}

function StoreEnjoymentSelection({ selected, select }) {
  const { VIBES_FROM_STAFF, VIBES_FROM_PATIENTS, APPOINTMENT_BOOK_SET_UP, LOCATION } = ENJOYMENT_OPTIONS;
  const isVibesFromStaff = selected === VIBES_FROM_STAFF.key;
  const setVibesFromStaff = () => select(VIBES_FROM_STAFF.key);
  const isVibesFromPatients = selected === VIBES_FROM_PATIENTS.key;
  const setVibesFromPatients = () => select(VIBES_FROM_PATIENTS.key);
  const isAppointmentBookSetUp = selected === APPOINTMENT_BOOK_SET_UP.key;
  const setAppointmnetBookSetUp = () => select(APPOINTMENT_BOOK_SET_UP.key);
  const isLocation = selected === LOCATION.key;
  const setLocation = () => select(LOCATION.key);
  return (
    <section className={'store-enjoyment-selection'}>
      <h3 className={'modal-subtitle'}>{`Your invoice will be generated and sent after 3 short anonymous questions`}</h3>
      <h2 className={'modal-header'}>{'What did you enjoy most about working here?'}</h2>
      <section className={'selection-options'}>
        <SelectionBox name={'vibes-from-staff'} checked={isVibesFromStaff} label={VIBES_FROM_STAFF.label} onClick={setVibesFromStaff} />
        <SelectionBox name={'vibes-from-patients'} checked={isVibesFromPatients} label={VIBES_FROM_PATIENTS.label} onClick={setVibesFromPatients} />
        <SelectionBox name={'appointment-book-set-up'} checked={isAppointmentBookSetUp} label={APPOINTMENT_BOOK_SET_UP.label} onClick={setAppointmnetBookSetUp} />
        <SelectionBox name={'location-and-store-facilities'} checked={isLocation} label={LOCATION.label} onClick={setLocation} />
      </section>
    </section>
  );
}


function StoreReviewSelection({ selected, select }) {
  const { VERY_KEEN, SOMEWHAT_KEEN, NO_OTHER_OPTION, NEVER_AGAIN } = REVIEW_OPTIONS;
  const isVeryKeen = selected === VERY_KEEN.key;
  const isSomewhatKeen = selected === SOMEWHAT_KEEN.key;
  const isNoOtherOption = selected === NO_OTHER_OPTION.key;
  const isNeverAgain = selected === NEVER_AGAIN.key;
  const setVeryKeen = () => select(VERY_KEEN.key);
  const setSomewhatKeen = () => select(SOMEWHAT_KEEN.key);
  const setNoOtherOption = () => select(NO_OTHER_OPTION.key);
  const setNeverAgain = () => select(NEVER_AGAIN.key);
  return (
    <section className={'store-review-selection'}>
      <h3 className={'modal-subtitle'}>{`Your invoice will be generated and sent after 3 short anonymous questions`}</h3>
      <h2 className={'modal-header'}>{'How keen are you to work at this store again?'}</h2>
      <section className={'selection-options'}>
        <SelectionBox name={'very-keen'} checked={isVeryKeen} label={VERY_KEEN.label} onClick={setVeryKeen} />
        <SelectionBox name={'somewhat-keen'} checked={isSomewhatKeen} label={SOMEWHAT_KEEN.label} onClick={setSomewhatKeen} />
        <SelectionBox name={'no-other-option'} checked={isNoOtherOption} label={NO_OTHER_OPTION.label} onClick={setNoOtherOption} />
        <SelectionBox name={'never-again'} checked={isNeverAgain} label={NEVER_AGAIN.label} onClick={setNeverAgain} />
      </section>
    </section>
  );
}

function MobileConfirmDetailsSelection({ dateDisplay, startTimeDisplay, endTimeDisplay, unpaidBreakDisplay, rateDisplay, totalDurationText, totalText, registeredForGstDisplay, editingButton }) {
  return (
    <section className={'confirm-details-selection'}>
      <h3 className={'modal-subtitle'}>{`Edit and save your hours if it is different to what's shown`}</h3>
      <h2 className={'modal-header'}>{'Are these details correct for your invoice?'}</h2>
      <section className={'mobile-shift-details-container'}>
        <section className={'shift-details-confirmation'}>
          <section className={'detail-container date'}>
            <span className={'detail'}>{dateDisplay}</span>
          </section>
          <section className={'detail-container start-time'}>
            <span className={'detail-label'}>{'Start time'}</span>
            <span className={'detail'}>{startTimeDisplay}</span>
          </section>
          <section className={'detail-container end-time'}>
            <span className={'detail-label'}>{'End time'}</span>
            <span className={'detail'}>{endTimeDisplay}</span>
          </section>
          <section className={'detail-container unpaid-break'}>
            <span className={'detail-label'}>{'Unpaid break'}</span>
            <span className={'detail'}>{unpaidBreakDisplay}</span>
          </section>
          <section className={'detail-container rate'}>
            <span className={'detail-label'}>{'Rate/hr'}</span>
            <span className={'detail'}>{rateDisplay}</span>
          </section>
        </section>
        <section className={'mobile-total-details-container'}>
          <section className={'detail-container'}>
            <span className={'detail-label'}>{'Registered for GST:'}</span>
            <span className={'detail'}>{registeredForGstDisplay}</span>
          </section>
          <section className={'total-details'}>
            <section className={'detail-container'}>
              <span className={'detail-label'}>{'Total hours paid:'}</span>
              <span className={'detail'}>{totalDurationText}</span>
            </section>
            <section className={'detail-container'}>
              <span className={'detail-label'}>
                {'Total rate charged:'}
              </span>
              <span className={'detail'}>{totalText}</span>
            </section>
            <span className={'detail-subtitle'}>{`GST will be added on top if you're registered`}</span>
          </section>
        </section>
      </section>
      {editingButton}
    </section>
  );
}

function WebConfirmDetailsSelection({ dateDisplay, startTimeDisplay, endTimeDisplay, unpaidBreakDisplay, rateDisplay, totalDurationText, totalText, registeredForGstDisplay, editingButton }) {
  return (
    <section className={'confirm-details-selection'}>
      <h3 className={'modal-subtitle'}>{`Edit and save your hours if it is different to what's shown`}</h3>
      <h2 className={'modal-header'}>{'Are these details correct for your invoice?'}</h2>
      <section className={'shift-details-container'}>
        <section className={'shift-details-confirmation'}>
          <section className={'detail-container date'}>
            <span className={'detail-label'}>{'Date'}</span>
            <span className={'detail'}>{dateDisplay}</span>
          </section>
          <section className={'detail-container start-time'}>
            <span className={'detail-label'}>{'Start time'}</span>
            <span className={'detail'}>{startTimeDisplay}</span>
          </section>
          <section className={'detail-container end-time'}>
            <span className={'detail-label'}>{'End time'}</span>
            <span className={'detail'}>{endTimeDisplay}</span>
          </section>
          <section className={'detail-container unpaid-break'}>
            <span className={'detail-label'}>{'Unpaid break'}</span>
            <span className={'detail'}>{unpaidBreakDisplay}</span>
          </section>
          <section className={'detail-container rate'}>
            <span className={'detail-label'}>{'Rate/hr'}</span>
            <span className={'detail'}>{rateDisplay}</span>
          </section>
        </section>
        <section className={'total-details-container'}>
          <section className={'total-details'}>
            <section className={'detail-container'}>
              <span className={'detail-label'}>{'Registered for GST:'}</span>
              <span className={'detail'}>{registeredForGstDisplay}</span>
            </section>
            <section className={'detail-container'}>
              <span className={'detail-label'}>{'Total hours paid:'}</span>
              <span className={'detail'}>{totalDurationText}</span>
            </section>
            <section className={'detail-container'}>
              <span className={'detail-label'}>
                {'Total rate charged:'}
              </span>
              <span className={'detail'}>{totalText}</span>
            </section>
          </section>
          <span className={'detail-subtitle'}>{`GST will be added on top if you're registered`}</span>
        </section>
      </section>
      {editingButton}
    </section>
  );
}


function ConfirmDetailsSelection({ details, shiftId, billToMedicare = false, setEditing, editing, timeZone }) {
  const { setProfile, profile } = useAuth();
  const initialStartTimeOffset = getTimeOffset(details.startTime, timeZone);
  const initialEndTimeOffset = getTimeOffset(details.endTime, timeZone);
  const shiftTimeOffset = details.startTime - initialStartTimeOffset;
  const [startTimeOffset, setStartTimeOffset] = useState(initialStartTimeOffset);
  const [endTimeOffset, setEndTimeOffset] = useState(initialEndTimeOffset);
  const [unpaidBreak, setUnpaidBreak] = useState(details.unpaidBreak);
  const [rate, setRate] = useState(details.rate.toFixed(2));
  const [loading, setLoading] = useState(false);
  const onCancel = () => {
    setStartTimeOffset(initialStartTimeOffset);
    setEndTimeOffset(initialEndTimeOffset);
    setUnpaidBreak(details.unpaidBreak);
    setRate(details.rate);
    setEditing(false);
  }
  const onSave = () => {
    const startTime = shiftTimeOffset + startTimeOffset;
    const endTime = shiftTimeOffset + endTimeOffset;
    const ratePerHour = rate !== "" ? Math.round(parseFloat(rate).toFixed(2) * 100) : 0;
    const duration = ((endTime - startTime) / MILLISECONDS_IN_AN_HOUR) - (unpaidBreak / 60);
    const total = Math.round(duration * ratePerHour);
    setLoading(true);
    postShiftChangeFromLocum(shiftId, {
      startTime,
      endTime,
      unpaidBreakTime: unpaidBreak * 60 * 1000,
      rate: ratePerHour,
      total,
    }, registeredForGst)
      .then(() => {
        getProfile().then(({ profile} ) => {
          setProfile(profile);
          setEditing(false);
          setLoading(false);
          if (rate === "") {
            setRate(0);
          }
        })
      })
  };

  const [registeredForGst, setRegisteredForGst] = useState(!profile.gstFree);

  const onChangeRate = (e) => {
    if (e.target.value === "" || (FLOAT_REGEX.test(e.target.value) && parseFloat(e.target.value) <= MAXIMUM_RATE)) {
      setRate(e.target.value);
    }
  };
  const selectStartTime = (timeOffset) => {
    const duration = Math.round((endTimeOffset - timeOffset) / MILLISECONDS_IN_AN_HOUR);
    if (duration * 60 < unpaidBreak) {
      setUnpaidBreak(duration * 60);
    }
    setStartTimeOffset(timeOffset);
  };
  const selectEndTime = (timeOffset) => {
    const duration = Math.round((timeOffset - startTimeOffset) / MILLISECONDS_IN_AN_HOUR);
    if (duration * 60 < unpaidBreak) {
      setUnpaidBreak(duration * 60);
    }
    setEndTimeOffset(timeOffset);
  };

  const editButton = <button className={'edit-button'} onClick={() => setEditing(true)}>{'Edit details'}</button>;
  const saveButton = (
    <section className={'cancel-and-save'}>
      <button className={`cancel-button ${loading ? 'disabled' : ''}`} onClick={onCancel} disabled={loading}>{'Cancel'}</button>
      <LoadingButton loading={loading} onClick={onSave} classNames={['save-button']} text={'Save'} disabled={rate === ""} />
    </section>
  );
  const editingButton = editing ? saveButton : editButton;
  const duration = (endTimeOffset - startTimeOffset) / MILLISECONDS_IN_AN_HOUR;
  const totalDuration = duration - (unpaidBreak / 60);
  const totalDurationText = `${totalDuration.toFixed(2)} hrs`;
  const total = rate !== "" ? (totalDuration * parseFloat(rate)).toFixed(2) : '0.00';
  const totalText = `$${total}`; 
  const timeDisplay = formatDatesWithTimeZone(new Date(shiftTimeOffset + startTimeOffset), new Date(shiftTimeOffset + endTimeOffset), true, timeZone);
  const startTimeDisplay = editing
    ?
      <DateTimeDropdown selected={startTimeOffset} setSelected={selectStartTime} endTimeOffset={endTimeOffset} />
    : timeDisplay.startTimeDisplay;
  const endTimeDisplay = editing
    ? <DateTimeDropdown selected={endTimeOffset} setSelected={selectEndTime} startTimeOffset={startTimeOffset} />
    : timeDisplay.endTimeDisplay;
  const unpaidBreakDisplay = editing
    ? <TimeDropdown selected={unpaidBreak} setSelected={setUnpaidBreak} intervalSize={15} maxSize={Math.min(duration * 60, 120)} />
    : `${unpaidBreak} minutes`;
  const rateDisplay = editing 
    ? <input className={`rate ${billToMedicare ? 'disabled' : ''}`} type={'text'} placeholder={'0.00'} value={billToMedicare ? 'NA': rate} onChange={onChangeRate} disabled={billToMedicare}/>
    : `${rate}`;
  const registeredForGstText = registeredForGst ? 'Yes' : 'No';
  const registeredForGstDisplay = editing ? <YesNoDropdown selected={registeredForGst} setSelected={setRegisteredForGst} /> : registeredForGstText;
  const isMobile = useMediaQuery('(max-width: 700px)');
  const detailsProps = { dateDisplay: timeDisplay.date, startTimeDisplay, endTimeDisplay, unpaidBreakDisplay, rateDisplay, totalDurationText, totalText, registeredForGstDisplay, editingButton };
  const confirmDetailsSelection = !isMobile ? 
    <WebConfirmDetailsSelection { ...detailsProps } /> : <MobileConfirmDetailsSelection { ...detailsProps} />;
  return confirmDetailsSelection;
}

function GenerateInvoiceSelection({ selected, select }) {
  const yesLabel = 'Yes, automatically generate my invoice and send it to the store! Note: Your invoice will be finalised once you answer 3 short questions.'
  const noLabel = 'No, I will generate my own invoice. Note: Locumly will be unable to track the status and follow up invoices on your behalf if it is self generated.';
  const selectYes = () => select(true);
  const selectNo = () => select(false);
  return (
    <section className={'generate-invoice-selection'}>
      <h2 className={'modal-header'}>{'Would you like us to generate your invoice?'}</h2>
      <section className={'selection-options'}>
        <SelectionBox name={'yes-generate-invoice'} checked={selected} label={yesLabel} onClick={selectYes} />
        <SelectionBox name={'no-generate-invoice'} checked={!selected} label={noLabel} onClick={selectNo} />
      </section>
    </section>
  );
}

function CompleteShiftModal({ confirm, closeModal, shift }) {
  const { GENERATE_INVOICE, CONFIRM_DETAILS, STORE_REVIEW, STORE_ENJOYMENT, STORE_FEEDBACK } = PAGE_STATES;
  const [pageState, setPageState] = useState(PAGE_STATES.GENERATE_INVOICE);
  const [invoiceGeneration, setInvoiceGeneration] = useState(true);
  const [storeReview, setStoreReview] = useState(REVIEW_OPTIONS.VERY_KEEN.key);
  const [storeEnjoyment, setStoreEnjoyment] = useState(ENJOYMENT_OPTIONS.VIBES_FROM_STAFF.key);
  const [feedback, setFeedback] = useState('');
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const isPositiveReview = storeReview === REVIEW_OPTIONS.VERY_KEEN.key || storeReview === REVIEW_OPTIONS.SOMEWHAT_KEEN.key;
  const moveToGenerateInvoice = () => setPageState(GENERATE_INVOICE);
  const moveToConfirmDetails = () => setPageState(CONFIRM_DETAILS);
  const moveToStoreReview = () => setPageState(STORE_REVIEW);
  const moveToStoreEnjoyment = () => setPageState(STORE_ENJOYMENT);
  const moveToStoreFeedback = () => setPageState(STORE_FEEDBACK);
  let modalContent = null;
  let continueReview = () => {};
  let back = () => {};
  let continueText = 'Continue';
  let closeText = 'Back';
  let submitClassName = '';
  switch (pageState) {
    case GENERATE_INVOICE:
      continueReview = moveToConfirmDetails;
      back = closeModal;
      closeText = 'Cancel'
      modalContent = <GenerateInvoiceSelection select={setInvoiceGeneration} selected={invoiceGeneration} />
      break;
    case CONFIRM_DETAILS:
      continueReview = moveToStoreReview;
      back = moveToGenerateInvoice;
      const unpaidBreak = Math.round(shift.unpaidBreakTime * 60 / MILLISECONDS_IN_AN_HOUR);
      const rate = parseFloat((shift.rate / 100).toFixed(2));
      const details = { startTime: shift.startTime, endTime: shift.endTime, rate, unpaidBreak };
      const { address } = shift;
      const { timeZoneOffset, timeZone } = address;
      modalContent = <ConfirmDetailsSelection details={details} shiftId={shift._id} billToMedicate={shift.billToMedicare} setEditing={setEditing} editing={editing} timeZoneOffset={timeZoneOffset} timeZone={timeZone} />;
      break;
    case STORE_REVIEW:
      continueReview = isPositiveReview ? moveToStoreEnjoyment : moveToStoreFeedback;
      back = moveToConfirmDetails;
      modalContent = <StoreReviewSelection select={setStoreReview} selected={storeReview} />;
      break;
    case STORE_ENJOYMENT:
      continueReview = moveToStoreFeedback;
      back = moveToStoreReview;
      modalContent = <StoreEnjoymentSelection select={setStoreEnjoyment} selected={storeEnjoyment} />;
      break;
    case STORE_FEEDBACK:
      continueReview = () => {
        const review = {
          storeReview,
          storeEnjoyment: isPositiveReview ? storeEnjoyment : null,
          feedback,
        };
        confirm(invoiceGeneration, review, setLoading);
      };
      back = isPositiveReview ? moveToStoreEnjoyment : moveToStoreReview;
      modalContent = <StoreFeedbackSelection isPositive={isPositiveReview} select={setFeedback} selected={feedback} />;
      continueText = 'Submit';
      submitClassName = 'submit';
      break;
    default:
      return null;
  }
  return (
    <article className={'complete-shift-modal-container'}>
      <section className={'complete-shift-modal'}>
        <section className={'close-button-container'}>
          <button className={'close-button'} onClick={closeModal}>{'⨯'}</button>
        </section>
        <section className={'modal-content'}>{modalContent}</section> 
        <LoadingButton loading={loading} disabled={loading || editing} onClick={continueReview} classNames={['continue-button', submitClassName]} text={continueText} />
        <button className={'continue-button back'} onClick={back}>{closeText}</button>
      </section>
    </article>
  )
}

export default CompleteShiftModal;