import React, { useState } from 'react';
import useAuth from '../hooks/AuthHook.jsx';
import { formatDatesWithTimeZone } from '../../helpers/DateUtils.js';
import LocumPage from './LocumPage.jsx';
import { LOCUM_NAVIGATION_BUTTONS } from './LocumNavigationSidebar.jsx';
import { ManageShift } from './Schedule.jsx';
import { ASSET_CDN_URL, SHIFT_STATUSES } from '../Constants.js';
import './LocumShiftsPage.scss';

const EditIcon = `${ASSET_CDN_URL}/icons/edit-pen-icon.svg`;
const { UNFILLED, FILLED, CONFIRMED, COMPLETED, INVOICE, DISPUTED, PAID } = SHIFT_STATUSES;
export const TUTORIAL_URL = '/tutorials/additional-provider-numbers';

export const ALL_STATUSES = [UNFILLED, FILLED, CONFIRMED, COMPLETED, INVOICE, DISPUTED, PAID].map(({ key }) => key);
export const CONFIRMED_STATUSES = [FILLED, CONFIRMED].map(({ key }) => key);
const UPCOMING_STATUSES = [UNFILLED, FILLED, CONFIRMED].map(({ key }) => key);
export const PAYMENT_PENDING_STATUSES = [FILLED, CONFIRMED, COMPLETED, INVOICE, DISPUTED].map(({ key }) => key);
const COMPLETED_STATUSES = [...PAYMENT_PENDING_STATUSES, PAID.key];

const AllTitleAndSubtitle = ({shifts, requested, confirmed}) => {
  const pendingPayShiftsCount = shifts.filter(shift => new Date(shift.endTime) < new Date() && PAYMENT_PENDING_STATUSES.includes(shift.status)).length
  return (
    <>
      <div className={'shifts-counts-container'}>
        <div className={'shift-count'}>
          {'You have '}
          <span className={'count'}>{confirmed}</span>
          {' upcoming confirmed shifts'}
        </div>
        <div className={'shift-count'}>
          {'You have '}
          <span className={'count'}>{requested}</span>
          {' requested shifts'}
        </div>
        <div className={'shift-count'}>
          {'You have '}
          <span className={'count'}>{pendingPayShiftsCount}</span>
          {' shifts pending payment'}
        </div>
      </div>
    </>
  )
};

const ShiftViewButtons = ({view, setView}) => {
  return (
    <div className={'view-buttons'}>
      <div className={`change-view-button ${view === 'all' ? 'selected' : ''}`} onClick={() => setView('all')}>
        {'All Shifts'}
      </div>
      <div className={`change-view-button ${view === 'upcoming' ? 'selected' : ''}`} onClick={() => setView('upcoming')}>
        {'Upcoming Shifts'}
      </div>
      <div className={`change-view-button ${view === 'completed' ? 'selected' : ''}`} onClick={() => setView('completed')}>
        {'Completed Shifts'}
      </div>
    </div>
  );
}

const ShiftView = ({ shift }) => {
  const { name, address, startTime, endTime, billToMedicare, rate, negotiable, status } = shift;
  const { state, suburb, timeZone } = address;
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);
  const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(startDate, endDate, false, timeZone);
  const rateDisplay = billToMedicare ? 'Rate: NA' : `$${(rate / 100).toFixed(2)}/hr`;
  const [manageShift, setManageShift] = useState(false);

  const StatusDisplay = (status) => {
    if (new Date(endTime) > new Date() && status === UNFILLED.key) {
      return {text: 'Requested', className: 'requested'};
    } 
    else if (new Date(endTime) > new Date() && (status === CONFIRMED.key || status === FILLED.key)) {
      return {text: 'Confirmed', className: 'confirmed'};
    } 
    else if (new Date(endTime) < new Date() && PAYMENT_PENDING_STATUSES.includes(status)) {
      return { text: 'Not Paid', className: 'not-paid' };
    }
    else if (status === PAID.key) {
      return { text: 'Paid', className: 'paid' };
    }
  }

  const shiftStatus = StatusDisplay(status);

  const ViewShift = () => {
    const newWindow = window.open(`/find/shift?id=${shift._id}`, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  }

  const ShowModal = (e) => {
    e.stopPropagation();
    setManageShift(prevState => !prevState);
  }

  return (
    <li className={'shift-details'} onClick={() => ViewShift()}>
      <img className={'edit-icon'} src={EditIcon} alt={'edit-shift'} onClick={(e) => ShowModal(e)} />
      <div className={'shift-title'}>
        <span className={'shift-name'}>{name}</span>
        <span className={'shift-address'}>{`${suburb}, ${state}`}</span>
      </div>
      <div className={'shift-datetime'}>
        <span>{date}</span>
        <span>{`${startTimeDisplay} - ${endTimeDisplay}`}</span>
      </div>
      <div className={'shift-rate'}>
        <span className={'shift-rate-display'}>{rateDisplay}</span>
        <span className={'shift-nego'}>{negotiable ? 'Rate Negotiable' : 'Rate Fixed'}</span>
      </div>
      <div>
        <span className={`shift-status ${shiftStatus.className}`}>{shiftStatus.text}</span>
      </div>
      {manageShift && 
        <ManageShift 
          shift={shift}
          setManageShifts={setManageShift}
          onSchedule={false}
        />
      }
    </li>
  )
}

export const LocumShiftList = ({ shifts }) => {
  const emptyText = 'No Shifts';
  const shiftList = shifts
    .filter(shift => !(new Date(shift.endTime) < new Date() && shift.status === UNFILLED.key))
    .map(shift => 
      <ShiftView
        shift={shift}
      />
    )
  return (
    <section className={'shift-list-view'}>
      <ul className={'shift-list'}>
        {shiftList}
        {shiftList.length === 0 && <span className={'no-shifts-text'}>{emptyText}</span> }
      </ul>
    </section>
  );
};

const LocumShiftsPage = () => {
  const { profile } = useAuth();
  const shifts = profile.shifts
    .filter(shift => ALL_STATUSES.includes(shift.status))
    .sort((e1, e2) => e1.startTime - e2.startTime);

  const requestedShifts = profile.shifts
    .filter(shift => shift.startTime > new Date().getTime() && shift.status === SHIFT_STATUSES.UNFILLED.key)

  const upcomingShifts = profile.shifts
    .filter(shift => shift.startTime > new Date().getTime() && UPCOMING_STATUSES.includes(shift.status))
    .sort((e1, e2) => e1.startTime - e2.startTime);

  const completedShifts = profile.shifts
    .filter(shift => new Date(shift.endTime) < new Date() && COMPLETED_STATUSES.includes(shift.status))
    .sort((e1, e2) => e1.endTime - e2.endTime);

  const confirmedShifts = profile.shifts
    .filter(shift => shift.startTime > new Date().getTime() && CONFIRMED_STATUSES.includes(shift.status))
    .sort((e1, e2) => e1.endTime - e2.endTime);

  const [view, setView] = useState('all');
  return (
    <LocumPage mode={LOCUM_NAVIGATION_BUTTONS.ALL.mode}>
      <article className={'shifts-page'}>
        <AllTitleAndSubtitle shifts={shifts} requested={requestedShifts.length} confirmed={confirmedShifts.length}/>
        <ShiftViewButtons view={view} setView={setView}/>
        <section className={'shift-viewer-container'}>
          <LocumShiftList
            shifts={view === 'all' ? shifts : view === 'upcoming' ? upcomingShifts : completedShifts}
          />
        </section>
      </article>
    </LocumPage>
  );
}

export default LocumShiftsPage;
