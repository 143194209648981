import React, { useState } from 'react';
import { postEmailMessage } from '../Api.js';
import { ASSET_CDN_URL } from '../Constants.js';
import './ContactPage.scss';

const EmailIcon = `${ASSET_CDN_URL}/icons/email-icon.png`;

const MessageUs = ({ setView }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [errorText, setErrorText] = useState('');

  const onChangeName = (e) => {
    setErrorText('');
    setName(e.target.value)
  }

  const onChangeEmail = (e) => {
    setErrorText('');
    setEmail(e.target.value)
  }

  const onChangeMessage = (e) => {
    setErrorText('');
    setMessage(e.target.value)
  }
  const submit = () => {
    postEmailMessage(name, email, message)
      .then(() => {
        setView('SENT');
      })
      .catch(() => setErrorText('Something went wrong.'));
  }
  return (
    <section className={'message-us'}>
      <h2 className={'title'}>{'Send us a message'}</h2>
      <span className={'subtitle'}>
        {'Got questions? Send us a message and our Support team will be in contact shortly!'}
      </span>
      <span className={'error-text'}>{errorText}</span>
      <input type={'text'} className={'input-name'} placeholder={'Name'} value={name} onChange={onChangeName} />
      <input type={'text'} className={'input-email'} placeholder={'Email'} value={email} onChange={onChangeEmail} />
      <textarea className={'input-message'} placeholder={'Message'} value={message} onChange={onChangeMessage} />
      <button className={'submit-button very-dark'} onClick={submit}>{'Send'}</button>
    </section>
  );
}

const ContactPage = () => {
  const [view, setView] = useState('MESSAGE');
  return (
    <article className={'contact-page'}>
      <h1 className={'title'}>{`Get in touch`}</h1>
      <span className={'subtitle'}>{'Interested in contacting us?'}</span>
      <section className={'contact'}>
        <section className={'email'}>
          <img className={'email-image'} src={EmailIcon} alt={'Email icon'} />
          <section className={'email-details'}>
            <span className={'label'}>{'Email'}</span>
            <span className={'address'}>{'hello@locum.ly'}</span>
          </section>
        </section>
        <section className={'message-container'}>
          {view === 'MESSAGE' && <MessageUs setView={setView} />}
          {view === 'SENT' && 
            <span className={'confirmation'}>
              {'Your message has been sent successfully! Hang tight and our support team should respond in under 24 hours.'}
            </span>
          }
        </section>
      </section>
    </article>
  );
}

export default ContactPage;