import React, { useState, useEffect, useRef } from 'react';
import { ASSET_CDN_URL, SHIFT_TYPES, DEFAULT_LOCATION } from '../Constants';
import { useLocation, useSearchParams } from 'react-router-dom';
import { computeDistance, orderShifts, filterShifts, filterShiftsByMonth, getDefaultDates } from '../../helpers/ShiftUtils.js';
import ShiftCard from '../locum/ShiftCard.jsx';
import FindStoreCard from '../locum/FindStoreCard.jsx';
import useAuth from '../hooks/AuthHook.jsx';
import { WebSearchButton } from '../common/navbar/NavSearch.jsx';
import { getAllShifts, getAllStores } from '../Api.js';
import { useMap, useOutsideClick, useMediaQuery } from '../hooks/CommonHooks.jsx';
import { debounce } from "../../helpers/debounce.js";
import MapShifts from '../locum/MapShifts.jsx';
import './FindPage.scss';

const MapIcon = `${ASSET_CDN_URL}/find/map-view.svg`;
const ListIcon = `${ASSET_CDN_URL}/find/list-view.svg`;
const SortIcon = `${ASSET_CDN_URL}/find/sort.svg`;
const SortDirIcon = `${ASSET_CDN_URL}/find/sort-arrow.svg`;
const StoreBackArrow = `${ASSET_CDN_URL}/find/no-tail-arrow.svg`;
const NewAddIcon = `${ASSET_CDN_URL}/find/newly-added.svg`;
const RegionalIcon = `${ASSET_CDN_URL}/find/regional.svg`;
const TopRatedIcon = `${ASSET_CDN_URL}/find/top-rated.svg`;
const OptometryIcon = `${ASSET_CDN_URL}/find/optometry.svg`;
const PharmacyIcon = `${ASSET_CDN_URL}/find/pharmacy.svg`;

const filterStores = (stores, storeType, networkFilter, filterAllStores, showRegional, showTopRated) => {
  return stores
    .filter(({ isTopRated }) => !showTopRated || isTopRated === showTopRated)
    .filter(({ isRegional }) => !showRegional || isRegional === 'REGIONAL')
    .filter(({ type }) => storeType === type)
    .filter(({ shifts }) => filterAllStores || shifts.length > 0)
    .filter(({ network }) => networkFilter[network] || (network === '' && networkFilter['OTHER']));
}

const orderStores = (stores, orderByType) => {
  switch (orderByType) {
    case 'distance-ascending':
      return stores.sort((e1, e2) => e1.distance - e2.distance);
    case 'distance-descending':
      return stores.sort((e1, e2) => e2.distance - e1.distance);
    case 'zero-bottom-shifts-ascending':
      return stores.sort((e1, e2) => {
        if (e1.shifts.length === 0) return 1;
        if (e2.shifts.length === 0) return -1;
        return e1.shifts.length - e2.shifts.length;
      })
    case 'shifts-descending':
      return stores.sort((e1, e2) => e1.shifts.length - e2.shifts.length);
    case 'shifts-ascending':
    default:
      return stores.sort((e1, e2) => e2.shifts.length - e1.shifts.length);
  }
}

const SortItem = ({ type, sortType, sortOrder, jobView }) => {
  const [arrowDir, setArrowDir] = useState(true);
  const handleClick = (e, jobView, type, asc) => {
    e.stopPropagation();
    sortOrder(jobView, type, asc);
    setArrowDir(asc ? !arrowDir : arrowDir);
  }
  return (
    <div className={'check-item'}>
      <div className={'check-input-container'}>
        <input className={'check-input'} type={'checkbox'} value={type.key} id={type.key} checked={sortType[jobView ? 'shift' : 'store'].type === type.key} onClick={(e) => handleClick(e, jobView, type.key, false)} />
        <label className={'check-text'} for={type.key}>
          {type.text}
        </label>
      </div>
      <img className={arrowDir ? 'check-arrow-dir' : 'check-arrow-dir rotate-arrow'} src={SortDirIcon} alt={'sort direction icon'} onClick={(e) => handleClick(e, jobView, type.key, true)} />
    </div>
  )
}

const QuickFilters = ({newlyAdded, setNewlyAdded, shiftType, setShiftType, showRegional, setShowRegional, showTopRated, setShowTopRated, searchParams, setSearchParams}) => {
  const handleFilterClick = (setFilter, key, value) => {
    setFilter(prevState => !prevState);
    setSearchParams({...searchParams, [key]: !value})
  }
  return (
    <section className={'quick-filter-container'}>
      <div className={'quick-filter-border'}>
        <div className={`quick-filter ${newlyAdded ? 'selected' : ''}`} onClick={() => handleFilterClick(setNewlyAdded, 'newlyAdded', newlyAdded)}>
          <img className={`quick-filter-icon`} src={NewAddIcon} alt={'Newly-added shift icon'}/>
          <div>{'Newly Added'}</div>
        </div>
        <div className={`quick-filter ${showRegional ? 'selected' : ''}`} onClick={() => handleFilterClick(setShowRegional, 'discoverRegional', showRegional)}>
          <img className={`quick-filter-icon`} src={RegionalIcon} alt={'Discover regional shift icon'}/>
          <div>{'Discover Regional'}</div>
        </div>
        <div className={`quick-filter ${showTopRated ? 'selected' : ''}`} onClick={() => handleFilterClick(setShowTopRated, 'isTopRated', showTopRated)}>
          <img className={`quick-filter-icon`} src={TopRatedIcon} alt={'Top Rated store icon'}/>
          <div>{'Top rated stores'}</div>
        </div>
        <div className={`quick-filter ${shiftType === SHIFT_TYPES.OPTOMETRY.key ? 'selected' : ''}`} onClick={() => setShiftType(SHIFT_TYPES.OPTOMETRY.key)}>
          <img className={`quick-filter-icon`} src={OptometryIcon} alt={'Optometry filter icon'} />
          <div>{'Optometry'}</div>
        </div>
        <div className={`quick-filter ${shiftType === SHIFT_TYPES.PHARMACY.key ? 'selected' : ''}`} onClick={() => setShiftType(SHIFT_TYPES.PHARMACY.key)}>
          <img className={`quick-filter-icon`} src={PharmacyIcon} alt={'Pharmacy filter icon'} />
          <div>{'Pharmacy'}</div>
        </div>
      </div>
    </section>
  )
}

/** Component for Locumly Find Page. */
const FindPage = () => {
  const [shifts, setShifts] = useState(null);
  const [allStores, setAllStores] = useState(null);
  const [highlightedShift, setHighlightedShift] = useState(null);
  const [highlightedStore, setHighlightedStore] = useState(null);
  const [viewStoreShifts, setViewStoreShifts] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [includeEmptyStores, setIncludeEmptyStores] = useState(true);
  const [foundShifts, setFoundShifts] = useState(null);
  const isMobile = useMediaQuery('(max-width: 480px)');
  const isTablet = useMediaQuery('(max-width: 780px)');
  const { state, pathname } = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  let searchParamObj = {};
  for (const val of searchParams.entries()) {
    searchParamObj[val[0]] = val[1] === 'true';
  }

  const [isListView, setListView] = useState(state && state.hasOwnProperty('isListView') ? state.isListView : true);
  const [jobView, setJobView] = useState(state && state.hasOwnProperty('jobView') ? state.jobView : true);
  const [newlyAdded, setNewlyAdded] = useState(searchParams.has('newlyAdded') ? searchParamObj.newlyAdded : false);
  const [showRegional, setShowRegional] = useState(searchParams.has('discoverRegional') ? searchParamObj.discoverRegional : false);
  const [showTopRated, setShowTopRated] = useState(searchParams.has('isTopRated') ? searchParamObj.isTopRated : false);


  const storeIds = state && state.storeId ? [state.storeId] : [];
  const [mapStores, setMapStores] = useState([]);
  const { authed, profile } = useAuth();

  const [mapCenter, setMapCenter] = useState(null);
  const filterAllStores = state && state.otherFilter && state.otherFilter.allStores ? state.otherFilter.allStores : false;
  const location = state && state.whereFilter && state.whereFilter.searchText ? state.whereFilter : DEFAULT_LOCATION;

  const [requestedShiftList, setRequestedShifts] = useState(profile && profile.shifts ? profile.shifts : [])
  useEffect(() => {
    if (profile && profile.shifts) {
      setRequestedShifts(profile.shifts.map(shift => shift._id));
    }
  }, [profile]);
  const dates = getDefaultDates(state);
  const useDates = state && state.whenFilter ? state.whenFilter.useDates : true;
  const [shiftType, setShiftType] = useState((state && state.otherFilter && state.otherFilter.shiftType) ? state.otherFilter.shiftType : (authed && profile) ? profile.type : SHIFT_TYPES.OPTOMETRY.key);
  const networkFilter = state && state.otherFilter && state.otherFilter.networks ? state.otherFilter.networks : {
    '1001_OPTICAL': true,
    'BAILEY_NELSON': true,
    'BUPA_OPTICAL': true,
    'GEORGE_AND_MATILDA': true,
    'ONE_THOUSAND_AND_ONE_OPTICAL': true,
    'OPSM': true,
    'OSCAR_WYLEE': true,
    'EYECARE_PLUS': true,
    'OTHER': true,
    'SPECSAVERS': true,
    '': true}
  const order = 'date-ascending';
  const sortMethods = {
    shift: [
      {key:'distance', text: 'Distance'},
      {key:'date', text: 'Date'},
      {key:'rate', text: 'Rate'}
    ],
    store: [
      {key:'distance', text: 'Distance'},
      {key:'shifts', text: 'No. Shifts'}
    ]
  }
  const navigationShift = state && state.shift ? state.shift : null;
  const [selectedShift, selectShift] = useState(navigationShift);

  // Sort Modal, order: 0 = asc, 1 = desc
  const [sortType, setSortType] = useState({
    shift: {
      type: 'distance',
      asc: true
    },
    store: {
      type: 'distance',
      asc: true
    }
  });
  const [showSortModal, setShowSortModal] = useState(false);
  const sortModal = useRef(null);
  useOutsideClick(sortModal, () => showSortModal && setShowSortModal(false));
  const mapContainer = useRef(null);
  const map = useRef(null);
  useMap(mapContainer, map, location, isListView, authed, shifts, highlightedStore, setHighlightedStore, 
    highlightedShift, setHighlightedShift, setMapStores, jobView, setMapCenter, allStores, isMobile, state, 
    newlyAdded, showRegional, showTopRated, setIncludeEmptyStores);

  let storeList = null;
  let shiftList = null;
  let mapShiftList = null;
  let selectedStoreShifts = null;

  if (shifts === null) {
    shiftList = (
    <span className={'loading-text'}>
      {'Loading...'}
    </span>)
  }
  else if (shifts.length === 0) {
    shiftList = (<span className={'no-shifts-text'}>{'No shifts found. Try again later!'}</span>)
  }
  else {
    const filteredShifts = useDates ? filterShifts(shifts, shiftType, dates.fromDate, dates.toDate, location, networkFilter, newlyAdded, showRegional, showTopRated) : filterShiftsByMonth(shifts, shiftType, dates.searchMethod, dates.selectedItems, location, networkFilter, newlyAdded, showRegional, showTopRated);
    const selectedShifts = isListView ? filteredShifts : filteredShifts.filter(shift => mapStores.includes(shift.storeId));
    if (filteredShifts.length === 0) {
      shiftList = (
        <span className={'no-shifts-text'}>
          {'No shifts found. Try increase your search radius or date range.'}
        </span>
      );
    }
    else {
      const distanceShifts = selectedShifts
        .map(shift => ({...shift, distance: computeDistance(location.coordinates, shift.address.coordinates, 'kilometers')}));
      const orderedShifts = orderShifts(distanceShifts, `${sortType.shift.type}-${sortType.shift.asc ? 'ascending' : 'descending'}`);
      shiftList = (orderedShifts
        .map(shift => 
          <ShiftCard
            distance={computeDistance(location.coordinates, shift.address.coordinates, 'kilometers')}
            select={selectShift}
            shift={shift}
            selectedStoreIds={storeIds}
            selectedShift={selectedShift}
            currentState={state}
            location={location}
            allShifts={shifts}
            requestedShiftList={requestedShiftList}
            key={`shift-${shift._id}`}
          />
        ));
    }
  }

  if (foundShifts) {
    if (foundShifts.length === 0) {
      selectedStoreShifts = 
      <span className={'no-shifts-text'}>
        {'This store has no active shifts. Click the "Notify me for new shifts" button to get notified when a new shift gets listed!'}
      </span>
    } else {
      const orderedShifts = orderShifts(foundShifts, 'date-ascending');
      selectedStoreShifts = orderedShifts.map(shift => 
        <ShiftCard 
          distance={computeDistance(location.coordinates, shift.address.coordinates, 'kilometers')}
          select={selectShift}
          shift={shift}
          selectedStoreIds={storeIds}
          selectedShift={selectedShift}
          currentState={state}
          location={location}
          allShifts={shifts}
          requestedShiftList={requestedShiftList}
          key={`store-shift-${shift._id}`}
        />)
    }
  }
  
  // Only display shift cards that are on the map.
  let orderedMapShifts = null;
  if (shifts !== null && shifts.length > 0) {
    const filteredShifts = useDates ? filterShifts(shifts, shiftType, dates.fromDate, dates.toDate, location, networkFilter, newlyAdded, showRegional, showTopRated) : filterShiftsByMonth(shifts, shiftType, dates.searchMethod, dates.selectedItems, location, networkFilter, newlyAdded, showRegional, showTopRated);
    const mapFilteredShifts = filteredShifts.filter(shift => mapStores.includes(shift.storeId));
    orderedMapShifts = orderShifts(mapFilteredShifts, order);
  }

  let orderedMapStores = null;
  if (allStores !== null && allStores.length > 0) {
    orderedMapStores = allStores.filter(store => mapStores.includes(store._id));
  }

  if (isMobile && orderedMapShifts !== null && orderedMapStores !== null && map && typeof map.current !== 'undefined') {
    mapShiftList = <MapShifts 
      stores={allStores} 
      orderedMapShifts={orderedMapShifts}
      orderedMapStores={orderedMapStores}
      mapStores={mapStores}
      setHighlightedStore={setHighlightedStore} 
      setHighlightedShift={setHighlightedShift}
      map={map} 
      jobView={jobView}
      state={state}
      mapCenter={mapCenter}
      location={location}
      viewStoreShifts={viewStoreShifts}
      setViewStoreShifts={setViewStoreShifts}
      setSelectedStore={setSelectedStore}
      requestedShiftList={requestedShiftList}
      includeEmptyStores={includeEmptyStores}
    />;
  }

  if (allStores === null) {
    storeList = 
      <span className={'loading-text'}>
        {'Loading...'}
      </span>
  }
  else if (allStores.length === 0) {
    storeList = <span className={'no-shifts-text'}>{'No stores found. Try again later!'}</span>
  }
  else {
    // Filter stores to display based on search criteria. Only applicable search criteria is:
    // Shift Type and Distance
    const filteredStores = filterStores(allStores, shiftType, networkFilter, filterAllStores, showRegional, showTopRated);
    const distanceStores = filteredStores.map(store => ({...store, distance: computeDistance(location.coordinates, store.address.coordinates, 'kilometers')}))
    const orderedStores = orderStores(distanceStores, `${sortType.store.type}-${sortType.store.asc ? 'ascending' : 'descending'}`);
    if (orderedStores.length === 0) {
      storeList = (
        <span className={'no-shifts-text'}>
          {'No stores found. Try increase your search radius.'}
        </span>
      );
    } else {
      storeList = orderedStores
        .map(store =>
          <FindStoreCard 
            distance={computeDistance(location.coordinates, store.address.coordinates, 'kilometers')}
            store={store}
            storeId={store._id}
            setViewStoreShifts={setViewStoreShifts}
            setSelectedStore={setSelectedStore}
            shiftList={shifts.filter(shift => shift.storeId === store._id)}
            key={`store-${store._id}`}
            currentState={state}
            location={location}
            isListView={isListView}
          />
        );
    }
  }

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, [])

  useEffect(() => {
    if (shifts === null) {
      getAllShifts(true).then((response) => {
        const linkedShiftId = searchParams.get('shiftId');
        const linkedShift = response.shifts ? response.shifts.find(shift => shift._id === linkedShiftId) : null;
        selectShift(linkedShift ? linkedShift : null);
        setShifts(response.shifts);
      }).catch(() => setShifts([]));
    }
  }, [shifts, setShifts, selectShift, searchParams]);

  useEffect(() => {
    if (allStores === null && shifts !== null) {
      
      getAllStores(true).then((response) => {
        const allActiveShiftIds = shifts.map(shift => shift._id);
        const filteredStores = response.stores.map(store => {
          store.shifts = store.shifts.filter(shift => allActiveShiftIds.includes(shift))
          return store;
        });
        setAllStores(filteredStores);
      }).catch(() => setAllStores([]));
    }
  }, [allStores, setAllStores, shifts]);

  useEffect(() => {
    if (selectedStore && selectedStore.shifts.length > 0) {
      setFoundShifts(orderShifts(shifts.filter(shift => selectedStore.shifts.includes(shift._id)), 'distance-ascending'));
    } else {
      setFoundShifts([]);
    }
  }, [selectedStore, setSelectedStore, shifts])

  const [prevScrollPos, setPrevScrollPos] = useState(-1);
  const [visible, setVisible] = useState(true);

  const handleScroll = debounce(() => {
    const currentScrollPos = window.scrollY;

    // If refreshed, show footer, otherwise hide footer when scrolling down and show footer when scrolling up.
    setVisible(prevScrollPos === -1 
              || (prevScrollPos >= currentScrollPos)
              || currentScrollPos < 10);

    setPrevScrollPos(currentScrollPos);
  }, 60);

  useEffect(() => {
    if (isMobile) {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [prevScrollPos, visible, handleScroll, isMobile]);

  // When on map view, scroll to top of page and prevent scrolling.
  useEffect(() => {
    !isListView && !viewStoreShifts && pathname === '/find' ? document.body.classList.add('prevent-scroll') : document.body.classList.remove('prevent-scroll');
    window.scrollTo({top: 0, left: 0});
  }, [isListView, viewStoreShifts, pathname]);

  const filterLen = () => {
    if (jobView) {
      let numShifts;
      if (isListView) {
        numShifts = shiftList === null || (shiftList && shiftList.type) === "span" ? 0 : shiftList.length;
      } else {
        numShifts = orderedMapShifts === null ? 0 : orderedMapShifts.length;
      }
      return numShifts;
    } else {
      let numStores;
      if (isListView) {
        numStores = storeList === null || (storeList && storeList.type) === "span" ? 0 : storeList.length;
      } else {
        const filteredMapStores = includeEmptyStores ? orderedMapStores : orderedMapStores.filter(store => store.shifts.length > 0);
        numStores = orderedMapStores === null ? 0 : filteredMapStores.length;
      }
      return numStores;
    }
  }

  const formattedShifts = () => {
    return filterLen() === 1 
      ? (jobView ? 'shift awaits' : 'store found') 
      : (jobView ? 'shifts await' : 'stores found');
  }

  const viewStoreFormat = () => {
    const numShifts = selectedStore.shifts.length;
    return selectedStore && numShifts === 1 ? 'shift awaits' : 'shifts await';
  }

  const handleViewChange = () => {
    setViewStoreShifts(false);
    setListView(!isListView);
  }

  const sortOrder = (jobView, type, asc) => {
    if (jobView) {
      setSortType({...sortType, shift: {type, asc: asc ? !sortType.shift.asc : sortType.shift.asc}})
    } else {
      setSortType({...sortType, store: {type, asc: asc ? !sortType.store.asc : sortType.store.asc}})
    }
  }

  return (  
    <article className={`find-page ${storeIds.length > 0 ? 'selected' : ''}`}>
      <section className={'find-container'}>
        {!isMobile && <WebSearchButton />}
        {/* Find Page Heading */}
        {isTablet && <QuickFilters 
                        newlyAdded={newlyAdded} 
                        setNewlyAdded={setNewlyAdded} 
                        shiftType={shiftType}
                        setShiftType={setShiftType}
                        showRegional={showRegional}
                        setShowRegional={setShowRegional}
                        showTopRated={showTopRated}
                        setShowTopRated={setShowTopRated}
                        searchParams={searchParamObj}
                        setSearchParams={setSearchParams}
                      />}
        <section className={'find-view-selection'}>
          <div className={'view-container'}>
            <div className={'avail-shifts'}>
              <span className={'filter-num'}>{viewStoreShifts && selectedStore ? selectedStore.shifts.length : filterLen()}</span>
              <span className={'formatted-text'}>{viewStoreShifts ? viewStoreFormat() : formattedShifts()}</span>
            </div>
            {(viewStoreShifts && selectedStore !== null) &&
              <div className={'store-shift-container'} onClick={(e) => setViewStoreShifts(false)}>
                <img className={'store-back-arrow'} src={StoreBackArrow} alt={''}/>
                <div className={'store-shift-title'}>
                  <span className={'store-shift-name'}>{selectedStore.name}</span>
                  <span className={'store-shift-address'}>{`${selectedStore.address.suburb}, ${selectedStore.address.state}`}</span>
                </div>
              </div>
            }

            {!viewStoreShifts && 
            <>
            {!isTablet && <QuickFilters 
                        newlyAdded={newlyAdded} 
                        setNewlyAdded={setNewlyAdded} 
                        shiftType={shiftType}
                        setShiftType={setShiftType}
                        showRegional={showRegional}
                        setShowRegional={setShowRegional}
                        showTopRated={showTopRated}
                        setShowTopRated={setShowTopRated}
                        searchParams={searchParamObj}
                        setSearchParams={setSearchParams}
                      />}
            <div className={'view-type-container'}>
              <div className={`view-type shift-view${jobView ? ' selected' : ''}`} onClick={() => setJobView(true)}>
                <span>{'Shift View'}</span>
              </div>
              <div className={`view-type store-view${jobView ? '' : ' selected'}`} onClick={() => setJobView(false)}>
                <span>{'Store View'}</span>
              </div>
            </div>
            </>}
            {isListView &&
              <section className={'sort-modal-container'} ref={sortModal}>
                <button className={'sort-icon-container'} onClick={() => setShowSortModal(!showSortModal)}>
                  {!isMobile && <span>{'Sort'}</span>}
                  <img className={`sort-icon ${isMobile ? '' : 'has-text'}`} src={SortIcon} alt={'Sort'} />
                </button>
                {showSortModal &&
                  <div className={'sort-modal'}>
                    <div className={'sort-modal-text'}>
                      <span>{'Sort By'}</span>
                      <img className={'sort-icon-modal'} src={SortIcon} alt={'Sort'} />
                    </div>
                    <div className={'check-container'}>
                      {sortMethods[jobView ? 'shift' : 'store'].map(type => 
                        <SortItem 
                          type={type}
                          sortType={sortType}
                          sortOrder={sortOrder}
                          jobView={jobView}
                          />
                      )}
                    </div>
                  </div>
                }
            </section>
            }
          </div>
        </section>
        {isListView && <section className={'shifts-container'}>
          <div className={'shifts-padding'}>
            {viewStoreShifts ? selectedStoreShifts : (jobView ? shiftList : storeList)}
          </div>
        </section>}
        {!isListView && viewStoreShifts && <section className={'shifts-container'}>
          <div className={'shifts-padding'}>
            {selectedStoreShifts}
          </div>
        </section>}
        <section className={viewStoreShifts ? 'map-container hide-map' : 'map-container'}>
          {!isListView && <section ref={mapContainer} className={'map-iframe'} />}
          {(!isListView && !jobView) && <nav id="menu"></nav>}
          {(!isListView && isMobile) && mapShiftList}
        </section>
        <div className={`map-button-container ${visible ? '' : 'bottom-map'} ${isMobile ? '' : 'web-view'}`}>
          <button className={'map-button'} onClick={() => handleViewChange()}>
            <span className={'map-button-text'}>{!isListView ? 'List View' : 'Map View'}</span>
            <img className={'map-icon'} src={!isListView ? ListIcon : MapIcon} alt={'Map Icon'} />
          </button>
        </div>
      </section>
    </article>
  );
};

export default FindPage;
