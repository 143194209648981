import './StorePhotoSection.scss';

import PropTypes from 'prop-types';
import React from 'react';

import StoreProfileSection from './StoreProfileSection';

const propTypes = {
  /* List of photos. */
  photos: PropTypes.arrayOf(PropTypes.string).isRequired,
};

function StorePhotoSection({ photos }) {
  const photoItems = photos.map((url) => {
    return (
      <li className={'store-photo-item'} key={url}>
        <img className={'store-photo'} src={url} alt={url} />
      </li>
    )
  });
  return (
    <StoreProfileSection
      titlePosition={'center'}
      sectionTitle={'Store Photos'}
      additionalClassNames={['photos']}
    >
      <ul className={'store-photo-list'}>
        {photoItems}
      </ul>
    </StoreProfileSection>
  )
}

StorePhotoSection.displayName = 'StorePhotoSection';
StorePhotoSection.propTypes = propTypes;
export default StorePhotoSection;