import { useEffect } from 'react';
import Cookies from 'js-cookie';

import useAuth from './AuthHook';
import { getProfile, getAllChats, getAllNotifications } from '../Api';

// Time we want to fetch new data from the backend
const TIME_TO_FETCH_PROFILE_IN_SECONDS = 1000 * 60 * 5;

export const useInitialProfileFetch = (firstLoad, setFirstLoad) => {
  const { authed, setAuthed, setProfile, setChats, setNotifications } = useAuth();
  useEffect(() => {
    const token = Cookies.get('token');
    if (!authed && token) {
      getProfile().then(({ profile }) => {
        setProfile(profile);
        setAuthed(true);
        setFirstLoad(true);
      })
      .catch(() => setFirstLoad(true));
    }
    else {
      setFirstLoad(true);
    }
  }, [firstLoad, setFirstLoad, authed, setAuthed, setProfile]);

  useEffect(async () => {
    if (authed) {
      getAllChats().then(({ chats }) => {
        setChats(chats);
      });
    }
  }, [authed])

  useEffect(async () => {
    if (authed) {
      getAllNotifications().then(({ notifications }) => {
        setNotifications(notifications);
      });
    }
  }, [authed]);
}

export const useProfileIntervalFetcher = () => {
  const { authed, setProfile } = useAuth();
  useEffect(() => {
    const timeId = setInterval(() => {
      if (authed) {
        getProfile().then(({ profile }) => {
          setProfile(profile);
        });
      }
    }, TIME_TO_FETCH_PROFILE_IN_SECONDS);
    return () => clearInterval(timeId);
  }, [authed, setProfile]);
}