import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { USER_ROLES, ASSET_CDN_URL } from '../Constants';
import useAuth from '../hooks/AuthHook.jsx';
import NotificationsAndMessagesSidebar from './NotificationsAndMessagesSidebar.jsx';
import './NotificationsPage.scss';
import { useMediaQuery } from '../hooks/CommonHooks.jsx';
import { GOOGLE_DIRECTIONS_URL } from '../locum/Shift.jsx';
import { formatDatesWithTimeZone } from '../../helpers/DateUtils.js';
import { postSeenNotifications } from '../Api.js';

const LocumlyLogo = `${ASSET_CDN_URL}/logo/locumly.png`;
const DropdownArrow = `${ASSET_CDN_URL}/dropdown/shift-rec-drop-arrow.svg`;

export const NOTIFICATION_TYPES = {
  BUSINESS_NEW_SHIFT_REQUEST: 'BUSINESS_NEW_SHIFT_REQUEST',
  BUSINESS_SHIFT_WITHDRAWAL: 'BUSINESS_SHIFT_WITHDRAWAL',
  LOCUM_SHIFT_DELETION: 'LOCUM_SHIFT_DELETION',
  LOCUM_SHIFT_SELECTION_SUCCESS: 'LOCUM_SHIFT_SELECTION_SUCCESS',
  LOCUM_SHIFT_SELECTION_UNSUCCESSFUL: 'LOCUM_SHIFT_SELECTION_UNSUCCESSFUL',
  LOCUM_PRE_SHIFT_DETAILS: 'LOCUM_PRE_SHIFT_DETAILS',
  LOCUM_SHIFT_REMINDER: 'LOCUM_SHIFT_REMINDER',
  LOCUM_SHIFT_CHANGE: 'LOCUM_SHIFT_CHANGE',
  BUSINESS_SHIFT_REMINDER: 'BUSINESS_SHIFT_REMINDER',
  LOCUM_SHIFT_PAYMENT: 'LOCUM_SHIFT_PAYMENT',
  BUSINESS_SHIFT_PAYMENT: 'BUSINESS_SHIFT_PAYMENT',
  BUSINESS_SHIFT_NOT_CONFIRMED: 'BUSINESS_SHIFT_NOT_CONFIRMED',
  WELCOME: 'WELCOME',
};

const getNotificationMessage = (notification, navigate, close) => {
  const { type } = notification;
  switch (type) {
    case NOTIFICATION_TYPES.BUSINESS_NEW_SHIFT_REQUEST: {
      const { shift } = notification;
      const { storeId, name, startTime, endTime, address } = shift;
      const { timeZone } = address;
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);
      const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(startDate, endDate, true, timeZone);
      const navToReview = (e) => {
        e.preventDefault();
        e.stopPropagation();
        navigate('/store/applicants/review', { state: { storeId }});
        close();
      };
      return (
        <section className={'notification-message'}>
          <p className={'paragraph'}>
          {`Hooray! You've got a new shift request for `}
            <span className={'name'}>{name}</span>
            {' for '}
            <span className={'shift-dates'}>
              <b>{date}</b>
              {' from '}
              <b>{startTimeDisplay}</b> 
              {' to '}
              <b>{endTimeDisplay}</b>
              {'.'}
            </span>
          </p>
          <p className={'paragraph'}>
            {'You can review your requests '}
            <button className={'link'} onClick={navToReview}>{'here'}</button>
            {'.'}
          </p>
        </section>
      );
    }
    case NOTIFICATION_TYPES.BUSINESS_SHIFT_WITHDRAWAL: {
      const { shift, firstName, lastName } = notification;
      const { storeId, name, startTime, endTime, applicants, address } = shift;
      const { timeZone } = address;
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);
      const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(startDate, endDate, true, timeZone);
      const navToReview = (e) => {
        e.preventDefault();
        e.stopPropagation();
        navigate('/store/applicants/review', { state: { storeId }});
        close();
      };
      const message = applicants.length > 0 
        ? 
          <>
            {'There are other applicants for this shift you can review '}
            <button className={'link'} onClick={navToReview}>{'here'}</button>
            {'.'}
          </>
        :
          <>
            {`Hang tight! Your shift currently being shown to all the locums in your area.`}
          </>;
      return (
        <section className={'notification-message'}>
          <p className={'paragraph'}>
            {`Unfortunately, `}
            <span className={'name'}>{`${firstName} ${lastName}`}</span>
            {' had to withdraw from their shift for '}
            <span className={'name'}>{name}</span>
            {' on '}
            <span className={'shift-dates'}>
              <b>{date}</b>
              {' from '}
              <b>{startTimeDisplay}</b> 
              {' to '}
              <b>{endTimeDisplay}</b>
            </span>
            {'. '}
          </p>
          <p className={'paragraph'}>
            {message}
          </p>
        </section>
      );
    }
    case NOTIFICATION_TYPES.LOCUM_SHIFT_DELETION: {
      const { shift } = notification;
      const { name, startTime, endTime, address } = shift;
      const { timeZone } = address;
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);
      const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(startDate, endDate, true, timeZone);
      const navToFind = (e) => {
        e.preventDefault();
        e.stopPropagation();
        navigate('/find');
        close();
      }
      return (
        <section className={'notification-message'}>
          <p className={'paragraph'}>
            {`The shift for `}
            <span className={'name'}>{name}</span>
            {' for '}
            <span className={'shift-dates'}>
              <b>{date}</b>
              {' from '}
              <b>{startTimeDisplay}</b> 
              {' to '}
              <b>{endTimeDisplay}</b>
              {' was deleted.'}
            </span>
          </p>
          <p className={'paragraph'}>
            {`Don't worry, plenty of other shifts are available.`}
          </p>
          <p className={'paragraph'}>
            {' Find more shifts '}
            <button className={'link'} onClick={navToFind}>{'here'}</button>
            {'.'}
          </p>
        </section>      
      );
    }
    case NOTIFICATION_TYPES.LOCUM_SHIFT_CHANGE: {
      const { shift } = notification;
      const { name, startTime, endTime, address } = shift;
      const { timeZone } = address;
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);
      const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(startDate, endDate, true, timeZone);
      const navToMessages = () => {
        navigate('/user/messages', { state: { 
          open: false,
          business: { 
            _id: shift.businessId, 
            firstName: shift.firstName, 
            lastName: shift.lastName
          }, 
          store: { 
            _id: shift.storeId, 
            avatar: shift.avatar, 
            name: shift.name
          }
        }})
        close();
      };
      return (
        <section className={'notification-message'}>
          <p className={'paragraph'}>
            {`Important: Submit your provider number to `}
            <span className={'name'}>{name}</span>
            {' ahead of your shift on '}
            <span className={'shift-dates'}>
              <b>{date}</b>
              {' from '}
              <b>{startTimeDisplay}</b> 
              {' to '}
              <b>{endTimeDisplay}</b>
              {'.'}
            </span>
          </p>
          <p className={'paragraph'}>
            {'You can '}
            <button className={'link'} onClick={navToMessages}>{'send a message'}</button> 
            {` to the store if you have any questions.`}
          </p>
        </section>      
      );
    }
    case NOTIFICATION_TYPES.LOCUM_SHIFT_SELECTION_SUCCESS: {
      const { shift } = notification;
      const { name, startTime, endTime, address } = shift;
      const { timeZone } = address;
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);
      const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(startDate, endDate, true, timeZone);
      const navToMessages = (e) => {
        e.preventDefault();
        e.stopPropagation();
        navigate('/user/messages', { state: { 
          open: false,
          business: { 
            _id: shift.businessId, 
            firstName: shift.firstName, 
            lastName: shift.lastName
          }, 
          store: { 
            _id: shift.storeId, 
            avatar: shift.avatar, 
            name: shift.name
          }
        }})
        close();
      };
      return (
        <section className={'notification-message'}>
          <p className={'paragraph'}>
            {`Hooray! You're confirmed for `}
            <span className={'name'}>{name}</span>
            {' for '}
            <span className={'shift-dates'}>
              <b>{date}</b>
              {' from '}
              <b>{startTimeDisplay}</b> 
              {' to '}
              <b>{endTimeDisplay}</b>
              {'. '}
            </span>
          </p>
          <p className={'paragraph'}>
            <button className={'link'} onClick={navToMessages}>{'Send a message'}</button>
            {' to the store if you have any questions.'}
          </p>
        </section>      
      );
    }
    case NOTIFICATION_TYPES.LOCUM_SHIFT_SELECTION_UNSUCCESSFUL: {
      const { shift } = notification;
      const { name, startTime, endTime, address } = shift;
      const { timeZone } = address;
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);
      const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(startDate, endDate, true, timeZone);
      const navToFind = (e) => {
        e.preventDefault();
        e.stopPropagation();
        navigate('/find');
        close();
      }
      return (
        <section className={'notification-message'}>
          <p className={'paragraph'}>
            {`Sorry the shift at `}
            <span className={'name'}>{name}</span>
            {' for '}
            <span className={'shift-dates'}>
              <b>{date}</b>
              {' from '}
              <b>{startTimeDisplay}</b> 
              {' to '}
              <b>{endTimeDisplay}</b>
              {` got filled by someone else!`}
            </span>
          </p>
          <p className={'paragraph'}>
            {`Don't worry, plenty of other shifts are available.`}
          </p>
          <p className={'paragraph'}>
            {' Find more shifts '}
            <button className={'link'} onClick={navToFind}>{'here'}</button>
            {'.'}
          </p>
        </section>      
      );
    }
    case NOTIFICATION_TYPES.LOCUM_PRE_SHIFT_DETAILS: {
      const { shift } = notification;
      const { name, startTime, endTime, address } = shift;
      const { timeZone } = address;
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);
      const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(startDate, endDate, true, timeZone);
      const navToMessages = (e) => {
        e.preventDefault();
        e.stopPropagation();
        navigate('/user/messages', { state: { 
          open: false,
          business: { 
            _id: shift.businessId, 
            firstName: shift.firstName, 
            lastName: shift.lastName
          }, 
          store: { 
            _id: shift.storeId, 
            avatar: shift.avatar, 
            name: shift.name
          }
        }})
        close();
      };
      return (
        <section className={'notification-message'}>
          <p className={'paragraph'}>
            {`Important: Submit your provider number to `}
            <span className={'name'}>{name}</span>
            {' ahead of your shift on '}
            <span className={'shift-dates'}>
              <b>{date}</b>
              {' from '}
              <b>{startTimeDisplay}</b> 
              {' to '}
              <b>{endTimeDisplay}</b>
              {'.'}
            </span>
          </p>
          <p className={'paragraph'}>
            {'You can '}
            <button className={'link'} onClick={navToMessages}>{'send a message'}</button> 
            {` to the store if you have any questions.`}
          </p>
        </section>      
      );
    }
    case NOTIFICATION_TYPES.LOCUM_SHIFT_REMINDER: {
      const { shift } = notification;
      const { name, address } = shift;
      const { mall, shopNumber, street, suburb, city, state, postcode } = address;
      const mallDisplay = mall ? `${mall}, ` : '';
      const shopNumberDisplay = shopNumber ? `${shopNumber}, ` : '';
      const getDirections = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const uriEncodedAddress = encodeURI(`${name} ${street} ${suburb} ${city} ${state} ${postcode}`);
        window.open(`${GOOGLE_DIRECTIONS_URL}${uriEncodedAddress}`);
        close();
      }
      return (
        <section className={'notification-message'}>
          <p className={'paragraph'}>
            {`Get excited! You have a shift at `}
            <span className={'name'}>{name}</span>
            {` tomorrow.`}
          </p>
          <p className={'paragraph'}>
            {`Here's the address: `}
          </p>
          <p className={'paragraph'}>
            <button className={'link'} onClick={getDirections}>
              {`${mallDisplay}${shopNumberDisplay}${street}, ${suburb}, ${city}, ${postcode}`}
            </button>
          </p>
        </section>      
      );
    }
    case NOTIFICATION_TYPES.BUSINESS_SHIFT_REMINDER: {
      const { shift } = notification;
      const { locum, name } = shift;
      const { firstName, lastName } = locum;
      return (
        <section className={'notification-message'}>
          <p className={'paragraph'}>
            {`Get excited!`}
            {`${firstName} ${lastName} will be completing the shift at `}
            <span className={'name'}>{name}</span>
            {`tomorrow.`}
          </p>
        </section>      
      );
    }
    case NOTIFICATION_TYPES.BUSINESS_SHIFT_PAYMENT: {
      const { shift } = notification;
      const { name, startTime, endTime, invoiceLink, address } = shift;
      const { timeZone } = address;
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);
      const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(startDate, endDate, true, timeZone);
      const downloadInvoice = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const link = document.createElement('a');
        link.href = invoiceLink;
        link.click();
      }
      return (
        <section className={'notification-message'}>
          <p className={'paragraph'}>
            {`Has the shift at `}
            <span className={'name'}>{name}</span>
            {' for '}
            <span className={'shift-dates'}>
              <b>{date}</b>
              {' from '}
              <b>{startTimeDisplay}</b> 
              {' to '}
              <b>{endTimeDisplay}</b>
            </span>
            {` been completed?`}
          </p>
          <p className={'paragraph'}>
            {`We've created an automatic invoice for you.`}
          </p>
          <p className={'paragraph'}>
            {'Pay it here or forward it to corporate: '}
          </p>
          <button className={'link'} onClick={downloadInvoice}>{'Invoice Link'}</button>
          <p className={'paragraph'}>
            {`Had an issue with the shift? Send us an email at help@locum.ly and we'll be in touch.`}
          </p>
        </section>      
      );
    }
    case NOTIFICATION_TYPES.LOCUM_SHIFT_PAYMENT: {
      const { shift } = notification;
      const { name, startTime, endTime, address } = shift;
      const { timeZone } = address;
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);
      const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(startDate, endDate, true, timeZone);
      const navToCompleted = (e) => {
        e.preventDefault();
        e.stopPropagation();
        navigate('/locum/shifts');
        close();
      };
      const navToFind = () => {
        navigate('/find');
        close();
      };
      return (
        <section className={'notification-message'}>
          <p className={'paragraph'}>
            {`Completed your shift at `}
            <span className={'name'}>{name}</span>
            {' for '}
            <span className={'shift-dates'}>
              <b>{date}</b>
              {' from '}
              <b>{startTimeDisplay}</b> 
              {' to '}
              <b>{endTimeDisplay}</b>
            </span>
            {'?'}
          </p>
          <p className={'paragraph'}>
            {'Send a '}
            <button className={'link'} onClick={navToCompleted}>{'request for payment'}</button>
            {' by marking the shift as complete and put your feet up as we generate an invoice for the store for you.'}
          </p>
          <p className={'paragraph'}>
            {'Expect the payment for your shift to be fufilled within a few weeks.'}
          </p>
          <button className={'link'} onClick={navToFind}> {'Book another shift here.'}</button>
        </section>      
      );
    }
    case NOTIFICATION_TYPES.BUSINESS_SHIFT_NOT_CONFIRMED: {
      const { shift } = notification;
      const { name, startTime, endTime, storeId, address } = shift;
      const { timeZone } = address;
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);
      const { date, startTimeDisplay, endTimeDisplay } = formatDatesWithTimeZone(startDate, endDate, true, timeZone);
      const navigateToUpcoming = (e) => {
        e.preventDefault();
        e.stopPropagation();
        navigate('/store/shifts/upcoming', { state: { storeId } });
      }
      return (
        <section className={'notification-message'}>
          <p className={'paragraph'}>
            {`No luck yet filling your shift at `}
            <span className={'name'}>{name}</span>
            {' on '}
            <span className={'shift-dates'}>
              <b>{date}</b>
              {' from '}
              <b>{startTimeDisplay}</b> 
              {' to '}
              <b>{endTimeDisplay}</b>
            </span>
            {'?'}
          </p>
          <p className={'paragraph'}>
          {'Try increasing the rate to stand out!'}
          </p>
          <p className={'paragraph'}>
            {' You can review and edit your upcoming shift details '}
            <button className={'link'} onClick={navigateToUpcoming}>{'here'}</button>
            {'.'}
          </p>
        </section>      
      );
    }
    case NOTIFICATION_TYPES.WELCOME: {
      const { firstName, role } = notification;
      const message = role === USER_ROLES.BUSINESS
        ? 'We are excited to assist you in finding the help you need.'
        : 'We are excited to assist you in finding Locum shifts.'
      return (
        <section className={'notification-message'}>
          <p className={'paragraph'}>
            {'Welcome onboard, '}
            <span className={'name'}>{firstName}</span>
            {'!'}
          </p>
          <p className={'paragraph'}>
            {message}
          </p>
        </section>
      );
    }
    default:
      return null;
  }
}

const NotificationDisplay = ({ notification }) => {
  const { date, avatar } = notification;
  const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
  const dateDisplay = new Date(date).toLocaleDateString('en-AU', options);
  const navigate = useNavigate();
  const notificationMessage = getNotificationMessage(notification, navigate, () => {});
  return (
    <section className={'notification-display'}>
      <span className={'avatar-and-date'}>
        <img className={'avatar'} src={!avatar ? LocumlyLogo : avatar} alt={'Notification Avatar'} />
        <span className={'date'}>{dateDisplay}</span>
      </span>
      <section className={'date-and-notification-message'}>
        {notificationMessage}
      </section>
    </section>
  );
}

const NotificationPage = () => {
  const { profile, setProfile, notifications, setNotifications, chats } = useAuth();
  const { state } = useLocation();
  const navigate = useNavigate();
  const notification = state && state.notification ? state.notification : null;
  const selected = state && state._id ? state._id : null;
  const [open, setOpen] = useState(true);
  const select = (notification, _id) => navigate('/user/notifications', { state: { notification, _id }});
  const isMobile = useMediaQuery('(max-width: 980px)');

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, [])

  const hasNew = notifications ? notifications.filter(notification => notification.new).length > 0 : false;
  useEffect(() => {
    if (notification && hasNew) {
      postSeenNotifications().then(() => {
        const seenNotifications = notifications.map(notification => ({ ...notification, new: false }));
        setNotifications(seenNotifications);
      });
    }
  }, [notification, hasNew, profile, setProfile, notifications, setNotifications]);

  // Resizes shift summary to fit right below month component.
  useEffect(() => {
    if (!open && isMobile) {
      const nav = document.getElementById('navbar-id');
      const header = document.getElementById('notifications-header');

      header.style.height = `${nav.offsetHeight}px`;
    }
  }, [open, isMobile])

  return (
    <article className={'notifications-page'}>
      {(!open && isMobile) && <div className={'shift-header'}>
        <div className={'chat-room-title-container'} id={'notifications-header'} onClick={() => setOpen(!open)}>
          <img src={DropdownArrow} className={'chat-title-back'} alt={'back button'}/>
          <div className={'chat-room-title'}>
            <span className={'notification-back'}>{'Back to notifications'}</span>
          </div>
        </div>
      </div>}
      <section className={'notifications-container'}>
        { (open || !isMobile) && 
          <section className={`sidebar-container ${open ? 'viewing-notif' : 'hidden'}`}>
            <NotificationsAndMessagesSidebar
              isNotification={true}
              notifications={notifications ? notifications : []}
              messages={chats}
              selected={selected}
              setSelected={select}
              setOpen={setOpen}
            />
          </section>
        }
        {!open && 
          <section className={'notification-information'}>
            {notification !== null && <NotificationDisplay notification={notification} />}
          </section>
        }
      </section>
    </article>
  );
};

export default NotificationPage;