import React from 'react';
import { PDF_CLOUDFRONT } from '../Constants';

const PrivacyPolicyPage = () => {
  return (
    <article className={'information-page'}>
      <section className={'privacy-policy-header'}>
        <h1 className={'information-title'}>{'Privacy Policy'}</h1>
        <span className={'update-date'}>{'Last Updated 8th March 2022'}</span>
      </section>
      <iframe className={'terms'} frameBorder={'0'} src={`${PDF_CLOUDFRONT}privacy-policy`} title={'Privacy Policy'} />
    </article>
  )
};

export default PrivacyPolicyPage;