import React from 'react';
import { Route } from 'react-router-dom';

import { RequireAuth } from '../components/hooks/AuthHook';

import CartPage from '../components/locum/CartPage';
import LocumManagePaymentsPage from '../components/locum/LocumManagePaymentsPage';
import LocumShiftsPage from '../components/locum/LocumShiftsPage';
import LocumUpcomingShiftsPage from '../components/locum/LocumUpcomingShiftsPage';
import LocumCompletedShiftsPage from '../components/locum/LocumCompletedShiftsPage';
import LocumManageAccountPage from '../components/locum/LocumManageAccountPage';
import LocumProfilePage from '../components/locum/LocumProfilePage';
import LocumExperiencePage from '../components/locum/LocumExperiencePage';
import Schedule from '../components/locum/Schedule';
import LocumlyPage from '../wrappers/LocumlyPage';

function getLocumRoutes() {
  return (
    <Route path={'/locum'} element={<LocumlyPage />}>
      <Route index={true} element={<RequireAuth><Schedule /></RequireAuth>} />
      <Route path={'payments/manage'} element={<RequireAuth><LocumManagePaymentsPage /></RequireAuth>} />
      <Route path={'cart'} element={<RequireAuth><CartPage /></RequireAuth>} />
      <Route path={'schedule'} element={<RequireAuth><Schedule /></RequireAuth>} />
      <Route path={'shifts'} element={<RequireAuth><LocumShiftsPage /></RequireAuth>} />
      <Route path={'shifts/upcoming'} element={<RequireAuth><LocumUpcomingShiftsPage /></RequireAuth>} />
      <Route path={'shifts/completed'} element={<RequireAuth><LocumCompletedShiftsPage /></RequireAuth>} />
      <Route path={'account/manage'} element={<RequireAuth><LocumManageAccountPage /></RequireAuth>} />
      <Route path={'experience/edit'} element={<RequireAuth><LocumExperiencePage /></RequireAuth>} />
      <Route path={'profile/edit'} element={<RequireAuth><LocumProfilePage /></RequireAuth>} />
      <Route path={'*'} element={<RequireAuth><Schedule /></RequireAuth>} />
    </Route>
  );
}

export default getLocumRoutes;