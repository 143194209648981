import React from 'react';
import { useNavigate } from 'react-router-dom';
import BackArrowButton from '../common/BackArrowButton.jsx';
import ChangePasswordCard from './ChangePasswordCard.jsx';
import ResetPasswordCard from './ResetPasswordCard.jsx';
import LoginOptionsCard from './LoginOptionsCard.jsx';
import LoginCard from './LoginCard.jsx';
import './LoginPage.scss';

/** Component for Locumly Login form. */
const LoginPageWrapper = ({ children }) => {
  return (
    <article className={'login-page'}>
      <section className={'login-background'}>
        {children}
      </section>
    </article>
  );
};

export const LoginPage = () => (
  <LoginPageWrapper>
    <LoginOptionsCard />
  </LoginPageWrapper>
);

export const BusinessLoginPage = () => {
  const navigate = useNavigate();
  return (
    <LoginPageWrapper>
      <BackArrowButton onClick={() => navigate('/login')} text={'Back'} />
      <LoginCard isBusiness={true} />
    </LoginPageWrapper>
  );
}
export const LocumLoginPage = () => {
  const navigate = useNavigate();
  return (
    <LoginPageWrapper>
      <BackArrowButton onClick={() => navigate('/login')} text={'Back'} />
      <LoginCard isBusiness={false} />
    </LoginPageWrapper>
  );
};

export const ResetBusinessPasswordPage = () => (
  <LoginPageWrapper>
    <ResetPasswordCard isBusiness={true} />
  </LoginPageWrapper>
);

export const ResetLocumPasswordPage = () => (
  <LoginPageWrapper>
    <ResetPasswordCard isBusiness={false} />
  </LoginPageWrapper>
);

export const ChangePasswordPage = () => (
  <LoginPageWrapper>
    <ChangePasswordCard />
  </LoginPageWrapper>
)