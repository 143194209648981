 import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllShifts } from '../Api.js';
import { computeDistance } from '../../helpers/ShiftUtils';
import { useCurrentLocation } from '../hooks/CommonHooks.jsx';
import HomeShiftCard from './HomeShiftCard.jsx';
import { TypingCarousel } from './SearchSection.jsx';
import './ShiftSection.scss';  

/** Component for Locumly shift list at Locumly Home. */
const ShiftSection = ({ shiftType }) => {
  const [shifts, setShifts] = useState(null);
  const [location, setLocation] = useState(null);
  let shiftList;
  if (shifts === null) {
    shiftList = 
      <span className={'loading-text'}>
        <TypingCarousel steps={[500, 'Loading', 500, 'Loading...']} showCursor={false} />
      </span>
  }
  else {
    if (location !== null) {
      shiftList = shifts
        .filter(shift => shiftType === null || shiftType === shift.type)
        .sort((e1, e2) => e1.distance - e2.distance)
        .slice(0, 50)
        .sort((e1, e2) => e1.startTime - e2.startTime)
        .map(shift => <HomeShiftCard shift={shift} key={`shift-${shift._id}`} />);
    } else {
      shiftList = shifts
        .filter(shift => shiftType === null || shiftType === shift.type)
        .sort((e1, e2) => e1.applicants.length - e2.applicants.length)
        .slice(0, 50)
        .sort((e1, e2) => e1.startTime - e2.startTime)
        .map(shift => <HomeShiftCard shift={shift} key={`shift-${shift._id}`} />);
    }
  }

  useEffect(() => {
    if (shifts === null) {
      getAllShifts(true).then(({ shifts }) => {
        setShifts(shifts)
      }).catch(() => setShifts([]));
    }
  }, [shifts, setShifts]);

  useEffect(() => {
    if (location !== null && shifts !== null) {
      setShifts(curShifts => 
        curShifts.map(shift => {
          shift.distance = computeDistance(location.coordinates, shift.address.coordinates, 'kilometers');
          return shift;
        })
      )
    }
  }, [location, setLocation, shifts, setShifts]);

  useCurrentLocation(setLocation, location === null, null, false);

  const navigate = useNavigate();
  const viewAll = () => navigate('/find');
  return (
    <section className={'shifts-section'} id={'shift-section'}>
      <h2 className={'title'}>{`See what's available`}</h2>
      <h3 className={'subtitle'}>{'Check upcoming shifts near you!'}</h3>
      <button
        className={`green-button very-dark`}
        onClick={viewAll}
      >
        {'View all shifts'}
      </button>
      <section className={'shift-list-container'}>
        <ul className={`shift-list ${shifts === null || shifts.length === 0 ? 'no-shifts' : ''}`}>
          {shiftList}
        </ul>
      </section>
    </section>
  );
};

export default ShiftSection;