import './PermanentShiftItem.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { PERMANENT_SHIFT_PROPS } from '../../StoreProfileProps';
import useStoreProfile from '../../../hooks/StoreProfileContext';

const propTypes = {
  /* Permanent shift listed by the store. */
  shift: PropTypes.shape(PERMANENT_SHIFT_PROPS).isRequired,

  /* Additional class names for the shift item. Currently, used to differentiate even/odd. */
  additionalClassNames: PropTypes.arrayOf(PropTypes.string).isRequired,

  /* True if is even. */
  isEven: PropTypes.bool.isRequired,
}

function PermanentShiftItem({ shift, additionalClassNames, isEven }) {
  const { storeProfile } = useStoreProfile();
  const { theme, storeUrl } = storeProfile;
  const { primaryHexColor, secondaryHexColor, tertiaryHexColor } = theme;
  const { jobTitle, salaryRange, locationName, summaryText, roleType, seekUrl } = shift;
  const seekClick = () => window.open(seekUrl);
  const backgroundColor = isEven ? tertiaryHexColor : secondaryHexColor;
  const plausibleLink = `plausible-event-link=${storeUrl}`;
  const plausibleEventClick = 'plausible-event-name=Permanent+Roles+Item+Click';
  const plausibleSeekLink = `plausible-event-outbound-link=${seekUrl}`;
  const plausibleEventClass = `${plausibleEventClick} ${plausibleLink} ${plausibleSeekLink}`;
  return (
    <li
      className={`store-shift-item permanent ${additionalClassNames.join(' ')}`}
      style={{ backgroundColor }}
    >
      <section className={'job-details'}>
        <span className={'job-title'}>{jobTitle}</span>
        <span className={'location-name'}>{locationName}</span>
        <span className={'summary-text'}>{summaryText}</span>
      </section>
      <section className={'job-actions'}>
        <span className={'role-type'}>{roleType}</span>
        <span className={'salary-range'}>{salaryRange}</span>
        <button
          className={`seek-button very-dark ${plausibleEventClass}`}
          type={'button'}
          onClick={seekClick}
          style={{ backgroundColor: primaryHexColor, borderColor: primaryHexColor }}
        >
          {'Apply via Seek >'}
        </button>
      </section>
    </li>
  )
}

PermanentShiftItem.displayName = 'PermanentShiftItem';
PermanentShiftItem.propTypes = propTypes;
export default PermanentShiftItem;