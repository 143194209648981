import React, { useState } from 'react';
import useAuth from '../hooks/AuthHook.jsx';
import { ShiftListViewer } from '../business/store/UpcomingShiftsPage.jsx';
import { LocumShiftViewer } from './LocumUpcomingShiftsPage.jsx';
import LocumPage from './LocumPage.jsx';
import { LOCUM_NAVIGATION_BUTTONS } from './LocumNavigationSidebar.jsx';
import LocumShiftCard from './LocumShiftCard.jsx';
import { SHIFT_STATUSES } from '../Constants.js';

const UpcomingTitleAndSubtitle = () => (
  <>
    <h1 className={'upcoming-shifts-title'}>{'Completed Shifts'}</h1>
    <p className={'upcoming-shifts-subtitle'}>
      {`Here is a list of your completed shifts.`}
    </p>
    <p className={'upcoming-shifts-subtitle'}>
      {`Check out the manage payments tab to gain access to your Stripe dashboard for your payments history.`}
    </p>
  </>
);

const SelectedShiftTitleAndSubtitle = ({ status }) => {
  let shiftText = '';
  let subShiftText = '';
  let title = 'Your Selected Completed Shift';
  if (SHIFT_STATUSES.CONFIRMED.key === status) {
    shiftText = `Once you mark this shift as complete, your invoice will be sent to the store.`;
    subShiftText = `If there were any issues with this shift, feel free to send an email to support@locum.ly.`;
  }
  else if (SHIFT_STATUSES.INVOICE.key === status) {
    shiftText = 'Good job on completing this shift. Your invoice has been generated and sent to the store.';
    subShiftText = `Hang tight! The store will be given a two week timeframe to complete the invoice.`;
  }
  else if (SHIFT_STATUSES.DISPUTED.key === status) {
    title = 'Your Selected Disputed Shift';
    shiftText = 'Some of these shift details have been reported to be inaccurate. Please direct any questions to hello@locum.ly';
    subShiftText = `Hang tight! The Locumly team is on the case. We will have this fixed in under 48 hours.`;
  }
  else {
    shiftText = 'This shift has been paid and completed.';
    subShiftText = 'Great work!';
  }
  return (
    <>
      <h1 className={'upcoming-shifts-title'}>{title}</h1>
      <p className={'upcoming-shifts-subtitle'}>{shiftText}</p>
      <p className={'upcoming-shifts-subtitle'}>{subShiftText}</p>
    </>
  );
};

const LocumCompletedShiftsPage = () => {
  const { profile } = useAuth();
  const emptyText = 'No completed shifts';
  const shifts = profile.shifts
    .filter(shift => 
      shift.endTime < new Date().getTime() && 
      (shift.status === SHIFT_STATUSES.CONFIRMED.key 
        || shift.status === SHIFT_STATUSES.INVOICE.key
        || shift.status === SHIFT_STATUSES.DISPUTED.key
        || shift.status === SHIFT_STATUSES.PAID.key
        || shift.status === SHIFT_STATUSES.COMPLETED.key)
    )
    .sort((e1, e2) => e1.endTime - e2.endTime);
  const [shift, setShift] = useState(null);
  return (
    <LocumPage mode={LOCUM_NAVIGATION_BUTTONS.COMPLETED.mode}>
      <article className={'upcoming-shifts-page'}>
        {shift === null && <UpcomingTitleAndSubtitle />}
        {shift !== null && <SelectedShiftTitleAndSubtitle status={shift.status} /> }
        {shift === null &&
          <section className={'shift-viewer-container'}>
            <ShiftListViewer
              shifts={shifts}
              CardComponent={LocumShiftCard}
              emptyText={emptyText}
              select={setShift}
            />
          </section>
        }
        {shift !== null && <LocumShiftViewer shift={shift} select={setShift} />}
      </article>
    </LocumPage>
  );
}

export default LocumCompletedShiftsPage;
